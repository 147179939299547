import React, { useEffect, useState } from "react";
import { Navigate, Outlet, createBrowserRouter, RouterProvider } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Layout, Result } from "antd";
import { useKeycloak } from "@react-keycloak/web";
import { useIdleTimer } from "react-idle-timer";
import {
  TBMHeader,
  PageDoesNotExist,
  UserIdProvider,
  windowHeightMinusHeader,
  AccessDenied,
  LoadingBackground,
  Paths,
  useLoading,
  Loading,
} from "./shared";
import {
  ActionEnum,
  UserFromQuery,
  ProtectedOrganizationRoute,
  ProtectedParticipantRoute,
  ProtectedStudyRoute,
  useGetUserLazyQuery,
} from "./services";
import {
  AccountSettings,
  ExecutorList,
  FirstLogin,
  LabelingInformation,
  NewOrganization,
  NewStudy,
  OrganizationController,
  OrganizationDetail,
  OrganizationMembers,
  OrganizationSettings,
  OrganizationStudies,
  ParticipantController,
  ParticipantGroupsTherapyConfig,
  ParticipantsGroups,
  ParticipantsOverview,
  ParticipantSupervisors,
  ParticipantTherapyConfigMemo,
  ProfileSettings,
  StudiesOverview,
  StudyController,
  StudyDetail,
  StudyMembers,
  StudyPersonnelSupervisors,
  StudySettings,
  SupervisedParticipantsOverview,
  UserController,
  VisualizationPageMemo,
} from "./modules";

const createRouter = ({
  user,
  landingPage,
  isAdmin,
}: {
  user: UserFromQuery;
  landingPage: string;
  isAdmin: boolean;
}) =>
  createBrowserRouter([
    {
      path: Paths.HOME,
      element: (
        <BaseContainer user={user}>
          <Outlet />
        </BaseContainer>
      ),
      children: [
        {
          path: "",
          element: <Navigate replace to={landingPage} />,
        },
        {
          path: Paths.ME,
          element: (
            <UserController>
              <Outlet />
            </UserController>
          ),
          children: [
            { path: Paths.relativeMePaths.PROFILE, element: <ProfileSettings user={user} /> },
            { path: Paths.relativeMePaths.ACCOUNT, element: <AccountSettings /> },
            { path: "*", element: <PageDoesNotExist /> },
          ],
        },
        {
          path: Paths.MANAGEMENT_CONSOLE,
          element: isAdmin ? <ExecutorList /> : <AccessDenied />,
        },
        {
          path: Paths.SUPERVISED_PARTICIPANTS,
          element: <SupervisedParticipantsOverview user={user} />,
        },
        {
          path: Paths.STUDIES_OVERVIEW,
          element: <StudiesOverview user={user} />,
        },
        {
          path: Paths.STUDY_NEW,
          element: <NewStudy user={user} />,
        },
        {
          path: Paths.ORGANIZATION_NEW,
          element: <NewOrganization />,
        },
        {
          path: Paths.getOrganizationPaths().BASE_PATH,
          element: (
            <ProtectedOrganizationRoute action={ActionEnum.ORGANIZATION_VIEW_DETAILS}>
              <OrganizationController>
                <Outlet />
              </OrganizationController>
            </ProtectedOrganizationRoute>
          ),
          children: [
            {
              path: Paths.relativeOrganizationPaths.OVERVIEW,
              element: (
                <ProtectedOrganizationRoute action={ActionEnum.ORGANIZATION_VIEW_DETAILS}>
                  <OrganizationDetail />
                </ProtectedOrganizationRoute>
              ),
            },
            {
              path: Paths.relativeOrganizationPaths.STUDIES,
              element: (
                <ProtectedOrganizationRoute action={ActionEnum.ORGANIZATION_VIEW_STUDIES}>
                  <OrganizationStudies />
                </ProtectedOrganizationRoute>
              ),
            },
            {
              path: Paths.relativeOrganizationPaths.MEMBERS,
              element: (
                <ProtectedOrganizationRoute action={ActionEnum.ORGANIZATION_VIEW_MEMBERS}>
                  <OrganizationMembers />
                </ProtectedOrganizationRoute>
              ),
            },
            {
              path: Paths.relativeOrganizationPaths.SETTINGS,
              element: (
                <ProtectedOrganizationRoute action={ActionEnum.ORGANIZATION_EDIT_ORGANIZATION}>
                  <OrganizationSettings />
                </ProtectedOrganizationRoute>
              ),
            },
            { path: "*", element: <PageDoesNotExist /> },
          ],
        },
        {
          path: Paths.getStudyPaths().BASE_PATH,
          element: (
            <StudyController>
              <Outlet />
            </StudyController>
          ),

          children: [
            {
              path: Paths.relativeStudyPaths.OVERVIEW,
              element: (
                <ProtectedStudyRoute action={ActionEnum.STUDY_VIEW_DETAILS}>
                  <StudyDetail />
                </ProtectedStudyRoute>
              ),
            },
            {
              path: Paths.relativeStudyPaths.PARTICIPANTS,
              element: (
                <ProtectedStudyRoute action={ActionEnum.STUDY_VIEW_PARTICIPANTS}>
                  <ParticipantsOverview />
                </ProtectedStudyRoute>
              ),
            },
            {
              path: Paths.relativeStudyPaths.PARTICIPANTS_GROUPS,
              element: (
                <ProtectedStudyRoute action={ActionEnum.STUDY_VIEW_GROUPS}>
                  <ParticipantsGroups />
                </ProtectedStudyRoute>
              ),
            },
            {
              path: Paths.relativeStudyPaths.PARTICIPANTS_GROUPS_THERAPY_CONFIG,
              element: (
                <ProtectedStudyRoute action={ActionEnum.STUDY_EDIT_GROUP_THERAPY_CONFIG}>
                  <ParticipantGroupsTherapyConfig />
                </ProtectedStudyRoute>
              ),
            },
            {
              path: Paths.relativeStudyPaths.SETTINGS,
              element: (
                <ProtectedStudyRoute action={ActionEnum.STUDY_EDIT}>
                  <StudySettings />
                </ProtectedStudyRoute>
              ),
            },
            {
              path: Paths.relativeStudyPaths.EDITORS,
              element: (
                <ProtectedStudyRoute action={ActionEnum.STUDY_VIEW_MEMBERS}>
                  <StudyMembers />
                </ProtectedStudyRoute>
              ),
            },
            {
              path: Paths.relativeStudyPaths.SUPERVISORS,
              element: (
                <ProtectedStudyRoute action={ActionEnum.STUDY_VIEW_MEMBERS}>
                  <StudyPersonnelSupervisors />
                </ProtectedStudyRoute>
              ),
            },
            {
              path: Paths.relativeParticipantPaths.BASE_PATH,
              element: (
                <ProtectedParticipantRoute action={ActionEnum.PARTICIPANT_VIEW_DETAILS}>
                  <ParticipantController>
                    <Outlet />
                  </ParticipantController>
                </ProtectedParticipantRoute>
              ),
              children: [
                {
                  path: Paths.relativeParticipantPaths.DASHBOARD,
                  element: (
                    <ProtectedParticipantRoute action={ActionEnum.PARTICIPANT_VIEW_DETAILS}>
                      <VisualizationPageMemo />
                    </ProtectedParticipantRoute>
                  ),
                },
                {
                  path: Paths.relativeParticipantPaths.THERAPY_CONFIG,
                  element: (
                    <ProtectedParticipantRoute action={ActionEnum.PARTICIPANT_VIEW_DETAILS}>
                      <ParticipantTherapyConfigMemo />
                    </ProtectedParticipantRoute>
                  ),
                },
                {
                  path: Paths.relativeParticipantPaths.SUPERVISORS,
                  element: (
                    <ProtectedParticipantRoute action={ActionEnum.PARTICIPANT_VIEW_DETAILS}>
                      <ParticipantSupervisors />
                    </ProtectedParticipantRoute>
                  ),
                },
                {
                  path: Paths.relativeParticipantPaths.LABELING_INFORMATION,
                  element: (
                    <ProtectedParticipantRoute action={ActionEnum.PARTICIPANT_VIEW_DETAILS}>
                      <LabelingInformation />
                    </ProtectedParticipantRoute>
                  ),
                },
                { path: "*", element: <PageDoesNotExist /> },
              ],
            },
            { path: "*", element: <PageDoesNotExist /> },
          ],
        },
        { path: "*", element: <PageDoesNotExist /> },
      ],
    },
  ]);

export function App() {
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();
  const [showError, setShowError] = useState(false);

  useIdleTimer({
    onIdle: () => keycloak.logout(),
    timeout: 20 * 60 * 1000,
    crossTab: true,
    syncTimers: 200,
    disabled: !keycloak.authenticated,
  });

  const periodicallyCheckSessionValidity = () => {
    const intervalId = setInterval(async () => {
      try {
        await keycloak.updateToken(60);
        await keycloak.loadUserInfo();
      } catch (e) {
        clearInterval(intervalId);
        keycloak.logout();
      }
    }, 10000);
  };

  useEffect(() => {
    if (!keycloak.authenticated) {
      keycloak.login();
    }
  }, [keycloak, keycloak.authenticated]);

  useEffect(() => {
    if (keycloak.subject) {
      getUser({
        variables: {
          userId: keycloak.subject,
        },
      });
      periodicallyCheckSessionValidity();
    }
  }, [keycloak.subject]);

  const [getUser, { data: getUserData, loading }] = useGetUserLazyQuery({
    onCompleted: (data) => {
      if (data?.user) {
        setShowError(false);
      } else {
        setShowError(true);
      }
    },
    onError: () => {
      setShowError(true);
    },
  });
  const user = getUserData?.user;
  const studyRoles = user?.studyMembers;
  const supervisorAssignments = user?.supervisors;
  const landingPage =
    studyRoles &&
    studyRoles?.length === 0 &&
    supervisorAssignments &&
    supervisorAssignments.length > 0
      ? Paths.SUPERVISED_PARTICIPANTS
      : Paths.STUDIES_OVERVIEW;

  if (showError) {
    return (
      <Result status="500" title="500" subTitle={t("common.results.internalError.subtitle")} />
    );
  }

  if (loading || !user) {
    return <LoadingBackground />;
  }

  return (
    <UserIdProvider userId={user.id}>
      <Layout style={{ minHeight: "100vh" }}>
        {!user.firstName ? (
          <FirstLogin logout={keycloak.logout} />
        ) : (
          <RouterProvider
            router={createRouter({
              user,
              landingPage,
              isAdmin: keycloak.hasResourceRole("master"),
            })}
          />
        )}
      </Layout>
    </UserIdProvider>
  );
}

function BaseContainer({ children, user }: { children?: React.ReactNode; user: UserFromQuery }) {
  const { isLoading } = useLoading();

  return (
    <>
      <TBMHeader user={user} />
      <Layout
        style={{
          minHeight: windowHeightMinusHeader,
          minWidth: 800,
          marginTop: 64,
          background: "white",
        }}
      >
        {children}
        {isLoading ? <Loading /> : null}
      </Layout>
    </>
  );
}

export default App;
