import { ApolloError } from "@apollo/client";
import { notification } from "antd";
import { useTranslation } from "react-i18next";
import { useUserLazy } from ".";

import { useUserId } from "../../../shared";
import {
  CreateSupervisorActionMutation,
  GetParticipantsOfStudyDocument,
  GetStudyMembersDocument,
  useCreateSupervisorActionMutation,
} from "../codegen";

interface IUseCreateStudyMemberProps {
  // eslint-disable-next-line no-unused-vars
  onCompleted?: (data: CreateSupervisorActionMutation) => void;
  // eslint-disable-next-line no-unused-vars
  onError?: (error: ApolloError) => void;
}

interface ICreateSupervisor {
  participantIds: string[];
  supervisorRoleId: string;
  email: string;
  studyId: string;
}

export function useCreateSupervisorByMail({
  onCompleted = () => {},
  onError = () => {},
}: IUseCreateStudyMemberProps) {
  const { t } = useTranslation();
  const { userId } = useUserId();
  const { getUser } = useUserLazy();
  const [mutate, rest] = useCreateSupervisorActionMutation({
    onCompleted: (data) => {
      notification.success({
        message: t("notifications.createSupervisor.success"),
      });
      if (data.createSupervisorAction?.find((supervisor) => supervisor?.userId === userId)) {
        getUser(userId);
      }

      onCompleted(data);
    },
    onError: (error) => {
      notification.error({
        message: t("notifications.createSupervisor.error"),
      });
      onError(error);
    },
  });

  const createSupervisor = async (props: ICreateSupervisor) => {
    await mutate({
      variables: {
        participantIds: props.participantIds,
        supervisorRoleId: props.supervisorRoleId,
        email: props.email,
        studyId: props.studyId,
      },
      refetchQueries: [
        { query: GetStudyMembersDocument, variables: { studyId: props.studyId } },
        { query: GetParticipantsOfStudyDocument, variables: { studyId: props.studyId } },
      ],
    });
  };

  return { createSupervisor, ...rest };
}

export default useCreateSupervisorByMail;
