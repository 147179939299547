import { Layout } from "antd";
import { Content } from "antd/lib/layout/layout";
import React from "react";
import { useLocation } from "react-router";
import {
  ActionEnum,
  useExecutorParticipant,
  usePermission,
  useVisualizations,
} from "../../../services";
import { useLabelingInformation } from "../../../services/graphql/hooks/useLabelingInformation";

import {
  collapsedSiderWidthTabletMode,
  Loader,
  PageDoesNotExist,
  participantContentStyle,
  participantLayoutStyle,
  ParticipantProvider,
  Paths,
  ServerError,
  useEditableTherapyElements,
  useParticipantId,
  useStudyId,
} from "../../../shared";
import useTabletMode from "../../../shared/hooks/useTabletMode";

import { ParticipantPageHeader, ParticipantMenuItemEnum, ParticipantSider } from "./components";

export function ParticipantController({ children }: { children: React.ReactNode }) {
  const location = useLocation();
  const isInTabletMode = useTabletMode();
  const { participantId } = useParticipantId();
  const { studyId } = useStudyId();

  const { participant, loading: participantLoading } = useExecutorParticipant(participantId);
  const { loading: permissionLoading } = usePermission({
    action: ActionEnum.PARTICIPANT_VIEW_DETAILS,
    participantId,
  });
  const { labelingInformation, loading: labelingInformationLoading } =
    useLabelingInformation(studyId);

  const { editableTherapyElements, loading: therapyDesignLoading } = useEditableTherapyElements({
    studyId,
    configurationLevel: "THERAPIST",
  });

  const participantRoutes = Paths.getStudyPaths(studyId).getParticipantsPaths(participantId);

  const therapyElementsHashLinks = editableTherapyElements?.map((element) => ({
    name: element.title,
    hashLink: `${participantRoutes.THERAPY_CONFIG}#${element.id}`,
  }));

  const { visualizationPageData, loading: visualizationsLoading } = useVisualizations({
    participantId,
  });

  const visualizationsHashLinks = visualizationPageData?.children.map((child) => ({
    name: child.title,
    hashLink: `${participantRoutes.DASHBOARD}#${child.id}`,
  }));

  const getContainerPropsFromPath = () => {
    let selectedKeys = [""];
    let defaultOpenKeys = [""];

    switch (location.pathname) {
      case participantRoutes.SUPERVISORS:
        selectedKeys = [ParticipantMenuItemEnum.SUPERVISORS];
        break;
      case participantRoutes.THERAPY_CONFIG:
        selectedKeys = [ParticipantMenuItemEnum.THERAPY_CONFIG];
        defaultOpenKeys = [ParticipantMenuItemEnum.THERAPY_CONFIG];
        break;
      case participantRoutes.LABELING_INFORMATION:
        selectedKeys = [ParticipantMenuItemEnum.LABELING_INFORMATION];
        defaultOpenKeys = [ParticipantMenuItemEnum.LABELING_INFORMATION];
        break;
      default:
        selectedKeys = [ParticipantMenuItemEnum.DASHBOARD];
        defaultOpenKeys = [ParticipantMenuItemEnum.DASHBOARD];
    }

    return {
      selectedKeys,
      defaultOpenKeys,
    };
  };

  if (
    participantLoading ||
    therapyDesignLoading ||
    permissionLoading ||
    labelingInformationLoading ||
    visualizationsLoading
  ) {
    return <Loader />;
  }

  if (!participant || !participant.participant) {
    return <ServerError />;
  }

  if (participant.participant.participantGroup.study.id !== studyId) {
    return <PageDoesNotExist />;
  }

  const hasuraParticipant = participant.participant;

  return (
    <Layout style={participantLayoutStyle}>
      <ParticipantPageHeader
        pseudonym={hasuraParticipant.pseudonym}
        coupleUrl={participant.coupleUrl}
        therapyStart={participant.therapyStart}
        therapyEnd={participant.therapyEnd}
        studyId={hasuraParticipant.participantGroup.study.id}
        participantId={participantId}
        therapyPeriodId={participant.therapyPeriodId}
        coupledAt={hasuraParticipant.coupledAt}
      />

      <Layout>
        <ParticipantSider
          {...getContainerPropsFromPath()}
          therapyElements={therapyElementsHashLinks}
          visualizations={visualizationsHashLinks}
          participantId={participant.id}
          studyId={hasuraParticipant.participantGroup.study.id}
          labelingInformation={labelingInformation}
        />

        <Content
          style={{
            ...participantContentStyle,
            paddingLeft: isInTabletMode ? collapsedSiderWidthTabletMode + 8 : 8,
          }}
        >
          <ParticipantProvider participant={hasuraParticipant}>{children}</ParticipantProvider>
        </Content>
      </Layout>
    </Layout>
  );
}

export default ParticipantController;
