import { ApolloError } from "@apollo/client";
import { notification } from "antd";
import { useTranslation } from "react-i18next";
import { DeleteStudyMemberMutation, useDeleteStudyMemberMutation } from "../codegen";

interface IDeleteStudyMemberProps {
  // eslint-disable-next-line no-unused-vars
  onCompleted?: (data: DeleteStudyMemberMutation) => void;
  // eslint-disable-next-line no-unused-vars
  onError?: (error: ApolloError) => void;
}

interface IDeleteStudyMember {
  userId: string;
  studyId: string;
  userName?: string;
}

export function useDeleteStudyMember({
  onCompleted = () => {},
  onError = () => {},
}: IDeleteStudyMemberProps) {
  const { t } = useTranslation();
  const [mutate, loading] = useDeleteStudyMemberMutation({
    onCompleted: (data) => {
      notification.success({
        message: t("notifications.deleteStudyMember.success"),
      });
      onCompleted(data);
    },
    onError: (error) => {
      notification.error({
        message: t("notifications.deleteStudyMember.error"),
      });
      onError(error);
    },
  });

  const deleteStudyMember = (props: IDeleteStudyMember) => {
    mutate({
      variables: { userId: props.userId, studyId: props.studyId },
    });
  };

  return { deleteStudyMember, loading };
}

export default useDeleteStudyMember;
