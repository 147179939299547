import { useParams } from "react-router-dom";

export function useOrganizationId() {
  const { organizationId } = useParams();
  if (!organizationId) {
    throw new Error("OrganizationId not defined");
  }
  return { organizationId };
}

export default useOrganizationId;
