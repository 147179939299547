import { Affix, Alert, Button, Divider, Result, Tooltip, Typography } from "antd";
import DOMPurify from "dompurify";
import React from "react";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { PrinterOutlined } from "@ant-design/icons";
import { useReactToPrint } from "react-to-print";
import {
  DummyAnchor,
  Loader,
  participantPageHeaderHeight,
  useCachedParticipant,
  useParticipantId,
} from "../../../../../shared";
import { useVisualizations } from "../../../../../services/graphql";
import { endOfDayString, sortElementsByTitle, startOfDayString } from "../../../../../utils";
import { VisualizationObject } from "../../../../../services/visualizations";
import { ElementContainer } from "../../../components";
import AnalysisRangePicker from "./AnalysisRangePicker";

import Visualization from "./Visualization";
import "./visualizationPage.css";
import ReportHeader from "./ReportHeader";

const { Title } = Typography;

export interface IVisualizationPageProps {
  participantId: string;
}

export interface IGenerateDownloadFileNameProps {
  start: string;
  end: string;
  pseudonym: string;
  visualizationTitle: string;
}

/**
 * Generates a meaningful fileName: pseudonym_visualiationTitle_start_to_end
 */
const generateDownloadFileName = ({
  start,
  end,
  pseudonym,
  visualizationTitle,
}: IGenerateDownloadFileNameProps) =>
  `${pseudonym}_${visualizationTitle}__${dayjs(start).format("YYYY-MM-DD")}_to_${dayjs(end).format(
    "YYYY-MM-DD",
  )}`.replace(/\s/g, "_");

/**
 * Container that renders all visualizations
 */
export function VisualizationPage() {
  const { participantId } = useParticipantId();
  const { participant } = useCachedParticipant();
  const { t } = useTranslation();

  const printRef = React.useRef<HTMLDivElement>(null);

  const handlePrint = useReactToPrint({
    documentTitle: `${t("platform.title")} - ${t("study.visualization.print.title", {
      participantPseudonym: participant?.pseudonym,
    })}`,
    content: () => printRef.current,
  });

  const {
    visualizationPageData,
    loading: visualizationsLoading,
    refetch,
  } = useVisualizations({ participantId });

  if (visualizationsLoading || !participant) {
    return <Loader />;
  }

  if (!visualizationPageData) {
    return (
      <Result
        status="404"
        title={t("study.visualization.noVisualizationResult.title")}
        subTitle={t("study.visualization.noVisualizationResult.subTitle")}
      />
    );
  }

  const maxChildWidth = Math.max(
    ...visualizationPageData.children.map((child) => child.preferredWidth || 0),
  );

  const orderedChildren = sortElementsByTitle<VisualizationObject>(visualizationPageData.children);

  return (
    <>
      <div
        style={{
          paddingBottom: 20,
          maxWidth: maxChildWidth === 0 ? 800 : maxChildWidth,
          margin: "auto",
        }}
      >
        <Title level={4}>
          {visualizationPageData.title}
          <Tooltip title={t("study.visualization.print.tooltip")}>
            <Button
              style={{ marginLeft: 16 }}
              shape="round"
              icon={<PrinterOutlined />}
              onClick={() => handlePrint()}
            />
          </Tooltip>
        </Title>
        {visualizationPageData.description != null ? (
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(visualizationPageData.description),
            }}
          />
        ) : null}
        <Divider />
        {visualizationPageData.message ? (
          <Affix offsetTop={64} style={{ marginTop: 24 }}>
            <Alert
              message={visualizationPageData.message.title}
              description={visualizationPageData.message.message}
              showIcon
              type={visualizationPageData.message.type}
            />
          </Affix>
        ) : null}
      </div>
      <div ref={printRef} className="printpage">
        <div className="reportHeader">
          <ReportHeader
            analysisStart={visualizationPageData.start}
            analysisEnd={visualizationPageData.end}
            participantPseudonym={participant.pseudonym}
          />
        </div>

        {orderedChildren.map((child) => {
          if (typeof child === "string") {
            return <DummyAnchor key={child} id={child} />;
          }

          return (
            <>
              <ElementContainer
                key={child.id}
                id={child.id}
                title={child.title}
                description={child.description}
                hashLinkPrefix=""
                style={{
                  maxWidth: maxChildWidth + 48,
                  width: "100%",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    maxWidth: child.preferredWidth || maxChildWidth,
                    margin: "auto",
                    overflowX: "auto",
                  }}
                >
                  <Visualization
                    visualizationObject={child}
                    downloadFileName={
                      child.downloadFileName ||
                      generateDownloadFileName({
                        pseudonym: participant.pseudonym,
                        start: visualizationPageData.start,
                        end: visualizationPageData.end,
                        visualizationTitle: child.title,
                      })
                    }
                  />
                </div>
                {child.message ? (
                  <Alert
                    type={child.message.type}
                    message={child.message.title}
                    description={
                      <div
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(child.message.message),
                        }}
                      />
                    }
                    banner
                    style={{ marginTop: 24, maxHeight: 200, overflowY: "auto" }}
                  />
                ) : null}
              </ElementContainer>
              <div className="pagebreak" />
            </>
          );
        })}
      </div>
      <div style={{ minHeight: 800 }} />
      <Affix
        offsetTop={72}
        style={{ position: "absolute", top: participantPageHeaderHeight + 72, right: 16 }}
      >
        <AnalysisRangePicker
          start={visualizationPageData.start}
          end={visualizationPageData.end}
          onChange={(range) =>
            refetch({ start: startOfDayString(range.start), end: endOfDayString(range.end) })
          }
        />
      </Affix>
    </>
  );
}

export const VisualizationPageMemo = React.memo(VisualizationPage);
