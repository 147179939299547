import React from "react";
import { Space, Statistic } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { useTranslation } from "react-i18next";
import { UserOutlined } from "@ant-design/icons";

import {
  participantPageHeaderStyle,
  useParticipantActionButtons,
  useParticipantState,
  StateIcon,
  Loader,
  ServerError,
} from "../../../../shared";
import { usePermission, ActionEnum, useExecutorParticipant } from "../../../../services";

interface IParticipantPageHeaderProps {
  pseudonym: string;
  coupleUrl?: string | null;
  therapyStart?: string | null;
  therapyEnd?: string | null;
  studyId: string;
  participantId: string;
  therapyPeriodId: string;
  coupledAt: string | null;
}

export function ParticipantPageHeader({
  pseudonym,
  coupleUrl,
  coupledAt,
  therapyEnd,
  therapyStart,
  participantId,
  therapyPeriodId,
  studyId,
}: IParticipantPageHeaderProps) {
  const { t } = useTranslation();
  const { permitted: canViewGroupAssignment } = usePermission({
    action: ActionEnum.STUDY_VIEW_GROUP_ASSIGNMENT,
    studyId,
  });

  const { permitted: studySiderIsVisible } = usePermission({
    action: ActionEnum.STUDY_VIEW_DETAILS,
    studyId,
  });

  const { style, name, description } = useParticipantState({ coupleUrl, therapyEnd, therapyStart });

  const actions = useParticipantActionButtons({
    coupleUrl,
    participantId,
    therapyEnd,
    therapyStart,
    pseudonym,
    state: name,
    studyId,
    therapyPeriodId,
    coupledAt,
  });

  const { participant, loading } = useExecutorParticipant(participantId);

  if (loading) {
    return <Loader />;
  }

  if (!participant || !participant.participant) {
    return <ServerError />;
  }

  return (
    <PageHeader
      style={{
        ...participantPageHeaderStyle,
        width: studySiderIsVisible ? participantPageHeaderStyle.width : "100%",
      }}
      title={t("participant.pageHeader.title", { pseudonym })}
      extra={actions}
    >
      <Space size={32}>
        <div style={{ width: 54, height: 54, position: "relative" }}>
          <UserOutlined
            style={{
              fontSize: 48,
              color: "lightgrey",
            }}
          />
          <div style={{ position: "absolute", bottom: 0, right: 0 }}>
            <StateIcon coupleUrl={coupleUrl} therapyEnd={therapyEnd} therapyStart={therapyStart} />
          </div>
        </div>
        <Statistic
          title={t("participant.pageHeader.state")}
          value={name}
          valueStyle={{ color: style.textColor, fontSize: "large", fontWeight: "500" }}
        />

        <Statistic
          title={t("participant.pageHeader.study")}
          value={participant?.participant.participantGroup.study.name}
          valueStyle={{ fontSize: "large", fontWeight: "500" }}
        />

        {canViewGroupAssignment && (
          <Statistic
            title={t("participant.pageHeader.participantGroup")}
            value={participant?.participant.participantGroup.name}
            valueStyle={{ fontSize: "large", fontWeight: "500" }}
          />
        )}

        <Statistic
          title={t("participant.pageHeader.info")}
          value={description}
          valueStyle={{ fontSize: "large", fontWeight: "500" }}
        />
      </Space>
    </PageHeader>
  );
}

export default ParticipantPageHeader;
