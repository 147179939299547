import { Table } from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ColumnType, TableProps } from "antd/lib/table";
import dayjs from "dayjs";
import React from "react";
import { Row, SortingDirection, TableVisualizationProps } from "../../../../../services";
import NoDataOverlayContainer from "./NoDataOverlayContainer";

/**
 * Interface for row data
 */
interface IRecord {
  date: string;
  time: string;
  [dataIndex: string]: string;
}

export interface IPrepareAndSortDataProps {
  data: Row[];
  dateOrder: SortingDirection;
  timeOrder: SortingDirection;
  locale: string;
}
/**
 * 1. datetime is split into a date and a time. Transforms data from {dateTime,
 *    ...rest}[] to {date, time, ...rest}[].
 * 2. sorts data by time and sort direction timeOrder
 * 3. sorts data by date and dateOrder sort direction
 */
export const prepareAndSortData = ({
  data,
  dateOrder,
  timeOrder,
  locale,
}: IPrepareAndSortDataProps) => {
  const dataWithTransformedDate = data.map((row) => ({
    date: dayjs(row.dateTime).toDate().toDateString(),
    time: dayjs(row.dateTime).toDate().toTimeString(),
    datetime: row.dateTime,
    ...row.values,
    key: row.key,
  }));

  dataWithTransformedDate.sort((a, b) =>
    timeOrder === "ascending"
      ? dayjs(`01.01.2000 ${a.time}`).diff(dayjs(`01.01.2000 ${b.time}`))
      : dayjs(`01.01.2000 ${b.time}`).diff(dayjs(`01.01.2000 ${a.time}`)),
  );

  dataWithTransformedDate.sort((a, b) =>
    dateOrder === "ascending"
      ? dayjs(a.date).diff(dayjs(b.date))
      : dayjs(b.date).diff(dayjs(a.date)),
  );

  return dataWithTransformedDate.map((row) => ({
    ...row,
    date: dayjs(row.datetime).toDate().toLocaleDateString(locale, {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
    }),
    time: dayjs(row.datetime).toDate().toLocaleTimeString(locale, { timeStyle: "short" }),
  }));
};

export interface IGetRowSpan {
  allRecords: IRecord[];
  currentRecord: IRecord;
  rowIndex: number;
}

/**
 * Calculates the rowSpan needed for the current row to combine the same dates
 * in the Date column.
 */
export const getDateRowSpan = ({ allRecords, currentRecord, rowIndex }: IGetRowSpan) => {
  const firstIndex = allRecords.map((record) => record.date).indexOf(currentRecord.date);
  const lastIndex = allRecords.map((record) => record.date).lastIndexOf(currentRecord.date);
  if (rowIndex === firstIndex) {
    return lastIndex - firstIndex + 1;
  }
  return 0;
};

/**
 * Calculates the rowSpan needed for the current row to combine the same times
 * in the Time column.
 */
export const getTimeRowSpan = ({ allRecords, currentRecord, rowIndex }: IGetRowSpan) => {
  const indicesOfRecordsWithSameDateTime = allRecords
    .map((item, i) => ({ index: i, date: item.date, time: item.time }))
    .filter((item) => item.date === currentRecord.date && item.time === currentRecord.time)
    .map((item) => item.index);

  const firstIndex = Math.min(...indicesOfRecordsWithSameDateTime);
  const lastIndex = Math.max(...indicesOfRecordsWithSameDateTime);
  if (rowIndex === firstIndex) {
    return lastIndex - firstIndex + 1;
  }
  return 0;
};

/**
 * Styled Table thah changes background color on row hover
 */
const StyledTable = styled((props: TableProps<any>) => <Table {...props} />)`
  && tbody > tr:hover > td {
    background: #fafafa;
  }
`;

/**
 * Visualization that allows the tabular representation of variables over time.
 */
export function TableVisualization({
  data,
  columnSpec,
  dateOrder = "descending",
  timeOrder = "ascending",
  maxHeight,
  showNoDataOverlay = false,
}: TableVisualizationProps) {
  const { i18n } = useTranslation();
  const preparedData = prepareAndSortData({ data, dateOrder, timeOrder, locale: i18n.language });
  console.log(preparedData);

  const columns: ColumnType<IRecord>[] = [
    {
      title: "Datum",
      dataIndex: "date",
      key: "date",
      onCell: (currentRecord, rowIndex) => ({
        rowSpan:
          rowIndex === undefined
            ? undefined
            : getDateRowSpan({ allRecords: preparedData, currentRecord, rowIndex }),
      }),
    },
    {
      title: "Uhrzeit",
      dataIndex: "time",
      key: "time",
      onCell: (currentRecord, rowIndex) => ({
        rowSpan:
          rowIndex === undefined
            ? undefined
            : getTimeRowSpan({ allRecords: preparedData, currentRecord, rowIndex }),
      }),
    },
    ...columnSpec.map((column) => ({ ...column, key: column.dataIndex })),
  ];

  return (
    <NoDataOverlayContainer showNoDataOverlay={showNoDataOverlay}>
      <StyledTable
        columns={columns}
        dataSource={preparedData}
        scroll={{ y: maxHeight }}
        pagination={false}
      />
    </NoDataOverlayContainer>
  );
}

export default TableVisualization;
