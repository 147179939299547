import React from "react";

import { Modal, Form, Input, notification } from "antd";
import { useTranslation } from "react-i18next";
import TextArea from "antd/lib/input/TextArea";
import { useUpdateParticipantsGroupMutation } from "../../../../services";

interface IEditParticipantsGroupModalProps {
  open: boolean;
  groupId: string;
  name: string;
  description: string;
  onCancel: () => void;
}

export function EditParticipantsGroupModal({
  open,
  groupId,
  name,
  description,
  onCancel,
}: IEditParticipantsGroupModalProps) {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [updateGroup] = useUpdateParticipantsGroupMutation({
    onCompleted: () => handleCancel(),
    onError: () => {
      notification.error({
        message: t("notifications.updateParticipantsGroup.error.message", { groupName: name }),
        description: t("notifications.updateParticipantsGroup.error.description"),
      });
    },
  });

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <Modal
      open={open}
      title={t("study.participantsGroups.editGroupModal.title")}
      okText={t("common.save")}
      cancelText={t("common.cancel")}
      // @ts-ignore
      cancelButtonProps={{ "data-id": "common.cancel" }}
      // @ts-ignorea
      okButtonProps={{ "data-id": "common.create" }}
      onCancel={handleCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            updateGroup({
              variables: {
                id: groupId,
                ...values,
              },
            });
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          name,
          description,
        }}
      >
        <Form.Item
          name="name"
          label={t("study.participantsGroups.createGroupModal.inputLabel")}
          rules={[
            {
              required: true,
              message: t("study.participantsGroups.createGroupModal.errorMessage"),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="description"
          label={t("study.participantsGroups.createGroupModal.groupDescriptionLabel")}
          rules={[{ required: false }]}
        >
          <TextArea rows={4} />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default EditParticipantsGroupModal;
