import { useGetSupervisorsOfParticipantQuery } from "../codegen";

export function useSupervisorsOfParticipant(participantId: string) {
  const {
    data,
    loading,
    refetch: refetchSupervisors,
  } = useGetSupervisorsOfParticipantQuery({
    variables: { participantId },
    fetchPolicy: "cache-and-network",
  });

  return {
    supervisors: data ? data.getSupervisorsOfParticipantAction : [],
    loading,
    refetchSupervisors,
  };
}

export default useSupervisorsOfParticipant;
