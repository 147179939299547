import { useState } from "react";
import { useGetExecutorsQuery } from "../codegen";

export function useExecutorsOfOrganization(organizationId: string) {
  const { data, ...rest } = useGetExecutorsQuery();
  const filteredExecutors = data?.executors.filter(
    (executor) => executor.organizationId === null || executor.organizationId === organizationId,
  );

  return { executors: filteredExecutors, ...rest };
}

export function useExecutorsOfOrganizationLazy() {
  const { data, ...rest } = useGetExecutorsQuery();
  const [organizationId, setOrganizationId] = useState<string>();

  const getExecutorsOfOrganization = (organizationId: string) => {
    setOrganizationId(organizationId);
  };

  return {
    executors: data?.executors.filter(
      (executor) =>
        !executor.hidden &&
        (executor.organizationId === null || executor.organizationId === organizationId),
    ),
    getExecutorsOfOrganization,
    ...rest,
  };
}

export default useExecutorsOfOrganization;
