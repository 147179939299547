import React from "react";

/**
 * Container to center content in a modal opened by model.confirm, model.info,
 * model.error, model.warning. Fixes wrong centering of content in modal when
 * icon is set to null. Right now, margin-right is set to 0, as antd has fixed
 * the issue. Before, it was set to -32. If this issue reoccurs, content can be
 * centered by setting margin-right to the desired value.
 *
 */
export function CenterInModal({ children }: { children: React.ReactNode }) {
  return (
    <div
      style={{
        marginRight: 0,
      }}
    >
      {children}
    </div>
  );
}

export default CenterInModal;
