import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, App, Row, Col } from "antd";
import { useLeaveStudy, useSupervisors } from "../../../services";
import useTherapyDesign from "../../../services/graphql/hooks/useTherapyDesign";
import { PageHeader, Paths, StudyRoleEnum, useCachedStudy } from "../../../shared";
import { ParticipantCard, PersonnelCard } from "./components";
import { ExecutorStatisticsAlerts } from "../components";

const rowHorizontalGutter = 8;
const rowVerticalGutter = 24;
const colSpan = 11;

export function StudyDetail() {
  const { t } = useTranslation();
  const { study } = useCachedStudy();
  const navigate = useNavigate();
  const { supervisors } = useSupervisors(study.id);
  const { modal } = App.useApp();

  useTherapyDesign(study.id);

  const { leaveStudy, canLeave } = useLeaveStudy({
    studyId: study.id,
    onCompleted: () => navigate(Paths.HOME),
  });

  const numberOfParticipants = study.participantGroups
    .map((group) => group.participants.length)
    .reduce((partialSum, a) => partialSum + a, 0);
  const numberOfParticipantsGroups = study.participantGroups.length;

  const numberOfOwners = study.studyMembers.filter(
    (member) => member.studyRole.name === StudyRoleEnum.OWNER,
  ).length;
  const numberOfAdmin = study.studyMembers.filter(
    (member) => member.studyRole.name === StudyRoleEnum.ADMIN,
  ).length;
  const numberOfParticipantManagers = study.studyMembers.filter(
    (member) => member.studyRole.name === StudyRoleEnum.PARTICIPANT_MANAGER,
  ).length;

  const handleLeaveStudy = () => {
    if (canLeave) {
      modal.confirm({
        title: t("study.leave.confirm.title", { studyName: study.name }),
        icon: <ExclamationCircleOutlined />,
        content: t("study.leave.confirm.description"),
        okText: t("common.yes"),
        okType: "danger",
        cancelText: t("common.no"),
        onOk() {
          leaveStudy();
        },
      });
    } else {
      modal.error({
        title: t("study.leave.error.title", { studyName: study.name }),
        icon: <ExclamationCircleOutlined />,
        content: t("study.leave.error.description"),
        okText: t("common.ok"),
        okType: "default",
        cancelButtonProps: { hidden: true },
      });
    }
  };

  return (
    <div style={{ maxWidth: 1000, margin: "auto" }}>
      <PageHeader
        title={`Welcome to ${study.name}`}
        extra={[
          <Button key="leaveButton" onClick={handleLeaveStudy}>
            {t("study.leave.leaveButton")}
          </Button>,
        ]}
      />

      <div style={{ textAlign: "center" }}>
        <Row justify="space-around" gutter={[rowHorizontalGutter, rowVerticalGutter]}>
          <Col span={colSpan}>
            <ParticipantCard
              studyId={study.id}
              numberOfParticipants={numberOfParticipants}
              numberOfParticipantsGroups={numberOfParticipantsGroups}
            />
          </Col>
          <Col span={colSpan}>
            <PersonnelCard
              studyId={study.id}
              numberOfAdmins={numberOfAdmin}
              numberOfOwners={numberOfOwners}
              numberOfParticipantManagers={numberOfParticipantManagers}
              numberOfParticipantSupervisors={supervisors?.length}
            />
          </Col>
        </Row>
      </div>
      <ExecutorStatisticsAlerts />
    </div>
  );
}

export default StudyDetail;
