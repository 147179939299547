/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import Dropdown, { DropDownProps } from "antd/lib/dropdown";
import React from "react";
import { DownOutlined } from "@ant-design/icons";
import { Avatar } from "antd";

export interface IHeaderDropDownMenuProps extends DropDownProps {
  title: string;
  avatar?: string | null;
}

function HeaderDropDownMenu(props: IHeaderDropDownMenuProps) {
  const { title, avatar } = props;
  return (
    <Dropdown {...props} trigger={["click"]}>
      <a style={{ color: "white", height: 24 }}>
        {avatar ? <Avatar src={avatar} /> : title}

        <DownOutlined style={{ marginLeft: 8 }} />
      </a>
    </Dropdown>
  );
}

export default HeaderDropDownMenu;
