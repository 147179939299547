import { Form, Modal } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useUpdateOrganizationMember } from "../../../services";
import { OrganizationRolesFormItem } from "./OrganizationRolesFormItem";

interface IEditOrganizationMemberModalProps {
  organizationId: string;
  userId: string;
  userName: string;
  open: boolean;
  currentRoleId: string;
  onCancel: () => void;
}

export function EditOrganizationMemberModal({
  organizationId,
  userId,
  open,
  currentRoleId,
  userName,
  onCancel,
}: IEditOrganizationMemberModalProps) {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { updateOrganizationMember, loading } = useUpdateOrganizationMember({
    onCompleted: () => {
      onCancel();
    },
  });

  useEffect(() => {
    if (open) {
      form.setFieldValue("roleId", currentRoleId);
    }
  }, [open]);

  const handleOnFormFinish = (values: any) => {
    updateOrganizationMember({
      organizationId,
      userId,
      organizationRoleId: values.roleId,
    });
  };

  return (
    <Modal
      title={t("editOrganizationRoleModal.title", { userName })}
      closable
      open={open}
      onCancel={onCancel}
      confirmLoading={loading}
      onOk={() => {
        form.submit();
      }}
      okText={t("common.ok")}
      // @ts-ignore
      okButtonProps={{ "data-id": "common.ok" }}
      cancelText={t("common.cancel")}
      // @ts-ignore
      cancelButtonProps={{ "data-id": "common.cancel" }}
      destroyOnClose
      afterClose={() => form.resetFields()}
    >
      <Form form={form} onFinish={handleOnFormFinish}>
        <OrganizationRolesFormItem organizationId={organizationId} />
      </Form>
    </Modal>
  );
}

export default EditOrganizationMemberModal;
