import { ApolloError } from "@apollo/client";
import { useUserLazy } from ".";
import { useUserId } from "../../../shared";
import {
  CreateSupervisorsMutation,
  GetParticipantsOfStudyDocument,
  GetSupervisorAssignmentsOfUserDocument,
  useCreateSupervisorsMutation,
} from "../codegen";

interface IUseCreateSupervisorByIdProps {
  // eslint-disable-next-line no-unused-vars
  onCompleted?: (data: CreateSupervisorsMutation) => void;
  // eslint-disable-next-line no-unused-vars
  onError?: (error: ApolloError) => void;
}

interface ICreateSupervisors {
  participantIds: string[];
  supervisorRoleId: string;
  userId: string;
  studyId: string;
}

export function useCreateSupervisorsById({
  onCompleted = () => {},
  onError = () => {},
}: IUseCreateSupervisorByIdProps) {
  const { userId } = useUserId();
  const { getUser } = useUserLazy();
  const [mutate, rest] = useCreateSupervisorsMutation({
    onCompleted: (data) => {
      if (data.createSupervisors?.returning.find((supervisor) => supervisor.userId === userId)) {
        getUser(userId);
      }
      onCompleted(data);
    },
    onError: (error) => {
      onError(error);
    },
  });

  const createSupervisors = (supervisors: ICreateSupervisors) => {
    mutate({
      variables: {
        assignments: supervisors.participantIds.map((participantId) => ({
          participantId,
          userId: supervisors.userId,
          supervisorRoleId: supervisors.supervisorRoleId,
        })),
      },
      refetchQueries: [
        {
          query: GetSupervisorAssignmentsOfUserDocument,
          variables: { userId: supervisors.userId, studyId: supervisors.studyId },
        },
        { query: GetParticipantsOfStudyDocument, variables: { studyId: supervisors.studyId } },
      ],
    });
  };

  return { createSupervisors, ...rest };
}

export default useCreateSupervisorsById;
