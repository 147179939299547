import { useTranslation } from "react-i18next";
import { useGetLabelingInformationQuery } from "../codegen";

export function useLabelingInformation(studyId: string) {
  const { i18n } = useTranslation();
  const { data, ...rest } = useGetLabelingInformationQuery({
    variables: { studyId, language: i18n.language },
  });
  return { labelingInformation: data?.getLabelingInformationAction, ...rest };
}
