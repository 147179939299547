/* eslint-disable react/require-default-props */
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import { Menu, Layout, Typography, Space, Affix } from "antd";

import React, { ReactNode, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useTabletMode from "../hooks/useTabletMode";
import {
  siderStyle,
  siderHeaderStyle,
  siderMenuStyle,
  siderWidth,
  primaryColor,
  headerHeight,
  collapsedSiderWidthTabletMode,
  collapsedSiderWidth,
} from "../layout/styles";

const { Sider } = Layout;
const { Text } = Typography;

export interface ISiderMenuProps {
  title?: string;
  isLoading?: boolean;
  selectedKeys: string[];
  defaultOpenKeys?: string[];
}

interface ISiderMenuContainerProps extends ISiderMenuProps {
  children?: ReactNode;
  titleLogo?: ReactNode;
  homeRoute?: string;
}

export function SideMenuContainer({
  titleLogo,
  title,
  isLoading,
  selectedKeys,
  defaultOpenKeys,
  homeRoute,
  children,
}: ISiderMenuContainerProps) {
  const isInTabletMode = useTabletMode();
  const [collapsed, setCollapsed] = useState(isInTabletMode);

  useEffect(() => {
    if (isInTabletMode) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  }, [isInTabletMode]);

  const collapsedWidth = isInTabletMode ? collapsedSiderWidthTabletMode : collapsedSiderWidth;

  return (
    <Sider
      data-testId="sider-menu"
      width={siderWidth}
      style={{
        ...siderStyle,
        position: isInTabletMode ? "fixed" : undefined,
        height: isInTabletMode ? "100vh" : undefined,
      }}
      collapsedWidth={collapsedWidth}
      collapsible
      collapsed={collapsed}
      onCollapse={(value: boolean) => setCollapsed(value)}
      trigger={
        <div style={{ fontSize: 16, backgroundColor: primaryColor, color: "white" }}>
          {collapsed ? <RightOutlined /> : <LeftOutlined />}
        </div>
      }
    >
      <Affix
        style={{
          position: "fixed",
          top: headerHeight,
        }}
      >
        <div
          style={{
            width: collapsed ? collapsedWidth : siderWidth,
            transition: "width 0.2s ease-out",
          }}
        >
          <div
            style={{
              ...siderHeaderStyle,
              marginLeft: collapsed ? 0 : siderHeaderStyle.marginLeft,
              textAlign: !titleLogo || collapsed ? "center" : "left",
              transition: "textAlign 0.5s",
            }}
          >
            {homeRoute ? (
              <Link to={homeRoute}>
                <Space align="center" size="middle">
                  {titleLogo}
                  {collapsed ? null : <Text strong>{title}</Text>}
                </Space>
              </Link>
            ) : (
              <Space align="center" size="middle">
                {titleLogo}
                {collapsed ? null : <Text strong>{title}</Text>}
              </Space>
            )}
          </div>

          {!isLoading && (
            <Menu
              style={siderMenuStyle}
              selectedKeys={selectedKeys}
              defaultOpenKeys={defaultOpenKeys}
              theme="light"
              mode="inline"
              onSelect={() => {
                if (isInTabletMode && !collapsed) setCollapsed(true);
              }}
            >
              {children}
            </Menu>
          )}
        </div>
      </Affix>
    </Sider>
  );
}
