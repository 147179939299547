import { Divider, Select, Typography } from "antd";
import DOMPurify from "dompurify";

import React, { useState } from "react";
import { MultiSpecOptionsGroup, MultiSpecVegaVisualizationProps } from "../../../../../services";
import VegaContainer from "./VegaContainer";

const { Option, OptGroup } = Select;
const { Paragraph, Text } = Typography;

const findVegaSpecAndDescription = ({
  optionsGroups,
  selectedOption,
  vegaSpecs,
}: {
  optionsGroups: MultiSpecOptionsGroup[];
  vegaSpecs: { id: string; spec: any }[];
  selectedOption: string;
}) => {
  const option = optionsGroups
    .map((optGroup) => optGroup.options)
    .flat()
    .find((opt) => opt.value === selectedOption);
  return {
    vegaSpec: vegaSpecs.find((vegaSpec) => vegaSpec.id === option?.vegaSpecId)?.spec,
    description: option?.description,
  };
};

/**
 * Allows visualization of different signals with different Vega specifications
 * in one visualization container. A drop-down field is used hereby to switch
 * between the different diagrams
 */
function MultiSpecVegaVisualization({
  vegaData,
  vegaSpecs,
  defaultSelected,
  optionValueDataIndex,
  optionsGroups,
  downloadFileName,
}: MultiSpecVegaVisualizationProps) {
  const [selectedOption, setSelectedOption] = useState<string>(defaultSelected);
  const [selectedVegaSpec, setSelectedVegaSpec] = useState(
    findVegaSpecAndDescription({ optionsGroups, selectedOption: defaultSelected, vegaSpecs }),
  );

  const handleChange = (value: string) => {
    setSelectedOption(value);
    setSelectedVegaSpec(
      findVegaSpecAndDescription({ optionsGroups, selectedOption: value, vegaSpecs }),
    );
    // setTimeout(() => ref.current?.scrollIntoView(), 1);
  };

  const optionsSelector = (
    <Select
      defaultValue={selectedOption}
      style={{ width: 320 }}
      onChange={handleChange}
      dropdownStyle={{ maxHeight: 240, overflowY: "auto" }}
    >
      {optionsGroups.map((optGroup) => (
        <OptGroup key={optGroup.key} label={optGroup.label}>
          {optGroup.options.map((opt) => (
            <Option key={opt.value} value={opt.value}>
              {opt.name}
            </Option>
          ))}
        </OptGroup>
      ))}
    </Select>
  );

  return (
    <>
      {optionsSelector}
      {selectedVegaSpec.description ? (
        <Paragraph style={{ marginTop: 16 }}>
          <Text strong> Description: </Text>
          <span
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(selectedVegaSpec.description),
            }}
          />
        </Paragraph>
      ) : null}
      <Divider />

      <div style={{ minHeight: 526 }}>
        <VegaContainer
          spec={selectedVegaSpec.vegaSpec}
          downloadFileName={downloadFileName}
          data={{
            source_data: vegaData.source_data.filter(
              (dataPoint: { [x: string]: string }) =>
                dataPoint[`${optionValueDataIndex}`] === selectedOption,
            ),
          }}
        />
      </div>
    </>
  );
}

export default MultiSpecVegaVisualization;
