import React, { ReactNode, useMemo } from "react";
import { GetParticipantActionQuery } from "../../services";

const ParticipantContext = React.createContext<
  { participant: GetParticipantActionQuery["getParticipantAction"]["participant"] } | undefined
>(undefined);

interface IParticipantProviderProps {
  participant: GetParticipantActionQuery["getParticipantAction"]["participant"];
  children: ReactNode;
}

function ParticipantProvider({ children, participant }: IParticipantProviderProps) {
  const value = useMemo(() => ({ participant }), [participant]);
  return <ParticipantContext.Provider value={value}>{children}</ParticipantContext.Provider>;
}

function useCachedParticipant() {
  const context = React.useContext(ParticipantContext);
  if (context === undefined) {
    throw new Error("useParticipant must be used within a ParticipantProvider");
  }
  return context;
}

export { ParticipantProvider, useCachedParticipant };
