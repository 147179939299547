import { ApolloError } from "@apollo/client";
import { notification } from "antd";
import { useTranslation } from "react-i18next";

import {
  GetGroupVariablesConfigsDocument,
  GroupConfigsToUpdate,
  UpdateGroupVariableConfigMutation,
  useUpdateGroupVariableConfigMutation,
} from "../codegen";

interface IUseUpdateProfile {
  studyId: string;
  // eslint-disable-next-line no-unused-vars
  onCompleted?: (data: UpdateGroupVariableConfigMutation) => void;
  // eslint-disable-next-line no-unused-vars
  onError?: (error: ApolloError) => void;
}

export function useUpdateGroupVariableConfig({
  studyId,
  onCompleted = () => {},
  onError = () => {},
}: IUseUpdateProfile) {
  const { t } = useTranslation();
  const [mutate, { loading }] = useUpdateGroupVariableConfigMutation({
    onCompleted: (data) => {
      const updatedConfigs = data.updateGroupTherapyVariableConfigsAction;
      if (!updatedConfigs) {
        notification.error({
          message: t("notifications.updateGroupTherapyConfig.error"),
        });
        return;
      }

      notification.success({
        message: t("notifications.updateGroupTherapyConfig.success"),
      });

      onCompleted(data);
    },
    onError: (error) => {
      notification.error({
        message: t("notifications.updateGroupTherapyConfig.error"),
      });
      onError(error);
    },
    refetchQueries: [{ query: GetGroupVariablesConfigsDocument, variables: { studyId } }],
  });

  const updateGroupVariableConfig = (config: GroupConfigsToUpdate[]) => {
    if (config.length > 0) {
      mutate({ variables: { objects: config } });
    } else {
      notification.info({
        message: t("notifications.updateGroupTherapyConfig.noChanges"),
      });
    }
  };

  return { updateGroupVariableConfig, loading };
}

export default useUpdateGroupVariableConfig;
