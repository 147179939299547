import { useTranslation } from "react-i18next";
import { useGetTherapyDesignQuery } from "../codegen";

export interface ICreateParticipant {
  participantId: string;
  therapyId: string;
  therapyStart: any;
  therapyEnd: any;
}

export interface ITherapyDesign {
  id: string;
  title: string;
  therapyElements?: ITherapyElement[];
  description: string;
  version: string;
  createdAt: string;
  updatedAt: string;
}

export interface ITherapyElement {
  id: string;
  therapyDesignId: string;
  title: string;
  description: string;
  therapyVariables: ITherapyVariable[];
  createdAt: string;
  updatedAt: string;
  order: number;
}

export interface IConfigurationLevel {
  id: number;
  groupEdit: boolean;
  therapistEdit: boolean;
  therapistView: boolean;
  participantEdit: boolean;
  participantView: boolean;
}

export interface ITherapyVariable {
  id: string;
  therapyElementId: string;
  order: number;
  displayName: string;
  description: string;
  type: string;
  mandatory: boolean;
  resetAtMidnight: boolean;
  configurationLevel: IConfigurationLevel;
  defaultValue: any;
  validatorProps?: any;
  createdAt: string;
  updatedAt: string;
}

export default function useTherapyDesign(studyId: string) {
  const { i18n } = useTranslation();
  const { data, loading } = useGetTherapyDesignQuery({
    variables: { studyId, language: i18n.language },
  });
  let therapyDesign: ITherapyDesign | undefined;

  if (data?.getTherapyDesignAction) {
    therapyDesign = JSON.parse(data.getTherapyDesignAction.value);
  }

  const getTherapyVariable = (variableId: string) => {
    const elementWithVariable = therapyDesign?.therapyElements?.find(
      (element) =>
        element.therapyVariables.find((variable) => variable.id === variableId) !== undefined,
    );
    return elementWithVariable?.therapyVariables.find((variable) => variable.id === variableId);
  };

  return { therapyDesign, getTherapyVariable, loading };
}
