import { ApolloError } from "@apollo/client";
import { notification } from "antd";
import { useTranslation } from "react-i18next";
import {
  CreateParticipantGroupActionMutation,
  GetGroupVariablesConfigsDocument,
  GetStudyDocument,
  useCreateParticipantGroupActionMutation,
} from "../codegen";

interface IUseCreateParticipantGroupProps {
  // eslint-disable-next-line no-unused-vars
  onCompleted?: (data: CreateParticipantGroupActionMutation) => void;
  // eslint-disable-next-line no-unused-vars
  onError?: (error: ApolloError) => void;
}

export function useCreateParticipantGroup({
  onCompleted = () => {},
  onError = () => {},
}: IUseCreateParticipantGroupProps) {
  const { t } = useTranslation();
  const [mutate, { loading, ...rest }] = useCreateParticipantGroupActionMutation({
    onCompleted: (data) => {
      onCompleted(data);
    },
    onError: (error) => {
      onError(error);
    },
  });

  const createParticipantGroup = ({
    studyId,
    name,
    description,
  }: {
    studyId: string;
    name: string;
    description?: string;
  }) => {
    mutate({
      variables: {
        studyId,
        name,
        description,
      },
      refetchQueries: [
        { query: GetStudyDocument, variables: { id: studyId } },
        { query: GetGroupVariablesConfigsDocument, variables: { studyId } },
      ],
    });
  };

  return { createParticipantGroup, loading, ...rest };
}

export default useCreateParticipantGroup;
