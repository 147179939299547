import { ApolloError } from "@apollo/client";
import { notification } from "antd";
import { useTranslation } from "react-i18next";
import {
  DeleteParticipantGroupMutation,
  GetStudyDocument,
  useDeleteParticipantGroupMutation,
} from "../codegen";

interface IUseDeleteParticipantGroupProps {
  studyId: string;
  // eslint-disable-next-line no-unused-vars
  onCompleted?: (data: DeleteParticipantGroupMutation) => void;
  // eslint-disable-next-line no-unused-vars
  onError?: (error: ApolloError) => void;
}

export function useDeleteParticipantGroup({
  studyId,
  onCompleted = () => {},
  onError = () => {},
}: IUseDeleteParticipantGroupProps) {
  const { t } = useTranslation();
  const [mutate, { loading, ...rest }] = useDeleteParticipantGroupMutation({
    onCompleted: (data) => {
      notification.success({
        message: t("notifications.deleteParticipantsGroup.success"),
      });
      onCompleted(data);
    },
    onError: (error) => {
      notification.error({
        message: t("notifications.deleteParticipantsGroup.error"),
      });
      onError(error);
    },
  });

  const deleteParticipantGroup = (groupId: string) => {
    mutate({
      variables: {
        groupId,
      },
      refetchQueries: [{ query: GetStudyDocument, variables: { id: studyId } }],
    });
  };

  return { deleteParticipantGroup, loading, ...rest };
}

export default useDeleteParticipantGroup;
