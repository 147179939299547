import { ApolloError } from "@apollo/client";
import { notification } from "antd";
import { useTranslation } from "react-i18next";
import { useUserId } from "../../../shared";
import {
  DeleteStudyMutation,
  GetExecutorsDocument,
  GetOrganizationDocument,
  GetUserDocument,
  useCanDeleteStudyQuery,
  useDeleteStudyMutation,
} from "../codegen";

interface IUseDeleteParticipantProps {
  studyId: string;
  organizationId: string;
  // eslint-disable-next-line no-unused-vars
  onCompleted?: (data: DeleteStudyMutation) => void;
  // eslint-disable-next-line no-unused-vars
  onError?: (error: ApolloError) => void;
}

export function useDeleteStudy({
  studyId,
  organizationId,
  onCompleted = () => {},
  onError = () => {},
}: IUseDeleteParticipantProps) {
  const { t } = useTranslation();
  const { userId } = useUserId();
  const { data: canDeleteCheck, loading: canDeleteLoading } = useCanDeleteStudyQuery({
    variables: { studyId },
    fetchPolicy: "network-only",
  });
  const [mutate, { loading: deleteLoading }] = useDeleteStudyMutation({
    onCompleted: (data) => {
      if (data.deleteStudyAction?.success) {
        notification.success({
          message: t("notifications.deleteStudy.success", {
            studyName: data.deleteStudyAction.studyName,
          }),
        });
      } else {
        notification.warning({
          message: t("notifications.deleteStudy.error"),
        });
      }
      onCompleted(data);
    },
    onError: (error) => {
      notification.error({
        message: t("notifications.deleteStudy.error"),
      });
      onError(error);
    },
  });

  const deleteStudy = () => {
    mutate({
      variables: {
        studyId,
      },
      refetchQueries: [
        { query: GetUserDocument, variables: { userId } },
        { query: GetOrganizationDocument, variables: { organizationId } },
        { query: GetExecutorsDocument },
      ],
    });
  };

  return {
    deleteStudy,
    leaveLoading: deleteLoading,
    canDelete: canDeleteCheck?.canDeleteStudyAction,
    canDeleteLoading,
  };
}

export default useDeleteStudy;
