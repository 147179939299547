import { Form, Input, Modal, Select } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import {
  ParticipantsGroupFromQuery,
  useCheckPseudonym,
  useCreateParticipant,
  useCreateSupervisorByMail,
  useExecutorStatistics,
  useSupervisorRoles,
} from "../../../../services";
import { endOfDayString, startOfDayString } from "../../../../utils";
import { TherapyPeriodFormItem, AddSupervisorsFormList } from "../../../../shared";

const { Option } = Select;

export interface ICreateParticipantModalProps {
  studyId: string;
  participantGroups: ParticipantsGroupFromQuery[];
  open: boolean;
  onCancel: () => void;
}

export function CreateParticipantModal({
  studyId,
  participantGroups,
  open,
  onCancel,
}: ICreateParticipantModalProps) {
  const [form] = Form.useForm();
  const { usageStatistics } = useExecutorStatistics();
  const { t } = useTranslation();
  const { createParticipant, loading } = useCreateParticipant({
    studyId,
  });
  const { isPseudonymAvailable } = useCheckPseudonym();

  const { supervisorRoles } = useSupervisorRoles(studyId);

  const { createSupervisor } = useCreateSupervisorByMail({});

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  const handleOnFormFinish = async (values: any) => {
    const createParticipantResult = await createParticipant({
      groupId: values.groupId,
      pseudonym: values.pseudonym,
      therapyStart:
        values.dateRange && values.dateRange[0] ? startOfDayString(values.dateRange[0]) : null,
      therapyEnd:
        values.dateRange && values.dateRange[1] ? endOfDayString(values.dateRange[1]) : null,
    });

    const participantId = createParticipantResult.data?.createParticipantAction?.participantId;

    if (participantId && supervisorRoles.length > 0) {
      values.supervisors?.forEach((email: string) =>
        createSupervisor({
          email,
          participantIds: [participantId],
          studyId,
          supervisorRoleId: supervisorRoles[0].id,
        }),
      );
    }

    form.resetFields();
    onCancel();
  };

  return (
    <Modal
      title={t("participant.createModal.title")}
      closable
      destroyOnClose
      open={open}
      onCancel={handleCancel}
      confirmLoading={loading}
      onOk={() => form.submit()}
      okText={t("common.ok")}
      // @ts-ignore
      okButtonProps={{ "data-id": "participant.createModal.okButton" }}
      cancelText={t("common.cancel")}
      // @ts-ignore
      cancelButtonProps={{ "data-id": "common.cancel" }}
    >
      <Form
        form={form}
        onFinish={handleOnFormFinish}
        layout="vertical"
        initialValues={{
          groupId: participantGroups.length === 1 ? participantGroups[0].id : undefined,
          dateRange: [null, usageStatistics?.expiresAt ? dayjs(usageStatistics.expiresAt) : null],
        }}
      >
        <Form.Item
          name="pseudonym"
          label={t("participant.createModal.pseudonym")}
          validateDebounce={500}
          rules={[
            {
              required: true,
              message: t("participant.createModal.errors.missingPseudonym"),
            },
            () => ({
              async validator(_, value) {
                if (!value?.trim()) {
                  return Promise.resolve();
                }

                const isAvailable = await isPseudonymAvailable({ studyId, pseudonym: value });
                if (isAvailable) {
                  return Promise.resolve();
                }

                return Promise.reject(
                  new Error(t("participant.createModal.errors.pseudonymInUse")),
                );
              },
            }),
          ]}
        >
          <Input onPressEnter={() => form.submit()} />
        </Form.Item>
        <Form.Item
          name="groupId"
          label={t("participant.createModal.participantGroup")}
          rules={[
            {
              required: true,
              message: t("participant.createModal.errors.missingParticipantGroup"),
            },
          ]}
        >
          <Select placeholder={t("participant.createModal.participantGroupPlaceholder")}>
            {participantGroups.map((group) => (
              <Option key={group.id} value={group.id}>
                {group.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <TherapyPeriodFormItem
          name="dateRange"
          form={form}
          endDate={usageStatistics?.expiresAt}
          showStartImmediatelyCheckbox
        />
        <AddSupervisorsFormList name="supervisors" studyId={studyId} />
      </Form>
    </Modal>
  );
}

export default CreateParticipantModal;
