import { ApolloError } from "@apollo/client";
import { notification } from "antd";
import { useTranslation } from "react-i18next";
import { useUserId } from "../../../shared";
import {
  DeleteOrganizationMutation,
  GetUserDocument,
  useDeleteOrganizationMutation,
} from "../codegen";

interface IDeleteOrganizationMemberProps {
  // eslint-disable-next-line no-unused-vars
  onCompleted?: (data: DeleteOrganizationMutation) => void;
  // eslint-disable-next-line no-unused-vars
  onError?: (error: ApolloError) => void;
}

export function useDeleteOrganization({
  onCompleted = () => {},
  onError = () => {},
}: IDeleteOrganizationMemberProps) {
  const { t } = useTranslation();
  const { userId } = useUserId();
  const [mutate, { loading }] = useDeleteOrganizationMutation({
    onCompleted: (data) => {
      if (data.deleteOrganizationAction.success) {
        notification.success({
          message: t("notifications.deleteOrganization.success", {
            organizationName: data.deleteOrganizationAction.organizationName,
          }),
        });
      } else {
        notification.warning({
          message: t("notifications.deleteOrganization.error"),
        });
      }
      onCompleted(data);
    },
    onError: (error) => {
      notification.error({
        message: t("notifications.deleteOrganization.error"),
      });
      onError(error);
    },
  });

  const deleteOrganization = (organizationId: string) => {
    mutate({
      variables: {
        organizationId,
      },
      refetchQueries: [{ query: GetUserDocument, variables: { userId } }],
    });
  };

  return { deleteOrganization, loading };
}

export default useDeleteOrganization;
