/* eslint-disable react/require-default-props */
import { Checkbox, Empty, List, Spin, Tag, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { TeamOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import {
  useParticipants,
  GetSupervisorAssignmentsOfUserQuery,
  GetParticipantsOfStudyQuery,
} from "../../../services/graphql";

const { Text } = Typography;

type Participant = GetSupervisorAssignmentsOfUserQuery["supervisors"][number]["participant"];
type ParticipantOfStudy = GetParticipantsOfStudyQuery["participants"][number];

export interface IUpdateAssignments {
  participantsToAdd: string[];
  participantsToRemove: string[];
}

interface ISupervisorAssignmentListProps {
  studyId: string;
  userId?: string;
  value?: Participant[];
  // eslint-disable-next-line no-unused-vars
  onChange?: (value: Participant[]) => void;
}

export function SupervisorAssignmentList({
  studyId,
  userId,
  value = [],
  onChange,
}: ISupervisorAssignmentListProps) {
  const [checkedParticipants, setCheckedParticipants] = useState<Participant[]>(value);
  const { t } = useTranslation();
  const [checkAll, setCheckAll] = useState<boolean | undefined>(undefined);

  const { participants } = useParticipants(studyId);

  useEffect(() => {
    if (onChange) {
      onChange(checkedParticipants);
    }
  }, [checkedParticipants]);

  const handleCheckBoxChange = (checked: boolean, participant: Participant) => {
    if (checked) {
      if (checkedParticipants) {
        setCheckedParticipants([...checkedParticipants, participant]);
      } else {
        setCheckedParticipants([participant]);
      }
    } else if (checkedParticipants) {
      setCheckedParticipants(
        checkedParticipants.filter(
          (checkedParticipant) => checkedParticipant.id !== participant.id,
        ),
      );
    }
  };

  const handleCheckAll = (checked: boolean) => {
    if (checked) {
      if (participants) setCheckedParticipants(participants);
    } else {
      setCheckedParticipants([]);
    }
    setCheckAll(checked);
  };

  const calculateNumberOfSupervisors = (item: ParticipantOfStudy) => {
    const numOfExisitingAssignments = item.supervisors.length;

    const existingAssignmentWasRemoved =
      item.supervisors.find((supervisor) => supervisor.userId === userId) &&
      !checkedParticipants.find((participant) => participant.id === item.id);
    if (existingAssignmentWasRemoved) return numOfExisitingAssignments - 1;

    const newAssignmentWasAdded =
      !item.supervisors.find((supervisor) => supervisor.userId === userId) &&
      checkedParticipants.find((participant) => participant.id === item.id);
    if (newAssignmentWasAdded) return numOfExisitingAssignments + 1;

    return numOfExisitingAssignments;
  };

  return participants ? (
    <>
      <Text strong>{t("study.supervisors.createModal.participantList.title")}</Text>
      <Checkbox
        data-id="study.supervisors.createModal.participantList.checkAll"
        indeterminate={checkAll === undefined}
        onChange={(e) => handleCheckAll(e.target.checked)}
        checked={checkAll}
        style={{ float: "right" }}
      >
        <Text>{t("study.supervisors.createModal.participantList.checkAll")}</Text>
      </Checkbox>
      <div style={{ width: "100%", maxHeight: 400, overflow: "auto", marginTop: 16 }}>
        <List
          bordered
          dataSource={participants}
          itemLayout="vertical"
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={t("study.supervisors.createModal.participantList.noParticipants")}
              />
            ),
          }}
          renderItem={(item) => (
            <ParticipantListItem
              checked={
                checkedParticipants.find((participant) => participant.id === item.id) && true
              }
              participant={item}
              numOfSupervisors={calculateNumberOfSupervisors(item)}
              key={item.id}
              handleCheckBoxChange={handleCheckBoxChange}
            />
          )}
        />
      </div>
    </>
  ) : (
    <div style={{ width: "100%", textAlign: "center" }}>
      <Spin />
    </div>
  );
}

interface IParticipantListItemProps {
  // eslint-disable-next-line camelcase
  participant: ParticipantOfStudy;
  numOfSupervisors: number;
  // eslint-disable-next-line react/require-default-props
  checked?: boolean;
  // eslint-disable-next-line no-unused-vars
  handleCheckBoxChange: (checked: boolean, participant: Participant) => void;
}

function ParticipantListItem({
  participant,
  numOfSupervisors,
  checked = false,
  handleCheckBoxChange,
}: IParticipantListItemProps) {
  const { t } = useTranslation();
  return (
    <List.Item
      key={participant.id}
      extra={[
        <Tag key="group" icon={<TeamOutlined />} color="default">
          {participant.participantGroup.name}
        </Tag>,

        <Tag key="numOfSupervisors" color={numOfSupervisors > 0 ? "green" : "red"}>
          {`${numOfSupervisors} ${t("common.supervisor", { count: numOfSupervisors })}`}
        </Tag>,
      ]}
    >
      <Checkbox
        checked={checked}
        onChange={(e) => handleCheckBoxChange(e.target.checked, participant)}
      >
        <Text>{participant.pseudonym}</Text>
      </Checkbox>
    </List.Item>
  );
}
