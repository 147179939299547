/* eslint-disable no-unused-vars */
import React from "react";
import { SettingOutlined, UserOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import { Avatar } from "antd";
import { ISiderMenuProps, LinkedMenuItem, Paths, SideMenuContainer } from "../../../shared";

// eslint-disable-next-line no-shadow
export enum UserSiderMenuItemEnum {
  PROFILE = "profile",
  ACCOUNT = "account",
}

export interface IUserSiderProps extends ISiderMenuProps {
  userAvatar?: string;
}

export function UserSider(props: IUserSiderProps) {
  const { userAvatar } = props;
  const avatar = userAvatar && <Avatar shape="circle" src={userAvatar} />;
  const { t } = useTranslation();

  return (
    <SideMenuContainer {...props} titleLogo={avatar}>
      <LinkedMenuItem
        key={UserSiderMenuItemEnum.PROFILE}
        icon={<UserOutlined />}
        linkAddress={Paths.PROFILE}
        itemText={t("user.menu.profile")}
      />

      <LinkedMenuItem
        key={UserSiderMenuItemEnum.ACCOUNT}
        icon={<SettingOutlined />}
        linkAddress={Paths.ACCOUNT}
        itemText={t("user.menu.account")}
      />
    </SideMenuContainer>
  );
}

export default UserSider;
