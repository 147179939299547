import useTherapyDesign from "../../services/graphql/hooks/useTherapyDesign";

interface IUseEditableTherapyElements {
  studyId: string;
  configurationLevel: "THERAPIST" | "GROUP";
}

export function useEditableTherapyElements({
  studyId,
  configurationLevel,
}: IUseEditableTherapyElements) {
  const { therapyDesign, loading } = useTherapyDesign(studyId);
  const editableTherapyElements = therapyDesign?.therapyElements
    ?.filter((therapyElement) =>
      therapyElement.therapyVariables.find((therapyVariable) => {
        if (configurationLevel === "THERAPIST") {
          return therapyVariable.configurationLevel.therapistEdit;
        }
        return therapyVariable.configurationLevel.groupEdit;
      }),
    )
    .sort((a, b) => b.order - a.order);

  return { editableTherapyElements, therapyDesign, loading };
}

export default useEditableTherapyElements;
