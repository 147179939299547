import { ExclamationCircleOutlined } from "@ant-design/icons";
import { App } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { usePermission, ActionEnum, useDeleteStudyMember } from "../../../../services";
import {
  getStudyRoleLabelColor,
  MemberListItem,
  IMemberProps,
  useUserId,
} from "../../../../shared";

import { EditStudyRoleModal } from "./EditStudyRoleModal";

export interface IStudyMemberListItemProps extends IMemberProps {
  studyId: string;
  /** Id of the role that is related to this study member */
  roleId: string;
  /** Name of the role e.g. ADMIN */
  roleName: string;
  /** Id of the user that is related to this study member */
  memberUserId: string;
  // eslint-disable-next-line no-unused-vars
  onRemoveRole?: (id: string) => void;
}

export function StudyMemberListItem(props: IStudyMemberListItemProps) {
  const {
    studyId,
    onRemoveRole = () => {},
    firstName,
    lastName,
    email,
    memberUserId,
    roleName,
    roleId,
  } = props;
  const { modal } = App.useApp();
  const { userId } = useUserId();
  const { t } = useTranslation();
  const [isEditMemberModalVisible, setIsEditMemberModalVisible] = useState(false);
  const { deleteStudyMember } = useDeleteStudyMember({
    onCompleted: (data) => onRemoveRole(data.deleteStudyMember?.id),
  });

  const { permitted: canRemoveMember } = usePermission({
    action: ActionEnum.STUDY_DELETE_MEMBER,
    studyId,
  });
  const { permitted: canEditMember } = usePermission({
    action: ActionEnum.STUDY_EDIT_MEMBER,
    studyId,
  });

  const userName = firstName ? `${firstName} ${lastName}` : email;

  const handleOnEditRole = () => {
    setIsEditMemberModalVisible(true);
  };

  const showDeleteConfirmMember = () => {
    modal.confirm({
      title: t("studyMemberListItem.delete.confirm.title", { userName }),
      icon: <ExclamationCircleOutlined />,
      content: t("studyMemberListItem.delete.confirm.description"),
      okText: t("common.yes"),
      okType: "danger",
      cancelText: t("common.no"),
      onOk() {
        deleteStudyMember({ userId: memberUserId, studyId, userName });
      },
    });
  };

  return (
    <>
      <MemberListItem
        {...props}
        roleName={roleName}
        roleColor={getStudyRoleLabelColor(roleName)}
        disableEdit={!!(userId === memberUserId || !canEditMember)}
        hideRemove={!!(userId === memberUserId || !canRemoveMember)}
        onRemoveRole={showDeleteConfirmMember}
        onEditRole={handleOnEditRole}
      />

      <EditStudyRoleModal
        open={isEditMemberModalVisible}
        onCancel={() => setIsEditMemberModalVisible(false)}
        studyId={studyId}
        userName={firstName ? `${firstName} ${lastName}` : `${email}`}
        currentRoleId={roleId}
        userId={memberUserId}
      />
    </>
  );
}
