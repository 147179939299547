import { useGetParticipantsGroupsQuery } from "../codegen";

export function useParticipantsGroups(studyId: string) {
  const { data, loading } = useGetParticipantsGroupsQuery({
    variables: { studyId },
  });

  return { participantsGroups: data ? data.participantsGroups : [], loading };
}

export default useParticipantsGroups;
