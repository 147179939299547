import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  json: { input: any; output: any };
  jsonb: { input: any; output: any };
  numeric: { input: any; output: any };
  timestamp: { input: any; output: any };
  timestamptz: { input: any; output: any };
  uuid: { input: any; output: any };
};

export type AddOrganizationMemberOutput = {
  __typename?: "AddOrganizationMemberOutput";
  email: Scalars["String"]["output"];
  memberId: Scalars["uuid"]["output"];
  organizationMember?: Maybe<OrganizationMember>;
};

export type AddStudyMemberOutput = {
  __typename?: "AddStudyMemberOutput";
  email: Scalars["String"]["output"];
  memberId: Scalars["String"]["output"];
  studyMember?: Maybe<StudyMember>;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["Boolean"]["input"]>;
  _gt?: InputMaybe<Scalars["Boolean"]["input"]>;
  _gte?: InputMaybe<Scalars["Boolean"]["input"]>;
  _in?: InputMaybe<Array<Scalars["Boolean"]["input"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]["input"]>;
  _lt?: InputMaybe<Scalars["Boolean"]["input"]>;
  _lte?: InputMaybe<Scalars["Boolean"]["input"]>;
  _neq?: InputMaybe<Scalars["Boolean"]["input"]>;
  _nin?: InputMaybe<Array<Scalars["Boolean"]["input"]>>;
};

export type CanDeleteAccountOutput = {
  __typename?: "CanDeleteAccountOutput";
  canDelete: Scalars["Boolean"]["output"];
  organizationsLastOwner: Array<Scalars["String"]["output"]>;
  studiesLastOwner: Array<Scalars["String"]["output"]>;
};

export type CanLeaveOrganizationOutput = {
  __typename?: "CanLeaveOrganizationOutput";
  canLeave: Scalars["Boolean"]["output"];
};

export type CanLeaveStudyOutput = {
  __typename?: "CanLeaveStudyOutput";
  canLeave: Scalars["Boolean"]["output"];
};

export type ConfigsToUpdateOutput = {
  __typename?: "ConfigsToUpdateOutput";
  id: Scalars["uuid"]["output"];
};

export type CreateApiAccountOutput = {
  __typename?: "CreateApiAccountOutput";
  accountId: Scalars["uuid"]["output"];
  clientId?: Maybe<Scalars["String"]["output"]>;
  clientSecret?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["uuid"]["output"];
  password: Scalars["String"]["output"];
};

export type CreateOrganizationInput = {
  address: Scalars["String"]["input"];
  city: Scalars["String"]["input"];
  country: Scalars["String"]["input"];
  department?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  userId: Scalars["uuid"]["input"];
  zip: Scalars["numeric"]["input"];
};

export type CreateOrganizationOutput = {
  __typename?: "CreateOrganizationOutput";
  id: Scalars["uuid"]["output"];
};

export type CreateParticipantInput = {
  groupId: Scalars["uuid"]["input"];
  pseudonym: Scalars["String"]["input"];
  therapyEnd?: InputMaybe<Scalars["String"]["input"]>;
  therapyStart?: InputMaybe<Scalars["String"]["input"]>;
};

export type CreateParticipantOutput = {
  __typename?: "CreateParticipantOutput";
  participant?: Maybe<Participant>;
  participantId: Scalars["uuid"]["output"];
};

export type CreateParticipantsGroupActionOutput = {
  __typename?: "CreateParticipantsGroupActionOutput";
  id: Scalars["uuid"]["output"];
  participantsGroup?: Maybe<ParticipantsGroup>;
};

export type CreateStudyInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  executorId: Scalars["uuid"]["input"];
  name: Scalars["String"]["input"];
  organizationId: Scalars["uuid"]["input"];
  userId: Scalars["uuid"]["input"];
};

export type CreateStudyOutput = {
  __typename?: "CreateStudyOutput";
  id: Scalars["uuid"]["output"];
  name: Scalars["String"]["output"];
};

export type CreateSupervisorOutput = {
  __typename?: "CreateSupervisorOutput";
  id: Scalars["uuid"]["output"];
  participantId: Scalars["uuid"]["output"];
  supervisor?: Maybe<Supervisor>;
  supervisorRoleId: Scalars["uuid"]["output"];
  userId: Scalars["uuid"]["output"];
};

export type DeleteAccountOutput = {
  __typename?: "DeleteAccountOutput";
  success: Scalars["Boolean"]["output"];
};

export type DeleteApiAccountAction = {
  __typename?: "DeleteApiAccountAction";
  accountId: Scalars["uuid"]["output"];
  id: Scalars["uuid"]["output"];
  serviceName: Scalars["String"]["output"];
};

export type DeleteOrganizationOutput = {
  __typename?: "DeleteOrganizationOutput";
  organizationName: Scalars["String"]["output"];
  success: Scalars["Boolean"]["output"];
};

export type DeleteParticipantOutput = {
  __typename?: "DeleteParticipantOutput";
  participantId: Scalars["uuid"]["output"];
  pseudonym?: Maybe<Scalars["String"]["output"]>;
};

export type DeleteStudyGroupOutput = {
  __typename?: "DeleteStudyGroupOutput";
  success: Scalars["Boolean"]["output"];
};

export type DeleteStudyOutput = {
  __typename?: "DeleteStudyOutput";
  studyName: Scalars["String"]["output"];
  success: Scalars["Boolean"]["output"];
};

export type GetExecutorStatisticsOutput = {
  __typename?: "GetExecutorStatisticsOutput";
  expiresAt?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["uuid"]["output"];
  isExpired: Scalars["Boolean"]["output"];
  participantCount: Scalars["numeric"]["output"];
  participantLimit: Scalars["numeric"]["output"];
  participantLimitReached: Scalars["Boolean"]["output"];
};

export type GetGroupsTherapyVariableConfigActionOutput = {
  __typename?: "GetGroupsTherapyVariableConfigActionOutput";
  groupValues: Array<GroupTherapyConfigValue>;
  id: Scalars["String"]["output"];
};

export type GetPersonalKeycloakDataOutput = {
  __typename?: "GetPersonalKeycloakDataOutput";
  email: Scalars["String"]["output"];
  enabled: Scalars["Boolean"]["output"];
  hasuraUser?: Maybe<User>;
  id: Scalars["uuid"]["output"];
  username: Scalars["String"]["output"];
};

export type GetSupervisorsOfParticipantOutput = {
  __typename?: "GetSupervisorsOfParticipantOutput";
  userAvatar?: Maybe<Scalars["String"]["output"]>;
  userEmail: Scalars["String"]["output"];
  userFirstName?: Maybe<Scalars["String"]["output"]>;
  userId: Scalars["uuid"]["output"];
  userLastName?: Maybe<Scalars["String"]["output"]>;
};

export type GetSupervisorsOfStudyOutput = {
  __typename?: "GetSupervisorsOfStudyOutput";
  /** An array relationship */
  supervisors: Array<Supervisor>;
  /** An aggregate relationship */
  supervisors_aggregate: Supervisor_Aggregate;
  userAvatar?: Maybe<Scalars["String"]["output"]>;
  userEmail: Scalars["String"]["output"];
  userFirstName?: Maybe<Scalars["String"]["output"]>;
  userId: Scalars["uuid"]["output"];
  userLastName?: Maybe<Scalars["String"]["output"]>;
};

export type GetSupervisorsOfStudyOutputSupervisorsArgs = {
  distinct_on?: InputMaybe<Array<Supervisor_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Supervisor_Order_By>>;
  where?: InputMaybe<Supervisor_Bool_Exp>;
};

export type GetSupervisorsOfStudyOutputSupervisors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supervisor_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Supervisor_Order_By>>;
  where?: InputMaybe<Supervisor_Bool_Exp>;
};

export type GetTherapyVariableValueOutput = {
  __typename?: "GetTherapyVariableValueOutput";
  createdAt: Scalars["String"]["output"];
  editorId: Scalars["uuid"]["output"];
  participant?: Maybe<Participant>;
  participantId: Scalars["uuid"]["output"];
  therapyVariableId: Scalars["uuid"]["output"];
  variableValue?: Maybe<Scalars["json"]["output"]>;
};

export type GroupConfigsToUpdate = {
  editorId: Scalars["uuid"]["input"];
  id: Scalars["uuid"]["input"];
  variableValue: Scalars["json"]["input"];
};

export type GroupTherapyConfigValue = {
  __typename?: "GroupTherapyConfigValue";
  id: Scalars["String"]["output"];
  participantsGroupId: Scalars["String"]["output"];
  variableId: Scalars["String"]["output"];
  variableValue: Scalars["String"]["output"];
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["Int"]["input"]>;
  _gt?: InputMaybe<Scalars["Int"]["input"]>;
  _gte?: InputMaybe<Scalars["Int"]["input"]>;
  _in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]["input"]>;
  _lt?: InputMaybe<Scalars["Int"]["input"]>;
  _lte?: InputMaybe<Scalars["Int"]["input"]>;
  _neq?: InputMaybe<Scalars["Int"]["input"]>;
  _nin?: InputMaybe<Array<Scalars["Int"]["input"]>>;
};

export type LabelingInformation = {
  __typename?: "LabelingInformation";
  deviceName: Scalars["String"]["output"];
  email: Scalars["String"]["output"];
  isForClinicalInvestigationOnly: Scalars["Boolean"]["output"];
  manufacturer: Scalars["String"]["output"];
  phone: Scalars["String"]["output"];
};

export type LeaveOrganizationOutput = {
  __typename?: "LeaveOrganizationOutput";
  success: Scalars["Boolean"]["output"];
};

export type LeaveStudyOutput = {
  __typename?: "LeaveStudyOutput";
  success: Scalars["Boolean"]["output"];
};

export type MasterUser = {
  __typename?: "MasterUser";
  email: Scalars["String"]["output"];
  id: Scalars["uuid"]["output"];
  user?: Maybe<User>;
};

export type OrganizationMemberAction = {
  __typename?: "OrganizationMemberAction";
  id: Scalars["uuid"]["output"];
  organizationMember?: Maybe<OrganizationMember>;
  userAvatar?: Maybe<Scalars["String"]["output"]>;
  userEmail: Scalars["String"]["output"];
  userFirstName?: Maybe<Scalars["String"]["output"]>;
  userLastName?: Maybe<Scalars["String"]["output"]>;
};

export type ParticipantOutput = {
  __typename?: "ParticipantOutput";
  coupleUrl?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["uuid"]["output"];
  participant?: Maybe<Participant>;
  therapyEnd?: Maybe<Scalars["String"]["output"]>;
  therapyPeriodId?: Maybe<Scalars["uuid"]["output"]>;
  therapyStart?: Maybe<Scalars["String"]["output"]>;
};

export type ParticipantVariableConfigsToUpdate = {
  editorId: Scalars["uuid"]["input"];
  participantId: Scalars["uuid"]["input"];
  therapyVariableId: Scalars["uuid"]["input"];
  variableValue: Scalars["json"]["input"];
};

export type ParticipantVariableConfigsToUpdateOutput = {
  __typename?: "ParticipantVariableConfigsToUpdateOutput";
  createdAt: Scalars["String"]["output"];
  editorId: Scalars["uuid"]["output"];
  participantId: Scalars["uuid"]["output"];
  therapyVariableId: Scalars["uuid"]["output"];
  variableValue: Scalars["json"]["output"];
};

export type SendTestNotificationOutput = {
  __typename?: "SendTestNotificationOutput";
  sentMessage: Scalars["String"]["output"];
  success: Scalars["Boolean"]["output"];
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["String"]["input"]>;
  _gt?: InputMaybe<Scalars["String"]["input"]>;
  _gte?: InputMaybe<Scalars["String"]["input"]>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars["String"]["input"]>;
  _in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars["String"]["input"]>;
  _is_null?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars["String"]["input"]>;
  _lt?: InputMaybe<Scalars["String"]["input"]>;
  _lte?: InputMaybe<Scalars["String"]["input"]>;
  _neq?: InputMaybe<Scalars["String"]["input"]>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars["String"]["input"]>;
  _nin?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars["String"]["input"]>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars["String"]["input"]>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars["String"]["input"]>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars["String"]["input"]>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars["String"]["input"]>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars["String"]["input"]>;
};

export type StudyMemberAction = {
  __typename?: "StudyMemberAction";
  id: Scalars["uuid"]["output"];
  studyMember?: Maybe<StudyMember>;
  userAvatar?: Maybe<Scalars["String"]["output"]>;
  userEmail: Scalars["String"]["output"];
  userFirstName?: Maybe<Scalars["String"]["output"]>;
  userLastName?: Maybe<Scalars["String"]["output"]>;
};

export type TherapyConfigValidationResult = {
  __typename?: "TherapyConfigValidationResult";
  results?: Maybe<Array<ValidationResult>>;
  validationInfoItem?: Maybe<Scalars["String"]["output"]>;
};

export type TherapyDesign = {
  __typename?: "TherapyDesign";
  id: Scalars["uuid"]["output"];
  value: Scalars["json"]["output"];
};

export type TherapyDesignInfo = {
  __typename?: "TherapyDesignInfo";
  description: Scalars["String"]["output"];
  id: Scalars["uuid"]["output"];
  title: Scalars["String"]["output"];
  version: Scalars["String"]["output"];
};

export type TherapyPeriod = {
  end?: InputMaybe<Scalars["String"]["input"]>;
  participantId: Scalars["uuid"]["input"];
  start?: InputMaybe<Scalars["String"]["input"]>;
  therapyPeriodId: Scalars["uuid"]["input"];
};

export type TriggerPasswordUpdateOutput = {
  __typename?: "TriggerPasswordUpdateOutput";
  success: Scalars["Boolean"]["output"];
};

export type UpdateEmailOutput = {
  __typename?: "UpdateEmailOutput";
  email: Scalars["String"]["output"];
  userId: Scalars["uuid"]["output"];
};

export type UpdateParticipantInput = {
  groupId: Scalars["uuid"]["input"];
  id: Scalars["uuid"]["input"];
  pseudonym: Scalars["String"]["input"];
};

export type ValidationResult = {
  __typename?: "ValidationResult";
  errorMessage?: Maybe<Scalars["String"]["output"]>;
  isValid: Scalars["Boolean"]["output"];
  value: Scalars["json"]["output"];
  variableId: Scalars["String"]["output"];
};

export type VariableConfigValue = {
  value: Scalars["json"]["input"];
  variableId: Scalars["String"]["input"];
};

export type VisualizationPage = {
  __typename?: "VisualizationPage";
  children: Array<Scalars["json"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  end: Scalars["String"]["output"];
  id: Scalars["uuid"]["output"];
  start: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
  warning?: Maybe<Scalars["String"]["output"]>;
};

/** columns and relationships of "api_account" */
export type Api_Account = {
  __typename?: "api_account";
  accountId: Scalars["uuid"]["output"];
  createdAt: Scalars["timestamptz"]["output"];
  expiresAt: Scalars["timestamptz"]["output"];
  id: Scalars["uuid"]["output"];
  serviceName: Scalars["String"]["output"];
  /** An object relationship */
  study: Study;
  studyId: Scalars["uuid"]["output"];
  updatedAt: Scalars["timestamptz"]["output"];
};

/** aggregated selection of "api_account" */
export type Api_Account_Aggregate = {
  __typename?: "api_account_aggregate";
  aggregate?: Maybe<Api_Account_Aggregate_Fields>;
  nodes: Array<Api_Account>;
};

export type Api_Account_Aggregate_Bool_Exp = {
  count?: InputMaybe<Api_Account_Aggregate_Bool_Exp_Count>;
};

export type Api_Account_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Api_Account_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<Api_Account_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "api_account" */
export type Api_Account_Aggregate_Fields = {
  __typename?: "api_account_aggregate_fields";
  count: Scalars["Int"]["output"];
  max?: Maybe<Api_Account_Max_Fields>;
  min?: Maybe<Api_Account_Min_Fields>;
};

/** aggregate fields of "api_account" */
export type Api_Account_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Api_Account_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "api_account" */
export type Api_Account_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Api_Account_Max_Order_By>;
  min?: InputMaybe<Api_Account_Min_Order_By>;
};

/** input type for inserting array relation for remote table "api_account" */
export type Api_Account_Arr_Rel_Insert_Input = {
  data: Array<Api_Account_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Api_Account_On_Conflict>;
};

/** Boolean expression to filter rows from the table "api_account". All fields are combined with a logical 'AND'. */
export type Api_Account_Bool_Exp = {
  _and?: InputMaybe<Array<Api_Account_Bool_Exp>>;
  _not?: InputMaybe<Api_Account_Bool_Exp>;
  _or?: InputMaybe<Array<Api_Account_Bool_Exp>>;
  accountId?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  expiresAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  serviceName?: InputMaybe<String_Comparison_Exp>;
  study?: InputMaybe<Study_Bool_Exp>;
  studyId?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "api_account" */
export enum Api_Account_Constraint {
  /** unique or primary key constraint on columns "id" */
  ApiAccountsPkey = "api_accounts_pkey",
}

/** input type for inserting data into table "api_account" */
export type Api_Account_Insert_Input = {
  accountId?: InputMaybe<Scalars["uuid"]["input"]>;
  createdAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  expiresAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  serviceName?: InputMaybe<Scalars["String"]["input"]>;
  study?: InputMaybe<Study_Obj_Rel_Insert_Input>;
  studyId?: InputMaybe<Scalars["uuid"]["input"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** aggregate max on columns */
export type Api_Account_Max_Fields = {
  __typename?: "api_account_max_fields";
  accountId?: Maybe<Scalars["uuid"]["output"]>;
  createdAt?: Maybe<Scalars["timestamptz"]["output"]>;
  expiresAt?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  serviceName?: Maybe<Scalars["String"]["output"]>;
  studyId?: Maybe<Scalars["uuid"]["output"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** order by max() on columns of table "api_account" */
export type Api_Account_Max_Order_By = {
  accountId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  expiresAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  serviceName?: InputMaybe<Order_By>;
  studyId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Api_Account_Min_Fields = {
  __typename?: "api_account_min_fields";
  accountId?: Maybe<Scalars["uuid"]["output"]>;
  createdAt?: Maybe<Scalars["timestamptz"]["output"]>;
  expiresAt?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  serviceName?: Maybe<Scalars["String"]["output"]>;
  studyId?: Maybe<Scalars["uuid"]["output"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** order by min() on columns of table "api_account" */
export type Api_Account_Min_Order_By = {
  accountId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  expiresAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  serviceName?: InputMaybe<Order_By>;
  studyId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "api_account" */
export type Api_Account_Mutation_Response = {
  __typename?: "api_account_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Api_Account>;
};

/** on_conflict condition type for table "api_account" */
export type Api_Account_On_Conflict = {
  constraint: Api_Account_Constraint;
  update_columns?: Array<Api_Account_Update_Column>;
  where?: InputMaybe<Api_Account_Bool_Exp>;
};

/** Ordering options when selecting data from "api_account". */
export type Api_Account_Order_By = {
  accountId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  expiresAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  serviceName?: InputMaybe<Order_By>;
  study?: InputMaybe<Study_Order_By>;
  studyId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: api_account */
export type Api_Account_Pk_Columns_Input = {
  id: Scalars["uuid"]["input"];
};

/** select columns of table "api_account" */
export enum Api_Account_Select_Column {
  /** column name */
  AccountId = "accountId",
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  ExpiresAt = "expiresAt",
  /** column name */
  Id = "id",
  /** column name */
  ServiceName = "serviceName",
  /** column name */
  StudyId = "studyId",
  /** column name */
  UpdatedAt = "updatedAt",
}

/** input type for updating data in table "api_account" */
export type Api_Account_Set_Input = {
  accountId?: InputMaybe<Scalars["uuid"]["input"]>;
  createdAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  expiresAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  serviceName?: InputMaybe<Scalars["String"]["input"]>;
  studyId?: InputMaybe<Scalars["uuid"]["input"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** Streaming cursor of the table "api_account" */
export type Api_Account_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Api_Account_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Api_Account_Stream_Cursor_Value_Input = {
  accountId?: InputMaybe<Scalars["uuid"]["input"]>;
  createdAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  expiresAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  serviceName?: InputMaybe<Scalars["String"]["input"]>;
  studyId?: InputMaybe<Scalars["uuid"]["input"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** update columns of table "api_account" */
export enum Api_Account_Update_Column {
  /** column name */
  AccountId = "accountId",
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  ExpiresAt = "expiresAt",
  /** column name */
  Id = "id",
  /** column name */
  ServiceName = "serviceName",
  /** column name */
  StudyId = "studyId",
  /** column name */
  UpdatedAt = "updatedAt",
}

export type Api_Account_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Api_Account_Set_Input>;
  /** filter the rows which have to be updated */
  where: Api_Account_Bool_Exp;
};

/** columns and relationships of "api_event" */
export type Api_Event = {
  __typename?: "api_event";
  body: Scalars["json"]["output"];
  createdAt: Scalars["timestamptz"]["output"];
  endpoint: Scalars["String"]["output"];
  id: Scalars["uuid"]["output"];
  /** An object relationship */
  user?: Maybe<User>;
  userId?: Maybe<Scalars["uuid"]["output"]>;
};

/** columns and relationships of "api_event" */
export type Api_EventBodyArgs = {
  path?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregated selection of "api_event" */
export type Api_Event_Aggregate = {
  __typename?: "api_event_aggregate";
  aggregate?: Maybe<Api_Event_Aggregate_Fields>;
  nodes: Array<Api_Event>;
};

export type Api_Event_Aggregate_Bool_Exp = {
  count?: InputMaybe<Api_Event_Aggregate_Bool_Exp_Count>;
};

export type Api_Event_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Api_Event_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<Api_Event_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "api_event" */
export type Api_Event_Aggregate_Fields = {
  __typename?: "api_event_aggregate_fields";
  count: Scalars["Int"]["output"];
  max?: Maybe<Api_Event_Max_Fields>;
  min?: Maybe<Api_Event_Min_Fields>;
};

/** aggregate fields of "api_event" */
export type Api_Event_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Api_Event_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "api_event" */
export type Api_Event_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Api_Event_Max_Order_By>;
  min?: InputMaybe<Api_Event_Min_Order_By>;
};

/** input type for inserting array relation for remote table "api_event" */
export type Api_Event_Arr_Rel_Insert_Input = {
  data: Array<Api_Event_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Api_Event_On_Conflict>;
};

/** Boolean expression to filter rows from the table "api_event". All fields are combined with a logical 'AND'. */
export type Api_Event_Bool_Exp = {
  _and?: InputMaybe<Array<Api_Event_Bool_Exp>>;
  _not?: InputMaybe<Api_Event_Bool_Exp>;
  _or?: InputMaybe<Array<Api_Event_Bool_Exp>>;
  body?: InputMaybe<Json_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  endpoint?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  userId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "api_event" */
export enum Api_Event_Constraint {
  /** unique or primary key constraint on columns "id" */
  ApiEventPkey = "api_event_pkey",
}

/** input type for inserting data into table "api_event" */
export type Api_Event_Insert_Input = {
  body?: InputMaybe<Scalars["json"]["input"]>;
  createdAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  endpoint?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars["uuid"]["input"]>;
};

/** aggregate max on columns */
export type Api_Event_Max_Fields = {
  __typename?: "api_event_max_fields";
  createdAt?: Maybe<Scalars["timestamptz"]["output"]>;
  endpoint?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  userId?: Maybe<Scalars["uuid"]["output"]>;
};

/** order by max() on columns of table "api_event" */
export type Api_Event_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  endpoint?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Api_Event_Min_Fields = {
  __typename?: "api_event_min_fields";
  createdAt?: Maybe<Scalars["timestamptz"]["output"]>;
  endpoint?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  userId?: Maybe<Scalars["uuid"]["output"]>;
};

/** order by min() on columns of table "api_event" */
export type Api_Event_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  endpoint?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "api_event" */
export type Api_Event_Mutation_Response = {
  __typename?: "api_event_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Api_Event>;
};

/** on_conflict condition type for table "api_event" */
export type Api_Event_On_Conflict = {
  constraint: Api_Event_Constraint;
  update_columns?: Array<Api_Event_Update_Column>;
  where?: InputMaybe<Api_Event_Bool_Exp>;
};

/** Ordering options when selecting data from "api_event". */
export type Api_Event_Order_By = {
  body?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  endpoint?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: api_event */
export type Api_Event_Pk_Columns_Input = {
  id: Scalars["uuid"]["input"];
};

/** select columns of table "api_event" */
export enum Api_Event_Select_Column {
  /** column name */
  Body = "body",
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  Endpoint = "endpoint",
  /** column name */
  Id = "id",
  /** column name */
  UserId = "userId",
}

/** input type for updating data in table "api_event" */
export type Api_Event_Set_Input = {
  body?: InputMaybe<Scalars["json"]["input"]>;
  createdAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  endpoint?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  userId?: InputMaybe<Scalars["uuid"]["input"]>;
};

/** Streaming cursor of the table "api_event" */
export type Api_Event_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Api_Event_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Api_Event_Stream_Cursor_Value_Input = {
  body?: InputMaybe<Scalars["json"]["input"]>;
  createdAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  endpoint?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  userId?: InputMaybe<Scalars["uuid"]["input"]>;
};

/** update columns of table "api_event" */
export enum Api_Event_Update_Column {
  /** column name */
  Body = "body",
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  Endpoint = "endpoint",
  /** column name */
  Id = "id",
  /** column name */
  UserId = "userId",
}

export type Api_Event_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Api_Event_Set_Input>;
  /** filter the rows which have to be updated */
  where: Api_Event_Bool_Exp;
};

/** columns and relationships of "audit_log" */
export type Audit_Log = {
  __typename?: "audit_log";
  createdAt: Scalars["timestamptz"]["output"];
  id: Scalars["uuid"]["output"];
  userEmail?: Maybe<Scalars["String"]["output"]>;
  userId?: Maybe<Scalars["uuid"]["output"]>;
  userRole?: Maybe<Scalars["String"]["output"]>;
  viaBackend?: Maybe<Scalars["Boolean"]["output"]>;
};

/** aggregated selection of "audit_log" */
export type Audit_Log_Aggregate = {
  __typename?: "audit_log_aggregate";
  aggregate?: Maybe<Audit_Log_Aggregate_Fields>;
  nodes: Array<Audit_Log>;
};

/** aggregate fields of "audit_log" */
export type Audit_Log_Aggregate_Fields = {
  __typename?: "audit_log_aggregate_fields";
  count: Scalars["Int"]["output"];
  max?: Maybe<Audit_Log_Max_Fields>;
  min?: Maybe<Audit_Log_Min_Fields>;
};

/** aggregate fields of "audit_log" */
export type Audit_Log_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Audit_Log_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Boolean expression to filter rows from the table "audit_log". All fields are combined with a logical 'AND'. */
export type Audit_Log_Bool_Exp = {
  _and?: InputMaybe<Array<Audit_Log_Bool_Exp>>;
  _not?: InputMaybe<Audit_Log_Bool_Exp>;
  _or?: InputMaybe<Array<Audit_Log_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  userEmail?: InputMaybe<String_Comparison_Exp>;
  userId?: InputMaybe<Uuid_Comparison_Exp>;
  userRole?: InputMaybe<String_Comparison_Exp>;
  viaBackend?: InputMaybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "audit_log" */
export enum Audit_Log_Constraint {
  /** unique or primary key constraint on columns "id" */
  AuditLogPkey = "audit_log_pkey",
}

/** input type for inserting data into table "audit_log" */
export type Audit_Log_Insert_Input = {
  createdAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  userEmail?: InputMaybe<Scalars["String"]["input"]>;
  userId?: InputMaybe<Scalars["uuid"]["input"]>;
  userRole?: InputMaybe<Scalars["String"]["input"]>;
  viaBackend?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** aggregate max on columns */
export type Audit_Log_Max_Fields = {
  __typename?: "audit_log_max_fields";
  createdAt?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  userEmail?: Maybe<Scalars["String"]["output"]>;
  userId?: Maybe<Scalars["uuid"]["output"]>;
  userRole?: Maybe<Scalars["String"]["output"]>;
};

/** aggregate min on columns */
export type Audit_Log_Min_Fields = {
  __typename?: "audit_log_min_fields";
  createdAt?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  userEmail?: Maybe<Scalars["String"]["output"]>;
  userId?: Maybe<Scalars["uuid"]["output"]>;
  userRole?: Maybe<Scalars["String"]["output"]>;
};

/** response of any mutation on the table "audit_log" */
export type Audit_Log_Mutation_Response = {
  __typename?: "audit_log_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Audit_Log>;
};

/** on_conflict condition type for table "audit_log" */
export type Audit_Log_On_Conflict = {
  constraint: Audit_Log_Constraint;
  update_columns?: Array<Audit_Log_Update_Column>;
  where?: InputMaybe<Audit_Log_Bool_Exp>;
};

/** Ordering options when selecting data from "audit_log". */
export type Audit_Log_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  userEmail?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  userRole?: InputMaybe<Order_By>;
  viaBackend?: InputMaybe<Order_By>;
};

/** primary key columns input for table: audit_log */
export type Audit_Log_Pk_Columns_Input = {
  id: Scalars["uuid"]["input"];
};

/** select columns of table "audit_log" */
export enum Audit_Log_Select_Column {
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  Id = "id",
  /** column name */
  UserEmail = "userEmail",
  /** column name */
  UserId = "userId",
  /** column name */
  UserRole = "userRole",
  /** column name */
  ViaBackend = "viaBackend",
}

/** input type for updating data in table "audit_log" */
export type Audit_Log_Set_Input = {
  createdAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  userEmail?: InputMaybe<Scalars["String"]["input"]>;
  userId?: InputMaybe<Scalars["uuid"]["input"]>;
  userRole?: InputMaybe<Scalars["String"]["input"]>;
  viaBackend?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Streaming cursor of the table "audit_log" */
export type Audit_Log_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Audit_Log_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Audit_Log_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  userEmail?: InputMaybe<Scalars["String"]["input"]>;
  userId?: InputMaybe<Scalars["uuid"]["input"]>;
  userRole?: InputMaybe<Scalars["String"]["input"]>;
  viaBackend?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** update columns of table "audit_log" */
export enum Audit_Log_Update_Column {
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  Id = "id",
  /** column name */
  UserEmail = "userEmail",
  /** column name */
  UserId = "userId",
  /** column name */
  UserRole = "userRole",
  /** column name */
  ViaBackend = "viaBackend",
}

export type Audit_Log_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Audit_Log_Set_Input>;
  /** filter the rows which have to be updated */
  where: Audit_Log_Bool_Exp;
};

/** columns and relationships of "backend_event" */
export type Backend_Event = {
  __typename?: "backend_event";
  createdAt: Scalars["timestamptz"]["output"];
  id: Scalars["uuid"]["output"];
  message: Scalars["String"]["output"];
  type?: Maybe<Scalars["String"]["output"]>;
  userEmail?: Maybe<Scalars["String"]["output"]>;
  userId?: Maybe<Scalars["uuid"]["output"]>;
  userRole?: Maybe<Scalars["String"]["output"]>;
  viaBackend?: Maybe<Scalars["Boolean"]["output"]>;
};

/** aggregated selection of "backend_event" */
export type Backend_Event_Aggregate = {
  __typename?: "backend_event_aggregate";
  aggregate?: Maybe<Backend_Event_Aggregate_Fields>;
  nodes: Array<Backend_Event>;
};

/** aggregate fields of "backend_event" */
export type Backend_Event_Aggregate_Fields = {
  __typename?: "backend_event_aggregate_fields";
  count: Scalars["Int"]["output"];
  max?: Maybe<Backend_Event_Max_Fields>;
  min?: Maybe<Backend_Event_Min_Fields>;
};

/** aggregate fields of "backend_event" */
export type Backend_Event_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Backend_Event_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Boolean expression to filter rows from the table "backend_event". All fields are combined with a logical 'AND'. */
export type Backend_Event_Bool_Exp = {
  _and?: InputMaybe<Array<Backend_Event_Bool_Exp>>;
  _not?: InputMaybe<Backend_Event_Bool_Exp>;
  _or?: InputMaybe<Array<Backend_Event_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  userEmail?: InputMaybe<String_Comparison_Exp>;
  userId?: InputMaybe<Uuid_Comparison_Exp>;
  userRole?: InputMaybe<String_Comparison_Exp>;
  viaBackend?: InputMaybe<Boolean_Comparison_Exp>;
};

/** input type for inserting data into table "backend_event" */
export type Backend_Event_Insert_Input = {
  createdAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
  userEmail?: InputMaybe<Scalars["String"]["input"]>;
  userId?: InputMaybe<Scalars["uuid"]["input"]>;
  userRole?: InputMaybe<Scalars["String"]["input"]>;
  viaBackend?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** aggregate max on columns */
export type Backend_Event_Max_Fields = {
  __typename?: "backend_event_max_fields";
  createdAt?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
  userEmail?: Maybe<Scalars["String"]["output"]>;
  userId?: Maybe<Scalars["uuid"]["output"]>;
  userRole?: Maybe<Scalars["String"]["output"]>;
};

/** aggregate min on columns */
export type Backend_Event_Min_Fields = {
  __typename?: "backend_event_min_fields";
  createdAt?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
  userEmail?: Maybe<Scalars["String"]["output"]>;
  userId?: Maybe<Scalars["uuid"]["output"]>;
  userRole?: Maybe<Scalars["String"]["output"]>;
};

/** response of any mutation on the table "backend_event" */
export type Backend_Event_Mutation_Response = {
  __typename?: "backend_event_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Backend_Event>;
};

/** Ordering options when selecting data from "backend_event". */
export type Backend_Event_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  userEmail?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  userRole?: InputMaybe<Order_By>;
  viaBackend?: InputMaybe<Order_By>;
};

/** select columns of table "backend_event" */
export enum Backend_Event_Select_Column {
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  Id = "id",
  /** column name */
  Message = "message",
  /** column name */
  Type = "type",
  /** column name */
  UserEmail = "userEmail",
  /** column name */
  UserId = "userId",
  /** column name */
  UserRole = "userRole",
  /** column name */
  ViaBackend = "viaBackend",
}

/** input type for updating data in table "backend_event" */
export type Backend_Event_Set_Input = {
  createdAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
  userEmail?: InputMaybe<Scalars["String"]["input"]>;
  userId?: InputMaybe<Scalars["uuid"]["input"]>;
  userRole?: InputMaybe<Scalars["String"]["input"]>;
  viaBackend?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Streaming cursor of the table "backend_event" */
export type Backend_Event_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Backend_Event_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Backend_Event_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
  userEmail?: InputMaybe<Scalars["String"]["input"]>;
  userId?: InputMaybe<Scalars["uuid"]["input"]>;
  userRole?: InputMaybe<Scalars["String"]["input"]>;
  viaBackend?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Backend_Event_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Backend_Event_Set_Input>;
  /** filter the rows which have to be updated */
  where: Backend_Event_Bool_Exp;
};

export type CanDeleteStudyOutput = {
  __typename?: "canDeleteStudyOutput";
  canDelete: Scalars["Boolean"]["output"];
  runningParticipants: Array<Maybe<Scalars["String"]["output"]>>;
};

/** columns and relationships of "contract" */
export type Contract = {
  __typename?: "contract";
  contractType: ContractTypeEnum_Enum;
  /** An object relationship */
  contractTypeEnum: ContractTypeEnum;
  createdAt: Scalars["timestamptz"]["output"];
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  id: Scalars["uuid"]["output"];
  /** An object relationship */
  organization: Organization;
  organizationId: Scalars["uuid"]["output"];
  signerEmail: Scalars["String"]["output"];
  signerFirstName: Scalars["String"]["output"];
  signerId: Scalars["uuid"]["output"];
  signerLastName: Scalars["String"]["output"];
  updatedAt: Scalars["timestamptz"]["output"];
};

/** columns and relationships of "contract_type_enum" */
export type ContractTypeEnum = {
  __typename?: "contractTypeEnum";
  contractType: Scalars["String"]["output"];
  /** An array relationship */
  contracts: Array<Contract>;
  /** An aggregate relationship */
  contracts_aggregate: Contract_Aggregate;
};

/** columns and relationships of "contract_type_enum" */
export type ContractTypeEnumContractsArgs = {
  distinct_on?: InputMaybe<Array<Contract_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Contract_Order_By>>;
  where?: InputMaybe<Contract_Bool_Exp>;
};

/** columns and relationships of "contract_type_enum" */
export type ContractTypeEnumContracts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contract_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Contract_Order_By>>;
  where?: InputMaybe<Contract_Bool_Exp>;
};

/** aggregated selection of "contract_type_enum" */
export type ContractTypeEnum_Aggregate = {
  __typename?: "contractTypeEnum_aggregate";
  aggregate?: Maybe<ContractTypeEnum_Aggregate_Fields>;
  nodes: Array<ContractTypeEnum>;
};

/** aggregate fields of "contract_type_enum" */
export type ContractTypeEnum_Aggregate_Fields = {
  __typename?: "contractTypeEnum_aggregate_fields";
  count: Scalars["Int"]["output"];
  max?: Maybe<ContractTypeEnum_Max_Fields>;
  min?: Maybe<ContractTypeEnum_Min_Fields>;
};

/** aggregate fields of "contract_type_enum" */
export type ContractTypeEnum_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ContractTypeEnum_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Boolean expression to filter rows from the table "contract_type_enum". All fields are combined with a logical 'AND'. */
export type ContractTypeEnum_Bool_Exp = {
  _and?: InputMaybe<Array<ContractTypeEnum_Bool_Exp>>;
  _not?: InputMaybe<ContractTypeEnum_Bool_Exp>;
  _or?: InputMaybe<Array<ContractTypeEnum_Bool_Exp>>;
  contractType?: InputMaybe<String_Comparison_Exp>;
  contracts?: InputMaybe<Contract_Bool_Exp>;
  contracts_aggregate?: InputMaybe<Contract_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "contract_type_enum" */
export enum ContractTypeEnum_Constraint {
  /** unique or primary key constraint on columns "contract_type" */
  ContracttypePkey = "contracttype_pkey",
}

export enum ContractTypeEnum_Enum {
  Dataprocessingagreement = "DATAPROCESSINGAGREEMENT",
  Generalterms = "GENERALTERMS",
  Privacyagreement = "PRIVACYAGREEMENT",
  Termsofservice = "TERMSOFSERVICE",
  Uselicense = "USELICENSE",
}

/** Boolean expression to compare columns of type "contractTypeEnum_enum". All fields are combined with logical 'AND'. */
export type ContractTypeEnum_Enum_Comparison_Exp = {
  _eq?: InputMaybe<ContractTypeEnum_Enum>;
  _in?: InputMaybe<Array<ContractTypeEnum_Enum>>;
  _is_null?: InputMaybe<Scalars["Boolean"]["input"]>;
  _neq?: InputMaybe<ContractTypeEnum_Enum>;
  _nin?: InputMaybe<Array<ContractTypeEnum_Enum>>;
};

/** input type for inserting data into table "contract_type_enum" */
export type ContractTypeEnum_Insert_Input = {
  contractType?: InputMaybe<Scalars["String"]["input"]>;
  contracts?: InputMaybe<Contract_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type ContractTypeEnum_Max_Fields = {
  __typename?: "contractTypeEnum_max_fields";
  contractType?: Maybe<Scalars["String"]["output"]>;
};

/** aggregate min on columns */
export type ContractTypeEnum_Min_Fields = {
  __typename?: "contractTypeEnum_min_fields";
  contractType?: Maybe<Scalars["String"]["output"]>;
};

/** response of any mutation on the table "contract_type_enum" */
export type ContractTypeEnum_Mutation_Response = {
  __typename?: "contractTypeEnum_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<ContractTypeEnum>;
};

/** input type for inserting object relation for remote table "contract_type_enum" */
export type ContractTypeEnum_Obj_Rel_Insert_Input = {
  data: ContractTypeEnum_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<ContractTypeEnum_On_Conflict>;
};

/** on_conflict condition type for table "contract_type_enum" */
export type ContractTypeEnum_On_Conflict = {
  constraint: ContractTypeEnum_Constraint;
  update_columns?: Array<ContractTypeEnum_Update_Column>;
  where?: InputMaybe<ContractTypeEnum_Bool_Exp>;
};

/** Ordering options when selecting data from "contract_type_enum". */
export type ContractTypeEnum_Order_By = {
  contractType?: InputMaybe<Order_By>;
  contracts_aggregate?: InputMaybe<Contract_Aggregate_Order_By>;
};

/** primary key columns input for table: contract_type_enum */
export type ContractTypeEnum_Pk_Columns_Input = {
  contractType: Scalars["String"]["input"];
};

/** select columns of table "contract_type_enum" */
export enum ContractTypeEnum_Select_Column {
  /** column name */
  ContractType = "contractType",
}

/** input type for updating data in table "contract_type_enum" */
export type ContractTypeEnum_Set_Input = {
  contractType?: InputMaybe<Scalars["String"]["input"]>;
};

/** Streaming cursor of the table "contractTypeEnum" */
export type ContractTypeEnum_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ContractTypeEnum_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ContractTypeEnum_Stream_Cursor_Value_Input = {
  contractType?: InputMaybe<Scalars["String"]["input"]>;
};

/** update columns of table "contract_type_enum" */
export enum ContractTypeEnum_Update_Column {
  /** column name */
  ContractType = "contractType",
}

export type ContractTypeEnum_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ContractTypeEnum_Set_Input>;
  /** filter the rows which have to be updated */
  where: ContractTypeEnum_Bool_Exp;
};

/** aggregated selection of "contract" */
export type Contract_Aggregate = {
  __typename?: "contract_aggregate";
  aggregate?: Maybe<Contract_Aggregate_Fields>;
  nodes: Array<Contract>;
};

export type Contract_Aggregate_Bool_Exp = {
  count?: InputMaybe<Contract_Aggregate_Bool_Exp_Count>;
};

export type Contract_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Contract_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<Contract_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "contract" */
export type Contract_Aggregate_Fields = {
  __typename?: "contract_aggregate_fields";
  count: Scalars["Int"]["output"];
  max?: Maybe<Contract_Max_Fields>;
  min?: Maybe<Contract_Min_Fields>;
};

/** aggregate fields of "contract" */
export type Contract_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Contract_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "contract" */
export type Contract_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Contract_Max_Order_By>;
  min?: InputMaybe<Contract_Min_Order_By>;
};

/** input type for inserting array relation for remote table "contract" */
export type Contract_Arr_Rel_Insert_Input = {
  data: Array<Contract_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Contract_On_Conflict>;
};

/** Boolean expression to filter rows from the table "contract". All fields are combined with a logical 'AND'. */
export type Contract_Bool_Exp = {
  _and?: InputMaybe<Array<Contract_Bool_Exp>>;
  _not?: InputMaybe<Contract_Bool_Exp>;
  _or?: InputMaybe<Array<Contract_Bool_Exp>>;
  contractType?: InputMaybe<ContractTypeEnum_Enum_Comparison_Exp>;
  contractTypeEnum?: InputMaybe<ContractTypeEnum_Bool_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  deletedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  organization?: InputMaybe<Organization_Bool_Exp>;
  organizationId?: InputMaybe<Uuid_Comparison_Exp>;
  signerEmail?: InputMaybe<String_Comparison_Exp>;
  signerFirstName?: InputMaybe<String_Comparison_Exp>;
  signerId?: InputMaybe<Uuid_Comparison_Exp>;
  signerLastName?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "contract" */
export enum Contract_Constraint {
  /** unique or primary key constraint on columns "id" */
  ContractPkey = "contract_pkey",
}

/** input type for inserting data into table "contract" */
export type Contract_Insert_Input = {
  contractType?: InputMaybe<ContractTypeEnum_Enum>;
  contractTypeEnum?: InputMaybe<ContractTypeEnum_Obj_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  organization?: InputMaybe<Organization_Obj_Rel_Insert_Input>;
  organizationId?: InputMaybe<Scalars["uuid"]["input"]>;
  signerEmail?: InputMaybe<Scalars["String"]["input"]>;
  signerFirstName?: InputMaybe<Scalars["String"]["input"]>;
  signerId?: InputMaybe<Scalars["uuid"]["input"]>;
  signerLastName?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** aggregate max on columns */
export type Contract_Max_Fields = {
  __typename?: "contract_max_fields";
  createdAt?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  organizationId?: Maybe<Scalars["uuid"]["output"]>;
  signerEmail?: Maybe<Scalars["String"]["output"]>;
  signerFirstName?: Maybe<Scalars["String"]["output"]>;
  signerId?: Maybe<Scalars["uuid"]["output"]>;
  signerLastName?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** order by max() on columns of table "contract" */
export type Contract_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organizationId?: InputMaybe<Order_By>;
  signerEmail?: InputMaybe<Order_By>;
  signerFirstName?: InputMaybe<Order_By>;
  signerId?: InputMaybe<Order_By>;
  signerLastName?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Contract_Min_Fields = {
  __typename?: "contract_min_fields";
  createdAt?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  organizationId?: Maybe<Scalars["uuid"]["output"]>;
  signerEmail?: Maybe<Scalars["String"]["output"]>;
  signerFirstName?: Maybe<Scalars["String"]["output"]>;
  signerId?: Maybe<Scalars["uuid"]["output"]>;
  signerLastName?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** order by min() on columns of table "contract" */
export type Contract_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organizationId?: InputMaybe<Order_By>;
  signerEmail?: InputMaybe<Order_By>;
  signerFirstName?: InputMaybe<Order_By>;
  signerId?: InputMaybe<Order_By>;
  signerLastName?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "contract" */
export type Contract_Mutation_Response = {
  __typename?: "contract_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Contract>;
};

/** on_conflict condition type for table "contract" */
export type Contract_On_Conflict = {
  constraint: Contract_Constraint;
  update_columns?: Array<Contract_Update_Column>;
  where?: InputMaybe<Contract_Bool_Exp>;
};

/** Ordering options when selecting data from "contract". */
export type Contract_Order_By = {
  contractType?: InputMaybe<Order_By>;
  contractTypeEnum?: InputMaybe<ContractTypeEnum_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organization_Order_By>;
  organizationId?: InputMaybe<Order_By>;
  signerEmail?: InputMaybe<Order_By>;
  signerFirstName?: InputMaybe<Order_By>;
  signerId?: InputMaybe<Order_By>;
  signerLastName?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: contract */
export type Contract_Pk_Columns_Input = {
  id: Scalars["uuid"]["input"];
};

/** select columns of table "contract" */
export enum Contract_Select_Column {
  /** column name */
  ContractType = "contractType",
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  Id = "id",
  /** column name */
  OrganizationId = "organizationId",
  /** column name */
  SignerEmail = "signerEmail",
  /** column name */
  SignerFirstName = "signerFirstName",
  /** column name */
  SignerId = "signerId",
  /** column name */
  SignerLastName = "signerLastName",
  /** column name */
  UpdatedAt = "updatedAt",
}

/** input type for updating data in table "contract" */
export type Contract_Set_Input = {
  contractType?: InputMaybe<ContractTypeEnum_Enum>;
  createdAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  organizationId?: InputMaybe<Scalars["uuid"]["input"]>;
  signerEmail?: InputMaybe<Scalars["String"]["input"]>;
  signerFirstName?: InputMaybe<Scalars["String"]["input"]>;
  signerId?: InputMaybe<Scalars["uuid"]["input"]>;
  signerLastName?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** Streaming cursor of the table "contract" */
export type Contract_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Contract_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Contract_Stream_Cursor_Value_Input = {
  contractType?: InputMaybe<ContractTypeEnum_Enum>;
  createdAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  organizationId?: InputMaybe<Scalars["uuid"]["input"]>;
  signerEmail?: InputMaybe<Scalars["String"]["input"]>;
  signerFirstName?: InputMaybe<Scalars["String"]["input"]>;
  signerId?: InputMaybe<Scalars["uuid"]["input"]>;
  signerLastName?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** update columns of table "contract" */
export enum Contract_Update_Column {
  /** column name */
  ContractType = "contractType",
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  Id = "id",
  /** column name */
  OrganizationId = "organizationId",
  /** column name */
  SignerEmail = "signerEmail",
  /** column name */
  SignerFirstName = "signerFirstName",
  /** column name */
  SignerId = "signerId",
  /** column name */
  SignerLastName = "signerLastName",
  /** column name */
  UpdatedAt = "updatedAt",
}

export type Contract_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Contract_Set_Input>;
  /** filter the rows which have to be updated */
  where: Contract_Bool_Exp;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = "ASC",
  /** descending ordering of the cursor */
  Desc = "DESC",
}

/** columns and relationships of "database_event" */
export type Database_Event = {
  __typename?: "database_event";
  after?: Maybe<Scalars["json"]["output"]>;
  before?: Maybe<Scalars["json"]["output"]>;
  createdAt: Scalars["timestamptz"]["output"];
  id: Scalars["uuid"]["output"];
  operationName?: Maybe<Scalars["String"]["output"]>;
  tableName?: Maybe<Scalars["String"]["output"]>;
  userEmail?: Maybe<Scalars["String"]["output"]>;
  userId?: Maybe<Scalars["uuid"]["output"]>;
  userRole?: Maybe<Scalars["String"]["output"]>;
  viaBackend?: Maybe<Scalars["Boolean"]["output"]>;
};

/** columns and relationships of "database_event" */
export type Database_EventAfterArgs = {
  path?: InputMaybe<Scalars["String"]["input"]>;
};

/** columns and relationships of "database_event" */
export type Database_EventBeforeArgs = {
  path?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregated selection of "database_event" */
export type Database_Event_Aggregate = {
  __typename?: "database_event_aggregate";
  aggregate?: Maybe<Database_Event_Aggregate_Fields>;
  nodes: Array<Database_Event>;
};

/** aggregate fields of "database_event" */
export type Database_Event_Aggregate_Fields = {
  __typename?: "database_event_aggregate_fields";
  count: Scalars["Int"]["output"];
  max?: Maybe<Database_Event_Max_Fields>;
  min?: Maybe<Database_Event_Min_Fields>;
};

/** aggregate fields of "database_event" */
export type Database_Event_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Database_Event_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Boolean expression to filter rows from the table "database_event". All fields are combined with a logical 'AND'. */
export type Database_Event_Bool_Exp = {
  _and?: InputMaybe<Array<Database_Event_Bool_Exp>>;
  _not?: InputMaybe<Database_Event_Bool_Exp>;
  _or?: InputMaybe<Array<Database_Event_Bool_Exp>>;
  after?: InputMaybe<Json_Comparison_Exp>;
  before?: InputMaybe<Json_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  operationName?: InputMaybe<String_Comparison_Exp>;
  tableName?: InputMaybe<String_Comparison_Exp>;
  userEmail?: InputMaybe<String_Comparison_Exp>;
  userId?: InputMaybe<Uuid_Comparison_Exp>;
  userRole?: InputMaybe<String_Comparison_Exp>;
  viaBackend?: InputMaybe<Boolean_Comparison_Exp>;
};

/** input type for inserting data into table "database_event" */
export type Database_Event_Insert_Input = {
  after?: InputMaybe<Scalars["json"]["input"]>;
  before?: InputMaybe<Scalars["json"]["input"]>;
  createdAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  operationName?: InputMaybe<Scalars["String"]["input"]>;
  tableName?: InputMaybe<Scalars["String"]["input"]>;
  userEmail?: InputMaybe<Scalars["String"]["input"]>;
  userId?: InputMaybe<Scalars["uuid"]["input"]>;
  userRole?: InputMaybe<Scalars["String"]["input"]>;
  viaBackend?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** aggregate max on columns */
export type Database_Event_Max_Fields = {
  __typename?: "database_event_max_fields";
  createdAt?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  operationName?: Maybe<Scalars["String"]["output"]>;
  tableName?: Maybe<Scalars["String"]["output"]>;
  userEmail?: Maybe<Scalars["String"]["output"]>;
  userId?: Maybe<Scalars["uuid"]["output"]>;
  userRole?: Maybe<Scalars["String"]["output"]>;
};

/** aggregate min on columns */
export type Database_Event_Min_Fields = {
  __typename?: "database_event_min_fields";
  createdAt?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  operationName?: Maybe<Scalars["String"]["output"]>;
  tableName?: Maybe<Scalars["String"]["output"]>;
  userEmail?: Maybe<Scalars["String"]["output"]>;
  userId?: Maybe<Scalars["uuid"]["output"]>;
  userRole?: Maybe<Scalars["String"]["output"]>;
};

/** response of any mutation on the table "database_event" */
export type Database_Event_Mutation_Response = {
  __typename?: "database_event_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Database_Event>;
};

/** Ordering options when selecting data from "database_event". */
export type Database_Event_Order_By = {
  after?: InputMaybe<Order_By>;
  before?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  operationName?: InputMaybe<Order_By>;
  tableName?: InputMaybe<Order_By>;
  userEmail?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  userRole?: InputMaybe<Order_By>;
  viaBackend?: InputMaybe<Order_By>;
};

/** select columns of table "database_event" */
export enum Database_Event_Select_Column {
  /** column name */
  After = "after",
  /** column name */
  Before = "before",
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  Id = "id",
  /** column name */
  OperationName = "operationName",
  /** column name */
  TableName = "tableName",
  /** column name */
  UserEmail = "userEmail",
  /** column name */
  UserId = "userId",
  /** column name */
  UserRole = "userRole",
  /** column name */
  ViaBackend = "viaBackend",
}

/** input type for updating data in table "database_event" */
export type Database_Event_Set_Input = {
  after?: InputMaybe<Scalars["json"]["input"]>;
  before?: InputMaybe<Scalars["json"]["input"]>;
  createdAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  operationName?: InputMaybe<Scalars["String"]["input"]>;
  tableName?: InputMaybe<Scalars["String"]["input"]>;
  userEmail?: InputMaybe<Scalars["String"]["input"]>;
  userId?: InputMaybe<Scalars["uuid"]["input"]>;
  userRole?: InputMaybe<Scalars["String"]["input"]>;
  viaBackend?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Streaming cursor of the table "database_event" */
export type Database_Event_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Database_Event_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Database_Event_Stream_Cursor_Value_Input = {
  after?: InputMaybe<Scalars["json"]["input"]>;
  before?: InputMaybe<Scalars["json"]["input"]>;
  createdAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  operationName?: InputMaybe<Scalars["String"]["input"]>;
  tableName?: InputMaybe<Scalars["String"]["input"]>;
  userEmail?: InputMaybe<Scalars["String"]["input"]>;
  userId?: InputMaybe<Scalars["uuid"]["input"]>;
  userRole?: InputMaybe<Scalars["String"]["input"]>;
  viaBackend?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Database_Event_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Database_Event_Set_Input>;
  /** filter the rows which have to be updated */
  where: Database_Event_Bool_Exp;
};

/** columns and relationships of "executor" */
export type Executor = {
  __typename?: "executor";
  createdAt: Scalars["timestamptz"]["output"];
  deletedAt?: Maybe<Scalars["timestamp"]["output"]>;
  executorUrl: Scalars["String"]["output"];
  executorUrlExternal: Scalars["String"]["output"];
  expiresAt?: Maybe<Scalars["timestamptz"]["output"]>;
  hidden: Scalars["Boolean"]["output"];
  id: Scalars["uuid"]["output"];
  name: Scalars["String"]["output"];
  /** An object relationship */
  organization?: Maybe<Organization>;
  organizationId?: Maybe<Scalars["uuid"]["output"]>;
  participantLimit: Scalars["Int"]["output"];
  /** An array relationship */
  studies: Array<Study>;
  /** An aggregate relationship */
  studies_aggregate: Study_Aggregate;
  therapyDesignId: Scalars["uuid"]["output"];
  updatedAt: Scalars["timestamptz"]["output"];
};

/** columns and relationships of "executor" */
export type ExecutorStudiesArgs = {
  distinct_on?: InputMaybe<Array<Study_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Study_Order_By>>;
  where?: InputMaybe<Study_Bool_Exp>;
};

/** columns and relationships of "executor" */
export type ExecutorStudies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Study_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Study_Order_By>>;
  where?: InputMaybe<Study_Bool_Exp>;
};

/** aggregated selection of "executor" */
export type Executor_Aggregate = {
  __typename?: "executor_aggregate";
  aggregate?: Maybe<Executor_Aggregate_Fields>;
  nodes: Array<Executor>;
};

/** aggregate fields of "executor" */
export type Executor_Aggregate_Fields = {
  __typename?: "executor_aggregate_fields";
  avg?: Maybe<Executor_Avg_Fields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<Executor_Max_Fields>;
  min?: Maybe<Executor_Min_Fields>;
  stddev?: Maybe<Executor_Stddev_Fields>;
  stddev_pop?: Maybe<Executor_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Executor_Stddev_Samp_Fields>;
  sum?: Maybe<Executor_Sum_Fields>;
  var_pop?: Maybe<Executor_Var_Pop_Fields>;
  var_samp?: Maybe<Executor_Var_Samp_Fields>;
  variance?: Maybe<Executor_Variance_Fields>;
};

/** aggregate fields of "executor" */
export type Executor_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Executor_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** aggregate avg on columns */
export type Executor_Avg_Fields = {
  __typename?: "executor_avg_fields";
  participantLimit?: Maybe<Scalars["Float"]["output"]>;
};

/** Boolean expression to filter rows from the table "executor". All fields are combined with a logical 'AND'. */
export type Executor_Bool_Exp = {
  _and?: InputMaybe<Array<Executor_Bool_Exp>>;
  _not?: InputMaybe<Executor_Bool_Exp>;
  _or?: InputMaybe<Array<Executor_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  deletedAt?: InputMaybe<Timestamp_Comparison_Exp>;
  executorUrl?: InputMaybe<String_Comparison_Exp>;
  executorUrlExternal?: InputMaybe<String_Comparison_Exp>;
  expiresAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  hidden?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organization?: InputMaybe<Organization_Bool_Exp>;
  organizationId?: InputMaybe<Uuid_Comparison_Exp>;
  participantLimit?: InputMaybe<Int_Comparison_Exp>;
  studies?: InputMaybe<Study_Bool_Exp>;
  studies_aggregate?: InputMaybe<Study_Aggregate_Bool_Exp>;
  therapyDesignId?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "executor" */
export enum Executor_Constraint {
  /** unique or primary key constraint on columns "id" */
  ExecutorLookupPkey = "executor_lookup_pkey",
}

/** input type for incrementing numeric columns in table "executor" */
export type Executor_Inc_Input = {
  participantLimit?: InputMaybe<Scalars["Int"]["input"]>;
};

/** input type for inserting data into table "executor" */
export type Executor_Insert_Input = {
  createdAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamp"]["input"]>;
  executorUrl?: InputMaybe<Scalars["String"]["input"]>;
  executorUrlExternal?: InputMaybe<Scalars["String"]["input"]>;
  expiresAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  hidden?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  organization?: InputMaybe<Organization_Obj_Rel_Insert_Input>;
  organizationId?: InputMaybe<Scalars["uuid"]["input"]>;
  participantLimit?: InputMaybe<Scalars["Int"]["input"]>;
  studies?: InputMaybe<Study_Arr_Rel_Insert_Input>;
  therapyDesignId?: InputMaybe<Scalars["uuid"]["input"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** aggregate max on columns */
export type Executor_Max_Fields = {
  __typename?: "executor_max_fields";
  createdAt?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamp"]["output"]>;
  executorUrl?: Maybe<Scalars["String"]["output"]>;
  executorUrlExternal?: Maybe<Scalars["String"]["output"]>;
  expiresAt?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  organizationId?: Maybe<Scalars["uuid"]["output"]>;
  participantLimit?: Maybe<Scalars["Int"]["output"]>;
  therapyDesignId?: Maybe<Scalars["uuid"]["output"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** aggregate min on columns */
export type Executor_Min_Fields = {
  __typename?: "executor_min_fields";
  createdAt?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamp"]["output"]>;
  executorUrl?: Maybe<Scalars["String"]["output"]>;
  executorUrlExternal?: Maybe<Scalars["String"]["output"]>;
  expiresAt?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  organizationId?: Maybe<Scalars["uuid"]["output"]>;
  participantLimit?: Maybe<Scalars["Int"]["output"]>;
  therapyDesignId?: Maybe<Scalars["uuid"]["output"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** response of any mutation on the table "executor" */
export type Executor_Mutation_Response = {
  __typename?: "executor_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Executor>;
};

/** input type for inserting object relation for remote table "executor" */
export type Executor_Obj_Rel_Insert_Input = {
  data: Executor_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Executor_On_Conflict>;
};

/** on_conflict condition type for table "executor" */
export type Executor_On_Conflict = {
  constraint: Executor_Constraint;
  update_columns?: Array<Executor_Update_Column>;
  where?: InputMaybe<Executor_Bool_Exp>;
};

/** Ordering options when selecting data from "executor". */
export type Executor_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  executorUrl?: InputMaybe<Order_By>;
  executorUrlExternal?: InputMaybe<Order_By>;
  expiresAt?: InputMaybe<Order_By>;
  hidden?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organization_Order_By>;
  organizationId?: InputMaybe<Order_By>;
  participantLimit?: InputMaybe<Order_By>;
  studies_aggregate?: InputMaybe<Study_Aggregate_Order_By>;
  therapyDesignId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: executor */
export type Executor_Pk_Columns_Input = {
  id: Scalars["uuid"]["input"];
};

/** select columns of table "executor" */
export enum Executor_Select_Column {
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  ExecutorUrl = "executorUrl",
  /** column name */
  ExecutorUrlExternal = "executorUrlExternal",
  /** column name */
  ExpiresAt = "expiresAt",
  /** column name */
  Hidden = "hidden",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  OrganizationId = "organizationId",
  /** column name */
  ParticipantLimit = "participantLimit",
  /** column name */
  TherapyDesignId = "therapyDesignId",
  /** column name */
  UpdatedAt = "updatedAt",
}

/** input type for updating data in table "executor" */
export type Executor_Set_Input = {
  createdAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamp"]["input"]>;
  executorUrl?: InputMaybe<Scalars["String"]["input"]>;
  executorUrlExternal?: InputMaybe<Scalars["String"]["input"]>;
  expiresAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  hidden?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["uuid"]["input"]>;
  participantLimit?: InputMaybe<Scalars["Int"]["input"]>;
  therapyDesignId?: InputMaybe<Scalars["uuid"]["input"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** aggregate stddev on columns */
export type Executor_Stddev_Fields = {
  __typename?: "executor_stddev_fields";
  participantLimit?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddev_pop on columns */
export type Executor_Stddev_Pop_Fields = {
  __typename?: "executor_stddev_pop_fields";
  participantLimit?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddev_samp on columns */
export type Executor_Stddev_Samp_Fields = {
  __typename?: "executor_stddev_samp_fields";
  participantLimit?: Maybe<Scalars["Float"]["output"]>;
};

/** Streaming cursor of the table "executor" */
export type Executor_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Executor_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Executor_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamp"]["input"]>;
  executorUrl?: InputMaybe<Scalars["String"]["input"]>;
  executorUrlExternal?: InputMaybe<Scalars["String"]["input"]>;
  expiresAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  hidden?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["uuid"]["input"]>;
  participantLimit?: InputMaybe<Scalars["Int"]["input"]>;
  therapyDesignId?: InputMaybe<Scalars["uuid"]["input"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** aggregate sum on columns */
export type Executor_Sum_Fields = {
  __typename?: "executor_sum_fields";
  participantLimit?: Maybe<Scalars["Int"]["output"]>;
};

/** update columns of table "executor" */
export enum Executor_Update_Column {
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  ExecutorUrl = "executorUrl",
  /** column name */
  ExecutorUrlExternal = "executorUrlExternal",
  /** column name */
  ExpiresAt = "expiresAt",
  /** column name */
  Hidden = "hidden",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  OrganizationId = "organizationId",
  /** column name */
  ParticipantLimit = "participantLimit",
  /** column name */
  TherapyDesignId = "therapyDesignId",
  /** column name */
  UpdatedAt = "updatedAt",
}

export type Executor_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Executor_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Executor_Set_Input>;
  /** filter the rows which have to be updated */
  where: Executor_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Executor_Var_Pop_Fields = {
  __typename?: "executor_var_pop_fields";
  participantLimit?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate var_samp on columns */
export type Executor_Var_Samp_Fields = {
  __typename?: "executor_var_samp_fields";
  participantLimit?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate variance on columns */
export type Executor_Variance_Fields = {
  __typename?: "executor_variance_fields";
  participantLimit?: Maybe<Scalars["Float"]["output"]>;
};

/** columns and relationships of "group_therapy_variable_config" */
export type GroupTherapyVariableConfig = {
  __typename?: "groupTherapyVariableConfig";
  changedByUserId: Scalars["uuid"]["output"];
  createdAt: Scalars["timestamptz"]["output"];
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  id: Scalars["uuid"]["output"];
  /** An object relationship */
  participantsGroup: ParticipantsGroup;
  participantsGroupId: Scalars["uuid"]["output"];
  updatedAt: Scalars["timestamptz"]["output"];
  variableId: Scalars["uuid"]["output"];
  variableValue: Scalars["jsonb"]["output"];
};

/** columns and relationships of "group_therapy_variable_config" */
export type GroupTherapyVariableConfigVariableValueArgs = {
  path?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregated selection of "group_therapy_variable_config" */
export type GroupTherapyVariableConfig_Aggregate = {
  __typename?: "groupTherapyVariableConfig_aggregate";
  aggregate?: Maybe<GroupTherapyVariableConfig_Aggregate_Fields>;
  nodes: Array<GroupTherapyVariableConfig>;
};

export type GroupTherapyVariableConfig_Aggregate_Bool_Exp = {
  count?: InputMaybe<GroupTherapyVariableConfig_Aggregate_Bool_Exp_Count>;
};

export type GroupTherapyVariableConfig_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<GroupTherapyVariableConfig_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<GroupTherapyVariableConfig_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "group_therapy_variable_config" */
export type GroupTherapyVariableConfig_Aggregate_Fields = {
  __typename?: "groupTherapyVariableConfig_aggregate_fields";
  count: Scalars["Int"]["output"];
  max?: Maybe<GroupTherapyVariableConfig_Max_Fields>;
  min?: Maybe<GroupTherapyVariableConfig_Min_Fields>;
};

/** aggregate fields of "group_therapy_variable_config" */
export type GroupTherapyVariableConfig_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<GroupTherapyVariableConfig_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "group_therapy_variable_config" */
export type GroupTherapyVariableConfig_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<GroupTherapyVariableConfig_Max_Order_By>;
  min?: InputMaybe<GroupTherapyVariableConfig_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type GroupTherapyVariableConfig_Append_Input = {
  variableValue?: InputMaybe<Scalars["jsonb"]["input"]>;
};

/** input type for inserting array relation for remote table "group_therapy_variable_config" */
export type GroupTherapyVariableConfig_Arr_Rel_Insert_Input = {
  data: Array<GroupTherapyVariableConfig_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<GroupTherapyVariableConfig_On_Conflict>;
};

/** Boolean expression to filter rows from the table "group_therapy_variable_config". All fields are combined with a logical 'AND'. */
export type GroupTherapyVariableConfig_Bool_Exp = {
  _and?: InputMaybe<Array<GroupTherapyVariableConfig_Bool_Exp>>;
  _not?: InputMaybe<GroupTherapyVariableConfig_Bool_Exp>;
  _or?: InputMaybe<Array<GroupTherapyVariableConfig_Bool_Exp>>;
  changedByUserId?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  deletedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  participantsGroup?: InputMaybe<ParticipantsGroup_Bool_Exp>;
  participantsGroupId?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  variableId?: InputMaybe<Uuid_Comparison_Exp>;
  variableValue?: InputMaybe<Jsonb_Comparison_Exp>;
};

/** unique or primary key constraints on table "group_therapy_variable_config" */
export enum GroupTherapyVariableConfig_Constraint {
  /** unique or primary key constraint on columns "id" */
  GroupTherapyVariableConfigIdKey = "group_therapy_variable_config_id_key",
  /** unique or primary key constraint on columns "variable_id", "participants_group_id" */
  ParticipantsGroupVariablePkey = "participants_group_variable_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type GroupTherapyVariableConfig_Delete_At_Path_Input = {
  variableValue?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type GroupTherapyVariableConfig_Delete_Elem_Input = {
  variableValue?: InputMaybe<Scalars["Int"]["input"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type GroupTherapyVariableConfig_Delete_Key_Input = {
  variableValue?: InputMaybe<Scalars["String"]["input"]>;
};

/** input type for inserting data into table "group_therapy_variable_config" */
export type GroupTherapyVariableConfig_Insert_Input = {
  changedByUserId?: InputMaybe<Scalars["uuid"]["input"]>;
  createdAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  participantsGroup?: InputMaybe<ParticipantsGroup_Obj_Rel_Insert_Input>;
  participantsGroupId?: InputMaybe<Scalars["uuid"]["input"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  variableId?: InputMaybe<Scalars["uuid"]["input"]>;
  variableValue?: InputMaybe<Scalars["jsonb"]["input"]>;
};

/** aggregate max on columns */
export type GroupTherapyVariableConfig_Max_Fields = {
  __typename?: "groupTherapyVariableConfig_max_fields";
  changedByUserId?: Maybe<Scalars["uuid"]["output"]>;
  createdAt?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  participantsGroupId?: Maybe<Scalars["uuid"]["output"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  variableId?: Maybe<Scalars["uuid"]["output"]>;
};

/** order by max() on columns of table "group_therapy_variable_config" */
export type GroupTherapyVariableConfig_Max_Order_By = {
  changedByUserId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  participantsGroupId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  variableId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type GroupTherapyVariableConfig_Min_Fields = {
  __typename?: "groupTherapyVariableConfig_min_fields";
  changedByUserId?: Maybe<Scalars["uuid"]["output"]>;
  createdAt?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  participantsGroupId?: Maybe<Scalars["uuid"]["output"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  variableId?: Maybe<Scalars["uuid"]["output"]>;
};

/** order by min() on columns of table "group_therapy_variable_config" */
export type GroupTherapyVariableConfig_Min_Order_By = {
  changedByUserId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  participantsGroupId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  variableId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "group_therapy_variable_config" */
export type GroupTherapyVariableConfig_Mutation_Response = {
  __typename?: "groupTherapyVariableConfig_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<GroupTherapyVariableConfig>;
};

/** on_conflict condition type for table "group_therapy_variable_config" */
export type GroupTherapyVariableConfig_On_Conflict = {
  constraint: GroupTherapyVariableConfig_Constraint;
  update_columns?: Array<GroupTherapyVariableConfig_Update_Column>;
  where?: InputMaybe<GroupTherapyVariableConfig_Bool_Exp>;
};

/** Ordering options when selecting data from "group_therapy_variable_config". */
export type GroupTherapyVariableConfig_Order_By = {
  changedByUserId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  participantsGroup?: InputMaybe<ParticipantsGroup_Order_By>;
  participantsGroupId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  variableId?: InputMaybe<Order_By>;
  variableValue?: InputMaybe<Order_By>;
};

/** primary key columns input for table: group_therapy_variable_config */
export type GroupTherapyVariableConfig_Pk_Columns_Input = {
  participantsGroupId: Scalars["uuid"]["input"];
  variableId: Scalars["uuid"]["input"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type GroupTherapyVariableConfig_Prepend_Input = {
  variableValue?: InputMaybe<Scalars["jsonb"]["input"]>;
};

/** select columns of table "group_therapy_variable_config" */
export enum GroupTherapyVariableConfig_Select_Column {
  /** column name */
  ChangedByUserId = "changedByUserId",
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  Id = "id",
  /** column name */
  ParticipantsGroupId = "participantsGroupId",
  /** column name */
  UpdatedAt = "updatedAt",
  /** column name */
  VariableId = "variableId",
  /** column name */
  VariableValue = "variableValue",
}

/** input type for updating data in table "group_therapy_variable_config" */
export type GroupTherapyVariableConfig_Set_Input = {
  changedByUserId?: InputMaybe<Scalars["uuid"]["input"]>;
  createdAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  participantsGroupId?: InputMaybe<Scalars["uuid"]["input"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  variableId?: InputMaybe<Scalars["uuid"]["input"]>;
  variableValue?: InputMaybe<Scalars["jsonb"]["input"]>;
};

/** Streaming cursor of the table "groupTherapyVariableConfig" */
export type GroupTherapyVariableConfig_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: GroupTherapyVariableConfig_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type GroupTherapyVariableConfig_Stream_Cursor_Value_Input = {
  changedByUserId?: InputMaybe<Scalars["uuid"]["input"]>;
  createdAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  participantsGroupId?: InputMaybe<Scalars["uuid"]["input"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  variableId?: InputMaybe<Scalars["uuid"]["input"]>;
  variableValue?: InputMaybe<Scalars["jsonb"]["input"]>;
};

/** update columns of table "group_therapy_variable_config" */
export enum GroupTherapyVariableConfig_Update_Column {
  /** column name */
  ChangedByUserId = "changedByUserId",
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  Id = "id",
  /** column name */
  ParticipantsGroupId = "participantsGroupId",
  /** column name */
  UpdatedAt = "updatedAt",
  /** column name */
  VariableId = "variableId",
  /** column name */
  VariableValue = "variableValue",
}

export type GroupTherapyVariableConfig_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<GroupTherapyVariableConfig_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<GroupTherapyVariableConfig_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<GroupTherapyVariableConfig_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<GroupTherapyVariableConfig_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<GroupTherapyVariableConfig_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<GroupTherapyVariableConfig_Set_Input>;
  /** filter the rows which have to be updated */
  where: GroupTherapyVariableConfig_Bool_Exp;
};

/** Boolean expression to compare columns of type "json". All fields are combined with logical 'AND'. */
export type Json_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["json"]["input"]>;
  _gt?: InputMaybe<Scalars["json"]["input"]>;
  _gte?: InputMaybe<Scalars["json"]["input"]>;
  _in?: InputMaybe<Array<Scalars["json"]["input"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]["input"]>;
  _lt?: InputMaybe<Scalars["json"]["input"]>;
  _lte?: InputMaybe<Scalars["json"]["input"]>;
  _neq?: InputMaybe<Scalars["json"]["input"]>;
  _nin?: InputMaybe<Array<Scalars["json"]["input"]>>;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars["jsonb"]["input"]>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars["jsonb"]["input"]>;
  _eq?: InputMaybe<Scalars["jsonb"]["input"]>;
  _gt?: InputMaybe<Scalars["jsonb"]["input"]>;
  _gte?: InputMaybe<Scalars["jsonb"]["input"]>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars["String"]["input"]>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars["String"]["input"]>>;
  _in?: InputMaybe<Array<Scalars["jsonb"]["input"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]["input"]>;
  _lt?: InputMaybe<Scalars["jsonb"]["input"]>;
  _lte?: InputMaybe<Scalars["jsonb"]["input"]>;
  _neq?: InputMaybe<Scalars["jsonb"]["input"]>;
  _nin?: InputMaybe<Array<Scalars["jsonb"]["input"]>>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: "mutation_root";
  /** insert a single row into the table: "api_account" */
  createApiAccount?: Maybe<Api_Account>;
  createApiAccountAction?: Maybe<CreateApiAccountOutput>;
  /** insert data into the table: "api_account" */
  createApiAccounts?: Maybe<Api_Account_Mutation_Response>;
  /** insert a single row into the table: "api_event" */
  createApiEvent?: Maybe<Api_Event>;
  /** insert data into the table: "api_event" */
  createApiEvents?: Maybe<Api_Event_Mutation_Response>;
  /** insert a single row into the table: "audit_log" */
  createAuditLog?: Maybe<Audit_Log>;
  /** insert data into the table: "audit_log" */
  createAuditLogs?: Maybe<Audit_Log_Mutation_Response>;
  /** insert a single row into the table: "backend_event" */
  createBackendEvent?: Maybe<Backend_Event>;
  /** insert data into the table: "backend_event" */
  createBackendEvents?: Maybe<Backend_Event_Mutation_Response>;
  /** insert a single row into the table: "contract" */
  createContract?: Maybe<Contract>;
  /** insert a single row into the table: "contract_type_enum" */
  createContractTypeEnum?: Maybe<ContractTypeEnum>;
  /** insert data into the table: "contract_type_enum" */
  createContractTypeEnums?: Maybe<ContractTypeEnum_Mutation_Response>;
  /** insert data into the table: "contract" */
  createContracts?: Maybe<Contract_Mutation_Response>;
  /** insert a single row into the table: "database_event" */
  createDatabaseEvent?: Maybe<Database_Event>;
  /** insert data into the table: "database_event" */
  createDatabaseEvents?: Maybe<Database_Event_Mutation_Response>;
  /** insert a single row into the table: "executor" */
  createExecutor?: Maybe<Executor>;
  /** insert data into the table: "executor" */
  createExecutors?: Maybe<Executor_Mutation_Response>;
  /** insert a single row into the table: "group_therapy_variable_config" */
  createGroupTherapyVariableConfig?: Maybe<GroupTherapyVariableConfig>;
  /** insert data into the table: "group_therapy_variable_config" */
  createGroupTherapyVariableConfigs?: Maybe<GroupTherapyVariableConfig_Mutation_Response>;
  /** insert a single row into the table: "organization" */
  createOrganization?: Maybe<Organization>;
  createOrganizationAction?: Maybe<CreateOrganizationOutput>;
  /** insert a single row into the table: "organization_member" */
  createOrganizationMember?: Maybe<OrganizationMember>;
  createOrganizationMemberAction?: Maybe<AddOrganizationMemberOutput>;
  /** insert data into the table: "organization_member" */
  createOrganizationMembers?: Maybe<OrganizationMember_Mutation_Response>;
  /** insert a single row into the table: "organization_role" */
  createOrganizationRole?: Maybe<OrganizationRole>;
  /** insert data into the table: "organization_role" */
  createOrganizationRoles?: Maybe<OrganizationRole_Mutation_Response>;
  /** insert data into the table: "organization" */
  createOrganizations?: Maybe<Organization_Mutation_Response>;
  /** insert a single row into the table: "participant" */
  createParticipant?: Maybe<Participant>;
  createParticipantAction?: Maybe<CreateParticipantOutput>;
  /** insert data into the table: "participant" */
  createParticipants?: Maybe<Participant_Mutation_Response>;
  /** insert a single row into the table: "participants_group" */
  createParticipantsGroup?: Maybe<ParticipantsGroup>;
  createParticipantsGroupAction?: Maybe<CreateParticipantsGroupActionOutput>;
  /** insert data into the table: "participants_group" */
  createParticipantsGroups?: Maybe<ParticipantsGroup_Mutation_Response>;
  /** insert data into the table: "study" */
  createStudies?: Maybe<Study_Mutation_Response>;
  /** insert a single row into the table: "study" */
  createStudy?: Maybe<Study>;
  createStudyAction?: Maybe<CreateStudyOutput>;
  /** insert a single row into the table: "study_member" */
  createStudyMember?: Maybe<StudyMember>;
  createStudyMemberAction?: Maybe<AddStudyMemberOutput>;
  /** insert data into the table: "study_member" */
  createStudyMembers?: Maybe<StudyMember_Mutation_Response>;
  /** insert a single row into the table: "study_role" */
  createStudyRole?: Maybe<StudyRole>;
  /** insert data into the table: "study_role" */
  createStudyRoles?: Maybe<StudyRole_Mutation_Response>;
  /** insert a single row into the table: "supervisor" */
  createSupervisor?: Maybe<Supervisor>;
  createSupervisorAction?: Maybe<Array<Maybe<CreateSupervisorOutput>>>;
  /** insert a single row into the table: "supervisor_role" */
  createSupervisorRole?: Maybe<SupervisorRole>;
  /** insert data into the table: "supervisor_role" */
  createSupervisorRoles?: Maybe<SupervisorRole_Mutation_Response>;
  /** insert data into the table: "supervisor" */
  createSupervisors?: Maybe<Supervisor_Mutation_Response>;
  /** insert a single row into the table: "user" */
  createUser?: Maybe<User>;
  /** insert data into the table: "user" */
  createUsers?: Maybe<User_Mutation_Response>;
  deleteAccountAction: DeleteAccountOutput;
  /** delete single row from the table: "api_account" */
  deleteApiAccount?: Maybe<Api_Account>;
  deleteApiAccountAction?: Maybe<DeleteApiAccountAction>;
  /** delete data from the table: "api_account" */
  deleteApiAccounts?: Maybe<Api_Account_Mutation_Response>;
  /** delete single row from the table: "api_event" */
  deleteApiEvent?: Maybe<Api_Event>;
  /** delete data from the table: "api_event" */
  deleteApiEvents?: Maybe<Api_Event_Mutation_Response>;
  /** delete data from the table: "audit_log" */
  deleteAuditLog?: Maybe<Audit_Log_Mutation_Response>;
  /** delete single row from the table: "audit_log" */
  deleteAuditLogs?: Maybe<Audit_Log>;
  /** delete data from the table: "backend_event" */
  deleteBackendEvents?: Maybe<Backend_Event_Mutation_Response>;
  /** delete single row from the table: "contract" */
  deleteContract?: Maybe<Contract>;
  /** delete single row from the table: "contract_type_enum" */
  deleteContractTypeEnum?: Maybe<ContractTypeEnum>;
  /** delete data from the table: "contract_type_enum" */
  deleteContractTypeEnums?: Maybe<ContractTypeEnum_Mutation_Response>;
  /** delete data from the table: "contract" */
  deleteContracts?: Maybe<Contract_Mutation_Response>;
  /** delete data from the table: "database_event" */
  deleteDatabaseEvents?: Maybe<Database_Event_Mutation_Response>;
  /** delete single row from the table: "executor" */
  deleteExecutor?: Maybe<Executor>;
  /** delete data from the table: "executor" */
  deleteExecutors?: Maybe<Executor_Mutation_Response>;
  /** delete single row from the table: "group_therapy_variable_config" */
  deleteGroupTherapyVariableConfig?: Maybe<GroupTherapyVariableConfig>;
  /** delete data from the table: "group_therapy_variable_config" */
  deleteGroupTherapyVariableConfigs?: Maybe<GroupTherapyVariableConfig_Mutation_Response>;
  /** delete single row from the table: "organization" */
  deleteOrganization?: Maybe<Organization>;
  deleteOrganizationAction: DeleteOrganizationOutput;
  /** delete single row from the table: "organization_member" */
  deleteOrganizationMember?: Maybe<OrganizationMember>;
  /** delete data from the table: "organization_member" */
  deleteOrganizationMembers?: Maybe<OrganizationMember_Mutation_Response>;
  /** delete single row from the table: "organization_role" */
  deleteOrganizationRole?: Maybe<OrganizationRole>;
  /** delete data from the table: "organization_role" */
  deleteOrganizationRoles?: Maybe<OrganizationRole_Mutation_Response>;
  /** delete data from the table: "organization" */
  deleteOrganizations?: Maybe<Organization_Mutation_Response>;
  /** delete single row from the table: "participant" */
  deleteParticipant?: Maybe<Participant>;
  deleteParticipantAction?: Maybe<DeleteParticipantOutput>;
  deleteParticipantGroup?: Maybe<DeleteStudyGroupOutput>;
  /** delete data from the table: "participant" */
  deleteParticipants?: Maybe<Participant_Mutation_Response>;
  /** delete single row from the table: "participants_group" */
  deleteParticipantsGroup?: Maybe<ParticipantsGroup>;
  /** delete data from the table: "participants_group" */
  deleteParticipantsGroups?: Maybe<ParticipantsGroup_Mutation_Response>;
  /** delete data from the table: "study" */
  deleteStudies?: Maybe<Study_Mutation_Response>;
  /** delete single row from the table: "study" */
  deleteStudy?: Maybe<Study>;
  deleteStudyAction: DeleteStudyOutput;
  /** delete single row from the table: "study_member" */
  deleteStudyMember?: Maybe<StudyMember>;
  /** delete data from the table: "study_member" */
  deleteStudyMembers?: Maybe<StudyMember_Mutation_Response>;
  /** delete single row from the table: "study_role" */
  deleteStudyRole?: Maybe<StudyRole>;
  /** delete data from the table: "study_role" */
  deleteStudyRoles?: Maybe<StudyRole_Mutation_Response>;
  /** delete single row from the table: "supervisor" */
  deleteSupervisor?: Maybe<Supervisor>;
  /** delete single row from the table: "supervisor_role" */
  deleteSupervisorRole?: Maybe<SupervisorRole>;
  /** delete data from the table: "supervisor_role" */
  deleteSupervisorRoles?: Maybe<SupervisorRole_Mutation_Response>;
  /** delete data from the table: "supervisor" */
  deleteSupervisors?: Maybe<Supervisor_Mutation_Response>;
  /** delete single row from the table: "user" */
  deleteUser?: Maybe<User>;
  /** delete data from the table: "user" */
  deleteUsers?: Maybe<User_Mutation_Response>;
  leaveOrganizationAction: LeaveOrganizationOutput;
  leaveStudyAction?: Maybe<LeaveStudyOutput>;
  resetCoupleKeyAction: ParticipantOutput;
  sendTestNotificationAction: SendTestNotificationOutput;
  triggerPasswordUpdate?: Maybe<TriggerPasswordUpdateOutput>;
  /** update single row of the table: "api_account" */
  updateApiAccount?: Maybe<Api_Account>;
  /** update data of the table: "api_account" */
  updateApiAccounts?: Maybe<Api_Account_Mutation_Response>;
  /** update single row of the table: "api_event" */
  updateApiEvent?: Maybe<Api_Event>;
  /** update data of the table: "api_event" */
  updateApiEvents?: Maybe<Api_Event_Mutation_Response>;
  /** update single row of the table: "audit_log" */
  updateAuditLog?: Maybe<Audit_Log>;
  /** update data of the table: "audit_log" */
  updateAuditLogs?: Maybe<Audit_Log_Mutation_Response>;
  /** update data of the table: "backend_event" */
  updateBackendEvents?: Maybe<Backend_Event_Mutation_Response>;
  /** update single row of the table: "contract" */
  updateContract?: Maybe<Contract>;
  /** update single row of the table: "contract_type_enum" */
  updateContractTypeEnum?: Maybe<ContractTypeEnum>;
  /** update data of the table: "contract_type_enum" */
  updateContractTypeEnums?: Maybe<ContractTypeEnum_Mutation_Response>;
  /** update data of the table: "contract" */
  updateContracts?: Maybe<Contract_Mutation_Response>;
  /** update data of the table: "database_event" */
  updateDatabaseEvents?: Maybe<Database_Event_Mutation_Response>;
  updateEmail?: Maybe<UpdateEmailOutput>;
  /** update single row of the table: "executor" */
  updateExecutor?: Maybe<Executor>;
  /** update data of the table: "executor" */
  updateExecutors?: Maybe<Executor_Mutation_Response>;
  /** update single row of the table: "group_therapy_variable_config" */
  updateGroupTherapyVariableConfig?: Maybe<GroupTherapyVariableConfig>;
  /** update data of the table: "group_therapy_variable_config" */
  updateGroupTherapyVariableConfigs?: Maybe<GroupTherapyVariableConfig_Mutation_Response>;
  updateGroupTherapyVariableConfigsAction?: Maybe<Array<Maybe<ConfigsToUpdateOutput>>>;
  /** update single row of the table: "organization" */
  updateOrganization?: Maybe<Organization>;
  /** update single row of the table: "organization_member" */
  updateOrganizationMember?: Maybe<OrganizationMember>;
  /** update data of the table: "organization_member" */
  updateOrganizationMembers?: Maybe<OrganizationMember_Mutation_Response>;
  /** update single row of the table: "organization_role" */
  updateOrganizationRole?: Maybe<OrganizationRole>;
  /** update data of the table: "organization_role" */
  updateOrganizationRoles?: Maybe<OrganizationRole_Mutation_Response>;
  /** update data of the table: "organization" */
  updateOrganizations?: Maybe<Organization_Mutation_Response>;
  /** update single row of the table: "participant" */
  updateParticipant?: Maybe<Participant>;
  updateParticipantAction: ParticipantOutput;
  updateParticipantTherapyVariableConfigsAction?: Maybe<
    Array<Maybe<ParticipantVariableConfigsToUpdateOutput>>
  >;
  /** update data of the table: "participant" */
  updateParticipants?: Maybe<Participant_Mutation_Response>;
  /** update single row of the table: "participants_group" */
  updateParticipantsGroup?: Maybe<ParticipantsGroup>;
  /** update data of the table: "participants_group" */
  updateParticipantsGroups?: Maybe<ParticipantsGroup_Mutation_Response>;
  /** update data of the table: "study" */
  updateStudies?: Maybe<Study_Mutation_Response>;
  /** update single row of the table: "study" */
  updateStudy?: Maybe<Study>;
  /** update single row of the table: "study_member" */
  updateStudyMember?: Maybe<StudyMember>;
  /** update data of the table: "study_member" */
  updateStudyMembers?: Maybe<StudyMember_Mutation_Response>;
  /** update single row of the table: "study_role" */
  updateStudyRole?: Maybe<StudyRole>;
  /** update data of the table: "study_role" */
  updateStudyRoles?: Maybe<StudyRole_Mutation_Response>;
  /** update single row of the table: "supervisor" */
  updateSupervisor?: Maybe<Supervisor>;
  /** update single row of the table: "supervisor_role" */
  updateSupervisorRole?: Maybe<SupervisorRole>;
  /** update data of the table: "supervisor_role" */
  updateSupervisorRoles?: Maybe<SupervisorRole_Mutation_Response>;
  /** update data of the table: "supervisor" */
  updateSupervisors?: Maybe<Supervisor_Mutation_Response>;
  updateTherapyPeriodAction: ParticipantOutput;
  /** update single row of the table: "user" */
  updateUser?: Maybe<User>;
  /** update data of the table: "user" */
  updateUsers?: Maybe<User_Mutation_Response>;
  /** update multiples rows of table: "api_account" */
  update_api_account_many?: Maybe<Array<Maybe<Api_Account_Mutation_Response>>>;
  /** update multiples rows of table: "api_event" */
  update_api_event_many?: Maybe<Array<Maybe<Api_Event_Mutation_Response>>>;
  /** update multiples rows of table: "audit_log" */
  update_audit_log_many?: Maybe<Array<Maybe<Audit_Log_Mutation_Response>>>;
  /** update multiples rows of table: "backend_event" */
  update_backend_event_many?: Maybe<Array<Maybe<Backend_Event_Mutation_Response>>>;
  /** update multiples rows of table: "contract_type_enum" */
  update_contractTypeEnum_many?: Maybe<Array<Maybe<ContractTypeEnum_Mutation_Response>>>;
  /** update multiples rows of table: "contract" */
  update_contract_many?: Maybe<Array<Maybe<Contract_Mutation_Response>>>;
  /** update multiples rows of table: "database_event" */
  update_database_event_many?: Maybe<Array<Maybe<Database_Event_Mutation_Response>>>;
  /** update multiples rows of table: "executor" */
  update_executor_many?: Maybe<Array<Maybe<Executor_Mutation_Response>>>;
  /** update multiples rows of table: "group_therapy_variable_config" */
  update_groupTherapyVariableConfig_many?: Maybe<
    Array<Maybe<GroupTherapyVariableConfig_Mutation_Response>>
  >;
  /** update multiples rows of table: "organization_member" */
  update_organizationMember_many?: Maybe<Array<Maybe<OrganizationMember_Mutation_Response>>>;
  /** update multiples rows of table: "organization_role" */
  update_organizationRole_many?: Maybe<Array<Maybe<OrganizationRole_Mutation_Response>>>;
  /** update multiples rows of table: "organization" */
  update_organization_many?: Maybe<Array<Maybe<Organization_Mutation_Response>>>;
  /** update multiples rows of table: "participant" */
  update_participant_many?: Maybe<Array<Maybe<Participant_Mutation_Response>>>;
  /** update multiples rows of table: "participants_group" */
  update_participantsGroup_many?: Maybe<Array<Maybe<ParticipantsGroup_Mutation_Response>>>;
  /** update multiples rows of table: "study_member" */
  update_studyMember_many?: Maybe<Array<Maybe<StudyMember_Mutation_Response>>>;
  /** update multiples rows of table: "study_role" */
  update_studyRole_many?: Maybe<Array<Maybe<StudyRole_Mutation_Response>>>;
  /** update multiples rows of table: "study" */
  update_study_many?: Maybe<Array<Maybe<Study_Mutation_Response>>>;
  /** update multiples rows of table: "supervisor_role" */
  update_supervisorRole_many?: Maybe<Array<Maybe<SupervisorRole_Mutation_Response>>>;
  /** update multiples rows of table: "supervisor" */
  update_supervisor_many?: Maybe<Array<Maybe<Supervisor_Mutation_Response>>>;
  /** update multiples rows of table: "user" */
  update_user_many?: Maybe<Array<Maybe<User_Mutation_Response>>>;
};

/** mutation root */
export type Mutation_RootCreateApiAccountArgs = {
  object: Api_Account_Insert_Input;
  on_conflict?: InputMaybe<Api_Account_On_Conflict>;
};

/** mutation root */
export type Mutation_RootCreateApiAccountActionArgs = {
  expiresAt: Scalars["String"]["input"];
  serviceName: Scalars["String"]["input"];
  studyId: Scalars["uuid"]["input"];
};

/** mutation root */
export type Mutation_RootCreateApiAccountsArgs = {
  objects: Array<Api_Account_Insert_Input>;
  on_conflict?: InputMaybe<Api_Account_On_Conflict>;
};

/** mutation root */
export type Mutation_RootCreateApiEventArgs = {
  object: Api_Event_Insert_Input;
  on_conflict?: InputMaybe<Api_Event_On_Conflict>;
};

/** mutation root */
export type Mutation_RootCreateApiEventsArgs = {
  objects: Array<Api_Event_Insert_Input>;
  on_conflict?: InputMaybe<Api_Event_On_Conflict>;
};

/** mutation root */
export type Mutation_RootCreateAuditLogArgs = {
  object: Audit_Log_Insert_Input;
  on_conflict?: InputMaybe<Audit_Log_On_Conflict>;
};

/** mutation root */
export type Mutation_RootCreateAuditLogsArgs = {
  objects: Array<Audit_Log_Insert_Input>;
  on_conflict?: InputMaybe<Audit_Log_On_Conflict>;
};

/** mutation root */
export type Mutation_RootCreateBackendEventArgs = {
  object: Backend_Event_Insert_Input;
};

/** mutation root */
export type Mutation_RootCreateBackendEventsArgs = {
  objects: Array<Backend_Event_Insert_Input>;
};

/** mutation root */
export type Mutation_RootCreateContractArgs = {
  object: Contract_Insert_Input;
  on_conflict?: InputMaybe<Contract_On_Conflict>;
};

/** mutation root */
export type Mutation_RootCreateContractTypeEnumArgs = {
  object: ContractTypeEnum_Insert_Input;
  on_conflict?: InputMaybe<ContractTypeEnum_On_Conflict>;
};

/** mutation root */
export type Mutation_RootCreateContractTypeEnumsArgs = {
  objects: Array<ContractTypeEnum_Insert_Input>;
  on_conflict?: InputMaybe<ContractTypeEnum_On_Conflict>;
};

/** mutation root */
export type Mutation_RootCreateContractsArgs = {
  objects: Array<Contract_Insert_Input>;
  on_conflict?: InputMaybe<Contract_On_Conflict>;
};

/** mutation root */
export type Mutation_RootCreateDatabaseEventArgs = {
  object: Database_Event_Insert_Input;
};

/** mutation root */
export type Mutation_RootCreateDatabaseEventsArgs = {
  objects: Array<Database_Event_Insert_Input>;
};

/** mutation root */
export type Mutation_RootCreateExecutorArgs = {
  object: Executor_Insert_Input;
  on_conflict?: InputMaybe<Executor_On_Conflict>;
};

/** mutation root */
export type Mutation_RootCreateExecutorsArgs = {
  objects: Array<Executor_Insert_Input>;
  on_conflict?: InputMaybe<Executor_On_Conflict>;
};

/** mutation root */
export type Mutation_RootCreateGroupTherapyVariableConfigArgs = {
  object: GroupTherapyVariableConfig_Insert_Input;
  on_conflict?: InputMaybe<GroupTherapyVariableConfig_On_Conflict>;
};

/** mutation root */
export type Mutation_RootCreateGroupTherapyVariableConfigsArgs = {
  objects: Array<GroupTherapyVariableConfig_Insert_Input>;
  on_conflict?: InputMaybe<GroupTherapyVariableConfig_On_Conflict>;
};

/** mutation root */
export type Mutation_RootCreateOrganizationArgs = {
  object: Organization_Insert_Input;
  on_conflict?: InputMaybe<Organization_On_Conflict>;
};

/** mutation root */
export type Mutation_RootCreateOrganizationActionArgs = {
  newOrganization: CreateOrganizationInput;
};

/** mutation root */
export type Mutation_RootCreateOrganizationMemberArgs = {
  object: OrganizationMember_Insert_Input;
  on_conflict?: InputMaybe<OrganizationMember_On_Conflict>;
};

/** mutation root */
export type Mutation_RootCreateOrganizationMemberActionArgs = {
  email: Scalars["String"]["input"];
  organizationId: Scalars["uuid"]["input"];
  roleId: Scalars["uuid"]["input"];
};

/** mutation root */
export type Mutation_RootCreateOrganizationMembersArgs = {
  objects: Array<OrganizationMember_Insert_Input>;
  on_conflict?: InputMaybe<OrganizationMember_On_Conflict>;
};

/** mutation root */
export type Mutation_RootCreateOrganizationRoleArgs = {
  object: OrganizationRole_Insert_Input;
  on_conflict?: InputMaybe<OrganizationRole_On_Conflict>;
};

/** mutation root */
export type Mutation_RootCreateOrganizationRolesArgs = {
  objects: Array<OrganizationRole_Insert_Input>;
  on_conflict?: InputMaybe<OrganizationRole_On_Conflict>;
};

/** mutation root */
export type Mutation_RootCreateOrganizationsArgs = {
  objects: Array<Organization_Insert_Input>;
  on_conflict?: InputMaybe<Organization_On_Conflict>;
};

/** mutation root */
export type Mutation_RootCreateParticipantArgs = {
  object: Participant_Insert_Input;
  on_conflict?: InputMaybe<Participant_On_Conflict>;
};

/** mutation root */
export type Mutation_RootCreateParticipantActionArgs = {
  participant: CreateParticipantInput;
};

/** mutation root */
export type Mutation_RootCreateParticipantsArgs = {
  objects: Array<Participant_Insert_Input>;
  on_conflict?: InputMaybe<Participant_On_Conflict>;
};

/** mutation root */
export type Mutation_RootCreateParticipantsGroupArgs = {
  object: ParticipantsGroup_Insert_Input;
  on_conflict?: InputMaybe<ParticipantsGroup_On_Conflict>;
};

/** mutation root */
export type Mutation_RootCreateParticipantsGroupActionArgs = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  studyId: Scalars["uuid"]["input"];
};

/** mutation root */
export type Mutation_RootCreateParticipantsGroupsArgs = {
  objects: Array<ParticipantsGroup_Insert_Input>;
  on_conflict?: InputMaybe<ParticipantsGroup_On_Conflict>;
};

/** mutation root */
export type Mutation_RootCreateStudiesArgs = {
  objects: Array<Study_Insert_Input>;
  on_conflict?: InputMaybe<Study_On_Conflict>;
};

/** mutation root */
export type Mutation_RootCreateStudyArgs = {
  object: Study_Insert_Input;
  on_conflict?: InputMaybe<Study_On_Conflict>;
};

/** mutation root */
export type Mutation_RootCreateStudyActionArgs = {
  study: CreateStudyInput;
};

/** mutation root */
export type Mutation_RootCreateStudyMemberArgs = {
  object: StudyMember_Insert_Input;
  on_conflict?: InputMaybe<StudyMember_On_Conflict>;
};

/** mutation root */
export type Mutation_RootCreateStudyMemberActionArgs = {
  email: Scalars["String"]["input"];
  roleId: Scalars["uuid"]["input"];
  studyId: Scalars["uuid"]["input"];
};

/** mutation root */
export type Mutation_RootCreateStudyMembersArgs = {
  objects: Array<StudyMember_Insert_Input>;
  on_conflict?: InputMaybe<StudyMember_On_Conflict>;
};

/** mutation root */
export type Mutation_RootCreateStudyRoleArgs = {
  object: StudyRole_Insert_Input;
  on_conflict?: InputMaybe<StudyRole_On_Conflict>;
};

/** mutation root */
export type Mutation_RootCreateStudyRolesArgs = {
  objects: Array<StudyRole_Insert_Input>;
  on_conflict?: InputMaybe<StudyRole_On_Conflict>;
};

/** mutation root */
export type Mutation_RootCreateSupervisorArgs = {
  object: Supervisor_Insert_Input;
  on_conflict?: InputMaybe<Supervisor_On_Conflict>;
};

/** mutation root */
export type Mutation_RootCreateSupervisorActionArgs = {
  email: Scalars["String"]["input"];
  participantIds: Array<InputMaybe<Scalars["uuid"]["input"]>>;
  studyId: Scalars["uuid"]["input"];
  supervisorRoleId: Scalars["uuid"]["input"];
};

/** mutation root */
export type Mutation_RootCreateSupervisorRoleArgs = {
  object: SupervisorRole_Insert_Input;
  on_conflict?: InputMaybe<SupervisorRole_On_Conflict>;
};

/** mutation root */
export type Mutation_RootCreateSupervisorRolesArgs = {
  objects: Array<SupervisorRole_Insert_Input>;
  on_conflict?: InputMaybe<SupervisorRole_On_Conflict>;
};

/** mutation root */
export type Mutation_RootCreateSupervisorsArgs = {
  objects: Array<Supervisor_Insert_Input>;
  on_conflict?: InputMaybe<Supervisor_On_Conflict>;
};

/** mutation root */
export type Mutation_RootCreateUserArgs = {
  object: User_Insert_Input;
  on_conflict?: InputMaybe<User_On_Conflict>;
};

/** mutation root */
export type Mutation_RootCreateUsersArgs = {
  objects: Array<User_Insert_Input>;
  on_conflict?: InputMaybe<User_On_Conflict>;
};

/** mutation root */
export type Mutation_RootDeleteAccountActionArgs = {
  userId: Scalars["uuid"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteApiAccountArgs = {
  id: Scalars["uuid"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteApiAccountActionArgs = {
  id?: InputMaybe<Scalars["uuid"]["input"]>;
};

/** mutation root */
export type Mutation_RootDeleteApiAccountsArgs = {
  where: Api_Account_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDeleteApiEventArgs = {
  id: Scalars["uuid"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteApiEventsArgs = {
  where: Api_Event_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDeleteAuditLogArgs = {
  where: Audit_Log_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDeleteAuditLogsArgs = {
  id: Scalars["uuid"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteBackendEventsArgs = {
  where: Backend_Event_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDeleteContractArgs = {
  id: Scalars["uuid"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteContractTypeEnumArgs = {
  contractType: Scalars["String"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteContractTypeEnumsArgs = {
  where: ContractTypeEnum_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDeleteContractsArgs = {
  where: Contract_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDeleteDatabaseEventsArgs = {
  where: Database_Event_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDeleteExecutorArgs = {
  id: Scalars["uuid"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteExecutorsArgs = {
  where: Executor_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDeleteGroupTherapyVariableConfigArgs = {
  participantsGroupId: Scalars["uuid"]["input"];
  variableId: Scalars["uuid"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteGroupTherapyVariableConfigsArgs = {
  where: GroupTherapyVariableConfig_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDeleteOrganizationArgs = {
  id: Scalars["uuid"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteOrganizationActionArgs = {
  organizationId: Scalars["uuid"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteOrganizationMemberArgs = {
  organizationId: Scalars["uuid"]["input"];
  userId: Scalars["uuid"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteOrganizationMembersArgs = {
  where: OrganizationMember_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDeleteOrganizationRoleArgs = {
  id: Scalars["uuid"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteOrganizationRolesArgs = {
  where: OrganizationRole_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDeleteOrganizationsArgs = {
  where: Organization_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDeleteParticipantArgs = {
  id: Scalars["uuid"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteParticipantActionArgs = {
  participantId: Scalars["uuid"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteParticipantGroupArgs = {
  groupId: Scalars["uuid"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteParticipantsArgs = {
  where: Participant_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDeleteParticipantsGroupArgs = {
  id: Scalars["uuid"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteParticipantsGroupsArgs = {
  where: ParticipantsGroup_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDeleteStudiesArgs = {
  where: Study_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDeleteStudyArgs = {
  id: Scalars["uuid"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteStudyActionArgs = {
  studyId: Scalars["uuid"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteStudyMemberArgs = {
  studyId: Scalars["uuid"]["input"];
  userId: Scalars["uuid"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteStudyMembersArgs = {
  where: StudyMember_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDeleteStudyRoleArgs = {
  id: Scalars["uuid"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteStudyRolesArgs = {
  where: StudyRole_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDeleteSupervisorArgs = {
  participantId: Scalars["uuid"]["input"];
  userId: Scalars["uuid"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteSupervisorRoleArgs = {
  id: Scalars["uuid"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteSupervisorRolesArgs = {
  where: SupervisorRole_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDeleteSupervisorsArgs = {
  where: Supervisor_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDeleteUserArgs = {
  id: Scalars["uuid"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteUsersArgs = {
  where: User_Bool_Exp;
};

/** mutation root */
export type Mutation_RootLeaveOrganizationActionArgs = {
  organizationId: Scalars["uuid"]["input"];
  userId: Scalars["uuid"]["input"];
};

/** mutation root */
export type Mutation_RootLeaveStudyActionArgs = {
  studyId: Scalars["uuid"]["input"];
  userId: Scalars["uuid"]["input"];
};

/** mutation root */
export type Mutation_RootResetCoupleKeyActionArgs = {
  participantId: Scalars["String"]["input"];
};

/** mutation root */
export type Mutation_RootSendTestNotificationActionArgs = {
  participantId: Scalars["uuid"]["input"];
};

/** mutation root */
export type Mutation_RootTriggerPasswordUpdateArgs = {
  userId: Scalars["uuid"]["input"];
};

/** mutation root */
export type Mutation_RootUpdateApiAccountArgs = {
  _set?: InputMaybe<Api_Account_Set_Input>;
  pk_columns: Api_Account_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdateApiAccountsArgs = {
  _set?: InputMaybe<Api_Account_Set_Input>;
  where: Api_Account_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdateApiEventArgs = {
  _set?: InputMaybe<Api_Event_Set_Input>;
  pk_columns: Api_Event_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdateApiEventsArgs = {
  _set?: InputMaybe<Api_Event_Set_Input>;
  where: Api_Event_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdateAuditLogArgs = {
  _set?: InputMaybe<Audit_Log_Set_Input>;
  pk_columns: Audit_Log_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdateAuditLogsArgs = {
  _set?: InputMaybe<Audit_Log_Set_Input>;
  where: Audit_Log_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdateBackendEventsArgs = {
  _set?: InputMaybe<Backend_Event_Set_Input>;
  where: Backend_Event_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdateContractArgs = {
  _set?: InputMaybe<Contract_Set_Input>;
  pk_columns: Contract_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdateContractTypeEnumArgs = {
  _set?: InputMaybe<ContractTypeEnum_Set_Input>;
  pk_columns: ContractTypeEnum_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdateContractTypeEnumsArgs = {
  _set?: InputMaybe<ContractTypeEnum_Set_Input>;
  where: ContractTypeEnum_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdateContractsArgs = {
  _set?: InputMaybe<Contract_Set_Input>;
  where: Contract_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdateDatabaseEventsArgs = {
  _set?: InputMaybe<Database_Event_Set_Input>;
  where: Database_Event_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdateEmailArgs = {
  email: Scalars["String"]["input"];
  userId: Scalars["uuid"]["input"];
};

/** mutation root */
export type Mutation_RootUpdateExecutorArgs = {
  _inc?: InputMaybe<Executor_Inc_Input>;
  _set?: InputMaybe<Executor_Set_Input>;
  pk_columns: Executor_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdateExecutorsArgs = {
  _inc?: InputMaybe<Executor_Inc_Input>;
  _set?: InputMaybe<Executor_Set_Input>;
  where: Executor_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdateGroupTherapyVariableConfigArgs = {
  _append?: InputMaybe<GroupTherapyVariableConfig_Append_Input>;
  _delete_at_path?: InputMaybe<GroupTherapyVariableConfig_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<GroupTherapyVariableConfig_Delete_Elem_Input>;
  _delete_key?: InputMaybe<GroupTherapyVariableConfig_Delete_Key_Input>;
  _prepend?: InputMaybe<GroupTherapyVariableConfig_Prepend_Input>;
  _set?: InputMaybe<GroupTherapyVariableConfig_Set_Input>;
  pk_columns: GroupTherapyVariableConfig_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdateGroupTherapyVariableConfigsArgs = {
  _append?: InputMaybe<GroupTherapyVariableConfig_Append_Input>;
  _delete_at_path?: InputMaybe<GroupTherapyVariableConfig_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<GroupTherapyVariableConfig_Delete_Elem_Input>;
  _delete_key?: InputMaybe<GroupTherapyVariableConfig_Delete_Key_Input>;
  _prepend?: InputMaybe<GroupTherapyVariableConfig_Prepend_Input>;
  _set?: InputMaybe<GroupTherapyVariableConfig_Set_Input>;
  where: GroupTherapyVariableConfig_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdateGroupTherapyVariableConfigsActionArgs = {
  objects?: InputMaybe<Array<GroupConfigsToUpdate>>;
};

/** mutation root */
export type Mutation_RootUpdateOrganizationArgs = {
  _set?: InputMaybe<Organization_Set_Input>;
  pk_columns: Organization_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdateOrganizationMemberArgs = {
  _set?: InputMaybe<OrganizationMember_Set_Input>;
  pk_columns: OrganizationMember_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdateOrganizationMembersArgs = {
  _set?: InputMaybe<OrganizationMember_Set_Input>;
  where: OrganizationMember_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdateOrganizationRoleArgs = {
  _set?: InputMaybe<OrganizationRole_Set_Input>;
  pk_columns: OrganizationRole_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdateOrganizationRolesArgs = {
  _set?: InputMaybe<OrganizationRole_Set_Input>;
  where: OrganizationRole_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdateOrganizationsArgs = {
  _set?: InputMaybe<Organization_Set_Input>;
  where: Organization_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdateParticipantArgs = {
  _set?: InputMaybe<Participant_Set_Input>;
  pk_columns: Participant_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdateParticipantActionArgs = {
  participant: UpdateParticipantInput;
};

/** mutation root */
export type Mutation_RootUpdateParticipantTherapyVariableConfigsActionArgs = {
  objects?: InputMaybe<Array<ParticipantVariableConfigsToUpdate>>;
};

/** mutation root */
export type Mutation_RootUpdateParticipantsArgs = {
  _set?: InputMaybe<Participant_Set_Input>;
  where: Participant_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdateParticipantsGroupArgs = {
  _set?: InputMaybe<ParticipantsGroup_Set_Input>;
  pk_columns: ParticipantsGroup_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdateParticipantsGroupsArgs = {
  _set?: InputMaybe<ParticipantsGroup_Set_Input>;
  where: ParticipantsGroup_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdateStudiesArgs = {
  _set?: InputMaybe<Study_Set_Input>;
  where: Study_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdateStudyArgs = {
  _set?: InputMaybe<Study_Set_Input>;
  pk_columns: Study_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdateStudyMemberArgs = {
  _set?: InputMaybe<StudyMember_Set_Input>;
  pk_columns: StudyMember_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdateStudyMembersArgs = {
  _set?: InputMaybe<StudyMember_Set_Input>;
  where: StudyMember_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdateStudyRoleArgs = {
  _set?: InputMaybe<StudyRole_Set_Input>;
  pk_columns: StudyRole_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdateStudyRolesArgs = {
  _set?: InputMaybe<StudyRole_Set_Input>;
  where: StudyRole_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdateSupervisorArgs = {
  _set?: InputMaybe<Supervisor_Set_Input>;
  pk_columns: Supervisor_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdateSupervisorRoleArgs = {
  _set?: InputMaybe<SupervisorRole_Set_Input>;
  pk_columns: SupervisorRole_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdateSupervisorRolesArgs = {
  _set?: InputMaybe<SupervisorRole_Set_Input>;
  where: SupervisorRole_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdateSupervisorsArgs = {
  _set?: InputMaybe<Supervisor_Set_Input>;
  where: Supervisor_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdateTherapyPeriodActionArgs = {
  therapyPeriod: TherapyPeriod;
};

/** mutation root */
export type Mutation_RootUpdateUserArgs = {
  _set?: InputMaybe<User_Set_Input>;
  pk_columns: User_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdateUsersArgs = {
  _set?: InputMaybe<User_Set_Input>;
  where: User_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Api_Account_ManyArgs = {
  updates: Array<Api_Account_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Api_Event_ManyArgs = {
  updates: Array<Api_Event_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Audit_Log_ManyArgs = {
  updates: Array<Audit_Log_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Backend_Event_ManyArgs = {
  updates: Array<Backend_Event_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ContractTypeEnum_ManyArgs = {
  updates: Array<ContractTypeEnum_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Contract_ManyArgs = {
  updates: Array<Contract_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Database_Event_ManyArgs = {
  updates: Array<Database_Event_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Executor_ManyArgs = {
  updates: Array<Executor_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_GroupTherapyVariableConfig_ManyArgs = {
  updates: Array<GroupTherapyVariableConfig_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_OrganizationMember_ManyArgs = {
  updates: Array<OrganizationMember_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_OrganizationRole_ManyArgs = {
  updates: Array<OrganizationRole_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_ManyArgs = {
  updates: Array<Organization_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Participant_ManyArgs = {
  updates: Array<Participant_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ParticipantsGroup_ManyArgs = {
  updates: Array<ParticipantsGroup_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_StudyMember_ManyArgs = {
  updates: Array<StudyMember_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_StudyRole_ManyArgs = {
  updates: Array<StudyRole_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Study_ManyArgs = {
  updates: Array<Study_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_SupervisorRole_ManyArgs = {
  updates: Array<SupervisorRole_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Supervisor_ManyArgs = {
  updates: Array<Supervisor_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_User_ManyArgs = {
  updates: Array<User_Updates>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = "asc",
  /** in ascending order, nulls first */
  AscNullsFirst = "asc_nulls_first",
  /** in ascending order, nulls last */
  AscNullsLast = "asc_nulls_last",
  /** in descending order, nulls first */
  Desc = "desc",
  /** in descending order, nulls first */
  DescNullsFirst = "desc_nulls_first",
  /** in descending order, nulls last */
  DescNullsLast = "desc_nulls_last",
}

/** columns and relationships of "organization" */
export type Organization = {
  __typename?: "organization";
  address: Scalars["String"]["output"];
  city: Scalars["String"]["output"];
  /** An array relationship */
  contracts: Array<Contract>;
  /** An aggregate relationship */
  contracts_aggregate: Contract_Aggregate;
  country: Scalars["String"]["output"];
  createdAt?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  department?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["uuid"]["output"];
  logo?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  /** An array relationship */
  organizationMembers: Array<OrganizationMember>;
  /** An aggregate relationship */
  organizationMembers_aggregate: OrganizationMember_Aggregate;
  /** An array relationship */
  organizationRoles: Array<OrganizationRole>;
  /** An aggregate relationship */
  organizationRoles_aggregate: OrganizationRole_Aggregate;
  /** An array relationship */
  studies: Array<Study>;
  /** An aggregate relationship */
  studies_aggregate: Study_Aggregate;
  updatedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  zip: Scalars["String"]["output"];
};

/** columns and relationships of "organization" */
export type OrganizationContractsArgs = {
  distinct_on?: InputMaybe<Array<Contract_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Contract_Order_By>>;
  where?: InputMaybe<Contract_Bool_Exp>;
};

/** columns and relationships of "organization" */
export type OrganizationContracts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contract_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Contract_Order_By>>;
  where?: InputMaybe<Contract_Bool_Exp>;
};

/** columns and relationships of "organization" */
export type OrganizationOrganizationMembersArgs = {
  distinct_on?: InputMaybe<Array<OrganizationMember_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<OrganizationMember_Order_By>>;
  where?: InputMaybe<OrganizationMember_Bool_Exp>;
};

/** columns and relationships of "organization" */
export type OrganizationOrganizationMembers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<OrganizationMember_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<OrganizationMember_Order_By>>;
  where?: InputMaybe<OrganizationMember_Bool_Exp>;
};

/** columns and relationships of "organization" */
export type OrganizationOrganizationRolesArgs = {
  distinct_on?: InputMaybe<Array<OrganizationRole_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<OrganizationRole_Order_By>>;
  where?: InputMaybe<OrganizationRole_Bool_Exp>;
};

/** columns and relationships of "organization" */
export type OrganizationOrganizationRoles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<OrganizationRole_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<OrganizationRole_Order_By>>;
  where?: InputMaybe<OrganizationRole_Bool_Exp>;
};

/** columns and relationships of "organization" */
export type OrganizationStudiesArgs = {
  distinct_on?: InputMaybe<Array<Study_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Study_Order_By>>;
  where?: InputMaybe<Study_Bool_Exp>;
};

/** columns and relationships of "organization" */
export type OrganizationStudies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Study_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Study_Order_By>>;
  where?: InputMaybe<Study_Bool_Exp>;
};

/** columns and relationships of "organization_member" */
export type OrganizationMember = {
  __typename?: "organizationMember";
  createdAt: Scalars["timestamptz"]["output"];
  id: Scalars["uuid"]["output"];
  /** An object relationship */
  organization: Organization;
  organizationId: Scalars["uuid"]["output"];
  /** An object relationship */
  organizationRole: OrganizationRole;
  organizationRoleId: Scalars["uuid"]["output"];
  updatedAt: Scalars["timestamptz"]["output"];
  /** An object relationship */
  user: User;
  userId: Scalars["uuid"]["output"];
};

/** aggregated selection of "organization_member" */
export type OrganizationMember_Aggregate = {
  __typename?: "organizationMember_aggregate";
  aggregate?: Maybe<OrganizationMember_Aggregate_Fields>;
  nodes: Array<OrganizationMember>;
};

export type OrganizationMember_Aggregate_Bool_Exp = {
  count?: InputMaybe<OrganizationMember_Aggregate_Bool_Exp_Count>;
};

export type OrganizationMember_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<OrganizationMember_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<OrganizationMember_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "organization_member" */
export type OrganizationMember_Aggregate_Fields = {
  __typename?: "organizationMember_aggregate_fields";
  count: Scalars["Int"]["output"];
  max?: Maybe<OrganizationMember_Max_Fields>;
  min?: Maybe<OrganizationMember_Min_Fields>;
};

/** aggregate fields of "organization_member" */
export type OrganizationMember_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<OrganizationMember_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "organization_member" */
export type OrganizationMember_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<OrganizationMember_Max_Order_By>;
  min?: InputMaybe<OrganizationMember_Min_Order_By>;
};

/** input type for inserting array relation for remote table "organization_member" */
export type OrganizationMember_Arr_Rel_Insert_Input = {
  data: Array<OrganizationMember_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<OrganizationMember_On_Conflict>;
};

/** Boolean expression to filter rows from the table "organization_member". All fields are combined with a logical 'AND'. */
export type OrganizationMember_Bool_Exp = {
  _and?: InputMaybe<Array<OrganizationMember_Bool_Exp>>;
  _not?: InputMaybe<OrganizationMember_Bool_Exp>;
  _or?: InputMaybe<Array<OrganizationMember_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  organization?: InputMaybe<Organization_Bool_Exp>;
  organizationId?: InputMaybe<Uuid_Comparison_Exp>;
  organizationRole?: InputMaybe<OrganizationRole_Bool_Exp>;
  organizationRoleId?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  userId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "organization_member" */
export enum OrganizationMember_Constraint {
  /** unique or primary key constraint on columns "user_id", "organization_id" */
  UserOrganizationRole2Pkey = "user_organization_role2_pkey",
}

/** input type for inserting data into table "organization_member" */
export type OrganizationMember_Insert_Input = {
  createdAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  organization?: InputMaybe<Organization_Obj_Rel_Insert_Input>;
  organizationId?: InputMaybe<Scalars["uuid"]["input"]>;
  organizationRole?: InputMaybe<OrganizationRole_Obj_Rel_Insert_Input>;
  organizationRoleId?: InputMaybe<Scalars["uuid"]["input"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars["uuid"]["input"]>;
};

/** aggregate max on columns */
export type OrganizationMember_Max_Fields = {
  __typename?: "organizationMember_max_fields";
  createdAt?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  organizationId?: Maybe<Scalars["uuid"]["output"]>;
  organizationRoleId?: Maybe<Scalars["uuid"]["output"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  userId?: Maybe<Scalars["uuid"]["output"]>;
};

/** order by max() on columns of table "organization_member" */
export type OrganizationMember_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organizationId?: InputMaybe<Order_By>;
  organizationRoleId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type OrganizationMember_Min_Fields = {
  __typename?: "organizationMember_min_fields";
  createdAt?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  organizationId?: Maybe<Scalars["uuid"]["output"]>;
  organizationRoleId?: Maybe<Scalars["uuid"]["output"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  userId?: Maybe<Scalars["uuid"]["output"]>;
};

/** order by min() on columns of table "organization_member" */
export type OrganizationMember_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organizationId?: InputMaybe<Order_By>;
  organizationRoleId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "organization_member" */
export type OrganizationMember_Mutation_Response = {
  __typename?: "organizationMember_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<OrganizationMember>;
};

/** on_conflict condition type for table "organization_member" */
export type OrganizationMember_On_Conflict = {
  constraint: OrganizationMember_Constraint;
  update_columns?: Array<OrganizationMember_Update_Column>;
  where?: InputMaybe<OrganizationMember_Bool_Exp>;
};

/** Ordering options when selecting data from "organization_member". */
export type OrganizationMember_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organization_Order_By>;
  organizationId?: InputMaybe<Order_By>;
  organizationRole?: InputMaybe<OrganizationRole_Order_By>;
  organizationRoleId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: organization_member */
export type OrganizationMember_Pk_Columns_Input = {
  organizationId: Scalars["uuid"]["input"];
  userId: Scalars["uuid"]["input"];
};

/** select columns of table "organization_member" */
export enum OrganizationMember_Select_Column {
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  Id = "id",
  /** column name */
  OrganizationId = "organizationId",
  /** column name */
  OrganizationRoleId = "organizationRoleId",
  /** column name */
  UpdatedAt = "updatedAt",
  /** column name */
  UserId = "userId",
}

/** input type for updating data in table "organization_member" */
export type OrganizationMember_Set_Input = {
  createdAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  organizationId?: InputMaybe<Scalars["uuid"]["input"]>;
  organizationRoleId?: InputMaybe<Scalars["uuid"]["input"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  userId?: InputMaybe<Scalars["uuid"]["input"]>;
};

/** Streaming cursor of the table "organizationMember" */
export type OrganizationMember_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: OrganizationMember_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type OrganizationMember_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  organizationId?: InputMaybe<Scalars["uuid"]["input"]>;
  organizationRoleId?: InputMaybe<Scalars["uuid"]["input"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  userId?: InputMaybe<Scalars["uuid"]["input"]>;
};

/** update columns of table "organization_member" */
export enum OrganizationMember_Update_Column {
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  Id = "id",
  /** column name */
  OrganizationId = "organizationId",
  /** column name */
  OrganizationRoleId = "organizationRoleId",
  /** column name */
  UpdatedAt = "updatedAt",
  /** column name */
  UserId = "userId",
}

export type OrganizationMember_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<OrganizationMember_Set_Input>;
  /** filter the rows which have to be updated */
  where: OrganizationMember_Bool_Exp;
};

/** columns and relationships of "organization_role" */
export type OrganizationRole = {
  __typename?: "organizationRole";
  createdAt: Scalars["timestamptz"]["output"];
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["uuid"]["output"];
  name: Scalars["String"]["output"];
  /** An object relationship */
  organization: Organization;
  organizationCreateMember: Scalars["Boolean"]["output"];
  organizationCreateStudy: Scalars["Boolean"]["output"];
  organizationDeleteMember: Scalars["Boolean"]["output"];
  organizationDeleteOrganization: Scalars["Boolean"]["output"];
  organizationEditBilling: Scalars["Boolean"]["output"];
  organizationEditMemberRole: Scalars["Boolean"]["output"];
  organizationEditOrganization: Scalars["Boolean"]["output"];
  organizationId: Scalars["uuid"]["output"];
  /** An array relationship */
  organizationMembers: Array<OrganizationMember>;
  /** An aggregate relationship */
  organizationMembers_aggregate: OrganizationMember_Aggregate;
  organizationViewDetails: Scalars["Boolean"]["output"];
  organizationViewInvoices: Scalars["Boolean"]["output"];
  organizationViewMembers: Scalars["Boolean"]["output"];
  organizationViewStudies: Scalars["Boolean"]["output"];
  updatedAt: Scalars["timestamptz"]["output"];
};

/** columns and relationships of "organization_role" */
export type OrganizationRoleOrganizationMembersArgs = {
  distinct_on?: InputMaybe<Array<OrganizationMember_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<OrganizationMember_Order_By>>;
  where?: InputMaybe<OrganizationMember_Bool_Exp>;
};

/** columns and relationships of "organization_role" */
export type OrganizationRoleOrganizationMembers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<OrganizationMember_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<OrganizationMember_Order_By>>;
  where?: InputMaybe<OrganizationMember_Bool_Exp>;
};

/** aggregated selection of "organization_role" */
export type OrganizationRole_Aggregate = {
  __typename?: "organizationRole_aggregate";
  aggregate?: Maybe<OrganizationRole_Aggregate_Fields>;
  nodes: Array<OrganizationRole>;
};

export type OrganizationRole_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<OrganizationRole_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<OrganizationRole_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<OrganizationRole_Aggregate_Bool_Exp_Count>;
};

export type OrganizationRole_Aggregate_Bool_Exp_Bool_And = {
  arguments: OrganizationRole_Select_Column_OrganizationRole_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<OrganizationRole_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type OrganizationRole_Aggregate_Bool_Exp_Bool_Or = {
  arguments: OrganizationRole_Select_Column_OrganizationRole_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<OrganizationRole_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type OrganizationRole_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<OrganizationRole_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<OrganizationRole_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "organization_role" */
export type OrganizationRole_Aggregate_Fields = {
  __typename?: "organizationRole_aggregate_fields";
  count: Scalars["Int"]["output"];
  max?: Maybe<OrganizationRole_Max_Fields>;
  min?: Maybe<OrganizationRole_Min_Fields>;
};

/** aggregate fields of "organization_role" */
export type OrganizationRole_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<OrganizationRole_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "organization_role" */
export type OrganizationRole_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<OrganizationRole_Max_Order_By>;
  min?: InputMaybe<OrganizationRole_Min_Order_By>;
};

/** input type for inserting array relation for remote table "organization_role" */
export type OrganizationRole_Arr_Rel_Insert_Input = {
  data: Array<OrganizationRole_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<OrganizationRole_On_Conflict>;
};

/** Boolean expression to filter rows from the table "organization_role". All fields are combined with a logical 'AND'. */
export type OrganizationRole_Bool_Exp = {
  _and?: InputMaybe<Array<OrganizationRole_Bool_Exp>>;
  _not?: InputMaybe<OrganizationRole_Bool_Exp>;
  _or?: InputMaybe<Array<OrganizationRole_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  deletedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organization?: InputMaybe<Organization_Bool_Exp>;
  organizationCreateMember?: InputMaybe<Boolean_Comparison_Exp>;
  organizationCreateStudy?: InputMaybe<Boolean_Comparison_Exp>;
  organizationDeleteMember?: InputMaybe<Boolean_Comparison_Exp>;
  organizationDeleteOrganization?: InputMaybe<Boolean_Comparison_Exp>;
  organizationEditBilling?: InputMaybe<Boolean_Comparison_Exp>;
  organizationEditMemberRole?: InputMaybe<Boolean_Comparison_Exp>;
  organizationEditOrganization?: InputMaybe<Boolean_Comparison_Exp>;
  organizationId?: InputMaybe<Uuid_Comparison_Exp>;
  organizationMembers?: InputMaybe<OrganizationMember_Bool_Exp>;
  organizationMembers_aggregate?: InputMaybe<OrganizationMember_Aggregate_Bool_Exp>;
  organizationViewDetails?: InputMaybe<Boolean_Comparison_Exp>;
  organizationViewInvoices?: InputMaybe<Boolean_Comparison_Exp>;
  organizationViewMembers?: InputMaybe<Boolean_Comparison_Exp>;
  organizationViewStudies?: InputMaybe<Boolean_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "organization_role" */
export enum OrganizationRole_Constraint {
  /** unique or primary key constraint on columns "id" */
  OrganizationRoleIdKey = "organization_role_id_key",
  /** unique or primary key constraint on columns "id" */
  OrganizationRolePkey = "organization_role_pkey",
}

/** input type for inserting data into table "organization_role" */
export type OrganizationRole_Insert_Input = {
  createdAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  organization?: InputMaybe<Organization_Obj_Rel_Insert_Input>;
  organizationCreateMember?: InputMaybe<Scalars["Boolean"]["input"]>;
  organizationCreateStudy?: InputMaybe<Scalars["Boolean"]["input"]>;
  organizationDeleteMember?: InputMaybe<Scalars["Boolean"]["input"]>;
  organizationDeleteOrganization?: InputMaybe<Scalars["Boolean"]["input"]>;
  organizationEditBilling?: InputMaybe<Scalars["Boolean"]["input"]>;
  organizationEditMemberRole?: InputMaybe<Scalars["Boolean"]["input"]>;
  organizationEditOrganization?: InputMaybe<Scalars["Boolean"]["input"]>;
  organizationId?: InputMaybe<Scalars["uuid"]["input"]>;
  organizationMembers?: InputMaybe<OrganizationMember_Arr_Rel_Insert_Input>;
  organizationViewDetails?: InputMaybe<Scalars["Boolean"]["input"]>;
  organizationViewInvoices?: InputMaybe<Scalars["Boolean"]["input"]>;
  organizationViewMembers?: InputMaybe<Scalars["Boolean"]["input"]>;
  organizationViewStudies?: InputMaybe<Scalars["Boolean"]["input"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** aggregate max on columns */
export type OrganizationRole_Max_Fields = {
  __typename?: "organizationRole_max_fields";
  createdAt?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  organizationId?: Maybe<Scalars["uuid"]["output"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** order by max() on columns of table "organization_role" */
export type OrganizationRole_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organizationId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type OrganizationRole_Min_Fields = {
  __typename?: "organizationRole_min_fields";
  createdAt?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  organizationId?: Maybe<Scalars["uuid"]["output"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** order by min() on columns of table "organization_role" */
export type OrganizationRole_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organizationId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "organization_role" */
export type OrganizationRole_Mutation_Response = {
  __typename?: "organizationRole_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<OrganizationRole>;
};

/** input type for inserting object relation for remote table "organization_role" */
export type OrganizationRole_Obj_Rel_Insert_Input = {
  data: OrganizationRole_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<OrganizationRole_On_Conflict>;
};

/** on_conflict condition type for table "organization_role" */
export type OrganizationRole_On_Conflict = {
  constraint: OrganizationRole_Constraint;
  update_columns?: Array<OrganizationRole_Update_Column>;
  where?: InputMaybe<OrganizationRole_Bool_Exp>;
};

/** Ordering options when selecting data from "organization_role". */
export type OrganizationRole_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organization_Order_By>;
  organizationCreateMember?: InputMaybe<Order_By>;
  organizationCreateStudy?: InputMaybe<Order_By>;
  organizationDeleteMember?: InputMaybe<Order_By>;
  organizationDeleteOrganization?: InputMaybe<Order_By>;
  organizationEditBilling?: InputMaybe<Order_By>;
  organizationEditMemberRole?: InputMaybe<Order_By>;
  organizationEditOrganization?: InputMaybe<Order_By>;
  organizationId?: InputMaybe<Order_By>;
  organizationMembers_aggregate?: InputMaybe<OrganizationMember_Aggregate_Order_By>;
  organizationViewDetails?: InputMaybe<Order_By>;
  organizationViewInvoices?: InputMaybe<Order_By>;
  organizationViewMembers?: InputMaybe<Order_By>;
  organizationViewStudies?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: organization_role */
export type OrganizationRole_Pk_Columns_Input = {
  id: Scalars["uuid"]["input"];
};

/** select columns of table "organization_role" */
export enum OrganizationRole_Select_Column {
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  OrganizationCreateMember = "organizationCreateMember",
  /** column name */
  OrganizationCreateStudy = "organizationCreateStudy",
  /** column name */
  OrganizationDeleteMember = "organizationDeleteMember",
  /** column name */
  OrganizationDeleteOrganization = "organizationDeleteOrganization",
  /** column name */
  OrganizationEditBilling = "organizationEditBilling",
  /** column name */
  OrganizationEditMemberRole = "organizationEditMemberRole",
  /** column name */
  OrganizationEditOrganization = "organizationEditOrganization",
  /** column name */
  OrganizationId = "organizationId",
  /** column name */
  OrganizationViewDetails = "organizationViewDetails",
  /** column name */
  OrganizationViewInvoices = "organizationViewInvoices",
  /** column name */
  OrganizationViewMembers = "organizationViewMembers",
  /** column name */
  OrganizationViewStudies = "organizationViewStudies",
  /** column name */
  UpdatedAt = "updatedAt",
}

/** select "organizationRole_aggregate_bool_exp_bool_and_arguments_columns" columns of table "organization_role" */
export enum OrganizationRole_Select_Column_OrganizationRole_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  OrganizationCreateMember = "organizationCreateMember",
  /** column name */
  OrganizationCreateStudy = "organizationCreateStudy",
  /** column name */
  OrganizationDeleteMember = "organizationDeleteMember",
  /** column name */
  OrganizationDeleteOrganization = "organizationDeleteOrganization",
  /** column name */
  OrganizationEditBilling = "organizationEditBilling",
  /** column name */
  OrganizationEditMemberRole = "organizationEditMemberRole",
  /** column name */
  OrganizationEditOrganization = "organizationEditOrganization",
  /** column name */
  OrganizationViewDetails = "organizationViewDetails",
  /** column name */
  OrganizationViewInvoices = "organizationViewInvoices",
  /** column name */
  OrganizationViewMembers = "organizationViewMembers",
  /** column name */
  OrganizationViewStudies = "organizationViewStudies",
}

/** select "organizationRole_aggregate_bool_exp_bool_or_arguments_columns" columns of table "organization_role" */
export enum OrganizationRole_Select_Column_OrganizationRole_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  OrganizationCreateMember = "organizationCreateMember",
  /** column name */
  OrganizationCreateStudy = "organizationCreateStudy",
  /** column name */
  OrganizationDeleteMember = "organizationDeleteMember",
  /** column name */
  OrganizationDeleteOrganization = "organizationDeleteOrganization",
  /** column name */
  OrganizationEditBilling = "organizationEditBilling",
  /** column name */
  OrganizationEditMemberRole = "organizationEditMemberRole",
  /** column name */
  OrganizationEditOrganization = "organizationEditOrganization",
  /** column name */
  OrganizationViewDetails = "organizationViewDetails",
  /** column name */
  OrganizationViewInvoices = "organizationViewInvoices",
  /** column name */
  OrganizationViewMembers = "organizationViewMembers",
  /** column name */
  OrganizationViewStudies = "organizationViewStudies",
}

/** input type for updating data in table "organization_role" */
export type OrganizationRole_Set_Input = {
  createdAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  organizationCreateMember?: InputMaybe<Scalars["Boolean"]["input"]>;
  organizationCreateStudy?: InputMaybe<Scalars["Boolean"]["input"]>;
  organizationDeleteMember?: InputMaybe<Scalars["Boolean"]["input"]>;
  organizationDeleteOrganization?: InputMaybe<Scalars["Boolean"]["input"]>;
  organizationEditBilling?: InputMaybe<Scalars["Boolean"]["input"]>;
  organizationEditMemberRole?: InputMaybe<Scalars["Boolean"]["input"]>;
  organizationEditOrganization?: InputMaybe<Scalars["Boolean"]["input"]>;
  organizationId?: InputMaybe<Scalars["uuid"]["input"]>;
  organizationViewDetails?: InputMaybe<Scalars["Boolean"]["input"]>;
  organizationViewInvoices?: InputMaybe<Scalars["Boolean"]["input"]>;
  organizationViewMembers?: InputMaybe<Scalars["Boolean"]["input"]>;
  organizationViewStudies?: InputMaybe<Scalars["Boolean"]["input"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** Streaming cursor of the table "organizationRole" */
export type OrganizationRole_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: OrganizationRole_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type OrganizationRole_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  organizationCreateMember?: InputMaybe<Scalars["Boolean"]["input"]>;
  organizationCreateStudy?: InputMaybe<Scalars["Boolean"]["input"]>;
  organizationDeleteMember?: InputMaybe<Scalars["Boolean"]["input"]>;
  organizationDeleteOrganization?: InputMaybe<Scalars["Boolean"]["input"]>;
  organizationEditBilling?: InputMaybe<Scalars["Boolean"]["input"]>;
  organizationEditMemberRole?: InputMaybe<Scalars["Boolean"]["input"]>;
  organizationEditOrganization?: InputMaybe<Scalars["Boolean"]["input"]>;
  organizationId?: InputMaybe<Scalars["uuid"]["input"]>;
  organizationViewDetails?: InputMaybe<Scalars["Boolean"]["input"]>;
  organizationViewInvoices?: InputMaybe<Scalars["Boolean"]["input"]>;
  organizationViewMembers?: InputMaybe<Scalars["Boolean"]["input"]>;
  organizationViewStudies?: InputMaybe<Scalars["Boolean"]["input"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** update columns of table "organization_role" */
export enum OrganizationRole_Update_Column {
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  OrganizationCreateMember = "organizationCreateMember",
  /** column name */
  OrganizationCreateStudy = "organizationCreateStudy",
  /** column name */
  OrganizationDeleteMember = "organizationDeleteMember",
  /** column name */
  OrganizationDeleteOrganization = "organizationDeleteOrganization",
  /** column name */
  OrganizationEditBilling = "organizationEditBilling",
  /** column name */
  OrganizationEditMemberRole = "organizationEditMemberRole",
  /** column name */
  OrganizationEditOrganization = "organizationEditOrganization",
  /** column name */
  OrganizationId = "organizationId",
  /** column name */
  OrganizationViewDetails = "organizationViewDetails",
  /** column name */
  OrganizationViewInvoices = "organizationViewInvoices",
  /** column name */
  OrganizationViewMembers = "organizationViewMembers",
  /** column name */
  OrganizationViewStudies = "organizationViewStudies",
  /** column name */
  UpdatedAt = "updatedAt",
}

export type OrganizationRole_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<OrganizationRole_Set_Input>;
  /** filter the rows which have to be updated */
  where: OrganizationRole_Bool_Exp;
};

/** aggregated selection of "organization" */
export type Organization_Aggregate = {
  __typename?: "organization_aggregate";
  aggregate?: Maybe<Organization_Aggregate_Fields>;
  nodes: Array<Organization>;
};

/** aggregate fields of "organization" */
export type Organization_Aggregate_Fields = {
  __typename?: "organization_aggregate_fields";
  count: Scalars["Int"]["output"];
  max?: Maybe<Organization_Max_Fields>;
  min?: Maybe<Organization_Min_Fields>;
};

/** aggregate fields of "organization" */
export type Organization_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Organization_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Boolean expression to filter rows from the table "organization". All fields are combined with a logical 'AND'. */
export type Organization_Bool_Exp = {
  _and?: InputMaybe<Array<Organization_Bool_Exp>>;
  _not?: InputMaybe<Organization_Bool_Exp>;
  _or?: InputMaybe<Array<Organization_Bool_Exp>>;
  address?: InputMaybe<String_Comparison_Exp>;
  city?: InputMaybe<String_Comparison_Exp>;
  contracts?: InputMaybe<Contract_Bool_Exp>;
  contracts_aggregate?: InputMaybe<Contract_Aggregate_Bool_Exp>;
  country?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  deletedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  department?: InputMaybe<String_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  logo?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organizationMembers?: InputMaybe<OrganizationMember_Bool_Exp>;
  organizationMembers_aggregate?: InputMaybe<OrganizationMember_Aggregate_Bool_Exp>;
  organizationRoles?: InputMaybe<OrganizationRole_Bool_Exp>;
  organizationRoles_aggregate?: InputMaybe<OrganizationRole_Aggregate_Bool_Exp>;
  studies?: InputMaybe<Study_Bool_Exp>;
  studies_aggregate?: InputMaybe<Study_Aggregate_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  zip?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "organization" */
export enum Organization_Constraint {
  /** unique or primary key constraint on columns "id" */
  OrganizationPkey = "organization_pkey",
}

/** input type for inserting data into table "organization" */
export type Organization_Insert_Input = {
  address?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  contracts?: InputMaybe<Contract_Arr_Rel_Insert_Input>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  department?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  logo?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  organizationMembers?: InputMaybe<OrganizationMember_Arr_Rel_Insert_Input>;
  organizationRoles?: InputMaybe<OrganizationRole_Arr_Rel_Insert_Input>;
  studies?: InputMaybe<Study_Arr_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  zip?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate max on columns */
export type Organization_Max_Fields = {
  __typename?: "organization_max_fields";
  address?: Maybe<Scalars["String"]["output"]>;
  city?: Maybe<Scalars["String"]["output"]>;
  country?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  department?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  logo?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  zip?: Maybe<Scalars["String"]["output"]>;
};

/** aggregate min on columns */
export type Organization_Min_Fields = {
  __typename?: "organization_min_fields";
  address?: Maybe<Scalars["String"]["output"]>;
  city?: Maybe<Scalars["String"]["output"]>;
  country?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  department?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  logo?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  zip?: Maybe<Scalars["String"]["output"]>;
};

/** response of any mutation on the table "organization" */
export type Organization_Mutation_Response = {
  __typename?: "organization_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Organization>;
};

/** input type for inserting object relation for remote table "organization" */
export type Organization_Obj_Rel_Insert_Input = {
  data: Organization_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Organization_On_Conflict>;
};

/** on_conflict condition type for table "organization" */
export type Organization_On_Conflict = {
  constraint: Organization_Constraint;
  update_columns?: Array<Organization_Update_Column>;
  where?: InputMaybe<Organization_Bool_Exp>;
};

/** Ordering options when selecting data from "organization". */
export type Organization_Order_By = {
  address?: InputMaybe<Order_By>;
  city?: InputMaybe<Order_By>;
  contracts_aggregate?: InputMaybe<Contract_Aggregate_Order_By>;
  country?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  department?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  logo?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organizationMembers_aggregate?: InputMaybe<OrganizationMember_Aggregate_Order_By>;
  organizationRoles_aggregate?: InputMaybe<OrganizationRole_Aggregate_Order_By>;
  studies_aggregate?: InputMaybe<Study_Aggregate_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  zip?: InputMaybe<Order_By>;
};

/** primary key columns input for table: organization */
export type Organization_Pk_Columns_Input = {
  id: Scalars["uuid"]["input"];
};

/** select columns of table "organization" */
export enum Organization_Select_Column {
  /** column name */
  Address = "address",
  /** column name */
  City = "city",
  /** column name */
  Country = "country",
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  Department = "department",
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  Logo = "logo",
  /** column name */
  Name = "name",
  /** column name */
  UpdatedAt = "updatedAt",
  /** column name */
  Zip = "zip",
}

/** input type for updating data in table "organization" */
export type Organization_Set_Input = {
  address?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  department?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  logo?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  zip?: InputMaybe<Scalars["String"]["input"]>;
};

/** Streaming cursor of the table "organization" */
export type Organization_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Organization_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Organization_Stream_Cursor_Value_Input = {
  address?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  department?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  logo?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  zip?: InputMaybe<Scalars["String"]["input"]>;
};

/** update columns of table "organization" */
export enum Organization_Update_Column {
  /** column name */
  Address = "address",
  /** column name */
  City = "city",
  /** column name */
  Country = "country",
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  Department = "department",
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  Logo = "logo",
  /** column name */
  Name = "name",
  /** column name */
  UpdatedAt = "updatedAt",
  /** column name */
  Zip = "zip",
}

export type Organization_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Organization_Set_Input>;
  /** filter the rows which have to be updated */
  where: Organization_Bool_Exp;
};

/** columns and relationships of "participant" */
export type Participant = {
  __typename?: "participant";
  coupledAt?: Maybe<Scalars["timestamptz"]["output"]>;
  createdAt?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  groupId: Scalars["uuid"]["output"];
  id: Scalars["uuid"]["output"];
  /** An object relationship */
  participantGroup: ParticipantsGroup;
  pseudonym: Scalars["String"]["output"];
  /** An array relationship */
  supervisors: Array<Supervisor>;
  /** An aggregate relationship */
  supervisors_aggregate: Supervisor_Aggregate;
  updatedAt?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** columns and relationships of "participant" */
export type ParticipantSupervisorsArgs = {
  distinct_on?: InputMaybe<Array<Supervisor_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Supervisor_Order_By>>;
  where?: InputMaybe<Supervisor_Bool_Exp>;
};

/** columns and relationships of "participant" */
export type ParticipantSupervisors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supervisor_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Supervisor_Order_By>>;
  where?: InputMaybe<Supervisor_Bool_Exp>;
};

/** aggregated selection of "participant" */
export type Participant_Aggregate = {
  __typename?: "participant_aggregate";
  aggregate?: Maybe<Participant_Aggregate_Fields>;
  nodes: Array<Participant>;
};

export type Participant_Aggregate_Bool_Exp = {
  count?: InputMaybe<Participant_Aggregate_Bool_Exp_Count>;
};

export type Participant_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Participant_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<Participant_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "participant" */
export type Participant_Aggregate_Fields = {
  __typename?: "participant_aggregate_fields";
  count: Scalars["Int"]["output"];
  max?: Maybe<Participant_Max_Fields>;
  min?: Maybe<Participant_Min_Fields>;
};

/** aggregate fields of "participant" */
export type Participant_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Participant_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "participant" */
export type Participant_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Participant_Max_Order_By>;
  min?: InputMaybe<Participant_Min_Order_By>;
};

/** input type for inserting array relation for remote table "participant" */
export type Participant_Arr_Rel_Insert_Input = {
  data: Array<Participant_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Participant_On_Conflict>;
};

/** Boolean expression to filter rows from the table "participant". All fields are combined with a logical 'AND'. */
export type Participant_Bool_Exp = {
  _and?: InputMaybe<Array<Participant_Bool_Exp>>;
  _not?: InputMaybe<Participant_Bool_Exp>;
  _or?: InputMaybe<Array<Participant_Bool_Exp>>;
  coupledAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  deletedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  groupId?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  participantGroup?: InputMaybe<ParticipantsGroup_Bool_Exp>;
  pseudonym?: InputMaybe<String_Comparison_Exp>;
  supervisors?: InputMaybe<Supervisor_Bool_Exp>;
  supervisors_aggregate?: InputMaybe<Supervisor_Aggregate_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "participant" */
export enum Participant_Constraint {
  /** unique or primary key constraint on columns "id" */
  ParticipantIdKey = "participant_id_key",
  /** unique or primary key constraint on columns "id" */
  ParticipantPkey = "participant_pkey",
}

/** input type for inserting data into table "participant" */
export type Participant_Insert_Input = {
  coupledAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  createdAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  groupId?: InputMaybe<Scalars["uuid"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  participantGroup?: InputMaybe<ParticipantsGroup_Obj_Rel_Insert_Input>;
  pseudonym?: InputMaybe<Scalars["String"]["input"]>;
  supervisors?: InputMaybe<Supervisor_Arr_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** aggregate max on columns */
export type Participant_Max_Fields = {
  __typename?: "participant_max_fields";
  coupledAt?: Maybe<Scalars["timestamptz"]["output"]>;
  createdAt?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  groupId?: Maybe<Scalars["uuid"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  pseudonym?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** order by max() on columns of table "participant" */
export type Participant_Max_Order_By = {
  coupledAt?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  groupId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pseudonym?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Participant_Min_Fields = {
  __typename?: "participant_min_fields";
  coupledAt?: Maybe<Scalars["timestamptz"]["output"]>;
  createdAt?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  groupId?: Maybe<Scalars["uuid"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  pseudonym?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** order by min() on columns of table "participant" */
export type Participant_Min_Order_By = {
  coupledAt?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  groupId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pseudonym?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "participant" */
export type Participant_Mutation_Response = {
  __typename?: "participant_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Participant>;
};

/** input type for inserting object relation for remote table "participant" */
export type Participant_Obj_Rel_Insert_Input = {
  data: Participant_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Participant_On_Conflict>;
};

/** on_conflict condition type for table "participant" */
export type Participant_On_Conflict = {
  constraint: Participant_Constraint;
  update_columns?: Array<Participant_Update_Column>;
  where?: InputMaybe<Participant_Bool_Exp>;
};

/** Ordering options when selecting data from "participant". */
export type Participant_Order_By = {
  coupledAt?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  groupId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  participantGroup?: InputMaybe<ParticipantsGroup_Order_By>;
  pseudonym?: InputMaybe<Order_By>;
  supervisors_aggregate?: InputMaybe<Supervisor_Aggregate_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: participant */
export type Participant_Pk_Columns_Input = {
  id: Scalars["uuid"]["input"];
};

/** select columns of table "participant" */
export enum Participant_Select_Column {
  /** column name */
  CoupledAt = "coupledAt",
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  GroupId = "groupId",
  /** column name */
  Id = "id",
  /** column name */
  Pseudonym = "pseudonym",
  /** column name */
  UpdatedAt = "updatedAt",
}

/** input type for updating data in table "participant" */
export type Participant_Set_Input = {
  coupledAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  createdAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  groupId?: InputMaybe<Scalars["uuid"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  pseudonym?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** Streaming cursor of the table "participant" */
export type Participant_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Participant_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Participant_Stream_Cursor_Value_Input = {
  coupledAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  createdAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  groupId?: InputMaybe<Scalars["uuid"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  pseudonym?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** update columns of table "participant" */
export enum Participant_Update_Column {
  /** column name */
  CoupledAt = "coupledAt",
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  GroupId = "groupId",
  /** column name */
  Id = "id",
  /** column name */
  Pseudonym = "pseudonym",
  /** column name */
  UpdatedAt = "updatedAt",
}

export type Participant_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Participant_Set_Input>;
  /** filter the rows which have to be updated */
  where: Participant_Bool_Exp;
};

/** columns and relationships of "participants_group" */
export type ParticipantsGroup = {
  __typename?: "participantsGroup";
  createdAt?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["uuid"]["output"];
  name: Scalars["String"]["output"];
  /** An array relationship */
  participants: Array<Participant>;
  /** An aggregate relationship */
  participants_aggregate: Participant_Aggregate;
  /** An object relationship */
  study: Study;
  studyId: Scalars["uuid"]["output"];
  /** An array relationship */
  therapyVariableConfigs: Array<GroupTherapyVariableConfig>;
  /** An aggregate relationship */
  therapyVariableConfigs_aggregate: GroupTherapyVariableConfig_Aggregate;
  updatedAt?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** columns and relationships of "participants_group" */
export type ParticipantsGroupParticipantsArgs = {
  distinct_on?: InputMaybe<Array<Participant_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Participant_Order_By>>;
  where?: InputMaybe<Participant_Bool_Exp>;
};

/** columns and relationships of "participants_group" */
export type ParticipantsGroupParticipants_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Participant_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Participant_Order_By>>;
  where?: InputMaybe<Participant_Bool_Exp>;
};

/** columns and relationships of "participants_group" */
export type ParticipantsGroupTherapyVariableConfigsArgs = {
  distinct_on?: InputMaybe<Array<GroupTherapyVariableConfig_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<GroupTherapyVariableConfig_Order_By>>;
  where?: InputMaybe<GroupTherapyVariableConfig_Bool_Exp>;
};

/** columns and relationships of "participants_group" */
export type ParticipantsGroupTherapyVariableConfigs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GroupTherapyVariableConfig_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<GroupTherapyVariableConfig_Order_By>>;
  where?: InputMaybe<GroupTherapyVariableConfig_Bool_Exp>;
};

/** aggregated selection of "participants_group" */
export type ParticipantsGroup_Aggregate = {
  __typename?: "participantsGroup_aggregate";
  aggregate?: Maybe<ParticipantsGroup_Aggregate_Fields>;
  nodes: Array<ParticipantsGroup>;
};

export type ParticipantsGroup_Aggregate_Bool_Exp = {
  count?: InputMaybe<ParticipantsGroup_Aggregate_Bool_Exp_Count>;
};

export type ParticipantsGroup_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<ParticipantsGroup_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<ParticipantsGroup_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "participants_group" */
export type ParticipantsGroup_Aggregate_Fields = {
  __typename?: "participantsGroup_aggregate_fields";
  count: Scalars["Int"]["output"];
  max?: Maybe<ParticipantsGroup_Max_Fields>;
  min?: Maybe<ParticipantsGroup_Min_Fields>;
};

/** aggregate fields of "participants_group" */
export type ParticipantsGroup_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ParticipantsGroup_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "participants_group" */
export type ParticipantsGroup_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<ParticipantsGroup_Max_Order_By>;
  min?: InputMaybe<ParticipantsGroup_Min_Order_By>;
};

/** input type for inserting array relation for remote table "participants_group" */
export type ParticipantsGroup_Arr_Rel_Insert_Input = {
  data: Array<ParticipantsGroup_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<ParticipantsGroup_On_Conflict>;
};

/** Boolean expression to filter rows from the table "participants_group". All fields are combined with a logical 'AND'. */
export type ParticipantsGroup_Bool_Exp = {
  _and?: InputMaybe<Array<ParticipantsGroup_Bool_Exp>>;
  _not?: InputMaybe<ParticipantsGroup_Bool_Exp>;
  _or?: InputMaybe<Array<ParticipantsGroup_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  deletedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  participants?: InputMaybe<Participant_Bool_Exp>;
  participants_aggregate?: InputMaybe<Participant_Aggregate_Bool_Exp>;
  study?: InputMaybe<Study_Bool_Exp>;
  studyId?: InputMaybe<Uuid_Comparison_Exp>;
  therapyVariableConfigs?: InputMaybe<GroupTherapyVariableConfig_Bool_Exp>;
  therapyVariableConfigs_aggregate?: InputMaybe<GroupTherapyVariableConfig_Aggregate_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "participants_group" */
export enum ParticipantsGroup_Constraint {
  /** unique or primary key constraint on columns "id" */
  StudygroupIdKey = "studygroup_id_key",
  /** unique or primary key constraint on columns "id" */
  StudygroupPkey = "studygroup_pkey",
}

/** input type for inserting data into table "participants_group" */
export type ParticipantsGroup_Insert_Input = {
  createdAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  participants?: InputMaybe<Participant_Arr_Rel_Insert_Input>;
  study?: InputMaybe<Study_Obj_Rel_Insert_Input>;
  studyId?: InputMaybe<Scalars["uuid"]["input"]>;
  therapyVariableConfigs?: InputMaybe<GroupTherapyVariableConfig_Arr_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** aggregate max on columns */
export type ParticipantsGroup_Max_Fields = {
  __typename?: "participantsGroup_max_fields";
  createdAt?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  studyId?: Maybe<Scalars["uuid"]["output"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** order by max() on columns of table "participants_group" */
export type ParticipantsGroup_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  studyId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type ParticipantsGroup_Min_Fields = {
  __typename?: "participantsGroup_min_fields";
  createdAt?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  studyId?: Maybe<Scalars["uuid"]["output"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** order by min() on columns of table "participants_group" */
export type ParticipantsGroup_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  studyId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "participants_group" */
export type ParticipantsGroup_Mutation_Response = {
  __typename?: "participantsGroup_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<ParticipantsGroup>;
};

/** input type for inserting object relation for remote table "participants_group" */
export type ParticipantsGroup_Obj_Rel_Insert_Input = {
  data: ParticipantsGroup_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<ParticipantsGroup_On_Conflict>;
};

/** on_conflict condition type for table "participants_group" */
export type ParticipantsGroup_On_Conflict = {
  constraint: ParticipantsGroup_Constraint;
  update_columns?: Array<ParticipantsGroup_Update_Column>;
  where?: InputMaybe<ParticipantsGroup_Bool_Exp>;
};

/** Ordering options when selecting data from "participants_group". */
export type ParticipantsGroup_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  participants_aggregate?: InputMaybe<Participant_Aggregate_Order_By>;
  study?: InputMaybe<Study_Order_By>;
  studyId?: InputMaybe<Order_By>;
  therapyVariableConfigs_aggregate?: InputMaybe<GroupTherapyVariableConfig_Aggregate_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: participants_group */
export type ParticipantsGroup_Pk_Columns_Input = {
  id: Scalars["uuid"]["input"];
};

/** select columns of table "participants_group" */
export enum ParticipantsGroup_Select_Column {
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  StudyId = "studyId",
  /** column name */
  UpdatedAt = "updatedAt",
}

/** input type for updating data in table "participants_group" */
export type ParticipantsGroup_Set_Input = {
  createdAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  studyId?: InputMaybe<Scalars["uuid"]["input"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** Streaming cursor of the table "participantsGroup" */
export type ParticipantsGroup_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ParticipantsGroup_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ParticipantsGroup_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  studyId?: InputMaybe<Scalars["uuid"]["input"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** update columns of table "participants_group" */
export enum ParticipantsGroup_Update_Column {
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  StudyId = "studyId",
  /** column name */
  UpdatedAt = "updatedAt",
}

export type ParticipantsGroup_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ParticipantsGroup_Set_Input>;
  /** filter the rows which have to be updated */
  where: ParticipantsGroup_Bool_Exp;
};

export type Query_Root = {
  __typename?: "query_root";
  /** fetch aggregated fields from the table: "api_account" */
  aggregateApiAccounts: Api_Account_Aggregate;
  /** fetch aggregated fields from the table: "api_event" */
  aggregateApiEvents: Api_Event_Aggregate;
  /** fetch aggregated fields from the table: "backend_event" */
  aggregateBackendEvent: Backend_Event_Aggregate;
  /** fetch aggregated fields from the table: "contract_type_enum" */
  aggregateContractTypeEnums: ContractTypeEnum_Aggregate;
  /** fetch aggregated fields from the table: "contract" */
  aggregateContracts: Contract_Aggregate;
  /** fetch aggregated fields from the table: "database_event" */
  aggregateDatabaseEvent: Database_Event_Aggregate;
  /** fetch aggregated fields from the table: "executor" */
  aggregateExecutors: Executor_Aggregate;
  /** fetch aggregated fields from the table: "group_therapy_variable_config" */
  aggregateGroupTherapyVariableConfigs: GroupTherapyVariableConfig_Aggregate;
  /** fetch aggregated fields from the table: "organization_member" */
  aggregateOrganizationMembers: OrganizationMember_Aggregate;
  /** fetch aggregated fields from the table: "organization_role" */
  aggregateOrganizationRoles: OrganizationRole_Aggregate;
  /** fetch aggregated fields from the table: "organization" */
  aggregateOrganizations: Organization_Aggregate;
  /** fetch aggregated fields from the table: "participant" */
  aggregateParticipants: Participant_Aggregate;
  /** fetch aggregated fields from the table: "participants_group" */
  aggregateParticipantsGroups: ParticipantsGroup_Aggregate;
  /** fetch aggregated fields from the table: "study" */
  aggregateStudies: Study_Aggregate;
  /** fetch aggregated fields from the table: "study_member" */
  aggregateStudyMembers: StudyMember_Aggregate;
  /** fetch aggregated fields from the table: "study_role" */
  aggregateStudyRoles: StudyRole_Aggregate;
  /** fetch aggregated fields from the table: "supervisor_role" */
  aggregateSupervisorRoles: SupervisorRole_Aggregate;
  /** fetch aggregated fields from the table: "supervisor" */
  aggregateSupervisors: Supervisor_Aggregate;
  /** fetch aggregated fields from the table: "user" */
  aggregateUsers: User_Aggregate;
  /** fetch data from the table: "api_account" using primary key columns */
  apiAccount?: Maybe<Api_Account>;
  /** An array relationship */
  apiAccounts: Array<Api_Account>;
  /** fetch data from the table: "api_event" using primary key columns */
  apiEvent?: Maybe<Api_Event>;
  /** An array relationship */
  apiEvents: Array<Api_Event>;
  /** fetch data from the table: "audit_log" using primary key columns */
  auditLog?: Maybe<Audit_Log>;
  /** fetch aggregated fields from the table: "audit_log" */
  auditLogAggregate: Audit_Log_Aggregate;
  /** fetch data from the table: "audit_log" */
  auditLogs: Array<Audit_Log>;
  /** fetch data from the table: "backend_event" */
  backendEvents: Array<Backend_Event>;
  canDeleteAccountAction: CanDeleteAccountOutput;
  canDeleteStudyAction: CanDeleteStudyOutput;
  canLeaveOrganizationAction: CanLeaveOrganizationOutput;
  canLeaveStudyAction: CanLeaveStudyOutput;
  /** fetch data from the table: "contract" using primary key columns */
  contract?: Maybe<Contract>;
  /** fetch data from the table: "contract_type_enum" using primary key columns */
  contractTypeEnum?: Maybe<ContractTypeEnum>;
  /** fetch data from the table: "contract_type_enum" */
  contractTypeEnums: Array<ContractTypeEnum>;
  /** An array relationship */
  contracts: Array<Contract>;
  /** fetch data from the table: "database_event" */
  databaseEvents: Array<Database_Event>;
  /** fetch data from the table: "executor" using primary key columns */
  executor?: Maybe<Executor>;
  /** fetch data from the table: "executor" */
  executors: Array<Executor>;
  getAccountDataAction?: Maybe<GetPersonalKeycloakDataOutput>;
  getExecutorStatisticsAction?: Maybe<GetExecutorStatisticsOutput>;
  getGroupsTherapyVariableConfigAction: GetGroupsTherapyVariableConfigActionOutput;
  getLabelingInformationAction?: Maybe<LabelingInformation>;
  getMasterUsersAction: Array<MasterUser>;
  getOrganizationMembersAction?: Maybe<Array<OrganizationMemberAction>>;
  getParticipantAction: ParticipantOutput;
  getParticipantTherapyVariableConfigAction?: Maybe<Array<Maybe<GetTherapyVariableValueOutput>>>;
  getParticipantsAction: Array<ParticipantOutput>;
  getStudyMembersAction: Array<StudyMemberAction>;
  getSupervisorsOfParticipantAction: Array<GetSupervisorsOfParticipantOutput>;
  getSupervisorsOfStudyAction: Array<GetSupervisorsOfStudyOutput>;
  getTherapyDesignAction?: Maybe<TherapyDesign>;
  getTherapyDesignsAction: Array<TherapyDesignInfo>;
  getVisualizationsAction?: Maybe<VisualizationPage>;
  /** fetch data from the table: "group_therapy_variable_config" using primary key columns */
  groupTherapyVariableConfig?: Maybe<GroupTherapyVariableConfig>;
  /** fetch data from the table: "group_therapy_variable_config" */
  groupTherapyVariableConfigs: Array<GroupTherapyVariableConfig>;
  /** fetch data from the table: "organization" using primary key columns */
  organization?: Maybe<Organization>;
  /** fetch data from the table: "organization_member" using primary key columns */
  organizationMember?: Maybe<OrganizationMember>;
  /** An array relationship */
  organizationMembers: Array<OrganizationMember>;
  /** fetch data from the table: "organization_role" using primary key columns */
  organizationRole?: Maybe<OrganizationRole>;
  /** An array relationship */
  organizationRoles: Array<OrganizationRole>;
  /** fetch data from the table: "organization" */
  organizations: Array<Organization>;
  /** fetch data from the table: "participant" using primary key columns */
  participant?: Maybe<Participant>;
  /** An array relationship */
  participants: Array<Participant>;
  /** fetch data from the table: "participants_group" using primary key columns */
  participantsGroup?: Maybe<ParticipantsGroup>;
  /** fetch data from the table: "participants_group" */
  participantsGroups: Array<ParticipantsGroup>;
  /** An array relationship */
  studies: Array<Study>;
  /** fetch data from the table: "study" using primary key columns */
  study?: Maybe<Study>;
  /** fetch data from the table: "study_member" using primary key columns */
  studyMember?: Maybe<StudyMember>;
  /** An array relationship */
  studyMembers: Array<StudyMember>;
  /** fetch data from the table: "study_role" using primary key columns */
  studyRole?: Maybe<StudyRole>;
  /** An array relationship */
  studyRoles: Array<StudyRole>;
  /** fetch data from the table: "supervisor" using primary key columns */
  supervisor?: Maybe<Supervisor>;
  /** fetch data from the table: "supervisor_role" using primary key columns */
  supervisorRole?: Maybe<SupervisorRole>;
  /** An array relationship */
  supervisorRoles: Array<SupervisorRole>;
  /** An array relationship */
  supervisors: Array<Supervisor>;
  /** fetch data from the table: "user" using primary key columns */
  user?: Maybe<User>;
  /** fetch data from the table: "user" */
  users: Array<User>;
  validateParticipantTherapyConfigAction: TherapyConfigValidationResult;
};

export type Query_RootAggregateApiAccountsArgs = {
  distinct_on?: InputMaybe<Array<Api_Account_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Api_Account_Order_By>>;
  where?: InputMaybe<Api_Account_Bool_Exp>;
};

export type Query_RootAggregateApiEventsArgs = {
  distinct_on?: InputMaybe<Array<Api_Event_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Api_Event_Order_By>>;
  where?: InputMaybe<Api_Event_Bool_Exp>;
};

export type Query_RootAggregateBackendEventArgs = {
  distinct_on?: InputMaybe<Array<Backend_Event_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Backend_Event_Order_By>>;
  where?: InputMaybe<Backend_Event_Bool_Exp>;
};

export type Query_RootAggregateContractTypeEnumsArgs = {
  distinct_on?: InputMaybe<Array<ContractTypeEnum_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<ContractTypeEnum_Order_By>>;
  where?: InputMaybe<ContractTypeEnum_Bool_Exp>;
};

export type Query_RootAggregateContractsArgs = {
  distinct_on?: InputMaybe<Array<Contract_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Contract_Order_By>>;
  where?: InputMaybe<Contract_Bool_Exp>;
};

export type Query_RootAggregateDatabaseEventArgs = {
  distinct_on?: InputMaybe<Array<Database_Event_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Database_Event_Order_By>>;
  where?: InputMaybe<Database_Event_Bool_Exp>;
};

export type Query_RootAggregateExecutorsArgs = {
  distinct_on?: InputMaybe<Array<Executor_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Executor_Order_By>>;
  where?: InputMaybe<Executor_Bool_Exp>;
};

export type Query_RootAggregateGroupTherapyVariableConfigsArgs = {
  distinct_on?: InputMaybe<Array<GroupTherapyVariableConfig_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<GroupTherapyVariableConfig_Order_By>>;
  where?: InputMaybe<GroupTherapyVariableConfig_Bool_Exp>;
};

export type Query_RootAggregateOrganizationMembersArgs = {
  distinct_on?: InputMaybe<Array<OrganizationMember_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<OrganizationMember_Order_By>>;
  where?: InputMaybe<OrganizationMember_Bool_Exp>;
};

export type Query_RootAggregateOrganizationRolesArgs = {
  distinct_on?: InputMaybe<Array<OrganizationRole_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<OrganizationRole_Order_By>>;
  where?: InputMaybe<OrganizationRole_Bool_Exp>;
};

export type Query_RootAggregateOrganizationsArgs = {
  distinct_on?: InputMaybe<Array<Organization_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Organization_Order_By>>;
  where?: InputMaybe<Organization_Bool_Exp>;
};

export type Query_RootAggregateParticipantsArgs = {
  distinct_on?: InputMaybe<Array<Participant_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Participant_Order_By>>;
  where?: InputMaybe<Participant_Bool_Exp>;
};

export type Query_RootAggregateParticipantsGroupsArgs = {
  distinct_on?: InputMaybe<Array<ParticipantsGroup_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<ParticipantsGroup_Order_By>>;
  where?: InputMaybe<ParticipantsGroup_Bool_Exp>;
};

export type Query_RootAggregateStudiesArgs = {
  distinct_on?: InputMaybe<Array<Study_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Study_Order_By>>;
  where?: InputMaybe<Study_Bool_Exp>;
};

export type Query_RootAggregateStudyMembersArgs = {
  distinct_on?: InputMaybe<Array<StudyMember_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<StudyMember_Order_By>>;
  where?: InputMaybe<StudyMember_Bool_Exp>;
};

export type Query_RootAggregateStudyRolesArgs = {
  distinct_on?: InputMaybe<Array<StudyRole_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<StudyRole_Order_By>>;
  where?: InputMaybe<StudyRole_Bool_Exp>;
};

export type Query_RootAggregateSupervisorRolesArgs = {
  distinct_on?: InputMaybe<Array<SupervisorRole_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<SupervisorRole_Order_By>>;
  where?: InputMaybe<SupervisorRole_Bool_Exp>;
};

export type Query_RootAggregateSupervisorsArgs = {
  distinct_on?: InputMaybe<Array<Supervisor_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Supervisor_Order_By>>;
  where?: InputMaybe<Supervisor_Bool_Exp>;
};

export type Query_RootAggregateUsersArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};

export type Query_RootApiAccountArgs = {
  id: Scalars["uuid"]["input"];
};

export type Query_RootApiAccountsArgs = {
  distinct_on?: InputMaybe<Array<Api_Account_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Api_Account_Order_By>>;
  where?: InputMaybe<Api_Account_Bool_Exp>;
};

export type Query_RootApiEventArgs = {
  id: Scalars["uuid"]["input"];
};

export type Query_RootApiEventsArgs = {
  distinct_on?: InputMaybe<Array<Api_Event_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Api_Event_Order_By>>;
  where?: InputMaybe<Api_Event_Bool_Exp>;
};

export type Query_RootAuditLogArgs = {
  id: Scalars["uuid"]["input"];
};

export type Query_RootAuditLogAggregateArgs = {
  distinct_on?: InputMaybe<Array<Audit_Log_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Audit_Log_Order_By>>;
  where?: InputMaybe<Audit_Log_Bool_Exp>;
};

export type Query_RootAuditLogsArgs = {
  distinct_on?: InputMaybe<Array<Audit_Log_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Audit_Log_Order_By>>;
  where?: InputMaybe<Audit_Log_Bool_Exp>;
};

export type Query_RootBackendEventsArgs = {
  distinct_on?: InputMaybe<Array<Backend_Event_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Backend_Event_Order_By>>;
  where?: InputMaybe<Backend_Event_Bool_Exp>;
};

export type Query_RootCanDeleteAccountActionArgs = {
  userId: Scalars["uuid"]["input"];
};

export type Query_RootCanDeleteStudyActionArgs = {
  studyId: Scalars["uuid"]["input"];
};

export type Query_RootCanLeaveOrganizationActionArgs = {
  organizationId: Scalars["uuid"]["input"];
  userId: Scalars["uuid"]["input"];
};

export type Query_RootCanLeaveStudyActionArgs = {
  studyId: Scalars["uuid"]["input"];
  userId: Scalars["uuid"]["input"];
};

export type Query_RootContractArgs = {
  id: Scalars["uuid"]["input"];
};

export type Query_RootContractTypeEnumArgs = {
  contractType: Scalars["String"]["input"];
};

export type Query_RootContractTypeEnumsArgs = {
  distinct_on?: InputMaybe<Array<ContractTypeEnum_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<ContractTypeEnum_Order_By>>;
  where?: InputMaybe<ContractTypeEnum_Bool_Exp>;
};

export type Query_RootContractsArgs = {
  distinct_on?: InputMaybe<Array<Contract_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Contract_Order_By>>;
  where?: InputMaybe<Contract_Bool_Exp>;
};

export type Query_RootDatabaseEventsArgs = {
  distinct_on?: InputMaybe<Array<Database_Event_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Database_Event_Order_By>>;
  where?: InputMaybe<Database_Event_Bool_Exp>;
};

export type Query_RootExecutorArgs = {
  id: Scalars["uuid"]["input"];
};

export type Query_RootExecutorsArgs = {
  distinct_on?: InputMaybe<Array<Executor_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Executor_Order_By>>;
  where?: InputMaybe<Executor_Bool_Exp>;
};

export type Query_RootGetAccountDataActionArgs = {
  userId: Scalars["uuid"]["input"];
};

export type Query_RootGetExecutorStatisticsActionArgs = {
  studyId: Scalars["uuid"]["input"];
};

export type Query_RootGetGroupsTherapyVariableConfigActionArgs = {
  studyId: Scalars["uuid"]["input"];
};

export type Query_RootGetLabelingInformationActionArgs = {
  language?: InputMaybe<Scalars["String"]["input"]>;
  studyId: Scalars["uuid"]["input"];
};

export type Query_RootGetOrganizationMembersActionArgs = {
  organizationId: Scalars["uuid"]["input"];
};

export type Query_RootGetParticipantActionArgs = {
  id: Scalars["uuid"]["input"];
};

export type Query_RootGetParticipantTherapyVariableConfigActionArgs = {
  participantId: Scalars["uuid"]["input"];
};

export type Query_RootGetParticipantsActionArgs = {
  therapyId: Scalars["uuid"]["input"];
};

export type Query_RootGetStudyMembersActionArgs = {
  studyId: Scalars["uuid"]["input"];
};

export type Query_RootGetSupervisorsOfParticipantActionArgs = {
  participantId: Scalars["uuid"]["input"];
};

export type Query_RootGetSupervisorsOfStudyActionArgs = {
  studyId: Scalars["uuid"]["input"];
};

export type Query_RootGetTherapyDesignActionArgs = {
  language?: InputMaybe<Scalars["String"]["input"]>;
  studyId: Scalars["uuid"]["input"];
};

export type Query_RootGetTherapyDesignsActionArgs = {
  executorUrl: Scalars["String"]["input"];
};

export type Query_RootGetVisualizationsActionArgs = {
  end?: InputMaybe<Scalars["String"]["input"]>;
  language?: InputMaybe<Scalars["String"]["input"]>;
  participantId: Scalars["uuid"]["input"];
  start?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_RootGroupTherapyVariableConfigArgs = {
  participantsGroupId: Scalars["uuid"]["input"];
  variableId: Scalars["uuid"]["input"];
};

export type Query_RootGroupTherapyVariableConfigsArgs = {
  distinct_on?: InputMaybe<Array<GroupTherapyVariableConfig_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<GroupTherapyVariableConfig_Order_By>>;
  where?: InputMaybe<GroupTherapyVariableConfig_Bool_Exp>;
};

export type Query_RootOrganizationArgs = {
  id: Scalars["uuid"]["input"];
};

export type Query_RootOrganizationMemberArgs = {
  organizationId: Scalars["uuid"]["input"];
  userId: Scalars["uuid"]["input"];
};

export type Query_RootOrganizationMembersArgs = {
  distinct_on?: InputMaybe<Array<OrganizationMember_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<OrganizationMember_Order_By>>;
  where?: InputMaybe<OrganizationMember_Bool_Exp>;
};

export type Query_RootOrganizationRoleArgs = {
  id: Scalars["uuid"]["input"];
};

export type Query_RootOrganizationRolesArgs = {
  distinct_on?: InputMaybe<Array<OrganizationRole_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<OrganizationRole_Order_By>>;
  where?: InputMaybe<OrganizationRole_Bool_Exp>;
};

export type Query_RootOrganizationsArgs = {
  distinct_on?: InputMaybe<Array<Organization_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Organization_Order_By>>;
  where?: InputMaybe<Organization_Bool_Exp>;
};

export type Query_RootParticipantArgs = {
  id: Scalars["uuid"]["input"];
};

export type Query_RootParticipantsArgs = {
  distinct_on?: InputMaybe<Array<Participant_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Participant_Order_By>>;
  where?: InputMaybe<Participant_Bool_Exp>;
};

export type Query_RootParticipantsGroupArgs = {
  id: Scalars["uuid"]["input"];
};

export type Query_RootParticipantsGroupsArgs = {
  distinct_on?: InputMaybe<Array<ParticipantsGroup_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<ParticipantsGroup_Order_By>>;
  where?: InputMaybe<ParticipantsGroup_Bool_Exp>;
};

export type Query_RootStudiesArgs = {
  distinct_on?: InputMaybe<Array<Study_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Study_Order_By>>;
  where?: InputMaybe<Study_Bool_Exp>;
};

export type Query_RootStudyArgs = {
  id: Scalars["uuid"]["input"];
};

export type Query_RootStudyMemberArgs = {
  studyId: Scalars["uuid"]["input"];
  userId: Scalars["uuid"]["input"];
};

export type Query_RootStudyMembersArgs = {
  distinct_on?: InputMaybe<Array<StudyMember_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<StudyMember_Order_By>>;
  where?: InputMaybe<StudyMember_Bool_Exp>;
};

export type Query_RootStudyRoleArgs = {
  id: Scalars["uuid"]["input"];
};

export type Query_RootStudyRolesArgs = {
  distinct_on?: InputMaybe<Array<StudyRole_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<StudyRole_Order_By>>;
  where?: InputMaybe<StudyRole_Bool_Exp>;
};

export type Query_RootSupervisorArgs = {
  participantId: Scalars["uuid"]["input"];
  userId: Scalars["uuid"]["input"];
};

export type Query_RootSupervisorRoleArgs = {
  id: Scalars["uuid"]["input"];
};

export type Query_RootSupervisorRolesArgs = {
  distinct_on?: InputMaybe<Array<SupervisorRole_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<SupervisorRole_Order_By>>;
  where?: InputMaybe<SupervisorRole_Bool_Exp>;
};

export type Query_RootSupervisorsArgs = {
  distinct_on?: InputMaybe<Array<Supervisor_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Supervisor_Order_By>>;
  where?: InputMaybe<Supervisor_Bool_Exp>;
};

export type Query_RootUserArgs = {
  id: Scalars["uuid"]["input"];
};

export type Query_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};

export type Query_RootValidateParticipantTherapyConfigActionArgs = {
  language: Scalars["String"]["input"];
  participantId: Scalars["uuid"]["input"];
  values?: InputMaybe<Array<VariableConfigValue>>;
};

/** columns and relationships of "study" */
export type Study = {
  __typename?: "study";
  allowedEmailDomains?: Maybe<Scalars["String"]["output"]>;
  /** An array relationship */
  apiAccounts: Array<Api_Account>;
  /** An aggregate relationship */
  apiAccounts_aggregate: Api_Account_Aggregate;
  createdAt?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  /** An object relationship */
  executor: Executor;
  executorId: Scalars["uuid"]["output"];
  id: Scalars["uuid"]["output"];
  logo?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  /** An object relationship */
  organization: Organization;
  organizationId: Scalars["uuid"]["output"];
  /** An array relationship */
  participantGroups: Array<ParticipantsGroup>;
  /** An aggregate relationship */
  participantGroups_aggregate: ParticipantsGroup_Aggregate;
  /** An array relationship */
  studyMembers: Array<StudyMember>;
  /** An aggregate relationship */
  studyMembers_aggregate: StudyMember_Aggregate;
  /** An array relationship */
  studyRoles: Array<StudyRole>;
  /** An aggregate relationship */
  studyRoles_aggregate: StudyRole_Aggregate;
  /** An array relationship */
  supervisorRoles: Array<SupervisorRole>;
  /** An aggregate relationship */
  supervisorRoles_aggregate: SupervisorRole_Aggregate;
  therapyId: Scalars["uuid"]["output"];
  updatedAt?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** columns and relationships of "study" */
export type StudyApiAccountsArgs = {
  distinct_on?: InputMaybe<Array<Api_Account_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Api_Account_Order_By>>;
  where?: InputMaybe<Api_Account_Bool_Exp>;
};

/** columns and relationships of "study" */
export type StudyApiAccounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Api_Account_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Api_Account_Order_By>>;
  where?: InputMaybe<Api_Account_Bool_Exp>;
};

/** columns and relationships of "study" */
export type StudyParticipantGroupsArgs = {
  distinct_on?: InputMaybe<Array<ParticipantsGroup_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<ParticipantsGroup_Order_By>>;
  where?: InputMaybe<ParticipantsGroup_Bool_Exp>;
};

/** columns and relationships of "study" */
export type StudyParticipantGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ParticipantsGroup_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<ParticipantsGroup_Order_By>>;
  where?: InputMaybe<ParticipantsGroup_Bool_Exp>;
};

/** columns and relationships of "study" */
export type StudyStudyMembersArgs = {
  distinct_on?: InputMaybe<Array<StudyMember_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<StudyMember_Order_By>>;
  where?: InputMaybe<StudyMember_Bool_Exp>;
};

/** columns and relationships of "study" */
export type StudyStudyMembers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<StudyMember_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<StudyMember_Order_By>>;
  where?: InputMaybe<StudyMember_Bool_Exp>;
};

/** columns and relationships of "study" */
export type StudyStudyRolesArgs = {
  distinct_on?: InputMaybe<Array<StudyRole_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<StudyRole_Order_By>>;
  where?: InputMaybe<StudyRole_Bool_Exp>;
};

/** columns and relationships of "study" */
export type StudyStudyRoles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<StudyRole_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<StudyRole_Order_By>>;
  where?: InputMaybe<StudyRole_Bool_Exp>;
};

/** columns and relationships of "study" */
export type StudySupervisorRolesArgs = {
  distinct_on?: InputMaybe<Array<SupervisorRole_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<SupervisorRole_Order_By>>;
  where?: InputMaybe<SupervisorRole_Bool_Exp>;
};

/** columns and relationships of "study" */
export type StudySupervisorRoles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SupervisorRole_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<SupervisorRole_Order_By>>;
  where?: InputMaybe<SupervisorRole_Bool_Exp>;
};

/** columns and relationships of "study_member" */
export type StudyMember = {
  __typename?: "studyMember";
  createdAt?: Maybe<Scalars["timestamptz"]["output"]>;
  id: Scalars["uuid"]["output"];
  /** An object relationship */
  study: Study;
  studyId: Scalars["uuid"]["output"];
  /** An object relationship */
  studyRole: StudyRole;
  studyRoleId: Scalars["uuid"]["output"];
  updatedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** An object relationship */
  user: User;
  userId: Scalars["uuid"]["output"];
};

/** aggregated selection of "study_member" */
export type StudyMember_Aggregate = {
  __typename?: "studyMember_aggregate";
  aggregate?: Maybe<StudyMember_Aggregate_Fields>;
  nodes: Array<StudyMember>;
};

export type StudyMember_Aggregate_Bool_Exp = {
  count?: InputMaybe<StudyMember_Aggregate_Bool_Exp_Count>;
};

export type StudyMember_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<StudyMember_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<StudyMember_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "study_member" */
export type StudyMember_Aggregate_Fields = {
  __typename?: "studyMember_aggregate_fields";
  count: Scalars["Int"]["output"];
  max?: Maybe<StudyMember_Max_Fields>;
  min?: Maybe<StudyMember_Min_Fields>;
};

/** aggregate fields of "study_member" */
export type StudyMember_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<StudyMember_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "study_member" */
export type StudyMember_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<StudyMember_Max_Order_By>;
  min?: InputMaybe<StudyMember_Min_Order_By>;
};

/** input type for inserting array relation for remote table "study_member" */
export type StudyMember_Arr_Rel_Insert_Input = {
  data: Array<StudyMember_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<StudyMember_On_Conflict>;
};

/** Boolean expression to filter rows from the table "study_member". All fields are combined with a logical 'AND'. */
export type StudyMember_Bool_Exp = {
  _and?: InputMaybe<Array<StudyMember_Bool_Exp>>;
  _not?: InputMaybe<StudyMember_Bool_Exp>;
  _or?: InputMaybe<Array<StudyMember_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  study?: InputMaybe<Study_Bool_Exp>;
  studyId?: InputMaybe<Uuid_Comparison_Exp>;
  studyRole?: InputMaybe<StudyRole_Bool_Exp>;
  studyRoleId?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  userId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "study_member" */
export enum StudyMember_Constraint {
  /** unique or primary key constraint on columns "id" */
  StudyroleIdKey = "studyrole_id_key",
  /** unique or primary key constraint on columns "study_id", "user_id" */
  StudyrolePkey = "studyrole_pkey",
}

/** input type for inserting data into table "study_member" */
export type StudyMember_Insert_Input = {
  createdAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  study?: InputMaybe<Study_Obj_Rel_Insert_Input>;
  studyId?: InputMaybe<Scalars["uuid"]["input"]>;
  studyRole?: InputMaybe<StudyRole_Obj_Rel_Insert_Input>;
  studyRoleId?: InputMaybe<Scalars["uuid"]["input"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars["uuid"]["input"]>;
};

/** aggregate max on columns */
export type StudyMember_Max_Fields = {
  __typename?: "studyMember_max_fields";
  createdAt?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  studyId?: Maybe<Scalars["uuid"]["output"]>;
  studyRoleId?: Maybe<Scalars["uuid"]["output"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  userId?: Maybe<Scalars["uuid"]["output"]>;
};

/** order by max() on columns of table "study_member" */
export type StudyMember_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  studyId?: InputMaybe<Order_By>;
  studyRoleId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type StudyMember_Min_Fields = {
  __typename?: "studyMember_min_fields";
  createdAt?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  studyId?: Maybe<Scalars["uuid"]["output"]>;
  studyRoleId?: Maybe<Scalars["uuid"]["output"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  userId?: Maybe<Scalars["uuid"]["output"]>;
};

/** order by min() on columns of table "study_member" */
export type StudyMember_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  studyId?: InputMaybe<Order_By>;
  studyRoleId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "study_member" */
export type StudyMember_Mutation_Response = {
  __typename?: "studyMember_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<StudyMember>;
};

/** on_conflict condition type for table "study_member" */
export type StudyMember_On_Conflict = {
  constraint: StudyMember_Constraint;
  update_columns?: Array<StudyMember_Update_Column>;
  where?: InputMaybe<StudyMember_Bool_Exp>;
};

/** Ordering options when selecting data from "study_member". */
export type StudyMember_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  study?: InputMaybe<Study_Order_By>;
  studyId?: InputMaybe<Order_By>;
  studyRole?: InputMaybe<StudyRole_Order_By>;
  studyRoleId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: study_member */
export type StudyMember_Pk_Columns_Input = {
  studyId: Scalars["uuid"]["input"];
  userId: Scalars["uuid"]["input"];
};

/** select columns of table "study_member" */
export enum StudyMember_Select_Column {
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  Id = "id",
  /** column name */
  StudyId = "studyId",
  /** column name */
  StudyRoleId = "studyRoleId",
  /** column name */
  UpdatedAt = "updatedAt",
  /** column name */
  UserId = "userId",
}

/** input type for updating data in table "study_member" */
export type StudyMember_Set_Input = {
  createdAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  studyId?: InputMaybe<Scalars["uuid"]["input"]>;
  studyRoleId?: InputMaybe<Scalars["uuid"]["input"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  userId?: InputMaybe<Scalars["uuid"]["input"]>;
};

/** Streaming cursor of the table "studyMember" */
export type StudyMember_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: StudyMember_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type StudyMember_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  studyId?: InputMaybe<Scalars["uuid"]["input"]>;
  studyRoleId?: InputMaybe<Scalars["uuid"]["input"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  userId?: InputMaybe<Scalars["uuid"]["input"]>;
};

/** update columns of table "study_member" */
export enum StudyMember_Update_Column {
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  Id = "id",
  /** column name */
  StudyId = "studyId",
  /** column name */
  StudyRoleId = "studyRoleId",
  /** column name */
  UpdatedAt = "updatedAt",
  /** column name */
  UserId = "userId",
}

export type StudyMember_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<StudyMember_Set_Input>;
  /** filter the rows which have to be updated */
  where: StudyMember_Bool_Exp;
};

/** columns and relationships of "study_role" */
export type StudyRole = {
  __typename?: "studyRole";
  createdAt: Scalars["timestamptz"]["output"];
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["uuid"]["output"];
  name: Scalars["String"]["output"];
  participantCreateSupervisor: Scalars["Boolean"]["output"];
  participantDelete: Scalars["Boolean"]["output"];
  participantDownloadData: Scalars["Boolean"]["output"];
  participantEditSettings: Scalars["Boolean"]["output"];
  participantEditTherapyConfig: Scalars["Boolean"]["output"];
  participantViewDetails: Scalars["Boolean"]["output"];
  /** An object relationship */
  study: Study;
  studyCreateGroup: Scalars["Boolean"]["output"];
  studyCreateMember: Scalars["Boolean"]["output"];
  studyCreateParticipant: Scalars["Boolean"]["output"];
  studyDelete: Scalars["Boolean"]["output"];
  studyDeleteGroup: Scalars["Boolean"]["output"];
  studyDeleteMember: Scalars["Boolean"]["output"];
  studyDownloadData: Scalars["Boolean"]["output"];
  studyEdit: Scalars["Boolean"]["output"];
  studyEditGroup: Scalars["Boolean"]["output"];
  studyEditGroupTherapyConfig: Scalars["Boolean"]["output"];
  studyEditMember: Scalars["Boolean"]["output"];
  studyId: Scalars["uuid"]["output"];
  /** An array relationship */
  studyMembers: Array<StudyMember>;
  /** An aggregate relationship */
  studyMembers_aggregate: StudyMember_Aggregate;
  studyViewDetails: Scalars["Boolean"]["output"];
  studyViewGroupAssignment: Scalars["Boolean"]["output"];
  studyViewGroupTherapyConfig: Scalars["Boolean"]["output"];
  studyViewGroups: Scalars["Boolean"]["output"];
  studyViewMembers: Scalars["Boolean"]["output"];
  studyViewParticipants: Scalars["Boolean"]["output"];
  updatedAt: Scalars["timestamptz"]["output"];
};

/** columns and relationships of "study_role" */
export type StudyRoleStudyMembersArgs = {
  distinct_on?: InputMaybe<Array<StudyMember_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<StudyMember_Order_By>>;
  where?: InputMaybe<StudyMember_Bool_Exp>;
};

/** columns and relationships of "study_role" */
export type StudyRoleStudyMembers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<StudyMember_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<StudyMember_Order_By>>;
  where?: InputMaybe<StudyMember_Bool_Exp>;
};

/** aggregated selection of "study_role" */
export type StudyRole_Aggregate = {
  __typename?: "studyRole_aggregate";
  aggregate?: Maybe<StudyRole_Aggregate_Fields>;
  nodes: Array<StudyRole>;
};

export type StudyRole_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<StudyRole_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<StudyRole_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<StudyRole_Aggregate_Bool_Exp_Count>;
};

export type StudyRole_Aggregate_Bool_Exp_Bool_And = {
  arguments: StudyRole_Select_Column_StudyRole_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<StudyRole_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type StudyRole_Aggregate_Bool_Exp_Bool_Or = {
  arguments: StudyRole_Select_Column_StudyRole_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<StudyRole_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type StudyRole_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<StudyRole_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<StudyRole_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "study_role" */
export type StudyRole_Aggregate_Fields = {
  __typename?: "studyRole_aggregate_fields";
  count: Scalars["Int"]["output"];
  max?: Maybe<StudyRole_Max_Fields>;
  min?: Maybe<StudyRole_Min_Fields>;
};

/** aggregate fields of "study_role" */
export type StudyRole_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<StudyRole_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "study_role" */
export type StudyRole_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<StudyRole_Max_Order_By>;
  min?: InputMaybe<StudyRole_Min_Order_By>;
};

/** input type for inserting array relation for remote table "study_role" */
export type StudyRole_Arr_Rel_Insert_Input = {
  data: Array<StudyRole_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<StudyRole_On_Conflict>;
};

/** Boolean expression to filter rows from the table "study_role". All fields are combined with a logical 'AND'. */
export type StudyRole_Bool_Exp = {
  _and?: InputMaybe<Array<StudyRole_Bool_Exp>>;
  _not?: InputMaybe<StudyRole_Bool_Exp>;
  _or?: InputMaybe<Array<StudyRole_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  deletedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  participantCreateSupervisor?: InputMaybe<Boolean_Comparison_Exp>;
  participantDelete?: InputMaybe<Boolean_Comparison_Exp>;
  participantDownloadData?: InputMaybe<Boolean_Comparison_Exp>;
  participantEditSettings?: InputMaybe<Boolean_Comparison_Exp>;
  participantEditTherapyConfig?: InputMaybe<Boolean_Comparison_Exp>;
  participantViewDetails?: InputMaybe<Boolean_Comparison_Exp>;
  study?: InputMaybe<Study_Bool_Exp>;
  studyCreateGroup?: InputMaybe<Boolean_Comparison_Exp>;
  studyCreateMember?: InputMaybe<Boolean_Comparison_Exp>;
  studyCreateParticipant?: InputMaybe<Boolean_Comparison_Exp>;
  studyDelete?: InputMaybe<Boolean_Comparison_Exp>;
  studyDeleteGroup?: InputMaybe<Boolean_Comparison_Exp>;
  studyDeleteMember?: InputMaybe<Boolean_Comparison_Exp>;
  studyDownloadData?: InputMaybe<Boolean_Comparison_Exp>;
  studyEdit?: InputMaybe<Boolean_Comparison_Exp>;
  studyEditGroup?: InputMaybe<Boolean_Comparison_Exp>;
  studyEditGroupTherapyConfig?: InputMaybe<Boolean_Comparison_Exp>;
  studyEditMember?: InputMaybe<Boolean_Comparison_Exp>;
  studyId?: InputMaybe<Uuid_Comparison_Exp>;
  studyMembers?: InputMaybe<StudyMember_Bool_Exp>;
  studyMembers_aggregate?: InputMaybe<StudyMember_Aggregate_Bool_Exp>;
  studyViewDetails?: InputMaybe<Boolean_Comparison_Exp>;
  studyViewGroupAssignment?: InputMaybe<Boolean_Comparison_Exp>;
  studyViewGroupTherapyConfig?: InputMaybe<Boolean_Comparison_Exp>;
  studyViewGroups?: InputMaybe<Boolean_Comparison_Exp>;
  studyViewMembers?: InputMaybe<Boolean_Comparison_Exp>;
  studyViewParticipants?: InputMaybe<Boolean_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "study_role" */
export enum StudyRole_Constraint {
  /** unique or primary key constraint on columns "id" */
  StudyRolePkey = "study_role_pkey",
}

/** input type for inserting data into table "study_role" */
export type StudyRole_Insert_Input = {
  createdAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  participantCreateSupervisor?: InputMaybe<Scalars["Boolean"]["input"]>;
  participantDelete?: InputMaybe<Scalars["Boolean"]["input"]>;
  participantDownloadData?: InputMaybe<Scalars["Boolean"]["input"]>;
  participantEditSettings?: InputMaybe<Scalars["Boolean"]["input"]>;
  participantEditTherapyConfig?: InputMaybe<Scalars["Boolean"]["input"]>;
  participantViewDetails?: InputMaybe<Scalars["Boolean"]["input"]>;
  study?: InputMaybe<Study_Obj_Rel_Insert_Input>;
  studyCreateGroup?: InputMaybe<Scalars["Boolean"]["input"]>;
  studyCreateMember?: InputMaybe<Scalars["Boolean"]["input"]>;
  studyCreateParticipant?: InputMaybe<Scalars["Boolean"]["input"]>;
  studyDelete?: InputMaybe<Scalars["Boolean"]["input"]>;
  studyDeleteGroup?: InputMaybe<Scalars["Boolean"]["input"]>;
  studyDeleteMember?: InputMaybe<Scalars["Boolean"]["input"]>;
  studyDownloadData?: InputMaybe<Scalars["Boolean"]["input"]>;
  studyEdit?: InputMaybe<Scalars["Boolean"]["input"]>;
  studyEditGroup?: InputMaybe<Scalars["Boolean"]["input"]>;
  studyEditGroupTherapyConfig?: InputMaybe<Scalars["Boolean"]["input"]>;
  studyEditMember?: InputMaybe<Scalars["Boolean"]["input"]>;
  studyId?: InputMaybe<Scalars["uuid"]["input"]>;
  studyMembers?: InputMaybe<StudyMember_Arr_Rel_Insert_Input>;
  studyViewDetails?: InputMaybe<Scalars["Boolean"]["input"]>;
  studyViewGroupAssignment?: InputMaybe<Scalars["Boolean"]["input"]>;
  studyViewGroupTherapyConfig?: InputMaybe<Scalars["Boolean"]["input"]>;
  studyViewGroups?: InputMaybe<Scalars["Boolean"]["input"]>;
  studyViewMembers?: InputMaybe<Scalars["Boolean"]["input"]>;
  studyViewParticipants?: InputMaybe<Scalars["Boolean"]["input"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** aggregate max on columns */
export type StudyRole_Max_Fields = {
  __typename?: "studyRole_max_fields";
  createdAt?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  studyId?: Maybe<Scalars["uuid"]["output"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** order by max() on columns of table "study_role" */
export type StudyRole_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  studyId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type StudyRole_Min_Fields = {
  __typename?: "studyRole_min_fields";
  createdAt?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  studyId?: Maybe<Scalars["uuid"]["output"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** order by min() on columns of table "study_role" */
export type StudyRole_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  studyId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "study_role" */
export type StudyRole_Mutation_Response = {
  __typename?: "studyRole_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<StudyRole>;
};

/** input type for inserting object relation for remote table "study_role" */
export type StudyRole_Obj_Rel_Insert_Input = {
  data: StudyRole_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<StudyRole_On_Conflict>;
};

/** on_conflict condition type for table "study_role" */
export type StudyRole_On_Conflict = {
  constraint: StudyRole_Constraint;
  update_columns?: Array<StudyRole_Update_Column>;
  where?: InputMaybe<StudyRole_Bool_Exp>;
};

/** Ordering options when selecting data from "study_role". */
export type StudyRole_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  participantCreateSupervisor?: InputMaybe<Order_By>;
  participantDelete?: InputMaybe<Order_By>;
  participantDownloadData?: InputMaybe<Order_By>;
  participantEditSettings?: InputMaybe<Order_By>;
  participantEditTherapyConfig?: InputMaybe<Order_By>;
  participantViewDetails?: InputMaybe<Order_By>;
  study?: InputMaybe<Study_Order_By>;
  studyCreateGroup?: InputMaybe<Order_By>;
  studyCreateMember?: InputMaybe<Order_By>;
  studyCreateParticipant?: InputMaybe<Order_By>;
  studyDelete?: InputMaybe<Order_By>;
  studyDeleteGroup?: InputMaybe<Order_By>;
  studyDeleteMember?: InputMaybe<Order_By>;
  studyDownloadData?: InputMaybe<Order_By>;
  studyEdit?: InputMaybe<Order_By>;
  studyEditGroup?: InputMaybe<Order_By>;
  studyEditGroupTherapyConfig?: InputMaybe<Order_By>;
  studyEditMember?: InputMaybe<Order_By>;
  studyId?: InputMaybe<Order_By>;
  studyMembers_aggregate?: InputMaybe<StudyMember_Aggregate_Order_By>;
  studyViewDetails?: InputMaybe<Order_By>;
  studyViewGroupAssignment?: InputMaybe<Order_By>;
  studyViewGroupTherapyConfig?: InputMaybe<Order_By>;
  studyViewGroups?: InputMaybe<Order_By>;
  studyViewMembers?: InputMaybe<Order_By>;
  studyViewParticipants?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: study_role */
export type StudyRole_Pk_Columns_Input = {
  id: Scalars["uuid"]["input"];
};

/** select columns of table "study_role" */
export enum StudyRole_Select_Column {
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  ParticipantCreateSupervisor = "participantCreateSupervisor",
  /** column name */
  ParticipantDelete = "participantDelete",
  /** column name */
  ParticipantDownloadData = "participantDownloadData",
  /** column name */
  ParticipantEditSettings = "participantEditSettings",
  /** column name */
  ParticipantEditTherapyConfig = "participantEditTherapyConfig",
  /** column name */
  ParticipantViewDetails = "participantViewDetails",
  /** column name */
  StudyCreateGroup = "studyCreateGroup",
  /** column name */
  StudyCreateMember = "studyCreateMember",
  /** column name */
  StudyCreateParticipant = "studyCreateParticipant",
  /** column name */
  StudyDelete = "studyDelete",
  /** column name */
  StudyDeleteGroup = "studyDeleteGroup",
  /** column name */
  StudyDeleteMember = "studyDeleteMember",
  /** column name */
  StudyDownloadData = "studyDownloadData",
  /** column name */
  StudyEdit = "studyEdit",
  /** column name */
  StudyEditGroup = "studyEditGroup",
  /** column name */
  StudyEditGroupTherapyConfig = "studyEditGroupTherapyConfig",
  /** column name */
  StudyEditMember = "studyEditMember",
  /** column name */
  StudyId = "studyId",
  /** column name */
  StudyViewDetails = "studyViewDetails",
  /** column name */
  StudyViewGroupAssignment = "studyViewGroupAssignment",
  /** column name */
  StudyViewGroupTherapyConfig = "studyViewGroupTherapyConfig",
  /** column name */
  StudyViewGroups = "studyViewGroups",
  /** column name */
  StudyViewMembers = "studyViewMembers",
  /** column name */
  StudyViewParticipants = "studyViewParticipants",
  /** column name */
  UpdatedAt = "updatedAt",
}

/** select "studyRole_aggregate_bool_exp_bool_and_arguments_columns" columns of table "study_role" */
export enum StudyRole_Select_Column_StudyRole_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  ParticipantCreateSupervisor = "participantCreateSupervisor",
  /** column name */
  ParticipantDelete = "participantDelete",
  /** column name */
  ParticipantDownloadData = "participantDownloadData",
  /** column name */
  ParticipantEditSettings = "participantEditSettings",
  /** column name */
  ParticipantEditTherapyConfig = "participantEditTherapyConfig",
  /** column name */
  ParticipantViewDetails = "participantViewDetails",
  /** column name */
  StudyCreateGroup = "studyCreateGroup",
  /** column name */
  StudyCreateMember = "studyCreateMember",
  /** column name */
  StudyCreateParticipant = "studyCreateParticipant",
  /** column name */
  StudyDelete = "studyDelete",
  /** column name */
  StudyDeleteGroup = "studyDeleteGroup",
  /** column name */
  StudyDeleteMember = "studyDeleteMember",
  /** column name */
  StudyDownloadData = "studyDownloadData",
  /** column name */
  StudyEdit = "studyEdit",
  /** column name */
  StudyEditGroup = "studyEditGroup",
  /** column name */
  StudyEditGroupTherapyConfig = "studyEditGroupTherapyConfig",
  /** column name */
  StudyEditMember = "studyEditMember",
  /** column name */
  StudyViewDetails = "studyViewDetails",
  /** column name */
  StudyViewGroupAssignment = "studyViewGroupAssignment",
  /** column name */
  StudyViewGroupTherapyConfig = "studyViewGroupTherapyConfig",
  /** column name */
  StudyViewGroups = "studyViewGroups",
  /** column name */
  StudyViewMembers = "studyViewMembers",
  /** column name */
  StudyViewParticipants = "studyViewParticipants",
}

/** select "studyRole_aggregate_bool_exp_bool_or_arguments_columns" columns of table "study_role" */
export enum StudyRole_Select_Column_StudyRole_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  ParticipantCreateSupervisor = "participantCreateSupervisor",
  /** column name */
  ParticipantDelete = "participantDelete",
  /** column name */
  ParticipantDownloadData = "participantDownloadData",
  /** column name */
  ParticipantEditSettings = "participantEditSettings",
  /** column name */
  ParticipantEditTherapyConfig = "participantEditTherapyConfig",
  /** column name */
  ParticipantViewDetails = "participantViewDetails",
  /** column name */
  StudyCreateGroup = "studyCreateGroup",
  /** column name */
  StudyCreateMember = "studyCreateMember",
  /** column name */
  StudyCreateParticipant = "studyCreateParticipant",
  /** column name */
  StudyDelete = "studyDelete",
  /** column name */
  StudyDeleteGroup = "studyDeleteGroup",
  /** column name */
  StudyDeleteMember = "studyDeleteMember",
  /** column name */
  StudyDownloadData = "studyDownloadData",
  /** column name */
  StudyEdit = "studyEdit",
  /** column name */
  StudyEditGroup = "studyEditGroup",
  /** column name */
  StudyEditGroupTherapyConfig = "studyEditGroupTherapyConfig",
  /** column name */
  StudyEditMember = "studyEditMember",
  /** column name */
  StudyViewDetails = "studyViewDetails",
  /** column name */
  StudyViewGroupAssignment = "studyViewGroupAssignment",
  /** column name */
  StudyViewGroupTherapyConfig = "studyViewGroupTherapyConfig",
  /** column name */
  StudyViewGroups = "studyViewGroups",
  /** column name */
  StudyViewMembers = "studyViewMembers",
  /** column name */
  StudyViewParticipants = "studyViewParticipants",
}

/** input type for updating data in table "study_role" */
export type StudyRole_Set_Input = {
  createdAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  participantCreateSupervisor?: InputMaybe<Scalars["Boolean"]["input"]>;
  participantDelete?: InputMaybe<Scalars["Boolean"]["input"]>;
  participantDownloadData?: InputMaybe<Scalars["Boolean"]["input"]>;
  participantEditSettings?: InputMaybe<Scalars["Boolean"]["input"]>;
  participantEditTherapyConfig?: InputMaybe<Scalars["Boolean"]["input"]>;
  participantViewDetails?: InputMaybe<Scalars["Boolean"]["input"]>;
  studyCreateGroup?: InputMaybe<Scalars["Boolean"]["input"]>;
  studyCreateMember?: InputMaybe<Scalars["Boolean"]["input"]>;
  studyCreateParticipant?: InputMaybe<Scalars["Boolean"]["input"]>;
  studyDelete?: InputMaybe<Scalars["Boolean"]["input"]>;
  studyDeleteGroup?: InputMaybe<Scalars["Boolean"]["input"]>;
  studyDeleteMember?: InputMaybe<Scalars["Boolean"]["input"]>;
  studyDownloadData?: InputMaybe<Scalars["Boolean"]["input"]>;
  studyEdit?: InputMaybe<Scalars["Boolean"]["input"]>;
  studyEditGroup?: InputMaybe<Scalars["Boolean"]["input"]>;
  studyEditGroupTherapyConfig?: InputMaybe<Scalars["Boolean"]["input"]>;
  studyEditMember?: InputMaybe<Scalars["Boolean"]["input"]>;
  studyId?: InputMaybe<Scalars["uuid"]["input"]>;
  studyViewDetails?: InputMaybe<Scalars["Boolean"]["input"]>;
  studyViewGroupAssignment?: InputMaybe<Scalars["Boolean"]["input"]>;
  studyViewGroupTherapyConfig?: InputMaybe<Scalars["Boolean"]["input"]>;
  studyViewGroups?: InputMaybe<Scalars["Boolean"]["input"]>;
  studyViewMembers?: InputMaybe<Scalars["Boolean"]["input"]>;
  studyViewParticipants?: InputMaybe<Scalars["Boolean"]["input"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** Streaming cursor of the table "studyRole" */
export type StudyRole_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: StudyRole_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type StudyRole_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  participantCreateSupervisor?: InputMaybe<Scalars["Boolean"]["input"]>;
  participantDelete?: InputMaybe<Scalars["Boolean"]["input"]>;
  participantDownloadData?: InputMaybe<Scalars["Boolean"]["input"]>;
  participantEditSettings?: InputMaybe<Scalars["Boolean"]["input"]>;
  participantEditTherapyConfig?: InputMaybe<Scalars["Boolean"]["input"]>;
  participantViewDetails?: InputMaybe<Scalars["Boolean"]["input"]>;
  studyCreateGroup?: InputMaybe<Scalars["Boolean"]["input"]>;
  studyCreateMember?: InputMaybe<Scalars["Boolean"]["input"]>;
  studyCreateParticipant?: InputMaybe<Scalars["Boolean"]["input"]>;
  studyDelete?: InputMaybe<Scalars["Boolean"]["input"]>;
  studyDeleteGroup?: InputMaybe<Scalars["Boolean"]["input"]>;
  studyDeleteMember?: InputMaybe<Scalars["Boolean"]["input"]>;
  studyDownloadData?: InputMaybe<Scalars["Boolean"]["input"]>;
  studyEdit?: InputMaybe<Scalars["Boolean"]["input"]>;
  studyEditGroup?: InputMaybe<Scalars["Boolean"]["input"]>;
  studyEditGroupTherapyConfig?: InputMaybe<Scalars["Boolean"]["input"]>;
  studyEditMember?: InputMaybe<Scalars["Boolean"]["input"]>;
  studyId?: InputMaybe<Scalars["uuid"]["input"]>;
  studyViewDetails?: InputMaybe<Scalars["Boolean"]["input"]>;
  studyViewGroupAssignment?: InputMaybe<Scalars["Boolean"]["input"]>;
  studyViewGroupTherapyConfig?: InputMaybe<Scalars["Boolean"]["input"]>;
  studyViewGroups?: InputMaybe<Scalars["Boolean"]["input"]>;
  studyViewMembers?: InputMaybe<Scalars["Boolean"]["input"]>;
  studyViewParticipants?: InputMaybe<Scalars["Boolean"]["input"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** update columns of table "study_role" */
export enum StudyRole_Update_Column {
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  ParticipantCreateSupervisor = "participantCreateSupervisor",
  /** column name */
  ParticipantDelete = "participantDelete",
  /** column name */
  ParticipantDownloadData = "participantDownloadData",
  /** column name */
  ParticipantEditSettings = "participantEditSettings",
  /** column name */
  ParticipantEditTherapyConfig = "participantEditTherapyConfig",
  /** column name */
  ParticipantViewDetails = "participantViewDetails",
  /** column name */
  StudyCreateGroup = "studyCreateGroup",
  /** column name */
  StudyCreateMember = "studyCreateMember",
  /** column name */
  StudyCreateParticipant = "studyCreateParticipant",
  /** column name */
  StudyDelete = "studyDelete",
  /** column name */
  StudyDeleteGroup = "studyDeleteGroup",
  /** column name */
  StudyDeleteMember = "studyDeleteMember",
  /** column name */
  StudyDownloadData = "studyDownloadData",
  /** column name */
  StudyEdit = "studyEdit",
  /** column name */
  StudyEditGroup = "studyEditGroup",
  /** column name */
  StudyEditGroupTherapyConfig = "studyEditGroupTherapyConfig",
  /** column name */
  StudyEditMember = "studyEditMember",
  /** column name */
  StudyId = "studyId",
  /** column name */
  StudyViewDetails = "studyViewDetails",
  /** column name */
  StudyViewGroupAssignment = "studyViewGroupAssignment",
  /** column name */
  StudyViewGroupTherapyConfig = "studyViewGroupTherapyConfig",
  /** column name */
  StudyViewGroups = "studyViewGroups",
  /** column name */
  StudyViewMembers = "studyViewMembers",
  /** column name */
  StudyViewParticipants = "studyViewParticipants",
  /** column name */
  UpdatedAt = "updatedAt",
}

export type StudyRole_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<StudyRole_Set_Input>;
  /** filter the rows which have to be updated */
  where: StudyRole_Bool_Exp;
};

/** aggregated selection of "study" */
export type Study_Aggregate = {
  __typename?: "study_aggregate";
  aggregate?: Maybe<Study_Aggregate_Fields>;
  nodes: Array<Study>;
};

export type Study_Aggregate_Bool_Exp = {
  count?: InputMaybe<Study_Aggregate_Bool_Exp_Count>;
};

export type Study_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Study_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<Study_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "study" */
export type Study_Aggregate_Fields = {
  __typename?: "study_aggregate_fields";
  count: Scalars["Int"]["output"];
  max?: Maybe<Study_Max_Fields>;
  min?: Maybe<Study_Min_Fields>;
};

/** aggregate fields of "study" */
export type Study_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Study_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "study" */
export type Study_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Study_Max_Order_By>;
  min?: InputMaybe<Study_Min_Order_By>;
};

/** input type for inserting array relation for remote table "study" */
export type Study_Arr_Rel_Insert_Input = {
  data: Array<Study_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Study_On_Conflict>;
};

/** Boolean expression to filter rows from the table "study". All fields are combined with a logical 'AND'. */
export type Study_Bool_Exp = {
  _and?: InputMaybe<Array<Study_Bool_Exp>>;
  _not?: InputMaybe<Study_Bool_Exp>;
  _or?: InputMaybe<Array<Study_Bool_Exp>>;
  allowedEmailDomains?: InputMaybe<String_Comparison_Exp>;
  apiAccounts?: InputMaybe<Api_Account_Bool_Exp>;
  apiAccounts_aggregate?: InputMaybe<Api_Account_Aggregate_Bool_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  deletedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  executor?: InputMaybe<Executor_Bool_Exp>;
  executorId?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  logo?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organization?: InputMaybe<Organization_Bool_Exp>;
  organizationId?: InputMaybe<Uuid_Comparison_Exp>;
  participantGroups?: InputMaybe<ParticipantsGroup_Bool_Exp>;
  participantGroups_aggregate?: InputMaybe<ParticipantsGroup_Aggregate_Bool_Exp>;
  studyMembers?: InputMaybe<StudyMember_Bool_Exp>;
  studyMembers_aggregate?: InputMaybe<StudyMember_Aggregate_Bool_Exp>;
  studyRoles?: InputMaybe<StudyRole_Bool_Exp>;
  studyRoles_aggregate?: InputMaybe<StudyRole_Aggregate_Bool_Exp>;
  supervisorRoles?: InputMaybe<SupervisorRole_Bool_Exp>;
  supervisorRoles_aggregate?: InputMaybe<SupervisorRole_Aggregate_Bool_Exp>;
  therapyId?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "study" */
export enum Study_Constraint {
  /** unique or primary key constraint on columns "id" */
  StudyIdKey = "study_id_key",
  /** unique or primary key constraint on columns "id" */
  StudyPkey = "study_pkey",
  /** unique or primary key constraint on columns "therapy_id" */
  StudyTherapyIdKey = "study_therapy_id_key",
}

/** input type for inserting data into table "study" */
export type Study_Insert_Input = {
  allowedEmailDomains?: InputMaybe<Scalars["String"]["input"]>;
  apiAccounts?: InputMaybe<Api_Account_Arr_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  executor?: InputMaybe<Executor_Obj_Rel_Insert_Input>;
  executorId?: InputMaybe<Scalars["uuid"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  logo?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  organization?: InputMaybe<Organization_Obj_Rel_Insert_Input>;
  organizationId?: InputMaybe<Scalars["uuid"]["input"]>;
  participantGroups?: InputMaybe<ParticipantsGroup_Arr_Rel_Insert_Input>;
  studyMembers?: InputMaybe<StudyMember_Arr_Rel_Insert_Input>;
  studyRoles?: InputMaybe<StudyRole_Arr_Rel_Insert_Input>;
  supervisorRoles?: InputMaybe<SupervisorRole_Arr_Rel_Insert_Input>;
  therapyId?: InputMaybe<Scalars["uuid"]["input"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** aggregate max on columns */
export type Study_Max_Fields = {
  __typename?: "study_max_fields";
  allowedEmailDomains?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  executorId?: Maybe<Scalars["uuid"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  logo?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  organizationId?: Maybe<Scalars["uuid"]["output"]>;
  therapyId?: Maybe<Scalars["uuid"]["output"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** order by max() on columns of table "study" */
export type Study_Max_Order_By = {
  allowedEmailDomains?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  executorId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  logo?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organizationId?: InputMaybe<Order_By>;
  therapyId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Study_Min_Fields = {
  __typename?: "study_min_fields";
  allowedEmailDomains?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  executorId?: Maybe<Scalars["uuid"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  logo?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  organizationId?: Maybe<Scalars["uuid"]["output"]>;
  therapyId?: Maybe<Scalars["uuid"]["output"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** order by min() on columns of table "study" */
export type Study_Min_Order_By = {
  allowedEmailDomains?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  executorId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  logo?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organizationId?: InputMaybe<Order_By>;
  therapyId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "study" */
export type Study_Mutation_Response = {
  __typename?: "study_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Study>;
};

/** input type for inserting object relation for remote table "study" */
export type Study_Obj_Rel_Insert_Input = {
  data: Study_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Study_On_Conflict>;
};

/** on_conflict condition type for table "study" */
export type Study_On_Conflict = {
  constraint: Study_Constraint;
  update_columns?: Array<Study_Update_Column>;
  where?: InputMaybe<Study_Bool_Exp>;
};

/** Ordering options when selecting data from "study". */
export type Study_Order_By = {
  allowedEmailDomains?: InputMaybe<Order_By>;
  apiAccounts_aggregate?: InputMaybe<Api_Account_Aggregate_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  executor?: InputMaybe<Executor_Order_By>;
  executorId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  logo?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organization_Order_By>;
  organizationId?: InputMaybe<Order_By>;
  participantGroups_aggregate?: InputMaybe<ParticipantsGroup_Aggregate_Order_By>;
  studyMembers_aggregate?: InputMaybe<StudyMember_Aggregate_Order_By>;
  studyRoles_aggregate?: InputMaybe<StudyRole_Aggregate_Order_By>;
  supervisorRoles_aggregate?: InputMaybe<SupervisorRole_Aggregate_Order_By>;
  therapyId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: study */
export type Study_Pk_Columns_Input = {
  id: Scalars["uuid"]["input"];
};

/** select columns of table "study" */
export enum Study_Select_Column {
  /** column name */
  AllowedEmailDomains = "allowedEmailDomains",
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  Description = "description",
  /** column name */
  ExecutorId = "executorId",
  /** column name */
  Id = "id",
  /** column name */
  Logo = "logo",
  /** column name */
  Name = "name",
  /** column name */
  OrganizationId = "organizationId",
  /** column name */
  TherapyId = "therapyId",
  /** column name */
  UpdatedAt = "updatedAt",
}

/** input type for updating data in table "study" */
export type Study_Set_Input = {
  allowedEmailDomains?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  executorId?: InputMaybe<Scalars["uuid"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  logo?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["uuid"]["input"]>;
  therapyId?: InputMaybe<Scalars["uuid"]["input"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** Streaming cursor of the table "study" */
export type Study_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Study_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Study_Stream_Cursor_Value_Input = {
  allowedEmailDomains?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  executorId?: InputMaybe<Scalars["uuid"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  logo?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["uuid"]["input"]>;
  therapyId?: InputMaybe<Scalars["uuid"]["input"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** update columns of table "study" */
export enum Study_Update_Column {
  /** column name */
  AllowedEmailDomains = "allowedEmailDomains",
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  Description = "description",
  /** column name */
  ExecutorId = "executorId",
  /** column name */
  Id = "id",
  /** column name */
  Logo = "logo",
  /** column name */
  Name = "name",
  /** column name */
  OrganizationId = "organizationId",
  /** column name */
  TherapyId = "therapyId",
  /** column name */
  UpdatedAt = "updatedAt",
}

export type Study_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Study_Set_Input>;
  /** filter the rows which have to be updated */
  where: Study_Bool_Exp;
};

export type Subscription_Root = {
  __typename?: "subscription_root";
  /** fetch aggregated fields from the table: "api_account" */
  aggregateApiAccounts: Api_Account_Aggregate;
  /** fetch aggregated fields from the table: "api_event" */
  aggregateApiEvents: Api_Event_Aggregate;
  /** fetch aggregated fields from the table: "backend_event" */
  aggregateBackendEvent: Backend_Event_Aggregate;
  /** fetch aggregated fields from the table: "contract_type_enum" */
  aggregateContractTypeEnums: ContractTypeEnum_Aggregate;
  /** fetch aggregated fields from the table: "contract" */
  aggregateContracts: Contract_Aggregate;
  /** fetch aggregated fields from the table: "database_event" */
  aggregateDatabaseEvent: Database_Event_Aggregate;
  /** fetch aggregated fields from the table: "executor" */
  aggregateExecutors: Executor_Aggregate;
  /** fetch aggregated fields from the table: "group_therapy_variable_config" */
  aggregateGroupTherapyVariableConfigs: GroupTherapyVariableConfig_Aggregate;
  /** fetch aggregated fields from the table: "organization_member" */
  aggregateOrganizationMembers: OrganizationMember_Aggregate;
  /** fetch aggregated fields from the table: "organization_role" */
  aggregateOrganizationRoles: OrganizationRole_Aggregate;
  /** fetch aggregated fields from the table: "organization" */
  aggregateOrganizations: Organization_Aggregate;
  /** fetch aggregated fields from the table: "participant" */
  aggregateParticipants: Participant_Aggregate;
  /** fetch aggregated fields from the table: "participants_group" */
  aggregateParticipantsGroups: ParticipantsGroup_Aggregate;
  /** fetch aggregated fields from the table: "study" */
  aggregateStudies: Study_Aggregate;
  /** fetch aggregated fields from the table: "study_member" */
  aggregateStudyMembers: StudyMember_Aggregate;
  /** fetch aggregated fields from the table: "study_role" */
  aggregateStudyRoles: StudyRole_Aggregate;
  /** fetch aggregated fields from the table: "supervisor_role" */
  aggregateSupervisorRoles: SupervisorRole_Aggregate;
  /** fetch aggregated fields from the table: "supervisor" */
  aggregateSupervisors: Supervisor_Aggregate;
  /** fetch aggregated fields from the table: "user" */
  aggregateUsers: User_Aggregate;
  /** fetch data from the table: "api_account" using primary key columns */
  apiAccount?: Maybe<Api_Account>;
  /** An array relationship */
  apiAccounts: Array<Api_Account>;
  /** fetch data from the table: "api_event" using primary key columns */
  apiEvent?: Maybe<Api_Event>;
  /** An array relationship */
  apiEvents: Array<Api_Event>;
  /** fetch data from the table in a streaming manner: "api_account" */
  api_account_stream: Array<Api_Account>;
  /** fetch data from the table in a streaming manner: "api_event" */
  api_event_stream: Array<Api_Event>;
  /** fetch data from the table: "audit_log" using primary key columns */
  auditLog?: Maybe<Audit_Log>;
  /** fetch aggregated fields from the table: "audit_log" */
  auditLogAggregate: Audit_Log_Aggregate;
  /** fetch data from the table: "audit_log" */
  auditLogs: Array<Audit_Log>;
  /** fetch data from the table in a streaming manner: "audit_log" */
  audit_log_stream: Array<Audit_Log>;
  /** fetch data from the table: "backend_event" */
  backendEvents: Array<Backend_Event>;
  /** fetch data from the table in a streaming manner: "backend_event" */
  backend_event_stream: Array<Backend_Event>;
  /** fetch data from the table: "contract" using primary key columns */
  contract?: Maybe<Contract>;
  /** fetch data from the table: "contract_type_enum" using primary key columns */
  contractTypeEnum?: Maybe<ContractTypeEnum>;
  /** fetch data from the table in a streaming manner: "contract_type_enum" */
  contractTypeEnum_stream: Array<ContractTypeEnum>;
  /** fetch data from the table: "contract_type_enum" */
  contractTypeEnums: Array<ContractTypeEnum>;
  /** fetch data from the table in a streaming manner: "contract" */
  contract_stream: Array<Contract>;
  /** An array relationship */
  contracts: Array<Contract>;
  /** fetch data from the table: "database_event" */
  databaseEvents: Array<Database_Event>;
  /** fetch data from the table in a streaming manner: "database_event" */
  database_event_stream: Array<Database_Event>;
  /** fetch data from the table: "executor" using primary key columns */
  executor?: Maybe<Executor>;
  /** fetch data from the table in a streaming manner: "executor" */
  executor_stream: Array<Executor>;
  /** fetch data from the table: "executor" */
  executors: Array<Executor>;
  /** fetch data from the table: "group_therapy_variable_config" using primary key columns */
  groupTherapyVariableConfig?: Maybe<GroupTherapyVariableConfig>;
  /** fetch data from the table in a streaming manner: "group_therapy_variable_config" */
  groupTherapyVariableConfig_stream: Array<GroupTherapyVariableConfig>;
  /** fetch data from the table: "group_therapy_variable_config" */
  groupTherapyVariableConfigs: Array<GroupTherapyVariableConfig>;
  /** fetch data from the table: "organization" using primary key columns */
  organization?: Maybe<Organization>;
  /** fetch data from the table: "organization_member" using primary key columns */
  organizationMember?: Maybe<OrganizationMember>;
  /** fetch data from the table in a streaming manner: "organization_member" */
  organizationMember_stream: Array<OrganizationMember>;
  /** An array relationship */
  organizationMembers: Array<OrganizationMember>;
  /** fetch data from the table: "organization_role" using primary key columns */
  organizationRole?: Maybe<OrganizationRole>;
  /** fetch data from the table in a streaming manner: "organization_role" */
  organizationRole_stream: Array<OrganizationRole>;
  /** An array relationship */
  organizationRoles: Array<OrganizationRole>;
  /** fetch data from the table in a streaming manner: "organization" */
  organization_stream: Array<Organization>;
  /** fetch data from the table: "organization" */
  organizations: Array<Organization>;
  /** fetch data from the table: "participant" using primary key columns */
  participant?: Maybe<Participant>;
  /** fetch data from the table in a streaming manner: "participant" */
  participant_stream: Array<Participant>;
  /** An array relationship */
  participants: Array<Participant>;
  /** fetch data from the table: "participants_group" using primary key columns */
  participantsGroup?: Maybe<ParticipantsGroup>;
  /** fetch data from the table in a streaming manner: "participants_group" */
  participantsGroup_stream: Array<ParticipantsGroup>;
  /** fetch data from the table: "participants_group" */
  participantsGroups: Array<ParticipantsGroup>;
  /** An array relationship */
  studies: Array<Study>;
  /** fetch data from the table: "study" using primary key columns */
  study?: Maybe<Study>;
  /** fetch data from the table: "study_member" using primary key columns */
  studyMember?: Maybe<StudyMember>;
  /** fetch data from the table in a streaming manner: "study_member" */
  studyMember_stream: Array<StudyMember>;
  /** An array relationship */
  studyMembers: Array<StudyMember>;
  /** fetch data from the table: "study_role" using primary key columns */
  studyRole?: Maybe<StudyRole>;
  /** fetch data from the table in a streaming manner: "study_role" */
  studyRole_stream: Array<StudyRole>;
  /** An array relationship */
  studyRoles: Array<StudyRole>;
  /** fetch data from the table in a streaming manner: "study" */
  study_stream: Array<Study>;
  /** fetch data from the table: "supervisor" using primary key columns */
  supervisor?: Maybe<Supervisor>;
  /** fetch data from the table: "supervisor_role" using primary key columns */
  supervisorRole?: Maybe<SupervisorRole>;
  /** fetch data from the table in a streaming manner: "supervisor_role" */
  supervisorRole_stream: Array<SupervisorRole>;
  /** An array relationship */
  supervisorRoles: Array<SupervisorRole>;
  /** fetch data from the table in a streaming manner: "supervisor" */
  supervisor_stream: Array<Supervisor>;
  /** An array relationship */
  supervisors: Array<Supervisor>;
  /** fetch data from the table: "user" using primary key columns */
  user?: Maybe<User>;
  /** fetch data from the table in a streaming manner: "user" */
  user_stream: Array<User>;
  /** fetch data from the table: "user" */
  users: Array<User>;
};

export type Subscription_RootAggregateApiAccountsArgs = {
  distinct_on?: InputMaybe<Array<Api_Account_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Api_Account_Order_By>>;
  where?: InputMaybe<Api_Account_Bool_Exp>;
};

export type Subscription_RootAggregateApiEventsArgs = {
  distinct_on?: InputMaybe<Array<Api_Event_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Api_Event_Order_By>>;
  where?: InputMaybe<Api_Event_Bool_Exp>;
};

export type Subscription_RootAggregateBackendEventArgs = {
  distinct_on?: InputMaybe<Array<Backend_Event_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Backend_Event_Order_By>>;
  where?: InputMaybe<Backend_Event_Bool_Exp>;
};

export type Subscription_RootAggregateContractTypeEnumsArgs = {
  distinct_on?: InputMaybe<Array<ContractTypeEnum_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<ContractTypeEnum_Order_By>>;
  where?: InputMaybe<ContractTypeEnum_Bool_Exp>;
};

export type Subscription_RootAggregateContractsArgs = {
  distinct_on?: InputMaybe<Array<Contract_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Contract_Order_By>>;
  where?: InputMaybe<Contract_Bool_Exp>;
};

export type Subscription_RootAggregateDatabaseEventArgs = {
  distinct_on?: InputMaybe<Array<Database_Event_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Database_Event_Order_By>>;
  where?: InputMaybe<Database_Event_Bool_Exp>;
};

export type Subscription_RootAggregateExecutorsArgs = {
  distinct_on?: InputMaybe<Array<Executor_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Executor_Order_By>>;
  where?: InputMaybe<Executor_Bool_Exp>;
};

export type Subscription_RootAggregateGroupTherapyVariableConfigsArgs = {
  distinct_on?: InputMaybe<Array<GroupTherapyVariableConfig_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<GroupTherapyVariableConfig_Order_By>>;
  where?: InputMaybe<GroupTherapyVariableConfig_Bool_Exp>;
};

export type Subscription_RootAggregateOrganizationMembersArgs = {
  distinct_on?: InputMaybe<Array<OrganizationMember_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<OrganizationMember_Order_By>>;
  where?: InputMaybe<OrganizationMember_Bool_Exp>;
};

export type Subscription_RootAggregateOrganizationRolesArgs = {
  distinct_on?: InputMaybe<Array<OrganizationRole_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<OrganizationRole_Order_By>>;
  where?: InputMaybe<OrganizationRole_Bool_Exp>;
};

export type Subscription_RootAggregateOrganizationsArgs = {
  distinct_on?: InputMaybe<Array<Organization_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Organization_Order_By>>;
  where?: InputMaybe<Organization_Bool_Exp>;
};

export type Subscription_RootAggregateParticipantsArgs = {
  distinct_on?: InputMaybe<Array<Participant_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Participant_Order_By>>;
  where?: InputMaybe<Participant_Bool_Exp>;
};

export type Subscription_RootAggregateParticipantsGroupsArgs = {
  distinct_on?: InputMaybe<Array<ParticipantsGroup_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<ParticipantsGroup_Order_By>>;
  where?: InputMaybe<ParticipantsGroup_Bool_Exp>;
};

export type Subscription_RootAggregateStudiesArgs = {
  distinct_on?: InputMaybe<Array<Study_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Study_Order_By>>;
  where?: InputMaybe<Study_Bool_Exp>;
};

export type Subscription_RootAggregateStudyMembersArgs = {
  distinct_on?: InputMaybe<Array<StudyMember_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<StudyMember_Order_By>>;
  where?: InputMaybe<StudyMember_Bool_Exp>;
};

export type Subscription_RootAggregateStudyRolesArgs = {
  distinct_on?: InputMaybe<Array<StudyRole_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<StudyRole_Order_By>>;
  where?: InputMaybe<StudyRole_Bool_Exp>;
};

export type Subscription_RootAggregateSupervisorRolesArgs = {
  distinct_on?: InputMaybe<Array<SupervisorRole_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<SupervisorRole_Order_By>>;
  where?: InputMaybe<SupervisorRole_Bool_Exp>;
};

export type Subscription_RootAggregateSupervisorsArgs = {
  distinct_on?: InputMaybe<Array<Supervisor_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Supervisor_Order_By>>;
  where?: InputMaybe<Supervisor_Bool_Exp>;
};

export type Subscription_RootAggregateUsersArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};

export type Subscription_RootApiAccountArgs = {
  id: Scalars["uuid"]["input"];
};

export type Subscription_RootApiAccountsArgs = {
  distinct_on?: InputMaybe<Array<Api_Account_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Api_Account_Order_By>>;
  where?: InputMaybe<Api_Account_Bool_Exp>;
};

export type Subscription_RootApiEventArgs = {
  id: Scalars["uuid"]["input"];
};

export type Subscription_RootApiEventsArgs = {
  distinct_on?: InputMaybe<Array<Api_Event_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Api_Event_Order_By>>;
  where?: InputMaybe<Api_Event_Bool_Exp>;
};

export type Subscription_RootApi_Account_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Api_Account_Stream_Cursor_Input>>;
  where?: InputMaybe<Api_Account_Bool_Exp>;
};

export type Subscription_RootApi_Event_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Api_Event_Stream_Cursor_Input>>;
  where?: InputMaybe<Api_Event_Bool_Exp>;
};

export type Subscription_RootAuditLogArgs = {
  id: Scalars["uuid"]["input"];
};

export type Subscription_RootAuditLogAggregateArgs = {
  distinct_on?: InputMaybe<Array<Audit_Log_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Audit_Log_Order_By>>;
  where?: InputMaybe<Audit_Log_Bool_Exp>;
};

export type Subscription_RootAuditLogsArgs = {
  distinct_on?: InputMaybe<Array<Audit_Log_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Audit_Log_Order_By>>;
  where?: InputMaybe<Audit_Log_Bool_Exp>;
};

export type Subscription_RootAudit_Log_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Audit_Log_Stream_Cursor_Input>>;
  where?: InputMaybe<Audit_Log_Bool_Exp>;
};

export type Subscription_RootBackendEventsArgs = {
  distinct_on?: InputMaybe<Array<Backend_Event_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Backend_Event_Order_By>>;
  where?: InputMaybe<Backend_Event_Bool_Exp>;
};

export type Subscription_RootBackend_Event_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Backend_Event_Stream_Cursor_Input>>;
  where?: InputMaybe<Backend_Event_Bool_Exp>;
};

export type Subscription_RootContractArgs = {
  id: Scalars["uuid"]["input"];
};

export type Subscription_RootContractTypeEnumArgs = {
  contractType: Scalars["String"]["input"];
};

export type Subscription_RootContractTypeEnum_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<ContractTypeEnum_Stream_Cursor_Input>>;
  where?: InputMaybe<ContractTypeEnum_Bool_Exp>;
};

export type Subscription_RootContractTypeEnumsArgs = {
  distinct_on?: InputMaybe<Array<ContractTypeEnum_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<ContractTypeEnum_Order_By>>;
  where?: InputMaybe<ContractTypeEnum_Bool_Exp>;
};

export type Subscription_RootContract_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Contract_Stream_Cursor_Input>>;
  where?: InputMaybe<Contract_Bool_Exp>;
};

export type Subscription_RootContractsArgs = {
  distinct_on?: InputMaybe<Array<Contract_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Contract_Order_By>>;
  where?: InputMaybe<Contract_Bool_Exp>;
};

export type Subscription_RootDatabaseEventsArgs = {
  distinct_on?: InputMaybe<Array<Database_Event_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Database_Event_Order_By>>;
  where?: InputMaybe<Database_Event_Bool_Exp>;
};

export type Subscription_RootDatabase_Event_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Database_Event_Stream_Cursor_Input>>;
  where?: InputMaybe<Database_Event_Bool_Exp>;
};

export type Subscription_RootExecutorArgs = {
  id: Scalars["uuid"]["input"];
};

export type Subscription_RootExecutor_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Executor_Stream_Cursor_Input>>;
  where?: InputMaybe<Executor_Bool_Exp>;
};

export type Subscription_RootExecutorsArgs = {
  distinct_on?: InputMaybe<Array<Executor_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Executor_Order_By>>;
  where?: InputMaybe<Executor_Bool_Exp>;
};

export type Subscription_RootGroupTherapyVariableConfigArgs = {
  participantsGroupId: Scalars["uuid"]["input"];
  variableId: Scalars["uuid"]["input"];
};

export type Subscription_RootGroupTherapyVariableConfig_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<GroupTherapyVariableConfig_Stream_Cursor_Input>>;
  where?: InputMaybe<GroupTherapyVariableConfig_Bool_Exp>;
};

export type Subscription_RootGroupTherapyVariableConfigsArgs = {
  distinct_on?: InputMaybe<Array<GroupTherapyVariableConfig_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<GroupTherapyVariableConfig_Order_By>>;
  where?: InputMaybe<GroupTherapyVariableConfig_Bool_Exp>;
};

export type Subscription_RootOrganizationArgs = {
  id: Scalars["uuid"]["input"];
};

export type Subscription_RootOrganizationMemberArgs = {
  organizationId: Scalars["uuid"]["input"];
  userId: Scalars["uuid"]["input"];
};

export type Subscription_RootOrganizationMember_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<OrganizationMember_Stream_Cursor_Input>>;
  where?: InputMaybe<OrganizationMember_Bool_Exp>;
};

export type Subscription_RootOrganizationMembersArgs = {
  distinct_on?: InputMaybe<Array<OrganizationMember_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<OrganizationMember_Order_By>>;
  where?: InputMaybe<OrganizationMember_Bool_Exp>;
};

export type Subscription_RootOrganizationRoleArgs = {
  id: Scalars["uuid"]["input"];
};

export type Subscription_RootOrganizationRole_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<OrganizationRole_Stream_Cursor_Input>>;
  where?: InputMaybe<OrganizationRole_Bool_Exp>;
};

export type Subscription_RootOrganizationRolesArgs = {
  distinct_on?: InputMaybe<Array<OrganizationRole_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<OrganizationRole_Order_By>>;
  where?: InputMaybe<OrganizationRole_Bool_Exp>;
};

export type Subscription_RootOrganization_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Organization_Stream_Cursor_Input>>;
  where?: InputMaybe<Organization_Bool_Exp>;
};

export type Subscription_RootOrganizationsArgs = {
  distinct_on?: InputMaybe<Array<Organization_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Organization_Order_By>>;
  where?: InputMaybe<Organization_Bool_Exp>;
};

export type Subscription_RootParticipantArgs = {
  id: Scalars["uuid"]["input"];
};

export type Subscription_RootParticipant_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Participant_Stream_Cursor_Input>>;
  where?: InputMaybe<Participant_Bool_Exp>;
};

export type Subscription_RootParticipantsArgs = {
  distinct_on?: InputMaybe<Array<Participant_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Participant_Order_By>>;
  where?: InputMaybe<Participant_Bool_Exp>;
};

export type Subscription_RootParticipantsGroupArgs = {
  id: Scalars["uuid"]["input"];
};

export type Subscription_RootParticipantsGroup_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<ParticipantsGroup_Stream_Cursor_Input>>;
  where?: InputMaybe<ParticipantsGroup_Bool_Exp>;
};

export type Subscription_RootParticipantsGroupsArgs = {
  distinct_on?: InputMaybe<Array<ParticipantsGroup_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<ParticipantsGroup_Order_By>>;
  where?: InputMaybe<ParticipantsGroup_Bool_Exp>;
};

export type Subscription_RootStudiesArgs = {
  distinct_on?: InputMaybe<Array<Study_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Study_Order_By>>;
  where?: InputMaybe<Study_Bool_Exp>;
};

export type Subscription_RootStudyArgs = {
  id: Scalars["uuid"]["input"];
};

export type Subscription_RootStudyMemberArgs = {
  studyId: Scalars["uuid"]["input"];
  userId: Scalars["uuid"]["input"];
};

export type Subscription_RootStudyMember_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<StudyMember_Stream_Cursor_Input>>;
  where?: InputMaybe<StudyMember_Bool_Exp>;
};

export type Subscription_RootStudyMembersArgs = {
  distinct_on?: InputMaybe<Array<StudyMember_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<StudyMember_Order_By>>;
  where?: InputMaybe<StudyMember_Bool_Exp>;
};

export type Subscription_RootStudyRoleArgs = {
  id: Scalars["uuid"]["input"];
};

export type Subscription_RootStudyRole_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<StudyRole_Stream_Cursor_Input>>;
  where?: InputMaybe<StudyRole_Bool_Exp>;
};

export type Subscription_RootStudyRolesArgs = {
  distinct_on?: InputMaybe<Array<StudyRole_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<StudyRole_Order_By>>;
  where?: InputMaybe<StudyRole_Bool_Exp>;
};

export type Subscription_RootStudy_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Study_Stream_Cursor_Input>>;
  where?: InputMaybe<Study_Bool_Exp>;
};

export type Subscription_RootSupervisorArgs = {
  participantId: Scalars["uuid"]["input"];
  userId: Scalars["uuid"]["input"];
};

export type Subscription_RootSupervisorRoleArgs = {
  id: Scalars["uuid"]["input"];
};

export type Subscription_RootSupervisorRole_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<SupervisorRole_Stream_Cursor_Input>>;
  where?: InputMaybe<SupervisorRole_Bool_Exp>;
};

export type Subscription_RootSupervisorRolesArgs = {
  distinct_on?: InputMaybe<Array<SupervisorRole_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<SupervisorRole_Order_By>>;
  where?: InputMaybe<SupervisorRole_Bool_Exp>;
};

export type Subscription_RootSupervisor_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Supervisor_Stream_Cursor_Input>>;
  where?: InputMaybe<Supervisor_Bool_Exp>;
};

export type Subscription_RootSupervisorsArgs = {
  distinct_on?: InputMaybe<Array<Supervisor_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Supervisor_Order_By>>;
  where?: InputMaybe<Supervisor_Bool_Exp>;
};

export type Subscription_RootUserArgs = {
  id: Scalars["uuid"]["input"];
};

export type Subscription_RootUser_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<User_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Bool_Exp>;
};

export type Subscription_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};

/** columns and relationships of "supervisor" */
export type Supervisor = {
  __typename?: "supervisor";
  createdAt: Scalars["timestamptz"]["output"];
  id: Scalars["uuid"]["output"];
  /** An object relationship */
  participant: Participant;
  participantId: Scalars["uuid"]["output"];
  /** An object relationship */
  supervisorRole: SupervisorRole;
  supervisorRoleId: Scalars["uuid"]["output"];
  updatedAt: Scalars["timestamptz"]["output"];
  /** An object relationship */
  user: User;
  userId: Scalars["uuid"]["output"];
};

/** columns and relationships of "supervisor_role" */
export type SupervisorRole = {
  __typename?: "supervisorRole";
  createdAt: Scalars["timestamptz"]["output"];
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["uuid"]["output"];
  name: Scalars["String"]["output"];
  participantCreateSupervisor: Scalars["Boolean"]["output"];
  participantDownloadData: Scalars["Boolean"]["output"];
  participantEditTherapyConfig: Scalars["Boolean"]["output"];
  participantViewDetails: Scalars["Boolean"]["output"];
  /** An object relationship */
  study: Study;
  studyId: Scalars["uuid"]["output"];
  /** An array relationship */
  supervisors: Array<Supervisor>;
  /** An aggregate relationship */
  supervisors_aggregate: Supervisor_Aggregate;
  updatedAt: Scalars["timestamptz"]["output"];
};

/** columns and relationships of "supervisor_role" */
export type SupervisorRoleSupervisorsArgs = {
  distinct_on?: InputMaybe<Array<Supervisor_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Supervisor_Order_By>>;
  where?: InputMaybe<Supervisor_Bool_Exp>;
};

/** columns and relationships of "supervisor_role" */
export type SupervisorRoleSupervisors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supervisor_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Supervisor_Order_By>>;
  where?: InputMaybe<Supervisor_Bool_Exp>;
};

/** aggregated selection of "supervisor_role" */
export type SupervisorRole_Aggregate = {
  __typename?: "supervisorRole_aggregate";
  aggregate?: Maybe<SupervisorRole_Aggregate_Fields>;
  nodes: Array<SupervisorRole>;
};

export type SupervisorRole_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<SupervisorRole_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<SupervisorRole_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<SupervisorRole_Aggregate_Bool_Exp_Count>;
};

export type SupervisorRole_Aggregate_Bool_Exp_Bool_And = {
  arguments: SupervisorRole_Select_Column_SupervisorRole_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<SupervisorRole_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type SupervisorRole_Aggregate_Bool_Exp_Bool_Or = {
  arguments: SupervisorRole_Select_Column_SupervisorRole_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<SupervisorRole_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type SupervisorRole_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<SupervisorRole_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<SupervisorRole_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "supervisor_role" */
export type SupervisorRole_Aggregate_Fields = {
  __typename?: "supervisorRole_aggregate_fields";
  count: Scalars["Int"]["output"];
  max?: Maybe<SupervisorRole_Max_Fields>;
  min?: Maybe<SupervisorRole_Min_Fields>;
};

/** aggregate fields of "supervisor_role" */
export type SupervisorRole_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<SupervisorRole_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "supervisor_role" */
export type SupervisorRole_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<SupervisorRole_Max_Order_By>;
  min?: InputMaybe<SupervisorRole_Min_Order_By>;
};

/** input type for inserting array relation for remote table "supervisor_role" */
export type SupervisorRole_Arr_Rel_Insert_Input = {
  data: Array<SupervisorRole_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<SupervisorRole_On_Conflict>;
};

/** Boolean expression to filter rows from the table "supervisor_role". All fields are combined with a logical 'AND'. */
export type SupervisorRole_Bool_Exp = {
  _and?: InputMaybe<Array<SupervisorRole_Bool_Exp>>;
  _not?: InputMaybe<SupervisorRole_Bool_Exp>;
  _or?: InputMaybe<Array<SupervisorRole_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  deletedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  participantCreateSupervisor?: InputMaybe<Boolean_Comparison_Exp>;
  participantDownloadData?: InputMaybe<Boolean_Comparison_Exp>;
  participantEditTherapyConfig?: InputMaybe<Boolean_Comparison_Exp>;
  participantViewDetails?: InputMaybe<Boolean_Comparison_Exp>;
  study?: InputMaybe<Study_Bool_Exp>;
  studyId?: InputMaybe<Uuid_Comparison_Exp>;
  supervisors?: InputMaybe<Supervisor_Bool_Exp>;
  supervisors_aggregate?: InputMaybe<Supervisor_Aggregate_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "supervisor_role" */
export enum SupervisorRole_Constraint {
  /** unique or primary key constraint on columns "id" */
  SupervisorRolePkey = "supervisor_role_pkey",
}

/** input type for inserting data into table "supervisor_role" */
export type SupervisorRole_Insert_Input = {
  createdAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  participantCreateSupervisor?: InputMaybe<Scalars["Boolean"]["input"]>;
  participantDownloadData?: InputMaybe<Scalars["Boolean"]["input"]>;
  participantEditTherapyConfig?: InputMaybe<Scalars["Boolean"]["input"]>;
  participantViewDetails?: InputMaybe<Scalars["Boolean"]["input"]>;
  study?: InputMaybe<Study_Obj_Rel_Insert_Input>;
  studyId?: InputMaybe<Scalars["uuid"]["input"]>;
  supervisors?: InputMaybe<Supervisor_Arr_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** aggregate max on columns */
export type SupervisorRole_Max_Fields = {
  __typename?: "supervisorRole_max_fields";
  createdAt?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  studyId?: Maybe<Scalars["uuid"]["output"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** order by max() on columns of table "supervisor_role" */
export type SupervisorRole_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  studyId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type SupervisorRole_Min_Fields = {
  __typename?: "supervisorRole_min_fields";
  createdAt?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  studyId?: Maybe<Scalars["uuid"]["output"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** order by min() on columns of table "supervisor_role" */
export type SupervisorRole_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  studyId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "supervisor_role" */
export type SupervisorRole_Mutation_Response = {
  __typename?: "supervisorRole_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<SupervisorRole>;
};

/** input type for inserting object relation for remote table "supervisor_role" */
export type SupervisorRole_Obj_Rel_Insert_Input = {
  data: SupervisorRole_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<SupervisorRole_On_Conflict>;
};

/** on_conflict condition type for table "supervisor_role" */
export type SupervisorRole_On_Conflict = {
  constraint: SupervisorRole_Constraint;
  update_columns?: Array<SupervisorRole_Update_Column>;
  where?: InputMaybe<SupervisorRole_Bool_Exp>;
};

/** Ordering options when selecting data from "supervisor_role". */
export type SupervisorRole_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  participantCreateSupervisor?: InputMaybe<Order_By>;
  participantDownloadData?: InputMaybe<Order_By>;
  participantEditTherapyConfig?: InputMaybe<Order_By>;
  participantViewDetails?: InputMaybe<Order_By>;
  study?: InputMaybe<Study_Order_By>;
  studyId?: InputMaybe<Order_By>;
  supervisors_aggregate?: InputMaybe<Supervisor_Aggregate_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: supervisor_role */
export type SupervisorRole_Pk_Columns_Input = {
  id: Scalars["uuid"]["input"];
};

/** select columns of table "supervisor_role" */
export enum SupervisorRole_Select_Column {
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  ParticipantCreateSupervisor = "participantCreateSupervisor",
  /** column name */
  ParticipantDownloadData = "participantDownloadData",
  /** column name */
  ParticipantEditTherapyConfig = "participantEditTherapyConfig",
  /** column name */
  ParticipantViewDetails = "participantViewDetails",
  /** column name */
  StudyId = "studyId",
  /** column name */
  UpdatedAt = "updatedAt",
}

/** select "supervisorRole_aggregate_bool_exp_bool_and_arguments_columns" columns of table "supervisor_role" */
export enum SupervisorRole_Select_Column_SupervisorRole_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  ParticipantCreateSupervisor = "participantCreateSupervisor",
  /** column name */
  ParticipantDownloadData = "participantDownloadData",
  /** column name */
  ParticipantEditTherapyConfig = "participantEditTherapyConfig",
  /** column name */
  ParticipantViewDetails = "participantViewDetails",
}

/** select "supervisorRole_aggregate_bool_exp_bool_or_arguments_columns" columns of table "supervisor_role" */
export enum SupervisorRole_Select_Column_SupervisorRole_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  ParticipantCreateSupervisor = "participantCreateSupervisor",
  /** column name */
  ParticipantDownloadData = "participantDownloadData",
  /** column name */
  ParticipantEditTherapyConfig = "participantEditTherapyConfig",
  /** column name */
  ParticipantViewDetails = "participantViewDetails",
}

/** input type for updating data in table "supervisor_role" */
export type SupervisorRole_Set_Input = {
  createdAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  participantCreateSupervisor?: InputMaybe<Scalars["Boolean"]["input"]>;
  participantDownloadData?: InputMaybe<Scalars["Boolean"]["input"]>;
  participantEditTherapyConfig?: InputMaybe<Scalars["Boolean"]["input"]>;
  participantViewDetails?: InputMaybe<Scalars["Boolean"]["input"]>;
  studyId?: InputMaybe<Scalars["uuid"]["input"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** Streaming cursor of the table "supervisorRole" */
export type SupervisorRole_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: SupervisorRole_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type SupervisorRole_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  participantCreateSupervisor?: InputMaybe<Scalars["Boolean"]["input"]>;
  participantDownloadData?: InputMaybe<Scalars["Boolean"]["input"]>;
  participantEditTherapyConfig?: InputMaybe<Scalars["Boolean"]["input"]>;
  participantViewDetails?: InputMaybe<Scalars["Boolean"]["input"]>;
  studyId?: InputMaybe<Scalars["uuid"]["input"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** update columns of table "supervisor_role" */
export enum SupervisorRole_Update_Column {
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  ParticipantCreateSupervisor = "participantCreateSupervisor",
  /** column name */
  ParticipantDownloadData = "participantDownloadData",
  /** column name */
  ParticipantEditTherapyConfig = "participantEditTherapyConfig",
  /** column name */
  ParticipantViewDetails = "participantViewDetails",
  /** column name */
  StudyId = "studyId",
  /** column name */
  UpdatedAt = "updatedAt",
}

export type SupervisorRole_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SupervisorRole_Set_Input>;
  /** filter the rows which have to be updated */
  where: SupervisorRole_Bool_Exp;
};

/** aggregated selection of "supervisor" */
export type Supervisor_Aggregate = {
  __typename?: "supervisor_aggregate";
  aggregate?: Maybe<Supervisor_Aggregate_Fields>;
  nodes: Array<Supervisor>;
};

export type Supervisor_Aggregate_Bool_Exp = {
  count?: InputMaybe<Supervisor_Aggregate_Bool_Exp_Count>;
};

export type Supervisor_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Supervisor_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<Supervisor_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "supervisor" */
export type Supervisor_Aggregate_Fields = {
  __typename?: "supervisor_aggregate_fields";
  count: Scalars["Int"]["output"];
  max?: Maybe<Supervisor_Max_Fields>;
  min?: Maybe<Supervisor_Min_Fields>;
};

/** aggregate fields of "supervisor" */
export type Supervisor_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Supervisor_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "supervisor" */
export type Supervisor_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Supervisor_Max_Order_By>;
  min?: InputMaybe<Supervisor_Min_Order_By>;
};

/** input type for inserting array relation for remote table "supervisor" */
export type Supervisor_Arr_Rel_Insert_Input = {
  data: Array<Supervisor_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Supervisor_On_Conflict>;
};

/** Boolean expression to filter rows from the table "supervisor". All fields are combined with a logical 'AND'. */
export type Supervisor_Bool_Exp = {
  _and?: InputMaybe<Array<Supervisor_Bool_Exp>>;
  _not?: InputMaybe<Supervisor_Bool_Exp>;
  _or?: InputMaybe<Array<Supervisor_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  participant?: InputMaybe<Participant_Bool_Exp>;
  participantId?: InputMaybe<Uuid_Comparison_Exp>;
  supervisorRole?: InputMaybe<SupervisorRole_Bool_Exp>;
  supervisorRoleId?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  userId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "supervisor" */
export enum Supervisor_Constraint {
  /** unique or primary key constraint on columns "id" */
  ParticipantsupervisorassignmentIdKey = "participantsupervisorassignment_id_key",
  /** unique or primary key constraint on columns "user_id", "participant_id" */
  ParticipantsupervisorassignmentPkey = "participantsupervisorassignment_pkey",
}

/** input type for inserting data into table "supervisor" */
export type Supervisor_Insert_Input = {
  createdAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  participant?: InputMaybe<Participant_Obj_Rel_Insert_Input>;
  participantId?: InputMaybe<Scalars["uuid"]["input"]>;
  supervisorRole?: InputMaybe<SupervisorRole_Obj_Rel_Insert_Input>;
  supervisorRoleId?: InputMaybe<Scalars["uuid"]["input"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars["uuid"]["input"]>;
};

/** aggregate max on columns */
export type Supervisor_Max_Fields = {
  __typename?: "supervisor_max_fields";
  createdAt?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  participantId?: Maybe<Scalars["uuid"]["output"]>;
  supervisorRoleId?: Maybe<Scalars["uuid"]["output"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  userId?: Maybe<Scalars["uuid"]["output"]>;
};

/** order by max() on columns of table "supervisor" */
export type Supervisor_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  participantId?: InputMaybe<Order_By>;
  supervisorRoleId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Supervisor_Min_Fields = {
  __typename?: "supervisor_min_fields";
  createdAt?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  participantId?: Maybe<Scalars["uuid"]["output"]>;
  supervisorRoleId?: Maybe<Scalars["uuid"]["output"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  userId?: Maybe<Scalars["uuid"]["output"]>;
};

/** order by min() on columns of table "supervisor" */
export type Supervisor_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  participantId?: InputMaybe<Order_By>;
  supervisorRoleId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "supervisor" */
export type Supervisor_Mutation_Response = {
  __typename?: "supervisor_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Supervisor>;
};

/** on_conflict condition type for table "supervisor" */
export type Supervisor_On_Conflict = {
  constraint: Supervisor_Constraint;
  update_columns?: Array<Supervisor_Update_Column>;
  where?: InputMaybe<Supervisor_Bool_Exp>;
};

/** Ordering options when selecting data from "supervisor". */
export type Supervisor_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  participant?: InputMaybe<Participant_Order_By>;
  participantId?: InputMaybe<Order_By>;
  supervisorRole?: InputMaybe<SupervisorRole_Order_By>;
  supervisorRoleId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: supervisor */
export type Supervisor_Pk_Columns_Input = {
  participantId: Scalars["uuid"]["input"];
  userId: Scalars["uuid"]["input"];
};

/** select columns of table "supervisor" */
export enum Supervisor_Select_Column {
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  Id = "id",
  /** column name */
  ParticipantId = "participantId",
  /** column name */
  SupervisorRoleId = "supervisorRoleId",
  /** column name */
  UpdatedAt = "updatedAt",
  /** column name */
  UserId = "userId",
}

/** input type for updating data in table "supervisor" */
export type Supervisor_Set_Input = {
  createdAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  participantId?: InputMaybe<Scalars["uuid"]["input"]>;
  supervisorRoleId?: InputMaybe<Scalars["uuid"]["input"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  userId?: InputMaybe<Scalars["uuid"]["input"]>;
};

/** Streaming cursor of the table "supervisor" */
export type Supervisor_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Supervisor_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Supervisor_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  participantId?: InputMaybe<Scalars["uuid"]["input"]>;
  supervisorRoleId?: InputMaybe<Scalars["uuid"]["input"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  userId?: InputMaybe<Scalars["uuid"]["input"]>;
};

/** update columns of table "supervisor" */
export enum Supervisor_Update_Column {
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  Id = "id",
  /** column name */
  ParticipantId = "participantId",
  /** column name */
  SupervisorRoleId = "supervisorRoleId",
  /** column name */
  UpdatedAt = "updatedAt",
  /** column name */
  UserId = "userId",
}

export type Supervisor_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Supervisor_Set_Input>;
  /** filter the rows which have to be updated */
  where: Supervisor_Bool_Exp;
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["timestamp"]["input"]>;
  _gt?: InputMaybe<Scalars["timestamp"]["input"]>;
  _gte?: InputMaybe<Scalars["timestamp"]["input"]>;
  _in?: InputMaybe<Array<Scalars["timestamp"]["input"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]["input"]>;
  _lt?: InputMaybe<Scalars["timestamp"]["input"]>;
  _lte?: InputMaybe<Scalars["timestamp"]["input"]>;
  _neq?: InputMaybe<Scalars["timestamp"]["input"]>;
  _nin?: InputMaybe<Array<Scalars["timestamp"]["input"]>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["timestamptz"]["input"]>;
  _gt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  _gte?: InputMaybe<Scalars["timestamptz"]["input"]>;
  _in?: InputMaybe<Array<Scalars["timestamptz"]["input"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]["input"]>;
  _lt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  _lte?: InputMaybe<Scalars["timestamptz"]["input"]>;
  _neq?: InputMaybe<Scalars["timestamptz"]["input"]>;
  _nin?: InputMaybe<Array<Scalars["timestamptz"]["input"]>>;
};

/** columns and relationships of "user" */
export type User = {
  __typename?: "user";
  /** An array relationship */
  apiEvents: Array<Api_Event>;
  /** An aggregate relationship */
  apiEvents_aggregate: Api_Event_Aggregate;
  avatar?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["timestamptz"]["output"]>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["uuid"]["output"];
  lastName?: Maybe<Scalars["String"]["output"]>;
  /** An array relationship */
  organizationMembers: Array<OrganizationMember>;
  /** An aggregate relationship */
  organizationMembers_aggregate: OrganizationMember_Aggregate;
  sendNewsletter?: Maybe<Scalars["Boolean"]["output"]>;
  /** An array relationship */
  studyMembers: Array<StudyMember>;
  /** An aggregate relationship */
  studyMembers_aggregate: StudyMember_Aggregate;
  /** An array relationship */
  supervisors: Array<Supervisor>;
  /** An aggregate relationship */
  supervisors_aggregate: Supervisor_Aggregate;
  title?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** columns and relationships of "user" */
export type UserApiEventsArgs = {
  distinct_on?: InputMaybe<Array<Api_Event_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Api_Event_Order_By>>;
  where?: InputMaybe<Api_Event_Bool_Exp>;
};

/** columns and relationships of "user" */
export type UserApiEvents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Api_Event_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Api_Event_Order_By>>;
  where?: InputMaybe<Api_Event_Bool_Exp>;
};

/** columns and relationships of "user" */
export type UserOrganizationMembersArgs = {
  distinct_on?: InputMaybe<Array<OrganizationMember_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<OrganizationMember_Order_By>>;
  where?: InputMaybe<OrganizationMember_Bool_Exp>;
};

/** columns and relationships of "user" */
export type UserOrganizationMembers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<OrganizationMember_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<OrganizationMember_Order_By>>;
  where?: InputMaybe<OrganizationMember_Bool_Exp>;
};

/** columns and relationships of "user" */
export type UserStudyMembersArgs = {
  distinct_on?: InputMaybe<Array<StudyMember_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<StudyMember_Order_By>>;
  where?: InputMaybe<StudyMember_Bool_Exp>;
};

/** columns and relationships of "user" */
export type UserStudyMembers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<StudyMember_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<StudyMember_Order_By>>;
  where?: InputMaybe<StudyMember_Bool_Exp>;
};

/** columns and relationships of "user" */
export type UserSupervisorsArgs = {
  distinct_on?: InputMaybe<Array<Supervisor_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Supervisor_Order_By>>;
  where?: InputMaybe<Supervisor_Bool_Exp>;
};

/** columns and relationships of "user" */
export type UserSupervisors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supervisor_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Supervisor_Order_By>>;
  where?: InputMaybe<Supervisor_Bool_Exp>;
};

/** aggregated selection of "user" */
export type User_Aggregate = {
  __typename?: "user_aggregate";
  aggregate?: Maybe<User_Aggregate_Fields>;
  nodes: Array<User>;
};

/** aggregate fields of "user" */
export type User_Aggregate_Fields = {
  __typename?: "user_aggregate_fields";
  count: Scalars["Int"]["output"];
  max?: Maybe<User_Max_Fields>;
  min?: Maybe<User_Min_Fields>;
};

/** aggregate fields of "user" */
export type User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Boolean expression to filter rows from the table "user". All fields are combined with a logical 'AND'. */
export type User_Bool_Exp = {
  _and?: InputMaybe<Array<User_Bool_Exp>>;
  _not?: InputMaybe<User_Bool_Exp>;
  _or?: InputMaybe<Array<User_Bool_Exp>>;
  apiEvents?: InputMaybe<Api_Event_Bool_Exp>;
  apiEvents_aggregate?: InputMaybe<Api_Event_Aggregate_Bool_Exp>;
  avatar?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  firstName?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  lastName?: InputMaybe<String_Comparison_Exp>;
  organizationMembers?: InputMaybe<OrganizationMember_Bool_Exp>;
  organizationMembers_aggregate?: InputMaybe<OrganizationMember_Aggregate_Bool_Exp>;
  sendNewsletter?: InputMaybe<Boolean_Comparison_Exp>;
  studyMembers?: InputMaybe<StudyMember_Bool_Exp>;
  studyMembers_aggregate?: InputMaybe<StudyMember_Aggregate_Bool_Exp>;
  supervisors?: InputMaybe<Supervisor_Bool_Exp>;
  supervisors_aggregate?: InputMaybe<Supervisor_Aggregate_Bool_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "user" */
export enum User_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserIdKey = "user_id_key",
  /** unique or primary key constraint on columns "id" */
  UserPkey = "user_pkey",
}

/** input type for inserting data into table "user" */
export type User_Insert_Input = {
  apiEvents?: InputMaybe<Api_Event_Arr_Rel_Insert_Input>;
  avatar?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  organizationMembers?: InputMaybe<OrganizationMember_Arr_Rel_Insert_Input>;
  sendNewsletter?: InputMaybe<Scalars["Boolean"]["input"]>;
  studyMembers?: InputMaybe<StudyMember_Arr_Rel_Insert_Input>;
  supervisors?: InputMaybe<Supervisor_Arr_Rel_Insert_Input>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** aggregate max on columns */
export type User_Max_Fields = {
  __typename?: "user_max_fields";
  avatar?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["timestamptz"]["output"]>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  lastName?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** aggregate min on columns */
export type User_Min_Fields = {
  __typename?: "user_min_fields";
  avatar?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["timestamptz"]["output"]>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  lastName?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** response of any mutation on the table "user" */
export type User_Mutation_Response = {
  __typename?: "user_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<User>;
};

/** input type for inserting object relation for remote table "user" */
export type User_Obj_Rel_Insert_Input = {
  data: User_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<User_On_Conflict>;
};

/** on_conflict condition type for table "user" */
export type User_On_Conflict = {
  constraint: User_Constraint;
  update_columns?: Array<User_Update_Column>;
  where?: InputMaybe<User_Bool_Exp>;
};

/** Ordering options when selecting data from "user". */
export type User_Order_By = {
  apiEvents_aggregate?: InputMaybe<Api_Event_Aggregate_Order_By>;
  avatar?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  organizationMembers_aggregate?: InputMaybe<OrganizationMember_Aggregate_Order_By>;
  sendNewsletter?: InputMaybe<Order_By>;
  studyMembers_aggregate?: InputMaybe<StudyMember_Aggregate_Order_By>;
  supervisors_aggregate?: InputMaybe<Supervisor_Aggregate_Order_By>;
  title?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user */
export type User_Pk_Columns_Input = {
  id: Scalars["uuid"]["input"];
};

/** select columns of table "user" */
export enum User_Select_Column {
  /** column name */
  Avatar = "avatar",
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  FirstName = "firstName",
  /** column name */
  Id = "id",
  /** column name */
  LastName = "lastName",
  /** column name */
  SendNewsletter = "sendNewsletter",
  /** column name */
  Title = "title",
  /** column name */
  UpdatedAt = "updatedAt",
}

/** input type for updating data in table "user" */
export type User_Set_Input = {
  avatar?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  sendNewsletter?: InputMaybe<Scalars["Boolean"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** Streaming cursor of the table "user" */
export type User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Stream_Cursor_Value_Input = {
  avatar?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  sendNewsletter?: InputMaybe<Scalars["Boolean"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** update columns of table "user" */
export enum User_Update_Column {
  /** column name */
  Avatar = "avatar",
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  FirstName = "firstName",
  /** column name */
  Id = "id",
  /** column name */
  LastName = "lastName",
  /** column name */
  SendNewsletter = "sendNewsletter",
  /** column name */
  Title = "title",
  /** column name */
  UpdatedAt = "updatedAt",
}

export type User_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Bool_Exp;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["uuid"]["input"]>;
  _gt?: InputMaybe<Scalars["uuid"]["input"]>;
  _gte?: InputMaybe<Scalars["uuid"]["input"]>;
  _in?: InputMaybe<Array<Scalars["uuid"]["input"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]["input"]>;
  _lt?: InputMaybe<Scalars["uuid"]["input"]>;
  _lte?: InputMaybe<Scalars["uuid"]["input"]>;
  _neq?: InputMaybe<Scalars["uuid"]["input"]>;
  _nin?: InputMaybe<Array<Scalars["uuid"]["input"]>>;
};

export type UpdateEmailMutationVariables = Exact<{
  email: Scalars["String"]["input"];
  userId: Scalars["uuid"]["input"];
}>;

export type UpdateEmailMutation = {
  __typename?: "mutation_root";
  updateEmail?: { __typename?: "UpdateEmailOutput"; userId: any; email: string } | null;
};

export type TriggerPasswordUpdateMutationVariables = Exact<{
  id: Scalars["uuid"]["input"];
}>;

export type TriggerPasswordUpdateMutation = {
  __typename?: "mutation_root";
  triggerPasswordUpdate?: { __typename?: "TriggerPasswordUpdateOutput"; success: boolean } | null;
};

export type GetAccountQueryVariables = Exact<{
  userId: Scalars["uuid"]["input"];
}>;

export type GetAccountQuery = {
  __typename?: "query_root";
  getAccountDataAction?: {
    __typename?: "GetPersonalKeycloakDataOutput";
    id: any;
    email: string;
    hasuraUser?: {
      __typename?: "user";
      firstName?: string | null;
      lastName?: string | null;
      id: any;
    } | null;
  } | null;
};

export type GetApiAccountsOfStudyQueryVariables = Exact<{
  studyId: Scalars["uuid"]["input"];
}>;

export type GetApiAccountsOfStudyQuery = {
  __typename?: "query_root";
  apiAccounts: Array<{
    __typename?: "api_account";
    id: any;
    serviceName: string;
    expiresAt: any;
    createdAt: any;
  }>;
};

export type UpdateApiAccountMutationVariables = Exact<{
  id: Scalars["uuid"]["input"];
  expiresAt: Scalars["timestamptz"]["input"];
  serviceName: Scalars["String"]["input"];
}>;

export type UpdateApiAccountMutation = {
  __typename?: "mutation_root";
  updateApiAccount?: {
    __typename?: "api_account";
    id: any;
    serviceName: string;
    expiresAt: any;
    createdAt: any;
  } | null;
};

export type CreateApiAccountActionMutationVariables = Exact<{
  expiresAt: Scalars["String"]["input"];
  serviceName: Scalars["String"]["input"];
  studyId: Scalars["uuid"]["input"];
}>;

export type CreateApiAccountActionMutation = {
  __typename?: "mutation_root";
  createApiAccountAction?: {
    __typename?: "CreateApiAccountOutput";
    clientId?: string | null;
    clientSecret?: string | null;
    id: any;
    password: string;
    accountId: any;
  } | null;
};

export type DeleteApiAccountActionMutationVariables = Exact<{
  id: Scalars["uuid"]["input"];
}>;

export type DeleteApiAccountActionMutation = {
  __typename?: "mutation_root";
  deleteApiAccountAction?: {
    __typename?: "DeleteApiAccountAction";
    id: any;
    serviceName: string;
  } | null;
};

export type CreateExecutorMutationVariables = Exact<{
  executorUrl: Scalars["String"]["input"];
  executorUrlExternal: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  organizationId?: InputMaybe<Scalars["uuid"]["input"]>;
  therapyDesignId: Scalars["uuid"]["input"];
  participantLimit: Scalars["Int"]["input"];
  expiresAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
}>;

export type CreateExecutorMutation = {
  __typename?: "mutation_root";
  createExecutor?: {
    __typename?: "executor";
    id: any;
    name: string;
    executorUrl: string;
    executorUrlExternal: string;
    participantLimit: number;
    expiresAt?: any | null;
  } | null;
};

export type GetExecutorsQueryVariables = Exact<{ [key: string]: never }>;

export type GetExecutorsQuery = {
  __typename?: "query_root";
  executors: Array<{
    __typename?: "executor";
    id: any;
    name: string;
    organizationId?: any | null;
    therapyDesignId: any;
    executorUrl: string;
    executorUrlExternal: string;
    participantLimit: number;
    expiresAt?: any | null;
    hidden: boolean;
    createdAt: any;
    studies: Array<{ __typename?: "study"; id: any; name: string; deletedAt?: any | null }>;
    organization?: { __typename?: "organization"; id: any; name: string } | null;
  }>;
};

export type GetExecutorQueryVariables = Exact<{
  executorId: Scalars["uuid"]["input"];
}>;

export type GetExecutorQuery = {
  __typename?: "query_root";
  executor?: {
    __typename?: "executor";
    executorUrl: string;
    executorUrlExternal: string;
    participantLimit: number;
    id: any;
    name: string;
    hidden: boolean;
    expiresAt?: any | null;
    therapyDesignId: any;
    studies: Array<{ __typename?: "study"; id: any; name: string; deletedAt?: any | null }>;
    organization?: { __typename?: "organization"; id: any; name: string } | null;
  } | null;
};

export type DeleteExecutorMutationVariables = Exact<{
  executorId: Scalars["uuid"]["input"];
  deletedAt: Scalars["timestamp"]["input"];
}>;

export type DeleteExecutorMutation = {
  __typename?: "mutation_root";
  updateExecutor?: {
    __typename?: "executor";
    id: any;
    name: string;
    deletedAt?: any | null;
  } | null;
};

export type UpdateExecutorMutationVariables = Exact<{
  id: Scalars["uuid"]["input"];
  executorUrl: Scalars["String"]["input"];
  executorUrlExternal: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  hidden: Scalars["Boolean"]["input"];
  participantLimit: Scalars["Int"]["input"];
  expiresAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
}>;

export type UpdateExecutorMutation = {
  __typename?: "mutation_root";
  updateExecutor?: {
    __typename?: "executor";
    id: any;
    hidden: boolean;
    executorUrl: string;
    executorUrlExternal: string;
    name: string;
    participantLimit: number;
    expiresAt?: any | null;
  } | null;
};

export type GetGroupVariablesConfigsQueryVariables = Exact<{
  studyId: Scalars["uuid"]["input"];
}>;

export type GetGroupVariablesConfigsQuery = {
  __typename?: "query_root";
  getGroupsTherapyVariableConfigAction: {
    __typename?: "GetGroupsTherapyVariableConfigActionOutput";
    id: string;
    groupValues: Array<{
      __typename?: "GroupTherapyConfigValue";
      id: string;
      participantsGroupId: string;
      variableId: string;
      variableValue: string;
    }>;
  };
};

export type UpdateGroupVariableConfigMutationVariables = Exact<{
  objects?: InputMaybe<Array<GroupConfigsToUpdate> | GroupConfigsToUpdate>;
}>;

export type UpdateGroupVariableConfigMutation = {
  __typename?: "mutation_root";
  updateGroupTherapyVariableConfigsAction?: Array<{
    __typename?: "ConfigsToUpdateOutput";
    id: any;
  } | null> | null;
};

export type GetOrganizationQueryVariables = Exact<{
  organizationId: Scalars["uuid"]["input"];
}>;

export type GetOrganizationQuery = {
  __typename?: "query_root";
  organization?: {
    __typename?: "organization";
    address: string;
    city: string;
    country: string;
    department?: string | null;
    description?: string | null;
    name: string;
    zip: string;
    id: any;
    contracts: Array<{
      __typename?: "contract";
      id: any;
      signerEmail: string;
      signerFirstName: string;
      signerLastName: string;
      contractType: ContractTypeEnum_Enum;
      createdAt: any;
    }>;
    studies: Array<{
      __typename?: "study";
      name: string;
      id: any;
      description?: string | null;
      createdAt?: any | null;
      logo?: string | null;
    }>;
    organizationMembers: Array<{
      __typename?: "organizationMember";
      id: any;
      userId: any;
      createdAt: any;
      organizationRole: { __typename?: "organizationRole"; id: any; name: string };
    }>;
    organizationRoles: Array<{ __typename?: "organizationRole"; id: any; name: string }>;
  } | null;
};

export type GetOrganizationsQueryVariables = Exact<{ [key: string]: never }>;

export type GetOrganizationsQuery = {
  __typename?: "query_root";
  organizations: Array<{ __typename?: "organization"; id: any; name: string }>;
};

export type CreateNewOrganizationMutationVariables = Exact<{
  description?: InputMaybe<Scalars["String"]["input"]>;
  department?: InputMaybe<Scalars["String"]["input"]>;
  country: Scalars["String"]["input"];
  city: Scalars["String"]["input"];
  address: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  zip: Scalars["numeric"]["input"];
  userId: Scalars["uuid"]["input"];
}>;

export type CreateNewOrganizationMutation = {
  __typename?: "mutation_root";
  createOrganizationAction?: { __typename?: "CreateOrganizationOutput"; id: any } | null;
};

export type UpdateOrganizationMutationVariables = Exact<{
  organizationId: Scalars["uuid"]["input"];
  address: Scalars["String"]["input"];
  city: Scalars["String"]["input"];
  country: Scalars["String"]["input"];
  department?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  zip: Scalars["String"]["input"];
}>;

export type UpdateOrganizationMutation = {
  __typename?: "mutation_root";
  updateOrganization?: {
    __typename?: "organization";
    id: any;
    name: string;
    address: string;
    city: string;
    country: string;
    department?: string | null;
    description?: string | null;
    zip: string;
  } | null;
};

export type LeaveOrganizationMutationVariables = Exact<{
  organizationId: Scalars["uuid"]["input"];
  userId: Scalars["uuid"]["input"];
}>;

export type LeaveOrganizationMutation = {
  __typename?: "mutation_root";
  leaveOrganizationAction: { __typename?: "LeaveOrganizationOutput"; success: boolean };
};

export type CanLeaveOrganizationQueryVariables = Exact<{
  organizationId: Scalars["uuid"]["input"];
  userId: Scalars["uuid"]["input"];
}>;

export type CanLeaveOrganizationQuery = {
  __typename?: "query_root";
  canLeaveOrganizationAction: { __typename?: "CanLeaveOrganizationOutput"; canLeave: boolean };
};

export type DeleteOrganizationMutationVariables = Exact<{
  organizationId: Scalars["uuid"]["input"];
}>;

export type DeleteOrganizationMutation = {
  __typename?: "mutation_root";
  deleteOrganizationAction: {
    __typename?: "DeleteOrganizationOutput";
    organizationName: string;
    success: boolean;
  };
};

export type GetRoleOfOrganizationMemberQueryVariables = Exact<{
  userId: Scalars["uuid"]["input"];
  organizationId: Scalars["uuid"]["input"];
}>;

export type GetRoleOfOrganizationMemberQuery = {
  __typename?: "query_root";
  organizationMember?: {
    __typename?: "organizationMember";
    id: any;
    organizationRole: {
      __typename?: "organizationRole";
      id: any;
      name: string;
      organizationCreateMember: boolean;
      organizationCreateStudy: boolean;
      organizationDeleteOrganization: boolean;
      organizationEditBilling: boolean;
      organizationEditMemberRole: boolean;
      organizationEditOrganization: boolean;
      organizationDeleteMember: boolean;
      organizationViewDetails: boolean;
      organizationViewInvoices: boolean;
      organizationViewMembers: boolean;
      organizationViewStudies: boolean;
    };
  } | null;
};

export type GetOrganizationMemberByIdQueryVariables = Exact<{
  id: Scalars["uuid"]["input"];
}>;

export type GetOrganizationMemberByIdQuery = {
  __typename?: "query_root";
  organizationMembers: Array<{
    __typename?: "organizationMember";
    id: any;
    userId: any;
    organizationRole: { __typename?: "organizationRole"; id: any; name: string };
  }>;
};

export type GetOrganizationMembersQueryVariables = Exact<{
  organizationId: Scalars["uuid"]["input"];
}>;

export type GetOrganizationMembersQuery = {
  __typename?: "query_root";
  getOrganizationMembersAction?: Array<{
    __typename?: "OrganizationMemberAction";
    id: any;
    userAvatar?: string | null;
    userEmail: string;
    userFirstName?: string | null;
    userLastName?: string | null;
    organizationMember?: {
      __typename?: "organizationMember";
      userId: any;
      id: any;
      organizationRole: { __typename?: "organizationRole"; id: any; name: string };
    } | null;
  }> | null;
};

export type CreateOrganizationMemberMutationVariables = Exact<{
  email: Scalars["String"]["input"];
  organizationId: Scalars["uuid"]["input"];
  roleId: Scalars["uuid"]["input"];
}>;

export type CreateOrganizationMemberMutation = {
  __typename?: "mutation_root";
  createOrganizationMemberAction?: {
    __typename?: "AddOrganizationMemberOutput";
    email: string;
    organizationMember?: {
      __typename?: "organizationMember";
      id: any;
      organizationRole: {
        __typename?: "organizationRole";
        id: any;
        name: string;
        description?: string | null;
      };
    } | null;
  } | null;
};

export type DeleteOrganizationMemberMutationVariables = Exact<{
  organizationId: Scalars["uuid"]["input"];
  userId: Scalars["uuid"]["input"];
}>;

export type DeleteOrganizationMemberMutation = {
  __typename?: "mutation_root";
  deleteOrganizationMember?: { __typename?: "organizationMember"; id: any } | null;
};

export type UpdateOrganizationMemberMutationVariables = Exact<{
  organizationId: Scalars["uuid"]["input"];
  userId: Scalars["uuid"]["input"];
  organizationRoleId: Scalars["uuid"]["input"];
}>;

export type UpdateOrganizationMemberMutation = {
  __typename?: "mutation_root";
  updateOrganizationMember?: {
    __typename?: "organizationMember";
    id: any;
    organizationRole: { __typename?: "organizationRole"; id: any; name: string };
  } | null;
};

export type GetOrganizationRolesOfOrganizationQueryVariables = Exact<{
  organizationId: Scalars["uuid"]["input"];
}>;

export type GetOrganizationRolesOfOrganizationQuery = {
  __typename?: "query_root";
  organizationRoles: Array<{ __typename?: "organizationRole"; id: any; name: string }>;
};

export type CreateParticipantActionMutationVariables = Exact<{
  groupId: Scalars["uuid"]["input"];
  pseudonym: Scalars["String"]["input"];
  therapyEnd: Scalars["String"]["input"];
  therapyStart: Scalars["String"]["input"];
}>;

export type CreateParticipantActionMutation = {
  __typename?: "mutation_root";
  createParticipantAction?: {
    __typename?: "CreateParticipantOutput";
    participantId: any;
    participant?: { __typename?: "participant"; id: any; pseudonym: string; groupId: any } | null;
  } | null;
};

export type GetParticipantCountOfStudyQueryVariables = Exact<{
  studyId: Scalars["uuid"]["input"];
}>;

export type GetParticipantCountOfStudyQuery = {
  __typename?: "query_root";
  aggregateParticipants: {
    __typename?: "participant_aggregate";
    aggregate?: { __typename?: "participant_aggregate_fields"; count: number } | null;
  };
};

export type GetParticipantsByStudyIdAndPseudonymQueryVariables = Exact<{
  pseudonym: Scalars["String"]["input"];
  studyId: Scalars["uuid"]["input"];
}>;

export type GetParticipantsByStudyIdAndPseudonymQuery = {
  __typename?: "query_root";
  participants: Array<{ __typename?: "participant"; id: any }>;
};

export type GetParticipantQueryVariables = Exact<{
  id: Scalars["uuid"]["input"];
}>;

export type GetParticipantQuery = {
  __typename?: "query_root";
  participant?: {
    __typename?: "participant";
    pseudonym: string;
    id: any;
    participantGroup: {
      __typename?: "participantsGroup";
      name: string;
      id: any;
      study: {
        __typename?: "study";
        id: any;
        name: string;
        supervisorRoles: Array<{ __typename?: "supervisorRole"; id: any; name: string }>;
        organization: { __typename?: "organization"; name: string };
      };
    };
    supervisors: Array<{
      __typename?: "supervisor";
      id: any;
      participantId: any;
      userId: any;
      user: { __typename?: "user"; id: any; firstName?: string | null; lastName?: string | null };
    }>;
  } | null;
};

export type UpdateParticipantMutationVariables = Exact<{
  groupId: Scalars["uuid"]["input"];
  id: Scalars["uuid"]["input"];
  pseudonym: Scalars["String"]["input"];
}>;

export type UpdateParticipantMutation = {
  __typename?: "mutation_root";
  updateParticipantAction: {
    __typename?: "ParticipantOutput";
    id: any;
    participant?: { __typename?: "participant"; id: any; pseudonym: string; groupId: any } | null;
  };
};

export type GetParticipantsOfStudyQueryVariables = Exact<{
  studyId: Scalars["uuid"]["input"];
}>;

export type GetParticipantsOfStudyQuery = {
  __typename?: "query_root";
  participants: Array<{
    __typename?: "participant";
    id: any;
    pseudonym: string;
    participantGroup: { __typename?: "participantsGroup"; id: any; name: string };
    supervisors: Array<{ __typename?: "supervisor"; id: any; userId: any }>;
  }>;
};

export type DeleteParticipantMutationVariables = Exact<{
  participantId: Scalars["uuid"]["input"];
}>;

export type DeleteParticipantMutation = {
  __typename?: "mutation_root";
  deleteParticipantAction?: {
    __typename?: "DeleteParticipantOutput";
    pseudonym?: string | null;
    participantId: any;
  } | null;
};

export type SendTestNotificationMutationVariables = Exact<{
  participantId: Scalars["uuid"]["input"];
}>;

export type SendTestNotificationMutation = {
  __typename?: "mutation_root";
  sendTestNotificationAction: {
    __typename?: "SendTestNotificationOutput";
    success: boolean;
    sentMessage: string;
  };
};

export type GetParticipantActionQueryVariables = Exact<{
  id: Scalars["uuid"]["input"];
}>;

export type GetParticipantActionQuery = {
  __typename?: "query_root";
  getParticipantAction: {
    __typename?: "ParticipantOutput";
    id: any;
    coupleUrl?: string | null;
    therapyEnd?: string | null;
    therapyPeriodId?: any | null;
    therapyStart?: string | null;
    participant?: {
      __typename?: "participant";
      pseudonym: string;
      coupledAt?: any | null;
      id: any;
      participantGroup: {
        __typename?: "participantsGroup";
        name: string;
        id: any;
        study: {
          __typename?: "study";
          id: any;
          name: string;
          supervisorRoles: Array<{ __typename?: "supervisorRole"; id: any; name: string }>;
          organization: { __typename?: "organization"; name: string };
        };
      };
      supervisors: Array<{
        __typename?: "supervisor";
        id: any;
        participantId: any;
        userId: any;
        user: { __typename?: "user"; id: any; firstName?: string | null; lastName?: string | null };
      }>;
    } | null;
  };
};

export type GetExecutorParticipantsQueryVariables = Exact<{
  therapyId: Scalars["uuid"]["input"];
}>;

export type GetExecutorParticipantsQuery = {
  __typename?: "query_root";
  getParticipantsAction: Array<{
    __typename?: "ParticipantOutput";
    coupleUrl?: string | null;
    id: any;
    therapyPeriodId?: any | null;
    therapyEnd?: string | null;
    therapyStart?: string | null;
    participant?: {
      __typename?: "participant";
      id: any;
      createdAt?: any | null;
      coupledAt?: any | null;
      pseudonym: string;
      participantGroup: { __typename?: "participantsGroup"; id: any; name: string };
      supervisors: Array<{ __typename?: "supervisor"; id: any; userId: any }>;
    } | null;
  }>;
};

export type ResetCoupleKeyActionMutationVariables = Exact<{
  participantId: Scalars["String"]["input"];
}>;

export type ResetCoupleKeyActionMutation = {
  __typename?: "mutation_root";
  resetCoupleKeyAction: { __typename?: "ParticipantOutput"; id: any; coupleUrl?: string | null };
};

export type CreateParticipantGroupActionMutationVariables = Exact<{
  description?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  studyId: Scalars["uuid"]["input"];
}>;

export type CreateParticipantGroupActionMutation = {
  __typename?: "mutation_root";
  createParticipantsGroupAction?: {
    __typename?: "CreateParticipantsGroupActionOutput";
    id: any;
    participantsGroup?: {
      __typename?: "participantsGroup";
      name: string;
      id: any;
      study: { __typename?: "study"; name: string; id: any };
    } | null;
  } | null;
};

export type UpdateParticipantsGroupMutationVariables = Exact<{
  id: Scalars["uuid"]["input"];
  name: Scalars["String"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type UpdateParticipantsGroupMutation = {
  __typename?: "mutation_root";
  updateParticipantsGroup?: {
    __typename?: "participantsGroup";
    id: any;
    name: string;
    description?: string | null;
  } | null;
};

export type GetParticipantsGroupsQueryVariables = Exact<{
  studyId: Scalars["uuid"]["input"];
}>;

export type GetParticipantsGroupsQuery = {
  __typename?: "query_root";
  participantsGroups: Array<{
    __typename?: "participantsGroup";
    name: string;
    id: any;
    description?: string | null;
    studyId: any;
    participants: Array<{
      __typename?: "participant";
      pseudonym: string;
      id: any;
      groupId: any;
      participantGroup: { __typename?: "participantsGroup"; name: string };
    }>;
  }>;
};

export type DeleteParticipantGroupMutationVariables = Exact<{
  groupId: Scalars["uuid"]["input"];
}>;

export type DeleteParticipantGroupMutation = {
  __typename?: "mutation_root";
  deleteParticipantGroup?: { __typename?: "DeleteStudyGroupOutput"; success: boolean } | null;
};

export type GetStudyQueryVariables = Exact<{
  id: Scalars["uuid"]["input"];
}>;

export type GetStudyQuery = {
  __typename?: "query_root";
  study?: {
    __typename?: "study";
    description?: string | null;
    id: any;
    name: string;
    logo?: string | null;
    therapyId: any;
    organizationId: any;
    allowedEmailDomains?: string | null;
    studyRoles: Array<{ __typename?: "studyRole"; id: any; name: string }>;
    supervisorRoles: Array<{ __typename?: "supervisorRole"; id: any; name: string }>;
    participantGroups: Array<{
      __typename?: "participantsGroup";
      name: string;
      id: any;
      description?: string | null;
      studyId: any;
      participants: Array<{
        __typename?: "participant";
        pseudonym: string;
        id: any;
        groupId: any;
        participantGroup: {
          __typename?: "participantsGroup";
          name: string;
          study: { __typename?: "study"; id: any };
        };
        supervisors: Array<{ __typename?: "supervisor"; id: any; participantId: any; userId: any }>;
      }>;
    }>;
    studyMembers: Array<{
      __typename?: "studyMember";
      id: any;
      studyRole: { __typename?: "studyRole"; id: any; name: string };
      user: {
        __typename?: "user";
        firstName?: string | null;
        lastName?: string | null;
        avatar?: string | null;
        id: any;
      };
    }>;
    executor: { __typename?: "executor"; id: any; name: string; executorUrl: string };
  } | null;
};

export type GetTherapyIdQueryVariables = Exact<{
  id: Scalars["uuid"]["input"];
}>;

export type GetTherapyIdQuery = {
  __typename?: "query_root";
  study?: { __typename?: "study"; id: any; therapyId: any } | null;
};

export type UpdateStudyMutationVariables = Exact<{
  id: Scalars["uuid"]["input"];
  name: Scalars["String"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type UpdateStudyMutation = {
  __typename?: "mutation_root";
  updateStudy?: { __typename?: "study"; name: string; description?: string | null; id: any } | null;
};

export type CreateStudyMutationVariables = Exact<{
  name: Scalars["String"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  executorId: Scalars["uuid"]["input"];
  organizationId: Scalars["uuid"]["input"];
  userId: Scalars["uuid"]["input"];
}>;

export type CreateStudyMutation = {
  __typename?: "mutation_root";
  createStudyAction?: { __typename?: "CreateStudyOutput"; id: any; name: string } | null;
};

export type UpdateStudyLogoMutationVariables = Exact<{
  logo?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["uuid"]["input"];
}>;

export type UpdateStudyLogoMutation = {
  __typename?: "mutation_root";
  updateStudy?: { __typename?: "study"; id: any; logo?: string | null } | null;
};

export type UpdateAllowedEmailDomainsMutationVariables = Exact<{
  studyId: Scalars["uuid"]["input"];
  allowedEmailDomains?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type UpdateAllowedEmailDomainsMutation = {
  __typename?: "mutation_root";
  updateStudy?: { __typename?: "study"; id: any; allowedEmailDomains?: string | null } | null;
};

export type AggregateStudiesOfUserInOrganizationQueryVariables = Exact<{
  organizationId: Scalars["uuid"]["input"];
  userId: Scalars["uuid"]["input"];
}>;

export type AggregateStudiesOfUserInOrganizationQuery = {
  __typename?: "query_root";
  aggregateStudyMembers: {
    __typename?: "studyMember_aggregate";
    aggregate?: { __typename?: "studyMember_aggregate_fields"; count: number } | null;
  };
};

export type LeaveStudyMutationVariables = Exact<{
  studyId: Scalars["uuid"]["input"];
  userId: Scalars["uuid"]["input"];
}>;

export type LeaveStudyMutation = {
  __typename?: "mutation_root";
  leaveStudyAction?: { __typename?: "LeaveStudyOutput"; success: boolean } | null;
};

export type CanLeaveStudyQueryVariables = Exact<{
  studyId: Scalars["uuid"]["input"];
  userId: Scalars["uuid"]["input"];
}>;

export type CanLeaveStudyQuery = {
  __typename?: "query_root";
  canLeaveStudyAction: { __typename?: "CanLeaveStudyOutput"; canLeave: boolean };
};

export type DeleteStudyMutationVariables = Exact<{
  studyId: Scalars["uuid"]["input"];
}>;

export type DeleteStudyMutation = {
  __typename?: "mutation_root";
  deleteStudyAction: { __typename?: "DeleteStudyOutput"; studyName: string; success: boolean };
};

export type CanDeleteStudyQueryVariables = Exact<{
  studyId: Scalars["uuid"]["input"];
}>;

export type CanDeleteStudyQuery = {
  __typename?: "query_root";
  canDeleteStudyAction: {
    __typename?: "canDeleteStudyOutput";
    canDelete: boolean;
    runningParticipants: Array<string | null>;
  };
};

export type GetLabelingInformationQueryVariables = Exact<{
  studyId: Scalars["uuid"]["input"];
  language?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetLabelingInformationQuery = {
  __typename?: "query_root";
  getLabelingInformationAction?: {
    __typename?: "LabelingInformation";
    deviceName: string;
    email: string;
    manufacturer: string;
    isForClinicalInvestigationOnly: boolean;
    phone: string;
  } | null;
};

export type GetExecutorUsageStatisticsQueryVariables = Exact<{
  studyId: Scalars["uuid"]["input"];
}>;

export type GetExecutorUsageStatisticsQuery = {
  __typename?: "query_root";
  getExecutorStatisticsAction?: {
    __typename?: "GetExecutorStatisticsOutput";
    id: any;
    expiresAt?: string | null;
    isExpired: boolean;
    participantCount: any;
    participantLimit: any;
    participantLimitReached: boolean;
  } | null;
};

export type GetStudyMemberQueryVariables = Exact<{
  userId: Scalars["uuid"]["input"];
  studyId: Scalars["uuid"]["input"];
}>;

export type GetStudyMemberQuery = {
  __typename?: "query_root";
  studyMember?: {
    __typename?: "studyMember";
    id: any;
    userId: any;
    studyRole: {
      __typename?: "studyRole";
      id: any;
      name: string;
      participantCreateSupervisor: boolean;
      participantDelete: boolean;
      participantDownloadData: boolean;
      participantEditSettings: boolean;
      participantEditTherapyConfig: boolean;
      participantViewDetails: boolean;
      studyCreateMember: boolean;
      studyCreateGroup: boolean;
      studyCreateParticipant: boolean;
      studyDelete: boolean;
      studyDeleteGroup: boolean;
      studyDeleteMember: boolean;
      studyDownloadData: boolean;
      studyEdit: boolean;
      studyEditGroup: boolean;
      studyEditGroupTherapyConfig: boolean;
      studyEditMember: boolean;
      studyViewDetails: boolean;
      studyViewGroupAssignment: boolean;
      studyViewGroups: boolean;
      studyViewGroupTherapyConfig: boolean;
      studyViewMembers: boolean;
      studyViewParticipants: boolean;
    };
  } | null;
};

export type GetStudyMemberByParticipantIdQueryVariables = Exact<{
  participantId: Scalars["uuid"]["input"];
  userId: Scalars["uuid"]["input"];
}>;

export type GetStudyMemberByParticipantIdQuery = {
  __typename?: "query_root";
  participant?: {
    __typename?: "participant";
    participantGroup: {
      __typename?: "participantsGroup";
      id: any;
      study: {
        __typename?: "study";
        studyMembers: Array<{
          __typename?: "studyMember";
          id: any;
          userId: any;
          studyRole: {
            __typename?: "studyRole";
            id: any;
            name: string;
            participantCreateSupervisor: boolean;
            participantDelete: boolean;
            participantDownloadData: boolean;
            participantEditSettings: boolean;
            participantEditTherapyConfig: boolean;
            participantViewDetails: boolean;
            studyCreateMember: boolean;
            studyCreateGroup: boolean;
            studyCreateParticipant: boolean;
            studyDelete: boolean;
            studyDeleteGroup: boolean;
            studyDeleteMember: boolean;
            studyDownloadData: boolean;
            studyEdit: boolean;
            studyEditGroup: boolean;
            studyEditGroupTherapyConfig: boolean;
            studyEditMember: boolean;
            studyViewDetails: boolean;
            studyViewGroupAssignment: boolean;
            studyViewGroups: boolean;
            studyViewGroupTherapyConfig: boolean;
            studyViewMembers: boolean;
            studyViewParticipants: boolean;
          };
        }>;
      };
    };
  } | null;
};

export type DeleteStudyMemberMutationVariables = Exact<{
  userId: Scalars["uuid"]["input"];
  studyId: Scalars["uuid"]["input"];
}>;

export type DeleteStudyMemberMutation = {
  __typename?: "mutation_root";
  deleteStudyMember?: { __typename?: "studyMember"; id: any; userId: any } | null;
};

export type UpdateStudyMemberMutationVariables = Exact<{
  userId: Scalars["uuid"]["input"];
  studyId: Scalars["uuid"]["input"];
  studyRoleId: Scalars["uuid"]["input"];
}>;

export type UpdateStudyMemberMutation = {
  __typename?: "mutation_root";
  updateStudyMember?: {
    __typename?: "studyMember";
    id: any;
    studyRole: { __typename?: "studyRole"; id: any; name: string };
  } | null;
};

export type GetStudySupervisorRoleQueryVariables = Exact<{
  studyId: Scalars["uuid"]["input"];
  userId: Scalars["uuid"]["input"];
  participantId: Scalars["uuid"]["input"];
}>;

export type GetStudySupervisorRoleQuery = {
  __typename?: "query_root";
  studyMember?: {
    __typename?: "studyMember";
    id: any;
    studyRole: { __typename?: "studyRole"; id: any; name: string };
  } | null;
  supervisor?: {
    __typename?: "supervisor";
    id: any;
    supervisorRole: { __typename?: "supervisorRole"; id: any; name: string };
  } | null;
};

export type GetStudyMemberByIdQueryVariables = Exact<{
  roleId: Scalars["uuid"]["input"];
}>;

export type GetStudyMemberByIdQuery = {
  __typename?: "query_root";
  studyMembers: Array<{
    __typename?: "studyMember";
    userId: any;
    id: any;
    studyRole: { __typename?: "studyRole"; id: any; name: string };
  }>;
};

export type CreateStudyMemberMutationVariables = Exact<{
  email: Scalars["String"]["input"];
  studyId: Scalars["uuid"]["input"];
  roleId: Scalars["uuid"]["input"];
}>;

export type CreateStudyMemberMutation = {
  __typename?: "mutation_root";
  createStudyMemberAction?: {
    __typename?: "AddStudyMemberOutput";
    email: string;
    studyMember?: {
      __typename?: "studyMember";
      id: any;
      studyRole: { __typename?: "studyRole"; id: any; name: string; description?: string | null };
    } | null;
  } | null;
};

export type GetStudyMembersQueryVariables = Exact<{
  studyId: Scalars["uuid"]["input"];
}>;

export type GetStudyMembersQuery = {
  __typename?: "query_root";
  getStudyMembersAction: Array<{
    __typename?: "StudyMemberAction";
    id: any;
    userAvatar?: string | null;
    userEmail: string;
    userFirstName?: string | null;
    userLastName?: string | null;
    studyMember?: {
      __typename?: "studyMember";
      id: any;
      userId: any;
      studyRole: { __typename?: "studyRole"; id: any; name: string; createdAt: any };
    } | null;
  }>;
};

export type GetStudyRolesOfStudyQueryVariables = Exact<{
  studyId: Scalars["uuid"]["input"];
}>;

export type GetStudyRolesOfStudyQuery = {
  __typename?: "query_root";
  studyRoles: Array<{ __typename?: "studyRole"; id: any; name: string }>;
};

export type CreateSupervisorsMutationVariables = Exact<{
  assignments: Array<Supervisor_Insert_Input> | Supervisor_Insert_Input;
}>;

export type CreateSupervisorsMutation = {
  __typename?: "mutation_root";
  createSupervisors?: {
    __typename?: "supervisor_mutation_response";
    returning: Array<{ __typename?: "supervisor"; id: any; participantId: any; userId: any }>;
  } | null;
};

export type GetSupervisorAssignmentsOfUserQueryVariables = Exact<{
  userId: Scalars["uuid"]["input"];
  studyId: Scalars["uuid"]["input"];
}>;

export type GetSupervisorAssignmentsOfUserQuery = {
  __typename?: "query_root";
  supervisors: Array<{
    __typename?: "supervisor";
    id: any;
    participant: { __typename?: "participant"; pseudonym: string; id: any };
  }>;
};

export type GetSupervisorQueryVariables = Exact<{
  userId: Scalars["uuid"]["input"];
  participantId: Scalars["uuid"]["input"];
}>;

export type GetSupervisorQuery = {
  __typename?: "query_root";
  supervisor?: {
    __typename?: "supervisor";
    id: any;
    supervisorRole: {
      __typename?: "supervisorRole";
      id: any;
      name: string;
      description?: string | null;
      participantCreateSupervisor: boolean;
      participantDownloadData: boolean;
      participantEditTherapyConfig: boolean;
      participantViewDetails: boolean;
    };
  } | null;
};

export type DeleteSupervisorMutationVariables = Exact<{
  participantId: Scalars["uuid"]["input"];
  userId: Scalars["uuid"]["input"];
}>;

export type DeleteSupervisorMutation = {
  __typename?: "mutation_root";
  deleteSupervisor?: { __typename?: "supervisor"; id: any } | null;
};

export type GetSupervisorsOfStudyQueryVariables = Exact<{
  studyId: Scalars["uuid"]["input"];
}>;

export type GetSupervisorsOfStudyQuery = {
  __typename?: "query_root";
  getSupervisorsOfStudyAction: Array<{
    __typename?: "GetSupervisorsOfStudyOutput";
    userAvatar?: string | null;
    userEmail: string;
    userFirstName?: string | null;
    userId: any;
    userLastName?: string | null;
  }>;
};

export type GetSupervisorsOfParticipantQueryVariables = Exact<{
  participantId: Scalars["uuid"]["input"];
}>;

export type GetSupervisorsOfParticipantQuery = {
  __typename?: "query_root";
  getSupervisorsOfParticipantAction: Array<{
    __typename?: "GetSupervisorsOfParticipantOutput";
    userAvatar?: string | null;
    userEmail: string;
    userFirstName?: string | null;
    userId: any;
    userLastName?: string | null;
  }>;
};

export type CreateSupervisorActionMutationVariables = Exact<{
  participantIds:
    | Array<InputMaybe<Scalars["uuid"]["input"]>>
    | InputMaybe<Scalars["uuid"]["input"]>;
  studyId: Scalars["uuid"]["input"];
  email: Scalars["String"]["input"];
  supervisorRoleId: Scalars["uuid"]["input"];
}>;

export type CreateSupervisorActionMutation = {
  __typename?: "mutation_root";
  createSupervisorAction?: Array<{
    __typename?: "CreateSupervisorOutput";
    id: any;
    participantId: any;
    userId: any;
  } | null> | null;
};

export type GetSupervisorRolesOfStudyQueryVariables = Exact<{
  studyId: Scalars["uuid"]["input"];
}>;

export type GetSupervisorRolesOfStudyQuery = {
  __typename?: "query_root";
  supervisorRoles: Array<{
    __typename?: "supervisorRole";
    id: any;
    name: string;
    participantCreateSupervisor: boolean;
    participantDownloadData: boolean;
    participantEditTherapyConfig: boolean;
    participantViewDetails: boolean;
  }>;
};

export type GetParticipantTherapyConfigQueryVariables = Exact<{
  participantId: Scalars["uuid"]["input"];
}>;

export type GetParticipantTherapyConfigQuery = {
  __typename?: "query_root";
  getParticipantTherapyVariableConfigAction?: Array<{
    __typename?: "GetTherapyVariableValueOutput";
    participantId: any;
    therapyVariableId: any;
    variableValue?: any | null;
  } | null> | null;
};

export type UpdateParticipantTherapyConfigMutationVariables = Exact<{
  objects?: InputMaybe<
    Array<ParticipantVariableConfigsToUpdate> | ParticipantVariableConfigsToUpdate
  >;
}>;

export type UpdateParticipantTherapyConfigMutation = {
  __typename?: "mutation_root";
  updateParticipantTherapyVariableConfigsAction?: Array<{
    __typename?: "ParticipantVariableConfigsToUpdateOutput";
    variableValue: any;
    editorId: any;
    participantId: any;
    therapyVariableId: any;
  } | null> | null;
};

export type ValidateParticipantTherapyConfigQueryVariables = Exact<{
  participantId: Scalars["uuid"]["input"];
  values: Array<VariableConfigValue> | VariableConfigValue;
  language: Scalars["String"]["input"];
}>;

export type ValidateParticipantTherapyConfigQuery = {
  __typename?: "query_root";
  validateParticipantTherapyConfigAction: {
    __typename?: "TherapyConfigValidationResult";
    validationInfoItem?: string | null;
    results?: Array<{
      __typename?: "ValidationResult";
      variableId: string;
      value: any;
      isValid: boolean;
      errorMessage?: string | null;
    }> | null;
  };
};

export type GetTherapyDesignQueryVariables = Exact<{
  studyId: Scalars["uuid"]["input"];
  language: Scalars["String"]["input"];
}>;

export type GetTherapyDesignQuery = {
  __typename?: "query_root";
  getTherapyDesignAction?: { __typename?: "TherapyDesign"; id: any; value: any } | null;
};

export type GetTherapyDesignsQueryVariables = Exact<{
  executorUrl: Scalars["String"]["input"];
}>;

export type GetTherapyDesignsQuery = {
  __typename?: "query_root";
  getTherapyDesignsAction: Array<{
    __typename?: "TherapyDesignInfo";
    description: string;
    id: any;
    title: string;
    version: string;
  }>;
};

export type UpdateTherapyPeriodMutationVariables = Exact<{
  therapyPeriod: TherapyPeriod;
}>;

export type UpdateTherapyPeriodMutation = {
  __typename?: "mutation_root";
  updateTherapyPeriodAction: {
    __typename?: "ParticipantOutput";
    id: any;
    coupleUrl?: string | null;
    therapyEnd?: string | null;
    therapyPeriodId?: any | null;
    therapyStart?: string | null;
  };
};

export type GetUserQueryVariables = Exact<{
  userId: Scalars["uuid"]["input"];
}>;

export type GetUserQuery = {
  __typename?: "query_root";
  user?: {
    __typename?: "user";
    id: any;
    firstName?: string | null;
    lastName?: string | null;
    sendNewsletter?: boolean | null;
    title?: string | null;
    avatar?: string | null;
    studyMembers: Array<{
      __typename?: "studyMember";
      id: any;
      studyRole: { __typename?: "studyRole"; id: any; name: string; description?: string | null };
      study: {
        __typename?: "study";
        name: string;
        id: any;
        description?: string | null;
        logo?: string | null;
        organization: { __typename?: "organization"; name: string };
      };
    }>;
    organizationMembers: Array<{
      __typename?: "organizationMember";
      id: any;
      organizationRole: {
        __typename?: "organizationRole";
        id: any;
        name: string;
        description?: string | null;
        organizationCreateStudy: boolean;
      };
      organization: { __typename?: "organization"; id: any; name: string };
    }>;
    supervisors: Array<{
      __typename?: "supervisor";
      id: any;
      participant: {
        __typename?: "participant";
        id: any;
        pseudonym: string;
        participantGroup: {
          __typename?: "participantsGroup";
          id: any;
          name: string;
          study: { __typename?: "study"; id: any; name: string };
        };
      };
    }>;
  } | null;
};

export type UpdateUserDetailsMutationVariables = Exact<{
  userId: Scalars["uuid"]["input"];
  firstName: Scalars["String"]["input"];
  lastName: Scalars["String"]["input"];
  title?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type UpdateUserDetailsMutation = {
  __typename?: "mutation_root";
  updateUser?: {
    __typename?: "user";
    id: any;
    firstName?: string | null;
    lastName?: string | null;
    title?: string | null;
    sendNewsletter?: boolean | null;
  } | null;
};

export type UpdateAvatarMutationVariables = Exact<{
  userId: Scalars["uuid"]["input"];
  base64Img: Scalars["String"]["input"];
}>;

export type UpdateAvatarMutation = {
  __typename?: "mutation_root";
  updateUser?: { __typename?: "user"; id: any; avatar?: string | null } | null;
};

export type CanDeleteAccountQueryVariables = Exact<{
  userId: Scalars["uuid"]["input"];
}>;

export type CanDeleteAccountQuery = {
  __typename?: "query_root";
  canDeleteAccountAction: {
    __typename?: "CanDeleteAccountOutput";
    studiesLastOwner: Array<string>;
    organizationsLastOwner: Array<string>;
    canDelete: boolean;
  };
};

export type DeleteAccountMutationVariables = Exact<{
  userId: Scalars["uuid"]["input"];
}>;

export type DeleteAccountMutation = {
  __typename?: "mutation_root";
  deleteAccountAction: { __typename?: "DeleteAccountOutput"; success: boolean };
};

export type GetVisualizationsOfParticipantQueryVariables = Exact<{
  end?: InputMaybe<Scalars["String"]["input"]>;
  participantId: Scalars["uuid"]["input"];
  start?: InputMaybe<Scalars["String"]["input"]>;
  language?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetVisualizationsOfParticipantQuery = {
  __typename?: "query_root";
  getVisualizationsAction?: {
    __typename?: "VisualizationPage";
    children: Array<any>;
    id: any;
    title: string;
    warning?: string | null;
    start: string;
    end: string;
    description?: string | null;
  } | null;
};

export const UpdateEmailDocument = gql`
  mutation UpdateEmail($email: String!, $userId: uuid!) {
    updateEmail(email: $email, userId: $userId) {
      userId
      email
    }
  }
`;
export type UpdateEmailMutationFn = Apollo.MutationFunction<
  UpdateEmailMutation,
  UpdateEmailMutationVariables
>;

/**
 * __useUpdateEmailMutation__
 *
 * To run a mutation, you first call `useUpdateEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmailMutation, { data, loading, error }] = useUpdateEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUpdateEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateEmailMutation, UpdateEmailMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateEmailMutation, UpdateEmailMutationVariables>(
    UpdateEmailDocument,
    options,
  );
}
export type UpdateEmailMutationHookResult = ReturnType<typeof useUpdateEmailMutation>;
export type UpdateEmailMutationResult = Apollo.MutationResult<UpdateEmailMutation>;
export type UpdateEmailMutationOptions = Apollo.BaseMutationOptions<
  UpdateEmailMutation,
  UpdateEmailMutationVariables
>;
export const TriggerPasswordUpdateDocument = gql`
  mutation TriggerPasswordUpdate($id: uuid!) {
    triggerPasswordUpdate(userId: $id) {
      success
    }
  }
`;
export type TriggerPasswordUpdateMutationFn = Apollo.MutationFunction<
  TriggerPasswordUpdateMutation,
  TriggerPasswordUpdateMutationVariables
>;

/**
 * __useTriggerPasswordUpdateMutation__
 *
 * To run a mutation, you first call `useTriggerPasswordUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTriggerPasswordUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [triggerPasswordUpdateMutation, { data, loading, error }] = useTriggerPasswordUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTriggerPasswordUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TriggerPasswordUpdateMutation,
    TriggerPasswordUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TriggerPasswordUpdateMutation, TriggerPasswordUpdateMutationVariables>(
    TriggerPasswordUpdateDocument,
    options,
  );
}
export type TriggerPasswordUpdateMutationHookResult = ReturnType<
  typeof useTriggerPasswordUpdateMutation
>;
export type TriggerPasswordUpdateMutationResult =
  Apollo.MutationResult<TriggerPasswordUpdateMutation>;
export type TriggerPasswordUpdateMutationOptions = Apollo.BaseMutationOptions<
  TriggerPasswordUpdateMutation,
  TriggerPasswordUpdateMutationVariables
>;
export const GetAccountDocument = gql`
  query GetAccount($userId: uuid!) {
    getAccountDataAction(userId: $userId) {
      id
      email
      hasuraUser {
        firstName
        lastName
        id
      }
    }
  }
`;

/**
 * __useGetAccountQuery__
 *
 * To run a query within a React component, call `useGetAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetAccountQuery(
  baseOptions: Apollo.QueryHookOptions<GetAccountQuery, GetAccountQueryVariables> &
    ({ variables: GetAccountQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAccountQuery, GetAccountQueryVariables>(GetAccountDocument, options);
}
export function useGetAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAccountQuery, GetAccountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAccountQuery, GetAccountQueryVariables>(
    GetAccountDocument,
    options,
  );
}
export function useGetAccountSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetAccountQuery, GetAccountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetAccountQuery, GetAccountQueryVariables>(
    GetAccountDocument,
    options,
  );
}
export type GetAccountQueryHookResult = ReturnType<typeof useGetAccountQuery>;
export type GetAccountLazyQueryHookResult = ReturnType<typeof useGetAccountLazyQuery>;
export type GetAccountSuspenseQueryHookResult = ReturnType<typeof useGetAccountSuspenseQuery>;
export type GetAccountQueryResult = Apollo.QueryResult<GetAccountQuery, GetAccountQueryVariables>;
export const GetApiAccountsOfStudyDocument = gql`
  query GetApiAccountsOfStudy($studyId: uuid!) {
    apiAccounts(where: { studyId: { _eq: $studyId } }) {
      id
      serviceName
      expiresAt
      createdAt
    }
  }
`;

/**
 * __useGetApiAccountsOfStudyQuery__
 *
 * To run a query within a React component, call `useGetApiAccountsOfStudyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApiAccountsOfStudyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApiAccountsOfStudyQuery({
 *   variables: {
 *      studyId: // value for 'studyId'
 *   },
 * });
 */
export function useGetApiAccountsOfStudyQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetApiAccountsOfStudyQuery,
    GetApiAccountsOfStudyQueryVariables
  > &
    ({ variables: GetApiAccountsOfStudyQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetApiAccountsOfStudyQuery, GetApiAccountsOfStudyQueryVariables>(
    GetApiAccountsOfStudyDocument,
    options,
  );
}
export function useGetApiAccountsOfStudyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetApiAccountsOfStudyQuery,
    GetApiAccountsOfStudyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetApiAccountsOfStudyQuery, GetApiAccountsOfStudyQueryVariables>(
    GetApiAccountsOfStudyDocument,
    options,
  );
}
export function useGetApiAccountsOfStudySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetApiAccountsOfStudyQuery,
    GetApiAccountsOfStudyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetApiAccountsOfStudyQuery, GetApiAccountsOfStudyQueryVariables>(
    GetApiAccountsOfStudyDocument,
    options,
  );
}
export type GetApiAccountsOfStudyQueryHookResult = ReturnType<typeof useGetApiAccountsOfStudyQuery>;
export type GetApiAccountsOfStudyLazyQueryHookResult = ReturnType<
  typeof useGetApiAccountsOfStudyLazyQuery
>;
export type GetApiAccountsOfStudySuspenseQueryHookResult = ReturnType<
  typeof useGetApiAccountsOfStudySuspenseQuery
>;
export type GetApiAccountsOfStudyQueryResult = Apollo.QueryResult<
  GetApiAccountsOfStudyQuery,
  GetApiAccountsOfStudyQueryVariables
>;
export const UpdateApiAccountDocument = gql`
  mutation UpdateApiAccount($id: uuid!, $expiresAt: timestamptz!, $serviceName: String!) {
    updateApiAccount(
      pk_columns: { id: $id }
      _set: { expiresAt: $expiresAt, serviceName: $serviceName }
    ) {
      id
      serviceName
      expiresAt
      createdAt
    }
  }
`;
export type UpdateApiAccountMutationFn = Apollo.MutationFunction<
  UpdateApiAccountMutation,
  UpdateApiAccountMutationVariables
>;

/**
 * __useUpdateApiAccountMutation__
 *
 * To run a mutation, you first call `useUpdateApiAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApiAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApiAccountMutation, { data, loading, error }] = useUpdateApiAccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *      expiresAt: // value for 'expiresAt'
 *      serviceName: // value for 'serviceName'
 *   },
 * });
 */
export function useUpdateApiAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateApiAccountMutation,
    UpdateApiAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateApiAccountMutation, UpdateApiAccountMutationVariables>(
    UpdateApiAccountDocument,
    options,
  );
}
export type UpdateApiAccountMutationHookResult = ReturnType<typeof useUpdateApiAccountMutation>;
export type UpdateApiAccountMutationResult = Apollo.MutationResult<UpdateApiAccountMutation>;
export type UpdateApiAccountMutationOptions = Apollo.BaseMutationOptions<
  UpdateApiAccountMutation,
  UpdateApiAccountMutationVariables
>;
export const CreateApiAccountActionDocument = gql`
  mutation CreateApiAccountAction($expiresAt: String!, $serviceName: String!, $studyId: uuid!) {
    createApiAccountAction(expiresAt: $expiresAt, serviceName: $serviceName, studyId: $studyId) {
      clientId
      clientSecret
      id
      password
      accountId
    }
  }
`;
export type CreateApiAccountActionMutationFn = Apollo.MutationFunction<
  CreateApiAccountActionMutation,
  CreateApiAccountActionMutationVariables
>;

/**
 * __useCreateApiAccountActionMutation__
 *
 * To run a mutation, you first call `useCreateApiAccountActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApiAccountActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApiAccountActionMutation, { data, loading, error }] = useCreateApiAccountActionMutation({
 *   variables: {
 *      expiresAt: // value for 'expiresAt'
 *      serviceName: // value for 'serviceName'
 *      studyId: // value for 'studyId'
 *   },
 * });
 */
export function useCreateApiAccountActionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateApiAccountActionMutation,
    CreateApiAccountActionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateApiAccountActionMutation,
    CreateApiAccountActionMutationVariables
  >(CreateApiAccountActionDocument, options);
}
export type CreateApiAccountActionMutationHookResult = ReturnType<
  typeof useCreateApiAccountActionMutation
>;
export type CreateApiAccountActionMutationResult =
  Apollo.MutationResult<CreateApiAccountActionMutation>;
export type CreateApiAccountActionMutationOptions = Apollo.BaseMutationOptions<
  CreateApiAccountActionMutation,
  CreateApiAccountActionMutationVariables
>;
export const DeleteApiAccountActionDocument = gql`
  mutation DeleteApiAccountAction($id: uuid!) {
    deleteApiAccountAction(id: $id) {
      id
      serviceName
    }
  }
`;
export type DeleteApiAccountActionMutationFn = Apollo.MutationFunction<
  DeleteApiAccountActionMutation,
  DeleteApiAccountActionMutationVariables
>;

/**
 * __useDeleteApiAccountActionMutation__
 *
 * To run a mutation, you first call `useDeleteApiAccountActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApiAccountActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApiAccountActionMutation, { data, loading, error }] = useDeleteApiAccountActionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteApiAccountActionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteApiAccountActionMutation,
    DeleteApiAccountActionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteApiAccountActionMutation,
    DeleteApiAccountActionMutationVariables
  >(DeleteApiAccountActionDocument, options);
}
export type DeleteApiAccountActionMutationHookResult = ReturnType<
  typeof useDeleteApiAccountActionMutation
>;
export type DeleteApiAccountActionMutationResult =
  Apollo.MutationResult<DeleteApiAccountActionMutation>;
export type DeleteApiAccountActionMutationOptions = Apollo.BaseMutationOptions<
  DeleteApiAccountActionMutation,
  DeleteApiAccountActionMutationVariables
>;
export const CreateExecutorDocument = gql`
  mutation CreateExecutor(
    $executorUrl: String!
    $executorUrlExternal: String!
    $name: String!
    $organizationId: uuid
    $therapyDesignId: uuid!
    $participantLimit: Int!
    $expiresAt: timestamptz
  ) {
    createExecutor(
      object: {
        executorUrl: $executorUrl
        executorUrlExternal: $executorUrlExternal
        name: $name
        organizationId: $organizationId
        therapyDesignId: $therapyDesignId
        participantLimit: $participantLimit
        expiresAt: $expiresAt
      }
    ) {
      id
      name
      executorUrl
      executorUrlExternal
      participantLimit
      expiresAt
    }
  }
`;
export type CreateExecutorMutationFn = Apollo.MutationFunction<
  CreateExecutorMutation,
  CreateExecutorMutationVariables
>;

/**
 * __useCreateExecutorMutation__
 *
 * To run a mutation, you first call `useCreateExecutorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExecutorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExecutorMutation, { data, loading, error }] = useCreateExecutorMutation({
 *   variables: {
 *      executorUrl: // value for 'executorUrl'
 *      executorUrlExternal: // value for 'executorUrlExternal'
 *      name: // value for 'name'
 *      organizationId: // value for 'organizationId'
 *      therapyDesignId: // value for 'therapyDesignId'
 *      participantLimit: // value for 'participantLimit'
 *      expiresAt: // value for 'expiresAt'
 *   },
 * });
 */
export function useCreateExecutorMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateExecutorMutation, CreateExecutorMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateExecutorMutation, CreateExecutorMutationVariables>(
    CreateExecutorDocument,
    options,
  );
}
export type CreateExecutorMutationHookResult = ReturnType<typeof useCreateExecutorMutation>;
export type CreateExecutorMutationResult = Apollo.MutationResult<CreateExecutorMutation>;
export type CreateExecutorMutationOptions = Apollo.BaseMutationOptions<
  CreateExecutorMutation,
  CreateExecutorMutationVariables
>;
export const GetExecutorsDocument = gql`
  query GetExecutors {
    executors(order_by: { createdAt: desc }) {
      id
      name
      organizationId
      therapyDesignId
      executorUrl
      executorUrlExternal
      participantLimit
      expiresAt
      hidden
      createdAt
      studies {
        id
        name
        deletedAt
      }
      organization {
        id
        name
      }
    }
  }
`;

/**
 * __useGetExecutorsQuery__
 *
 * To run a query within a React component, call `useGetExecutorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExecutorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExecutorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetExecutorsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetExecutorsQuery, GetExecutorsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetExecutorsQuery, GetExecutorsQueryVariables>(
    GetExecutorsDocument,
    options,
  );
}
export function useGetExecutorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetExecutorsQuery, GetExecutorsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetExecutorsQuery, GetExecutorsQueryVariables>(
    GetExecutorsDocument,
    options,
  );
}
export function useGetExecutorsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetExecutorsQuery, GetExecutorsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetExecutorsQuery, GetExecutorsQueryVariables>(
    GetExecutorsDocument,
    options,
  );
}
export type GetExecutorsQueryHookResult = ReturnType<typeof useGetExecutorsQuery>;
export type GetExecutorsLazyQueryHookResult = ReturnType<typeof useGetExecutorsLazyQuery>;
export type GetExecutorsSuspenseQueryHookResult = ReturnType<typeof useGetExecutorsSuspenseQuery>;
export type GetExecutorsQueryResult = Apollo.QueryResult<
  GetExecutorsQuery,
  GetExecutorsQueryVariables
>;
export const GetExecutorDocument = gql`
  query GetExecutor($executorId: uuid!) {
    executor(id: $executorId) {
      executorUrl
      executorUrlExternal
      participantLimit
      id
      name
      hidden
      expiresAt
      studies {
        id
        name
        deletedAt
      }
      therapyDesignId
      organization {
        id
        name
      }
    }
  }
`;

/**
 * __useGetExecutorQuery__
 *
 * To run a query within a React component, call `useGetExecutorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExecutorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExecutorQuery({
 *   variables: {
 *      executorId: // value for 'executorId'
 *   },
 * });
 */
export function useGetExecutorQuery(
  baseOptions: Apollo.QueryHookOptions<GetExecutorQuery, GetExecutorQueryVariables> &
    ({ variables: GetExecutorQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetExecutorQuery, GetExecutorQueryVariables>(GetExecutorDocument, options);
}
export function useGetExecutorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetExecutorQuery, GetExecutorQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetExecutorQuery, GetExecutorQueryVariables>(
    GetExecutorDocument,
    options,
  );
}
export function useGetExecutorSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetExecutorQuery, GetExecutorQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetExecutorQuery, GetExecutorQueryVariables>(
    GetExecutorDocument,
    options,
  );
}
export type GetExecutorQueryHookResult = ReturnType<typeof useGetExecutorQuery>;
export type GetExecutorLazyQueryHookResult = ReturnType<typeof useGetExecutorLazyQuery>;
export type GetExecutorSuspenseQueryHookResult = ReturnType<typeof useGetExecutorSuspenseQuery>;
export type GetExecutorQueryResult = Apollo.QueryResult<
  GetExecutorQuery,
  GetExecutorQueryVariables
>;
export const DeleteExecutorDocument = gql`
  mutation DeleteExecutor($executorId: uuid!, $deletedAt: timestamp!) {
    updateExecutor(pk_columns: { id: $executorId }, _set: { deletedAt: $deletedAt }) {
      id
      name
      deletedAt
    }
  }
`;
export type DeleteExecutorMutationFn = Apollo.MutationFunction<
  DeleteExecutorMutation,
  DeleteExecutorMutationVariables
>;

/**
 * __useDeleteExecutorMutation__
 *
 * To run a mutation, you first call `useDeleteExecutorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteExecutorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteExecutorMutation, { data, loading, error }] = useDeleteExecutorMutation({
 *   variables: {
 *      executorId: // value for 'executorId'
 *      deletedAt: // value for 'deletedAt'
 *   },
 * });
 */
export function useDeleteExecutorMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteExecutorMutation, DeleteExecutorMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteExecutorMutation, DeleteExecutorMutationVariables>(
    DeleteExecutorDocument,
    options,
  );
}
export type DeleteExecutorMutationHookResult = ReturnType<typeof useDeleteExecutorMutation>;
export type DeleteExecutorMutationResult = Apollo.MutationResult<DeleteExecutorMutation>;
export type DeleteExecutorMutationOptions = Apollo.BaseMutationOptions<
  DeleteExecutorMutation,
  DeleteExecutorMutationVariables
>;
export const UpdateExecutorDocument = gql`
  mutation UpdateExecutor(
    $id: uuid!
    $executorUrl: String!
    $executorUrlExternal: String!
    $name: String!
    $hidden: Boolean!
    $participantLimit: Int!
    $expiresAt: timestamptz
  ) {
    updateExecutor(
      pk_columns: { id: $id }
      _set: {
        executorUrl: $executorUrl
        executorUrlExternal: $executorUrlExternal
        name: $name
        hidden: $hidden
        participantLimit: $participantLimit
        expiresAt: $expiresAt
      }
    ) {
      id
      hidden
      executorUrl
      executorUrlExternal
      name
      participantLimit
      expiresAt
    }
  }
`;
export type UpdateExecutorMutationFn = Apollo.MutationFunction<
  UpdateExecutorMutation,
  UpdateExecutorMutationVariables
>;

/**
 * __useUpdateExecutorMutation__
 *
 * To run a mutation, you first call `useUpdateExecutorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExecutorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExecutorMutation, { data, loading, error }] = useUpdateExecutorMutation({
 *   variables: {
 *      id: // value for 'id'
 *      executorUrl: // value for 'executorUrl'
 *      executorUrlExternal: // value for 'executorUrlExternal'
 *      name: // value for 'name'
 *      hidden: // value for 'hidden'
 *      participantLimit: // value for 'participantLimit'
 *      expiresAt: // value for 'expiresAt'
 *   },
 * });
 */
export function useUpdateExecutorMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateExecutorMutation, UpdateExecutorMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateExecutorMutation, UpdateExecutorMutationVariables>(
    UpdateExecutorDocument,
    options,
  );
}
export type UpdateExecutorMutationHookResult = ReturnType<typeof useUpdateExecutorMutation>;
export type UpdateExecutorMutationResult = Apollo.MutationResult<UpdateExecutorMutation>;
export type UpdateExecutorMutationOptions = Apollo.BaseMutationOptions<
  UpdateExecutorMutation,
  UpdateExecutorMutationVariables
>;
export const GetGroupVariablesConfigsDocument = gql`
  query GetGroupVariablesConfigs($studyId: uuid!) {
    getGroupsTherapyVariableConfigAction(studyId: $studyId) {
      id
      groupValues {
        id
        participantsGroupId
        variableId
        variableValue
      }
    }
  }
`;

/**
 * __useGetGroupVariablesConfigsQuery__
 *
 * To run a query within a React component, call `useGetGroupVariablesConfigsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupVariablesConfigsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupVariablesConfigsQuery({
 *   variables: {
 *      studyId: // value for 'studyId'
 *   },
 * });
 */
export function useGetGroupVariablesConfigsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetGroupVariablesConfigsQuery,
    GetGroupVariablesConfigsQueryVariables
  > &
    ({ variables: GetGroupVariablesConfigsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetGroupVariablesConfigsQuery, GetGroupVariablesConfigsQueryVariables>(
    GetGroupVariablesConfigsDocument,
    options,
  );
}
export function useGetGroupVariablesConfigsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGroupVariablesConfigsQuery,
    GetGroupVariablesConfigsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetGroupVariablesConfigsQuery, GetGroupVariablesConfigsQueryVariables>(
    GetGroupVariablesConfigsDocument,
    options,
  );
}
export function useGetGroupVariablesConfigsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetGroupVariablesConfigsQuery,
    GetGroupVariablesConfigsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetGroupVariablesConfigsQuery,
    GetGroupVariablesConfigsQueryVariables
  >(GetGroupVariablesConfigsDocument, options);
}
export type GetGroupVariablesConfigsQueryHookResult = ReturnType<
  typeof useGetGroupVariablesConfigsQuery
>;
export type GetGroupVariablesConfigsLazyQueryHookResult = ReturnType<
  typeof useGetGroupVariablesConfigsLazyQuery
>;
export type GetGroupVariablesConfigsSuspenseQueryHookResult = ReturnType<
  typeof useGetGroupVariablesConfigsSuspenseQuery
>;
export type GetGroupVariablesConfigsQueryResult = Apollo.QueryResult<
  GetGroupVariablesConfigsQuery,
  GetGroupVariablesConfigsQueryVariables
>;
export const UpdateGroupVariableConfigDocument = gql`
  mutation UpdateGroupVariableConfig($objects: [GroupConfigsToUpdate!]) {
    updateGroupTherapyVariableConfigsAction(objects: $objects) {
      id
    }
  }
`;
export type UpdateGroupVariableConfigMutationFn = Apollo.MutationFunction<
  UpdateGroupVariableConfigMutation,
  UpdateGroupVariableConfigMutationVariables
>;

/**
 * __useUpdateGroupVariableConfigMutation__
 *
 * To run a mutation, you first call `useUpdateGroupVariableConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGroupVariableConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGroupVariableConfigMutation, { data, loading, error }] = useUpdateGroupVariableConfigMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useUpdateGroupVariableConfigMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateGroupVariableConfigMutation,
    UpdateGroupVariableConfigMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateGroupVariableConfigMutation,
    UpdateGroupVariableConfigMutationVariables
  >(UpdateGroupVariableConfigDocument, options);
}
export type UpdateGroupVariableConfigMutationHookResult = ReturnType<
  typeof useUpdateGroupVariableConfigMutation
>;
export type UpdateGroupVariableConfigMutationResult =
  Apollo.MutationResult<UpdateGroupVariableConfigMutation>;
export type UpdateGroupVariableConfigMutationOptions = Apollo.BaseMutationOptions<
  UpdateGroupVariableConfigMutation,
  UpdateGroupVariableConfigMutationVariables
>;
export const GetOrganizationDocument = gql`
  query GetOrganization($organizationId: uuid!) {
    organization(id: $organizationId) {
      contracts {
        id
        signerEmail
        signerFirstName
        signerLastName
        contractType
        createdAt
      }
      studies {
        name
        id
        description
        createdAt
        logo
      }
      organizationMembers {
        id
        userId
        createdAt
        organizationRole {
          id
          name
        }
      }
      organizationRoles {
        id
        name
      }
      address
      city
      country
      department
      description
      name
      zip
      id
    }
  }
`;

/**
 * __useGetOrganizationQuery__
 *
 * To run a query within a React component, call `useGetOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetOrganizationQuery(
  baseOptions: Apollo.QueryHookOptions<GetOrganizationQuery, GetOrganizationQueryVariables> &
    ({ variables: GetOrganizationQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrganizationQuery, GetOrganizationQueryVariables>(
    GetOrganizationDocument,
    options,
  );
}
export function useGetOrganizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationQuery, GetOrganizationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOrganizationQuery, GetOrganizationQueryVariables>(
    GetOrganizationDocument,
    options,
  );
}
export function useGetOrganizationSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetOrganizationQuery,
    GetOrganizationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetOrganizationQuery, GetOrganizationQueryVariables>(
    GetOrganizationDocument,
    options,
  );
}
export type GetOrganizationQueryHookResult = ReturnType<typeof useGetOrganizationQuery>;
export type GetOrganizationLazyQueryHookResult = ReturnType<typeof useGetOrganizationLazyQuery>;
export type GetOrganizationSuspenseQueryHookResult = ReturnType<
  typeof useGetOrganizationSuspenseQuery
>;
export type GetOrganizationQueryResult = Apollo.QueryResult<
  GetOrganizationQuery,
  GetOrganizationQueryVariables
>;
export const GetOrganizationsDocument = gql`
  query GetOrganizations {
    organizations {
      id
      name
    }
  }
`;

/**
 * __useGetOrganizationsQuery__
 *
 * To run a query within a React component, call `useGetOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrganizationsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetOrganizationsQuery, GetOrganizationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrganizationsQuery, GetOrganizationsQueryVariables>(
    GetOrganizationsDocument,
    options,
  );
}
export function useGetOrganizationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationsQuery, GetOrganizationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOrganizationsQuery, GetOrganizationsQueryVariables>(
    GetOrganizationsDocument,
    options,
  );
}
export function useGetOrganizationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetOrganizationsQuery,
    GetOrganizationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetOrganizationsQuery, GetOrganizationsQueryVariables>(
    GetOrganizationsDocument,
    options,
  );
}
export type GetOrganizationsQueryHookResult = ReturnType<typeof useGetOrganizationsQuery>;
export type GetOrganizationsLazyQueryHookResult = ReturnType<typeof useGetOrganizationsLazyQuery>;
export type GetOrganizationsSuspenseQueryHookResult = ReturnType<
  typeof useGetOrganizationsSuspenseQuery
>;
export type GetOrganizationsQueryResult = Apollo.QueryResult<
  GetOrganizationsQuery,
  GetOrganizationsQueryVariables
>;
export const CreateNewOrganizationDocument = gql`
  mutation CreateNewOrganization(
    $description: String
    $department: String
    $country: String!
    $city: String!
    $address: String!
    $name: String!
    $zip: numeric!
    $userId: uuid!
  ) {
    createOrganizationAction(
      newOrganization: {
        address: $address
        city: $city
        country: $country
        name: $name
        zip: $zip
        department: $department
        description: $description
        userId: $userId
      }
    ) {
      id
    }
  }
`;
export type CreateNewOrganizationMutationFn = Apollo.MutationFunction<
  CreateNewOrganizationMutation,
  CreateNewOrganizationMutationVariables
>;

/**
 * __useCreateNewOrganizationMutation__
 *
 * To run a mutation, you first call `useCreateNewOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewOrganizationMutation, { data, loading, error }] = useCreateNewOrganizationMutation({
 *   variables: {
 *      description: // value for 'description'
 *      department: // value for 'department'
 *      country: // value for 'country'
 *      city: // value for 'city'
 *      address: // value for 'address'
 *      name: // value for 'name'
 *      zip: // value for 'zip'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCreateNewOrganizationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateNewOrganizationMutation,
    CreateNewOrganizationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateNewOrganizationMutation, CreateNewOrganizationMutationVariables>(
    CreateNewOrganizationDocument,
    options,
  );
}
export type CreateNewOrganizationMutationHookResult = ReturnType<
  typeof useCreateNewOrganizationMutation
>;
export type CreateNewOrganizationMutationResult =
  Apollo.MutationResult<CreateNewOrganizationMutation>;
export type CreateNewOrganizationMutationOptions = Apollo.BaseMutationOptions<
  CreateNewOrganizationMutation,
  CreateNewOrganizationMutationVariables
>;
export const UpdateOrganizationDocument = gql`
  mutation UpdateOrganization(
    $organizationId: uuid!
    $address: String!
    $city: String!
    $country: String!
    $department: String
    $description: String
    $name: String!
    $zip: String!
  ) {
    updateOrganization(
      pk_columns: { id: $organizationId }
      _set: {
        address: $address
        city: $city
        country: $country
        department: $department
        description: $description
        name: $name
        zip: $zip
      }
    ) {
      id
      name
      address
      city
      country
      department
      description
      zip
    }
  }
`;
export type UpdateOrganizationMutationFn = Apollo.MutationFunction<
  UpdateOrganizationMutation,
  UpdateOrganizationMutationVariables
>;

/**
 * __useUpdateOrganizationMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationMutation, { data, loading, error }] = useUpdateOrganizationMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      address: // value for 'address'
 *      city: // value for 'city'
 *      country: // value for 'country'
 *      department: // value for 'department'
 *      description: // value for 'description'
 *      name: // value for 'name'
 *      zip: // value for 'zip'
 *   },
 * });
 */
export function useUpdateOrganizationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrganizationMutation,
    UpdateOrganizationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>(
    UpdateOrganizationDocument,
    options,
  );
}
export type UpdateOrganizationMutationHookResult = ReturnType<typeof useUpdateOrganizationMutation>;
export type UpdateOrganizationMutationResult = Apollo.MutationResult<UpdateOrganizationMutation>;
export type UpdateOrganizationMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrganizationMutation,
  UpdateOrganizationMutationVariables
>;
export const LeaveOrganizationDocument = gql`
  mutation LeaveOrganization($organizationId: uuid!, $userId: uuid!) {
    leaveOrganizationAction(organizationId: $organizationId, userId: $userId) {
      success
    }
  }
`;
export type LeaveOrganizationMutationFn = Apollo.MutationFunction<
  LeaveOrganizationMutation,
  LeaveOrganizationMutationVariables
>;

/**
 * __useLeaveOrganizationMutation__
 *
 * To run a mutation, you first call `useLeaveOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveOrganizationMutation, { data, loading, error }] = useLeaveOrganizationMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useLeaveOrganizationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LeaveOrganizationMutation,
    LeaveOrganizationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LeaveOrganizationMutation, LeaveOrganizationMutationVariables>(
    LeaveOrganizationDocument,
    options,
  );
}
export type LeaveOrganizationMutationHookResult = ReturnType<typeof useLeaveOrganizationMutation>;
export type LeaveOrganizationMutationResult = Apollo.MutationResult<LeaveOrganizationMutation>;
export type LeaveOrganizationMutationOptions = Apollo.BaseMutationOptions<
  LeaveOrganizationMutation,
  LeaveOrganizationMutationVariables
>;
export const CanLeaveOrganizationDocument = gql`
  query CanLeaveOrganization($organizationId: uuid!, $userId: uuid!) {
    canLeaveOrganizationAction(organizationId: $organizationId, userId: $userId) {
      canLeave
    }
  }
`;

/**
 * __useCanLeaveOrganizationQuery__
 *
 * To run a query within a React component, call `useCanLeaveOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCanLeaveOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCanLeaveOrganizationQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCanLeaveOrganizationQuery(
  baseOptions: Apollo.QueryHookOptions<
    CanLeaveOrganizationQuery,
    CanLeaveOrganizationQueryVariables
  > &
    ({ variables: CanLeaveOrganizationQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CanLeaveOrganizationQuery, CanLeaveOrganizationQueryVariables>(
    CanLeaveOrganizationDocument,
    options,
  );
}
export function useCanLeaveOrganizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CanLeaveOrganizationQuery,
    CanLeaveOrganizationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CanLeaveOrganizationQuery, CanLeaveOrganizationQueryVariables>(
    CanLeaveOrganizationDocument,
    options,
  );
}
export function useCanLeaveOrganizationSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CanLeaveOrganizationQuery,
    CanLeaveOrganizationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CanLeaveOrganizationQuery, CanLeaveOrganizationQueryVariables>(
    CanLeaveOrganizationDocument,
    options,
  );
}
export type CanLeaveOrganizationQueryHookResult = ReturnType<typeof useCanLeaveOrganizationQuery>;
export type CanLeaveOrganizationLazyQueryHookResult = ReturnType<
  typeof useCanLeaveOrganizationLazyQuery
>;
export type CanLeaveOrganizationSuspenseQueryHookResult = ReturnType<
  typeof useCanLeaveOrganizationSuspenseQuery
>;
export type CanLeaveOrganizationQueryResult = Apollo.QueryResult<
  CanLeaveOrganizationQuery,
  CanLeaveOrganizationQueryVariables
>;
export const DeleteOrganizationDocument = gql`
  mutation DeleteOrganization($organizationId: uuid!) {
    deleteOrganizationAction(organizationId: $organizationId) {
      organizationName
      success
    }
  }
`;
export type DeleteOrganizationMutationFn = Apollo.MutationFunction<
  DeleteOrganizationMutation,
  DeleteOrganizationMutationVariables
>;

/**
 * __useDeleteOrganizationMutation__
 *
 * To run a mutation, you first call `useDeleteOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrganizationMutation, { data, loading, error }] = useDeleteOrganizationMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useDeleteOrganizationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteOrganizationMutation,
    DeleteOrganizationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteOrganizationMutation, DeleteOrganizationMutationVariables>(
    DeleteOrganizationDocument,
    options,
  );
}
export type DeleteOrganizationMutationHookResult = ReturnType<typeof useDeleteOrganizationMutation>;
export type DeleteOrganizationMutationResult = Apollo.MutationResult<DeleteOrganizationMutation>;
export type DeleteOrganizationMutationOptions = Apollo.BaseMutationOptions<
  DeleteOrganizationMutation,
  DeleteOrganizationMutationVariables
>;
export const GetRoleOfOrganizationMemberDocument = gql`
  query GetRoleOfOrganizationMember($userId: uuid!, $organizationId: uuid!) {
    organizationMember(organizationId: $organizationId, userId: $userId) {
      id
      organizationRole {
        id
        name
        organizationCreateMember
        organizationCreateStudy
        organizationDeleteOrganization
        organizationEditBilling
        organizationEditMemberRole
        organizationEditOrganization
        organizationDeleteMember
        organizationViewDetails
        organizationViewInvoices
        organizationViewMembers
        organizationViewStudies
      }
    }
  }
`;

/**
 * __useGetRoleOfOrganizationMemberQuery__
 *
 * To run a query within a React component, call `useGetRoleOfOrganizationMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoleOfOrganizationMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoleOfOrganizationMemberQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetRoleOfOrganizationMemberQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRoleOfOrganizationMemberQuery,
    GetRoleOfOrganizationMemberQueryVariables
  > &
    ({ variables: GetRoleOfOrganizationMemberQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetRoleOfOrganizationMemberQuery,
    GetRoleOfOrganizationMemberQueryVariables
  >(GetRoleOfOrganizationMemberDocument, options);
}
export function useGetRoleOfOrganizationMemberLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRoleOfOrganizationMemberQuery,
    GetRoleOfOrganizationMemberQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRoleOfOrganizationMemberQuery,
    GetRoleOfOrganizationMemberQueryVariables
  >(GetRoleOfOrganizationMemberDocument, options);
}
export function useGetRoleOfOrganizationMemberSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetRoleOfOrganizationMemberQuery,
    GetRoleOfOrganizationMemberQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetRoleOfOrganizationMemberQuery,
    GetRoleOfOrganizationMemberQueryVariables
  >(GetRoleOfOrganizationMemberDocument, options);
}
export type GetRoleOfOrganizationMemberQueryHookResult = ReturnType<
  typeof useGetRoleOfOrganizationMemberQuery
>;
export type GetRoleOfOrganizationMemberLazyQueryHookResult = ReturnType<
  typeof useGetRoleOfOrganizationMemberLazyQuery
>;
export type GetRoleOfOrganizationMemberSuspenseQueryHookResult = ReturnType<
  typeof useGetRoleOfOrganizationMemberSuspenseQuery
>;
export type GetRoleOfOrganizationMemberQueryResult = Apollo.QueryResult<
  GetRoleOfOrganizationMemberQuery,
  GetRoleOfOrganizationMemberQueryVariables
>;
export const GetOrganizationMemberByIdDocument = gql`
  query GetOrganizationMemberById($id: uuid!) {
    organizationMembers(where: { id: { _eq: $id } }) {
      id
      userId
      organizationRole {
        id
        name
      }
    }
  }
`;

/**
 * __useGetOrganizationMemberByIdQuery__
 *
 * To run a query within a React component, call `useGetOrganizationMemberByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationMemberByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationMemberByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrganizationMemberByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOrganizationMemberByIdQuery,
    GetOrganizationMemberByIdQueryVariables
  > &
    ({ variables: GetOrganizationMemberByIdQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrganizationMemberByIdQuery, GetOrganizationMemberByIdQueryVariables>(
    GetOrganizationMemberByIdDocument,
    options,
  );
}
export function useGetOrganizationMemberByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrganizationMemberByIdQuery,
    GetOrganizationMemberByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOrganizationMemberByIdQuery,
    GetOrganizationMemberByIdQueryVariables
  >(GetOrganizationMemberByIdDocument, options);
}
export function useGetOrganizationMemberByIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetOrganizationMemberByIdQuery,
    GetOrganizationMemberByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetOrganizationMemberByIdQuery,
    GetOrganizationMemberByIdQueryVariables
  >(GetOrganizationMemberByIdDocument, options);
}
export type GetOrganizationMemberByIdQueryHookResult = ReturnType<
  typeof useGetOrganizationMemberByIdQuery
>;
export type GetOrganizationMemberByIdLazyQueryHookResult = ReturnType<
  typeof useGetOrganizationMemberByIdLazyQuery
>;
export type GetOrganizationMemberByIdSuspenseQueryHookResult = ReturnType<
  typeof useGetOrganizationMemberByIdSuspenseQuery
>;
export type GetOrganizationMemberByIdQueryResult = Apollo.QueryResult<
  GetOrganizationMemberByIdQuery,
  GetOrganizationMemberByIdQueryVariables
>;
export const GetOrganizationMembersDocument = gql`
  query GetOrganizationMembers($organizationId: uuid!) {
    getOrganizationMembersAction(organizationId: $organizationId) {
      id
      organizationMember {
        userId
        id
        organizationRole {
          id
          name
        }
      }
      userAvatar
      userEmail
      userFirstName
      userLastName
    }
  }
`;

/**
 * __useGetOrganizationMembersQuery__
 *
 * To run a query within a React component, call `useGetOrganizationMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationMembersQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetOrganizationMembersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOrganizationMembersQuery,
    GetOrganizationMembersQueryVariables
  > &
    ({ variables: GetOrganizationMembersQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrganizationMembersQuery, GetOrganizationMembersQueryVariables>(
    GetOrganizationMembersDocument,
    options,
  );
}
export function useGetOrganizationMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrganizationMembersQuery,
    GetOrganizationMembersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOrganizationMembersQuery, GetOrganizationMembersQueryVariables>(
    GetOrganizationMembersDocument,
    options,
  );
}
export function useGetOrganizationMembersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetOrganizationMembersQuery,
    GetOrganizationMembersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetOrganizationMembersQuery, GetOrganizationMembersQueryVariables>(
    GetOrganizationMembersDocument,
    options,
  );
}
export type GetOrganizationMembersQueryHookResult = ReturnType<
  typeof useGetOrganizationMembersQuery
>;
export type GetOrganizationMembersLazyQueryHookResult = ReturnType<
  typeof useGetOrganizationMembersLazyQuery
>;
export type GetOrganizationMembersSuspenseQueryHookResult = ReturnType<
  typeof useGetOrganizationMembersSuspenseQuery
>;
export type GetOrganizationMembersQueryResult = Apollo.QueryResult<
  GetOrganizationMembersQuery,
  GetOrganizationMembersQueryVariables
>;
export const CreateOrganizationMemberDocument = gql`
  mutation CreateOrganizationMember($email: String!, $organizationId: uuid!, $roleId: uuid!) {
    createOrganizationMemberAction(
      email: $email
      organizationId: $organizationId
      roleId: $roleId
    ) {
      email
      organizationMember {
        id
        organizationRole {
          id
          name
          description
        }
      }
    }
  }
`;
export type CreateOrganizationMemberMutationFn = Apollo.MutationFunction<
  CreateOrganizationMemberMutation,
  CreateOrganizationMemberMutationVariables
>;

/**
 * __useCreateOrganizationMemberMutation__
 *
 * To run a mutation, you first call `useCreateOrganizationMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganizationMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganizationMemberMutation, { data, loading, error }] = useCreateOrganizationMemberMutation({
 *   variables: {
 *      email: // value for 'email'
 *      organizationId: // value for 'organizationId'
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function useCreateOrganizationMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOrganizationMemberMutation,
    CreateOrganizationMemberMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOrganizationMemberMutation,
    CreateOrganizationMemberMutationVariables
  >(CreateOrganizationMemberDocument, options);
}
export type CreateOrganizationMemberMutationHookResult = ReturnType<
  typeof useCreateOrganizationMemberMutation
>;
export type CreateOrganizationMemberMutationResult =
  Apollo.MutationResult<CreateOrganizationMemberMutation>;
export type CreateOrganizationMemberMutationOptions = Apollo.BaseMutationOptions<
  CreateOrganizationMemberMutation,
  CreateOrganizationMemberMutationVariables
>;
export const DeleteOrganizationMemberDocument = gql`
  mutation DeleteOrganizationMember($organizationId: uuid!, $userId: uuid!) {
    deleteOrganizationMember(organizationId: $organizationId, userId: $userId) {
      id
    }
  }
`;
export type DeleteOrganizationMemberMutationFn = Apollo.MutationFunction<
  DeleteOrganizationMemberMutation,
  DeleteOrganizationMemberMutationVariables
>;

/**
 * __useDeleteOrganizationMemberMutation__
 *
 * To run a mutation, you first call `useDeleteOrganizationMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrganizationMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrganizationMemberMutation, { data, loading, error }] = useDeleteOrganizationMemberMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeleteOrganizationMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteOrganizationMemberMutation,
    DeleteOrganizationMemberMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteOrganizationMemberMutation,
    DeleteOrganizationMemberMutationVariables
  >(DeleteOrganizationMemberDocument, options);
}
export type DeleteOrganizationMemberMutationHookResult = ReturnType<
  typeof useDeleteOrganizationMemberMutation
>;
export type DeleteOrganizationMemberMutationResult =
  Apollo.MutationResult<DeleteOrganizationMemberMutation>;
export type DeleteOrganizationMemberMutationOptions = Apollo.BaseMutationOptions<
  DeleteOrganizationMemberMutation,
  DeleteOrganizationMemberMutationVariables
>;
export const UpdateOrganizationMemberDocument = gql`
  mutation UpdateOrganizationMember(
    $organizationId: uuid!
    $userId: uuid!
    $organizationRoleId: uuid!
  ) {
    updateOrganizationMember(
      pk_columns: { organizationId: $organizationId, userId: $userId }
      _set: { organizationRoleId: $organizationRoleId }
    ) {
      id
      organizationRole {
        id
        name
      }
    }
  }
`;
export type UpdateOrganizationMemberMutationFn = Apollo.MutationFunction<
  UpdateOrganizationMemberMutation,
  UpdateOrganizationMemberMutationVariables
>;

/**
 * __useUpdateOrganizationMemberMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationMemberMutation, { data, loading, error }] = useUpdateOrganizationMemberMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      userId: // value for 'userId'
 *      organizationRoleId: // value for 'organizationRoleId'
 *   },
 * });
 */
export function useUpdateOrganizationMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrganizationMemberMutation,
    UpdateOrganizationMemberMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOrganizationMemberMutation,
    UpdateOrganizationMemberMutationVariables
  >(UpdateOrganizationMemberDocument, options);
}
export type UpdateOrganizationMemberMutationHookResult = ReturnType<
  typeof useUpdateOrganizationMemberMutation
>;
export type UpdateOrganizationMemberMutationResult =
  Apollo.MutationResult<UpdateOrganizationMemberMutation>;
export type UpdateOrganizationMemberMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrganizationMemberMutation,
  UpdateOrganizationMemberMutationVariables
>;
export const GetOrganizationRolesOfOrganizationDocument = gql`
  query GetOrganizationRolesOfOrganization($organizationId: uuid!) {
    organizationRoles(where: { organizationId: { _eq: $organizationId } }) {
      id
      name
    }
  }
`;

/**
 * __useGetOrganizationRolesOfOrganizationQuery__
 *
 * To run a query within a React component, call `useGetOrganizationRolesOfOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationRolesOfOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationRolesOfOrganizationQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetOrganizationRolesOfOrganizationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOrganizationRolesOfOrganizationQuery,
    GetOrganizationRolesOfOrganizationQueryVariables
  > &
    (
      | { variables: GetOrganizationRolesOfOrganizationQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOrganizationRolesOfOrganizationQuery,
    GetOrganizationRolesOfOrganizationQueryVariables
  >(GetOrganizationRolesOfOrganizationDocument, options);
}
export function useGetOrganizationRolesOfOrganizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrganizationRolesOfOrganizationQuery,
    GetOrganizationRolesOfOrganizationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOrganizationRolesOfOrganizationQuery,
    GetOrganizationRolesOfOrganizationQueryVariables
  >(GetOrganizationRolesOfOrganizationDocument, options);
}
export function useGetOrganizationRolesOfOrganizationSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetOrganizationRolesOfOrganizationQuery,
    GetOrganizationRolesOfOrganizationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetOrganizationRolesOfOrganizationQuery,
    GetOrganizationRolesOfOrganizationQueryVariables
  >(GetOrganizationRolesOfOrganizationDocument, options);
}
export type GetOrganizationRolesOfOrganizationQueryHookResult = ReturnType<
  typeof useGetOrganizationRolesOfOrganizationQuery
>;
export type GetOrganizationRolesOfOrganizationLazyQueryHookResult = ReturnType<
  typeof useGetOrganizationRolesOfOrganizationLazyQuery
>;
export type GetOrganizationRolesOfOrganizationSuspenseQueryHookResult = ReturnType<
  typeof useGetOrganizationRolesOfOrganizationSuspenseQuery
>;
export type GetOrganizationRolesOfOrganizationQueryResult = Apollo.QueryResult<
  GetOrganizationRolesOfOrganizationQuery,
  GetOrganizationRolesOfOrganizationQueryVariables
>;
export const CreateParticipantActionDocument = gql`
  mutation CreateParticipantAction(
    $groupId: uuid!
    $pseudonym: String!
    $therapyEnd: String!
    $therapyStart: String!
  ) {
    createParticipantAction(
      participant: {
        groupId: $groupId
        pseudonym: $pseudonym
        therapyEnd: $therapyEnd
        therapyStart: $therapyStart
      }
    ) {
      participantId
      participant {
        id
        pseudonym
        groupId
      }
    }
  }
`;
export type CreateParticipantActionMutationFn = Apollo.MutationFunction<
  CreateParticipantActionMutation,
  CreateParticipantActionMutationVariables
>;

/**
 * __useCreateParticipantActionMutation__
 *
 * To run a mutation, you first call `useCreateParticipantActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateParticipantActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createParticipantActionMutation, { data, loading, error }] = useCreateParticipantActionMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      pseudonym: // value for 'pseudonym'
 *      therapyEnd: // value for 'therapyEnd'
 *      therapyStart: // value for 'therapyStart'
 *   },
 * });
 */
export function useCreateParticipantActionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateParticipantActionMutation,
    CreateParticipantActionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateParticipantActionMutation,
    CreateParticipantActionMutationVariables
  >(CreateParticipantActionDocument, options);
}
export type CreateParticipantActionMutationHookResult = ReturnType<
  typeof useCreateParticipantActionMutation
>;
export type CreateParticipantActionMutationResult =
  Apollo.MutationResult<CreateParticipantActionMutation>;
export type CreateParticipantActionMutationOptions = Apollo.BaseMutationOptions<
  CreateParticipantActionMutation,
  CreateParticipantActionMutationVariables
>;
export const GetParticipantCountOfStudyDocument = gql`
  query GetParticipantCountOfStudy($studyId: uuid!) {
    aggregateParticipants(where: { participantGroup: { studyId: { _eq: $studyId } } }) {
      aggregate {
        count
      }
    }
  }
`;

/**
 * __useGetParticipantCountOfStudyQuery__
 *
 * To run a query within a React component, call `useGetParticipantCountOfStudyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParticipantCountOfStudyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParticipantCountOfStudyQuery({
 *   variables: {
 *      studyId: // value for 'studyId'
 *   },
 * });
 */
export function useGetParticipantCountOfStudyQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetParticipantCountOfStudyQuery,
    GetParticipantCountOfStudyQueryVariables
  > &
    ({ variables: GetParticipantCountOfStudyQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetParticipantCountOfStudyQuery, GetParticipantCountOfStudyQueryVariables>(
    GetParticipantCountOfStudyDocument,
    options,
  );
}
export function useGetParticipantCountOfStudyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetParticipantCountOfStudyQuery,
    GetParticipantCountOfStudyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetParticipantCountOfStudyQuery,
    GetParticipantCountOfStudyQueryVariables
  >(GetParticipantCountOfStudyDocument, options);
}
export function useGetParticipantCountOfStudySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetParticipantCountOfStudyQuery,
    GetParticipantCountOfStudyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetParticipantCountOfStudyQuery,
    GetParticipantCountOfStudyQueryVariables
  >(GetParticipantCountOfStudyDocument, options);
}
export type GetParticipantCountOfStudyQueryHookResult = ReturnType<
  typeof useGetParticipantCountOfStudyQuery
>;
export type GetParticipantCountOfStudyLazyQueryHookResult = ReturnType<
  typeof useGetParticipantCountOfStudyLazyQuery
>;
export type GetParticipantCountOfStudySuspenseQueryHookResult = ReturnType<
  typeof useGetParticipantCountOfStudySuspenseQuery
>;
export type GetParticipantCountOfStudyQueryResult = Apollo.QueryResult<
  GetParticipantCountOfStudyQuery,
  GetParticipantCountOfStudyQueryVariables
>;
export const GetParticipantsByStudyIdAndPseudonymDocument = gql`
  query GetParticipantsByStudyIdAndPseudonym($pseudonym: String!, $studyId: uuid!) {
    participants(
      where: {
        _and: { pseudonym: { _eq: $pseudonym }, participantGroup: { studyId: { _eq: $studyId } } }
      }
    ) {
      id
    }
  }
`;

/**
 * __useGetParticipantsByStudyIdAndPseudonymQuery__
 *
 * To run a query within a React component, call `useGetParticipantsByStudyIdAndPseudonymQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParticipantsByStudyIdAndPseudonymQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParticipantsByStudyIdAndPseudonymQuery({
 *   variables: {
 *      pseudonym: // value for 'pseudonym'
 *      studyId: // value for 'studyId'
 *   },
 * });
 */
export function useGetParticipantsByStudyIdAndPseudonymQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetParticipantsByStudyIdAndPseudonymQuery,
    GetParticipantsByStudyIdAndPseudonymQueryVariables
  > &
    (
      | { variables: GetParticipantsByStudyIdAndPseudonymQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetParticipantsByStudyIdAndPseudonymQuery,
    GetParticipantsByStudyIdAndPseudonymQueryVariables
  >(GetParticipantsByStudyIdAndPseudonymDocument, options);
}
export function useGetParticipantsByStudyIdAndPseudonymLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetParticipantsByStudyIdAndPseudonymQuery,
    GetParticipantsByStudyIdAndPseudonymQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetParticipantsByStudyIdAndPseudonymQuery,
    GetParticipantsByStudyIdAndPseudonymQueryVariables
  >(GetParticipantsByStudyIdAndPseudonymDocument, options);
}
export function useGetParticipantsByStudyIdAndPseudonymSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetParticipantsByStudyIdAndPseudonymQuery,
    GetParticipantsByStudyIdAndPseudonymQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetParticipantsByStudyIdAndPseudonymQuery,
    GetParticipantsByStudyIdAndPseudonymQueryVariables
  >(GetParticipantsByStudyIdAndPseudonymDocument, options);
}
export type GetParticipantsByStudyIdAndPseudonymQueryHookResult = ReturnType<
  typeof useGetParticipantsByStudyIdAndPseudonymQuery
>;
export type GetParticipantsByStudyIdAndPseudonymLazyQueryHookResult = ReturnType<
  typeof useGetParticipantsByStudyIdAndPseudonymLazyQuery
>;
export type GetParticipantsByStudyIdAndPseudonymSuspenseQueryHookResult = ReturnType<
  typeof useGetParticipantsByStudyIdAndPseudonymSuspenseQuery
>;
export type GetParticipantsByStudyIdAndPseudonymQueryResult = Apollo.QueryResult<
  GetParticipantsByStudyIdAndPseudonymQuery,
  GetParticipantsByStudyIdAndPseudonymQueryVariables
>;
export const GetParticipantDocument = gql`
  query GetParticipant($id: uuid!) {
    participant(id: $id) {
      participantGroup {
        name
        id
        study {
          id
          name
          supervisorRoles {
            id
            name
          }
          organization {
            name
          }
        }
      }
      supervisors {
        id
        participantId
        userId
        user {
          id
          firstName
          lastName
        }
      }
      pseudonym
      id
    }
  }
`;

/**
 * __useGetParticipantQuery__
 *
 * To run a query within a React component, call `useGetParticipantQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParticipantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParticipantQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetParticipantQuery(
  baseOptions: Apollo.QueryHookOptions<GetParticipantQuery, GetParticipantQueryVariables> &
    ({ variables: GetParticipantQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetParticipantQuery, GetParticipantQueryVariables>(
    GetParticipantDocument,
    options,
  );
}
export function useGetParticipantLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetParticipantQuery, GetParticipantQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetParticipantQuery, GetParticipantQueryVariables>(
    GetParticipantDocument,
    options,
  );
}
export function useGetParticipantSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetParticipantQuery, GetParticipantQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetParticipantQuery, GetParticipantQueryVariables>(
    GetParticipantDocument,
    options,
  );
}
export type GetParticipantQueryHookResult = ReturnType<typeof useGetParticipantQuery>;
export type GetParticipantLazyQueryHookResult = ReturnType<typeof useGetParticipantLazyQuery>;
export type GetParticipantSuspenseQueryHookResult = ReturnType<
  typeof useGetParticipantSuspenseQuery
>;
export type GetParticipantQueryResult = Apollo.QueryResult<
  GetParticipantQuery,
  GetParticipantQueryVariables
>;
export const UpdateParticipantDocument = gql`
  mutation UpdateParticipant($groupId: uuid!, $id: uuid!, $pseudonym: String!) {
    updateParticipantAction(participant: { groupId: $groupId, id: $id, pseudonym: $pseudonym }) {
      id
      participant {
        id
        pseudonym
        groupId
      }
    }
  }
`;
export type UpdateParticipantMutationFn = Apollo.MutationFunction<
  UpdateParticipantMutation,
  UpdateParticipantMutationVariables
>;

/**
 * __useUpdateParticipantMutation__
 *
 * To run a mutation, you first call `useUpdateParticipantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateParticipantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateParticipantMutation, { data, loading, error }] = useUpdateParticipantMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      id: // value for 'id'
 *      pseudonym: // value for 'pseudonym'
 *   },
 * });
 */
export function useUpdateParticipantMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateParticipantMutation,
    UpdateParticipantMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateParticipantMutation, UpdateParticipantMutationVariables>(
    UpdateParticipantDocument,
    options,
  );
}
export type UpdateParticipantMutationHookResult = ReturnType<typeof useUpdateParticipantMutation>;
export type UpdateParticipantMutationResult = Apollo.MutationResult<UpdateParticipantMutation>;
export type UpdateParticipantMutationOptions = Apollo.BaseMutationOptions<
  UpdateParticipantMutation,
  UpdateParticipantMutationVariables
>;
export const GetParticipantsOfStudyDocument = gql`
  query GetParticipantsOfStudy($studyId: uuid!) {
    participants(
      where: { participantGroup: { study: { id: { _eq: $studyId } } } }
      order_by: { createdAt: desc }
    ) {
      id
      pseudonym
      participantGroup {
        id
        name
      }
      supervisors {
        id
        userId
      }
    }
  }
`;

/**
 * __useGetParticipantsOfStudyQuery__
 *
 * To run a query within a React component, call `useGetParticipantsOfStudyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParticipantsOfStudyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParticipantsOfStudyQuery({
 *   variables: {
 *      studyId: // value for 'studyId'
 *   },
 * });
 */
export function useGetParticipantsOfStudyQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetParticipantsOfStudyQuery,
    GetParticipantsOfStudyQueryVariables
  > &
    ({ variables: GetParticipantsOfStudyQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetParticipantsOfStudyQuery, GetParticipantsOfStudyQueryVariables>(
    GetParticipantsOfStudyDocument,
    options,
  );
}
export function useGetParticipantsOfStudyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetParticipantsOfStudyQuery,
    GetParticipantsOfStudyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetParticipantsOfStudyQuery, GetParticipantsOfStudyQueryVariables>(
    GetParticipantsOfStudyDocument,
    options,
  );
}
export function useGetParticipantsOfStudySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetParticipantsOfStudyQuery,
    GetParticipantsOfStudyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetParticipantsOfStudyQuery, GetParticipantsOfStudyQueryVariables>(
    GetParticipantsOfStudyDocument,
    options,
  );
}
export type GetParticipantsOfStudyQueryHookResult = ReturnType<
  typeof useGetParticipantsOfStudyQuery
>;
export type GetParticipantsOfStudyLazyQueryHookResult = ReturnType<
  typeof useGetParticipantsOfStudyLazyQuery
>;
export type GetParticipantsOfStudySuspenseQueryHookResult = ReturnType<
  typeof useGetParticipantsOfStudySuspenseQuery
>;
export type GetParticipantsOfStudyQueryResult = Apollo.QueryResult<
  GetParticipantsOfStudyQuery,
  GetParticipantsOfStudyQueryVariables
>;
export const DeleteParticipantDocument = gql`
  mutation DeleteParticipant($participantId: uuid!) {
    deleteParticipantAction(participantId: $participantId) {
      pseudonym
      participantId
    }
  }
`;
export type DeleteParticipantMutationFn = Apollo.MutationFunction<
  DeleteParticipantMutation,
  DeleteParticipantMutationVariables
>;

/**
 * __useDeleteParticipantMutation__
 *
 * To run a mutation, you first call `useDeleteParticipantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteParticipantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteParticipantMutation, { data, loading, error }] = useDeleteParticipantMutation({
 *   variables: {
 *      participantId: // value for 'participantId'
 *   },
 * });
 */
export function useDeleteParticipantMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteParticipantMutation,
    DeleteParticipantMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteParticipantMutation, DeleteParticipantMutationVariables>(
    DeleteParticipantDocument,
    options,
  );
}
export type DeleteParticipantMutationHookResult = ReturnType<typeof useDeleteParticipantMutation>;
export type DeleteParticipantMutationResult = Apollo.MutationResult<DeleteParticipantMutation>;
export type DeleteParticipantMutationOptions = Apollo.BaseMutationOptions<
  DeleteParticipantMutation,
  DeleteParticipantMutationVariables
>;
export const SendTestNotificationDocument = gql`
  mutation SendTestNotification($participantId: uuid!) {
    sendTestNotificationAction(participantId: $participantId) {
      success
      sentMessage
    }
  }
`;
export type SendTestNotificationMutationFn = Apollo.MutationFunction<
  SendTestNotificationMutation,
  SendTestNotificationMutationVariables
>;

/**
 * __useSendTestNotificationMutation__
 *
 * To run a mutation, you first call `useSendTestNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendTestNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendTestNotificationMutation, { data, loading, error }] = useSendTestNotificationMutation({
 *   variables: {
 *      participantId: // value for 'participantId'
 *   },
 * });
 */
export function useSendTestNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendTestNotificationMutation,
    SendTestNotificationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SendTestNotificationMutation, SendTestNotificationMutationVariables>(
    SendTestNotificationDocument,
    options,
  );
}
export type SendTestNotificationMutationHookResult = ReturnType<
  typeof useSendTestNotificationMutation
>;
export type SendTestNotificationMutationResult =
  Apollo.MutationResult<SendTestNotificationMutation>;
export type SendTestNotificationMutationOptions = Apollo.BaseMutationOptions<
  SendTestNotificationMutation,
  SendTestNotificationMutationVariables
>;
export const GetParticipantActionDocument = gql`
  query GetParticipantAction($id: uuid!) {
    getParticipantAction(id: $id) {
      id
      coupleUrl
      therapyEnd
      therapyPeriodId
      therapyStart
      participant {
        participantGroup {
          name
          id
          study {
            id
            name
            supervisorRoles {
              id
              name
            }
            organization {
              name
            }
          }
        }
        supervisors {
          id
          participantId
          userId
          user {
            id
            firstName
            lastName
          }
        }
        pseudonym
        coupledAt
        id
      }
    }
  }
`;

/**
 * __useGetParticipantActionQuery__
 *
 * To run a query within a React component, call `useGetParticipantActionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParticipantActionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParticipantActionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetParticipantActionQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetParticipantActionQuery,
    GetParticipantActionQueryVariables
  > &
    ({ variables: GetParticipantActionQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetParticipantActionQuery, GetParticipantActionQueryVariables>(
    GetParticipantActionDocument,
    options,
  );
}
export function useGetParticipantActionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetParticipantActionQuery,
    GetParticipantActionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetParticipantActionQuery, GetParticipantActionQueryVariables>(
    GetParticipantActionDocument,
    options,
  );
}
export function useGetParticipantActionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetParticipantActionQuery,
    GetParticipantActionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetParticipantActionQuery, GetParticipantActionQueryVariables>(
    GetParticipantActionDocument,
    options,
  );
}
export type GetParticipantActionQueryHookResult = ReturnType<typeof useGetParticipantActionQuery>;
export type GetParticipantActionLazyQueryHookResult = ReturnType<
  typeof useGetParticipantActionLazyQuery
>;
export type GetParticipantActionSuspenseQueryHookResult = ReturnType<
  typeof useGetParticipantActionSuspenseQuery
>;
export type GetParticipantActionQueryResult = Apollo.QueryResult<
  GetParticipantActionQuery,
  GetParticipantActionQueryVariables
>;
export const GetExecutorParticipantsDocument = gql`
  query GetExecutorParticipants($therapyId: uuid!) {
    getParticipantsAction(therapyId: $therapyId) {
      coupleUrl
      id
      participant {
        id
        createdAt
        coupledAt
        pseudonym
        participantGroup {
          id
          name
        }
        supervisors {
          id
          userId
        }
      }
      therapyPeriodId
      therapyEnd
      therapyStart
    }
  }
`;

/**
 * __useGetExecutorParticipantsQuery__
 *
 * To run a query within a React component, call `useGetExecutorParticipantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExecutorParticipantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExecutorParticipantsQuery({
 *   variables: {
 *      therapyId: // value for 'therapyId'
 *   },
 * });
 */
export function useGetExecutorParticipantsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetExecutorParticipantsQuery,
    GetExecutorParticipantsQueryVariables
  > &
    ({ variables: GetExecutorParticipantsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetExecutorParticipantsQuery, GetExecutorParticipantsQueryVariables>(
    GetExecutorParticipantsDocument,
    options,
  );
}
export function useGetExecutorParticipantsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetExecutorParticipantsQuery,
    GetExecutorParticipantsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetExecutorParticipantsQuery, GetExecutorParticipantsQueryVariables>(
    GetExecutorParticipantsDocument,
    options,
  );
}
export function useGetExecutorParticipantsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetExecutorParticipantsQuery,
    GetExecutorParticipantsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetExecutorParticipantsQuery,
    GetExecutorParticipantsQueryVariables
  >(GetExecutorParticipantsDocument, options);
}
export type GetExecutorParticipantsQueryHookResult = ReturnType<
  typeof useGetExecutorParticipantsQuery
>;
export type GetExecutorParticipantsLazyQueryHookResult = ReturnType<
  typeof useGetExecutorParticipantsLazyQuery
>;
export type GetExecutorParticipantsSuspenseQueryHookResult = ReturnType<
  typeof useGetExecutorParticipantsSuspenseQuery
>;
export type GetExecutorParticipantsQueryResult = Apollo.QueryResult<
  GetExecutorParticipantsQuery,
  GetExecutorParticipantsQueryVariables
>;
export const ResetCoupleKeyActionDocument = gql`
  mutation ResetCoupleKeyAction($participantId: String!) {
    resetCoupleKeyAction(participantId: $participantId) {
      id
      coupleUrl
    }
  }
`;
export type ResetCoupleKeyActionMutationFn = Apollo.MutationFunction<
  ResetCoupleKeyActionMutation,
  ResetCoupleKeyActionMutationVariables
>;

/**
 * __useResetCoupleKeyActionMutation__
 *
 * To run a mutation, you first call `useResetCoupleKeyActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetCoupleKeyActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetCoupleKeyActionMutation, { data, loading, error }] = useResetCoupleKeyActionMutation({
 *   variables: {
 *      participantId: // value for 'participantId'
 *   },
 * });
 */
export function useResetCoupleKeyActionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetCoupleKeyActionMutation,
    ResetCoupleKeyActionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResetCoupleKeyActionMutation, ResetCoupleKeyActionMutationVariables>(
    ResetCoupleKeyActionDocument,
    options,
  );
}
export type ResetCoupleKeyActionMutationHookResult = ReturnType<
  typeof useResetCoupleKeyActionMutation
>;
export type ResetCoupleKeyActionMutationResult =
  Apollo.MutationResult<ResetCoupleKeyActionMutation>;
export type ResetCoupleKeyActionMutationOptions = Apollo.BaseMutationOptions<
  ResetCoupleKeyActionMutation,
  ResetCoupleKeyActionMutationVariables
>;
export const CreateParticipantGroupActionDocument = gql`
  mutation CreateParticipantGroupAction($description: String, $name: String!, $studyId: uuid!) {
    createParticipantsGroupAction(name: $name, studyId: $studyId, description: $description) {
      id
      participantsGroup {
        name
        id
        study {
          name
          id
        }
      }
    }
  }
`;
export type CreateParticipantGroupActionMutationFn = Apollo.MutationFunction<
  CreateParticipantGroupActionMutation,
  CreateParticipantGroupActionMutationVariables
>;

/**
 * __useCreateParticipantGroupActionMutation__
 *
 * To run a mutation, you first call `useCreateParticipantGroupActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateParticipantGroupActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createParticipantGroupActionMutation, { data, loading, error }] = useCreateParticipantGroupActionMutation({
 *   variables: {
 *      description: // value for 'description'
 *      name: // value for 'name'
 *      studyId: // value for 'studyId'
 *   },
 * });
 */
export function useCreateParticipantGroupActionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateParticipantGroupActionMutation,
    CreateParticipantGroupActionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateParticipantGroupActionMutation,
    CreateParticipantGroupActionMutationVariables
  >(CreateParticipantGroupActionDocument, options);
}
export type CreateParticipantGroupActionMutationHookResult = ReturnType<
  typeof useCreateParticipantGroupActionMutation
>;
export type CreateParticipantGroupActionMutationResult =
  Apollo.MutationResult<CreateParticipantGroupActionMutation>;
export type CreateParticipantGroupActionMutationOptions = Apollo.BaseMutationOptions<
  CreateParticipantGroupActionMutation,
  CreateParticipantGroupActionMutationVariables
>;
export const UpdateParticipantsGroupDocument = gql`
  mutation UpdateParticipantsGroup($id: uuid!, $name: String!, $description: String) {
    updateParticipantsGroup(
      pk_columns: { id: $id }
      _set: { name: $name, description: $description }
    ) {
      id
      name
      description
    }
  }
`;
export type UpdateParticipantsGroupMutationFn = Apollo.MutationFunction<
  UpdateParticipantsGroupMutation,
  UpdateParticipantsGroupMutationVariables
>;

/**
 * __useUpdateParticipantsGroupMutation__
 *
 * To run a mutation, you first call `useUpdateParticipantsGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateParticipantsGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateParticipantsGroupMutation, { data, loading, error }] = useUpdateParticipantsGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useUpdateParticipantsGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateParticipantsGroupMutation,
    UpdateParticipantsGroupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateParticipantsGroupMutation,
    UpdateParticipantsGroupMutationVariables
  >(UpdateParticipantsGroupDocument, options);
}
export type UpdateParticipantsGroupMutationHookResult = ReturnType<
  typeof useUpdateParticipantsGroupMutation
>;
export type UpdateParticipantsGroupMutationResult =
  Apollo.MutationResult<UpdateParticipantsGroupMutation>;
export type UpdateParticipantsGroupMutationOptions = Apollo.BaseMutationOptions<
  UpdateParticipantsGroupMutation,
  UpdateParticipantsGroupMutationVariables
>;
export const GetParticipantsGroupsDocument = gql`
  query GetParticipantsGroups($studyId: uuid!) {
    participantsGroups(where: { studyId: { _eq: $studyId } }) {
      name
      id
      description
      studyId
      participants {
        pseudonym
        id
        groupId
        participantGroup {
          name
        }
      }
    }
  }
`;

/**
 * __useGetParticipantsGroupsQuery__
 *
 * To run a query within a React component, call `useGetParticipantsGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParticipantsGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParticipantsGroupsQuery({
 *   variables: {
 *      studyId: // value for 'studyId'
 *   },
 * });
 */
export function useGetParticipantsGroupsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetParticipantsGroupsQuery,
    GetParticipantsGroupsQueryVariables
  > &
    ({ variables: GetParticipantsGroupsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetParticipantsGroupsQuery, GetParticipantsGroupsQueryVariables>(
    GetParticipantsGroupsDocument,
    options,
  );
}
export function useGetParticipantsGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetParticipantsGroupsQuery,
    GetParticipantsGroupsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetParticipantsGroupsQuery, GetParticipantsGroupsQueryVariables>(
    GetParticipantsGroupsDocument,
    options,
  );
}
export function useGetParticipantsGroupsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetParticipantsGroupsQuery,
    GetParticipantsGroupsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetParticipantsGroupsQuery, GetParticipantsGroupsQueryVariables>(
    GetParticipantsGroupsDocument,
    options,
  );
}
export type GetParticipantsGroupsQueryHookResult = ReturnType<typeof useGetParticipantsGroupsQuery>;
export type GetParticipantsGroupsLazyQueryHookResult = ReturnType<
  typeof useGetParticipantsGroupsLazyQuery
>;
export type GetParticipantsGroupsSuspenseQueryHookResult = ReturnType<
  typeof useGetParticipantsGroupsSuspenseQuery
>;
export type GetParticipantsGroupsQueryResult = Apollo.QueryResult<
  GetParticipantsGroupsQuery,
  GetParticipantsGroupsQueryVariables
>;
export const DeleteParticipantGroupDocument = gql`
  mutation DeleteParticipantGroup($groupId: uuid!) {
    deleteParticipantGroup(groupId: $groupId) {
      success
    }
  }
`;
export type DeleteParticipantGroupMutationFn = Apollo.MutationFunction<
  DeleteParticipantGroupMutation,
  DeleteParticipantGroupMutationVariables
>;

/**
 * __useDeleteParticipantGroupMutation__
 *
 * To run a mutation, you first call `useDeleteParticipantGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteParticipantGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteParticipantGroupMutation, { data, loading, error }] = useDeleteParticipantGroupMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useDeleteParticipantGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteParticipantGroupMutation,
    DeleteParticipantGroupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteParticipantGroupMutation,
    DeleteParticipantGroupMutationVariables
  >(DeleteParticipantGroupDocument, options);
}
export type DeleteParticipantGroupMutationHookResult = ReturnType<
  typeof useDeleteParticipantGroupMutation
>;
export type DeleteParticipantGroupMutationResult =
  Apollo.MutationResult<DeleteParticipantGroupMutation>;
export type DeleteParticipantGroupMutationOptions = Apollo.BaseMutationOptions<
  DeleteParticipantGroupMutation,
  DeleteParticipantGroupMutationVariables
>;
export const GetStudyDocument = gql`
  query GetStudy($id: uuid!) {
    study(id: $id) {
      description
      id
      name
      logo
      therapyId
      organizationId
      allowedEmailDomains
      studyRoles {
        id
        name
      }
      supervisorRoles {
        id
        name
      }
      participantGroups {
        name
        id
        description
        studyId
        participants {
          pseudonym
          id
          groupId
          participantGroup {
            name
            study {
              id
            }
          }
          supervisors {
            id
            participantId
            userId
          }
        }
      }
      studyMembers {
        id
        studyRole {
          id
          name
        }
        user {
          firstName
          lastName
          avatar
          id
        }
      }
      executor {
        id
        name
        executorUrl
      }
    }
  }
`;

/**
 * __useGetStudyQuery__
 *
 * To run a query within a React component, call `useGetStudyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetStudyQuery(
  baseOptions: Apollo.QueryHookOptions<GetStudyQuery, GetStudyQueryVariables> &
    ({ variables: GetStudyQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetStudyQuery, GetStudyQueryVariables>(GetStudyDocument, options);
}
export function useGetStudyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetStudyQuery, GetStudyQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetStudyQuery, GetStudyQueryVariables>(GetStudyDocument, options);
}
export function useGetStudySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetStudyQuery, GetStudyQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetStudyQuery, GetStudyQueryVariables>(GetStudyDocument, options);
}
export type GetStudyQueryHookResult = ReturnType<typeof useGetStudyQuery>;
export type GetStudyLazyQueryHookResult = ReturnType<typeof useGetStudyLazyQuery>;
export type GetStudySuspenseQueryHookResult = ReturnType<typeof useGetStudySuspenseQuery>;
export type GetStudyQueryResult = Apollo.QueryResult<GetStudyQuery, GetStudyQueryVariables>;
export const GetTherapyIdDocument = gql`
  query GetTherapyId($id: uuid!) {
    study(id: $id) {
      id
      therapyId
    }
  }
`;

/**
 * __useGetTherapyIdQuery__
 *
 * To run a query within a React component, call `useGetTherapyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTherapyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTherapyIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTherapyIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetTherapyIdQuery, GetTherapyIdQueryVariables> &
    ({ variables: GetTherapyIdQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTherapyIdQuery, GetTherapyIdQueryVariables>(
    GetTherapyIdDocument,
    options,
  );
}
export function useGetTherapyIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTherapyIdQuery, GetTherapyIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTherapyIdQuery, GetTherapyIdQueryVariables>(
    GetTherapyIdDocument,
    options,
  );
}
export function useGetTherapyIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetTherapyIdQuery, GetTherapyIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetTherapyIdQuery, GetTherapyIdQueryVariables>(
    GetTherapyIdDocument,
    options,
  );
}
export type GetTherapyIdQueryHookResult = ReturnType<typeof useGetTherapyIdQuery>;
export type GetTherapyIdLazyQueryHookResult = ReturnType<typeof useGetTherapyIdLazyQuery>;
export type GetTherapyIdSuspenseQueryHookResult = ReturnType<typeof useGetTherapyIdSuspenseQuery>;
export type GetTherapyIdQueryResult = Apollo.QueryResult<
  GetTherapyIdQuery,
  GetTherapyIdQueryVariables
>;
export const UpdateStudyDocument = gql`
  mutation UpdateStudy($id: uuid!, $name: String!, $description: String) {
    updateStudy(pk_columns: { id: $id }, _set: { name: $name, description: $description }) {
      name
      description
      id
    }
  }
`;
export type UpdateStudyMutationFn = Apollo.MutationFunction<
  UpdateStudyMutation,
  UpdateStudyMutationVariables
>;

/**
 * __useUpdateStudyMutation__
 *
 * To run a mutation, you first call `useUpdateStudyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStudyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStudyMutation, { data, loading, error }] = useUpdateStudyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useUpdateStudyMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateStudyMutation, UpdateStudyMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateStudyMutation, UpdateStudyMutationVariables>(
    UpdateStudyDocument,
    options,
  );
}
export type UpdateStudyMutationHookResult = ReturnType<typeof useUpdateStudyMutation>;
export type UpdateStudyMutationResult = Apollo.MutationResult<UpdateStudyMutation>;
export type UpdateStudyMutationOptions = Apollo.BaseMutationOptions<
  UpdateStudyMutation,
  UpdateStudyMutationVariables
>;
export const CreateStudyDocument = gql`
  mutation CreateStudy(
    $name: String!
    $description: String
    $executorId: uuid!
    $organizationId: uuid!
    $userId: uuid!
  ) {
    createStudyAction(
      study: {
        name: $name
        organizationId: $organizationId
        executorId: $executorId
        description: $description
        userId: $userId
      }
    ) {
      id
      name
    }
  }
`;
export type CreateStudyMutationFn = Apollo.MutationFunction<
  CreateStudyMutation,
  CreateStudyMutationVariables
>;

/**
 * __useCreateStudyMutation__
 *
 * To run a mutation, you first call `useCreateStudyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStudyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStudyMutation, { data, loading, error }] = useCreateStudyMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      executorId: // value for 'executorId'
 *      organizationId: // value for 'organizationId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCreateStudyMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateStudyMutation, CreateStudyMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateStudyMutation, CreateStudyMutationVariables>(
    CreateStudyDocument,
    options,
  );
}
export type CreateStudyMutationHookResult = ReturnType<typeof useCreateStudyMutation>;
export type CreateStudyMutationResult = Apollo.MutationResult<CreateStudyMutation>;
export type CreateStudyMutationOptions = Apollo.BaseMutationOptions<
  CreateStudyMutation,
  CreateStudyMutationVariables
>;
export const UpdateStudyLogoDocument = gql`
  mutation UpdateStudyLogo($logo: String, $id: uuid!) {
    updateStudy(pk_columns: { id: $id }, _set: { logo: $logo }) {
      id
      logo
    }
  }
`;
export type UpdateStudyLogoMutationFn = Apollo.MutationFunction<
  UpdateStudyLogoMutation,
  UpdateStudyLogoMutationVariables
>;

/**
 * __useUpdateStudyLogoMutation__
 *
 * To run a mutation, you first call `useUpdateStudyLogoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStudyLogoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStudyLogoMutation, { data, loading, error }] = useUpdateStudyLogoMutation({
 *   variables: {
 *      logo: // value for 'logo'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateStudyLogoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateStudyLogoMutation,
    UpdateStudyLogoMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateStudyLogoMutation, UpdateStudyLogoMutationVariables>(
    UpdateStudyLogoDocument,
    options,
  );
}
export type UpdateStudyLogoMutationHookResult = ReturnType<typeof useUpdateStudyLogoMutation>;
export type UpdateStudyLogoMutationResult = Apollo.MutationResult<UpdateStudyLogoMutation>;
export type UpdateStudyLogoMutationOptions = Apollo.BaseMutationOptions<
  UpdateStudyLogoMutation,
  UpdateStudyLogoMutationVariables
>;
export const UpdateAllowedEmailDomainsDocument = gql`
  mutation UpdateAllowedEmailDomains($studyId: uuid!, $allowedEmailDomains: String) {
    updateStudy(pk_columns: { id: $studyId }, _set: { allowedEmailDomains: $allowedEmailDomains }) {
      id
      allowedEmailDomains
    }
  }
`;
export type UpdateAllowedEmailDomainsMutationFn = Apollo.MutationFunction<
  UpdateAllowedEmailDomainsMutation,
  UpdateAllowedEmailDomainsMutationVariables
>;

/**
 * __useUpdateAllowedEmailDomainsMutation__
 *
 * To run a mutation, you first call `useUpdateAllowedEmailDomainsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAllowedEmailDomainsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAllowedEmailDomainsMutation, { data, loading, error }] = useUpdateAllowedEmailDomainsMutation({
 *   variables: {
 *      studyId: // value for 'studyId'
 *      allowedEmailDomains: // value for 'allowedEmailDomains'
 *   },
 * });
 */
export function useUpdateAllowedEmailDomainsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAllowedEmailDomainsMutation,
    UpdateAllowedEmailDomainsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAllowedEmailDomainsMutation,
    UpdateAllowedEmailDomainsMutationVariables
  >(UpdateAllowedEmailDomainsDocument, options);
}
export type UpdateAllowedEmailDomainsMutationHookResult = ReturnType<
  typeof useUpdateAllowedEmailDomainsMutation
>;
export type UpdateAllowedEmailDomainsMutationResult =
  Apollo.MutationResult<UpdateAllowedEmailDomainsMutation>;
export type UpdateAllowedEmailDomainsMutationOptions = Apollo.BaseMutationOptions<
  UpdateAllowedEmailDomainsMutation,
  UpdateAllowedEmailDomainsMutationVariables
>;
export const AggregateStudiesOfUserInOrganizationDocument = gql`
  query AggregateStudiesOfUserInOrganization($organizationId: uuid!, $userId: uuid!) {
    aggregateStudyMembers(
      where: { study: { organizationId: { _eq: $organizationId } }, userId: { _eq: $userId } }
    ) {
      aggregate {
        count
      }
    }
  }
`;

/**
 * __useAggregateStudiesOfUserInOrganizationQuery__
 *
 * To run a query within a React component, call `useAggregateStudiesOfUserInOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateStudiesOfUserInOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateStudiesOfUserInOrganizationQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAggregateStudiesOfUserInOrganizationQuery(
  baseOptions: Apollo.QueryHookOptions<
    AggregateStudiesOfUserInOrganizationQuery,
    AggregateStudiesOfUserInOrganizationQueryVariables
  > &
    (
      | { variables: AggregateStudiesOfUserInOrganizationQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AggregateStudiesOfUserInOrganizationQuery,
    AggregateStudiesOfUserInOrganizationQueryVariables
  >(AggregateStudiesOfUserInOrganizationDocument, options);
}
export function useAggregateStudiesOfUserInOrganizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AggregateStudiesOfUserInOrganizationQuery,
    AggregateStudiesOfUserInOrganizationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AggregateStudiesOfUserInOrganizationQuery,
    AggregateStudiesOfUserInOrganizationQueryVariables
  >(AggregateStudiesOfUserInOrganizationDocument, options);
}
export function useAggregateStudiesOfUserInOrganizationSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    AggregateStudiesOfUserInOrganizationQuery,
    AggregateStudiesOfUserInOrganizationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AggregateStudiesOfUserInOrganizationQuery,
    AggregateStudiesOfUserInOrganizationQueryVariables
  >(AggregateStudiesOfUserInOrganizationDocument, options);
}
export type AggregateStudiesOfUserInOrganizationQueryHookResult = ReturnType<
  typeof useAggregateStudiesOfUserInOrganizationQuery
>;
export type AggregateStudiesOfUserInOrganizationLazyQueryHookResult = ReturnType<
  typeof useAggregateStudiesOfUserInOrganizationLazyQuery
>;
export type AggregateStudiesOfUserInOrganizationSuspenseQueryHookResult = ReturnType<
  typeof useAggregateStudiesOfUserInOrganizationSuspenseQuery
>;
export type AggregateStudiesOfUserInOrganizationQueryResult = Apollo.QueryResult<
  AggregateStudiesOfUserInOrganizationQuery,
  AggregateStudiesOfUserInOrganizationQueryVariables
>;
export const LeaveStudyDocument = gql`
  mutation LeaveStudy($studyId: uuid!, $userId: uuid!) {
    leaveStudyAction(studyId: $studyId, userId: $userId) {
      success
    }
  }
`;
export type LeaveStudyMutationFn = Apollo.MutationFunction<
  LeaveStudyMutation,
  LeaveStudyMutationVariables
>;

/**
 * __useLeaveStudyMutation__
 *
 * To run a mutation, you first call `useLeaveStudyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveStudyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveStudyMutation, { data, loading, error }] = useLeaveStudyMutation({
 *   variables: {
 *      studyId: // value for 'studyId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useLeaveStudyMutation(
  baseOptions?: Apollo.MutationHookOptions<LeaveStudyMutation, LeaveStudyMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LeaveStudyMutation, LeaveStudyMutationVariables>(
    LeaveStudyDocument,
    options,
  );
}
export type LeaveStudyMutationHookResult = ReturnType<typeof useLeaveStudyMutation>;
export type LeaveStudyMutationResult = Apollo.MutationResult<LeaveStudyMutation>;
export type LeaveStudyMutationOptions = Apollo.BaseMutationOptions<
  LeaveStudyMutation,
  LeaveStudyMutationVariables
>;
export const CanLeaveStudyDocument = gql`
  query CanLeaveStudy($studyId: uuid!, $userId: uuid!) {
    canLeaveStudyAction(studyId: $studyId, userId: $userId) {
      canLeave
    }
  }
`;

/**
 * __useCanLeaveStudyQuery__
 *
 * To run a query within a React component, call `useCanLeaveStudyQuery` and pass it any options that fit your needs.
 * When your component renders, `useCanLeaveStudyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCanLeaveStudyQuery({
 *   variables: {
 *      studyId: // value for 'studyId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCanLeaveStudyQuery(
  baseOptions: Apollo.QueryHookOptions<CanLeaveStudyQuery, CanLeaveStudyQueryVariables> &
    ({ variables: CanLeaveStudyQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CanLeaveStudyQuery, CanLeaveStudyQueryVariables>(
    CanLeaveStudyDocument,
    options,
  );
}
export function useCanLeaveStudyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CanLeaveStudyQuery, CanLeaveStudyQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CanLeaveStudyQuery, CanLeaveStudyQueryVariables>(
    CanLeaveStudyDocument,
    options,
  );
}
export function useCanLeaveStudySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<CanLeaveStudyQuery, CanLeaveStudyQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CanLeaveStudyQuery, CanLeaveStudyQueryVariables>(
    CanLeaveStudyDocument,
    options,
  );
}
export type CanLeaveStudyQueryHookResult = ReturnType<typeof useCanLeaveStudyQuery>;
export type CanLeaveStudyLazyQueryHookResult = ReturnType<typeof useCanLeaveStudyLazyQuery>;
export type CanLeaveStudySuspenseQueryHookResult = ReturnType<typeof useCanLeaveStudySuspenseQuery>;
export type CanLeaveStudyQueryResult = Apollo.QueryResult<
  CanLeaveStudyQuery,
  CanLeaveStudyQueryVariables
>;
export const DeleteStudyDocument = gql`
  mutation DeleteStudy($studyId: uuid!) {
    deleteStudyAction(studyId: $studyId) {
      studyName
      success
    }
  }
`;
export type DeleteStudyMutationFn = Apollo.MutationFunction<
  DeleteStudyMutation,
  DeleteStudyMutationVariables
>;

/**
 * __useDeleteStudyMutation__
 *
 * To run a mutation, you first call `useDeleteStudyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStudyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStudyMutation, { data, loading, error }] = useDeleteStudyMutation({
 *   variables: {
 *      studyId: // value for 'studyId'
 *   },
 * });
 */
export function useDeleteStudyMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteStudyMutation, DeleteStudyMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteStudyMutation, DeleteStudyMutationVariables>(
    DeleteStudyDocument,
    options,
  );
}
export type DeleteStudyMutationHookResult = ReturnType<typeof useDeleteStudyMutation>;
export type DeleteStudyMutationResult = Apollo.MutationResult<DeleteStudyMutation>;
export type DeleteStudyMutationOptions = Apollo.BaseMutationOptions<
  DeleteStudyMutation,
  DeleteStudyMutationVariables
>;
export const CanDeleteStudyDocument = gql`
  query CanDeleteStudy($studyId: uuid!) {
    canDeleteStudyAction(studyId: $studyId) {
      canDelete
      runningParticipants
    }
  }
`;

/**
 * __useCanDeleteStudyQuery__
 *
 * To run a query within a React component, call `useCanDeleteStudyQuery` and pass it any options that fit your needs.
 * When your component renders, `useCanDeleteStudyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCanDeleteStudyQuery({
 *   variables: {
 *      studyId: // value for 'studyId'
 *   },
 * });
 */
export function useCanDeleteStudyQuery(
  baseOptions: Apollo.QueryHookOptions<CanDeleteStudyQuery, CanDeleteStudyQueryVariables> &
    ({ variables: CanDeleteStudyQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CanDeleteStudyQuery, CanDeleteStudyQueryVariables>(
    CanDeleteStudyDocument,
    options,
  );
}
export function useCanDeleteStudyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CanDeleteStudyQuery, CanDeleteStudyQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CanDeleteStudyQuery, CanDeleteStudyQueryVariables>(
    CanDeleteStudyDocument,
    options,
  );
}
export function useCanDeleteStudySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<CanDeleteStudyQuery, CanDeleteStudyQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CanDeleteStudyQuery, CanDeleteStudyQueryVariables>(
    CanDeleteStudyDocument,
    options,
  );
}
export type CanDeleteStudyQueryHookResult = ReturnType<typeof useCanDeleteStudyQuery>;
export type CanDeleteStudyLazyQueryHookResult = ReturnType<typeof useCanDeleteStudyLazyQuery>;
export type CanDeleteStudySuspenseQueryHookResult = ReturnType<
  typeof useCanDeleteStudySuspenseQuery
>;
export type CanDeleteStudyQueryResult = Apollo.QueryResult<
  CanDeleteStudyQuery,
  CanDeleteStudyQueryVariables
>;
export const GetLabelingInformationDocument = gql`
  query GetLabelingInformation($studyId: uuid!, $language: String) {
    getLabelingInformationAction(studyId: $studyId, language: $language) {
      deviceName
      email
      manufacturer
      isForClinicalInvestigationOnly
      phone
    }
  }
`;

/**
 * __useGetLabelingInformationQuery__
 *
 * To run a query within a React component, call `useGetLabelingInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLabelingInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLabelingInformationQuery({
 *   variables: {
 *      studyId: // value for 'studyId'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useGetLabelingInformationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLabelingInformationQuery,
    GetLabelingInformationQueryVariables
  > &
    ({ variables: GetLabelingInformationQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLabelingInformationQuery, GetLabelingInformationQueryVariables>(
    GetLabelingInformationDocument,
    options,
  );
}
export function useGetLabelingInformationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLabelingInformationQuery,
    GetLabelingInformationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLabelingInformationQuery, GetLabelingInformationQueryVariables>(
    GetLabelingInformationDocument,
    options,
  );
}
export function useGetLabelingInformationSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetLabelingInformationQuery,
    GetLabelingInformationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetLabelingInformationQuery, GetLabelingInformationQueryVariables>(
    GetLabelingInformationDocument,
    options,
  );
}
export type GetLabelingInformationQueryHookResult = ReturnType<
  typeof useGetLabelingInformationQuery
>;
export type GetLabelingInformationLazyQueryHookResult = ReturnType<
  typeof useGetLabelingInformationLazyQuery
>;
export type GetLabelingInformationSuspenseQueryHookResult = ReturnType<
  typeof useGetLabelingInformationSuspenseQuery
>;
export type GetLabelingInformationQueryResult = Apollo.QueryResult<
  GetLabelingInformationQuery,
  GetLabelingInformationQueryVariables
>;
export const GetExecutorUsageStatisticsDocument = gql`
  query GetExecutorUsageStatistics($studyId: uuid!) {
    getExecutorStatisticsAction(studyId: $studyId) {
      id
      expiresAt
      isExpired
      participantCount
      participantLimit
      participantLimitReached
    }
  }
`;

/**
 * __useGetExecutorUsageStatisticsQuery__
 *
 * To run a query within a React component, call `useGetExecutorUsageStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExecutorUsageStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExecutorUsageStatisticsQuery({
 *   variables: {
 *      studyId: // value for 'studyId'
 *   },
 * });
 */
export function useGetExecutorUsageStatisticsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetExecutorUsageStatisticsQuery,
    GetExecutorUsageStatisticsQueryVariables
  > &
    ({ variables: GetExecutorUsageStatisticsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetExecutorUsageStatisticsQuery, GetExecutorUsageStatisticsQueryVariables>(
    GetExecutorUsageStatisticsDocument,
    options,
  );
}
export function useGetExecutorUsageStatisticsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetExecutorUsageStatisticsQuery,
    GetExecutorUsageStatisticsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetExecutorUsageStatisticsQuery,
    GetExecutorUsageStatisticsQueryVariables
  >(GetExecutorUsageStatisticsDocument, options);
}
export function useGetExecutorUsageStatisticsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetExecutorUsageStatisticsQuery,
    GetExecutorUsageStatisticsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetExecutorUsageStatisticsQuery,
    GetExecutorUsageStatisticsQueryVariables
  >(GetExecutorUsageStatisticsDocument, options);
}
export type GetExecutorUsageStatisticsQueryHookResult = ReturnType<
  typeof useGetExecutorUsageStatisticsQuery
>;
export type GetExecutorUsageStatisticsLazyQueryHookResult = ReturnType<
  typeof useGetExecutorUsageStatisticsLazyQuery
>;
export type GetExecutorUsageStatisticsSuspenseQueryHookResult = ReturnType<
  typeof useGetExecutorUsageStatisticsSuspenseQuery
>;
export type GetExecutorUsageStatisticsQueryResult = Apollo.QueryResult<
  GetExecutorUsageStatisticsQuery,
  GetExecutorUsageStatisticsQueryVariables
>;
export const GetStudyMemberDocument = gql`
  query GetStudyMember($userId: uuid!, $studyId: uuid!) {
    studyMember(studyId: $studyId, userId: $userId) {
      id
      userId
      studyRole {
        id
        name
        participantCreateSupervisor
        participantDelete
        participantDownloadData
        participantEditSettings
        participantEditTherapyConfig
        participantViewDetails
        studyCreateMember
        studyCreateGroup
        studyCreateParticipant
        studyDelete
        studyDeleteGroup
        studyDeleteMember
        studyDownloadData
        studyEdit
        studyEditGroup
        studyEditGroupTherapyConfig
        studyEditMember
        studyViewDetails
        studyViewGroupAssignment
        studyViewGroups
        studyViewGroupTherapyConfig
        studyViewMembers
        studyViewParticipants
      }
    }
  }
`;

/**
 * __useGetStudyMemberQuery__
 *
 * To run a query within a React component, call `useGetStudyMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudyMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudyMemberQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      studyId: // value for 'studyId'
 *   },
 * });
 */
export function useGetStudyMemberQuery(
  baseOptions: Apollo.QueryHookOptions<GetStudyMemberQuery, GetStudyMemberQueryVariables> &
    ({ variables: GetStudyMemberQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetStudyMemberQuery, GetStudyMemberQueryVariables>(
    GetStudyMemberDocument,
    options,
  );
}
export function useGetStudyMemberLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetStudyMemberQuery, GetStudyMemberQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetStudyMemberQuery, GetStudyMemberQueryVariables>(
    GetStudyMemberDocument,
    options,
  );
}
export function useGetStudyMemberSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetStudyMemberQuery, GetStudyMemberQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetStudyMemberQuery, GetStudyMemberQueryVariables>(
    GetStudyMemberDocument,
    options,
  );
}
export type GetStudyMemberQueryHookResult = ReturnType<typeof useGetStudyMemberQuery>;
export type GetStudyMemberLazyQueryHookResult = ReturnType<typeof useGetStudyMemberLazyQuery>;
export type GetStudyMemberSuspenseQueryHookResult = ReturnType<
  typeof useGetStudyMemberSuspenseQuery
>;
export type GetStudyMemberQueryResult = Apollo.QueryResult<
  GetStudyMemberQuery,
  GetStudyMemberQueryVariables
>;
export const GetStudyMemberByParticipantIdDocument = gql`
  query GetStudyMemberByParticipantId($participantId: uuid!, $userId: uuid!) {
    participant(id: $participantId) {
      participantGroup {
        id
        study {
          studyMembers(where: { userId: { _eq: $userId } }) {
            id
            userId
            studyRole {
              id
              name
              participantCreateSupervisor
              participantDelete
              participantDownloadData
              participantEditSettings
              participantEditTherapyConfig
              participantViewDetails
              studyCreateMember
              studyCreateGroup
              studyCreateParticipant
              studyDelete
              studyDeleteGroup
              studyDeleteMember
              studyDownloadData
              studyEdit
              studyEditGroup
              studyEditGroupTherapyConfig
              studyEditMember
              studyViewDetails
              studyViewGroupAssignment
              studyViewGroups
              studyViewGroupTherapyConfig
              studyViewMembers
              studyViewParticipants
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetStudyMemberByParticipantIdQuery__
 *
 * To run a query within a React component, call `useGetStudyMemberByParticipantIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudyMemberByParticipantIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudyMemberByParticipantIdQuery({
 *   variables: {
 *      participantId: // value for 'participantId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetStudyMemberByParticipantIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetStudyMemberByParticipantIdQuery,
    GetStudyMemberByParticipantIdQueryVariables
  > &
    (
      | { variables: GetStudyMemberByParticipantIdQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetStudyMemberByParticipantIdQuery,
    GetStudyMemberByParticipantIdQueryVariables
  >(GetStudyMemberByParticipantIdDocument, options);
}
export function useGetStudyMemberByParticipantIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStudyMemberByParticipantIdQuery,
    GetStudyMemberByParticipantIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetStudyMemberByParticipantIdQuery,
    GetStudyMemberByParticipantIdQueryVariables
  >(GetStudyMemberByParticipantIdDocument, options);
}
export function useGetStudyMemberByParticipantIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetStudyMemberByParticipantIdQuery,
    GetStudyMemberByParticipantIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetStudyMemberByParticipantIdQuery,
    GetStudyMemberByParticipantIdQueryVariables
  >(GetStudyMemberByParticipantIdDocument, options);
}
export type GetStudyMemberByParticipantIdQueryHookResult = ReturnType<
  typeof useGetStudyMemberByParticipantIdQuery
>;
export type GetStudyMemberByParticipantIdLazyQueryHookResult = ReturnType<
  typeof useGetStudyMemberByParticipantIdLazyQuery
>;
export type GetStudyMemberByParticipantIdSuspenseQueryHookResult = ReturnType<
  typeof useGetStudyMemberByParticipantIdSuspenseQuery
>;
export type GetStudyMemberByParticipantIdQueryResult = Apollo.QueryResult<
  GetStudyMemberByParticipantIdQuery,
  GetStudyMemberByParticipantIdQueryVariables
>;
export const DeleteStudyMemberDocument = gql`
  mutation DeleteStudyMember($userId: uuid!, $studyId: uuid!) {
    deleteStudyMember(studyId: $studyId, userId: $userId) {
      id
      userId
    }
  }
`;
export type DeleteStudyMemberMutationFn = Apollo.MutationFunction<
  DeleteStudyMemberMutation,
  DeleteStudyMemberMutationVariables
>;

/**
 * __useDeleteStudyMemberMutation__
 *
 * To run a mutation, you first call `useDeleteStudyMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStudyMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStudyMemberMutation, { data, loading, error }] = useDeleteStudyMemberMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      studyId: // value for 'studyId'
 *   },
 * });
 */
export function useDeleteStudyMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteStudyMemberMutation,
    DeleteStudyMemberMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteStudyMemberMutation, DeleteStudyMemberMutationVariables>(
    DeleteStudyMemberDocument,
    options,
  );
}
export type DeleteStudyMemberMutationHookResult = ReturnType<typeof useDeleteStudyMemberMutation>;
export type DeleteStudyMemberMutationResult = Apollo.MutationResult<DeleteStudyMemberMutation>;
export type DeleteStudyMemberMutationOptions = Apollo.BaseMutationOptions<
  DeleteStudyMemberMutation,
  DeleteStudyMemberMutationVariables
>;
export const UpdateStudyMemberDocument = gql`
  mutation UpdateStudyMember($userId: uuid!, $studyId: uuid!, $studyRoleId: uuid!) {
    updateStudyMember(
      pk_columns: { studyId: $studyId, userId: $userId }
      _set: { studyRoleId: $studyRoleId }
    ) {
      id
      studyRole {
        id
        name
      }
    }
  }
`;
export type UpdateStudyMemberMutationFn = Apollo.MutationFunction<
  UpdateStudyMemberMutation,
  UpdateStudyMemberMutationVariables
>;

/**
 * __useUpdateStudyMemberMutation__
 *
 * To run a mutation, you first call `useUpdateStudyMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStudyMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStudyMemberMutation, { data, loading, error }] = useUpdateStudyMemberMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      studyId: // value for 'studyId'
 *      studyRoleId: // value for 'studyRoleId'
 *   },
 * });
 */
export function useUpdateStudyMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateStudyMemberMutation,
    UpdateStudyMemberMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateStudyMemberMutation, UpdateStudyMemberMutationVariables>(
    UpdateStudyMemberDocument,
    options,
  );
}
export type UpdateStudyMemberMutationHookResult = ReturnType<typeof useUpdateStudyMemberMutation>;
export type UpdateStudyMemberMutationResult = Apollo.MutationResult<UpdateStudyMemberMutation>;
export type UpdateStudyMemberMutationOptions = Apollo.BaseMutationOptions<
  UpdateStudyMemberMutation,
  UpdateStudyMemberMutationVariables
>;
export const GetStudySupervisorRoleDocument = gql`
  query GetStudySupervisorRole($studyId: uuid!, $userId: uuid!, $participantId: uuid!) {
    studyMember(studyId: $studyId, userId: $userId) {
      id
      studyRole {
        id
        name
      }
    }
    supervisor(participantId: $participantId, userId: $userId) {
      id
      supervisorRole {
        id
        name
      }
    }
  }
`;

/**
 * __useGetStudySupervisorRoleQuery__
 *
 * To run a query within a React component, call `useGetStudySupervisorRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudySupervisorRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudySupervisorRoleQuery({
 *   variables: {
 *      studyId: // value for 'studyId'
 *      userId: // value for 'userId'
 *      participantId: // value for 'participantId'
 *   },
 * });
 */
export function useGetStudySupervisorRoleQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetStudySupervisorRoleQuery,
    GetStudySupervisorRoleQueryVariables
  > &
    ({ variables: GetStudySupervisorRoleQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetStudySupervisorRoleQuery, GetStudySupervisorRoleQueryVariables>(
    GetStudySupervisorRoleDocument,
    options,
  );
}
export function useGetStudySupervisorRoleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStudySupervisorRoleQuery,
    GetStudySupervisorRoleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetStudySupervisorRoleQuery, GetStudySupervisorRoleQueryVariables>(
    GetStudySupervisorRoleDocument,
    options,
  );
}
export function useGetStudySupervisorRoleSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetStudySupervisorRoleQuery,
    GetStudySupervisorRoleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetStudySupervisorRoleQuery, GetStudySupervisorRoleQueryVariables>(
    GetStudySupervisorRoleDocument,
    options,
  );
}
export type GetStudySupervisorRoleQueryHookResult = ReturnType<
  typeof useGetStudySupervisorRoleQuery
>;
export type GetStudySupervisorRoleLazyQueryHookResult = ReturnType<
  typeof useGetStudySupervisorRoleLazyQuery
>;
export type GetStudySupervisorRoleSuspenseQueryHookResult = ReturnType<
  typeof useGetStudySupervisorRoleSuspenseQuery
>;
export type GetStudySupervisorRoleQueryResult = Apollo.QueryResult<
  GetStudySupervisorRoleQuery,
  GetStudySupervisorRoleQueryVariables
>;
export const GetStudyMemberByIdDocument = gql`
  query GetStudyMemberById($roleId: uuid!) {
    studyMembers(where: { id: { _eq: $roleId } }, limit: 1) {
      userId
      studyRole {
        id
        name
      }
      id
    }
  }
`;

/**
 * __useGetStudyMemberByIdQuery__
 *
 * To run a query within a React component, call `useGetStudyMemberByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudyMemberByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudyMemberByIdQuery({
 *   variables: {
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function useGetStudyMemberByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetStudyMemberByIdQuery, GetStudyMemberByIdQueryVariables> &
    ({ variables: GetStudyMemberByIdQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetStudyMemberByIdQuery, GetStudyMemberByIdQueryVariables>(
    GetStudyMemberByIdDocument,
    options,
  );
}
export function useGetStudyMemberByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStudyMemberByIdQuery,
    GetStudyMemberByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetStudyMemberByIdQuery, GetStudyMemberByIdQueryVariables>(
    GetStudyMemberByIdDocument,
    options,
  );
}
export function useGetStudyMemberByIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetStudyMemberByIdQuery,
    GetStudyMemberByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetStudyMemberByIdQuery, GetStudyMemberByIdQueryVariables>(
    GetStudyMemberByIdDocument,
    options,
  );
}
export type GetStudyMemberByIdQueryHookResult = ReturnType<typeof useGetStudyMemberByIdQuery>;
export type GetStudyMemberByIdLazyQueryHookResult = ReturnType<
  typeof useGetStudyMemberByIdLazyQuery
>;
export type GetStudyMemberByIdSuspenseQueryHookResult = ReturnType<
  typeof useGetStudyMemberByIdSuspenseQuery
>;
export type GetStudyMemberByIdQueryResult = Apollo.QueryResult<
  GetStudyMemberByIdQuery,
  GetStudyMemberByIdQueryVariables
>;
export const CreateStudyMemberDocument = gql`
  mutation CreateStudyMember($email: String!, $studyId: uuid!, $roleId: uuid!) {
    createStudyMemberAction(email: $email, roleId: $roleId, studyId: $studyId) {
      email
      studyMember {
        id
        studyRole {
          id
          name
          description
        }
      }
    }
  }
`;
export type CreateStudyMemberMutationFn = Apollo.MutationFunction<
  CreateStudyMemberMutation,
  CreateStudyMemberMutationVariables
>;

/**
 * __useCreateStudyMemberMutation__
 *
 * To run a mutation, you first call `useCreateStudyMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStudyMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStudyMemberMutation, { data, loading, error }] = useCreateStudyMemberMutation({
 *   variables: {
 *      email: // value for 'email'
 *      studyId: // value for 'studyId'
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function useCreateStudyMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateStudyMemberMutation,
    CreateStudyMemberMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateStudyMemberMutation, CreateStudyMemberMutationVariables>(
    CreateStudyMemberDocument,
    options,
  );
}
export type CreateStudyMemberMutationHookResult = ReturnType<typeof useCreateStudyMemberMutation>;
export type CreateStudyMemberMutationResult = Apollo.MutationResult<CreateStudyMemberMutation>;
export type CreateStudyMemberMutationOptions = Apollo.BaseMutationOptions<
  CreateStudyMemberMutation,
  CreateStudyMemberMutationVariables
>;
export const GetStudyMembersDocument = gql`
  query GetStudyMembers($studyId: uuid!) {
    getStudyMembersAction(studyId: $studyId) {
      id
      userAvatar
      userEmail
      userFirstName
      userLastName
      studyMember {
        id
        userId
        studyRole {
          id
          name
          createdAt
        }
      }
    }
  }
`;

/**
 * __useGetStudyMembersQuery__
 *
 * To run a query within a React component, call `useGetStudyMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudyMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudyMembersQuery({
 *   variables: {
 *      studyId: // value for 'studyId'
 *   },
 * });
 */
export function useGetStudyMembersQuery(
  baseOptions: Apollo.QueryHookOptions<GetStudyMembersQuery, GetStudyMembersQueryVariables> &
    ({ variables: GetStudyMembersQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetStudyMembersQuery, GetStudyMembersQueryVariables>(
    GetStudyMembersDocument,
    options,
  );
}
export function useGetStudyMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetStudyMembersQuery, GetStudyMembersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetStudyMembersQuery, GetStudyMembersQueryVariables>(
    GetStudyMembersDocument,
    options,
  );
}
export function useGetStudyMembersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetStudyMembersQuery,
    GetStudyMembersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetStudyMembersQuery, GetStudyMembersQueryVariables>(
    GetStudyMembersDocument,
    options,
  );
}
export type GetStudyMembersQueryHookResult = ReturnType<typeof useGetStudyMembersQuery>;
export type GetStudyMembersLazyQueryHookResult = ReturnType<typeof useGetStudyMembersLazyQuery>;
export type GetStudyMembersSuspenseQueryHookResult = ReturnType<
  typeof useGetStudyMembersSuspenseQuery
>;
export type GetStudyMembersQueryResult = Apollo.QueryResult<
  GetStudyMembersQuery,
  GetStudyMembersQueryVariables
>;
export const GetStudyRolesOfStudyDocument = gql`
  query GetStudyRolesOfStudy($studyId: uuid!) {
    studyRoles(where: { studyId: { _eq: $studyId } }) {
      id
      name
    }
  }
`;

/**
 * __useGetStudyRolesOfStudyQuery__
 *
 * To run a query within a React component, call `useGetStudyRolesOfStudyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudyRolesOfStudyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudyRolesOfStudyQuery({
 *   variables: {
 *      studyId: // value for 'studyId'
 *   },
 * });
 */
export function useGetStudyRolesOfStudyQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetStudyRolesOfStudyQuery,
    GetStudyRolesOfStudyQueryVariables
  > &
    ({ variables: GetStudyRolesOfStudyQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetStudyRolesOfStudyQuery, GetStudyRolesOfStudyQueryVariables>(
    GetStudyRolesOfStudyDocument,
    options,
  );
}
export function useGetStudyRolesOfStudyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStudyRolesOfStudyQuery,
    GetStudyRolesOfStudyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetStudyRolesOfStudyQuery, GetStudyRolesOfStudyQueryVariables>(
    GetStudyRolesOfStudyDocument,
    options,
  );
}
export function useGetStudyRolesOfStudySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetStudyRolesOfStudyQuery,
    GetStudyRolesOfStudyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetStudyRolesOfStudyQuery, GetStudyRolesOfStudyQueryVariables>(
    GetStudyRolesOfStudyDocument,
    options,
  );
}
export type GetStudyRolesOfStudyQueryHookResult = ReturnType<typeof useGetStudyRolesOfStudyQuery>;
export type GetStudyRolesOfStudyLazyQueryHookResult = ReturnType<
  typeof useGetStudyRolesOfStudyLazyQuery
>;
export type GetStudyRolesOfStudySuspenseQueryHookResult = ReturnType<
  typeof useGetStudyRolesOfStudySuspenseQuery
>;
export type GetStudyRolesOfStudyQueryResult = Apollo.QueryResult<
  GetStudyRolesOfStudyQuery,
  GetStudyRolesOfStudyQueryVariables
>;
export const CreateSupervisorsDocument = gql`
  mutation CreateSupervisors($assignments: [supervisor_insert_input!]!) {
    createSupervisors(objects: $assignments) {
      returning {
        id
        participantId
        userId
      }
    }
  }
`;
export type CreateSupervisorsMutationFn = Apollo.MutationFunction<
  CreateSupervisorsMutation,
  CreateSupervisorsMutationVariables
>;

/**
 * __useCreateSupervisorsMutation__
 *
 * To run a mutation, you first call `useCreateSupervisorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSupervisorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSupervisorsMutation, { data, loading, error }] = useCreateSupervisorsMutation({
 *   variables: {
 *      assignments: // value for 'assignments'
 *   },
 * });
 */
export function useCreateSupervisorsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSupervisorsMutation,
    CreateSupervisorsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateSupervisorsMutation, CreateSupervisorsMutationVariables>(
    CreateSupervisorsDocument,
    options,
  );
}
export type CreateSupervisorsMutationHookResult = ReturnType<typeof useCreateSupervisorsMutation>;
export type CreateSupervisorsMutationResult = Apollo.MutationResult<CreateSupervisorsMutation>;
export type CreateSupervisorsMutationOptions = Apollo.BaseMutationOptions<
  CreateSupervisorsMutation,
  CreateSupervisorsMutationVariables
>;
export const GetSupervisorAssignmentsOfUserDocument = gql`
  query GetSupervisorAssignmentsOfUser($userId: uuid!, $studyId: uuid!) {
    supervisors(
      where: {
        _and: {
          userId: { _eq: $userId }
          participant: { participantGroup: { studyId: { _eq: $studyId } } }
        }
      }
    ) {
      id
      participant {
        pseudonym
        id
      }
    }
  }
`;

/**
 * __useGetSupervisorAssignmentsOfUserQuery__
 *
 * To run a query within a React component, call `useGetSupervisorAssignmentsOfUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupervisorAssignmentsOfUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupervisorAssignmentsOfUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      studyId: // value for 'studyId'
 *   },
 * });
 */
export function useGetSupervisorAssignmentsOfUserQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSupervisorAssignmentsOfUserQuery,
    GetSupervisorAssignmentsOfUserQueryVariables
  > &
    (
      | { variables: GetSupervisorAssignmentsOfUserQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSupervisorAssignmentsOfUserQuery,
    GetSupervisorAssignmentsOfUserQueryVariables
  >(GetSupervisorAssignmentsOfUserDocument, options);
}
export function useGetSupervisorAssignmentsOfUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSupervisorAssignmentsOfUserQuery,
    GetSupervisorAssignmentsOfUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSupervisorAssignmentsOfUserQuery,
    GetSupervisorAssignmentsOfUserQueryVariables
  >(GetSupervisorAssignmentsOfUserDocument, options);
}
export function useGetSupervisorAssignmentsOfUserSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetSupervisorAssignmentsOfUserQuery,
    GetSupervisorAssignmentsOfUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetSupervisorAssignmentsOfUserQuery,
    GetSupervisorAssignmentsOfUserQueryVariables
  >(GetSupervisorAssignmentsOfUserDocument, options);
}
export type GetSupervisorAssignmentsOfUserQueryHookResult = ReturnType<
  typeof useGetSupervisorAssignmentsOfUserQuery
>;
export type GetSupervisorAssignmentsOfUserLazyQueryHookResult = ReturnType<
  typeof useGetSupervisorAssignmentsOfUserLazyQuery
>;
export type GetSupervisorAssignmentsOfUserSuspenseQueryHookResult = ReturnType<
  typeof useGetSupervisorAssignmentsOfUserSuspenseQuery
>;
export type GetSupervisorAssignmentsOfUserQueryResult = Apollo.QueryResult<
  GetSupervisorAssignmentsOfUserQuery,
  GetSupervisorAssignmentsOfUserQueryVariables
>;
export const GetSupervisorDocument = gql`
  query GetSupervisor($userId: uuid!, $participantId: uuid!) {
    supervisor(participantId: $participantId, userId: $userId) {
      id
      supervisorRole {
        id
        name
        description
        participantCreateSupervisor
        participantDownloadData
        participantEditTherapyConfig
        participantViewDetails
      }
    }
  }
`;

/**
 * __useGetSupervisorQuery__
 *
 * To run a query within a React component, call `useGetSupervisorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupervisorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupervisorQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      participantId: // value for 'participantId'
 *   },
 * });
 */
export function useGetSupervisorQuery(
  baseOptions: Apollo.QueryHookOptions<GetSupervisorQuery, GetSupervisorQueryVariables> &
    ({ variables: GetSupervisorQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSupervisorQuery, GetSupervisorQueryVariables>(
    GetSupervisorDocument,
    options,
  );
}
export function useGetSupervisorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSupervisorQuery, GetSupervisorQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSupervisorQuery, GetSupervisorQueryVariables>(
    GetSupervisorDocument,
    options,
  );
}
export function useGetSupervisorSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetSupervisorQuery, GetSupervisorQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetSupervisorQuery, GetSupervisorQueryVariables>(
    GetSupervisorDocument,
    options,
  );
}
export type GetSupervisorQueryHookResult = ReturnType<typeof useGetSupervisorQuery>;
export type GetSupervisorLazyQueryHookResult = ReturnType<typeof useGetSupervisorLazyQuery>;
export type GetSupervisorSuspenseQueryHookResult = ReturnType<typeof useGetSupervisorSuspenseQuery>;
export type GetSupervisorQueryResult = Apollo.QueryResult<
  GetSupervisorQuery,
  GetSupervisorQueryVariables
>;
export const DeleteSupervisorDocument = gql`
  mutation DeleteSupervisor($participantId: uuid!, $userId: uuid!) {
    deleteSupervisor(participantId: $participantId, userId: $userId) {
      id
    }
  }
`;
export type DeleteSupervisorMutationFn = Apollo.MutationFunction<
  DeleteSupervisorMutation,
  DeleteSupervisorMutationVariables
>;

/**
 * __useDeleteSupervisorMutation__
 *
 * To run a mutation, you first call `useDeleteSupervisorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSupervisorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSupervisorMutation, { data, loading, error }] = useDeleteSupervisorMutation({
 *   variables: {
 *      participantId: // value for 'participantId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeleteSupervisorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteSupervisorMutation,
    DeleteSupervisorMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteSupervisorMutation, DeleteSupervisorMutationVariables>(
    DeleteSupervisorDocument,
    options,
  );
}
export type DeleteSupervisorMutationHookResult = ReturnType<typeof useDeleteSupervisorMutation>;
export type DeleteSupervisorMutationResult = Apollo.MutationResult<DeleteSupervisorMutation>;
export type DeleteSupervisorMutationOptions = Apollo.BaseMutationOptions<
  DeleteSupervisorMutation,
  DeleteSupervisorMutationVariables
>;
export const GetSupervisorsOfStudyDocument = gql`
  query GetSupervisorsOfStudy($studyId: uuid!) {
    getSupervisorsOfStudyAction(studyId: $studyId) {
      userAvatar
      userEmail
      userFirstName
      userId
      userLastName
    }
  }
`;

/**
 * __useGetSupervisorsOfStudyQuery__
 *
 * To run a query within a React component, call `useGetSupervisorsOfStudyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupervisorsOfStudyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupervisorsOfStudyQuery({
 *   variables: {
 *      studyId: // value for 'studyId'
 *   },
 * });
 */
export function useGetSupervisorsOfStudyQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSupervisorsOfStudyQuery,
    GetSupervisorsOfStudyQueryVariables
  > &
    ({ variables: GetSupervisorsOfStudyQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSupervisorsOfStudyQuery, GetSupervisorsOfStudyQueryVariables>(
    GetSupervisorsOfStudyDocument,
    options,
  );
}
export function useGetSupervisorsOfStudyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSupervisorsOfStudyQuery,
    GetSupervisorsOfStudyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSupervisorsOfStudyQuery, GetSupervisorsOfStudyQueryVariables>(
    GetSupervisorsOfStudyDocument,
    options,
  );
}
export function useGetSupervisorsOfStudySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetSupervisorsOfStudyQuery,
    GetSupervisorsOfStudyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetSupervisorsOfStudyQuery, GetSupervisorsOfStudyQueryVariables>(
    GetSupervisorsOfStudyDocument,
    options,
  );
}
export type GetSupervisorsOfStudyQueryHookResult = ReturnType<typeof useGetSupervisorsOfStudyQuery>;
export type GetSupervisorsOfStudyLazyQueryHookResult = ReturnType<
  typeof useGetSupervisorsOfStudyLazyQuery
>;
export type GetSupervisorsOfStudySuspenseQueryHookResult = ReturnType<
  typeof useGetSupervisorsOfStudySuspenseQuery
>;
export type GetSupervisorsOfStudyQueryResult = Apollo.QueryResult<
  GetSupervisorsOfStudyQuery,
  GetSupervisorsOfStudyQueryVariables
>;
export const GetSupervisorsOfParticipantDocument = gql`
  query GetSupervisorsOfParticipant($participantId: uuid!) {
    getSupervisorsOfParticipantAction(participantId: $participantId) {
      userAvatar
      userEmail
      userFirstName
      userId
      userLastName
    }
  }
`;

/**
 * __useGetSupervisorsOfParticipantQuery__
 *
 * To run a query within a React component, call `useGetSupervisorsOfParticipantQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupervisorsOfParticipantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupervisorsOfParticipantQuery({
 *   variables: {
 *      participantId: // value for 'participantId'
 *   },
 * });
 */
export function useGetSupervisorsOfParticipantQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSupervisorsOfParticipantQuery,
    GetSupervisorsOfParticipantQueryVariables
  > &
    ({ variables: GetSupervisorsOfParticipantQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSupervisorsOfParticipantQuery,
    GetSupervisorsOfParticipantQueryVariables
  >(GetSupervisorsOfParticipantDocument, options);
}
export function useGetSupervisorsOfParticipantLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSupervisorsOfParticipantQuery,
    GetSupervisorsOfParticipantQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSupervisorsOfParticipantQuery,
    GetSupervisorsOfParticipantQueryVariables
  >(GetSupervisorsOfParticipantDocument, options);
}
export function useGetSupervisorsOfParticipantSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetSupervisorsOfParticipantQuery,
    GetSupervisorsOfParticipantQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetSupervisorsOfParticipantQuery,
    GetSupervisorsOfParticipantQueryVariables
  >(GetSupervisorsOfParticipantDocument, options);
}
export type GetSupervisorsOfParticipantQueryHookResult = ReturnType<
  typeof useGetSupervisorsOfParticipantQuery
>;
export type GetSupervisorsOfParticipantLazyQueryHookResult = ReturnType<
  typeof useGetSupervisorsOfParticipantLazyQuery
>;
export type GetSupervisorsOfParticipantSuspenseQueryHookResult = ReturnType<
  typeof useGetSupervisorsOfParticipantSuspenseQuery
>;
export type GetSupervisorsOfParticipantQueryResult = Apollo.QueryResult<
  GetSupervisorsOfParticipantQuery,
  GetSupervisorsOfParticipantQueryVariables
>;
export const CreateSupervisorActionDocument = gql`
  mutation CreateSupervisorAction(
    $participantIds: [uuid]!
    $studyId: uuid!
    $email: String!
    $supervisorRoleId: uuid!
  ) {
    createSupervisorAction(
      email: $email
      participantIds: $participantIds
      studyId: $studyId
      supervisorRoleId: $supervisorRoleId
    ) {
      id
      participantId
      userId
    }
  }
`;
export type CreateSupervisorActionMutationFn = Apollo.MutationFunction<
  CreateSupervisorActionMutation,
  CreateSupervisorActionMutationVariables
>;

/**
 * __useCreateSupervisorActionMutation__
 *
 * To run a mutation, you first call `useCreateSupervisorActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSupervisorActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSupervisorActionMutation, { data, loading, error }] = useCreateSupervisorActionMutation({
 *   variables: {
 *      participantIds: // value for 'participantIds'
 *      studyId: // value for 'studyId'
 *      email: // value for 'email'
 *      supervisorRoleId: // value for 'supervisorRoleId'
 *   },
 * });
 */
export function useCreateSupervisorActionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSupervisorActionMutation,
    CreateSupervisorActionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateSupervisorActionMutation,
    CreateSupervisorActionMutationVariables
  >(CreateSupervisorActionDocument, options);
}
export type CreateSupervisorActionMutationHookResult = ReturnType<
  typeof useCreateSupervisorActionMutation
>;
export type CreateSupervisorActionMutationResult =
  Apollo.MutationResult<CreateSupervisorActionMutation>;
export type CreateSupervisorActionMutationOptions = Apollo.BaseMutationOptions<
  CreateSupervisorActionMutation,
  CreateSupervisorActionMutationVariables
>;
export const GetSupervisorRolesOfStudyDocument = gql`
  query GetSupervisorRolesOfStudy($studyId: uuid!) {
    supervisorRoles(where: { studyId: { _eq: $studyId } }) {
      id
      name
      participantCreateSupervisor
      participantDownloadData
      participantEditTherapyConfig
      participantViewDetails
    }
  }
`;

/**
 * __useGetSupervisorRolesOfStudyQuery__
 *
 * To run a query within a React component, call `useGetSupervisorRolesOfStudyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupervisorRolesOfStudyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupervisorRolesOfStudyQuery({
 *   variables: {
 *      studyId: // value for 'studyId'
 *   },
 * });
 */
export function useGetSupervisorRolesOfStudyQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSupervisorRolesOfStudyQuery,
    GetSupervisorRolesOfStudyQueryVariables
  > &
    ({ variables: GetSupervisorRolesOfStudyQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSupervisorRolesOfStudyQuery, GetSupervisorRolesOfStudyQueryVariables>(
    GetSupervisorRolesOfStudyDocument,
    options,
  );
}
export function useGetSupervisorRolesOfStudyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSupervisorRolesOfStudyQuery,
    GetSupervisorRolesOfStudyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSupervisorRolesOfStudyQuery,
    GetSupervisorRolesOfStudyQueryVariables
  >(GetSupervisorRolesOfStudyDocument, options);
}
export function useGetSupervisorRolesOfStudySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetSupervisorRolesOfStudyQuery,
    GetSupervisorRolesOfStudyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetSupervisorRolesOfStudyQuery,
    GetSupervisorRolesOfStudyQueryVariables
  >(GetSupervisorRolesOfStudyDocument, options);
}
export type GetSupervisorRolesOfStudyQueryHookResult = ReturnType<
  typeof useGetSupervisorRolesOfStudyQuery
>;
export type GetSupervisorRolesOfStudyLazyQueryHookResult = ReturnType<
  typeof useGetSupervisorRolesOfStudyLazyQuery
>;
export type GetSupervisorRolesOfStudySuspenseQueryHookResult = ReturnType<
  typeof useGetSupervisorRolesOfStudySuspenseQuery
>;
export type GetSupervisorRolesOfStudyQueryResult = Apollo.QueryResult<
  GetSupervisorRolesOfStudyQuery,
  GetSupervisorRolesOfStudyQueryVariables
>;
export const GetParticipantTherapyConfigDocument = gql`
  query GetParticipantTherapyConfig($participantId: uuid!) {
    getParticipantTherapyVariableConfigAction(participantId: $participantId) {
      participantId
      therapyVariableId
      variableValue
    }
  }
`;

/**
 * __useGetParticipantTherapyConfigQuery__
 *
 * To run a query within a React component, call `useGetParticipantTherapyConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParticipantTherapyConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParticipantTherapyConfigQuery({
 *   variables: {
 *      participantId: // value for 'participantId'
 *   },
 * });
 */
export function useGetParticipantTherapyConfigQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetParticipantTherapyConfigQuery,
    GetParticipantTherapyConfigQueryVariables
  > &
    ({ variables: GetParticipantTherapyConfigQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetParticipantTherapyConfigQuery,
    GetParticipantTherapyConfigQueryVariables
  >(GetParticipantTherapyConfigDocument, options);
}
export function useGetParticipantTherapyConfigLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetParticipantTherapyConfigQuery,
    GetParticipantTherapyConfigQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetParticipantTherapyConfigQuery,
    GetParticipantTherapyConfigQueryVariables
  >(GetParticipantTherapyConfigDocument, options);
}
export function useGetParticipantTherapyConfigSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetParticipantTherapyConfigQuery,
    GetParticipantTherapyConfigQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetParticipantTherapyConfigQuery,
    GetParticipantTherapyConfigQueryVariables
  >(GetParticipantTherapyConfigDocument, options);
}
export type GetParticipantTherapyConfigQueryHookResult = ReturnType<
  typeof useGetParticipantTherapyConfigQuery
>;
export type GetParticipantTherapyConfigLazyQueryHookResult = ReturnType<
  typeof useGetParticipantTherapyConfigLazyQuery
>;
export type GetParticipantTherapyConfigSuspenseQueryHookResult = ReturnType<
  typeof useGetParticipantTherapyConfigSuspenseQuery
>;
export type GetParticipantTherapyConfigQueryResult = Apollo.QueryResult<
  GetParticipantTherapyConfigQuery,
  GetParticipantTherapyConfigQueryVariables
>;
export const UpdateParticipantTherapyConfigDocument = gql`
  mutation UpdateParticipantTherapyConfig($objects: [ParticipantVariableConfigsToUpdate!]) {
    updateParticipantTherapyVariableConfigsAction(objects: $objects) {
      variableValue
      editorId
      participantId
      therapyVariableId
    }
  }
`;
export type UpdateParticipantTherapyConfigMutationFn = Apollo.MutationFunction<
  UpdateParticipantTherapyConfigMutation,
  UpdateParticipantTherapyConfigMutationVariables
>;

/**
 * __useUpdateParticipantTherapyConfigMutation__
 *
 * To run a mutation, you first call `useUpdateParticipantTherapyConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateParticipantTherapyConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateParticipantTherapyConfigMutation, { data, loading, error }] = useUpdateParticipantTherapyConfigMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useUpdateParticipantTherapyConfigMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateParticipantTherapyConfigMutation,
    UpdateParticipantTherapyConfigMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateParticipantTherapyConfigMutation,
    UpdateParticipantTherapyConfigMutationVariables
  >(UpdateParticipantTherapyConfigDocument, options);
}
export type UpdateParticipantTherapyConfigMutationHookResult = ReturnType<
  typeof useUpdateParticipantTherapyConfigMutation
>;
export type UpdateParticipantTherapyConfigMutationResult =
  Apollo.MutationResult<UpdateParticipantTherapyConfigMutation>;
export type UpdateParticipantTherapyConfigMutationOptions = Apollo.BaseMutationOptions<
  UpdateParticipantTherapyConfigMutation,
  UpdateParticipantTherapyConfigMutationVariables
>;
export const ValidateParticipantTherapyConfigDocument = gql`
  query ValidateParticipantTherapyConfig(
    $participantId: uuid!
    $values: [VariableConfigValue!]!
    $language: String!
  ) {
    validateParticipantTherapyConfigAction(
      participantId: $participantId
      values: $values
      language: $language
    ) {
      validationInfoItem
      results {
        variableId
        value
        isValid
        errorMessage
      }
    }
  }
`;

/**
 * __useValidateParticipantTherapyConfigQuery__
 *
 * To run a query within a React component, call `useValidateParticipantTherapyConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateParticipantTherapyConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateParticipantTherapyConfigQuery({
 *   variables: {
 *      participantId: // value for 'participantId'
 *      values: // value for 'values'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useValidateParticipantTherapyConfigQuery(
  baseOptions: Apollo.QueryHookOptions<
    ValidateParticipantTherapyConfigQuery,
    ValidateParticipantTherapyConfigQueryVariables
  > &
    (
      | { variables: ValidateParticipantTherapyConfigQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ValidateParticipantTherapyConfigQuery,
    ValidateParticipantTherapyConfigQueryVariables
  >(ValidateParticipantTherapyConfigDocument, options);
}
export function useValidateParticipantTherapyConfigLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ValidateParticipantTherapyConfigQuery,
    ValidateParticipantTherapyConfigQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ValidateParticipantTherapyConfigQuery,
    ValidateParticipantTherapyConfigQueryVariables
  >(ValidateParticipantTherapyConfigDocument, options);
}
export function useValidateParticipantTherapyConfigSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ValidateParticipantTherapyConfigQuery,
    ValidateParticipantTherapyConfigQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ValidateParticipantTherapyConfigQuery,
    ValidateParticipantTherapyConfigQueryVariables
  >(ValidateParticipantTherapyConfigDocument, options);
}
export type ValidateParticipantTherapyConfigQueryHookResult = ReturnType<
  typeof useValidateParticipantTherapyConfigQuery
>;
export type ValidateParticipantTherapyConfigLazyQueryHookResult = ReturnType<
  typeof useValidateParticipantTherapyConfigLazyQuery
>;
export type ValidateParticipantTherapyConfigSuspenseQueryHookResult = ReturnType<
  typeof useValidateParticipantTherapyConfigSuspenseQuery
>;
export type ValidateParticipantTherapyConfigQueryResult = Apollo.QueryResult<
  ValidateParticipantTherapyConfigQuery,
  ValidateParticipantTherapyConfigQueryVariables
>;
export const GetTherapyDesignDocument = gql`
  query GetTherapyDesign($studyId: uuid!, $language: String!) {
    getTherapyDesignAction(studyId: $studyId, language: $language) {
      id
      value
    }
  }
`;

/**
 * __useGetTherapyDesignQuery__
 *
 * To run a query within a React component, call `useGetTherapyDesignQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTherapyDesignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTherapyDesignQuery({
 *   variables: {
 *      studyId: // value for 'studyId'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useGetTherapyDesignQuery(
  baseOptions: Apollo.QueryHookOptions<GetTherapyDesignQuery, GetTherapyDesignQueryVariables> &
    ({ variables: GetTherapyDesignQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTherapyDesignQuery, GetTherapyDesignQueryVariables>(
    GetTherapyDesignDocument,
    options,
  );
}
export function useGetTherapyDesignLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTherapyDesignQuery, GetTherapyDesignQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTherapyDesignQuery, GetTherapyDesignQueryVariables>(
    GetTherapyDesignDocument,
    options,
  );
}
export function useGetTherapyDesignSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetTherapyDesignQuery,
    GetTherapyDesignQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetTherapyDesignQuery, GetTherapyDesignQueryVariables>(
    GetTherapyDesignDocument,
    options,
  );
}
export type GetTherapyDesignQueryHookResult = ReturnType<typeof useGetTherapyDesignQuery>;
export type GetTherapyDesignLazyQueryHookResult = ReturnType<typeof useGetTherapyDesignLazyQuery>;
export type GetTherapyDesignSuspenseQueryHookResult = ReturnType<
  typeof useGetTherapyDesignSuspenseQuery
>;
export type GetTherapyDesignQueryResult = Apollo.QueryResult<
  GetTherapyDesignQuery,
  GetTherapyDesignQueryVariables
>;
export const GetTherapyDesignsDocument = gql`
  query GetTherapyDesigns($executorUrl: String!) {
    getTherapyDesignsAction(executorUrl: $executorUrl) {
      description
      id
      title
      version
    }
  }
`;

/**
 * __useGetTherapyDesignsQuery__
 *
 * To run a query within a React component, call `useGetTherapyDesignsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTherapyDesignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTherapyDesignsQuery({
 *   variables: {
 *      executorUrl: // value for 'executorUrl'
 *   },
 * });
 */
export function useGetTherapyDesignsQuery(
  baseOptions: Apollo.QueryHookOptions<GetTherapyDesignsQuery, GetTherapyDesignsQueryVariables> &
    ({ variables: GetTherapyDesignsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTherapyDesignsQuery, GetTherapyDesignsQueryVariables>(
    GetTherapyDesignsDocument,
    options,
  );
}
export function useGetTherapyDesignsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTherapyDesignsQuery,
    GetTherapyDesignsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTherapyDesignsQuery, GetTherapyDesignsQueryVariables>(
    GetTherapyDesignsDocument,
    options,
  );
}
export function useGetTherapyDesignsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetTherapyDesignsQuery,
    GetTherapyDesignsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetTherapyDesignsQuery, GetTherapyDesignsQueryVariables>(
    GetTherapyDesignsDocument,
    options,
  );
}
export type GetTherapyDesignsQueryHookResult = ReturnType<typeof useGetTherapyDesignsQuery>;
export type GetTherapyDesignsLazyQueryHookResult = ReturnType<typeof useGetTherapyDesignsLazyQuery>;
export type GetTherapyDesignsSuspenseQueryHookResult = ReturnType<
  typeof useGetTherapyDesignsSuspenseQuery
>;
export type GetTherapyDesignsQueryResult = Apollo.QueryResult<
  GetTherapyDesignsQuery,
  GetTherapyDesignsQueryVariables
>;
export const UpdateTherapyPeriodDocument = gql`
  mutation UpdateTherapyPeriod($therapyPeriod: TherapyPeriod!) {
    updateTherapyPeriodAction(therapyPeriod: $therapyPeriod) {
      id
      coupleUrl
      therapyEnd
      therapyPeriodId
      therapyStart
    }
  }
`;
export type UpdateTherapyPeriodMutationFn = Apollo.MutationFunction<
  UpdateTherapyPeriodMutation,
  UpdateTherapyPeriodMutationVariables
>;

/**
 * __useUpdateTherapyPeriodMutation__
 *
 * To run a mutation, you first call `useUpdateTherapyPeriodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTherapyPeriodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTherapyPeriodMutation, { data, loading, error }] = useUpdateTherapyPeriodMutation({
 *   variables: {
 *      therapyPeriod: // value for 'therapyPeriod'
 *   },
 * });
 */
export function useUpdateTherapyPeriodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTherapyPeriodMutation,
    UpdateTherapyPeriodMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTherapyPeriodMutation, UpdateTherapyPeriodMutationVariables>(
    UpdateTherapyPeriodDocument,
    options,
  );
}
export type UpdateTherapyPeriodMutationHookResult = ReturnType<
  typeof useUpdateTherapyPeriodMutation
>;
export type UpdateTherapyPeriodMutationResult = Apollo.MutationResult<UpdateTherapyPeriodMutation>;
export type UpdateTherapyPeriodMutationOptions = Apollo.BaseMutationOptions<
  UpdateTherapyPeriodMutation,
  UpdateTherapyPeriodMutationVariables
>;
export const GetUserDocument = gql`
  query GetUser($userId: uuid!) {
    user(id: $userId) {
      id
      firstName
      lastName
      sendNewsletter
      title
      avatar
      studyMembers {
        id
        studyRole {
          id
          name
          description
        }
        study {
          name
          id
          description
          logo
          organization {
            name
          }
        }
      }
      organizationMembers {
        id
        organizationRole {
          id
          name
          description
          organizationCreateStudy
        }
        organization {
          id
          name
        }
      }
      supervisors {
        id
        participant {
          id
          pseudonym
          participantGroup {
            id
            name
            study {
              id
              name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserQuery(
  baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables> &
    ({ variables: GetUserQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
}
export function useGetUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
}
export function useGetUserSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetUserQuery, GetUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
}
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserSuspenseQueryHookResult = ReturnType<typeof useGetUserSuspenseQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const UpdateUserDetailsDocument = gql`
  mutation UpdateUserDetails(
    $userId: uuid!
    $firstName: String!
    $lastName: String!
    $title: String
  ) {
    updateUser(
      pk_columns: { id: $userId }
      _set: { firstName: $firstName, lastName: $lastName, title: $title }
    ) {
      id
      firstName
      lastName
      title
      sendNewsletter
    }
  }
`;
export type UpdateUserDetailsMutationFn = Apollo.MutationFunction<
  UpdateUserDetailsMutation,
  UpdateUserDetailsMutationVariables
>;

/**
 * __useUpdateUserDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateUserDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserDetailsMutation, { data, loading, error }] = useUpdateUserDetailsMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useUpdateUserDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserDetailsMutation,
    UpdateUserDetailsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserDetailsMutation, UpdateUserDetailsMutationVariables>(
    UpdateUserDetailsDocument,
    options,
  );
}
export type UpdateUserDetailsMutationHookResult = ReturnType<typeof useUpdateUserDetailsMutation>;
export type UpdateUserDetailsMutationResult = Apollo.MutationResult<UpdateUserDetailsMutation>;
export type UpdateUserDetailsMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserDetailsMutation,
  UpdateUserDetailsMutationVariables
>;
export const UpdateAvatarDocument = gql`
  mutation UpdateAvatar($userId: uuid!, $base64Img: String!) {
    updateUser(pk_columns: { id: $userId }, _set: { avatar: $base64Img }) {
      id
      avatar
    }
  }
`;
export type UpdateAvatarMutationFn = Apollo.MutationFunction<
  UpdateAvatarMutation,
  UpdateAvatarMutationVariables
>;

/**
 * __useUpdateAvatarMutation__
 *
 * To run a mutation, you first call `useUpdateAvatarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAvatarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAvatarMutation, { data, loading, error }] = useUpdateAvatarMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      base64Img: // value for 'base64Img'
 *   },
 * });
 */
export function useUpdateAvatarMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateAvatarMutation, UpdateAvatarMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAvatarMutation, UpdateAvatarMutationVariables>(
    UpdateAvatarDocument,
    options,
  );
}
export type UpdateAvatarMutationHookResult = ReturnType<typeof useUpdateAvatarMutation>;
export type UpdateAvatarMutationResult = Apollo.MutationResult<UpdateAvatarMutation>;
export type UpdateAvatarMutationOptions = Apollo.BaseMutationOptions<
  UpdateAvatarMutation,
  UpdateAvatarMutationVariables
>;
export const CanDeleteAccountDocument = gql`
  query CanDeleteAccount($userId: uuid!) {
    canDeleteAccountAction(userId: $userId) {
      studiesLastOwner
      organizationsLastOwner
      canDelete
    }
  }
`;

/**
 * __useCanDeleteAccountQuery__
 *
 * To run a query within a React component, call `useCanDeleteAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useCanDeleteAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCanDeleteAccountQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCanDeleteAccountQuery(
  baseOptions: Apollo.QueryHookOptions<CanDeleteAccountQuery, CanDeleteAccountQueryVariables> &
    ({ variables: CanDeleteAccountQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CanDeleteAccountQuery, CanDeleteAccountQueryVariables>(
    CanDeleteAccountDocument,
    options,
  );
}
export function useCanDeleteAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CanDeleteAccountQuery, CanDeleteAccountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CanDeleteAccountQuery, CanDeleteAccountQueryVariables>(
    CanDeleteAccountDocument,
    options,
  );
}
export function useCanDeleteAccountSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CanDeleteAccountQuery,
    CanDeleteAccountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CanDeleteAccountQuery, CanDeleteAccountQueryVariables>(
    CanDeleteAccountDocument,
    options,
  );
}
export type CanDeleteAccountQueryHookResult = ReturnType<typeof useCanDeleteAccountQuery>;
export type CanDeleteAccountLazyQueryHookResult = ReturnType<typeof useCanDeleteAccountLazyQuery>;
export type CanDeleteAccountSuspenseQueryHookResult = ReturnType<
  typeof useCanDeleteAccountSuspenseQuery
>;
export type CanDeleteAccountQueryResult = Apollo.QueryResult<
  CanDeleteAccountQuery,
  CanDeleteAccountQueryVariables
>;
export const DeleteAccountDocument = gql`
  mutation DeleteAccount($userId: uuid!) {
    deleteAccountAction(userId: $userId) {
      success
    }
  }
`;
export type DeleteAccountMutationFn = Apollo.MutationFunction<
  DeleteAccountMutation,
  DeleteAccountMutationVariables
>;

/**
 * __useDeleteAccountMutation__
 *
 * To run a mutation, you first call `useDeleteAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccountMutation, { data, loading, error }] = useDeleteAccountMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeleteAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteAccountMutation, DeleteAccountMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAccountMutation, DeleteAccountMutationVariables>(
    DeleteAccountDocument,
    options,
  );
}
export type DeleteAccountMutationHookResult = ReturnType<typeof useDeleteAccountMutation>;
export type DeleteAccountMutationResult = Apollo.MutationResult<DeleteAccountMutation>;
export type DeleteAccountMutationOptions = Apollo.BaseMutationOptions<
  DeleteAccountMutation,
  DeleteAccountMutationVariables
>;
export const GetVisualizationsOfParticipantDocument = gql`
  query GetVisualizationsOfParticipant(
    $end: String
    $participantId: uuid!
    $start: String
    $language: String = "en"
  ) {
    getVisualizationsAction(
      participantId: $participantId
      end: $end
      start: $start
      language: $language
    ) {
      children
      id
      title
      warning
      start
      end
      description
    }
  }
`;

/**
 * __useGetVisualizationsOfParticipantQuery__
 *
 * To run a query within a React component, call `useGetVisualizationsOfParticipantQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVisualizationsOfParticipantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVisualizationsOfParticipantQuery({
 *   variables: {
 *      end: // value for 'end'
 *      participantId: // value for 'participantId'
 *      start: // value for 'start'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useGetVisualizationsOfParticipantQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetVisualizationsOfParticipantQuery,
    GetVisualizationsOfParticipantQueryVariables
  > &
    (
      | { variables: GetVisualizationsOfParticipantQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetVisualizationsOfParticipantQuery,
    GetVisualizationsOfParticipantQueryVariables
  >(GetVisualizationsOfParticipantDocument, options);
}
export function useGetVisualizationsOfParticipantLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVisualizationsOfParticipantQuery,
    GetVisualizationsOfParticipantQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetVisualizationsOfParticipantQuery,
    GetVisualizationsOfParticipantQueryVariables
  >(GetVisualizationsOfParticipantDocument, options);
}
export function useGetVisualizationsOfParticipantSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetVisualizationsOfParticipantQuery,
    GetVisualizationsOfParticipantQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetVisualizationsOfParticipantQuery,
    GetVisualizationsOfParticipantQueryVariables
  >(GetVisualizationsOfParticipantDocument, options);
}
export type GetVisualizationsOfParticipantQueryHookResult = ReturnType<
  typeof useGetVisualizationsOfParticipantQuery
>;
export type GetVisualizationsOfParticipantLazyQueryHookResult = ReturnType<
  typeof useGetVisualizationsOfParticipantLazyQuery
>;
export type GetVisualizationsOfParticipantSuspenseQueryHookResult = ReturnType<
  typeof useGetVisualizationsOfParticipantSuspenseQuery
>;
export type GetVisualizationsOfParticipantQueryResult = Apollo.QueryResult<
  GetVisualizationsOfParticipantQuery,
  GetVisualizationsOfParticipantQueryVariables
>;
