import React, { ReactNode, useMemo } from "react";
import { GetStudyQuery } from "../../services";

const StudyContext = React.createContext<
  { study: NonNullable<GetStudyQuery["study"]> } | undefined
>(undefined);

interface IStudyProviderProps {
  study: NonNullable<GetStudyQuery["study"]>;
  children: ReactNode;
}

function StudyProvider({ children, study }: IStudyProviderProps) {
  const value = useMemo(() => ({ study }), [study]);

  return <StudyContext.Provider value={value}>{children}</StudyContext.Provider>;
}

function useCachedStudy() {
  const context = React.useContext(StudyContext);
  if (context === undefined) {
    throw new Error("useStudy must be used within a StudyProvider");
  }
  return context;
}

export { StudyProvider, useCachedStudy };
