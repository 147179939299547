/* eslint-disable camelcase */
import React, { CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import { OrganizationRoleEnum } from "../..";

import { getOrganizationRoleLabelColor } from "../Tag";

import TagMultiFilter from "./TagMultiFilter";

export interface IOrganizationRoleFilterProps {
  initialValue?: string[];
  style?: CSSProperties;
  // eslint-disable-next-line no-unused-vars
  onChange: (value: string[]) => void;
}

export function OrganizationRoleFilter({
  onChange,
  style = {},
  initialValue = [OrganizationRoleEnum.MEMBER, OrganizationRoleEnum.OWNER],
}: IOrganizationRoleFilterProps) {
  const { t } = useTranslation();
  return (
    <TagMultiFilter
      getTagColor={getOrganizationRoleLabelColor}
      initialValue={initialValue}
      placeholderText={t("organization.members.roleFilter.placeholder")}
      options={[OrganizationRoleEnum.MEMBER, OrganizationRoleEnum.OWNER]}
      onChange={onChange}
      style={style}
    />
  );
}

export default OrganizationRoleFilter;
