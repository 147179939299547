import { Form, Input, Modal, Spin } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useCreateStudyMember, useStudyRoles } from "../../../../services";
import { StudyRoleEnum, useAllowedEmailDomains } from "../../../../shared";
import { StudyRolesFormItem } from "./StudyRolesFormItem";

interface ICreateStudyMemberModalProps {
  studyId: string;
  open: boolean;
  onCancel: () => void;
}

export function CreateStudyMemberModal({ studyId, open, onCancel }: ICreateStudyMemberModalProps) {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { validationRule } = useAllowedEmailDomains(studyId);
  const { createStudyMember, loading } = useCreateStudyMember({
    onCompleted: () => onCancel(),
  });
  const { studyRoles, loading: studyRolesLoading } = useStudyRoles(studyId);
  const participantManagerRole = studyRoles?.find(
    (role) => role.name === StudyRoleEnum.PARTICIPANT_MANAGER,
  );

  const handleOnFormFinish = (values: any) => {
    createStudyMember({
      studyId,
      email: values.email,
      roleId: values.roleId,
    });
  };

  return (
    <Modal
      title={t("study.personnel.createMemberModal.title")}
      closable
      open={open}
      onCancel={onCancel}
      confirmLoading={loading}
      onOk={() => form.submit()}
      okText={t("common.ok")}
      // @ts-ignore
      okButtonProps={{ "data-id": "common.ok" }}
      cancelText={t("common.cancel")}
      // @ts-ignore
      cancelButtonProps={{ "data-id": "common.cancel" }}
      afterClose={() => form.resetFields()}
    >
      <Spin spinning={studyRolesLoading} tip={t("common.loading")}>
        <Form
          form={form}
          onFinish={handleOnFormFinish}
          initialValues={{ roleId: participantManagerRole?.id }}
        >
          <Form.Item
            name={["email"]}
            rules={[
              {
                type: "email",
                required: true,
                message: t("study.personnel.createMemberModal.missingEmail"),
              },
              validationRule,
            ]}
          >
            <Input placeholder={t("study.personnel.createMemberModal.inputPlaceholder")} />
          </Form.Item>
          <StudyRolesFormItem studyId={studyId} />
        </Form>
      </Spin>
    </Modal>
  );
}

export default CreateStudyMemberModal;
