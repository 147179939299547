import { useKeycloak } from "@react-keycloak/web";
import { notification } from "antd";
import { useTranslation } from "react-i18next";
import { useTriggerPasswordUpdateMutation, useUpdateEmailMutation } from "../codegen";

export function useUpdateAccount(userId: string) {
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();
  const [mutateEmail, { loading: updateEmailLoading }] = useUpdateEmailMutation({
    onCompleted: (data) => {
      if (data.updateEmail?.email) {
        keycloak.logout();
      } else {
        notification.error({
          message: t("notifications.updateEmail.error"),
        });
      }
    },
    onError: () => {
      notification.error({
        message: t("notifications.updateEmail.error"),
      });
    },
  });

  const [mutatePassword, { loading: updatePasswordLoading }] = useTriggerPasswordUpdateMutation({
    variables: { id: userId },
    onCompleted: () => {
      keycloak.logout();
    },
  });

  const updateEmail = (email: string) => {
    mutateEmail({ variables: { userId, email } });
  };

  return {
    updateEmailLoading,
    updatePasswordLoading,
    triggerPasswordUpdate: mutatePassword,
    updateEmail,
  };
}

export default useUpdateAccount;
