import { Participant, ParticipantOutput, useGetExecutorParticipantsQuery } from "../codegen";

export function useExecutorParticipants(therapyId: string) {
  const { data, loading } = useGetExecutorParticipantsQuery({
    variables: { therapyId },
  });

  return {
    participants: data?.getParticipantsAction.filter(
      (participant) => participant.participant !== null,
    ) as ExecutorParticipant[] | undefined,
    loading,
  };
}

export default useExecutorParticipants;

export type ExecutorParticipant = NonNullable<ParticipantOutput> & {
  participant: NonNullable<Participant>;
};
