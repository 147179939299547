// import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

import React from "react";
import { useTranslation } from "react-i18next";
import { Loader } from "../context";

export function Loading() {
  const { t } = useTranslation();

  return (
    <div
      style={{
        width: "100vw",
        height: "100vH",
        paddingTop: "40vH",
        textAlign: "center",
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 100,
      }}
    >
      <Spin
        //  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        tip={t("common.loading")}
      />
    </div>
  );
}

interface ILoadingHandlerProps {
  loading: boolean;
  children: React.ReactNode;
}

export function LoadingHandler({ loading, children }: ILoadingHandlerProps) {
  if (loading) {
    return <Loader />;
  }

  return <div>{children}</div>;
}
