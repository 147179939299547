import React from "react";
import { Form, Checkbox, Space } from "antd";
import { useTranslation } from "react-i18next";

import { IBaseFormItemProps, ITherapyConfigInputProps } from "./TherapyConfigFormItem";

export function BooleanFormItem({ editing, name, executorValidationRule }: IBaseFormItemProps) {
  return (
    <Form.Item name={name} rules={[executorValidationRule]}>
      <BooleanInput editing={editing} />
    </Form.Item>
  );
}

function BooleanInput({
  editing,
  value: therapyVariableConfigValue,
  onChange,
}: ITherapyConfigInputProps) {
  const { t } = useTranslation();
  const valueToBoolean = Boolean(JSON.parse(therapyVariableConfigValue.value));
  return (
    <Space>
      <Checkbox
        disabled={!editing}
        checked={valueToBoolean}
        onChange={(e) => {
          if (onChange)
            onChange({
              ...therapyVariableConfigValue,
              value: e.target.checked,
            });
        }}
      >
        {t("immerse.activateItem")}
      </Checkbox>
    </Space>
  );
}

export default BooleanFormItem;
