import { Button, ButtonProps, Form, FormInstance } from "antd";
import React from "react";

interface SubmitButtonProps extends ButtonProps {
  formInstance: FormInstance;
}

/**
 * Submit button that is disabled until the form is valid.
 */
export function SubmitButton({ formInstance, children, ...rest }: SubmitButtonProps) {
  const [submittable, setSubmittable] = React.useState(false);

  // Watch all values
  const values = Form.useWatch([], formInstance);

  React.useEffect(() => {
    if (values) {
      formInstance.validateFields({ validateOnly: true }).then(
        () => {
          setSubmittable(true);
        },
        () => {
          setSubmittable(false);
        },
      );
    }
  }, [values]);

  return (
    <Button {...rest} htmlType="submit" disabled={!submittable}>
      {children}
    </Button>
  );
}

export default SubmitButton;
