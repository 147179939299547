import { useValidateParticipantConfig } from "../../../../services";

export interface IFieldData {
  variableId: string;
  value: any;
}

/**
 * Hook to validate participant therapy config
 *
 * @param participantId
 * @returns
 */
export function useValidateParticipantTherapyConfig(participantId: string) {
  const { validate, validationResult } = useValidateParticipantConfig();

  const validateVariableValue = async ({
    values,
  }: {
    values: IFieldData[];
    variableId: string;
  }) => {
    const ans = await validate({ participantId, values });
    const validationResults = ans.data?.validateParticipantTherapyConfigAction.results;

    return validationResults || undefined;
  };

  return {
    validationInfoText: validationResult?.validationInfoItem,
    validateVariableValue,
  };
}

export default useValidateParticipantTherapyConfig;
