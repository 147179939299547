import { flatten } from "array-flatten";
import { addObjectField } from ".";
import { ITherapyElement } from "../services";

export type NestedArray<T> = Array<T> | Array<NestedArray<T>>;

interface IListHeadingsAndElementIdsProps {
  [key: string]: string | IListHeadingsAndElementIdsProps;
}

/**
 * Helper for sorting therapy elements according to the hirachy defined in their title.
 * The hirachy is expected to be defined as "firstLevel:secondLevel:...:title" (headings, with ":" as separator)
 * Returns an array that contains string and TherapyElement values. The string values represent
 * headings e.g. "firstLevel" that could be used to structure the content (e.g. for placing anchors)
 *
 * @param therapyElements
 * @returns
 */
export function sortTherapyElementsByTitle(therapyElements: ITherapyElement[]) {
  return sortElementsByTitle<ITherapyElement>(therapyElements);
}

export function sortElementsByTitle<T>(elements: { id: string; title: string }[]) {
  const structure: IListHeadingsAndElementIdsProps = {};
  elements.forEach((element) =>
    addObjectField({
      object: structure,
      path: element.title,
      delimiter: ":",
      value: element.id,
    }),
  );

  const headingsAndElementIds = listHeadingsAndElementIds(structure);

  return headingsAndElementIds.map((headingOrElementId) => {
    const therapyElement = elements.find((element) => element.id === headingOrElementId);
    if (therapyElement) {
      return therapyElement;
    }
    const heading = headingOrElementId;
    return heading;
  }) as (string | T)[];
}

/**
 * Utility for sortTherapyElementsByTitle. Converts the object structure into an array
 * of string values representing either a heading or a therapy element ID. This forms the
 * basis to enable the insertion of anchors for headings in the right place
 *
 * @param config
 * @returns
 */
export function listHeadingsAndElementIds(config: IListHeadingsAndElementIdsProps) {
  const elementsIds: (string | NestedArray<string>)[] = Object.keys(config).map((key) => {
    if (typeof config[key] !== "string" && config[key] !== null) {
      return [
        key,
        listHeadingsAndElementIds(config[key] as IListHeadingsAndElementIdsProps),
      ] as NestedArray<string>;
    }
    return config[key] as string;
  });
  return flatten(elementsIds);
}

export default sortTherapyElementsByTitle;
