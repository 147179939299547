import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Input, Button, Collapse, Typography, Space } from "antd";
import { useForm } from "antd/lib/form/Form";
import { UserFromQuery, useUpdateAvatar, useUpdateProfile } from "../../services";
import { UpdateProfilePicture } from "../../shared";

const layout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 20,
  },
};
const buttonLayout = {
  wrapperCol: {
    offset: 21,
    span: 3,
  },
};
const { Title, Text } = Typography;
const { Panel } = Collapse;
interface IProfileSettingsProps {
  user: UserFromQuery;
}
export function ProfileSettings({ user }: IProfileSettingsProps) {
  const [hasChanges, setHasChanges] = useState(false);
  const { updateProfile, loading: updateProfileLoading } = useUpdateProfile({});
  const { updateAvatar } = useUpdateAvatar({});
  const { t } = useTranslation();
  const [form] = useForm();
  const onFinish = (values: any) => {
    updateProfile({
      userId: user.id,
      firstName: values.firstName,
      lastName: values.lastName,
      title: values.title,
    });
  };
  const handleProfilePictureChange = (base64Img: string) => {
    updateAvatar({
      userId: user.id,
      base64Img,
    });
  };

  return (
    <Collapse
      expandIconPosition="right"
      bordered={false}
      style={{ backgroundColor: "white" }}
      defaultActiveKey={["1", "2"]}
    >
      <Panel
        header={
          <>
            <Title level={4} style={{ marginTop: 0 }}>
              {t("user.profile.mainSettings.title")}
            </Title>
            <Text>{t("user.profile.mainSettings.description")}</Text>
          </>
        }
        key="1"
      >
        <Form
          form={form}
          style={{ paddingTop: 24 }}
          {...layout}
          name="basic"
          initialValues={{
            title: user.title,
            firstName: user.firstName,
            lastName: user.lastName,
          }}
          onFinish={onFinish}
          onValuesChange={() => setHasChanges(true)}
        >
          <Form.Item name="title" label={t("user.title")} rules={[{ required: false }]}>
            <Input />
          </Form.Item>
          <Form.Item name="firstName" label={t("user.firstName")} rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="lastName" label={t("user.lastName")} rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item {...buttonLayout}>
            <Space style={{ float: "right" }}>
              <Button
                disabled={!hasChanges}
                hidden={!hasChanges}
                style={{ float: "left" }}
                onClick={() => {
                  form.resetFields();
                  setHasChanges(false);
                }}
              >
                {t("common.cancel")}
              </Button>
              <Button
                style={{ float: "left" }}
                type="primary"
                htmlType="submit"
                disabled={!hasChanges}
                loading={updateProfileLoading}
                onClick={() => {
                  form.submit();
                  setHasChanges(false);
                }}
              >
                {t("common.save")}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Panel>
      <Panel
        header={
          <>
            <Title level={4} style={{ marginTop: 0 }}>
              {t("user.avatar.title")}
            </Title>
            <Text>{t("user.avatar.description")}</Text>
          </>
        }
        key="2"
      >
        <UpdateProfilePicture
          imgBase64={user.avatar ? user.avatar : ""}
          onImageChange={handleProfilePictureChange}
          type="avatar"
        />
      </Panel>
    </Collapse>
  );
}

export default ProfileSettings;
