/* eslint-disable react/require-default-props */
/* eslint-disable camelcase */

import React, { CSSProperties, useState } from "react";
import { useTranslation } from "react-i18next";
import { RiUserHeartLine } from "react-icons/ri";
import Highlighter from "react-highlight-words";
import { Link } from "react-router-dom";
import { Col, List, Modal, Row, Space, Typography } from "antd";
import { StudyRoleTag } from "../others/Tag";
import {
  defaultListItemStyle,
  highlighterColor,
  listItemTitleFontWeight,
  primaryColor,
} from "../layout";

import {
  usePermission,
  ActionEnum,
  useStudyLazy,
  useGetParticipantCountOfStudyQuery,
} from "../../services";
import { StudyLogo } from "../others/StudyLogo";

import { useGroupVariableConfigsLazy } from "../../services/graphql/hooks/useGroupVariableConfig";
import { Paths } from "../routes";

const { Paragraph, Text, Title } = Typography;

interface IStudyListItemsProps {
  studyId: string;
  studyName: string;
  studyDescription?: string | null;

  studyLogo?: string;
  studyCreationDate?: any;
  organizationName?: string;
  studyRole?: string;
  disableLink?: boolean;
  hideOrganizationName?: boolean;
  searchStringToHighlight?: string;
  modalVisibility?: boolean;
}

const listItemStyle: CSSProperties = {
  ...defaultListItemStyle,
  minHeight: 64,
};

export function StudyListItem({
  studyId,
  studyName,
  studyDescription,
  studyLogo,
  studyRole,
  organizationName,
  disableLink,
  searchStringToHighlight,
  hideOrganizationName,
  modalVisibility = false,
  studyCreationDate,
}: IStudyListItemsProps) {
  const [style, setStyle] = useState(listItemStyle);
  const { data } = useGetParticipantCountOfStudyQuery({
    variables: { studyId },
  });

  const { t } = useTranslation();

  // Following hooks are only for precaching
  const { fetchStudy } = useStudyLazy();
  const { fetchGroupVariableConfigs } = useGroupVariableConfigsLazy();

  const [showDetailsModal, setShowDetailsModal] = useState(modalVisibility);
  usePermission({ action: ActionEnum.STUDY_VIEW_DETAILS, studyId });
  const creationDate = new Date(studyCreationDate);

  const precacheStudyData = () => {
    fetchStudy(studyId);
    fetchGroupVariableConfigs(studyId);
  };

  const item = (
    <>
      <List.Item
        style={style}
        key={studyId}
        onMouseEnter={() => {
          setStyle({ ...listItemStyle, borderColor: primaryColor });
          precacheStudyData();
        }}
        onMouseLeave={() => setStyle(listItemStyle)}
        onClick={() => setShowDetailsModal(true)}
        actions={[
          <Space>
            <RiUserHeartLine size={20} />
            {data?.aggregateParticipants.aggregate ? data.aggregateParticipants.aggregate.count : 0}
          </Space>,
        ]}
      >
        <List.Item.Meta
          avatar={<StudyLogo src={studyLogo} studyTitle={studyName} />}
          title={
            <Text style={{ color: primaryColor, fontWeight: listItemTitleFontWeight }}>
              <Highlighter
                highlightStyle={{ background: highlighterColor }}
                searchWords={[searchStringToHighlight || ""]}
                autoEscape
                textToHighlight={
                  hideOrganizationName ? studyName : `${studyName} - ${organizationName}`
                }
              />
            </Text>
          }
          description={
            <Paragraph style={{ maxWidth: "90%", textAlign: "justify" }} ellipsis={{ rows: 2 }}>
              <Highlighter
                highlightStyle={{ background: highlighterColor }}
                searchWords={[searchStringToHighlight || ""]}
                autoEscape
                textToHighlight={studyDescription || ""}
              />
            </Paragraph>
          }
        />
        <StudyRoleTag role={studyRole} />
      </List.Item>
      <Modal
        title={
          <Space>
            <StudyLogo src={studyLogo} studyTitle={studyName} />
            <Title level={4} style={{ display: "inline" }}>
              {studyName}
            </Title>
          </Space>
        }
        open={showDetailsModal}
        onOk={() => setShowDetailsModal(false)}
        onCancel={() => setShowDetailsModal(false)}
        destroyOnClose
        cancelButtonProps={{ style: { visibility: "hidden" } }}
        width={600}
      >
        <Paragraph>
          <Text strong>{t("study.listItem.description.title")}</Text>
          <br />
          {studyDescription || <i>- {t("study.listItem.description.noDescription")} -</i>}
        </Paragraph>

        <Paragraph>
          <Row justify="space-between">
            <Col span={7}>
              <div>
                <Text type="secondary">{t("study.listItem.description.createdAt")}</Text>
                <br />
                <Text strong>{creationDate.toLocaleDateString()}</Text>
              </div>
            </Col>

            <Col span={7}>
              <div>
                <Text type="secondary">{t("study.listItem.description.numOfParticipants")}</Text>
                <br />
                <Space>
                  <RiUserHeartLine size={20} />
                  <Text strong>
                    {data?.aggregateParticipants.aggregate
                      ? data.aggregateParticipants.aggregate.count
                      : 0}
                  </Text>
                </Space>
              </div>
            </Col>
          </Row>
        </Paragraph>
      </Modal>
    </>
  );

  if (disableLink) {
    return item;
  }

  return <Link to={Paths.getStudyPaths(studyId).OVERVIEW}>{item}</Link>;
}

export default StudyListItem;
