import { Form, Input, Modal, Select } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  useCreateSupervisorByMail,
  useSupervisorsOfParticipant,
  useSupervisors,
} from "../../../services/graphql";
import { AddSupervisorsFormList } from "../../../shared";
import useAllowedEmailDomains from "../../../shared/hooks/useAllowedEmailDomains";
import { SupervisorAssignmentList } from "./SuperVisorAssignmentList";

export interface IFinishEditAssignments {
  numOfAssignmentsAdded: number;
  numOfAssignmentsRemoved: number;
}

interface ICreateSupervisorModalProps {
  supervisorRoles: { name: string; id: string }[];
  open: boolean;
  onCancel: () => void;
  onFinish: () => void;
}

interface ICreateSupervisorsModalProps extends ICreateSupervisorModalProps {
  studyId: string;
}

interface ICreateParticipantSupervisorModalProps extends ICreateSupervisorsModalProps {
  participantId: string;
}

export function CreateSupervisorsModal({
  studyId,
  open,
  supervisorRoles,
  ...props
}: ICreateSupervisorsModalProps) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { supervisors } = useSupervisors(studyId);
  const { createSupervisor, loading } = useCreateSupervisorByMail({});
  const { validationRule } = useAllowedEmailDomains(studyId);

  const handleCancel = () => {
    form.resetFields();
    props.onCancel();
  };

  const handleOnFormFinish = async (values: any) => {
    const participantIds = values.assignments.map((participant: any) => participant.id);
    await createSupervisor({
      participantIds,
      supervisorRoleId: values.supervisorRoleId,
      email: values.email,
      studyId,
    });
    props.onFinish();
  };

  const checkIfSupervisorExists = (email: string) => {
    if (supervisors?.find((supervisor) => supervisor?.userEmail === email)) {
      return true;
    }
    return false;
  };

  return (
    <Modal
      title={t("study.supervisors.createModal.title")}
      closable
      open={open}
      onCancel={handleCancel}
      confirmLoading={loading}
      onOk={() => form.submit()}
      okText={t("common.ok")}
      // @ts-ignore
      okButtonProps={{ "data-id": "common.ok" }}
      cancelText={t("common.cancel")}
      // @ts-ignore
      cancelButtonProps={{ "data-id": "common.cancel" }}
    >
      <Form
        form={form}
        onFinish={handleOnFormFinish}
        initialValues={{ supervisorRoleId: supervisorRoles[0].id }}
      >
        <Form.Item
          name={["email"]}
          rules={[
            {
              type: "email",
              required: true,
              message: t("study.supervisors.createModal.missingEmail"),
            },
            () => ({
              validator(_, value) {
                if (!checkIfSupervisorExists(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(t("participant.createSupervisorModal.alreadyAddedError")),
                );
              },
            }),
            validationRule,
          ]}
        >
          <Input
            onPressEnter={() => form.submit()}
            placeholder={t("study.supervisors.createModal.inputPlaceholder")}
          />
        </Form.Item>
        <Form.Item
          style={{ display: supervisorRoles.length < 2 ? "none" : "block" }}
          name="supervisorRoleId"
          label={t("participant.createSupervisorModal.roleLabel")}
        >
          <Select>
            {supervisorRoles.map((role) => (
              <Select.Option key={role.id} value={role.id}>
                {role.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name={["assignments"]}
          rules={[
            {
              required: true,
              message: t("study.supervisors.createModal.missingParticipant"),
            },
          ]}
        >
          <SupervisorAssignmentList studyId={studyId} />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export function CreateSupervisorModal({
  participantId,
  open,
  supervisorRoles,
  onFinish,
  onCancel,
  studyId,
}: ICreateParticipantSupervisorModalProps) {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const { supervisors } = useSupervisorsOfParticipant(participantId);
  const { createSupervisor, loading } = useCreateSupervisorByMail({});

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  const handleOnFormFinish = async (values: any) => {
    values.supervisors?.forEach((email: string) =>
      createSupervisor({
        participantIds: [participantId],
        supervisorRoleId: values.supervisorRoleId,
        email,
        studyId,
      }),
    );
    form.resetFields();
    onFinish();
  };

  const checkIfSupervisorExists = (email: string) => {
    if (supervisors?.find((supervisor) => supervisor?.userEmail === email)) {
      return true;
    }
    return false;
  };

  return (
    <Modal
      title={t("participant.createSupervisorModal.title")}
      closable
      open={open}
      onCancel={handleCancel}
      confirmLoading={loading}
      onOk={() => form.submit()}
      okText={t("common.ok")}
      // @ts-ignore
      okButtonProps={{ "data-id": "common.ok" }}
      cancelText={t("common.cancel")}
      // @ts-ignore
      cancelButtonProps={{ "data-id": "common.cancel" }}
    >
      <Form
        form={form}
        onFinish={handleOnFormFinish}
        initialValues={{ supervisorRoleId: supervisorRoles[0].id, supervisors: [undefined] }}
      >
        <AddSupervisorsFormList
          name="supervisors"
          studyId={studyId}
          minNumOfSupervisors={1}
          additionalValidationRule={() => ({
            validator(_, value) {
              if (!checkIfSupervisorExists(value)) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error(t("participant.createSupervisorModal.alreadyAddedError")),
              );
            },
          })}
          addButtonText={t("participant.createSupervisorModal.moreButton")}
          label={null}
        />
        <Form.Item
          style={{ display: supervisorRoles.length < 2 ? "none" : "block" }}
          name="supervisorRoleId"
          label={t("participant.createSupervisorModal.roleLabel")}
        >
          <Select>
            {supervisorRoles.map((role) => (
              <Select.Option key={role.id} value={role.id}>
                {role.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
}
