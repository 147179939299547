import React from "react";
import UserSider, { IUserSiderProps } from "./UserSider";
import { IPageContentProps, PageContainer } from "../../../shared";

interface IUserProps extends IUserSiderProps, IPageContentProps {}

export function UserContainer(props: IUserProps) {
  const { children } = props;
  return (
    <PageContainer sider={<UserSider {...props} />} {...props}>
      {children}
    </PageContainer>
  );
}

export default UserContainer;
