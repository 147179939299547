import { Divider, Space, Typography } from "antd";
import DOMPurify from "dompurify";
import React from "react";
import { VisualizationObject } from "../../../../../services";

import MultiSpecVegaVisualization from "./MultiSpecVegaVisualization";
import MultiVisualizationContainer from "./MultiVisualizationContainer";
import TableVisualization from "./TableVisualization";
import UniversalSelectVegaVisualization from "./UniversalSelectVegaVisualization";
import VegaContainer from "./VegaContainer";

export interface IVisualizationProps {
  visualizationObject: VisualizationObject;
  downloadFileName: string;
}
const { Title } = Typography;

/**
 * Wrapper that selects the appropriate visualization depending on the visualization type
 */

export function Visualization({ visualizationObject, downloadFileName }: IVisualizationProps) {
  switch (visualizationObject.type) {
    case "VegaVisualization":
      return (
        <VegaContainer
          showNoDataOverlay={visualizationObject.showNoDataOverlay}
          spec={visualizationObject.vegaSpec}
          downloadFileName={downloadFileName}
          data={visualizationObject.vegaData}
          {...visualizationObject.vegaRendererProps}
        />
      );

    case "UniversalSelectVegaVisualization":
      return (
        <UniversalSelectVegaVisualization
          {...visualizationObject}
          downloadFileName={downloadFileName}
        />
      );

    case "MultiSpecVegaVisualization":
      return (
        <MultiSpecVegaVisualization {...visualizationObject} downloadFileName={downloadFileName} />
      );

    case "TableVisualization":
      return <TableVisualization {...visualizationObject} downloadFileName={downloadFileName} />;

    case "VisualizationObjectContainer":
      return (
        <Space direction="vertical">
          {visualizationObject.children.map((visualization) => (
            <div key={visualization.id}>
              <Divider />
              <Title level={5}>{visualization.title.split(":").slice(-1)}</Title>
              <p
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(visualization.description),
                }}
              />
              <Visualization
                visualizationObject={visualization}
                downloadFileName={downloadFileName}
              />
            </div>
          ))}
        </Space>
      );
    case "MultiVisualizationContainer":
      return <MultiVisualizationContainer {...visualizationObject} />;

    default:
      return <div>VisualizationType not implemented</div>;
  }
}

export default Visualization;
