import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Typography, Divider, List, Empty, Button, Alert } from "antd";
import { ParticipantsGroupListItem, CreateParticipantsGroupModal } from "./components";

import { usePermission, ActionEnum } from "../../../services";
import {
  LoadingHandler,
  PageHeader,
  SearchField,
  useCachedStudy,
  useStudyId,
} from "../../../shared";
import { sortingHepler } from "../../../utils";

import { ParticipantsGroupFromQuery } from "../../../services/graphql";

const { Text } = Typography;

export function ParticipantsGroups() {
  const { t } = useTranslation();
  const { studyId } = useStudyId();
  const { study } = useCachedStudy();
  const [searchResult, setSearchResult] = useState({
    searchText: "",
    items: [] as ParticipantsGroupFromQuery[],
  });
  const [isAddGroupModalVisible, setIsAddGroupModalVisible] = useState(false);

  const { loading: canCreateGroupsLoading, permitted: canCreateGroups } = usePermission({
    action: ActionEnum.STUDY_CREATE_GROUP,
    studyId,
  });
  const { loading: canEditGroupsLoading, permitted: canEditGroups } = usePermission({
    action: ActionEnum.STUDY_EDIT_GROUP,
    studyId,
  });
  const { loading: canDeleteGroupsLoading, permitted: canDeleteGroups } = usePermission({
    action: ActionEnum.STUDY_DELETE_GROUP,
    studyId,
  });

  const participantsGroups = study.participantGroups;

  const loading = canCreateGroupsLoading || canEditGroupsLoading || canDeleteGroupsLoading;

  const sortItems = (items: ParticipantsGroupFromQuery[]) => {
    items.sort((a, b) => {
      const valueA = a.name;
      const valueB = b.name;

      return sortingHepler({ valueA, valueB, order: "ascending" });
    });
    return items;
  };

  const filter = (studyGroupsToFilter: ParticipantsGroupFromQuery[]) => {
    const filterForSearchResult = studyGroupsToFilter.filter((group) =>
      searchResult.items.find((item) => item.id === group.id),
    );
    return sortItems(filterForSearchResult);
  };

  return (
    <LoadingHandler loading={loading}>
      <PageHeader
        title={t("study.participantsGroups.title")}
        description={t("study.participantsGroups.description")}
        hideDivider
        style={{ marginBottom: 24, padding: 0 }}
      >
        {participantsGroups.length === 0 ? (
          <Alert
            style={{ marginTop: 12 }}
            message={t("study.participantsGroups.noGroupAlert.title")}
            description={<Text>{t("study.participantsGroups.noGroupAlert.description")}</Text>}
            type="info"
            showIcon
            closable
          />
        ) : null}
      </PageHeader>

      <Button
        data-id="study.participantsGroups.createGroupButton"
        style={{ float: "right" }}
        type="primary"
        disabled={!canCreateGroups}
        onClick={() => setIsAddGroupModalVisible(true)}
      >
        {t("study.participantsGroups.createGroupButton")}
      </Button>

      <SearchField
        allItems={participantsGroups.map((group) => ({
          item: group,
          searchFields: group.description ? [group.name, group.description] : [group.name],
        }))}
        placeholderText={t("study.participantsGroups.searchPlaceholder")}
        onSearch={setSearchResult}
        style={{ float: "left", width: 240, marginBottom: 16 }}
      />

      <Divider style={{ marginBottom: 0 }} />

      <List<ParticipantsGroupFromQuery>
        itemLayout="horizontal"
        size="small"
        pagination={{
          hideOnSinglePage: true,
          pageSize: 10,
        }}
        dataSource={filter(participantsGroups)}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t("study.participantsGroups.noGroups")}
            />
          ),
        }}
        renderItem={(item) => (
          <ParticipantsGroupListItem
            searchStringToHighlight={searchResult.searchText}
            groupId={item.id}
            studyId={studyId}
            name={item.name}
            description={item.description ? item.description : ""}
            numberOfParticipants={item.participants.length}
            hideRemove={!canDeleteGroups}
            hideEdit={!canEditGroups}
          />
        )}
      />

      <CreateParticipantsGroupModal
        studyId={studyId}
        open={isAddGroupModalVisible}
        onCancel={() => setIsAddGroupModalVisible(false)}
      />
    </LoadingHandler>
  );
}

export default ParticipantsGroups;
