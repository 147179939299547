import React from "react";

import { usePermission, ActionEnum } from "../../../services";

import { ParticipantsGroupsTherapyConfigContent } from "./components";
import { LoadingHandler, useEditableTherapyElements, useCachedStudy } from "../../../shared";
import { useGroupVariableConfigs } from "../../../services/graphql/hooks/useGroupVariableConfig";

export function ParticipantGroupsTherapyConfig() {
  const { study } = useCachedStudy();
  const studyId = study.id;

  const { editableTherapyElements, loading: therapyDesignLoading } = useEditableTherapyElements({
    studyId,
    configurationLevel: "GROUP",
  });

  const { groupVariableConfigs, loading: groupVariablesConfigLoading } =
    useGroupVariableConfigs(studyId);

  const { loading: canEditGroupTherapyConfigLoading, permitted: canEditGroupTherapyConfig } =
    usePermission({ action: ActionEnum.STUDY_EDIT_GROUP_THERAPY_CONFIG, studyId });

  const loading =
    canEditGroupTherapyConfigLoading || groupVariablesConfigLoading || therapyDesignLoading;

  return (
    <LoadingHandler loading={loading}>
      <ParticipantsGroupsTherapyConfigContent
        studyId={studyId}
        hasPermissionToEdit={canEditGroupTherapyConfig}
        groups={study.participantGroups}
        elementsWithEditableVariables={editableTherapyElements}
        groupVariableAssignment={groupVariableConfigs}
      />
    </LoadingHandler>
  );
}

export default ParticipantGroupsTherapyConfig;
