import React from "react";
import { useTranslation } from "react-i18next";

export interface INoDataOverlayContainerProps {
  showNoDataOverlay: Boolean;
  children: React.ReactNode;
}

/**
 * Container that renders an overlay with a message if there is no data to display.
 *
 */
function NoDataOverlayContainer({ showNoDataOverlay, children }: INoDataOverlayContainerProps) {
  const { t } = useTranslation();
  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          opacity: showNoDataOverlay ? 0.4 : 1,
        }}
      >
        {children}
      </div>

      {showNoDataOverlay && (
        <div
          style={{
            position: "absolute",
            top: "30%",
            left: 0,
            right: 0,
            textAlign: "center",
            fontSize: "medium",
            fontStyle: "italic",

            fontWeight: 600,
          }}
        >
          <span style={{ backgroundColor: "white", opacity: 0.9, padding: 8, borderRadius: 2 }}>
            {t("vegaContainer.noData")}
          </span>
        </div>
      )}
    </div>
  );
}

export default NoDataOverlayContainer;
