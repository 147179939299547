import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Divider, Button, List, Empty } from "antd";
import {
  usePermission,
  ActionEnum,
  useSupervisorsOfParticipant,
  useSupervisorRoles,
  GetSupervisorsOfParticipantQuery,
} from "../../../services";
import { ParticipantSupervisorListItem } from "./components";

import { Loader, PageHeader, SearchField, useParticipantId, useStudyId } from "../../../shared";

import { CreateSupervisorModal } from "../components";

type Supervisor = GetSupervisorsOfParticipantQuery["getSupervisorsOfParticipantAction"][number];

export function ParticipantSupervisors() {
  const { studyId } = useStudyId();
  const { participantId } = useParticipantId();
  const [searchResult, setSearchResult] = useState({ searchText: "", items: [] as Supervisor[] });
  const [isAddSupervisorModalVisible, setIsAddSupervisorModalVisible] = useState(false);
  const { t } = useTranslation();
  const { supervisorRoles, loading: getSupervisorRolesLoading } = useSupervisorRoles(studyId);
  const {
    supervisors,
    loading: getSupervisorsLoading,
    refetchSupervisors,
  } = useSupervisorsOfParticipant(participantId);

  const { permitted: canAddSupervisor, loading: canAddSupervisorLoading } = usePermission({
    action: ActionEnum.PARTICIPANT_CREATE_SUPERVISOR,
    participantId,
  });

  const loading = canAddSupervisorLoading || getSupervisorsLoading || getSupervisorRolesLoading;

  const handleOnAddSupervisorFinished = () => {
    refetchSupervisors();
    setIsAddSupervisorModalVisible(false);
  };

  const filter = (supervisorsToFilter: Supervisor[]) =>
    supervisorsToFilter.filter((supervisor) =>
      searchResult.items.find((item) => item.userId === supervisor.userId),
    );

  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <div style={{ maxWidth: 1000, margin: "auto" }}>
        <PageHeader
          title={t("participant.supervisors.title")}
          style={{ marginBottom: 24, padding: 0 }}
          description={t("participant.supervisors.description")}
          hideDivider
        />

        <Button
          style={{ float: "right" }}
          type="primary"
          disabled={!canAddSupervisor}
          onClick={() => setIsAddSupervisorModalVisible(true)}
        >
          {t("participant.supervisors.addButtonText")}
        </Button>

        <SearchField
          allItems={supervisors.map((supervisor) => ({
            item: supervisor,
            searchFields: [
              `${supervisor.userFirstName} ${supervisor.userLastName}`,
              `${supervisor.userLastName} ${supervisor.userFirstName}`,
              supervisor.userEmail,
            ],
          }))}
          placeholderText={t("participant.supervisors.searchPlaceholder")}
          onSearch={setSearchResult}
          style={{ float: "left", width: 240, marginBottom: 16 }}
        />

        <Divider style={{ marginBottom: 0 }} />

        <List<Supervisor>
          itemLayout="horizontal"
          size="small"
          pagination={{
            hideOnSinglePage: true,
            pageSize: 5,
          }}
          dataSource={filter(supervisors)}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={t("participant.supervisors.noSupervisor")}
              />
            ),
          }}
          renderItem={(item) => (
            <ParticipantSupervisorListItem
              searchStringToHighlight={searchResult.searchText}
              participantId={participantId}
              avatar={item.userAvatar}
              email={item.userEmail}
              firstName={item.userFirstName}
              lastName={item.userLastName}
              memberId={item.userId}
              studyId={studyId}
              supervisorRoleId="TO_DO"
            />
          )}
        />
      </div>

      <CreateSupervisorModal
        onCancel={() => setIsAddSupervisorModalVisible(false)}
        onFinish={() => handleOnAddSupervisorFinished()}
        participantId={participantId}
        studyId={studyId}
        open={isAddSupervisorModalVisible}
        supervisorRoles={supervisorRoles.map((role) => ({ id: role.id, name: role.name }))}
      />
    </>
  );
}

export default ParticipantSupervisors;
