import React, { useState } from "react";
import { App } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { EditOrganizationMemberModal } from "./EditOrganizationMemberModal";
import { usePermission, ActionEnum, useDeleteOrganizationMember } from "../../../services";
import {
  getOrganizationRoleLabelColor,
  MemberListItem,
  IMemberProps,
  useUserId,
} from "../../../shared";

export interface IOrganizationMemberListItemProps extends IMemberProps {
  memberUserId: string;
  onRemoveMember?: (id: string) => void; // eslint-disable-line no-unused-vars
  orgaId: string;
  roleId: string;
  roleName: string;
}

export function OrganizationMemberListItem(props: IOrganizationMemberListItemProps) {
  const {
    email,
    firstName,
    lastName,
    memberId,
    memberUserId,
    onRemoveMember = () => {},
    orgaId,
    roleId,
    roleName,
  } = props;
  const { t } = useTranslation();
  const { modal } = App.useApp();
  const { userId: loggedInUserId } = useUserId();
  const [isEditMemberModalVisible, setIsEditMemberModalVisible] = useState(false);
  const { deleteOrganizationMember } = useDeleteOrganizationMember({
    onCompleted: () => onRemoveMember(memberId),
  });

  const { permitted: canRemoveMember } = usePermission({
    action: ActionEnum.ORGANIZATION_DELETE_MEMBER,
    organizationId: orgaId,
  });
  const { permitted: canEditMember } = usePermission({
    action: ActionEnum.ORGANIZATION_EDIT_MEMBER_ROLE,
    organizationId: orgaId,
  });

  const userName = firstName ? `${firstName} ${lastName}` : `${email}`;

  const handleOnEditRole = () => {
    setIsEditMemberModalVisible(true);
  };

  const showConfirmDeleteMember = () => {
    modal.confirm({
      cancelText: t("common.no"),
      content: t("organizationMemberListItem.delete.confirm.description"),
      icon: <ExclamationCircleOutlined />,
      okText: t("common.yes"),
      okType: "danger",
      onOk() {
        deleteOrganizationMember({ organizationId: orgaId, userId: memberUserId });
      },
      title: t("organizationMemberListItem.delete.confirm.title", { userName }),
    });
  };

  return (
    <>
      <MemberListItem
        {...props}
        roleId={roleId}
        roleName={roleName}
        roleColor={getOrganizationRoleLabelColor(roleName)}
        disableEdit={!!(loggedInUserId === memberUserId || !canEditMember)}
        hideRemove={!!(loggedInUserId === memberUserId || !canRemoveMember)}
        onRemoveRole={showConfirmDeleteMember}
        onEditRole={handleOnEditRole}
      />

      <EditOrganizationMemberModal
        open={isEditMemberModalVisible}
        onCancel={() => setIsEditMemberModalVisible(false)}
        organizationId={orgaId}
        userName={userName}
        currentRoleId={roleId}
        userId={memberUserId}
      />
    </>
  );
}
