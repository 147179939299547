import { Select } from "antd";
import React, { CSSProperties, useState } from "react";
import { useTranslation } from "react-i18next";
import { ParticipantStateEnum } from "../..";

import TagMultiFilter from "./TagMultiFilter";

const { Option } = Select;

export interface IParticipantStateFilterMultiProps {
  initialValue?: string[];
  style?: CSSProperties;
  // eslint-disable-next-line no-unused-vars
  onChange: (value: string[]) => void;
}

export function ParticipantStateFilterMulti({
  onChange,
  style = {},
  initialValue = [
    ParticipantStateEnum.UNCOUPLED,
    ParticipantStateEnum.WAITING,
    ParticipantStateEnum.PAUSED,
    ParticipantStateEnum.RUNNING,
  ],
}: IParticipantStateFilterMultiProps) {
  const { t } = useTranslation();
  return (
    <TagMultiFilter
      getTagColor={() => "grey"}
      initialValue={initialValue}
      placeholderText={t("study.participants.stateFilterMulti.placeholder")}
      options={[
        ParticipantStateEnum.UNCOUPLED,
        ParticipantStateEnum.WAITING,
        ParticipantStateEnum.PAUSED,
        ParticipantStateEnum.RUNNING,
      ]}
      onChange={onChange}
      style={style}
    />
  );
}

export interface IParticipantStateFilterSingleProps {
  initialValue?: ParticipantStateEnum | "all";
  style?: CSSProperties;
  // eslint-disable-next-line no-unused-vars
  onChange: (value: "all" | ParticipantStateEnum) => void;
}

export function ParticipantStateFilterSingle({
  initialValue = "all",
  onChange,
  style = {},
}: IParticipantStateFilterSingleProps) {
  const [value, setValue] = useState<"all" | ParticipantStateEnum>(initialValue);

  const handleChange = (newValue: any) => {
    setValue(newValue);
    onChange(newValue);
  };

  const options = [
    ParticipantStateEnum.UNCOUPLED,
    ParticipantStateEnum.WAITING,
    ParticipantStateEnum.PAUSED,
    ParticipantStateEnum.RUNNING,
  ];

  return (
    <Select value={value} style={style} onChange={handleChange}>
      <Option value="all">All States</Option>
      {options.map((option) => (
        <Option key={option} value={option}>
          {option}
        </Option>
      ))}
    </Select>
  );
}
