/* eslint-disable no-unused-vars */
import {
  GetSupervisorQuery,
  GetStudyMemberQuery,
  GetRoleOfOrganizationMemberQuery,
} from "../../graphql";

// eslint-disable-next-line no-shadow
export enum ActionEnum {
  ORGANIZATION_CREATE_MEMBER = "ORGANIZATION_CREATE_MEMBER",
  ORGANIZATION_CREATE_STUDY = "ORGANIZATION_CREATE_STUDY",
  ORGANIZATION_DELETE_MEMBER = "ORGANIZATION_DELETE_MEMBER",
  ORGANIZATION_DELETE_ORGANIZATION = "ORGANIZATION_DELETE_ORGANIZATION",
  ORGANIZATION_EDIT_BILLING = "ORGANIZATION_EDIT_BILLING",
  ORGANIZATION_EDIT_MEMBER_ROLE = "ORGANIZATION_EDIT_MEMBER_ROLE",
  ORGANIZATION_EDIT_ORGANIZATION = "ORGANIZATION_EDIT_ORGANIZATION",
  ORGANIZATION_VIEW_DETAILS = "ORGANIZATION_VIEW_DETAILS",
  ORGANIZATION_VIEW_INVOICES = "ORGANIZATION_VIEW_INVOICES",
  ORGANIZATION_VIEW_MEMBERS = "ORGANIZATION_VIEW_MEMBERS",
  ORGANIZATION_VIEW_STUDIES = "ORGANIZATION_VIEW_STUDIES",

  PARTICIPANT_CREATE_SUPERVISOR = "PARTICIPANT_CREATE_SUPERVISOR",
  PARTICIPANT_DELETE = "PARTICIPANT_DELETE",
  PARTICIPANT_DOWNLOAD_DATA = "PARTICIPANT_DOWNLOAD_DATA",
  PARTICIPANT_EDIT_SETTINGS = "PARTICIPANT_EDIT_SETTINGS",
  PARTICIPANT_EDIT_THERAPY_CONFIG = "PARTICIPANT_EDIT_THERAPY_CONFIG",
  PARTICIPANT_VIEW_DETAILS = "PARTICIPANT_VIEW_DETAILS",

  STUDY_CREATE_GROUP = "STUDY_CREATE_GROUP",
  STUDY_CREATE_MEMBER = "STUDY_CREATE_MEMBER",
  STUDY_CREATE_PARTICIPANT = "STUDY_CREATE_PARTICIPANT",
  STUDY_DELETE = "STUDY_DELETE",
  STUDY_DELETE_GROUP = "STUDY_DELETE_GROUP",
  STUDY_DELETE_MEMBER = "STUDY_DELETE_MEMBER",
  STUDY_DOWNLOAD_DATA = "STUDY_DOWNLOAD_DATA",
  STUDY_EDIT = "STUDY_EDIT",
  STUDY_EDIT_GROUP = "STUDY_EDIT_GROUP",
  STUDY_EDIT_GROUP_THERAPY_CONFIG = "STUDY_EDIT_GROUP_THERAPY_CONFIG",
  STUDY_EDIT_MEMBER = "STUDY_EDIT_MEMBER",
  STUDY_VIEW_DETAILS = "STUDY_VIEW_DETAILS",
  STUDY_VIEW_GROUP_ASSIGNMENT = "STUDY_VIEW_GROUP_ASSIGNMENT",
  STUDY_VIEW_GROUP_THERAPY_CONFIG = "STUDY_VIEW_GROUP_THERAPY_CONFIG",
  STUDY_VIEW_GROUPS = "STUDY_VIEW_GROUPS",
  STUDY_VIEW_MEMBERS = "STUDY_VIEW_MEMBERS",
  STUDY_VIEW_PARTICIPANTS = "STUDY_VIEW_PARTICIPANTS",
}

interface ICheckSupervisorPermissions {
  action: ActionEnum;
  roleDefinition: NonNullable<GetSupervisorQuery["supervisor"]>["supervisorRole"];
}

export const checkSupervisorPermissions = ({
  action,
  roleDefinition: permissions,
}: ICheckSupervisorPermissions) => {
  switch (action) {
    case ActionEnum.PARTICIPANT_CREATE_SUPERVISOR: {
      return permissions.participantCreateSupervisor;
    }
    case ActionEnum.PARTICIPANT_DOWNLOAD_DATA: {
      return permissions.participantDownloadData;
    }
    case ActionEnum.PARTICIPANT_EDIT_THERAPY_CONFIG: {
      return permissions.participantEditTherapyConfig;
    }
    case ActionEnum.PARTICIPANT_VIEW_DETAILS: {
      return permissions.participantViewDetails;
    }
    default:
      return false;
  }
};

export interface ICheckOrgaPermissions {
  action: ActionEnum;
  roleDefinition: NonNullable<
    GetRoleOfOrganizationMemberQuery["organizationMember"]
  >["organizationRole"];
}

export const checkOrgaPermissions = ({
  action,
  roleDefinition: permissions,
}: ICheckOrgaPermissions) => {
  switch (action) {
    case ActionEnum.ORGANIZATION_CREATE_MEMBER: {
      return permissions.organizationCreateMember;
    }
    case ActionEnum.ORGANIZATION_CREATE_STUDY: {
      return permissions.organizationCreateStudy;
    }
    case ActionEnum.ORGANIZATION_DELETE_ORGANIZATION: {
      return permissions.organizationDeleteOrganization;
    }
    case ActionEnum.ORGANIZATION_EDIT_BILLING: {
      return permissions.organizationEditBilling;
    }
    case ActionEnum.ORGANIZATION_EDIT_MEMBER_ROLE: {
      return permissions.organizationEditMemberRole;
    }
    case ActionEnum.ORGANIZATION_EDIT_ORGANIZATION: {
      return permissions.organizationEditOrganization;
    }
    case ActionEnum.ORGANIZATION_DELETE_MEMBER: {
      return permissions.organizationDeleteMember;
    }
    case ActionEnum.ORGANIZATION_VIEW_DETAILS: {
      return permissions.organizationViewDetails;
    }
    case ActionEnum.ORGANIZATION_VIEW_INVOICES: {
      return permissions.organizationViewInvoices;
    }
    case ActionEnum.ORGANIZATION_VIEW_MEMBERS: {
      return permissions.organizationViewMembers;
    }
    case ActionEnum.ORGANIZATION_VIEW_STUDIES: {
      return permissions.organizationViewStudies;
    }
    default:
      return false;
  }
};

export interface ICheckStudyPermissions {
  action: ActionEnum;
  roleDefinition: NonNullable<GetStudyMemberQuery["studyMember"]>["studyRole"];
}

export const checkStudyPermissions = ({
  action,
  roleDefinition: permissions,
}: ICheckStudyPermissions) => {
  switch (action) {
    case ActionEnum.STUDY_VIEW_DETAILS: {
      return permissions.studyViewDetails;
    }
    case ActionEnum.STUDY_EDIT: {
      return permissions.studyEdit;
    }
    case ActionEnum.STUDY_DELETE: {
      return permissions.studyDelete;
    }
    case ActionEnum.STUDY_DOWNLOAD_DATA: {
      return permissions.studyDownloadData;
    }
    case ActionEnum.STUDY_VIEW_MEMBERS: {
      return permissions.studyViewMembers;
    }
    case ActionEnum.STUDY_CREATE_MEMBER: {
      return permissions.studyCreateMember;
    }
    case ActionEnum.STUDY_EDIT_MEMBER: {
      return permissions.studyEditMember;
    }
    case ActionEnum.STUDY_DELETE_MEMBER: {
      return permissions.studyDeleteMember;
    }
    case ActionEnum.STUDY_VIEW_GROUPS: {
      return permissions.studyViewGroups;
    }
    case ActionEnum.STUDY_CREATE_GROUP: {
      return permissions.studyCreateGroup;
    }
    case ActionEnum.STUDY_DELETE_GROUP: {
      return permissions.studyDeleteGroup;
    }
    case ActionEnum.STUDY_EDIT_GROUP: {
      return permissions.studyEditGroup;
    }
    case ActionEnum.STUDY_VIEW_GROUP_THERAPY_CONFIG: {
      return permissions.studyViewGroupTherapyConfig;
    }
    case ActionEnum.STUDY_EDIT_GROUP_THERAPY_CONFIG: {
      return permissions.studyEditGroupTherapyConfig;
    }
    case ActionEnum.STUDY_VIEW_GROUP_ASSIGNMENT: {
      return permissions.studyViewGroupAssignment;
    }
    case ActionEnum.STUDY_VIEW_PARTICIPANTS: {
      return permissions.studyViewParticipants;
    }
    case ActionEnum.STUDY_CREATE_PARTICIPANT: {
      return permissions.studyCreateParticipant;
    }
    case ActionEnum.PARTICIPANT_VIEW_DETAILS: {
      return permissions.participantViewDetails;
    }
    case ActionEnum.PARTICIPANT_DELETE: {
      return permissions.participantDelete;
    }
    case ActionEnum.PARTICIPANT_EDIT_SETTINGS: {
      return permissions.participantEditSettings;
    }
    case ActionEnum.PARTICIPANT_DOWNLOAD_DATA: {
      return permissions.participantDownloadData;
    }
    case ActionEnum.PARTICIPANT_EDIT_THERAPY_CONFIG: {
      return permissions.participantEditTherapyConfig;
    }
    case ActionEnum.PARTICIPANT_CREATE_SUPERVISOR: {
      return permissions.participantCreateSupervisor;
    }
    default:
      return false;
  }
};
