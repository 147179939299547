/* eslint-disable camelcase */
/* eslint-disable react/require-default-props */
import React from "react";

import { Tag as AntdTag } from "antd";

import { OrganizationRoleEnum, StudyRoleEnum, StudyStateEnum } from "../enumerations";

interface TagProps {
  text?: string;
  color?: string;
}

export function Tag({ text, color }: TagProps) {
  return <AntdTag color={color}>{text?.toUpperCase()}</AntdTag>;
}

export const getStudyRoleLabelColor = (role?: string) => {
  switch (role) {
    case StudyRoleEnum.OWNER:
      return "red";
    case StudyRoleEnum.ADMIN:
      return "gold";
    case StudyRoleEnum.PARTICIPANT_MANAGER:
      return "blue";
    default:
      return "grey";
  }
};

export const getStudyStateLabelColor = (state: string) => {
  switch (state) {
    case StudyStateEnum.RUNNING:
      return "green";
    case StudyStateEnum.INPREPARATION:
      return "orange";
    case StudyStateEnum.FINISHED:
      return "blue";
    case StudyStateEnum.DELETED:
      return "red";
    default:
      return "grey";
  }
};

export const getOrganizationRoleLabelColor = (role?: string) => {
  switch (role) {
    case OrganizationRoleEnum.OWNER:
      return "cyan";
    case OrganizationRoleEnum.MEMBER:
      return "purple";
    default:
      return "grey";
  }
};

interface IRoleTagProps {
  role: OrganizationRoleEnum;
}

export function OrganizationRoleTag({ role }: IRoleTagProps) {
  return <Tag text={role} color={getOrganizationRoleLabelColor(role)} />;
}

interface IStudyStateTagProps {
  text?: string | number;
  state: StudyStateEnum;
}

export function StudyStateTag({ text = "", state }: IStudyStateTagProps) {
  return <Tag text={`${text} ${state}`} color={getStudyStateLabelColor(state)} />;
}

interface IStudyRoleTagProps {
  role?: string;
}

export function StudyRoleTag({ role }: IStudyRoleTagProps) {
  return role ? <Tag text={`${role}`} color={getStudyRoleLabelColor(role)} /> : null;
}

interface IStudyNameTagProps {
  name?: string;
}

export function StudyNameTag({ name }: IStudyNameTagProps) {
  return name ? <Tag text={name} color="default" /> : null;
}

export default Tag;
