import { ApolloError } from "@apollo/client";
import { notification } from "antd";
import { useTranslation } from "react-i18next";
import { UpdateAvatarMutation, useUpdateAvatarMutation } from "../codegen";

interface IUseUpdateAvatar {
  // eslint-disable-next-line no-unused-vars
  onCompleted?: (data: UpdateAvatarMutation) => void;
  // eslint-disable-next-line no-unused-vars
  onError?: (error: ApolloError) => void;
}

interface IUpdateAvatar {
  userId: string;
  base64Img: string;
}

export function useUpdateAvatar({ onCompleted = () => {}, onError = () => {} }: IUseUpdateAvatar) {
  const { t } = useTranslation();
  const [mutate, { loading }] = useUpdateAvatarMutation({
    onCompleted: (data) => {
      notification.success({
        message: t("notifications.updateProfilePicture.success"),
      });
      onCompleted(data);
    },
    onError: (error) => {
      notification.error({
        message: t("notifications.updateProfilePicture.error"),
      });
      onError(error);
    },
  });

  const updateAvatar = (props: IUpdateAvatar) => {
    mutate({
      variables: {
        userId: props.userId,
        base64Img: props.base64Img,
      },
    });
  };

  return { updateAvatar, loading };
}
export default useUpdateAvatar;
