import SubMenu, { SubMenuProps } from "antd/es/menu/SubMenu";
import React, { ReactNode } from "react";
import {
  IPermissionProps,
  usePermission,
} from "../../services/permissionValidator/PermissionValidator";

export interface ISubMenuWithPermissionProps extends IPermissionProps, SubMenuProps {
  children: ReactNode;
}

export function SubMenuWithPermission(props: ISubMenuWithPermissionProps) {
  const { children, studyId, action, participantId, organizationId, ...rest } = props;
  const { permitted: isPermitted } = usePermission({
    action,
    participantId,
    organizationId,
    studyId,
  });

  return isPermitted ? <SubMenu {...rest}>{children}</SubMenu> : null;
}
