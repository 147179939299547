import React, { ReactNode, useMemo } from "react";

const UserIdContext = React.createContext<{ userId: string } | undefined>(undefined);

interface IUserIdProviderProps {
  userId: string;
  children: ReactNode;
}

function UserIdProvider({ children, userId }: IUserIdProviderProps) {
  const value = useMemo(() => ({ userId }), [userId]);

  return <UserIdContext.Provider value={value}>{children}</UserIdContext.Provider>;
}

function useUserId() {
  const context = React.useContext(UserIdContext);
  if (context === undefined) {
    throw new Error("useUserId must be used within a UserIdProvider");
  }
  return context;
}

export { UserIdProvider, useUserId };
