import React, { ReactNode, useMemo } from "react";

const TherapyDesignIdContext = React.createContext<
  { therapyDesignId: string; studyId: string } | undefined
>(undefined);

interface ITherapyDesignIdProviderProps {
  therapyDesignId: string;
  studyId: string;
  children: ReactNode;
}

function TherapyDesignIdProvider({
  children,
  therapyDesignId,
  studyId,
}: ITherapyDesignIdProviderProps) {
  const value = useMemo(() => ({ therapyDesignId, studyId }), [therapyDesignId, studyId]);

  return (
    <TherapyDesignIdContext.Provider value={value}>{children}</TherapyDesignIdContext.Provider>
  );
}

function useTherapyDesignId() {
  const context = React.useContext(TherapyDesignIdContext);
  if (context === undefined) {
    throw new Error("useTherapyDesignId must be used within a TherapyDesignIdProvider");
  }
  return context;
}

export { TherapyDesignIdProvider, useTherapyDesignId };
