/* eslint-disable react/require-default-props */
import React, { useState } from "react";
import { List, Button, App, Tag, Typography, Tooltip } from "antd";
import Highlighter from "react-highlight-words";
import { GrGroup } from "react-icons/gr";
import { useTranslation } from "react-i18next";

import { ExclamationCircleOutlined } from "@ant-design/icons";
import { EditParticipantsGroupModal } from "./EditParticipantsGroupModal";
import { useDeleteParticipantGroup } from "../../../../services";
import { highlighterColor, listItemTitleFontWeight, primaryColor } from "../../../../shared";

const { Text, Paragraph } = Typography;

interface IParticipantsGroupListItemsProps {
  groupId: string;
  studyId: string;
  name: string;
  description: string;
  numberOfParticipants: number;
  hideRemove?: boolean;
  hideEdit?: boolean;
  searchStringToHighlight?: string;
}

export function ParticipantsGroupListItem({
  groupId,
  studyId,
  name,
  description,
  numberOfParticipants,
  hideRemove = false,
  hideEdit = false,
  searchStringToHighlight = "",
}: IParticipantsGroupListItemsProps) {
  const { t } = useTranslation();
  const { modal } = App.useApp();
  const [isEditGroupModalVisible, setisEditGroupModalVisible] = useState(false);
  const { deleteParticipantGroup } = useDeleteParticipantGroup({ studyId });

  const confirmDelete = () => {
    modal.confirm({
      title: t("common.participantsGroup.deleteModal.title", { groupName: name }),
      icon: <ExclamationCircleOutlined />,
      content: t("common.participantsGroup.deleteModal.description"),
      okText: t("common.yes"),
      okType: "danger",
      cancelText: t("common.no"),
      onOk: () => deleteParticipantGroup(groupId),
    });
  };

  return (
    <List.Item
      style={{ minHeight: 64 }}
      extra={[
        <Tag key="numOfPTag" style={{ marginRight: 24 }} color="blue">
          <b>{numberOfParticipants}</b> {t("common.participant", { count: numberOfParticipants })}
        </Tag>,

        <Button
          data-id="editButton"
          key="editButton"
          style={{ display: hideEdit ? "none" : "unset", marginRight: 10 }}
          onClick={() => setisEditGroupModalVisible(true)}
        >
          {t("common.edit")}
        </Button>,
        <Tooltip
          data-id="deleteButton"
          overlayStyle={{ display: numberOfParticipants > 0 ? "unset" : "none" }}
          destroyTooltipOnHide
          title={t("common.participantsGroup.deleteButtonTooltip")}
          color={primaryColor}
          key="deleteButton"
        >
          <Button
            disabled={numberOfParticipants > 0}
            style={{ display: hideRemove ? "none" : "unset" }}
            onClick={() => confirmDelete()}
          >
            {t("common.delete")}
          </Button>
        </Tooltip>,
      ]}
    >
      <List.Item.Meta
        avatar={<GrGroup />}
        title={
          <Text style={{ fontWeight: listItemTitleFontWeight }}>
            <Highlighter
              highlightStyle={{ background: highlighterColor }}
              searchWords={[searchStringToHighlight || ""]}
              autoEscape
              textToHighlight={name}
            />
          </Text>
        }
        description={
          <Paragraph style={{ textAlign: "justify", marginRight: 24 }}>
            <Highlighter
              highlightStyle={{ background: highlighterColor }}
              searchWords={[searchStringToHighlight || ""]}
              autoEscape
              textToHighlight={description}
            />
          </Paragraph>
        }
      />

      <EditParticipantsGroupModal
        onCancel={() => setisEditGroupModalVisible(false)}
        open={isEditGroupModalVisible}
        groupId={groupId}
        name={name}
        description={description}
      />
    </List.Item>
  );
}

export default ParticipantsGroupListItem;
