import { Descriptions, Typography } from "antd";
import dayjs from "dayjs";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAccount, useUser } from "../../../../../services";
import { useUserId } from "../../../../../shared";

const { Title } = Typography;

export interface IReportHeader {
  analysisStart: string;
  analysisEnd: string;
  participantPseudonym: string;
}

export function ReportHeader({ analysisStart, analysisEnd, participantPseudonym }: IReportHeader) {
  const { t } = useTranslation();
  const { userId } = useUserId();
  const { user } = useUser(userId);
  const { account } = useAccount(userId);

  return (
    <div style={{ marginBottom: 24 }}>
      <Title>{t("study.visualization.print.title", { participantPseudonym })}</Title>
      <Descriptions column={2} bordered>
        <Descriptions.Item label={t("study.visualization.print.analysisStart")}>
          {dayjs(analysisStart).toDate().toLocaleDateString()}
        </Descriptions.Item>
        <Descriptions.Item label={t("study.visualization.print.analysisEnd")}>
          {dayjs(analysisEnd).toDate().toLocaleDateString()}
        </Descriptions.Item>
        <Descriptions.Item span={2} label={t("study.visualization.print.reportDate")}>
          {dayjs().toLocaleString()}
        </Descriptions.Item>
        <Descriptions.Item span={2} label={t("study.visualization.print.generatedBy")}>
          {user?.firstName} {user?.lastName} ({account?.email})
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
}

export default ReportHeader;
