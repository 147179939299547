import { Checkbox, Radio, Space, Typography } from "antd";
import React, { useState } from "react";
import {
  OptionsGroup,
  OptionsMessage,
  UniversalSelectVegaVisualizationProps,
} from "../../../../../services";
import { SelectVegaVisualization } from "./SelectVegaVisualization";

const { Text } = Typography;

/**
 * Adds radio button or checkbox groups to a vegavisualization to switch between signals.
 */
export function UniversalSelectVegaVisualization({
  vegaSpec,
  vegaData,
  vegaRendererProps,
  defaultSelected,
  optionsGroups,
  optionsPosition,
  downloadFileName,
  showNoDataOverlay,
}: UniversalSelectVegaVisualizationProps) {
  const [selectedOption, setSelectedOption] = useState(defaultSelected);

  const messages = Object.entries(selectedOption)
    .map((entry) => {
      const [key, value] = entry;
      return optionsGroups
        .filter((optionsGroup) => optionsGroup.optionValueDataIndex === key)
        .map((optionGroup) =>
          optionGroup.options.filter((option) => {
            if (!option.message) {
              return false;
            }
            if (Array.isArray(value)) {
              return value.includes(option.value);
            }
            return option.value === value;
          }),
        )
        .flat()
        .map((option) => option.message as OptionsMessage);
    })
    .flat();

  /**
   * Determines whether a data point should be filtered. For this purpose, all
   * OptionsGroups are scanned and it is checked whether at least one of them
   * has a correspondingly activated option.
   */
  const showDataPoint = ({
    dataPoint,
    optionGroup,
  }: {
    optionGroup: OptionsGroup;
    dataPoint: any;
  }) => {
    if (optionGroup.selectorType === "radio") {
      return (
        dataPoint[optionGroup.optionValueDataIndex] ===
        selectedOption[optionGroup.optionValueDataIndex]
      );
    }
    if (optionGroup.selectorType === "check") {
      return selectedOption[optionGroup.optionValueDataIndex].includes(
        dataPoint[optionGroup.optionValueDataIndex],
      );
    }
    return false;
  };

  return (
    <SelectVegaVisualization
      optionsPosition={optionsPosition}
      vegaSpec={vegaSpec}
      vegaRendererProps={vegaRendererProps}
      downloadFileName={downloadFileName}
      infoMessages={messages}
      showNoDataOverlay={showNoDataOverlay}
      data={{
        source_data: vegaData.source_data.filter((dataPoint: { [x: string]: string }) =>
          optionsGroups.length === 0
            ? true
            : optionsGroups.some((optionGroup) => showDataPoint({ dataPoint, optionGroup })),
        ),
      }}
    >
      {optionsGroups.map((optionsGroup) => (
        <div key={optionsGroup.name} style={{ marginBottom: 8 }}>
          <Space>
            <Text strong>{optionsGroup.name}</Text>

            {optionsGroup.selectorType === "radio" ? (
              <RadioButtonOptionsGroup
                optionsGroup={optionsGroup}
                value={selectedOption[`${optionsGroup.optionValueDataIndex}`] as string}
                onChange={(e) => setSelectedOption((prev) => ({ ...prev, [e.dataIndex]: e.value }))}
              />
            ) : (
              <CheckboxOptionsGroup
                optionsGroup={optionsGroup}
                value={selectedOption[`${optionsGroup.optionValueDataIndex}`] as string[]}
                onChange={(e) =>
                  setSelectedOption((prev) => ({
                    ...prev,
                    [e.dataIndex]: Array.isArray(prev[e.dataIndex])
                      ? [
                          ...(prev[e.dataIndex] as string[]).filter(
                            (value) =>
                              !optionsGroup.options.some((option) => option.value === value),
                          ),
                          ...e.value,
                        ]
                      : [prev[e.dataIndex] as string, ...e.value],
                  }))
                }
              />
            )}
          </Space>
        </div>
      ))}
    </SelectVegaVisualization>
  );
}

export interface IRadioButtonOptionsGroupProps {
  optionsGroup: OptionsGroup;
  value: string;
  // eslint-disable-next-line no-unused-vars
  onChange: ({ dataIndex, value }: { dataIndex: string; value: string }) => void;
}

export interface ICheckboxOptionsGroupProps {
  optionsGroup: OptionsGroup;
  value: string[];
  // eslint-disable-next-line no-unused-vars
  onChange: ({ dataIndex, value }: { dataIndex: string; value: string[] }) => void;
}

function RadioButtonOptionsGroup({ optionsGroup, value, onChange }: IRadioButtonOptionsGroupProps) {
  return (
    <Radio.Group
      value={value}
      onChange={(e) =>
        onChange({ dataIndex: optionsGroup.optionValueDataIndex, value: e.target.value })
      }
    >
      <Space direction={optionsGroup.optionsOrientation} size="small" wrap>
        {optionsGroup.options.map((option) => (
          <Radio key={option.value} value={option.value}>
            <Space size={3} align="baseline">
              <div style={{ backgroundColor: option.color, width: 10, height: 10 }} />
              {option.name || option.value}
            </Space>
          </Radio>
        ))}
      </Space>
    </Radio.Group>
  );
}

function CheckboxOptionsGroup({ optionsGroup, value, onChange }: ICheckboxOptionsGroupProps) {
  return (
    <Checkbox.Group
      value={value}
      onChange={(e) =>
        onChange({
          dataIndex: optionsGroup.optionValueDataIndex,
          value: e.map((checkbox) => checkbox.valueOf() as string),
        })
      }
    >
      <Space direction={optionsGroup.optionsOrientation} size="small" wrap>
        {optionsGroup.options.map((option) => (
          <Checkbox key={option.value} value={option.value}>
            <Space size={3} align="baseline">
              <div style={{ backgroundColor: option.color, width: 10, height: 10 }} />
              {option.name || option.value}
            </Space>
          </Checkbox>
        ))}
      </Space>
    </Checkbox.Group>
  );
}

export default UniversalSelectVegaVisualization;
