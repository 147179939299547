import { Breadcrumb, Typography } from "antd";
import DOMPurify from "dompurify";
import React from "react";
import { NavHashLink } from "react-router-hash-link";
import { navRouterYOffset } from "../../../../shared";

const { Title } = Typography;

export interface IElementContainerProps {
  id: string;
  title: string;
  hashLinkPrefix: string;
  // eslint-disable-next-line react/require-default-props
  description?: string;
  // eslint-disable-next-line react/require-default-props
  children?: React.ReactNode;
  style?: React.CSSProperties;
}

export function ElementContainer({
  id,
  title,
  hashLinkPrefix,
  description = "",
  children,
  style = {},
}: IElementContainerProps) {
  const splitedTitle = title.split(":");
  return (
    <div
      data-testid={`therapy-config-element-${title}`}
      id={id}
      key={id}
      style={{
        marginBottom: 24,
        padding: 24,
        paddingTop: 12,
        borderStyle: "solid",
        borderColor: "#d9d9d9",
        borderWidth: 1,
        borderRadius: 6,
        ...style,
      }}
    >
      <div style={{ marginBottom: 24 }}>
        {splitedTitle.length > 1 && (
          <Breadcrumb style={{ marginBottom: 16 }}>
            {splitedTitle.map((partTitle) => (
              <Breadcrumb.Item key={partTitle}>
                <NavHashLink
                  scroll={(el) => {
                    const y =
                      el.getBoundingClientRect().top + window.pageYOffset - navRouterYOffset;
                    window.scrollTo({ top: y, behavior: "smooth" });
                  }}
                  smooth
                  to={`${hashLinkPrefix}#${
                    partTitle === splitedTitle.slice(-1)[0] ? id : partTitle
                  }`}
                >
                  {partTitle.replace("_", "")}
                </NavHashLink>
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>
        )}
        <Title style={{ marginTop: 8 }} level={4}>
          {splitedTitle.slice(-1)}
        </Title>
        <p
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(description),
          }}
        />
      </div>
      {children}
    </div>
  );
}

export default ElementContainer;
