import { ApolloError } from "@apollo/client";
import { notification } from "antd";
import { useTranslation } from "react-i18next";
import { useTherapyId } from "..";
import { useUserId } from "../../../shared";
import {
  DeleteParticipantMutation,
  GetExecutorParticipantsDocument,
  GetParticipantsOfStudyDocument,
  GetStudyDocument,
  GetUserDocument,
  useDeleteParticipantMutation,
} from "../codegen";

interface IUseDeleteParticipantProps {
  studyId: string;
  // eslint-disable-next-line no-unused-vars
  onCompleted?: (data: DeleteParticipantMutation) => void;
  // eslint-disable-next-line no-unused-vars
  onError?: (error: ApolloError) => void;
}

export function useDeleteParticipant({
  studyId,
  onCompleted = () => {},
  onError = () => {},
}: IUseDeleteParticipantProps) {
  const { t } = useTranslation();
  const { userId } = useUserId();
  const { therapyId } = useTherapyId(studyId);
  const [mutate, { loading }] = useDeleteParticipantMutation({
    onCompleted: (data) => {
      notification.success({
        message: t("notifications.deleteParticipant.success", {
          pseudonym: data.deleteParticipantAction?.pseudonym,
        }),
      });
      onCompleted(data);
    },
    onError: (error) => {
      notification.error({
        message: t("notifications.deleteParticipant.error"),
      });
      onError(error);
    },
  });

  const deleteParticipant = (participantId: string) => {
    mutate({
      variables: {
        participantId,
      },
      refetchQueries: [
        { query: GetExecutorParticipantsDocument, variables: { therapyId } },
        { query: GetParticipantsOfStudyDocument, variables: { studyId } },
        { query: GetUserDocument, variables: { userId } },
        { query: GetStudyDocument, variables: { id: studyId } },
      ],
    });
  };

  return { deleteParticipant, loading };
}

export default useDeleteParticipant;
