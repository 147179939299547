import { ApolloError } from "@apollo/client";
import { notification } from "antd";
import { useTranslation } from "react-i18next";
import { UpdateApiAccountMutation, useUpdateApiAccountMutation } from "../codegen";

interface IUseUpdateApiApiAccount {
  // eslint-disable-next-line no-unused-vars
  onCompleted?: (data: UpdateApiAccountMutation) => void;
  // eslint-disable-next-line no-unused-vars
  onError?: (error: ApolloError) => void;
}

export interface IUpdateApiAccount {
  id: string;
  serviceName: string;
  expiresAt: string;
}

export const useUpdateApiAccount = ({
  onCompleted = () => {},
  onError = () => {},
}: IUseUpdateApiApiAccount) => {
  const { t } = useTranslation();
  const [mutate, { ...rest }] = useUpdateApiAccountMutation({
    onCompleted: (data) => {
      notification.success({
        message: t("notifications.updateApiAccount.success", {
          serviceName: data.updateApiAccount?.serviceName,
        }),
      });
      onCompleted(data);
    },
    onError: (error) => {
      notification.error({
        message: t("notifications.updateApiAccount.error"),
      });
      onError(error);
    },
  });

  const updateApiAccount = ({ id, serviceName, expiresAt }: IUpdateApiAccount) => {
    mutate({ variables: { id, serviceName, expiresAt } });
  };

  return { updateApiAccount, ...rest };
};
