/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import { useTranslation } from "react-i18next";
import { useWindowWidth } from "@react-hook/window-size";
import { Dropdown, Layout, Menu, Select, Space, Typography } from "antd";
import {
  LogoutOutlined,
  SettingOutlined,
  UserOutlined,
  PlusCircleOutlined,
  MenuOutlined,
  DeploymentUnitOutlined,
  HomeOutlined,
  TeamOutlined,
  ToolOutlined,
} from "@ant-design/icons";
import { ItemType } from "antd/es/menu/hooks/useItems";
import logoTherapyDesigner from "../../assets/logo_white.svg";
import logoInteractionDesigner from "../../assets/logo_white_id.svg";
import { headerHeight, headerStyle, siderWidth } from "../styles";
import HeaderDropDownMenu from "./HeaderDropDownMenu";
import { Paths } from "../../routes";
import { UserFromQuery } from "../../../services";
import { isInInteractionDesignerMode } from "../../../utils";

const { Header } = Layout;
const { Text } = Typography;
const languagesTD = ["en", "de", "nl", "sk"];
const languagesID = ["de", "en"];

const languages = isInInteractionDesignerMode ? languagesID : languagesTD;

export interface IHeaderProps {
  user: UserFromQuery;
}
export enum UserMenuItemEnum {
  ACCOUNT = "user:account",
  PROFILE = "user:profile",
  SIGN_OUT = "user:signOut",
}
export enum OrganizationMenuItemEnum {
  NEW_ORGANIZATION = "organization:newOrganization",
}

interface ISimpleOrganizationRepresentation {
  id: string;
  name: string;
}

export function TBMHeader({ user }: IHeaderProps) {
  const navigate = useNavigate();
  const { keycloak } = useKeycloak();
  const { t, i18n } = useTranslation();
  const windowWidth = useWindowWidth();
  const logo = isInInteractionDesignerMode ? logoInteractionDesigner : logoTherapyDesigner;

  const createOrganizationMenu = (
    organizations: ISimpleOrganizationRepresentation[],
  ): ItemType[] => {
    const organizationItems: ItemType[] = organizations.map((organization) => ({
      key: organization.id,
      label: organization.name,
      onClick: () => navigate(Paths.getOrganizationPaths(organization.id).OVERVIEW),
    }));
    const otherItems: ItemType[] = [
      { type: "divider" },
      {
        key: OrganizationMenuItemEnum.NEW_ORGANIZATION,
        label: t("organization.menu.newOrganization"),
        icon: <PlusCircleOutlined />,
        onClick: () => navigate(Paths.ORGANIZATION_NEW),
      },
    ];
    return organizationItems.concat(otherItems);
  };

  const createOrganizationMenuItem = (organizationRoles: any): React.ReactElement => (
    <Menu.Item
      key={organizationRoles.organization.id}
      onClick={() =>
        navigate(Paths.getOrganizationPaths(organizationRoles.organization.id).OVERVIEW)
      }
    >
      {organizationRoles.organization.name}
    </Menu.Item>
  );
  const userMenu = (
    <>
      <Menu.Item
        key={UserMenuItemEnum.PROFILE}
        onClick={() => {
          navigate(Paths.PROFILE);
        }}
        icon={<UserOutlined />}
      >
        {t("user.menu.profile")}
      </Menu.Item>
      <Menu.Item
        key={UserMenuItemEnum.ACCOUNT}
        onClick={() => navigate(Paths.ACCOUNT)}
        icon={<SettingOutlined />}
      >
        {t("user.menu.account")}
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        key={UserMenuItemEnum.SIGN_OUT}
        onClick={() => keycloak.logout()}
        icon={<LogoutOutlined />}
      >
        {t("user.menu.signOut")}
      </Menu.Item>
    </>
  );
  const organizationsMenu = (
    <>
      {user?.organizationMembers.map((organizationRoles) =>
        createOrganizationMenuItem(organizationRoles),
      )}
      <Menu.Divider />
      <Menu.Item
        key={OrganizationMenuItemEnum.NEW_ORGANIZATION}
        onClick={() => navigate(Paths.ORGANIZATION_NEW)}
        icon={<PlusCircleOutlined />}
      >
        {t("organization.menu.newOrganization")}
      </Menu.Item>
    </>
  );
  return (
    <Header style={headerStyle} data-testId="page-header">
      <div
        style={{
          float: "left",
          width: siderWidth,
          height: headerHeight,
          padding: 0,
        }}
      >
        <Link to={Paths.HOME}>
          <img
            src={logo}
            data-testId="logo"
            height="100%"
            alt=""
            style={{ paddingLeft: 16, paddingTop: 12, paddingBottom: 12 }}
          />
        </Link>
      </div>

      <HeaderDropDownMenu
        menu={{
          items: languages.map((language) => ({
            key: language,
            label: language,
            onClick: () => i18n.changeLanguage(language),
          })),
        }}
        title={i18n.language.slice(0, 2)}
      />

      {windowWidth > 1000 ? (
        <Space size={32} style={{ float: "right", height: "100%" }}>
          {keycloak.hasResourceRole("master") && (
            <Link to={Paths.MANAGEMENT_CONSOLE}>
              <Text style={{ color: "white" }}>{t("common.console")}</Text>
            </Link>
          )}
          <Link to={Paths.SUPERVISED_PARTICIPANTS}>
            <Text style={{ color: "white" }}>{t("common.participant_other")}</Text>
          </Link>
          <Link to={Paths.STUDIES_OVERVIEW}>
            <Text style={{ color: "white" }}>{t("header.studies")}</Text>
          </Link>
          {user?.organizationMembers && user?.organizationMembers.length > 0 ? (
            <HeaderDropDownMenu
              menu={{
                style: { marginTop: 24 },
                items: createOrganizationMenu(
                  user.organizationMembers.map((organizationMember) => ({
                    id: organizationMember.organization.id,
                    name: organizationMember.organization.name,
                  })),
                ),
              }}
              title={t("header.organizations")}
            />
          ) : (
            <Link to={Paths.ORGANIZATION_NEW} style={{ float: "right", color: "white" }}>
              {t("header.organization")}
            </Link>
          )}
          <HeaderDropDownMenu
            menu={{
              style: { marginTop: 24 },
              items: [
                {
                  label: t("user.menu.profile"),
                  key: UserMenuItemEnum.PROFILE,
                  onClick: () => navigate(Paths.PROFILE),
                  icon: <UserOutlined />,
                },
                {
                  label: t("user.menu.account"),
                  key: UserMenuItemEnum.ACCOUNT,
                  onClick: () => navigate(Paths.ACCOUNT),
                  icon: <SettingOutlined />,
                },
                {
                  type: "divider",
                },
                {
                  label: t("user.menu.signOut"),
                  key: "3",
                  onClick: () => keycloak.logout(),
                  icon: <LogoutOutlined />,
                },
              ],
            }}
            avatar={user?.avatar}
            title={`${user?.firstName} ${user?.lastName}`}
          />
        </Space>
      ) : (
        <Dropdown
          overlay={
            <Menu>
              {keycloak.hasResourceRole("master") && (
                <Menu.Item
                  icon={<ToolOutlined />}
                  onClick={() => navigate(Paths.MANAGEMENT_CONSOLE)}
                >
                  {t("common.console")}
                </Menu.Item>
              )}
              <Menu.Item
                icon={<TeamOutlined />}
                onClick={() => navigate(Paths.SUPERVISED_PARTICIPANTS)}
              >
                {t("common.participant_other")}
              </Menu.Item>
              <Menu.Item
                icon={<DeploymentUnitOutlined />}
                onClick={() => navigate(Paths.STUDIES_OVERVIEW)}
              >
                {t("header.studies")}
              </Menu.Item>
              <Menu.SubMenu icon={<HomeOutlined />} title={t("header.organization")}>
                {organizationsMenu}
              </Menu.SubMenu>
              {userMenu}
            </Menu>
          }
          trigger={["click"]}
        >
          <div style={{ float: "right", height: "100%", color: "white", fontSize: 24 }}>
            <MenuOutlined />
          </div>
        </Dropdown>
      )}
    </Header>
  );
}
