import { Link } from "react-router-dom";
import { Alert, Button, Divider, Empty, List, Typography } from "antd";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { LoadingHandler, PageHeader, Paths, SearchField } from "../../../shared";
import {
  usePermission,
  ActionEnum,
  useSupervisors,
  useParticipants,
  useSupervisorRoles,
  GetSupervisorsOfStudyQuery,
} from "../../../services";
import { CreateSupervisorsModal } from "../components";
import { StudySupervisorListItem } from "./components";
import useStudyId from "../../../shared/hooks/useStudyId";

const { Text } = Typography;

type Supervisor = GetSupervisorsOfStudyQuery["getSupervisorsOfStudyAction"][number];

export function StudyPersonnelSupervisors() {
  const { studyId } = useStudyId();
  const [searchResult, setSearchResult] = useState({ searchText: "", items: [] as Supervisor[] });
  const { t } = useTranslation();
  const [isAddMemberModalVisible, setIsAddMemberModalVisible] = useState(false);
  const { participants, loading: participantsLoading } = useParticipants(studyId);
  const { supervisorRoles, loading: supervisorRolesLoading } = useSupervisorRoles(studyId);
  const { supervisors, loading: supervisorsLoading, refetch } = useSupervisors(studyId);
  const { permitted: canAddAssignment, loading: canAddAssignmentLoading } = usePermission({
    action: ActionEnum.STUDY_CREATE_PARTICIPANT,
    studyId,
  });
  const studyRoutes = Paths.getStudyPaths(studyId);

  const studyHasParticipants = participants && participants.length > 0;

  const handleOnFinishAddSupervisor = async () => {
    await refetch();
    setIsAddMemberModalVisible(false);
  };

  const loading =
    participantsLoading || supervisorsLoading || canAddAssignmentLoading || supervisorRolesLoading;

  return (
    <LoadingHandler loading={loading}>
      <PageHeader
        title={t("study.supervisors.title")}
        description={t("study.supervisors.description")}
        hideDivider
        style={{ marginBottom: 24, padding: 0 }}
      >
        {!studyHasParticipants ? (
          <Alert
            style={{ marginTop: 12 }}
            message={t("study.supervisors.noParticipantsAlert.title")}
            description={
              <>
                <Text>{t("study.supervisors.noParticipantsAlert.description.1")}</Text>
                <Link to={studyRoutes.PARTICIPANTS}>
                  <Button type="link">{t("study.menu.participants")}</Button>
                </Link>
              </>
            }
            type="warning"
            showIcon
            closable
          />
        ) : null}
      </PageHeader>

      <Button
        data-id="study.supervisors.addSupervisorButton"
        style={{ float: "right" }}
        type="primary"
        disabled={!canAddAssignment || !studyHasParticipants}
        onClick={() => setIsAddMemberModalVisible(true)}
      >
        {t("study.supervisors.addSupervisorButton")}
      </Button>

      <SearchField
        allItems={supervisors?.map((supervisor) => ({
          item: supervisor,
          searchFields: [
            `${supervisor.userFirstName} ${supervisor?.userLastName}`,
            `${supervisor?.userLastName} ${supervisor?.userFirstName}`,
            supervisor.userEmail,
          ],
        }))}
        placeholderText={t("study.supervisors.searchPlaceholder")}
        onSearch={setSearchResult}
        style={{ float: "left", width: 240, marginBottom: 16 }}
      />

      <Divider style={{ marginBottom: 0 }} />

      <List<Supervisor>
        itemLayout="horizontal"
        size="small"
        pagination={{
          hideOnSinglePage: true,
          pageSize: 10,
        }}
        dataSource={searchResult.items}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t("study.supervisors.noSupervisor")}
            />
          ),
        }}
        renderItem={(item) => (
          <StudySupervisorListItem
            searchStringToHighlight={searchResult.searchText}
            studyId={studyId}
            avatar={item.userAvatar}
            email={item.userEmail}
            firstName={item.userFirstName}
            lastName={item.userLastName}
            memberId={item.userId}
          />
        )}
      />

      {supervisorRoles && (
        <CreateSupervisorsModal
          onCancel={() => setIsAddMemberModalVisible(false)}
          onFinish={handleOnFinishAddSupervisor}
          studyId={studyId}
          open={isAddMemberModalVisible}
          supervisorRoles={supervisorRoles.map((role) => ({ id: role.id, name: role.name }))}
        />
      )}
    </LoadingHandler>
  );
}

export default StudyPersonnelSupervisors;
