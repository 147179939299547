import { GetExecutorsQuery, useGetExecutorsQuery } from "../codegen";

export function useExecutors() {
  const { data, ...rest } = useGetExecutorsQuery({
    fetchPolicy: "cache-and-network",
    context: {
      headers: {
        "x-hasura-role": "master",
      },
    },
  });

  return { executors: data?.executors, ...rest };
}

export default useExecutors;

export type ExecutorFromQuery = NonNullable<GetExecutorsQuery["executors"]>[number];
