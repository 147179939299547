import React from "react";
import OrganizationSider, { IOrganizationSiderProps } from "./OrganizationSider";
import { IPageContentProps, PageContainer } from "../../../shared";

interface IOrganizationContainerProps extends IOrganizationSiderProps, IPageContentProps {}

export function OrganizationContainer(props: IOrganizationContainerProps) {
  const { children } = props;
  return (
    <PageContainer sider={<OrganizationSider {...props} />} {...props}>
      {children}
    </PageContainer>
  );
}

export default OrganizationContainer;
