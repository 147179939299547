/* eslint-disable react/require-default-props */

import React, { ReactNode } from "react";
import { Typography, Divider } from "antd";
import { PageHeader as AntdPageHeader, PageHeaderProps } from "@ant-design/pro-layout";

const { Text, Title } = Typography;

interface IPageHeaderProps extends PageHeaderProps {
  description?: string;
  hideDivider?: boolean;
  children?: ReactNode;
}

export function PageHeader(props: IPageHeaderProps) {
  const { title, style, description, children, hideDivider } = props;
  return (
    <AntdPageHeader
      title={typeof title === "string" ? <Title level={3}> {title} </Title> : title}
      style={style || { padding: 0, marginBottom: 24 }}
      {...props}
    >
      <Text>{description}</Text>
      {children}
      {!hideDivider && <Divider />}
    </AntdPageHeader>
  );
}

export default PageHeader;
