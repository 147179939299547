import { useAggregateStudiesOfUserInOrganizationQuery } from "../codegen";

export function useStudyCountOfUser(props: { userId: string; organizationId: string }) {
  const { data, loading, refetch } = useAggregateStudiesOfUserInOrganizationQuery({
    variables: {
      organizationId: props.organizationId,
      userId: props.userId,
    },
  });

  return { studyCount: data?.aggregateStudyMembers.aggregate?.count, loading, refetch };
}

export default useStudyCountOfUser;
