import { ApolloError } from "@apollo/client";
import { notification } from "antd";
import { useTranslation } from "react-i18next";
import { UpdateOrganizationMutation, useUpdateOrganizationMutation } from "../codegen";

interface IUseUpdateOrganization {
  // eslint-disable-next-line no-unused-vars
  onCompleted?: (data: UpdateOrganizationMutation) => void;
  // eslint-disable-next-line no-unused-vars
  onError?: (error: ApolloError) => void;
}

interface IUpdateOrganization {
  organizationId: string;
  address: string;
  city: string;
  country: string;
  department?: string;
  description?: string;
  name: string;
  zip: string;
}

export function useUpdateOrganization({
  onCompleted = () => {},
  onError = () => {},
}: IUseUpdateOrganization) {
  const { t } = useTranslation();
  const [mutate, { loading }] = useUpdateOrganizationMutation({
    onCompleted: (data) => {
      notification.success({
        message: t("notifications.updateOrganization.success"),
      });
      onCompleted(data);
    },
    onError: (error) => {
      notification.error({
        message: t("notifications.updateOrganization.error"),
      });
      onError(error);
    },
  });

  const updateOrganization = (props: IUpdateOrganization) => {
    mutate({
      variables: {
        organizationId: props.organizationId,
        address: props.address,
        city: props.city,
        country: props.country,
        department: props.department,
        description: props.description,
        name: props.name,
        zip: props.zip,
      },
    });
  };

  return { updateOrganization, loading };
}

export default useUpdateOrganization;
