import React, { useEffect } from "react";
import { AccessDenied } from "../../shared/results";
import { Loader, useOrganizationId, useUserId } from "../../shared";
import {
  checkOrgaPermissions,
  checkStudyPermissions,
  checkSupervisorPermissions,
  useSupervisorRules,
  ActionEnum,
  useOrganizationRules,
  useStudyRules,
} from "./utils";
import useStudyId from "../../shared/hooks/useStudyId";
import useParticipantId from "../../shared/hooks/useParticipantId";

export interface IPermissionProps {
  action: ActionEnum;
  organizationId?: string;
  studyId?: string;
  participantId?: string;
}

export function usePermission({
  action,
  organizationId,
  participantId,
  studyId,
}: IPermissionProps) {
  const { userId } = useUserId();
  const {
    fetchSupervisorRules,
    loading: supervisorRulesLoading,
    supervisorRules,
  } = useSupervisorRules();

  const { fetchOrgaRules, loading: orgaRulesLoading, organizationRules } = useOrganizationRules();
  const {
    fetchStudyRulesByStudyId,
    fetchStudyRulesByParticipantId,
    loading: studyRulesLoading,
    studyRulesByStudyId,
    studyRulesByParticipantId,
  } = useStudyRules();

  const isOrgaPermission = action.startsWith("ORGANIZATION");
  const isStudyPermission = action.startsWith("STUDY");
  const isParticipantPermission = action.startsWith("PARTICIPANT");

  useEffect(() => {
    if (isOrgaPermission && organizationId) {
      fetchOrgaRules({ userId, organizationId });
    } else if (isStudyPermission && studyId) {
      fetchStudyRulesByStudyId({ userId, studyId });
    } else if (isParticipantPermission && participantId) {
      fetchSupervisorRules({ userId, participantId });
      fetchStudyRulesByParticipantId({ userId, participantId });
    } else {
      throw new Error("Action not known or corresponding parameter missing");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action]);

  let isPermitted = false;
  let loading = true;

  if (isOrgaPermission) {
    if (organizationRules) {
      isPermitted = checkOrgaPermissions({
        action,
        roleDefinition: organizationRules,
      });
    }
    loading = orgaRulesLoading || organizationRules === undefined;
  } else if (isParticipantPermission) {
    const permittedBySupervisorRole =
      supervisorRules &&
      checkSupervisorPermissions({
        action,
        roleDefinition: supervisorRules,
      });

    const permittedByStudyRole =
      studyRulesByParticipantId &&
      checkStudyPermissions({
        action,
        roleDefinition: studyRulesByParticipantId,
      });

    isPermitted = permittedByStudyRole === true || permittedBySupervisorRole === true;

    if (isPermitted) {
      loading = false;
    } else {
      loading =
        studyRulesLoading ||
        supervisorRulesLoading ||
        studyRulesByParticipantId === undefined ||
        supervisorRules === undefined;
    }
  } else if (isStudyPermission) {
    if (studyRulesByStudyId) {
      isPermitted = checkStudyPermissions({
        action,
        roleDefinition: studyRulesByStudyId,
      });
    }

    loading = studyRulesLoading || studyRulesByStudyId === undefined;
  }

  return {
    loading,
    permitted: isPermitted,
  };
}

export interface IPermissionValidatorProps extends IPermissionProps {
  yes?: React.ReactElement;
  children?: React.ReactElement;
  no?: React.ReactElement;
  loading?: React.ReactElement;
}

export function PermissionValidator({
  yes,
  children,
  no,
  loading,
  ...rest
}: IPermissionValidatorProps) {
  const { loading: isLoading, permitted } = usePermission(rest);

  if (isLoading) {
    return loading || <Loader componentName="PermissionValidator" />;
  }

  if (!permitted) {
    return no || <AccessDenied />;
  }

  return yes || null;
}

export interface IProtectedRouteProps {
  action: ActionEnum;
  children: React.ReactElement;
}

export function ProtectedOrganizationRoute({ action, children }: IProtectedRouteProps) {
  const { organizationId } = useOrganizationId();
  const { loading, permitted } = usePermission({ action, organizationId });

  return (
    <DefaultProtectedRouteChild loading={loading} permitted={permitted}>
      {children}
    </DefaultProtectedRouteChild>
  );
}

export function ProtectedStudyRoute({ action, children }: IProtectedRouteProps) {
  const { studyId } = useStudyId();
  const { loading, permitted } = usePermission({ action, studyId });

  return (
    <DefaultProtectedRouteChild loading={loading} permitted={permitted}>
      {children}
    </DefaultProtectedRouteChild>
  );
}

export function ProtectedParticipantRoute({ action, children }: IProtectedRouteProps) {
  const { studyId } = useStudyId();
  const { participantId } = useParticipantId();
  const { loading, permitted } = usePermission({ action, studyId, participantId });

  return (
    <DefaultProtectedRouteChild loading={loading} permitted={permitted}>
      {children}
    </DefaultProtectedRouteChild>
  );
}

function DefaultProtectedRouteChild({
  loading,
  permitted,
  children,
}: {
  loading: boolean;
  permitted: boolean;
  children: React.ReactNode;
}) {
  if (loading) {
    return <Loader componentName="PermissionValidator" />;
  }

  if (!permitted) {
    return <AccessDenied />;
  }

  return children as React.ReactElement;
}
