import { ApolloError } from "@apollo/client";
import { notification } from "antd";
import { useTranslation } from "react-i18next";
import {
  GetOrganizationMembersDocument,
  UpdateOrganizationMemberMutation,
  useUpdateOrganizationMemberMutation,
} from "../codegen";

interface IUseUpdateOrganizationMember {
  // eslint-disable-next-line no-unused-vars
  onCompleted?: (data: UpdateOrganizationMemberMutation) => void;
  // eslint-disable-next-line no-unused-vars
  onError?: (error: ApolloError) => void;
}

interface IUpdateOrganizationMember {
  organizationId: string;
  userId: string;
  organizationRoleId: string;
}

export function useUpdateOrganizationMember({
  onCompleted = () => {},
  onError = () => {},
}: IUseUpdateOrganizationMember) {
  const { t } = useTranslation();
  const [mutate, { loading }] = useUpdateOrganizationMemberMutation({
    onCompleted: (data) => {
      notification.success({
        message: t("notifications.updateOrganizationMember.success", {
          roleName: data.updateOrganizationMember?.organizationRole.name,
        }),
      });
      onCompleted(data);
    },
    onError: (error) => {
      notification.error({
        message: t("notifications.updateOrganizationMember.error"),
      });
      onError(error);
    },
  });

  const updateOrganizationMember = (props: IUpdateOrganizationMember) => {
    mutate({
      variables: {
        organizationId: props.organizationId,
        userId: props.userId,
        organizationRoleId: props.organizationRoleId,
      },
      refetchQueries: [
        {
          query: GetOrganizationMembersDocument,
          variables: { organizationId: props.organizationId },
        },
      ],
    });
  };

  return { updateOrganizationMember, loading };
}

export default useUpdateOrganizationMember;
