import { Alert, Card, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLabelingInformation } from "../../../../services";
import { primaryColor, useStudyId } from "../../../../shared";

const { Text, Link } = Typography;

export function LabelingInformation() {
  const { studyId } = useStudyId();
  const { labelingInformation } = useLabelingInformation(studyId);
  const { t } = useTranslation();

  return (
    <Card
      title={`${t("study.labeling.title")} ${labelingInformation?.deviceName}`}
      style={{ width: 400, margin: "auto" }}
      headStyle={{
        backgroundColor: primaryColor,
        color: "white",
        textAlign: "center",
      }}
    >
      <ul>
        <li>
          <Text strong>{t("study.labeling.deviceName")}:</Text> {labelingInformation?.deviceName}
        </li>
        <li>
          <Text strong>{t("study.labeling.manufacturer")}:</Text>{" "}
          {labelingInformation?.manufacturer}
        </li>
        <li>
          <Text strong>{t("study.labeling.contact")}:</Text>
          <ul>
            <li>
              <Text>{t("study.labeling.email")}: </Text>
              <Link href={`mailto:${labelingInformation?.email}`}>
                {labelingInformation?.email}
              </Link>
            </li>
            <li>
              <Text>{t("study.labeling.phone")}:</Text> {labelingInformation?.phone}
            </li>
          </ul>
        </li>
      </ul>
      {labelingInformation?.isForClinicalInvestigationOnly ? (
        <Alert
          style={{ textAlign: "center", fontWeight: 500 }}
          message={t("study.labeling.forClinicalInvestigationOnly")}
          type="warning"
        />
      ) : null}
    </Card>
  );
}

export default LabelingInformation;
