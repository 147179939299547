import { ApolloError, useMutation } from "@apollo/client";
import { notification } from "antd";
import { useTranslation } from "react-i18next";
import {
  GetParticipantTherapyConfigDocument,
  ParticipantVariableConfigsToUpdate,
  UpdateParticipantTherapyConfigMutation,
  useUpdateParticipantTherapyConfigMutation,
} from "../codegen";

interface IUseUpdateParticipantVariableConfigProps {
  participantId: string;
  // eslint-disable-next-line no-unused-vars
  onCompleted?: (data: UpdateParticipantTherapyConfigMutation) => void;
  // eslint-disable-next-line no-unused-vars
  onError?: (error: ApolloError) => void;
}

export function useUpdateParticipantVariableConfig({
  participantId,
  onCompleted = () => {},
  onError = () => {},
}: IUseUpdateParticipantVariableConfigProps) {
  const { t } = useTranslation();
  const [mutate, { loading }] = useUpdateParticipantTherapyConfigMutation({
    onCompleted: (data) => {
      const updatedConfigs = data.updateParticipantTherapyVariableConfigsAction;
      if (!updatedConfigs) {
        notification.error({
          message: t("notifications.updateParticipantConfig.error"),
        });
        return;
      }

      notification.success({
        message: t("notifications.updateParticipantConfig.success"),
      });

      onCompleted(data);
    },
    onError: (error) => {
      notification.error({
        message: t("notifications.updateParticipantConfig.error"),
      });

      onError(error);
    },

    refetchQueries: [{ query: GetParticipantTherapyConfigDocument, variables: { participantId } }],
  });

  const updateParticipantVariableConfig = (config: ParticipantVariableConfigsToUpdate[]) => {
    if (config.length > 0) {
      mutate({ variables: { objects: config } });
    } else {
      notification.info({
        message: t("notifications.updateParticipantConfig.noChanges"),
      });
    }
  };

  return { updateParticipantVariableConfig, loading };
}

export default useUpdateParticipantVariableConfig;
