/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  List,
  Typography,
  Divider,
  Result,
  Select,
  Space,
  Alert,
  Button,
  App,
  message,
  QRCode,
} from "antd";
import { BulbOutlined } from "@ant-design/icons";
import "../../shared/layout/css/FullPage.css";
import { MainPageContainer, SupervisedParticipantsListItem } from "./components";
import { CenterInModal, PageHeader, primaryColor, SearchField, useUserId } from "../../shared";
import { UserFromQuery, useUser } from "../../services";

const { Title, Text, Paragraph } = Typography;
const { Option } = Select;

type Supervisor = UserFromQuery["supervisors"][number];

interface ISupervisorOverviewProps {
  user: UserFromQuery;
}

export function SupervisedParticipantsOverview({ user }: ISupervisorOverviewProps) {
  const [searchResult, setSearchResult] = useState({
    searchText: "",
    items: user.supervisors,
  });
  const [studyFilter, setStudyFilter] = useState("all");
  const { userId } = useUserId();
  const { refetch } = useUser(userId);
  const { t } = useTranslation();
  const { modal, notification } = App.useApp();

  const allAssignments = user.supervisors;

  const studiesList = allAssignments
    .map((assignment) => assignment.participant.participantGroup.study)
    .filter((value, index, self) => self.indexOf(value) === index);

  const filterAssignments = (assignmentsToFilter: Supervisor[]) => {
    const filterForStudies =
      studyFilter === "all"
        ? assignmentsToFilter
        : assignmentsToFilter.filter(
            (assignment) => assignment.participant.participantGroup.study.id === studyFilter,
          );

    const filterForSearchText = filterForStudies.filter((assignment) =>
      searchResult.items.find((item) => item.id === assignment.id),
    );
    return filterForSearchText;
  };

  const showSupervisorCode = (code: string) => {
    modal.info({
      title: t("supervisedParticipants.buttons.supervisorCode"),
      icon: null,
      content: (
        <CenterInModal>
          <p>{t("supervisedParticipants.supervisorCodeModal.content")}</p>
          <div
            onClick={() => message.info(code)}
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: 24,
              marginTop: 24,
            }}
          >
            <QRCode value={code} size={200} bordered={false} />
          </div>
        </CenterInModal>
      ),
      okText: t("common.close"),
    });
  };

  const refetchList = async () => {
    const result = await refetch();
    if (result.data.user && result.data.user.supervisors.length > allAssignments.length) {
      notification.info({
        message: t("supervisedParticipants.notifications.newParticipants"),
      });
    } else {
      notification.info({
        message: t("supervisedParticipants.notifications.noNewParticipants"),
      });
    }
  };

  const superVisorCodeAlert = (
    <Alert
      style={{ marginTop: 12, borderColor: primaryColor, background: "white" }}
      message={t("supervisedParticipants.supervisorCodeAlert.title")}
      description={
        <>
          <Paragraph>{t("supervisedParticipants.supervisorCodeAlert.description")}</Paragraph>

          <Space>
            <Button onClick={() => refetchList()}>
              {t("supervisedParticipants.buttons.refetchParticipants")}
            </Button>
            <Button onClick={() => showSupervisorCode(userId)}>
              {t("supervisedParticipants.buttons.supervisorCode")}
            </Button>
          </Space>
        </>
      }
    />
  );

  return allAssignments.length > 0 ? (
    <MainPageContainer>
      <PageHeader
        hideDivider
        title={<Title level={2}>{t("supervisedParticipants.title")}</Title>}
        description={t("supervisedParticipants.description")}
      >
        {superVisorCodeAlert}
      </PageHeader>
      <Space>
        <SearchField
          key="searchField"
          allItems={allAssignments.map((assignment) => ({
            item: assignment,
            searchFields: [assignment.participant.pseudonym],
          }))}
          placeholderText={t("supervisedParticipants.searchPlaceholder")}
          onSearch={setSearchResult}
          style={{ width: 192 }}
        />

        <Select
          defaultValue="all"
          style={{ width: 200 }}
          onChange={(value) => setStudyFilter(value)}
        >
          <Option value="all">{t("supervisedParticipants.allStudies")}</Option>
          {studiesList.map((study) => (
            <Option key={study.id} value={study.id}>
              {study.name}
            </Option>
          ))}
        </Select>
      </Space>

      <Divider style={{ marginBottom: 0 }} />

      <List<Supervisor>
        itemLayout="horizontal"
        size="default"
        pagination={{
          hideOnSinglePage: true,
          pageSize: 10,
        }}
        dataSource={filterAssignments(allAssignments)}
        renderItem={(item) => (
          <SupervisedParticipantsListItem
            id={item.participant.id}
            searchStringToHighlight={searchResult.searchText}
            groupName={item.participant.participantGroup.name}
            pseudonym={item.participant.pseudonym}
            studyId={item.participant.participantGroup.study.id}
            studyName={item.participant.participantGroup.study.name}
            userId={user.id}
          />
        )}
      />
    </MainPageContainer>
  ) : (
    <Result
      style={{
        margin: "auto",
        marginTop: 64,
        width: 600,

        padding: 40,
      }}
      icon={<BulbOutlined style={{ color: primaryColor }} />}
      title={t("supervisedParticipants.welcomeText")}
      subTitle={
        <>
          <Text>{t("supervisedParticipants.welcomeDescription")}</Text>
          <br />
          {superVisorCodeAlert}
        </>
      }
    />
  );
}

export default SupervisedParticipantsOverview;
