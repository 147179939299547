import React from "react";
import { Link } from "react-router-dom";
import { IPermissionProps } from "../../services";
import { ILinkedMenuItemProps } from "./LinkedMenuItem";
import { MenuItemWithPermission } from "./MenuItemWithPermission";

interface ILinkedMenuItemWithPermission extends IPermissionProps, ILinkedMenuItemProps {}

export function LinkedMenuItemWithPermission(props: ILinkedMenuItemWithPermission) {
  const { linkAddress, itemText, ...rest } = props;
  return (
    <MenuItemWithPermission {...rest}>
      <Link to={linkAddress}>{itemText}</Link>
    </MenuItemWithPermission>
  );
}

export default LinkedMenuItemWithPermission;
