/* eslint-disable no-unused-vars */
import {
  CloudServerOutlined,
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, Empty, List, Menu, Space, Table, Tag, Typography } from "antd";
import { ColumnType } from "antd/lib/table";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { ExecutorFromQuery, useExecutors } from "../../services";
import { LoadingHandler, PageHeader, primaryColor } from "../../shared";
import { MainPageContainer } from "../main/components";
import CreateExecutorModal from "./components/CreateExecutorModal";
import ExecutorListItem from "./components/ExecutorListItem";

type ExecutorStudy = ExecutorFromQuery["studies"][number];

const { Text, Title } = Typography;

interface IColumnType {
  key: string;
  name: string;
  organizationName: string;
  url: string;
  hidden: boolean;
  studies: ExecutorStudy[];
}

const menu = (
  <Menu
    items={[
      {
        key: "1",
        label: "Edit",
        icon: <EditOutlined />,
      },
      {
        key: "2",
        label: "Delete",
        icon: <DeleteOutlined />,
        disabled: true,
      },
    ]}
  />
);

/**
 * Defines the columns for the executor overview table. During use one can
 * provide a translation function and/or a subString that should be highlighted.
 * @param t
 * @returns
 */
const excutorTableColumns = (t: any, searchStringToHighlight: string | undefined) => {
  const columns: ColumnType<IColumnType>[] = [
    {
      title: "",
      width: 24,
      dataIndex: "icon",
      key: "icon",
      render: () => (
        <CloudServerOutlined
          style={{
            borderRadius: "50%",
            borderStyle: "solid",
            borderColor: "grey",
            borderWidth: 1,
            fontSize: 16,
            padding: 4,
            color: "grey",
          }}
        />
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name: string, record) => (
        <Space>
          <Text strong style={{ color: primaryColor }}>
            {name}
          </Text>
          {record.hidden ? (
            <Tag key="visibility" icon={<EyeInvisibleOutlined />} color="default">
              {t("management.executor.executorListItem.hiddenTag")}{" "}
            </Tag>
          ) : null}
        </Space>
      ),
    },
    {
      title: "URL",
      dataIndex: "url",
      key: "url",
      render: (url: string) => (
        <>
          <Text>
            <strong>URL:</strong> {url}
          </Text>
          <br />
          <Text>
            <strong>Design:</strong> IMMERSE Usability Evaluation{" "}
          </Text>
          <br />
          <Text>
            <strong>Version:</strong> b3127e60-5d9a-4a2d-9860-c7c1d7f6ee88
          </Text>
        </>
      ),
    },
    {
      title: "Organization",
      dataIndex: "organizationName",
      key: "organizationName",
    },
    {
      title: "Studies",
      dataIndex: "studies",
      key: "studies",

      render: (studies: ExecutorStudy[]) =>
        studies.length > 0 ? (
          <List
            size="small"
            dataSource={studies.map((study) => study.name)}
            renderItem={(item) => <List.Item>{item}</List.Item>}
          />
        ) : (
          <Text type="secondary" italic>
            no studies yet
          </Text>
        ),
    },

    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      align: "right",
      render: () => (
        <Dropdown overlay={menu} key="dropdown" trigger={["click"]}>
          <Button
            style={{
              border: "none",
              padding: 0,
            }}
          >
            <EllipsisOutlined
              style={{
                fontSize: 20,
              }}
            />
          </Button>
        </Dropdown>
      ),
    },
  ];
  return columns;
};

export function ExecutorList() {
  const { t } = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);
  const { executors, loading } = useExecutors();

  // const preparedExecutors = executors?.map((executor) => ({
  //   key: executor.id,
  //   name: executor.name,
  //   organizationName: executor.organization ? executor.organization.name : "All Organizations",
  //   url: executor.executorUrl,
  //   studies: executor.studies,
  //   hidden: executor.hidden,
  // }));

  return (
    <MainPageContainer>
      <PageHeader
        hideDivider
        title={<Title level={2}>{t("management.executor.overview.title")}</Title>}
        description={t("management.executor.overview.description")}
        extra={[
          <Button
            data-id="management.executor.createExecutorButton"
            key="1"
            type="primary"
            onClick={() => setModalVisible(true)}
          >
            {t("management.executor.createExecutorButton")}{" "}
          </Button>,
        ]}
      />
      <LoadingHandler loading={loading}>
        <List<ExecutorFromQuery>
          itemLayout="horizontal"
          size="small"
          pagination={{
            hideOnSinglePage: true,
            pageSize: 10,
          }}
          dataSource={executors}
          renderItem={(item) => <ExecutorListItem executor={item} />}
        />

        {/* <Table
          dataSource={preparedExecutors || []}
          columns={excutorTableColumns(t, "")}
          style={{ marginTop: 24, minWidth: "100%" }}
          pagination={{
            hideOnSinglePage: true,
            pageSize: 10,
          }}
          locale={{
            emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="no executors" />,
          }}
        /> */}
      </LoadingHandler>

      <CreateExecutorModal open={modalVisible} onCancel={() => setModalVisible(false)} />
    </MainPageContainer>
  );
}

export default ExecutorList;
