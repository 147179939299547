import { Form, Input, Modal, Spin } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useCreateOrganizationMember, useOrganizationRoles } from "../../../services";

import { OrganizationRolesFormItem } from "./OrganizationRolesFormItem";
import { OrganizationRoleEnum } from "../../../shared";

interface ICreateOrganizationMemberModalProps {
  organizationId: string;
  open: boolean;
  onCancel: () => void;
}

export function CreateOrganizationMemberModal({
  organizationId,
  open,
  onCancel,
}: ICreateOrganizationMemberModalProps) {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { organizationRoles, loading: organizationRolesLoading } =
    useOrganizationRoles(organizationId);
  const memberRole = organizationRoles?.find((role) => role.name === OrganizationRoleEnum.MEMBER);
  const { createOrganizationMember, loading } = useCreateOrganizationMember({
    onCompleted: () => {
      onCancel();
    },
  });

  const handleOnFormFinish = (values: any) => {
    createOrganizationMember({
      organizationId,
      email: values.email,
      roleId: values.roleId,
    });
  };

  return (
    <Modal
      title={t("organization.members.addMemberModal.title")}
      closable
      open={open}
      onCancel={onCancel}
      confirmLoading={loading}
      onOk={() => form.submit()}
      okText={t("common.ok")}
      // @ts-ignore
      okButtonProps={{ "data-id": "common.ok" }}
      cancelText={t("common.cancel")}
      // @ts-ignore
      cancelButtonProps={{ "data-id": "common.cancel" }}
      destroyOnClose
      afterClose={() => form.resetFields()}
    >
      <Spin spinning={organizationRolesLoading} tip={t("common.loading")}>
        <Form form={form} onFinish={handleOnFormFinish} initialValues={{ roleId: memberRole?.id }}>
          <Form.Item
            name={["email"]}
            rules={[
              {
                type: "email",
                required: true,
                message: t("organization.addMemberModal.missingEmail"),
              },
            ]}
          >
            <Input placeholder={t("organization.addMemberModal.emailPlaceholder")} />
          </Form.Item>
          <OrganizationRolesFormItem organizationId={organizationId} />
        </Form>
      </Spin>
    </Modal>
  );
}

export default CreateOrganizationMemberModal;
