/* eslint-disable react/require-default-props */
import { Menu } from "antd";

import { NavHashLink } from "react-router-hash-link";
import React from "react";
import { ILinkedMenuItemProps } from ".";

interface IHashLinkedMenuItemProps extends ILinkedMenuItemProps {
  pageYOffset?: number;
}

export function HashLinkedMenuItem(props: IHashLinkedMenuItemProps) {
  const { linkAddress, itemText, pageYOffset = 0, ...rest } = props;
  return (
    <Menu.Item {...rest}>
      <NavHashLink
        scroll={(el) => {
          const y = el.getBoundingClientRect().top + window.pageYOffset - pageYOffset;
          window.scrollTo({ top: y, behavior: "smooth" });
        }}
        smooth
        to={linkAddress}
      >
        {itemText}
      </NavHashLink>
    </Menu.Item>
  );
}

export default HashLinkedMenuItem;
