import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";

import { setContext } from "@apollo/link-context";
import { onError } from "@apollo/link-error";
import Keycloak from "keycloak-js";

// Pass keycloak instance as object to have token by reference instead of only by value when only passing token as string
export function buildApolloClients(keycloak: Keycloak) {
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path }) =>
        console.log(`[GraphQL error]: message: ${message}, location: ${locations}, path: ${path}`),
      );

    if (networkError) console.log(`[Network error]: ${JSON.stringify(networkError)}`);
  });

  const authLink = setContext(async (_, { headers }) => {
    if (keycloak.isTokenExpired()) {
      try {
        await keycloak.updateToken(10);
      } catch {
        keycloak.logout();
      }
    }

    return {
      headers: {
        ...headers,
        authorization: `Bearer ${keycloak.token}`,
      },
    };
  });

  const httpLink = new HttpLink({
    uri: "/v1/graphql",
    headers: {
      "client-name": "Frontend [web]",
      "client-version": "1.0.0",
    },
  });

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: authLink.concat(errorLink.concat(httpLink)),
  });

  return {
    apolloClient: client,
  };
}
