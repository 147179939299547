import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { v4 } from "uuid";

const LoadingContext = React.createContext({ isLoading: false });

interface IUpdateLoadingContext {
  // eslint-disable-next-line no-unused-vars
  setLoadingState: (_: ISetLoadingStateProps) => void;
  // eslint-disable-next-line no-unused-vars
  generateID: (prefix?: string) => string;
}

const UpdateLoadingContext = React.createContext<IUpdateLoadingContext>({
  // eslint-disable-next-line no-unused-vars
  setLoadingState: (_: ISetLoadingStateProps) => {},
  // eslint-disable-next-line no-unused-vars
  generateID: (prefix?: string) => "id",
});

interface ILoadingContextProps {
  children: ReactNode;
}

interface ISetLoadingStateProps {
  id: string;
  loading: boolean;
}

function LoadingContextProvider({ children }: ILoadingContextProps) {
  const [loadingIds, setLoadingIds] = useState<string[]>([]);

  const setLoadingState = ({ id, loading }: ISetLoadingStateProps) => {
    if (loading) {
      setLoadingIds((prev) => [...prev, id]);
    }

    if (!loading) {
      setLoadingIds((prev) => [...prev].filter((item) => item !== id));
    }
  };
  const generateID = (prefix?: string) => `${prefix}_${v4()}`;

  const loadingValue = useMemo(() => ({ isLoading: loadingIds.length > 0 }), [loadingIds]);
  const loadingUtils = useMemo(() => ({ setLoadingState, generateID }), []);

  return (
    <LoadingContext.Provider value={loadingValue}>
      <UpdateLoadingContext.Provider value={loadingUtils}>{children}</UpdateLoadingContext.Provider>
    </LoadingContext.Provider>
  );
}

function useLoading() {
  const context = React.useContext(LoadingContext);
  if (context === undefined) {
    throw new Error("useLoading must be used within a LoadingContextProvider");
  }
  return context;
}

function useSetLoading() {
  const context = React.useContext(UpdateLoadingContext);
  if (context === undefined) {
    throw new Error("useSetLoading must be used within a LoadingContextProvider");
  }
  return context;
}

export interface ILoaderProps {
  componentName?: string;
}

function Loader({ componentName }: ILoaderProps) {
  const { setLoadingState, generateID } = useSetLoading();
  const [id] = useState(generateID(componentName));
  useEffect(() => {
    setLoadingState({
      id,
      loading: true,
    });

    return () => {
      setLoadingState({ id, loading: false });
    };
  }, []);

  return null;
}

export { LoadingContextProvider, useLoading, Loader };
