import { Form, Row, Col, Checkbox, DatePicker, Typography } from "antd";
import { FormInstance } from "antd/lib/form";
import dayjs, { Dayjs } from "dayjs";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { primaryColor } from "../layout";

const { RangePicker } = DatePicker;

const { Text } = Typography;

export interface ITherapyPeriodFormItemProps {
  /**
   * Field name of form item to later retrieve value from it
   */
  name: string;
  /**
   * Form Instance that is used in the current form
   */
  form: FormInstance;
  /**
   * End date of the study defined by the executor system runtime. Used to
   * disable dates after the study has ended.
   */
  endDate?: string | null;

  /**
   * If true, a checkbox will be shown to start the therapy immediately when the
   * participant is coupled.
   */
  showStartImmediatelyCheckbox?: boolean;
}

/**
 * Form element that can be used to set or update the therapy period of a
 * participant. Consists of a date range picker and two check boxes. The
 * checkboxes allow setting "start immediately when coupled" and "never finish".
 */
export function TherapyPeriodFormItem({
  name,
  form,
  endDate,
  showStartImmediatelyCheckbox,
}: ITherapyPeriodFormItemProps) {
  const [startImmediately, setStartImmediately] = useState(
    form.getFieldValue(name) ? form.getFieldValue(name)[0] === null : true,
  );
  const [endOnLastPossibleDate, setEndOnLastPossibleDate] = useState(
    form.getFieldValue(name)
      ? form.getFieldValue(name)[1] === null || dayjs(form.getFieldValue(name)[1]).isSame(endDate)
      : true,
  );

  const [initialStartDate] = useState(
    form.getFieldValue(name) ? form.getFieldValue(name)[0] : null,
  );
  const { t } = useTranslation();

  useEffect(() => {
    if (!form.getFieldValue(name)) {
      form.setFieldsValue({
        [name]: [null, null],
      });
    }
  }, [name, form]);

  return (
    <>
      <Form.Item
        name={name}
        rules={[
          () => ({
            validator(_, value: [Dayjs | null, Dayjs | null]) {
              return validateTherapyPeriod({
                start: value[0],
                end: value[1],
                systemEndDate: endDate || "",
                initialStartDate,
                startImmediately,
                endOnLastPossibleDate,
                t,
              });
            },
          }),
        ]}
        label={t("participant.therapyPeriodFormItem.title")}
        style={{ marginBottom: 0 }}
      >
        <RangePicker
          style={{ width: "100%" }}
          allowEmpty={[startImmediately, endOnLastPossibleDate]}
          disabled={[startImmediately, endOnLastPossibleDate]}
          minDate={dayjs().startOf("day")}
          maxDate={endDate ? dayjs(endDate).endOf("day") : undefined}
          placeholder={[
            startImmediately
              ? t("participant.therapyPeriodStatus.startsWhenCoupled")
              : t("participant.therapyPeriodFormItem.selectStartPlaceholder"),
            endOnLastPossibleDate
              ? t("participant.therapyPeriodStatus.noEnd")
              : t("participant.therapyPeriodFormItem.selectEndPlaceholder"),
          ]}
        />
      </Form.Item>
      <Form.Item>
        {!endOnLastPossibleDate && endDate ? (
          <Row>
            <Col span={12} offset={12}>
              <Text style={{ color: primaryColor }}>
                {t("participant.therapyPeriodFormItem.lastPossibleDate", {
                  endDate: dayjs(endDate).toDate().toLocaleDateString(),
                })}
              </Text>
            </Col>
          </Row>
        ) : null}
        <Row style={{ lineHeight: "32px" }}>
          <Col span={12}>
            {showStartImmediatelyCheckbox ? (
              <Checkbox
                checked={startImmediately}
                onChange={(e) => {
                  if (e.target.checked) {
                    const oldRangePickerValue = form.getFieldValue(name);
                    form.setFieldsValue({
                      [name]: [null, oldRangePickerValue[1]],
                    });
                  }
                  setStartImmediately(e.target.checked);
                }}
                style={{
                  lineHeight: "140%",
                }}
              >
                {t("participant.therapyPeriodFormItem.startCheckBox")}
              </Checkbox>
            ) : null}
          </Col>
          <Col span={12}>
            <Checkbox
              checked={endOnLastPossibleDate}
              onChange={(e) => {
                if (e.target.checked) {
                  const oldRangePickerValue = form.getFieldValue(name);
                  form.setFieldsValue({
                    [name]: [oldRangePickerValue[0], endDate ? dayjs(endDate).endOf("day") : null],
                  });
                }
                setEndOnLastPossibleDate(e.target.checked);
              }}
              style={{ lineHeight: "140%" }}
            >
              <div>
                {t("participant.therapyPeriodFormItem.endCheckBox")}
                <br />
                {endDate && dayjs(endDate).toDate().toLocaleDateString()}
              </div>
            </Checkbox>
          </Col>
        </Row>
      </Form.Item>
    </>
  );
}

export default TherapyPeriodFormItem;

/**
 * Validates the therapy period form item. Returns a promise that resolves if
 * the values are valid and rejects with an array of error messages if the form
 * is invalid.
 */
export const validateTherapyPeriod = ({
  start,
  end,
  systemEndDate,
  initialStartDate,
  startImmediately,
  endOnLastPossibleDate,
  t,
}: {
  start: Dayjs | null;
  end: Dayjs | null;
  systemEndDate: string;
  initialStartDate: Dayjs | null;
  startImmediately: boolean;
  endOnLastPossibleDate: boolean;
  t: TFunction<"translation", undefined>;
}) => {
  const startDateMissing = !startImmediately && start === null;
  const endDateMissing = !endOnLastPossibleDate && end === null;
  const startInPast = start?.isBefore(dayjs(), "day");
  const startWasEdited = !start?.isSame(initialStartDate, "day");
  const endInPast = end?.isBefore(dayjs(), "day");
  const endAfterSystemEndDate = end?.isAfter(dayjs(systemEndDate), "day");

  const possibleErrors = [
    {
      errorCondition: startDateMissing,
      message: t("participant.therapyPeriodFormItem.errors.startMissing"),
    },
    {
      errorCondition: endDateMissing,
      message: t("participant.therapyPeriodFormItem.errors.endMissing"),
    },
    {
      errorCondition: startWasEdited && startInPast,
      message: t("participant.therapyPeriodFormItem.errors.startInPast"),
    },
    {
      errorCondition: endInPast,
      message: t("participant.therapyPeriodFormItem.errors.endInPast"),
    },
    {
      errorCondition: endAfterSystemEndDate,
      message: t("participant.therapyPeriodFormItem.errors.endAfterSystemEnd"),
    },
  ];

  const errors = possibleErrors
    .filter((validation) => validation.errorCondition)
    .map((validation) => validation.message);

  if (errors.length > 0) {
    return Promise.reject(errors);
  }

  return Promise.resolve();
};
