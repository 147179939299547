import { ApolloError } from "@apollo/client";
import { notification } from "antd";
import { useTranslation } from "react-i18next";
import { UpdateStudyMemberMutation, useUpdateStudyMemberMutation } from "../codegen";

interface IUseUpdateStudyMemberProps {
  // eslint-disable-next-line no-unused-vars
  onCompleted?: (data: UpdateStudyMemberMutation) => void;
  // eslint-disable-next-line no-unused-vars
  onError?: (error: ApolloError) => void;
}

interface IUpdateStudyMember {
  studyId: string;
  userId: string;
  studyRoleId: string;
}

export function useUpdateStudyMember({
  onCompleted = () => {},
  onError = () => {},
}: IUseUpdateStudyMemberProps) {
  const { t } = useTranslation();
  const [mutate, { loading }] = useUpdateStudyMemberMutation({
    onCompleted: (data) => {
      notification.success({
        message: t("notifications.updateStudyMember.success", {
          roleName: data.updateStudyMember?.studyRole.name,
        }),
      });
      onCompleted(data);
    },
    onError: (error) => {
      notification.error({
        message: t("notifications.updateStudyMember.error"),
      });
      onError(error);
    },
  });

  const updateStudyMember = (props: IUpdateStudyMember) => {
    mutate({
      variables: {
        studyId: props.studyId,
        userId: props.userId,
        studyRoleId: props.studyRoleId,
      },
    });
  };

  return { updateStudyMember, loading };
}

export default useUpdateStudyMember;
