/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { QRCode, Result, message } from "antd";
import { QRCodeProps } from "antd/lib";
import React from "react";
import { useTranslation } from "react-i18next";

/**
 * Component to display a QR code that decodes an url to couple a smartphone.
 */
export function CoupleCode({ value, ...rest }: QRCodeProps) {
  const { t } = useTranslation();

  if (!value) {
    return <Result status="success" title={t("participant.actions.qrCode.confirm.success")} />;
  }
  if (value.includes("/coupleSmartphone?coupleKey=")) {
    return (
      <div data-testid="QR_Code" onClick={() => message.info(value)}>
        <QRCode value={value} {...rest} />
      </div>
    );
  }

  return (
    <Result
      status="warning"
      title={t("participant.actions.qrCode.problem.title")}
      subTitle={t("participant.actions.qrCode.problem.subTitle")}
    />
  );
}

export default CoupleCode;
