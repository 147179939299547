import { useTranslation } from "react-i18next";
import { useStudy } from "../../services";

export function useAllowedEmailDomains(studyId: string) {
  const { study } = useStudy(studyId);
  const { t } = useTranslation();
  const allowedDomains = study?.allowedEmailDomains?.split(",");

  const validationRule = () => ({
    async validator(_: any, value?: string) {
      if (study?.allowedEmailDomains == null) {
        return Promise.resolve();
      }
      if (value && allowedDomains?.includes(value.split("@")[1])) {
        return Promise.resolve();
      }
      return Promise.reject(
        new Error(
          t("participant.addSupervisorModal.errorMessage.domainNotAllowed", {
            domains: allowedDomains?.join(", "),
          }),
        ),
      );
    },
  });

  return { validationRule, allowedDomains };
}

export default useAllowedEmailDomains;
