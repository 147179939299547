import { Result } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

import BackHomeButton from "./BackHomeButton";

export function AccessDenied() {
  const { t } = useTranslation();
  return (
    <Result
      status="403"
      title="403"
      subTitle={t("common.results.accessDenied.subtitle")}
      extra={<BackHomeButton />}
    />
  );
}

export default AccessDenied;
