import { Alert, Select } from "antd";
import DOMPurify from "dompurify";
import React, { useState } from "react";
import { MultiVisualizationContainerProps, VisualizationObject } from "../../../../../services";
import Visualization from "./Visualization";

const { Option, OptGroup } = Select;

/**
 * Groups an array of VisualizationObjects according to their title. Each title
 * is examined after the separator ':'. The last occurrence of the separator in
 * the title separates the group name from the actual title of the
 * visualization. If the separator is not contained in the title, the
 * visualization will be assigned to the group 'default'.
 * @param visualizations
 * @returns
 */
const groupVisualizationsByTitle = (visualizations: VisualizationObject[]) => {
  const groups: { groupName: string; children: VisualizationObject[] }[] = [];

  visualizations.forEach((visualization) => {
    const lastIndexOfDivider = visualization.title.lastIndexOf(":");

    if (lastIndexOfDivider !== -1) {
      const groupName = visualization.title.slice(0, lastIndexOfDivider);
      const visualizationTitle = visualization.title.slice(lastIndexOfDivider + 1);
      const groupToAdd = groups.find((group) => group.groupName === groupName);
      if (groupToAdd) {
        groupToAdd.children.push({ ...visualization, title: visualizationTitle });
      } else {
        groups.push({ groupName, children: [{ ...visualization, title: visualizationTitle }] });
      }
    } else {
      const groupToAdd = groups.find((group) => group.groupName === "default");
      if (groupToAdd) {
        groupToAdd.children.push(visualization);
      } else {
        groups.push({ groupName: "default", children: [visualization] });
      }
    }
  });

  return groups;
};

/**
 * Container that contains multiple visualizations. Provides a drop-down field
 * to switch between visualizations
 * @param param0
 * @returns
 */
export function MultiVisualizationContainer({ children }: MultiVisualizationContainerProps) {
  const [selectedVisualizationId, setSelectedVisualizationId] = useState(
    children.length > 0 ? children[0]?.id : "",
  );

  const optionsGroups = groupVisualizationsByTitle(children);
  const selectedVisualization = children.find((child) => child.id === selectedVisualizationId);

  return (
    <>
      <Select
        style={{ width: 320, marginBottom: 24 }}
        defaultValue={children.length > 0 ? children[0].id : undefined}
        onChange={setSelectedVisualizationId}
        dropdownStyle={{ overflowY: "auto" }}
        placeholder="No Visualizations available"
      >
        {optionsGroups.map((group) =>
          group.groupName === "default" ? (
            <React.Fragment key="default">
              {group.children.map((child) => (
                <Option key={child.id} value={child.id}>
                  {child.title}
                </Option>
              ))}
            </React.Fragment>
          ) : (
            <OptGroup key={group.groupName} label={group.groupName}>
              {group.children.map((child) => (
                <Option key={child.id} value={child.id}>
                  {child.title}
                </Option>
              ))}
            </OptGroup>
          ),
        )}
      </Select>
      {selectedVisualization ? (
        <>
          <p
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(selectedVisualization.description),
            }}
          />
          <Visualization
            key={selectedVisualization.id}
            visualizationObject={selectedVisualization}
            downloadFileName="123"
          />
          {selectedVisualization.message ? (
            <Alert
              type={selectedVisualization.message.type}
              message={selectedVisualization.message.title}
              description={
                <div
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(selectedVisualization.message.message),
                  }}
                />
              }
              banner
              style={{ marginTop: 24, maxHeight: 200, overflowY: "auto" }}
            />
          ) : null}
        </>
      ) : null}
    </>
  );
}

export default MultiVisualizationContainer;
