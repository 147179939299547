import { useStudyId } from "../../../shared";
import { useGetExecutorUsageStatisticsQuery } from "../codegen";

export function useExecutorStatistics() {
  const { studyId } = useStudyId();
  const { data, ...rest } = useGetExecutorUsageStatisticsQuery({
    variables: { studyId },
  });

  return { usageStatistics: data?.getExecutorStatisticsAction, ...rest };
}

export default useExecutorStatistics;
