/* eslint-disable react/require-default-props */
import { Result } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import BackHomeButton from "./BackHomeButton";

export function ServerError({ showHomeButton = true }: { showHomeButton?: boolean }) {
  const { t } = useTranslation();

  return (
    <Result
      status="500"
      title="500"
      subTitle={t("common.results.internalError.subtitle")}
      extra={showHomeButton ? <BackHomeButton /> : null}
    />
  );
}

export default ServerError;
