import { Alert, Divider, Space, Typography } from "antd";
import DOMPurify from "dompurify";
import React from "react";

import { OptionsMessage, Position } from "../../../../../services";
import VegaContainer from "./VegaContainer";

const { Text } = Typography;

export interface ISelectVegaProps {
  optionsPosition?: Position;
  children: React.ReactNode;
  vegaSpec: any;
  data: any;
  vegaRendererProps?: any;
  downloadFileName?: string;
  infoMessages?: OptionsMessage[];
  showNoDataOverlay?: Boolean;
}

/**
 * Layout container for displaying Vega visualizations together with a control element
 */
export function SelectVegaVisualization({
  data,
  children,
  vegaSpec,
  vegaRendererProps,
  optionsPosition = "bottom",
  downloadFileName,
  infoMessages,
  showNoDataOverlay,
}: ISelectVegaProps) {
  const vegaVisualization = (
    <VegaContainer
      spec={vegaSpec}
      downloadFileName={downloadFileName}
      data={data}
      showNoDataOverlay={showNoDataOverlay}
      {...vegaRendererProps}
    />
  );

  const informationBox =
    infoMessages &&
    infoMessages.map((info) => (
      <Alert
        key={info.key}
        style={{ marginBottom: 4 }}
        message={
          <>
            <Text strong>{info.title}</Text>
            <Text>
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(info.message),
                }}
              />
            </Text>
          </>
        }
        type={info.type}
      />
    ));

  switch (optionsPosition) {
    case "top":
      return (
        <>
          {children}
          {informationBox}
          <Divider style={{ marginTop: informationBox ? 0 : undefined }} />

          {vegaVisualization}
        </>
      );

    case "left":
      return (
        <Space>
          {children}
          <div>
            {informationBox}
            {vegaVisualization}
          </div>
        </Space>
      );

    case "right":
      return (
        <Space align="start">
          <div>
            {informationBox}
            {vegaVisualization}
          </div>
          <div style={{ paddingTop: 44, minWidth: 200 }}>{children}</div>
        </Space>
      );

    default:
      return (
        <>
          {informationBox}
          {vegaVisualization}

          <Divider />
          {children}
        </>
      );
  }
}
