/* eslint-disable react/require-default-props */
import React from "react";
import { List, Typography } from "antd";
import { RiUserHeartLine } from "react-icons/ri";
import { Link } from "react-router-dom";

import Highlighter from "react-highlight-words";

import { usePermission, ActionEnum } from "../../services";

import { highlighterColor, primaryColor, Paths } from "..";

const { Text } = Typography;

export interface IParticipantListItemProps {
  id: string;
  pseudonym: string;
  studyId: string;
  studyName?: string;
  groupName?: string;
  numOfSupervisors?: number;
  searchStringToHighlight?: string;
}

interface IBaseParticipantListItemProps extends IParticipantListItemProps {
  children?: React.ReactNode;
  extra?: React.ReactNode;
}

export function BaseParticipantListItem({
  id,
  studyId,
  pseudonym,
  groupName,
  extra,
  searchStringToHighlight,
  children,
}: IBaseParticipantListItemProps) {
  const participantRoutes = Paths.getStudyPaths(studyId).getParticipantsPaths(id);
  const { permitted, loading } = usePermission({
    action: ActionEnum.STUDY_VIEW_GROUP_ASSIGNMENT,
    studyId,
  });
  usePermission({
    action: ActionEnum.PARTICIPANT_VIEW_DETAILS,
    participantId: id,
  });

  const getGroupName = () => {
    if (loading) {
      return "...";
    }
    return permitted ? groupName : "";
  };

  return (
    <List.Item key={id} extra={[extra]}>
      <List.Item.Meta
        avatar={<RiUserHeartLine size={20} />}
        title={
          <Text style={{ color: primaryColor, fontWeight: 500 }}>
            <Link to={participantRoutes.DASHBOARD}>
              <Highlighter
                highlightStyle={{ background: highlighterColor }}
                searchWords={[searchStringToHighlight || ""]}
                autoEscape
                textToHighlight={pseudonym}
              />
            </Link>
          </Text>
        }
        description={getGroupName()}
      />

      {children}
    </List.Item>
  );
}
