import { Badge, Card } from "antd";
import React from "react";

const cardStyle = {
  minHeight: 280,
  borderWidth: 2,
  borderRadius: 10,
  width: "100%",
};

interface IOverviewCardProps {
  title: string;
  ribbonIcon: React.ReactNode;
  // eslint-disable-next-line react/require-default-props
  children?: React.ReactNode;
}

function OverviewCard({ title, ribbonIcon, children }: IOverviewCardProps) {
  return (
    <Badge.Ribbon placement="start" text={ribbonIcon}>
      <Card style={cardStyle}>
        <Card.Meta
          title={title}
          description={<div style={{ textAlign: "center" }}>{children}</div>}
        />
      </Card>
    </Badge.Ribbon>
  );
}

export default OverviewCard;
