import { TeamOutlined } from "@ant-design/icons";
import { Button, Divider, Space, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { RiUserHeartLine } from "react-icons/ri";
import { useNavigate } from "react-router";
import { Paths } from "../../../../shared";

import OverviewCard from "./OverviewCard";

const { Paragraph, Text } = Typography;

interface IParticipantCardProps {
  studyId: string;
  numberOfParticipants: number;
  numberOfParticipantsGroups: number;
}

/**
 * Card for Study Overview page, that holds some top level information about the participants
 */
export function ParticipantCard({
  studyId,
  numberOfParticipants,
  numberOfParticipantsGroups,
}: IParticipantCardProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <OverviewCard
      title={t("study.overview.participantCard.title")}
      ribbonIcon={<RiUserHeartLine />}
    >
      <Paragraph type="secondary">{t("study.overview.participantCard.description.1")}</Paragraph>
      <Paragraph>
        <Space>
          <RiUserHeartLine />
          <Text strong>{numberOfParticipants}</Text>
          <Text>{t("study.overview.participantCard.description.2")}</Text>
        </Space>
      </Paragraph>
      <Paragraph>
        <Text type="secondary">{t("study.overview.participantCard.description.3")}</Text>
      </Paragraph>
      <Paragraph>
        <Space>
          <TeamOutlined />
          <Text strong>{numberOfParticipantsGroups}</Text>
          <Text>{t("study.overview.participantCard.description.4")}</Text>
        </Space>
      </Paragraph>
      <Divider />
      <Space>
        <Button
          type="link"
          onClick={() => navigate(Paths.getStudyPaths(studyId).PARTICIPANTS_GROUPS)}
        >
          {t("study.overview.participantCard.buttons.manageParticipantGroups")}
        </Button>
        <Button
          type="link"
          onClick={() => navigate(Paths.getStudyPaths(studyId).PARTICIPANTS)}
          disabled={numberOfParticipantsGroups === 0}
        >
          {t("study.overview.participantCard.buttons.manageParticipants")}
        </Button>
      </Space>
    </OverviewCard>
  );
}

export default ParticipantCard;
