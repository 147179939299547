/* eslint-disable react/require-default-props */
import { Avatar } from "antd";
import React from "react";
import stc from "string-to-color";

interface IStudyLogoProps {
  src?: string | null;
  studyTitle?: string;
}

export function StudyLogo({ src, studyTitle }: IStudyLogoProps) {
  return src ? (
    <Avatar shape="square" src={src} />
  ) : (
    <Avatar style={{ background: stc(studyTitle?.charAt(0).toUpperCase()) }} shape="square">
      {studyTitle?.charAt(0).toUpperCase()}
    </Avatar>
  );
}

export default StudyLogo;
