import React, { useState } from "react";

import { List, Empty, Divider } from "antd";

import { useTranslation } from "react-i18next";
import { GetOrganizationQuery } from "../../services";
import { PageHeader, SearchField, StudyListItem, useCachedOrganization } from "../../shared";

type Study = NonNullable<GetOrganizationQuery["organization"]>["studies"][number];

export function OrganizationStudies() {
  const { organization } = useCachedOrganization();
  const { t } = useTranslation();
  const { studies } = organization;
  const [searchResult, setSearchResult] = useState({ searchText: "", items: studies || [] });

  const filter = (studiesToFilter: Study[]) => {
    const filterForSearchText = studiesToFilter.filter((study) =>
      searchResult.items.find((item) => item.id === study.id),
    );
    return filterForSearchText;
  };

  return (
    <>
      <PageHeader
        hideDivider
        title={t("organization.studies.title", { organization: organization.name })}
        description={t("organization.studies.description")}
      />
      <SearchField
        allItems={studies?.map((study) => ({
          item: study,
          searchFields: study.description ? [study.name, study.description] : [study.name],
        }))}
        placeholderText={t("organization.studies.searchPlaceholder")}
        onSearch={setSearchResult}
        style={{ float: "left", width: 240, marginBottom: 16 }}
      />

      <Divider />
      <List<Study>
        itemLayout="horizontal"
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t("organization.studies.noStudies")}
            />
          ),
        }}
        size="small"
        pagination={{
          hideOnSinglePage: true,
          pageSize: 5,
        }}
        dataSource={filter(studies)}
        renderItem={(item) => (
          <StudyListItem
            searchStringToHighlight={searchResult.searchText}
            disableLink
            hideOrganizationName
            studyId={item.id}
            studyName={item.name}
            studyDescription={item.description}
            studyLogo={item.logo ? item.logo : undefined}
            studyCreationDate={item.createdAt}
            organizationName={organization.name}
          />
        )}
      />
    </>
  );
}

export default OrganizationStudies;
