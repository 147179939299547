import React from "react";
import { IGetStateProps, useParticipantState } from "../../../../../shared";

export function ParticipantStateTag(props: IGetStateProps) {
  const state = useParticipantState(props);

  return (
    <div
      style={{
        background: state.style.backgroundColor,
        color: state.style.textColor,
        borderColor: state.style.borderColor,
        borderStyle: "solid",
        borderWidth: 1,
        fontWeight: 400,
        paddingLeft: 8,
        paddingRight: 8,
      }}
    >
      {state.name}
    </div>
  );
}

export default ParticipantStateTag;
