import { useGetOrganizationMembersQuery } from "../codegen";

export function useOrganizationMembers(organizationId: string) {
  const { data, loading, refetch } = useGetOrganizationMembersQuery({
    variables: { organizationId },
    fetchPolicy: "cache-and-network",
  });

  return { organizationMembers: data?.getOrganizationMembersAction, loading, refetch };
}

export default useOrganizationMembers;
