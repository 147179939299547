/* eslint-disable camelcase */
import React from "react";
import { Typography, Card, Space, Button, Row, Col, Badge, App } from "antd";
import { FaBaby } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  DeploymentUnitOutlined,
  ExclamationCircleOutlined,
  HomeOutlined,
  SmileOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Loader,
  OrganizationRoleEnum,
  OrganizationRoleTag,
  PageHeader,
  Paths,
  StudyStateEnum,
  StudyStateTag,
  useOrganizationId,
  useUserId,
} from "../../shared";
import {
  useLeaveOrganization,
  useOrganization,
  useStudyCountOfUser,
  OrganizationFromQuery,
} from "../../services";

const { Paragraph, Text } = Typography;
const cardStyle = { minHeight: 280, borderWidth: 2, borderRadius: 10, width: "100%" };
const colSpan = 11;

export function OrganizationDetail() {
  const { userId } = useUserId();
  const { organizationId } = useOrganizationId();
  const { t } = useTranslation();
  const { organization } = useOrganization(organizationId);
  const { studyCount, loading } = useStudyCountOfUser({ userId, organizationId });
  const navigate = useNavigate();
  const { modal } = App.useApp();
  const { leaveOrganization, canLeave } = useLeaveOrganization({
    organizationId,
    onCompleted: () => navigate(Paths.HOME),
  });

  if (loading || !organization) {
    return <Loader />;
  }

  const handleLeaveOrganization = () => {
    if (canLeave) {
      modal.confirm({
        title: t("organization.leave.confirm.title", { organization: organization?.name }),
        icon: <ExclamationCircleOutlined />,
        content: t("organization.leave.confirm.content"),
        okText: t("common.yes"),
        okType: "danger",
        cancelText: t("common.no"),
        onOk() {
          leaveOrganization();
        },
      });
    } else {
      modal.error({
        title: t("organization.leave.error.title", { organization: organization.name }),
        icon: <ExclamationCircleOutlined />,
        content: t("organization.leave.error.content"),
        okText: t("common.ok"),
        okType: "default",
        cancelButtonProps: { hidden: true },
      });
    }
  };

  const currentUserAsMember = organization.organizationMembers.find(
    (member) => member.userId === userId,
  );
  const dateUSerJoinedOrganization = new Date(currentUserAsMember?.createdAt);
  const rowHorizontalGutter = 8;
  const rowVerticalGutter = 24;

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <PageHeader
        title={t("organization.overview.title", { organization: organization.name })}
        extra={[
          <Button key="leaveButton" onClick={handleLeaveOrganization}>
            {t("organization.overview.leaveButton")}
          </Button>,
        ]}
      >
        <Paragraph
          style={{ textAlign: "justify" }}
          ellipsis={{
            rows: 5,
            expandable: true,
          }}
        >
          {organization.description}
        </Paragraph>
      </PageHeader>

      <div style={{ textAlign: "center" }}>
        <Row justify="space-around" gutter={[rowHorizontalGutter, rowVerticalGutter]}>
          <Col span={colSpan}>
            <OrganizationContactCard organization={organization} />
          </Col>

          <Col span={colSpan}>
            <OrganizationMembersCard
              numOfMemberRoles={
                organization.organizationMembers.filter(
                  (member) => member.organizationRole.name === "MEMBER",
                ).length
              }
              numOfOwnerRoles={
                organization.organizationMembers.filter(
                  (member) => member.organizationRole.name === "OWNER",
                ).length
              }
              totalNumOfMembers={organization.organizationMembers.length}
            />
          </Col>
          <Col span={colSpan}>
            {currentUserAsMember && (
              <UserRoleCard
                roleCreationDate={dateUSerJoinedOrganization}
                roleName={currentUserAsMember.organizationRole.name}
                numOfStudiesOfUser={studyCount}
              />
            )}
          </Col>
        </Row>
      </div>
    </>
  );
}

export default OrganizationDetail;

interface IUserRoleCardProps {
  roleCreationDate: Date;
  roleName: string;
  // eslint-disable-next-line react/require-default-props
  numOfStudiesOfUser?: number;
}

function UserRoleCard({ roleCreationDate, roleName, numOfStudiesOfUser }: IUserRoleCardProps) {
  const { t } = useTranslation();
  return (
    <Badge.Ribbon placement="start" text={<SmileOutlined />}>
      <Card style={cardStyle}>
        <Card.Meta
          title={t("organization.overview.userCard.title")}
          description={
            <div style={{ textAlign: "center" }}>
              <Paragraph>
                <Text type="secondary">{t("organization.overview.userCard.memberSince")}</Text>
                <br />
                <Space>
                  <FaBaby />
                  <Text>{roleCreationDate.toLocaleDateString()}</Text>
                </Space>
              </Paragraph>
              <Paragraph>
                <Space>
                  <Text type="secondary">{t("organization.overview.userCard.yourRole")}</Text>

                  <OrganizationRoleTag role={roleName as OrganizationRoleEnum} />
                </Space>
              </Paragraph>
              <Paragraph>
                <Text type="secondary">{t("organization.overview.userCard.description.1")}</Text>
                <br />
                <Text strong>
                  {numOfStudiesOfUser} {t("common.study", { count: numOfStudiesOfUser })}
                </Text>
                <br />
                <Text type="secondary">{t("organization.overview.userCard.description.2")}</Text>
              </Paragraph>
            </div>
          }
        />
      </Card>
    </Badge.Ribbon>
  );
}

interface IOrganizationMembersCard {
  totalNumOfMembers: number;
  numOfOwnerRoles: number;
  numOfMemberRoles: number;
}

function OrganizationMembersCard({
  totalNumOfMembers,
  numOfMemberRoles,
  numOfOwnerRoles,
}: IOrganizationMembersCard) {
  const { t } = useTranslation();
  return (
    <Badge.Ribbon placement="start" text={<UserOutlined />}>
      <Card style={cardStyle}>
        <Card.Meta
          title={t("organization.overview.organizationCard.title")}
          description={
            <div style={{ textAlign: "center" }}>
              <Paragraph>
                <Text type="secondary">
                  {t("organization.overview.organizationCard.description.1")}
                </Text>
                <br />
                <Space>
                  <Text strong>{totalNumOfMembers}</Text>
                  <Text>{t("common.member", { count: totalNumOfMembers })}</Text>
                </Space>
              </Paragraph>
              <Paragraph>
                <Text type="secondary">
                  {" "}
                  {t("organization.overview.organizationCard.description.2")}
                </Text>
              </Paragraph>
              <Paragraph>
                <Space>
                  <TeamOutlined />
                  <Text>{numOfOwnerRoles}</Text>
                  <OrganizationRoleTag role={OrganizationRoleEnum.OWNER} />
                </Space>
              </Paragraph>
              <Paragraph>
                <Space>
                  <TeamOutlined />
                  <Text>{numOfMemberRoles}</Text>
                  <OrganizationRoleTag role={OrganizationRoleEnum.MEMBER} />
                </Space>
              </Paragraph>
            </div>
          }
        />
      </Card>
    </Badge.Ribbon>
  );
}

interface IOrganizationStudiesCardProps {
  totalNumOfStudies: number;
  inPreparation: number;
  running: number;
  finished: number;
  deleted: number;
}

// eslint-disable-next-line no-unused-vars
function OrganizationStudiesCard({
  totalNumOfStudies,
  inPreparation,
  running,
  finished,
  deleted,
}: IOrganizationStudiesCardProps) {
  return (
    <Badge.Ribbon placement="start" text={<DeploymentUnitOutlined />}>
      <Card style={cardStyle}>
        <Card.Meta
          title="Studies"
          description={
            <div style={{ textAlign: "center" }}>
              <Paragraph>
                <Text type="secondary">The organization launches</Text>
                <br />
                <Space>
                  <Text strong>{totalNumOfStudies}</Text>
                  <Text>Studies</Text>
                </Space>
              </Paragraph>

              <Paragraph>
                <Space>
                  <Text strong>{inPreparation}</Text>
                  <StudyStateTag state={StudyStateEnum.INPREPARATION} />
                </Space>
              </Paragraph>
              <Paragraph>
                <Space>
                  <Text strong>{running}</Text>
                  <StudyStateTag state={StudyStateEnum.RUNNING} />
                </Space>
              </Paragraph>
              <Paragraph>
                <Space>
                  <Text strong>{finished}</Text>
                  <StudyStateTag state={StudyStateEnum.FINISHED} />
                </Space>
              </Paragraph>
              <Paragraph>
                <Space>
                  <Text strong>{deleted}</Text>
                  <StudyStateTag state={StudyStateEnum.DELETED} />
                </Space>
              </Paragraph>
            </div>
          }
        />
      </Card>
    </Badge.Ribbon>
  );
}

interface IOrganizationContactCard {
  organization: OrganizationFromQuery;
}

function OrganizationContactCard({ organization }: IOrganizationContactCard) {
  const { t } = useTranslation();
  return (
    <Badge.Ribbon placement="start" text={<HomeOutlined />}>
      <Card style={cardStyle}>
        <Card.Meta
          title={t("organization.overview.contactCard.title")}
          description={
            <>
              <Paragraph>
                <strong>{organization.name}</strong> <br />
                {organization.department}
                {organization.address} <br />
                {organization.zip} {organization.city} <br />
                {organization.country}
              </Paragraph>
              {/* <Paragraph>
                <Space>
                  <PhoneOutlined />
                  <Text>+49 721 9654-760</Text>
                </Space>
                <br />
                <Space>
                  <MailOutlined />
                  <a href="mailto:info@yinfo.com">info@info.com</a>
                </Space>
                <br />
                <Space>
                  <HomeOutlined />
                  <a href="https:\\movisens.com">movisens.com</a>
                </Space>
              </Paragraph> */}
            </>
          }
        />
      </Card>
    </Badge.Ribbon>
  );
}
