import { Checkbox, DatePicker, Form, Input, InputNumber, Modal } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { ExecutorFromQuery, useUpdateExecutor } from "../../../services";

interface IEditExecutorModal {
  executor: ExecutorFromQuery;
  open: boolean;
  onCancel: () => void;
}

function EditExecutorModal({ executor, open, onCancel }: IEditExecutorModal) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { updateExecutor, loading } = useUpdateExecutor({});

  const handleFinish = async (values: any) => {
    await updateExecutor({
      id: executor.id,
      executorUrl: values.executorUrl,
      executorUrlExternal: values.executorUrlExternal,
      participantLimit: values.participantLimit,
      name: values.name,
      hidden: !values.hidden,
      expiresAt: values.expiresAt?.endOf("day").toISOString(),
    });
    handleCancel();
  };

  const handleCancel = () => {
    onCancel();
    form.resetFields();
  };

  return (
    <Modal
      open={open}
      title={t("management.executor.editExecutorModal.title")}
      okText={t("common.save")}
      cancelText={t("common.cancel")}
      // @ts-ignore
      cancelButtonProps={{ "data-id": "common.cancel" }}
      onCancel={handleCancel}
      // @ts-ignore
      okButtonProps={{ "data-id": "common.create", loading }}
      onOk={() => form.submit()}
      destroyOnClose
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        onFinish={handleFinish}
        initialValues={{
          name: executor.name,
          executorUrl: executor.executorUrl,
          executorUrlExternal: executor.executorUrlExternal,
          participantLimit: executor.participantLimit,
          expiresAt: executor.expiresAt ? dayjs(executor.expiresAt) : undefined,
          hidden: !executor.hidden,
        }}
      >
        <Form.Item
          name="name"
          label={t("management.executor.createExecutorModal.executorNameLabel")}
          rules={[
            { required: true, message: t("management.executor.createExecutorModal.missingName") },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="executorUrl"
          label={t("management.executor.createExecutorModal.executorUrlLabel")}
          rules={[
            {
              required: true,
              message: t("management.executor.createExecutorModal.missingUrl"),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="executorUrlExternal"
          label={t("management.executor.createExecutorModal.executorUrlLabelExternal")}
          rules={[
            {
              required: true,
              message: t("management.executor.createExecutorModal.missingUrl"),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="participantLimit"
          label={t("management.executor.executorListItem.executorParticipantLimit")}
          rules={[
            { required: true, message: t("management.executor.createExecutorModal.missingLimit") },
          ]}
        >
          <InputNumber type="number" min={-1} step={1} precision={0} style={{ width: 200 }} />
        </Form.Item>
        <Form.Item
          name="expiresAt"
          label={t("management.executor.executorListItem.executorExpirationDate")}
        >
          <DatePicker
            style={{ width: 200 }}
            disabledDate={(date) => date.isBefore(dayjs())}
            showToday={false}
          />
        </Form.Item>
        <Form.Item name="hidden" valuePropName="checked" noStyle>
          <Checkbox>{t("management.executor.editExecutorModal.allowStudyCreation")}</Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default EditExecutorModal;
