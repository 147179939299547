import React from "react";
import { usePermission, ActionEnum, useParticipantTherapyConfig } from "../../../services";
import {
  LoadingHandler,
  useEditableTherapyElements,
  useParticipantId,
  useStudyId,
} from "../../../shared";
import { ParticipantTherapyConfigContent } from "./components";

export function ParticipantTherapyConfig() {
  const { participantId } = useParticipantId();
  const { studyId } = useStudyId();

  const { editableTherapyElements, loading: therapyDesignLoading } = useEditableTherapyElements({
    studyId,
    configurationLevel: "THERAPIST",
  });

  const {
    loading: canEditParticipantTherapyConfigLoading,
    permitted: canEditParticipantTherapyConfig,
  } = usePermission({
    action: ActionEnum.PARTICIPANT_EDIT_THERAPY_CONFIG,
    participantId,
  });
  const { therapyConfig, loading: therapyConfigLoading } =
    useParticipantTherapyConfig(participantId);

  const loading =
    canEditParticipantTherapyConfigLoading || therapyConfigLoading || therapyDesignLoading;

  return (
    <LoadingHandler loading={loading}>
      <ParticipantTherapyConfigContent
        participantId={participantId}
        hasPermissionToEdit={canEditParticipantTherapyConfig}
        elementsWithEditableVariables={editableTherapyElements}
        therapyConfig={therapyConfig?.map((item) => ({
          therapyVariableId: item?.therapyVariableId,
          therapyVariableValue: item?.variableValue,
        }))}
      />
    </LoadingHandler>
  );
}

export const ParticipantTherapyConfigMemo = React.memo(ParticipantTherapyConfig);
