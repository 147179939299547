import { ApolloError } from "@apollo/client";
import { notification } from "antd";
import { useTranslation } from "react-i18next";

import { useUserId } from "../../../shared";
import {
  CreateStudyMutation,
  GetExecutorsDocument,
  GetOrganizationDocument,
  GetUserDocument,
  useCreateStudyMutation,
} from "../codegen";

interface IUseCreateStudyProps {
  // eslint-disable-next-line no-unused-vars
  onCompleted?: (data: CreateStudyMutation) => void;
  // eslint-disable-next-line no-unused-vars
  onError?: (error: ApolloError) => void;
}

interface ICreateStudyProps {
  name: string;
  description?: string;
  organizationId: string;
  executorId: string;
}

// eslint-disable-next-line no-unused-vars
export function useCreateStudy({
  onCompleted = () => {},
  onError = () => {},
}: IUseCreateStudyProps) {
  const { t } = useTranslation();
  const { userId } = useUserId();

  const [mutate, { ...rest }] = useCreateStudyMutation({
    onCompleted: (data) => {
      notification.success({
        message: t("notifications.createStudy.success", {
          studyName: data.createStudyAction?.name,
        }),
      });

      onCompleted(data);
    },
    onError: (error) => {
      notification.error({
        message: t("notifications.createStudy.error"),
      });
      onError(error);
    },
  });

  const createStudy = (props: ICreateStudyProps) => {
    mutate({
      variables: {
        name: props.name,
        description: props.description,
        executorId: props.executorId,
        organizationId: props.organizationId,
        userId,
      },
      refetchQueries: [
        { query: GetUserDocument, variables: { userId } },
        { query: GetOrganizationDocument, variables: { organizationId: props.organizationId } },
        { query: GetExecutorsDocument },
      ],
    });
  };

  return { createStudy, ...rest };
}

export default useCreateStudy;
