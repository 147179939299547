import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Typography, Button, Alert, Empty, Select, Space, Table } from "antd";
import { RiUserHeartLine } from "react-icons/ri";
import { UserAddOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { ColumnType } from "antd/lib/table";
import Highlighter from "react-highlight-words";
import dayjs from "dayjs";
import {
  usePermission,
  ActionEnum,
  useExecutorParticipants,
  ExecutorParticipant as Participant,
  useExecutorStatistics,
} from "../../../services";
import {
  LoadingHandler,
  PageHeader,
  ParticipantStateFilterSingle,
  SearchField,
  getStateName,
  ParticipantStateEnum,
  Paths,
  IGetStateProps,
  StateWithIcon,
  ParticipantActionsMenuButton,
  IParticipantStateActionsProps,
  highlighterColor,
  primaryColor,
  useStudyId,
  useCachedStudy,
} from "../../../shared";
import { CreateParticipantModal } from "./components";
import { ExecutorStatisticsAlerts } from "../components";

const { Text } = Typography;
const { Option } = Select;

export interface IColumnType {
  key: string;
  pseudonym: string;
  participantGroup: string;
  therapyStart?: string | null;
  therapyEnd?: string | null;
  state: IGetStateProps;
  actions: {
    therapyPeriodId: string;
    therapyStart?: string | null;
    therapyEnd?: string | null;
    coupleUrl?: string | null;
    participantId: string;
    pseudonym: string;
    studyId: string;
    coupledAt: string | null;
  };
}
/**
 * Defines the columns for the participant overview table. During use one can
 * provide a translation function and/or a subString that should be highlighted.
 * @param t
 * @returns
 */
const participantsTableColumns = (t: any, searchStringToHighlight: string | undefined) => {
  const columns: ColumnType<IColumnType>[] = [
    {
      title: "",
      width: 40,
      dataIndex: "icon",
      key: "icon",
      render: () => (
        <RiUserHeartLine
          style={{
            borderRadius: "50%",
            borderStyle: "solid",
            borderColor: "grey",
            borderWidth: 1,
            fontSize: 28,
            padding: 4,
            color: "grey",
          }}
        />
      ),
    },
    {
      title: t("study.participants.table.title.pseudonym"),
      dataIndex: "pseudonym",
      key: "pseudonym",
      sorter: (a, b) => a.pseudonym.localeCompare(b.pseudonym),
      render: (pseudonym: string, record) => {
        const participantRoutes = Paths.getStudyPaths(record.actions.studyId).getParticipantsPaths(
          record.actions.participantId,
        );
        return (
          <Text strong style={{ color: primaryColor }}>
            <Link to={participantRoutes.DASHBOARD}>
              <Highlighter
                highlightStyle={{ background: highlighterColor }}
                searchWords={[searchStringToHighlight || ""]}
                autoEscape
                textToHighlight={pseudonym}
              />
            </Link>
          </Text>
        );
      },
    },
    {
      title: t("study.participants.table.title.participantGroup"),
      dataIndex: "participantGroup",
      key: "participantGroup",
    },
    {
      title: t("study.participants.table.title.start"),
      dataIndex: "therapyStart",
      key: "therapyStart",
      sorter: (a, b) => {
        if (a.therapyStart === b.therapyStart) {
          return 0;
        }
        if (a.therapyStart == null && b.therapyStart !== null) {
          return -1;
        }

        if (a.therapyStart !== null && b.therapyStart === null) {
          return 1;
        }

        return dayjs(a.therapyStart).diff(dayjs(b.therapyStart));
      },

      render: (therapyStart: string | null) =>
        therapyStart ? (
          <Text>
            {dayjs(therapyStart)
              .toDate()
              .toLocaleDateString(undefined, { dateStyle: "medium" } as any)}
          </Text>
        ) : (
          <Text type="secondary" italic>
            {t("study.participants.table.text.whenCoupled")}
          </Text>
        ),
    },
    {
      title: t("study.participants.table.title.end"),
      dataIndex: "therapyEnd",
      key: "therapyEnd",
      sorter: (a, b) => {
        if (a.therapyEnd === b.therapyEnd) {
          return 0;
        }
        if (a.therapyEnd == null && b.therapyEnd !== null) {
          return -1;
        }

        if (a.therapyEnd !== null && b.therapyEnd === null) {
          return 1;
        }

        return dayjs(a.therapyEnd).diff(dayjs(b.therapyEnd));
      },
      render: (therapyEnd: string | null) =>
        therapyEnd ? (
          <Text>
            {dayjs(therapyEnd)
              .toDate()
              .toLocaleDateString(undefined, { dateStyle: "medium" } as any)}
          </Text>
        ) : (
          <Text type="secondary" italic>
            {t("study.participants.table.text.never")}
          </Text>
        ),
    },
    {
      title: t("study.participants.table.title.state"),
      dataIndex: "state",
      key: "state",
      render: (state: IGetStateProps) => StateWithIcon({ ...state }),
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      align: "right",
      render: (props: IParticipantStateActionsProps) => <ParticipantActionsMenuButton {...props} />,
    },
  ];
  return columns;
};

const filterParticipantByGroupId = ({
  groupId,
  participant,
}: {
  groupId: string;
  participant: Participant;
}) => {
  if (groupId === "all") {
    return true;
  }
  return groupId === participant.participant.participantGroup.id;
};

const filterParticipantByState = ({
  state,
  participant,
}: {
  state: string;
  participant: Participant;
}) => {
  if (state === "all") {
    return true;
  }
  return (
    state ===
    getStateName({
      coupleUrl: participant.coupleUrl,
      therapyEnd: participant.therapyEnd,
      therapyStart: participant.therapyStart,
    })
  );
};

const filterParticipantBySearch = ({
  searchResult,
  participant,
}: {
  searchResult: Participant[];
  participant: Participant;
}) => searchResult.find((item) => item.id === participant.id);

export function ParticipantsOverview() {
  const { studyId } = useStudyId();
  const { study } = useCachedStudy();
  const { therapyId, participantGroups } = study;

  const [searchResult, setSearchResult] = useState({
    searchText: "",
    items: [] as Participant[],
  });
  const [isAddParticipantModalVisible, setIsAddParticipantModalVisible] = useState(false);
  const [groupFilter, setGroupFilter] = useState("all");
  const [participantStateFilter, setParticipantStateFilter] = useState<
    "all" | ParticipantStateEnum
  >("all");
  const { participants, loading: getParticipantsLoading } = useExecutorParticipants(therapyId);
  const { loading: canAddParticipantLoading, permitted: canAddParticipants } = usePermission({
    action: ActionEnum.STUDY_CREATE_PARTICIPANT,
    studyId,
  });
  const { usageStatistics, loading: executorStatisticsLoading } = useExecutorStatistics();
  const { t } = useTranslation();
  const studyRoutes = Paths.getStudyPaths(studyId);

  const loading = canAddParticipantLoading || getParticipantsLoading || executorStatisticsLoading;

  const createInfoAlerts = (studyHasGroups: boolean, studyHasParticipants: boolean) => {
    if (!studyHasGroups) {
      return (
        <Alert
          style={{ marginTop: 12 }}
          message={t("study.participants.noGroups.title")}
          description={
            <Text>
              {t("study.participants.noGroups.description.1")}{" "}
              <Link to={studyRoutes.PARTICIPANTS_GROUPS}>
                <b>{t("study.participants.noGroups.description.2")}</b>
              </Link>{" "}
              {t("study.participants.noGroups.description.3")}
            </Text>
          }
          type="warning"
          showIcon
          closable
        />
      );
    }
    if (!studyHasParticipants) {
      return (
        <Alert
          style={{ marginTop: 12 }}
          message={t("study.participants.firstParticipant.title")}
          description={t("study.participants.firstParticipant.description")}
          type="info"
          showIcon
          closable
        />
      );
    }

    return null;
  };

  const preparedParticipants: IColumnType[] = (participants || [])
    .filter((participant) => filterParticipantByGroupId({ participant, groupId: groupFilter }))
    .filter((participant) =>
      filterParticipantByState({ state: participantStateFilter, participant }),
    )
    .filter((participant) =>
      filterParticipantBySearch({ searchResult: searchResult.items, participant }),
    )
    .sort(
      (participant1, participant2) =>
        new Date(participant2.participant.createdAt).getTime() -
        new Date(participant1.participant.createdAt).getTime(),
    )
    .map((participant) => ({
      key: participant.id,
      pseudonym: participant.participant.pseudonym,
      participantGroup: participant.participant.participantGroup.name,
      therapyStart: participant.therapyStart,
      therapyEnd: participant.therapyEnd,
      state: {
        coupleUrl: participant.coupleUrl,
        therapyStart: participant.therapyStart,
        therapyEnd: participant.therapyEnd,
      },
      actions: {
        coupleUrl: participant.coupleUrl,
        participantId: participant.id,
        pseudonym: participant.participant.pseudonym,
        studyId,
        therapyEnd: participant.therapyEnd,
        therapyPeriodId: participant.therapyPeriodId,
        therapyStart: participant.therapyStart,
        coupledAt: participant.participant?.coupledAt,
      },
    }));

  return (
    <LoadingHandler loading={loading}>
      <PageHeader
        title={t("study.participants.title")}
        description={t("study.participants.description")}
        hideDivider
        style={{ marginBottom: 24, padding: 0 }}
      >
        {createInfoAlerts(
          participantGroups !== undefined && participantGroups.length > 0,
          participants !== undefined && participants.length > 0,
        )}
        <ExecutorStatisticsAlerts />
      </PageHeader>
      <Space>
        <SearchField
          allItems={participants?.map((participant) => ({
            item: participant,
            searchFields: [participant.participant.pseudonym],
          }))}
          placeholderText={t("study.participants.searchPlaceholder")}
          onSearch={setSearchResult}
          style={{ maxWidth: 160 }}
        />

        <Select
          style={{ maxWidth: 160 }}
          defaultValue="all"
          onChange={(value) => setGroupFilter(value)}
        >
          <Option value="all">All Groups</Option>
          {participantGroups?.map((participantGroup) => (
            <Option key={participantGroup.id} value={participantGroup.id}>
              {participantGroup.name}
            </Option>
          ))}
        </Select>
        <ParticipantStateFilterSingle
          onChange={setParticipantStateFilter}
          style={{ maxWidth: 160 }}
        />
      </Space>
      <Button
        data-id="study.participants.createButton"
        style={{ float: "right" }}
        icon={<UserAddOutlined />}
        type="primary"
        disabled={
          !canAddParticipants || participantGroups?.length === 0 || usageStatistics?.isExpired
        }
        onClick={() => setIsAddParticipantModalVisible(true)}
      >
        {t("study.participants.createButton")}
      </Button>
      <Table
        dataSource={preparedParticipants}
        columns={participantsTableColumns(t, searchResult.searchText)}
        style={{ marginTop: 24, minWidth: "100%" }}
        pagination={{
          hideOnSinglePage: true,
          pageSize: 10,
        }}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t("study.participants.noParticipants")}
            />
          ),
        }}
      />

      {participantGroups && (
        <CreateParticipantModal
          participantGroups={participantGroups}
          studyId={studyId}
          open={isAddParticipantModalVisible}
          onCancel={() => setIsAddParticipantModalVisible(false)}
        />
      )}
    </LoadingHandler>
  );
}

export default ParticipantsOverview;
