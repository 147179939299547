/* eslint-disable react/require-default-props */

import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";
import { Button, Result } from "antd";

import {
  GroupConfigsToUpdate,
  GroupVariableAssignment,
  ParticipantsGroupFromQuery,
  ITherapyElement,
  useUpdateGroupVariableConfig,
} from "../../../../services/graphql";

import { DummyAnchor, Paths, useUserId } from "../../../../shared";

import {
  TherapyElementContainer,
  TherapyElementsGroupConfig,
} from "../../components/therapyConfigTable";
import TherapyConfigForm from "../../components/therapyConfigTable/TherapyConfigForm";

import { sortTherapyElementsByTitle, filterForEditedTherapyValues } from "../../../../utils";

interface IParticipantsGroupsTherapyConfigContentProps {
  studyId: string;
  hasPermissionToEdit: boolean;
  groups?: ParticipantsGroupFromQuery[];
  elementsWithEditableVariables?: ITherapyElement[];
  groupVariableAssignment?: GroupVariableAssignment[];
}

export function ParticipantsGroupsTherapyConfigContent({
  studyId,
  hasPermissionToEdit,
  groups = [],
  elementsWithEditableVariables = [],
  groupVariableAssignment = [],
}: IParticipantsGroupsTherapyConfigContentProps) {
  const { t } = useTranslation();
  const { userId } = useUserId();
  const [forceRefresh, setForceRefresh] = useState(false);
  const { updateGroupVariableConfig } = useUpdateGroupVariableConfig({
    studyId,
    onError: () => setForceRefresh((prev: boolean) => !prev),
  });
  const studyRoutes = Paths.getStudyPaths(studyId);

  const handleOnFormFinish = (editedValues: any, initialValues: any) => {
    const filteredValues = filterForEditedTherapyValues({ editedValues, initialValues });

    const preparedConfigArray: GroupConfigsToUpdate[] = filteredValues.map(
      ({ key, currentValue }) => ({
        id: key,
        variableValue: currentValue,
        editorId: userId,
      }),
    );
    updateGroupVariableConfig(preparedConfigArray);
  };

  const studyHasGroups = groups.length > 0;

  if (!studyHasGroups) {
    return (
      <Result
        style={{ margin: "auto", width: "75%", background: "white", padding: 40 }}
        title={t("study.participantsGroups.therapyConfig.noGroupResult.title")}
        subTitle={t("study.participantsGroups.therapyConfig.noGroupResult.description")}
        extra={
          <Button type="primary">
            <Link to={studyRoutes.PARTICIPANTS_GROUPS}>{t("study.menu.manageGroups")}</Link>
          </Button>
        }
      />
    );
  }

  if (elementsWithEditableVariables.length === 0) {
    return (
      <Result
        style={{ margin: "auto", width: "75%", background: "white", padding: 40 }}
        title={t("study.participantsGroups.therapyConfig.noVariablesResult.title")}
        subTitle={t("study.participantsGroups.therapyConfig.noVariablesResult.description")}
      />
    );
  }

  const initialValuesArray = groupVariableAssignment.map((assignment) => ({
    key: assignment.id,
    value: assignment.variableValue,
  }));

  const initialValueObject = Object.fromEntries(initialValuesArray.map((e) => [e.key, e.value]));

  const groupVariableInformation = groupVariableAssignment.map((assignment) => {
    const correspondingGroup = groups?.find((group) => group.id === assignment.participantsGroupId);
    return {
      ...assignment,
      groupName: correspondingGroup?.name || "ERROR",
    };
  });

  return (
    <TherapyConfigForm
      title={t("study.participantsGroups.therapyConfig.title")}
      description={t("study.participantsGroups.therapyConfig.description")}
      editable={hasPermissionToEdit}
      affixOffsetTop={74}
      initialValues={initialValueObject}
      onFinish={(values) => handleOnFormFinish(values, initialValueObject)}
      forceValueRefresh={forceRefresh}
      groupVariableInformation={groupVariableInformation}
    >
      {sortTherapyElementsByTitle(elementsWithEditableVariables).map((item) => {
        if (typeof item === "string") {
          return <DummyAnchor key={item} id={item} />;
        }
        if (item.therapyVariables.length > 0) {
          return (
            <TherapyElementContainer
              id={item.id}
              key={item.id}
              title={item.title}
              description={item.description}
            >
              <TherapyElementsGroupConfig
                groups={groups}
                therapyVariables={item.therapyVariables}
                variablesAssignment={groupVariableAssignment}
              />
            </TherapyElementContainer>
          );
        }

        return null;
      })}
    </TherapyConfigForm>
  );
}

export default ParticipantsGroupsTherapyConfigContent;
