import { useGetParticipantsOfStudyQuery } from "../codegen";

export function useParticipants(studyId: string) {
  const { loading, data } = useGetParticipantsOfStudyQuery({
    variables: { studyId },
  });

  return { participants: data ? data.participants : [], loading };
}

export default useParticipants;
