/* eslint-disable no-unused-vars */
import { Typography } from "antd";
import { Rule } from "antd/lib/form";
import React from "react";
import { useTranslation } from "react-i18next";
import { IFieldData } from "..";
import { ValidationResult } from "../../../../../services";
import { BooleanFormItem } from "./BooleanFormItem";
import StandardFormItem, { StandardFormItemTypes } from "./StandardFormItem";
import TimeRangeFormItem from "./TimeRangeFormItem";
import SelectItem from "./SelectItem";

const { Text } = Typography;
type ValidatorResults = Pick<ValidationResult, "variableId" | "value" | "isValid" | "errorMessage">;

// eslint-disable-next-line no-shadow
export enum VariableType {
  TEXT = "Text",
  DECIMAL = "Decimal",
  INT = "Integer",
  DATE = "Date",
  DATETIME = "DateTime",
  TIME = "Time",
  TIME_RANGE = "TimeRange",
  BOOLEAN = "Bool",
  SINGLE_SELECT = "Categorical",
  MULTI_SELECT = "ListContainer",
  UNKNOWN = "Unknown",
}

export interface IFormItemProps {
  name: string;
  editing: boolean;
  mandatory: boolean;
  validatorProps?: any;
}

export interface IFormItemValidator {
  validate?: ({
    values,
    variableId,
  }: {
    values: IFieldData[];
    variableId: string;
  }) => Promise<ValidatorResults[] | undefined>;
}

export interface ITherapyConfigFormItemProps extends IFormItemProps, IFormItemValidator {
  variableType: string;
}

export interface IBaseFormItemProps extends IFormItemProps {
  executorValidationRule: Rule;
}

export interface ITherapyConfigInputProps {
  editing: boolean;
  value?: any;
  // eslint-disable-next-line no-unused-vars
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
  validatorProps?: any;
}

export function TherapyConfigFormItem({
  name,
  editing,
  variableType,
  mandatory,
  validatorProps,
  validate,
}: ITherapyConfigFormItemProps) {
  const { t } = useTranslation();

  const executorValidationRule: Rule = ({ getFieldsValue, setFields }) => ({
    async validator() {
      const validationResults = validate
        ? await validate({
            values: Object.entries(getFieldsValue()).map(([key, value]) => ({
              variableId: key,
              value,
            })),
            variableId: name,
          })
        : undefined;

      if (validationResults) {
        setFields(
          validationResults?.map((result) => ({
            name: result.variableId,
            errors: result.errorMessage ? [result.errorMessage] : [],
          })),
        );
      }

      const errorMessage = validationResults?.find((it) => it.variableId === name)?.errorMessage;
      if (errorMessage) {
        return Promise.reject(new Error(errorMessage));
      }
      return Promise.resolve();
    },
  });

  switch (variableType) {
    case VariableType.TIME_RANGE: {
      return (
        <TimeRangeFormItem
          mandatory={mandatory}
          validatorProps={validatorProps}
          editing={editing}
          name={name}
          executorValidationRule={executorValidationRule}
        />
      );
    }

    case VariableType.BOOLEAN: {
      return (
        <BooleanFormItem
          mandatory={mandatory}
          validatorProps={validatorProps}
          editing={editing}
          name={name}
          executorValidationRule={executorValidationRule}
        />
      );
    }

    case VariableType.SINGLE_SELECT: {
      return (
        <SelectItem
          mandatory={mandatory}
          validatorProps={validatorProps}
          editing={editing}
          name={name}
          executorValidationRule={executorValidationRule}
          selectType="single"
        />
      );
    }

    case VariableType.MULTI_SELECT: {
      return (
        <SelectItem
          mandatory={mandatory}
          validatorProps={validatorProps}
          editing={editing}
          name={name}
          executorValidationRule={executorValidationRule}
          selectType="multiple"
        />
      );
    }

    case VariableType.TEXT:
    case VariableType.DECIMAL:
    case VariableType.INT:
    case VariableType.DATE:
    case VariableType.DATETIME:
    case VariableType.TIME: {
      return (
        <StandardFormItem
          mandatory={mandatory}
          validatorProps={validatorProps}
          editing={editing}
          name={name}
          variableType={variableType}
          executorValidationRule={executorValidationRule}
        />
      );
    }

    default:
      return (
        <Text type="danger">
          {t("study.therapyConfig.FormItem.missingFormElement")} {variableType}
        </Text>
      );
  }
}
