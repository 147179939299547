import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Divider, List, Empty, Button } from "antd";
import { OrganizationMemberListItem, CreateOrganizationMemberModal } from "./components";
import {
  usePermission,
  ActionEnum,
  useOrganizationMembers,
  useOrganizationRoles,
  GetOrganizationMembersQuery,
} from "../../services";
import {
  Loader,
  OrganizationRoleFilter,
  PageHeader,
  SearchField,
  ServerError,
  useCachedOrganization,
  useOrganizationId,
} from "../../shared";

type OrganizationMember = NonNullable<
  GetOrganizationMembersQuery["getOrganizationMembersAction"]
>[number];

export function OrganizationMembers() {
  const { t } = useTranslation();
  const { organizationId } = useOrganizationId();
  const { organization } = useCachedOrganization();
  const [searchResult, setSearchResult] = useState({
    searchText: "",
    items: [] as OrganizationMember[],
  });
  const [organizationRoleFilter, setOrganizationRoleFilter] = useState<string[]>([
    "MEMBER",
    "OWNER",
  ]);
  const [isAddMemberModalVisible, setIsAddMemberModalVisible] = useState(false);

  const {
    organizationMembers,
    loading: getMembersLoading,
    refetch,
  } = useOrganizationMembers(organizationId);
  useOrganizationRoles(organizationId);

  const { permitted: canAddMember, loading: canAddMemberLoading } = usePermission({
    action: ActionEnum.ORGANIZATION_CREATE_MEMBER,
    organizationId,
  });

  const filter = (membersToFilter: OrganizationMember[]) => {
    const filterForRole = membersToFilter.filter((member) =>
      organizationRoleFilter.includes(member.organizationMember!.organizationRole.name),
    );
    const filterForSearchText = filterForRole.filter((member) =>
      searchResult.items.find((item) => item.id === member.id),
    );
    return filterForSearchText;
  };

  if (canAddMemberLoading || getMembersLoading) {
    return <Loader />;
  }

  if (!organizationMembers) {
    return <ServerError />;
  }

  return (
    <>
      <PageHeader
        title={t("organization.members.title", { organization: organization.name })}
        description={t("organization.members.description")}
        hideDivider
        style={{ marginBottom: 24, padding: 0 }}
      />
      <Button
        style={{ float: "right" }}
        type="primary"
        disabled={!canAddMember}
        onClick={() => setIsAddMemberModalVisible(true)}
      >
        {t("organization.members.addMemberButton")}
      </Button>
      <SearchField
        allItems={organizationMembers?.map((member) => ({
          item: member,
          searchFields: [
            `${member.userFirstName} ${member.userLastName}`,
            `${member.userLastName} ${member.userFirstName}`,
            member.userEmail,
          ],
        }))}
        placeholderText={t("organization.members.searchPlaceholder")}
        onSearch={setSearchResult}
        style={{ float: "left", width: 240, marginBottom: 16 }}
      />

      <OrganizationRoleFilter
        onChange={(value) => setOrganizationRoleFilter(value)}
        style={{
          float: "left",
          display: "inline-block",
          width: 196,
          marginLeft: 16,
          height: "100%",
        }}
      />

      <Divider style={{ marginBottom: 0 }} />
      <List<OrganizationMember>
        itemLayout="horizontal"
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t("organization.members.noMembers")}
            />
          ),
        }}
        size="small"
        pagination={{
          hideOnSinglePage: true,
          pageSize: 10,
        }}
        dataSource={filter(organizationMembers || [])}
        renderItem={(item) => (
          <OrganizationMemberListItem
            searchStringToHighlight={searchResult.searchText}
            avatar={item.userAvatar}
            email={item.userEmail}
            firstName={item.userFirstName}
            lastName={item.userLastName}
            roleId={item.organizationMember!.organizationRole.id}
            roleName={item.organizationMember!.organizationRole.name}
            memberUserId={item.organizationMember!.userId}
            memberId={item.id}
            orgaId={organization.id}
            onRemoveMember={() => refetch()}
          />
        )}
      />

      <CreateOrganizationMemberModal
        organizationId={organization.id}
        open={isAddMemberModalVisible}
        onCancel={() => setIsAddMemberModalVisible(false)}
      />
    </>
  );
}

export default OrganizationMembers;
