import { ApolloError, useMutation } from "@apollo/client";
import { notification } from "antd";
import { useTranslation } from "react-i18next";
import { useUserId } from "../../../shared";
import {
  GetUserDocument,
  LeaveStudyMutation,
  useCanLeaveStudyQuery,
  useLeaveStudyMutation,
} from "../codegen";

interface IUseDeleteParticipantProps {
  studyId: string;
  // eslint-disable-next-line no-unused-vars
  onCompleted?: (data: LeaveStudyMutation) => void;
  // eslint-disable-next-line no-unused-vars
  onError?: (error: ApolloError) => void;
}

export function useLeaveStudy({
  studyId,
  onCompleted = () => {},
  onError = () => {},
}: IUseDeleteParticipantProps) {
  const { t } = useTranslation();
  const { userId } = useUserId();
  const { data: canLeaveCheck, loading: canLeaveLoading } = useCanLeaveStudyQuery({
    variables: { studyId, userId },
    fetchPolicy: "network-only",
  });
  const [mutate, { loading: leaveLoading }] = useLeaveStudyMutation({
    onCompleted: (data) => {
      if (data.leaveStudyAction?.success) {
        notification.success({
          message: t("notifications.leaveStudy.success"),
        });
      } else {
        notification.warning({
          message: t("notifications.leaveStudy.error"),
        });
      }
      onCompleted(data);
    },
    onError: (error) => {
      notification.error({
        message: t("notifications.leaveStudy.error"),
      });
      onError(error);
    },
  });

  const leaveStudy = () => {
    mutate({
      variables: {
        studyId,
        userId,
      },
      refetchQueries: [{ query: GetUserDocument, variables: { userId } }],
    });
  };

  return {
    leaveStudy,
    leaveLoading,
    canLeave: canLeaveCheck?.canLeaveStudyAction.canLeave,
    canLeaveLoading,
  };
}

export default useLeaveStudy;
