import { Layout } from "antd";
import React, { CSSProperties, ReactNode } from "react";
import useTabletMode from "../../hooks/useTabletMode";
import {
  mainLayoutStyle as defaultMainLayoutStyle,
  mainContentStyle as defaultMainContentStyle,
  collapsedSiderWidthTabletMode,
} from "../styles";

const { Content } = Layout;

export interface IPageContentProps {
  children: ReactNode;
  contentStyle?: CSSProperties;
  mainLayoutStyle?: CSSProperties;
  maxContentWidth?: number | string;
  hideSider?: boolean;
}

export function PageContent({
  mainLayoutStyle,
  contentStyle,
  maxContentWidth = 800,
  children,
  hideSider = false,
}: IPageContentProps) {
  const isInTabletMode = useTabletMode();

  return (
    <Layout style={mainLayoutStyle || defaultMainLayoutStyle}>
      <Content style={contentStyle || defaultMainContentStyle}>
        <div
          style={{
            margin: "auto",
            maxWidth: maxContentWidth,
            paddingLeft: isInTabletMode && !hideSider ? collapsedSiderWidthTabletMode : undefined,
          }}
        >
          {children}
        </div>
      </Content>
    </Layout>
  );
}
