import { useTranslation } from "react-i18next";
import { notification } from "antd";
import React, { useState } from "react";
import { MemberListItem, IMemberProps } from "../../../../shared";
import { useGetSupervisorAssignmentsOfUserQuery, useSupervisorRoles } from "../../../../services";
import {
  EditSupervisorAssignmentModal,
  IFinishEditAssignments,
} from "./EditSupervisorAssignmentModal";

interface IStudySupervisorListItemProps extends IMemberProps {
  studyId: string;
}

export function StudySupervisorListItem(props: IStudySupervisorListItemProps) {
  const {
    studyId,
    memberId: roleId,
    firstName: roleFirstName,
    lastName: roleLastName,
    email: roleUserEmail,
  } = props;
  const [isEditMemberModalVisible, setIsEditMemberModalVisible] = useState(false);
  const { t } = useTranslation();
  const { supervisorRoles } = useSupervisorRoles(studyId);
  const { data: getRoleData } = useGetSupervisorAssignmentsOfUserQuery({
    variables: { userId: roleId, studyId },
    fetchPolicy: "cache-and-network",
  });

  const participants = getRoleData?.supervisors.map((supervisor) => supervisor.participant);
  const userName = roleFirstName ? `${roleFirstName} ${roleLastName}` : roleUserEmail;

  const handleOnEditRole = () => {
    setIsEditMemberModalVisible(true);
  };

  const handleOnFinishAssignmentEdit = (result: IFinishEditAssignments) => {
    notification.success({
      message: t("notifications.updateSupervisorsAssignments.success.title", { userName }),
      description: (
        <>
          {t("notifications.updateSupervisorsAssignments.success.description.1", {
            count: result.numOfAssignmentsAdded,
          })}
          <br />
          {t("notifications.updateSupervisorsAssignments.success.description.2", {
            count: result.numOfAssignmentsRemoved,
          })}
        </>
      ),
    });
    setIsEditMemberModalVisible(false);
  };

  return (
    <>
      <MemberListItem
        {...props}
        roleId="todo"
        hideRemove
        roleColor="blue"
        roleName={`${participants?.length || "..."} ${t("common.participant", {
          count: participants?.length || 0,
        })}`}
        editButtonTitle={t("study.supervisors.listItem.editButton")}
        onEditRole={handleOnEditRole}
      />
      {participants && supervisorRoles && (
        <EditSupervisorAssignmentModal
          open={isEditMemberModalVisible}
          onCancel={() => setIsEditMemberModalVisible(false)}
          onFinish={handleOnFinishAssignmentEdit}
          studyId={studyId}
          userId={roleId}
          userName={userName}
          supervisorRoleId={supervisorRoles[0].id}
          assignedParticipants={participants}
        />
      )}
    </>
  );
}

export default StudySupervisorListItem;
