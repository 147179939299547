import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { VisualizationPageProps } from "../../visualizations/types";
import { useGetVisualizationsOfParticipantQuery } from "../codegen";

export interface IuseVisualizationsProps {
  participantId: string;
  start?: string;
  end?: string;
}

export function useVisualizations({ participantId, start, end }: IuseVisualizationsProps) {
  const { i18n } = useTranslation();
  const { data, ...rest } = useGetVisualizationsOfParticipantQuery({
    variables: { participantId, start, end, language: i18n.language },
  });

  let visualizationPageData: VisualizationPageProps | undefined;

  if (data?.getVisualizationsAction) {
    visualizationPageData = JSON.parse(
      JSON.stringify(data.getVisualizationsAction),
    ) as VisualizationPageProps;
    visualizationPageData.children.sort((a, b) => b.orderIndex - a.orderIndex);
  }

  return { visualizationPageData, ...rest };
}
