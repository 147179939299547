import { ApolloError } from "@apollo/client";
import { Form, Modal, Input, Select, App } from "antd";
import { useForm } from "antd/lib/form/Form";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParticipant, useParticipantsGroups } from "..";
import { useCheckPseudonym } from "../..";
import {
  GetParticipantActionDocument,
  GetParticipantsGroupsDocument,
  UpdateParticipantMutation,
  useUpdateParticipantMutation,
} from "../codegen";
import { CenterInModal } from "../../../shared";

const { Option } = Select;

interface IUseUpdateParticipantProps {
  studyId: string;
  participantId: string;
  // eslint-disable-next-line no-unused-vars
  onCompleted?: (data: UpdateParticipantMutation) => void;
  // eslint-disable-next-line no-unused-vars
  onError?: (error: ApolloError) => void;
}

interface IUpdateParticipant {
  pseudonym: string;
  groupId: string;
}

export function useUpdateParticipant({
  studyId,
  participantId,
  onCompleted = () => {},
  onError = () => {},
}: IUseUpdateParticipantProps) {
  const { t } = useTranslation();
  const [form] = useForm();
  const { participant } = useParticipant(participantId);
  const { participantsGroups } = useParticipantsGroups(studyId);
  const { isPseudonymAvailable } = useCheckPseudonym();
  const { modal, notification } = App.useApp();

  const [mutate, { loading }] = useUpdateParticipantMutation({
    onCompleted: (data) => {
      notification.success({
        message: t("notifications.updateParticipant.success", {
          pseudonym: data.updateParticipantAction.participant?.pseudonym,
        }),
      });
      onCompleted(data);
      // todo find better solution to close Modal after mutation was successful
      Modal.destroyAll();
    },
    onError: (error) => {
      notification.error({
        message: t("notifications.updateParticipant.error"),
      });
      onError(error);
    },
  });

  const updateParticipant = (props: IUpdateParticipant) => {
    mutate({
      variables: {
        id: participantId,
        pseudonym: props.pseudonym,
        groupId: props.groupId,
      },
      refetchQueries: [
        { query: GetParticipantsGroupsDocument, variables: { studyId } },
        { query: GetParticipantActionDocument, variables: { id: participantId } },
      ],
    });
  };

  const showUpdateParticipantModal = () => {
    modal.confirm({
      title: t("participant.updateModal.title", { pseudonym: participant?.pseudonym }),
      icon: null,
      content: (
        <CenterInModal>
          <Form
            initialValues={{
              pseudonym: participant?.pseudonym,
              groupId: participant?.participantGroup.id,
            }}
            layout="vertical"
            style={{ marginTop: 24 }}
            form={form}
            onFinish={(values: any) =>
              updateParticipant({ groupId: values.groupId, pseudonym: values.pseudonym })
            }
          >
            <Form.Item
              name="pseudonym"
              label={t("participant.createModal.pseudonym")}
              rules={[
                {
                  required: true,
                  message: t("participant.createModal.errors.missingPseudonym"),
                },
                () => ({
                  async validator(_, value) {
                    const isAvailable = await isPseudonymAvailable({ studyId, pseudonym: value });
                    if (isAvailable || value === participant?.pseudonym) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(t("participant.createModal.errors.pseudonymInUse")),
                    );
                  },
                }),
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="groupId"
              label={t("participant.createModal.participantGroup")}
              rules={[
                {
                  required: true,
                  message: t("participant.createModal.errors.missingParticipantGroup"),
                },
              ]}
            >
              <Select placeholder={t("participant.createModal.participantGroupPlaceholder")}>
                {participantsGroups.map((group) => (
                  <Option key={group.id} value={group.id}>
                    {group.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </CenterInModal>
      ),
      okText: t("common.save"),
      cancelText: t("common.cancel"),
      okButtonProps: { loading },
      onOk: () => {
        form.submit();
        return true;
      },
    });
  };

  return { updateParticipant, showUpdateParticipantModal, loading };
}

export default useUpdateParticipant;
