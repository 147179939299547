import { GetStudyQuery, useGetStudyLazyQuery, useGetStudyQuery } from "../codegen";

export function useStudy(studyId: string) {
  const { data, loading, refetch } = useGetStudyQuery({
    variables: { id: studyId },
  });
  return { study: data?.study, loading, refetch };
}

export function useStudyLazy() {
  const [fetch, { data, loading }] = useGetStudyLazyQuery();

  const fetchStudy = (studyId: string) => {
    return fetch({ variables: { id: studyId } });
  };

  return { study: data?.study, fetchStudy, loading };
}

export type ParticipantsGroupFromQuery = NonNullable<
  GetStudyQuery["study"]
>["participantGroups"][number];
