import { TeamOutlined } from "@ant-design/icons";
import { Button, Divider, Space, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Paths, StudyRoleEnum, StudyRoleTag } from "../../../../shared";

import OverviewCard from "./OverviewCard";

const { Paragraph, Text } = Typography;

interface IPersonnelCardProps {
  studyId: string;
  numberOfOwners: number;
  numberOfParticipantManagers: number;
  numberOfAdmins: number;
  // eslint-disable-next-line react/require-default-props
  numberOfParticipantSupervisors?: number;
}

/**
 * Card for Study Overview page, that holds some top level information about the personnel
 */
export function PersonnelCard({
  studyId,
  numberOfAdmins,
  numberOfParticipantManagers,
  numberOfParticipantSupervisors,
  numberOfOwners,
}: IPersonnelCardProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <OverviewCard title={t("study.overview.personnel.title")} ribbonIcon={<TeamOutlined />}>
      <Paragraph type="secondary">{t("study.overview.personnel.description.1")}</Paragraph>

      <Paragraph>
        <Space>
          <TeamOutlined />
          <Text strong>{numberOfParticipantManagers}</Text>
          <StudyRoleTag role={StudyRoleEnum.PARTICIPANT_MANAGER} />
        </Space>
      </Paragraph>
      <Paragraph>
        <Space>
          <TeamOutlined />
          <Text strong>{numberOfAdmins}</Text>
          <StudyRoleTag role={StudyRoleEnum.ADMIN} />
        </Space>
      </Paragraph>
      <Paragraph>
        <Space>
          <TeamOutlined />
          <Text strong>{numberOfOwners}</Text>
          <StudyRoleTag role={StudyRoleEnum.OWNER} />
        </Space>
      </Paragraph>
      <Paragraph type="secondary">{t("study.overview.personnel.description.2")}</Paragraph>
      <Paragraph>
        <Space>
          <TeamOutlined />
          <Text strong>{numberOfParticipantSupervisors}</Text>
          <Text>{t("study.overview.personnel.description.3")}</Text>
        </Space>
      </Paragraph>
      <Divider />
      <Space>
        <Button type="link" onClick={() => navigate(Paths.getStudyPaths(studyId).SUPERVISORS)}>
          {t("study.overview.personnelCard.buttons.manageSupervisors")}
        </Button>
        <Button type="link" onClick={() => navigate(Paths.getStudyPaths(studyId).EDITORS)}>
          {t("study.overview.personnelCard.buttons.studyEditors")}
        </Button>
      </Space>
    </OverviewCard>
  );
}

export default PersonnelCard;
