/* eslint-disable react/require-default-props */
import { Input } from "antd";
import React, { useEffect, useState } from "react";
import { DeleteTwoTone, SearchOutlined } from "@ant-design/icons";

interface ISearchItem {
  searchFields: string[];
  item: any;
}

export interface IOnSearchResult {
  searchText: string;
  items: any;
}

interface ISearchFieldProps {
  placeholderText?: string;
  dataId?: string;
  style?: React.CSSProperties;
  allItems?: ISearchItem[];
  // eslint-disable-next-line no-unused-vars
  onSearch: (result: IOnSearchResult) => void;
  onPressEnter?: () => void;
}

export function SearchField({
  placeholderText = "",
  dataId = "",
  allItems = [],
  onSearch,
  style,
  onPressEnter = () => {},
}: ISearchFieldProps) {
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    if (allItems) {
      if (searchValue.trim() === "") {
        onSearch({ searchText: "", items: allItems.map((item) => item.item) });
      } else {
        const filteredItems = allItems.filter((item) => {
          const searchFields =
            item.searchFields.filter(
              (searchField) => searchField.toUpperCase().indexOf(searchValue.toUpperCase()) !== -1,
            ).length > 0;
          return searchFields;
        });
        onSearch({ searchText: searchValue, items: filteredItems.map((items) => items.item) });
      }
    }
  }, [searchValue, allItems.length]);

  return (
    <Input
      data-id={dataId}
      placeholder={placeholderText}
      style={style}
      value={searchValue}
      onChange={(event) => setSearchValue(event.currentTarget.value)}
      onPressEnter={() => onPressEnter()}
      suffix={
        searchValue ? (
          <DeleteTwoTone
            twoToneColor="#eb2f96"
            onClick={() => {
              setSearchValue("");
            }}
          />
        ) : (
          <SearchOutlined />
        )
      }
    />
  );
}

export default SearchField;
