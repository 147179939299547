import { Form, Input, Modal, notification } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React from "react";
import { useTranslation } from "react-i18next";
import { useCreateParticipantGroup } from "../../../../services";

interface ICreateParticipantsGroupModalProps {
  studyId: string;
  open: boolean;
  onCancel: () => void;
}

export function CreateParticipantsGroupModal({
  studyId,
  open,
  onCancel,
}: ICreateParticipantsGroupModalProps) {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const { createParticipantGroup, loading } = useCreateParticipantGroup({
    onCompleted: (data) => {
      notification.success({
        message: t("notifications.createParticipantGroup.success", {
          participantGroup: data.createParticipantsGroupAction?.participantsGroup?.name,
          studyName: data.createParticipantsGroupAction?.participantsGroup?.study.name,
        }),
      });
      onCancel();
    },
    onError: () => {
      notification.error({
        message: t("notifications.createParticipantGroup.error"),
      });
    },
  });

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  const handleOnFormFinish = async (values: any) => {
    await createParticipantGroup({ studyId, name: values.name, description: values.description });
    form.resetFields();
  };

  return (
    <Modal
      title={t("study.participantsGroups.createGroupModal.title")}
      closable
      destroyOnClose
      open={open}
      onCancel={handleCancel}
      confirmLoading={loading}
      onOk={() => form.submit()}
      okText={t("common.ok")}
      // @ts-ignore
      okButtonProps={{ "data-id": "common.ok" }}
      cancelText={t("common.cancel")}
      // @ts-ignore
      cancelButtonProps={{ "data-id": "common.cancel" }}
    >
      <Form form={form} onFinish={handleOnFormFinish} layout="vertical">
        <Form.Item
          name="name"
          label={t("study.participantsGroups.createGroupModal.inputLabel")}
          rules={[
            {
              required: true,
              message: t("study.participantsGroups.createGroupModal.errorMessage"),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="description"
          label={t("study.participantsGroups.createGroupModal.groupDescriptionLabel")}
          rules={[{ required: false }]}
        >
          <TextArea rows={4} />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default CreateParticipantsGroupModal;
