import React from "react";
import modal from "antd/es/modal";
import { notification } from "antd";
import { useTranslation } from "react-i18next";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { MemberListItem, IMemberProps, useUserId } from "../../../../shared";
import { useDeleteSupervisor } from "../../../../services";

interface IParticipantSupervisorProps extends IMemberProps {
  studyId: string;
  participantId: string;
  supervisorRoleId: string;
}

export function ParticipantSupervisorListItem(props: IParticipantSupervisorProps) {
  const { t } = useTranslation();
  const { participantId, memberId, supervisorRoleId, studyId, email, firstName, lastName } = props;
  const { userId } = useUserId();
  const name = firstName ? `${firstName} ${lastName}` : email;
  const { deleteSupervisor } = useDeleteSupervisor({
    onCompleted: () => {
      notification.success({
        message: t("participant.supervisorListItem.successMessage", { name }),
      });
    },
    onError: () => {
      notification.error({
        message: t("participant.supervisorListItem.errorMessage"),
      });
    },
  });

  const handleRemoveSupervisor = () => {
    modal.confirm({
      title: t("common.supervisor.deleteModal.title", { name }),
      icon: <ExclamationCircleOutlined />,
      content: t("common.supervisor.deleteModal.description"),
      okText: t("common.yes"),
      okType: "danger",
      cancelText: t("common.no"),
      onOk: () => deleteSupervisor({ participantId, userId: memberId, studyId }),
    });
  };

  return (
    <MemberListItem
      roleId={supervisorRoleId}
      {...props}
      hideRemove={userId === memberId}
      hideRoleTag
      disableEdit
      onRemoveRole={() => handleRemoveSupervisor()}
    />
  );
}

export default ParticipantSupervisorListItem;
