import { ApolloError } from "@apollo/client";
import { notification } from "antd";
import { useTranslation } from "react-i18next";
import {
  DeleteExecutorMutation,
  GetExecutorsDocument,
  useDeleteExecutorMutation,
} from "../codegen";

interface IUseDeleteParticipantProps {
  // eslint-disable-next-line no-unused-vars
  onCompleted?: (data: DeleteExecutorMutation) => void;
  // eslint-disable-next-line no-unused-vars
  onError?: (error: ApolloError) => void;
}

export function useDeleteExecutor({
  onCompleted = () => {},
  onError = () => {},
}: IUseDeleteParticipantProps) {
  const { t } = useTranslation();
  const [mutate, { ...rest }] = useDeleteExecutorMutation({
    onCompleted: (data) => {
      notification.success({
        message: t("notifications.deleteExecutor.success", {
          executorName: data.updateExecutor?.name,
        }),
      });

      onCompleted(data);
    },
    onError: (error) => {
      notification.error({
        message: t("notifications.deleteExecutor.error"),
      });
      onError(error);
    },
    context: {
      headers: {
        "x-hasura-role": "master",
      },
    },
  });

  const deleteExecutor = (executorId: String) => {
    mutate({
      variables: {
        executorId,
        deletedAt: new Date().toISOString(),
      },
      refetchQueries: [{ query: GetExecutorsDocument }],
    });
  };

  return {
    deleteExecutor,
    ...rest,
  };
}

export default useDeleteExecutor;
