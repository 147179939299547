export const TherapyConfigHashLinkPrefix = "therapyconfig";

export const getTherapyConfigHashLink = (key: string) => `${TherapyConfigHashLinkPrefix}#${key}`;

export const Paths = {
  HOME: "/",
  ME: "/me",
  PROFILE: "/me/profile",
  ACCOUNT: "/me/account",
  STUDIES_OVERVIEW: "/studies",
  STUDY_NEW: "/studies/new",
  SUPERVISED_PARTICIPANTS: "/participants",
  MANAGEMENT_CONSOLE: "/management",
  ORGANIZATION_NEW: "/organizations/new",

  relativeMePaths: {
    PROFILE: "profile",
    ACCOUNT: "account",
  },

  relativeOrganizationPaths: {
    OVERVIEW: "",
    STUDIES: `studies`,
    MEMBERS: `members`,
    SETTINGS: `settings`,
    CONTRACTS: `contracts`,
  },
  relativeStudyPaths: {
    OVERVIEW: "",
    PARTICIPANTS: `participants`,
    PARTICIPANTS_GROUPS: `groups`,
    PARTICIPANTS_GROUPS_THERAPY_CONFIG: `${TherapyConfigHashLinkPrefix}`,
    SUPERVISORS: `supervisors`,
    EDITORS: `editors`,
    SETTINGS: `settings`,
  },
  relativeParticipantPaths: {
    BASE_PATH: "participants/:participantId",
    DASHBOARD: "",
    THERAPY_CONFIG: `${TherapyConfigHashLinkPrefix}`,
    SUPERVISORS: `supervisors`,
    LABELING_INFORMATION: `labeling`,
  },

  getStudyPaths: (studyId?: string) => {
    const studyBasePath = `/studies/${studyId || ":studyId"}`;
    return {
      BASE_PATH: studyBasePath,
      OVERVIEW: studyBasePath,
      PARTICIPANTS: `${studyBasePath}/${Paths.relativeStudyPaths.PARTICIPANTS}`,
      PARTICIPANTS_GROUPS: `${studyBasePath}/${Paths.relativeStudyPaths.PARTICIPANTS_GROUPS}`,
      PARTICIPANTS_GROUPS_THERAPY_CONFIG: `${studyBasePath}/${TherapyConfigHashLinkPrefix}`,
      SUPERVISORS: `${studyBasePath}/${Paths.relativeStudyPaths.SUPERVISORS}`,
      EDITORS: `${studyBasePath}/${Paths.relativeStudyPaths.EDITORS}`,
      SETTINGS: `${studyBasePath}/${Paths.relativeStudyPaths.SETTINGS}`,
      getParticipantsPaths: (participantId?: string) => {
        const participantBasePath = `${studyBasePath}/${"participants"}/${
          participantId || ":participantId"
        }`;
        return {
          BASE_PATH: participantBasePath,
          DASHBOARD: participantBasePath,
          THERAPY_CONFIG: `${participantBasePath}/${Paths.relativeParticipantPaths.THERAPY_CONFIG}`,
          SUPERVISORS: `${participantBasePath}/${Paths.relativeParticipantPaths.SUPERVISORS}`,
          LABELING_INFORMATION: `${participantBasePath}/${Paths.relativeParticipantPaths.LABELING_INFORMATION}`,
        };
      },
    };
  },

  getOrganizationPaths: (organizationId?: string) => {
    const organizationBasePath = `/organizations/${organizationId || ":organizationId"}`;
    return {
      BASE_PATH: organizationBasePath,
      OVERVIEW: organizationBasePath,
      STUDIES: `${organizationBasePath}/${Paths.relativeOrganizationPaths.STUDIES}`,
      MEMBERS: `${organizationBasePath}/${Paths.relativeOrganizationPaths.MEMBERS}`,
      SETTINGS: `${organizationBasePath}/${Paths.relativeOrganizationPaths.SETTINGS}`,
      CONTRACTS: `${organizationBasePath}/${Paths.relativeOrganizationPaths.CONTRACTS}`,
    };
  },
};

export default Paths;
