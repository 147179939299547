import { Alert, Typography, AlertProps } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useExecutorStatistics } from "../../../services";

const { Text } = Typography;

export function ExecutorStatisticsAlerts() {
  const { t } = useTranslation();
  const { usageStatistics } = useExecutorStatistics();
  if (!usageStatistics) return null;

  const { participantLimit, participantCount, expiresAt, isExpired, participantLimitReached } =
    usageStatistics;

  const alerts: ({ key: string } & AlertProps)[] = [];
  if (expiresAt || participantLimit !== -1) {
    alerts.push({
      key: "executorStatistics",
      type: "info",
      message: t("study.participants.executorStatistics.title"),
      description: (
        <div>
          <Text>{t("study.participants.executorStatistics.description")}</Text>
          <ul>
            {expiresAt && (
              <li>
                <Text>
                  <Text strong>
                    {t("study.participants.executorStatistics.expiresAtLimitTitle")}
                  </Text>{" "}
                  {t("study.participants.executorStatistics.expiresAtLimit", {
                    date: new Date(expiresAt).toLocaleDateString(),
                  })}
                </Text>
              </li>
            )}
            {participantLimit !== -1 && (
              <li>
                <Text>
                  <Text strong>
                    {t("study.participants.executorStatistics.participantLimitTitle")}
                  </Text>{" "}
                  {t("study.participants.executorStatistics.participantLimit", {
                    participantLimit,
                    participantCount,
                  })}
                </Text>
              </li>
            )}
          </ul>
        </div>
      ),
    });
  }

  if (expiresAt && isExpired) {
    alerts.push({
      key: "studyExpired",
      type: "warning",
      message: t("study.participants.studyExpired.title"),
      description: t("study.participants.studyExpired.description", {
        date: new Date(expiresAt).toLocaleDateString(),
      }),
    });
  }

  if (participantLimitReached) {
    alerts.push({
      key: "participantLimitReached",
      type: "warning",
      message: t("study.participants.participantLimitReached.title"),
      description: t("study.participants.participantLimitReached.description", {
        participantLimit,
      }),
    });
  }

  if (alerts.length > 0) {
    return alerts.map((alert) => (
      <Alert
        key={alert.key}
        style={{ marginTop: 12 }}
        message={alert.message}
        description={alert.description}
        type={alert.type as any}
        showIcon
      />
    ));
  }

  return null;
}
export default ExecutorStatisticsAlerts;
