import { ApolloError } from "@apollo/client";
import { notification } from "antd";
import { useTranslation } from "react-i18next";
import {
  CreateApiAccountActionMutation,
  GetApiAccountsOfStudyDocument,
  useCreateApiAccountActionMutation,
} from "../codegen";

interface IUseCreateApiAccount {
  // eslint-disable-next-line no-unused-vars
  onCompleted?: (data: CreateApiAccountActionMutation) => void;
  // eslint-disable-next-line no-unused-vars
  onError?: (error: ApolloError) => void;
}

interface ICreateApiAccount {
  studyId: string;
  serviceName: string;
  expiresAt: string;
}

export const useCreateApiAccount = ({
  onCompleted = () => {},
  onError = () => {},
}: IUseCreateApiAccount) => {
  const { t } = useTranslation();
  const [mutate, { loading }] = useCreateApiAccountActionMutation({
    onCompleted: (data) => {
      onCompleted(data);
    },
    onError: (error) => {
      notification.error({
        message: t("notifications.createApiAccount.error"),
      });
      onError(error);
    },
  });

  const createApiAccount = ({ studyId, expiresAt, serviceName }: ICreateApiAccount) => {
    return mutate({
      variables: { studyId, expiresAt, serviceName },
      refetchQueries: [{ query: GetApiAccountsOfStudyDocument, variables: { studyId } }],
    });
  };

  return { createApiAccount, loading };
};
