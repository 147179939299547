import { ApolloError } from "@apollo/client";
import {
  DeleteSupervisorMutation,
  GetSupervisorAssignmentsOfUserDocument,
  GetSupervisorsOfParticipantDocument,
  GetSupervisorsOfStudyDocument,
  useDeleteSupervisorMutation,
} from "../codegen";

interface IUseDeleteParticipantProps {
  // eslint-disable-next-line no-unused-vars
  onCompleted?: (data: DeleteSupervisorMutation) => void;
  // eslint-disable-next-line no-unused-vars
  onError?: (error: ApolloError) => void;
}

interface IDeleteSupervisor {
  participantId: string;
  userId: string;
  studyId: string;
}

export function useDeleteSupervisor({
  onCompleted = () => {},
  onError = () => {},
}: IUseDeleteParticipantProps) {
  const [mutate, { loading }] = useDeleteSupervisorMutation({
    onCompleted: (data) => {
      onCompleted(data);
    },
    onError: (error) => {
      onError(error);
    },
  });

  /**
   * Deletes a supervisor from a participant. A supervisor as an entity is not
   * the user itself, but only the assignment of a user to a participant. After
   * the deletion, the queries 'GetSupervisorsOfParticipantDocument',
   * 'GetSupervisorAssignmentsOfUserDocument', 'GetSupervisorsOfStudyDocument'
   * are refetched. This is necessary to update the cache.
   */
  const deleteSupervisor = ({ participantId, studyId, userId }: IDeleteSupervisor) => {
    mutate({
      variables: {
        participantId,
        userId,
      },
      refetchQueries: [
        {
          query: GetSupervisorsOfParticipantDocument,
          variables: { participantId },
        },
        {
          query: GetSupervisorAssignmentsOfUserDocument,
          variables: { userId, studyId },
        },
        { query: GetSupervisorsOfStudyDocument, variables: { studyId } },
      ],
    });
  };

  return { deleteSupervisor, loading };
}

export default useDeleteSupervisor;
