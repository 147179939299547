import { Alert, Radio, Typography } from "antd";
import React from "react";

const { Text } = Typography;

interface IRadioButtonRoleItemProps {
  roleId: string;
  roleName: string;
  type: "warning" | "success" | "info" | "error" | undefined;
  description: React.ReactNode;
}

export function RadioButtonRoleItem({
  roleName,
  roleId,
  type,
  description,
}: IRadioButtonRoleItemProps) {
  return (
    <Alert
      style={{
        marginTop: 8,
      }}
      message={
        <>
          <Radio data-testid={roleName} value={roleId} />
          <Text strong style={{ marginLeft: 8 }}>
            {roleName}
          </Text>
        </>
      }
      description={<div style={{ marginLeft: 32 }}>{description}</div>}
      type={type}
    />
  );
}

export default RadioButtonRoleItem;
