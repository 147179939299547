import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { Input, Button } from "antd";
import Form, { Rule } from "antd/lib/form";
import React from "react";
import { useTranslation } from "react-i18next";
// import { useTranslation } from "react-i18next";

export interface IDynamicFormListProps {
  /**
   * Defines the  minimum number of input items that have to be completed
   */
  minNumOfItems?: number;

  name: string;
  /**
   * Label text, only used for the first inputfield
   */
  label?: string | null;
  /**
   * PlaceHolderText for input Items
   */
  placeholderText?: string;
  /**
   * Button Text, button is used to add more input fields
   */
  addButtonText?: string;
  /**
   * additional valdition rules added to each input field (see antd form item validation)
   */
  // eslint-disable-next-line no-unused-vars
  additionalValidationRules?: (index: number) => Rule[];
  /**
   * If true, a validation error is shown when there are duplicates in the list
   */
  checkForDuplicates?: boolean;
}

/**
 *  Dynamic Form List that provides input fields for adding
 *  a list of values.
 */
export function DynamicFormList({
  minNumOfItems = 0,
  name,
  additionalValidationRules,
  placeholderText,
  addButtonText,
  checkForDuplicates,
  label,
}: IDynamicFormListProps) {
  const { t } = useTranslation();

  return (
    <Form.List
      name={name}
      rules={[
        {
          validator: (_, values) => {
            const filteredValues = values?.filter(
              (value: undefined | string) => value !== undefined,
            );
            const duplicates = filteredValues?.length !== new Set(filteredValues).size;
            if (duplicates && filteredValues && checkForDuplicates) {
              return Promise.reject(new Error(t("dynamicFormList.duplicates")));
            }
            return Promise.resolve();
          },
        },
      ]}
    >
      {(fields, { add, remove }, { errors }) => (
        <>
          {fields.map((field, index) => (
            <Form.Item
              required={false}
              key={field.key}
              style={{ marginBottom: 8 }}
              labelAlign="left"
              label={index === 0 ? label : null}
            >
              <Form.Item
                {...field}
                validateTrigger={["onBlur"]}
                rules={(additionalValidationRules && additionalValidationRules(index)) || []}
                noStyle
              >
                <Input
                  placeholder={placeholderText}
                  style={{ width: "100%" }}
                  suffix={
                    index >= minNumOfItems ? (
                      <CloseOutlined
                        onClick={() => {
                          remove(field.name);
                        }}
                      />
                    ) : null
                  }
                />
              </Form.Item>
            </Form.Item>
          ))}
          <Form.Item>
            <Button
              type="dashed"
              onClick={() => add()}
              style={{ width: "100%" }}
              icon={<PlusOutlined />}
            >
              {addButtonText || "Add"}
            </Button>
            <Form.ErrorList errors={errors} />
          </Form.Item>
        </>
      )}
    </Form.List>
  );
}

export default DynamicFormList;
