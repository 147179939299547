import { notification } from "antd";
import { useTranslation } from "react-i18next";
import { useGetParticipantActionLazyQuery } from "../codegen";

export function useCheckCoupleState(participantId: string) {
  const { t } = useTranslation();
  const [fetch, { data, loading }] = useGetParticipantActionLazyQuery({
    variables: { id: participantId },
    fetchPolicy: "network-only",
  });

  const checkCoupleState = async () => {
    const req = await fetch();
    const data = req.data?.getParticipantAction;
    if (data?.coupleUrl === null) {
      notification.success({
        message: t("notifications.checkCoupleState.success", {
          pseudonym: data?.participant?.pseudonym,
        }),
      });
    } else {
      notification.error({
        message: t("notifications.checkCoupleState.error", {
          pseudonym: data?.participant?.pseudonym,
        }),
      });
    }
    return data?.coupleUrl === null;
  };

  return {
    checkCoupleState,
    isCoupled: data?.getParticipantAction.coupleUrl === null,
    loading,
  };
}
