import {
  useGetRoleOfOrganizationMemberLazyQuery,
  useGetStudyMemberByParticipantIdLazyQuery,
  useGetStudyMemberLazyQuery,
  useGetSupervisorLazyQuery,
} from "../..";

export interface IUseSupervisorRulesProps {
  userId: string;
  participantId: string;
}

export const useSupervisorRules = () => {
  const [fetch, { loading, data }] = useGetSupervisorLazyQuery();

  const fetchSupervisorRules = ({ userId, participantId }: IUseSupervisorRulesProps) => {
    fetch({
      variables: {
        userId,
        participantId,
      },
    });
  };

  let supervisorRules;
  if (data?.supervisor === null) {
    supervisorRules = null;
  } else {
    supervisorRules = data?.supervisor?.supervisorRole;
  }

  return { fetchSupervisorRules, loading, supervisorRules };
};

export interface IUseOrganizationRulesProps {
  userId: string;
  organizationId: string;
}

export const useOrganizationRules = () => {
  const [fetch, { data, loading }] = useGetRoleOfOrganizationMemberLazyQuery();

  const fetchOrgaRules = ({ userId, organizationId }: IUseOrganizationRulesProps) => {
    fetch({ variables: { userId, organizationId } });
  };

  let organizationRules;
  if (data?.organizationMember === null) {
    organizationRules = null;
  } else {
    organizationRules = data?.organizationMember?.organizationRole;
  }

  return { fetchOrgaRules, loading, organizationRules };
};

export interface IFetchStudyRulesByStudyIdProps {
  userId: string;
  studyId: string;
}

export interface IFetchStudyRulesByParticipantIdProps {
  userId: string;
  participantId: string;
}

export const useStudyRules = () => {
  const [fetchByStudyId, { loading: loadingByStudyId, data: dataByStudyId }] =
    useGetStudyMemberLazyQuery();
  const [fetchByParticipantId, { loading: loadingByParticipantId, data: dataByParticipantId }] =
    useGetStudyMemberByParticipantIdLazyQuery();

  const fetchStudyRulesByStudyId = ({ userId, studyId }: IFetchStudyRulesByStudyIdProps) => {
    fetchByStudyId({
      variables: { userId, studyId },
    });
  };

  const fetchStudyRulesByParticipantId = ({
    userId,
    participantId,
  }: IFetchStudyRulesByParticipantIdProps) => {
    fetchByParticipantId({
      variables: { userId, participantId },
    });
  };

  let studyRulesByParticipantId;

  if (dataByParticipantId?.participant == null) {
    studyRulesByParticipantId = null;
  } else {
    studyRulesByParticipantId =
      dataByParticipantId?.participant?.participantGroup.study.studyMembers.find(
        (studyMember) => studyMember !== undefined,
      )?.studyRole;
  }

  let studyRulesByStudyId;
  if (dataByStudyId?.studyMember === null) {
    studyRulesByStudyId = null;
  } else {
    studyRulesByStudyId = dataByStudyId?.studyMember?.studyRole;
  }

  return {
    fetchStudyRulesByStudyId,
    fetchStudyRulesByParticipantId,
    loading: loadingByStudyId || loadingByParticipantId,
    studyRulesByStudyId,
    studyRulesByParticipantId,
  };
};
