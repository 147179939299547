import React, { useState } from "react";
import { Form, Input, Button, Collapse, Typography, Alert, App, Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import TextArea from "antd/lib/input/TextArea";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

import {
  usePermission,
  ActionEnum,
  useDeleteStudy,
  useUpdateStudyMutation,
  useUpdateStudyLogoMutation,
} from "../../../services";
import { Paths, UpdateProfilePicture, useCachedStudy } from "../../../shared";
import useTherapyDesign from "../../../services/graphql/hooks/useTherapyDesign";
import { ApiAccountTable, DomainList } from "./components";

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

const buttonLayout = {
  wrapperCol: {
    offset: 21,
    span: 3,
  },
};

const { Panel } = Collapse;
const { Title, Text, Paragraph } = Typography;

export function StudySettings() {
  const { t } = useTranslation();
  const { modal, notification } = App.useApp();
  const { study } = useCachedStudy();
  const [descriptionHasChanges, setDescriptionHasChange] = useState(false);

  const { therapyDesign } = useTherapyDesign(study.id);
  const [updateStudyInfo] = useUpdateStudyMutation({
    onCompleted: () => {
      notification.success({
        message: t("notifications.updateStudy.success"),
      });
    },
    onError: () => {
      notification.error({
        message: t("notifications.updateStudy.error"),
      });
    },
  });

  const [updateLogo] = useUpdateStudyLogoMutation({
    onCompleted: () => {
      notification.success({
        message: t("notifications.updateStudyLogo.success"),
      });
    },
    onError: () => {
      notification.error({
        message: t("notifications.updateStudyLogo.error"),
      });
    },
  });

  const navigate = useNavigate();
  const { deleteStudy, canDelete } = useDeleteStudy({
    studyId: study.id,
    organizationId: study.organizationId,
    onCompleted: (data) => {
      if (data.deleteStudyAction.success) {
        navigate(Paths.HOME);
      }
    },
  });

  const { permitted: canEditStudy } = usePermission({
    action: ActionEnum.STUDY_EDIT,
    studyId: study.id,
  });
  const { permitted: permissionToDeleteStudy } = usePermission({
    action: ActionEnum.STUDY_DELETE,
    studyId: study.id,
  });

  const handleFinish = (values: any) => {
    updateStudyInfo({
      variables: {
        id: study.id,
        name: values.studyName,
        description: values.studyDescription,
      },
    });
  };

  const handleLogoChange = (base64Img: string) => {
    updateLogo({
      variables: {
        id: study.id,
        logo: base64Img,
      },
    });
  };

  const handleDeleteStudy = () => {
    modal.confirm({
      title: t("study.delete.confirm.title", { studyName: study.name }),
      icon: <ExclamationCircleOutlined />,
      content: t("study.delete.confirm.description"),
      okText: t("common.yes"),
      okType: "danger",
      cancelText: t("common.no"),
      onOk() {
        deleteStudy();
      },
    });
  };

  return (
    <Collapse
      expandIconPosition="right"
      bordered={false}
      style={{ backgroundColor: "white" }}
      defaultActiveKey={["1", "2"]}
    >
      <Panel
        header={
          <>
            <Title level={4} style={{ marginTop: 0 }}>
              {t("study.settings.generalInfo.title")}
            </Title>
            <Text>{t("study.settings.generalInfo.shortDescription")}</Text>
          </>
        }
        key="1"
      >
        <Paragraph>{t("study.settings.generalInfo.longDescription")} </Paragraph>
        <Form
          {...layout}
          name="basic"
          initialValues={{
            studyName: study?.name,
            studyDescription: study?.description,
          }}
          onFinish={handleFinish}
          onFieldsChange={() => setDescriptionHasChange(true)}
        >
          <Form.Item
            name="studyName"
            label={t("study.createNew.studyNameLabel")}
            rules={[{ required: true, message: t("study.errorMessages.studyNameMissing") }]}
          >
            <Input disabled={!canEditStudy} />
          </Form.Item>

          <Form.Item
            name="studyDescription"
            label={t("study.createNew.studyDescriptionLabel")}
            rules={[{ required: false }]}
          >
            <TextArea disabled={!canEditStudy} rows={3} />
          </Form.Item>

          <Form.Item {...buttonLayout}>
            <Button
              disabled={!canEditStudy || !descriptionHasChanges}
              style={{ float: "right" }}
              type="primary"
              htmlType="submit"
            >
              {t("common.save")}
            </Button>
          </Form.Item>
        </Form>
      </Panel>
      <Panel
        header={
          <>
            <Title level={4} style={{ marginTop: 0 }}>
              {t("study.settings.logo.title")}
            </Title>
            <Text>{t("study.settings.logo.shortDescription")}</Text>
          </>
        }
        key="2"
      >
        <UpdateProfilePicture
          imgBase64={study?.logo ? study.logo : ""}
          onImageChange={handleLogoChange}
          type="logo"
        />
      </Panel>
      <Panel
        header={
          <>
            <Title level={4} style={{ marginTop: 0 }}>
              {t("study.settings.studyPersonnel.title")}
            </Title>
            <Text>{t("study.settings.studyPersonnel.shortDescription")}</Text>
          </>
        }
        key="3"
      >
        <Paragraph>{t("study.settings.studyPersonnel.description")}</Paragraph>
        <DomainList studyId={study.id} allowedDomains={study.allowedEmailDomains || null} />
      </Panel>
      <Panel
        header={
          <>
            <Title level={4} style={{ marginTop: 0 }}>
              {t("study.settings.apiAccess.title")}
            </Title>
            <Text>{t("study.settings.apiAccount.shortDescription")}</Text>
          </>
        }
        key="4"
      >
        <Paragraph>{t("study.settings.apiAccess.description")}</Paragraph>
        <ApiAccountTable studyId={study.id} />
      </Panel>

      <Panel
        key="5"
        header={
          <>
            <Title level={4} style={{ marginTop: 0 }}>
              {t("study.settings.executor.title")}
            </Title>
            <Text>{t("study.settings.executor.shortDescription")}</Text>
          </>
        }
      >
        <Row>
          <Col span={4}>{t("study.settings.executor.name")}:</Col>
          <Col span={20}>
            <Text strong>{study.executor.name}</Text>
          </Col>
        </Row>
        <Row>
          <Col span={4}>{t("study.settings.executor.url")}:</Col>
          <Col span={20}>
            <Text strong>{study.executor.executorUrl}</Text>
          </Col>
        </Row>
        <Row>
          <Col span={4}>{t("study.settings.executor.design")}:</Col>
          <Col span={20}>
            <Text strong>
              {therapyDesign?.title} {therapyDesign?.version}
            </Text>
          </Col>
        </Row>
      </Panel>
      <Panel
        header={
          <>
            <Title level={4} style={{ marginTop: 0 }}>
              {t("study.settings.advanced.title")}
            </Title>
            <Text>{t("study.settings.advanced.shortDescription")}</Text>
          </>
        }
        key="6"
      >
        <Alert
          description={
            <>
              <Paragraph>{t("study.settings.advanced.delete.description.1")} </Paragraph>
              <Paragraph>
                <ul>
                  {!permissionToDeleteStudy && (
                    <li key="1">
                      <Text strong>{t("study.settings.advanced.delete.description.2")}</Text>
                    </li>
                  )}
                  {!canDelete?.canDelete && (
                    <li key="2">
                      <Text strong>{t("study.settings.advanced.delete.description.3")} </Text>
                      <ul>
                        {canDelete?.runningParticipants.map((pseudonym) => (
                          <li key={pseudonym}>{pseudonym}</li>
                        ))}
                      </ul>
                    </li>
                  )}
                </ul>
              </Paragraph>
            </>
          }
          type="error"
          action={
            <Button
              onClick={handleDeleteStudy}
              disabled={!permissionToDeleteStudy || !canDelete?.canDelete}
              danger
            >
              {t("common.delete")}{" "}
            </Button>
          }
        />
      </Panel>
    </Collapse>
  );
}

export default StudySettings;
