import { Rule } from "antd/lib/form";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAllowedEmailDomains } from "../hooks";
import DynamicFormList from "./DynamicFormList";

export interface IAddSupervisorsFormListProps {
  /** Defines the number of supervisors that are not deletable.
   *  Notice that when component is used in form you need to set initial
   *  values via the form
   */
  minNumOfSupervisors?: number;
  /**
   * Field name of form item to later retrieve value from it
   */
  name: string;
  /**
   * Label text, only used for the first inputfield
   */
  label?: string | null;
  /**
   * Button Text, button is used to add more input fields
   */
  addButtonText?: string;
  /**
   * additional rule valdition rule added to each input field (see antd form item validation)
   */
  additionalValidationRule?: Rule;

  studyId: string;
}

/**
 *  Dynamic Form List that provides input fields for adding
 *  of supervisors via email addresses. The number of input fields
 *  can be  varied by the 'add supervisor' button and the close
 *  icons of the input fields
 */
export function AddSupervisorsFormList({
  minNumOfSupervisors = 0,
  name,
  additionalValidationRule,
  addButtonText,
  studyId,
  label,
}: IAddSupervisorsFormListProps) {
  const { t } = useTranslation();
  const { validationRule: allowedDomainsRule } = useAllowedEmailDomains(studyId);
  return (
    <DynamicFormList
      minNumOfItems={minNumOfSupervisors}
      name={name}
      label={label || t("participant.addSupervisorModal.label")}
      addButtonText={addButtonText || t("participant.addSupervisorModal.buttonText")}
      additionalValidationRules={(index) => [
        {
          required: true,
          type: "email",
          message: `${t("participant.addSupervisorModal.errorMessage.1")} ${
            index >= minNumOfSupervisors ? t("participant.addSupervisorModal.errorMessage.2") : ""
          }`,
        },
        allowedDomainsRule,
        additionalValidationRule || {},
      ]}
      placeholderText={t("participant.addSupervisorModal.inputPlaceholder")}
      checkForDuplicates
    />
  );
}

export default AddSupervisorsFormList;
