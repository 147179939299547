import { ApolloError } from "@apollo/client";
import { notification } from "antd";
import { useTranslation } from "react-i18next";
import {
  GetParticipantActionDocument,
  ResetCoupleKeyActionMutation,
  useResetCoupleKeyActionMutation,
} from "../codegen";

interface IUseResetCoupleKey {
  // eslint-disable-next-line no-unused-vars
  onCompleted?: (data: ResetCoupleKeyActionMutation) => void;
  // eslint-disable-next-line no-unused-vars
  onError?: (error: ApolloError) => void;
}

export function useResetCoupleKey({
  onCompleted = () => {},
  onError = () => {},
}: IUseResetCoupleKey) {
  const { t } = useTranslation();
  const [mutate, { loading }] = useResetCoupleKeyActionMutation({
    onCompleted: (data) => {
      notification.success({
        message: t("notifications.resetCoupleKey.success"),
      });
      onCompleted(data);
    },
    onError: (error) => {
      notification.error({
        message: t("notifications.resetCoupleKey.error"),
      });
      onError(error);
    },
  });

  const resetCoupleKey = async (participantId: string) => {
    const answer = await mutate({
      variables: {
        participantId,
      },
      refetchQueries: [{ query: GetParticipantActionDocument, variables: { id: participantId } }],
    });
    return answer.data?.resetCoupleKeyAction;
  };

  return { resetCoupleKey, loading };
}

export default useResetCoupleKey;
