import React from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Divider, Form, Input, Button, Alert } from "antd";
import { useTranslation } from "react-i18next";
import "../../shared/layout/css/FullPage.css";
import { GetUserDocument, useCreateNewOrganizationMutation } from "../../services";
import { PageHeader, Paths, useUserId } from "../../shared";
import { MainPageContainer } from "./components";

const { Title } = Typography;
const { TextArea } = Input;

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

const buttonLayout = {
  wrapperCol: {
    offset: 21,
    span: 3,
  },
};

export function NewOrganization() {
  const { userId } = useUserId();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [createOrganization, { loading: createOrganizationLoading }] =
    useCreateNewOrganizationMutation({
      onCompleted: (result) => {
        navigate(Paths.getOrganizationPaths(result.createOrganizationAction?.id).BASE_PATH);
      },
    });

  const onFinish = (values: any) => {
    createOrganization({
      variables: {
        name: values.name,
        description: values.description,
        department: values.department,
        country: values.country,
        city: values.city,
        address: values.address,
        zip: values.zip,
        userId,
      },
      refetchQueries: [{ query: GetUserDocument, variables: { userId } }],
    });
  };

  return (
    <MainPageContainer>
      <PageHeader hideDivider title={<Title level={2}>{t("organization.createNew.title")}</Title>}>
        <Alert
          message={t("common.information")}
          description={t("organization.createNew.information")}
          type="info"
          showIcon
        />
      </PageHeader>

      <Divider />

      <Form {...layout} style={{ marginTop: "20px" }} name="basic" onFinish={onFinish}>
        <Form.Item
          name="name"
          label={t("organization.name")}
          style={{ display: "inline-block", width: "calc(50% - 8px)" }}
          rules={[
            { required: true, message: t("organization.errorMessages.missingOrganizationName") },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="department"
          label={t("organization.department")}
          style={{ display: "inline-block", width: "calc(50% - 8px)", float: "right" }}
          rules={[{ required: false }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="description"
          label={t("organization.description")}
          rules={[{ required: false }]}
        >
          <TextArea rows={3} />
        </Form.Item>

        <Form.Item
          name="address"
          label={t("organization.address")}
          rules={[{ required: true, message: t("organization.errorMessages.missingAddress") }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="zip"
          label={t("organization.zip")}
          rules={[{ required: true, message: t("organization.errorMessages.missingPostalCode") }]}
          style={{ display: "inline-block", width: "calc(10% - 8px)" }}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="city"
          label={t("organization.city")}
          rules={[{ required: true, message: t("organization.errorMessages.missingCity") }]}
          style={{ display: "inline-block", width: "calc(90% - 8px)", float: "right" }}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="country"
          label={t("organization.country")}
          rules={[{ required: true, message: t("organization.errorMessages.missingCountry") }]}
        >
          <Input />
        </Form.Item>

        {/*  <Checkbox>
        <Form.Item
          name="termsOfService"
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject(new Error(t("organization.errorMessages.acceptTermsOfService"))),
            },
          ]}
          {...tailFormItemLayout}
        >
            <Trans i18nKey="organization.createNew.agreeTermsOfServiceText">
              I agree to the <a href="https://www.movisens.com">Terms of service</a>
            </Trans>
          </Checkbox>
        </Form.Item>

        <Form.Item
          name="dataProcessingAgreement"
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject(
                      new Error(t("organization.errorMessages.acceptDataProcessingAgreement"))
                    ),
            },
          ]}
          {...tailFormItemLayout}
        >
          <Checkbox>
            <Trans i18nKey="organization.createNew.agreeDataProcessingText">
              I agree to the <a href="https://www.movisens.com">Data Processing Agreement</a>
            </Trans>
          </Checkbox>
        </Form.Item>

        <Form.Item
          name="uselicense"
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject(new Error(t("organization.errorMessages.acceptUseLicense"))),
            },
          ]}
          {...tailFormItemLayout}
        >
          <Checkbox>
            <Trans i18nKey="organization.createNew.agreeUseLicenseText">
              I agree to the <a href="https://www.movisens.com">Use License</a>
            </Trans>
          </Checkbox>
        </Form.Item> */}

        <Form.Item {...buttonLayout}>
          <Button
            data-id="organization.createNew.createButton"
            loading={createOrganizationLoading}
            style={{ float: "right" }}
            type="primary"
            htmlType="submit"
          >
            {t("organization.createNew.createButton")}
          </Button>
        </Form.Item>
      </Form>
    </MainPageContainer>
  );
}

export default NewOrganization;
