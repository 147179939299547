import { Menu } from "antd";
import { MenuItemProps } from "antd/lib/menu/MenuItem";
import React, { ReactNode } from "react";
import { IPermissionProps, usePermission } from "../../services";

export interface IMenuItemWithPermissionProps extends IPermissionProps, MenuItemProps {
  children?: ReactNode;
}

export function MenuItemWithPermission(props: IMenuItemWithPermissionProps) {
  const { children, action, studyId, organizationId, participantId, ...rest } = props;
  const { permitted: isPermitted } = usePermission({
    action,
    studyId,
    organizationId,
    participantId,
  });

  return isPermitted ? <Menu.Item {...rest}>{children}</Menu.Item> : null;
}
