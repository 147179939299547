/* eslint-disable react/require-default-props */

import React from "react";

import { ElementContainer } from "./ElementContainer";

interface ITherapyElementContainerProps {
  id: string;
  title: string;
  description: string;
  children?: React.ReactNode;
  editing?: boolean;
}

export function TherapyElementContainer({
  id,
  title,
  description,
  children,
  editing = false,
}: ITherapyElementContainerProps) {
  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      const newProps = { editing };
      return React.cloneElement(child, newProps);
    }
    return child;
  });

  return (
    <ElementContainer
      id={id}
      title={title}
      description={description}
      hashLinkPrefix="therapyconfig"
    >
      {childrenWithProps}
    </ElementContainer>
  );
}

export default TherapyElementContainer;
