import { useGetStudyRolesOfStudyQuery } from "../codegen";

export function useStudyRoles(studyId: string) {
  const { data, loading } = useGetStudyRolesOfStudyQuery({
    variables: { studyId },
  });

  return { studyRoles: data?.studyRoles, loading };
}

export default useStudyRoles;
