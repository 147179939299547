import { useGetParticipantTherapyConfigQuery } from "../codegen";

export function useParticipantTherapyConfig(participantId: string) {
  const { loading, data, ...rest } = useGetParticipantTherapyConfigQuery({
    variables: { participantId },
  });

  return {
    therapyConfig: data?.getParticipantTherapyVariableConfigAction,
    loading,
    ...rest,
  };
}

export default useParticipantTherapyConfig;
