import { useParams } from "react-router-dom";

export function useParticipantId() {
  const { participantId } = useParams();
  if (!participantId) {
    throw new Error("ParticipantId not defined");
  }
  return { participantId };
}

export default useParticipantId;
