import { useGetTherapyDesignsQuery } from "../codegen";

export function useTherapyDesignsFromExecutor(executorUrl: string) {
  const { data, ...rest } = useGetTherapyDesignsQuery({
    variables: { executorUrl },
    context: {
      headers: {
        "x-hasura-role": "master",
      },
    },
  });

  return { therapyDesigns: data?.getTherapyDesignsAction, ...rest };
}

export default useTherapyDesignsFromExecutor;
