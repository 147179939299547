import { Select, Tag } from "antd";
import React, { useState, CSSProperties } from "react";

export interface ITagMultiFilterProps {
  initialValue: string[];
  options: string[];
  style?: CSSProperties;
  // eslint-disable-next-line no-unused-vars
  onChange: (value: string[]) => void;
  // eslint-disable-next-line no-unused-vars
  getTagColor: (value: string) => string;
  placeholderText?: string;
}

export function TagMultiFilter({
  onChange,
  getTagColor,
  style = {},
  initialValue,
  options,
  placeholderText,
}: ITagMultiFilterProps) {
  const [stateFilter, setStateFilter] = useState<string[]>(initialValue);

  const handleStateChange = (value: string, operation: "SELECT" | "DESELECT") => {
    let newStateFilter: string[] = [];
    if (operation === "DESELECT") {
      newStateFilter = stateFilter.filter((state) => state !== value);
    } else if (!stateFilter.includes(value)) {
      newStateFilter = [...stateFilter, value];
    }
    setStateFilter(newStateFilter);
    onChange(newStateFilter.map((item) => item.toString()));
  };

  const tagRender = ({ value, closable, onClose }: { value: any; closable: any; onClose: any }) => {
    const onPreventMouseDown = (event: {
      preventDefault: () => void;
      stopPropagation: () => void;
    }) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={getTagColor(value)}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {value}
      </Tag>
    );
  };

  return (
    <Select
      mode="multiple"
      tagRender={tagRender}
      showArrow
      placeholder={placeholderText || "Chose a filter option"}
      value={stateFilter}
      onDeselect={(value: string) => handleStateChange(value, "DESELECT")}
      onSelect={(value: string) => handleStateChange(value, "SELECT")}
      style={style}
      options={options.map((option) => ({ value: option }))}
    />
  );
}

export default TagMultiFilter;
