import React, { ReactNode } from "react";
import { OrganizationFromQuery } from "../../services";

const OrganizationContext = React.createContext<
  { organization: OrganizationFromQuery } | undefined
>(undefined);

interface IOrganizationProviderProps {
  organization: OrganizationFromQuery;
  children: ReactNode;
}

function OrganizationProvider({ children, organization }: IOrganizationProviderProps) {
  const value = React.useMemo(() => ({ organization }), [organization]);
  return <OrganizationContext.Provider value={value}>{children}</OrganizationContext.Provider>;
}

function useCachedOrganization() {
  const context = React.useContext(OrganizationContext);
  if (context === undefined) {
    throw new Error("useOrganization must be used within a OrganizationProvider");
  }
  return context;
}

export { OrganizationProvider, useCachedOrganization };
