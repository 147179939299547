import { Alert, Button, Form, Space } from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import useUpdateStudySettings from "../../../../services/graphql/hooks/useUpdateStudySettings";
import { DynamicFormList } from "../../../../shared";

export interface IDomainListProps {
  studyId: string;
  allowedDomains: string | null;
}

export function DomainList({ studyId, allowedDomains }: IDomainListProps) {
  const { t } = useTranslation();
  const { updateAllowedEmailDomains, loading } = useUpdateStudySettings({
    onCompleted: () => {
      setHasChanges(false);
    },
    studyId,
  });
  const [noDomainRestrictions, setNoDomainRestrictions] = useState(
    allowedDomains == null || allowedDomains?.split(",").length === 0,
  );
  const [hasChanges, setHasChanges] = useState(false);
  const [form] = useForm();

  return (
    <>
      {noDomainRestrictions ? (
        <Alert
          style={{ marginBottom: 16 }}
          type="warning"
          description={t("study.settings.studyPersonnel.allDomainsAllowed")}
        />
      ) : null}
      <Form
        form={form}
        initialValues={{ allowedEmailDomains: allowedDomains?.split(",") }}
        onValuesChange={(values) => {
          setHasChanges(true);
          setNoDomainRestrictions(values.allowedEmailDomains.length === 0);
        }}
        onFinish={(values: any) => {
          updateAllowedEmailDomains({
            allowedEmailDomains: values.allowedEmailDomains.filter(
              (domain: string | undefined) => domain?.trim() !== "",
            ),
          });
        }}
      >
        <DynamicFormList
          name="allowedEmailDomains"
          addButtonText={t("study.settings.studyPersonnel.addDomainButton")}
          checkForDuplicates
          minNumOfItems={0}
          placeholderText={t("study.settings.studyPersonnel.placeholderText")}
          additionalValidationRules={() => [
            {
              validator(_: any, value: any) {
                if (value?.includes(",")) {
                  return Promise.reject(
                    new Error(t("study.settings.studyPersonnel.noCommasAllowed")),
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        />
        <Form.Item>
          <Space style={{ float: "right" }}>
            {hasChanges && (
              <Button
                type="default"
                onClick={() => {
                  form.resetFields();
                  setHasChanges(false);
                  setNoDomainRestrictions(
                    allowedDomains == null || allowedDomains?.split(",").length === 0,
                  );
                }}
              >
                {t("common.cancel")}
              </Button>
            )}
            <Button loading={loading} type="primary" htmlType="submit" disabled={!hasChanges}>
              {t("common.save")}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </>
  );
}

export default DomainList;
