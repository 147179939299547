import {
  GetGroupVariablesConfigsQuery,
  useGetGroupVariablesConfigsLazyQuery,
  useGetGroupVariablesConfigsQuery,
} from "../codegen";

export function useGroupVariableConfigs(studyId: string) {
  const { loading, data } = useGetGroupVariablesConfigsQuery({
    variables: { studyId },
  });

  return { groupVariableConfigs: data?.getGroupsTherapyVariableConfigAction?.groupValues, loading };
}

export function useGroupVariableConfigsLazy() {
  const [fetch, { loading, data }] = useGetGroupVariablesConfigsLazyQuery();

  const fetchGroupVariableConfigs = (studyId: string) => {
    fetch({ variables: { studyId } });
  };

  return {
    fetchGroupVariableConfigs,
    groupVariableConfigs: data?.getGroupsTherapyVariableConfigAction?.groupValues,
    loading,
  };
}

export type GroupVariableAssignment =
  GetGroupVariablesConfigsQuery["getGroupsTherapyVariableConfigAction"]["groupValues"][number];
