import { ApolloError } from "@apollo/client";
import { notification } from "antd";
import { useTranslation } from "react-i18next";

import {
  DeleteApiAccountActionMutation,
  GetApiAccountsOfStudyDocument,
  useDeleteApiAccountActionMutation,
} from "../codegen";

interface IUseDeleteApiAccount {
  // eslint-disable-next-line no-unused-vars
  onCompleted?: (data: DeleteApiAccountActionMutation) => void;
  // eslint-disable-next-line no-unused-vars
  onError?: (error: ApolloError) => void;
}

interface IDeleteApiAccount {
  accountId: string;
  studyId: string;
}

export const useDeleteApiAccount = ({
  onCompleted = () => {},
  onError = () => {},
}: IUseDeleteApiAccount) => {
  const { t } = useTranslation();
  const [mutate, { ...rest }] = useDeleteApiAccountActionMutation({
    onCompleted: (data) => {
      notification.success({
        message: t("notifications.deleteApiAccount.success", {
          serviceName: data.deleteApiAccountAction?.serviceName,
        }),
      });

      onCompleted(data);
    },
    onError: (error) => {
      notification.error({
        message: t("notifications.deleteApiAccount.error"),
      });
      onError(error);
    },
  });

  const deleteApiAccount = ({ accountId, studyId }: IDeleteApiAccount) => {
    return mutate({
      variables: { id: accountId },
      refetchQueries: [{ query: GetApiAccountsOfStudyDocument, variables: { studyId } }],
    });
  };

  return { deleteApiAccount, ...rest };
};
