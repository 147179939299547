import { GetUserQuery, useGetUserQuery } from "../codegen";

export function useUser(userId: string) {
  const { data, loading, ...rest } = useGetUserQuery({
    variables: { userId },
  });

  return { user: data?.user, loading, ...rest };
}

export default useUser;

export type UserFromQuery = NonNullable<GetUserQuery["user"]>;
