/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-unused-vars */
import { Checkbox, Table, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import React from "react";
import { useTranslation } from "react-i18next";
import { VariableType } from "./TherapyConfigFormItem";
import { ISelectItemValidatorProps } from "./SelectItem";

export interface IChangedValue {
  key: string;
  oldValue: any;
  newValue: any;
  type: string;
  groupName?: string;
  validatorProps?: ISelectItemValidatorProps;
}

interface IChangedValuesTable {
  useGroupConfigMode?: boolean;
  changedValues: IChangedValue[];
}

const { Text } = Typography;

/**
 * Tabular overview showing the changes in the therapy configuration. Used in a
 * modal dialogue that the user must confirm before the therapy configuration is
 * saved.
 *
 */
export function ChangedValuesTable({ changedValues, useGroupConfigMode }: IChangedValuesTable) {
  const { t } = useTranslation();
  const columns: ColumnsType<IChangedValue> = [
    {
      title: t("study.therapyConfig.changedValuesTable.parameter"),
      dataIndex: "key",
      key: "paramater",
      width: 200,
    },
    {
      title: t("study.therapyConfig.changedValuesTable.oldValue"),
      dataIndex: "oldValue",
      key: "oldValue",
      width: 200,
      render: (value, record, index) => (
        <CellRender value={value} type={record.type} validatorProps={record.validatorProps} />
      ),
    },
    {
      title: t("study.therapyConfig.changedValuesTable.newValue"),
      dataIndex: "newValue",
      key: "newValue",
      width: 200,
      render: (value, record, index) => (
        <CellRender
          value={value}
          type={record.type}
          validatorProps={record.validatorProps}
          highlight
        />
      ),
    },
  ];

  if (useGroupConfigMode) {
    columns.unshift({
      title: "Teilnehmergruppe",
      dataIndex: "groupName",
      key: "group",
      width: 200,
    });
  }
  return <Table pagination={false} dataSource={changedValues} columns={columns} />;
}

interface ICellRenderProps {
  value: any;
  type: string;
  // eslint-disable-next-line react/require-default-props
  highlight?: boolean;
  validatorProps?: ISelectItemValidatorProps;
}

/**
 * Renders a therapy config value based on its type. The value is highlighted if
 * the `highlight` prop is set to true.
 */
function CellRender({ value, type, highlight = false, validatorProps }: ICellRenderProps) {
  switch (type) {
    case VariableType.BOOLEAN: {
      return <Checkbox checked={value} disabled={!highlight} />;
    }
    case VariableType.TIME_RANGE: {
      return (
        <Text strong={highlight}>
          {formatTime(value.start)} - {formatTime(value.end)}
        </Text>
      );
    }
    case VariableType.INT:
    case VariableType.TEXT: {
      return <Text strong={highlight}>{value}</Text>;
    }
    case VariableType.TIME: {
      return <Text strong={highlight}>{formatTime(value)}</Text>;
    }
    case VariableType.SINGLE_SELECT:
      return (
        <Text strong={highlight}>
          {validatorProps?.options?.find((option: any) => option.value === value)?.label || "?"}
        </Text>
      );
    case VariableType.MULTI_SELECT: {
      return (
        <ul>
          {value.map((item: any) => (
            <li key={item}>
              <Text strong={highlight}>
                {validatorProps?.options?.find((option: any) => option.value === item)?.label ||
                  "?"}
              </Text>
            </li>
          ))}
        </ul>
      );
    }
    case VariableType.DATE: {
      return <Text strong={highlight}>{formatDate(value)}</Text>;
    }
    default: {
      return <Text type="danger">Missing Representation for {type}</Text>;
    }
  }
}

/**
 * Formats a time string by removing the seconds.
 */
export function formatTime(timeString: string): string {
  const timeParts = timeString.split(":");

  return `${timeParts[0]}:${timeParts[1]}`;
}

/**
 * Formats a date string to a locale date string, based on the user's browser
 * settings and a given pattern.
 */
export function formatDate(dateString: string): string {
  const date = new Date(dateString);
  return date.toLocaleDateString(undefined, { year: "numeric", month: "2-digit", day: "2-digit" });
}

export default ChangedValuesTable;
