import React from "react";
import { useTranslation } from "react-i18next";
import { Form, Input, Button, Typography, Skeleton, Collapse, Alert, App, Space } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useKeycloak } from "@react-keycloak/web";
import { SubmitButton, useUserId } from "../../shared";
import { useAccount, useUpdateAccount, useDeleteAccount } from "../../services";

const { Text, Paragraph, Title } = Typography;
const { Panel } = Collapse;

const layout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 20,
  },
};

const buttonLayout = {
  wrapperCol: {
    offset: 21,
    span: 3,
  },
};

export function AccountSettings() {
  const { userId } = useUserId();
  const { modal } = App.useApp();
  const [form] = Form.useForm();
  const { keycloak } = useKeycloak();
  const { account, loading: accountLoading } = useAccount(userId);
  const { updateEmailLoading, updatePasswordLoading, updateEmail, triggerPasswordUpdate } =
    useUpdateAccount(userId);
  const { canDelete, deleteAccount } = useDeleteAccount({ onCompleted: () => keycloak.logout() });
  const { t } = useTranslation();

  const handleDeleteAccount = () => {
    modal.confirm({
      title: t("user.deleteAccount.title"),
      icon: <ExclamationCircleOutlined />,
      content: t("user.deleteAccount.content"),
      okText: t("common.yes"),
      okType: "danger",
      cancelText: t("common.no"),
      onOk() {
        deleteAccount();
      },
    });
  };

  const handleChangeEmail = (newEmail: string) => {
    modal.confirm({
      title: t("user.updateEmail.modal.title"),
      icon: <ExclamationCircleOutlined />,
      content: t("user.updateEmail.modal.content", { newEmail }),
      okText: t("common.yes"),
      okType: "danger",
      cancelText: t("common.no"),
      onOk() {
        updateEmail(newEmail);
      },
    });
  };

  const handleResetPassword = () => {
    modal.confirm({
      title: t("user.resetPassword.modal.title"),
      icon: <ExclamationCircleOutlined />,
      content: t("user.resetPassword.modal.content"),
      okText: t("common.yes"),
      okType: "danger",
      cancelText: t("common.no"),
      onOk() {
        triggerPasswordUpdate();
      },
    });
  };

  return (
    <Collapse
      expandIconPosition="right"
      bordered={false}
      style={{ backgroundColor: "white" }}
      defaultActiveKey={["1", "2"]}
    >
      <Panel
        header={
          <>
            <Title level={4} style={{ marginTop: 0 }}>
              {t("user.account.email.title")}
            </Title>
            <Text>{t("user.account.email.shortDescription")}</Text>
          </>
        }
        key="1"
      >
        <Paragraph>{t("user.account.email.description")}</Paragraph>

        <Form {...layout} form={form} onFinish={(values) => handleChangeEmail(values.newEmail)}>
          <Form.Item label={t("user.account.email.currentLabel")}>
            {accountLoading ? (
              <Skeleton.Input active style={{ width: 200 }} />
            ) : (
              <Text strong>{account?.email}</Text>
            )}
          </Form.Item>

          <Form.Item
            name="newEmail"
            label={t("user.account.email.newLabel")}
            rules={[
              {
                required: true,
                type: "email",
                message: t("user.errorMessages.missingEmail"),
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="newEmail_confirmed"
            label={t("user.account.email.confirmLabel")}
            rules={[
              {
                required: true,
                type: "email",
                message: t("user.errorMessages.missingEmailConfirmation"),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newEmail") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(t("user.errorMessages.emailAddressesDoNotMatch")),
                  );
                },
              }),
            ]}
            dependencies={["newEmail"]}
            hasFeedback
          >
            <Input />
          </Form.Item>

          <Form.Item {...buttonLayout}>
            <Space style={{ float: "right" }}>
              <Button htmlType="reset">{t("common.discardChanges")}</Button>
              <SubmitButton formInstance={form} loading={updateEmailLoading}>
                {t("user.account.changeEmailButton")}
              </SubmitButton>
            </Space>
          </Form.Item>
        </Form>
      </Panel>

      <Panel
        header={
          <>
            <Title level={4} style={{ marginTop: 0 }}>
              {t("user.account.password.title")}
            </Title>
            <Text>{t("user.account.password.shortDescription")}</Text>
          </>
        }
        key="2"
      >
        <Paragraph>{t("user.account.password.description")}</Paragraph>
        <Button loading={updatePasswordLoading} onClick={() => handleResetPassword()}>
          {t("user.account.password.updatePasswordButton")}
        </Button>
      </Panel>
      <Panel
        header={
          <>
            <Title level={4} style={{ marginTop: 0 }}>
              {t("user.account.advancedSettings.title")}
            </Title>
            <Text>{t("user.account.advancedSettings.shortDescription")}</Text>
          </>
        }
        key="3"
      >
        <Alert
          description={
            <>
              <Paragraph>{t("user.account.advancedSettings.description.1")}</Paragraph>
              {canDelete && canDelete.organizationsLastOwner.length > 0 && (
                <>
                  <Text strong>{t("user.account.advancedSettings.description.2")}</Text>
                  <ul>
                    {canDelete.organizationsLastOwner.map((oranizationName) => (
                      <li key={oranizationName}>{oranizationName}</li>
                    ))}
                  </ul>
                </>
              )}
              {canDelete && canDelete.studiesLastOwner.length > 0 && (
                <>
                  <Text strong>{t("user.account.advancedSettings.description.3")}</Text>
                  <ul>
                    {canDelete.studiesLastOwner.map((studyName) => (
                      <li key={studyName}>{studyName}</li>
                    ))}
                  </ul>
                </>
              )}
            </>
          }
          type="error"
          action={
            <Button danger disabled={!canDelete?.canDelete} onClick={handleDeleteAccount}>
              {t("common.delete")}
            </Button>
          }
        />
      </Panel>
    </Collapse>
  );
}

export default AccountSettings;
