/* eslint-disable no-unused-vars */
/* eslint-disable react/require-default-props */
import React, { ReactNode, useEffect, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Menu, Layout, Divider, Typography, Affix } from "antd";
import {
  LineChartOutlined,
  ToolOutlined,
  EyeOutlined,
  RightOutlined,
  LeftOutlined,
  TagOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import {
  participantSiderStyle,
  LinkedMenuItemWithPermission,
  participantSiderWidth,
  navRouterYOffset,
  participantMenuStyle,
  Paths,
  getTherapyConfigHashLink,
  HashLinkedMenuItem,
  primaryColor,
  highlighterColor,
  headerHeight,
  collapsedSiderWidthTabletMode,
  collapsedSiderWidth,
  participantPageHeaderHeight,
  SubMenuWithPermission,
} from "../../../../shared";

import { ActionEnum, GetLabelingInformationQuery } from "../../../../services";
import { addObjectField, isInInteractionDesignerMode } from "../../../../utils";
import { useTabletMode } from "../../../../shared/hooks/useTabletMode";

const { Sider } = Layout;
const { SubMenu } = Menu;
const { Text } = Typography;

type LabelingInformation = GetLabelingInformationQuery["getLabelingInformationAction"];

interface IHashLink {
  name: string;
  hashLink: string;
}

interface IParticipantMenuProps {
  selectedKeys?: string[];
  defaultOpenKeys?: string[];
  studyId: string;
  participantId: string;
  therapyElements?: IHashLink[];
  visualizations?: IHashLink[];
  labelingInformation?: LabelingInformation | null;
}

// eslint-disable-next-line no-shadow
export enum ParticipantMenuItemEnum {
  DASHBOARD = "participant:dashboard",
  LABELING_INFORMATION = "participant:labelingInformation",
  SUPERVISORS = "participant:supervisors",
  THERAPY_CONFIG = "participant:therapyConfig",
}

/**
 * Takes an array of HashLink objects and creates a nested object structure
 * based on their names and hash links
 * @param items An array of objects containing a name and hashLink property.
 * @returns An object structure with the names as paths and the hashLinks as
 * values.
 */
const createMenuStructure = (items?: IHashLink[]) => {
  const structure = {};
  items?.forEach((item) => {
    addObjectField({
      object: structure,
      path: item.name,
      delimiter: ":",
      value: item.hashLink,
    });
  });
  return structure;
};

/**
 * Recursive function that takes a nested object structure and creates an antd
 * Menu component with nested SubMenu and HashLinkedMenuItem components.
 *
 * @param config The object structure to create the menu from.
 * @returns The antd Menu component.
 */
const createMenuContentFromStructure = (config: any): ReactNode =>
  Object.keys(config).map((key) => {
    if (typeof config[key] === "object" && config[key] !== null) {
      if (key.startsWith("_")) {
        return (
          <React.Fragment key={key}>
            <HashLinkedMenuItem
              data-id={`${key}`}
              linkAddress={getTherapyConfigHashLink(key)}
              pageYOffset={navRouterYOffset}
              itemText={
                <Divider style={{ borderColor: "#9c9c9c" }} plain>
                  <Text italic style={{ color: "#9c9c9c" }}>
                    {key.replace("_", "")}
                  </Text>
                </Divider>
              }
              key={key.replace("_", "")}
            />
            {createMenuContentFromStructure(config[key])}
          </React.Fragment>
        );
      }
      return (
        <SubMenu key={`${key}`} title={key}>
          {createMenuContentFromStructure(config[key])}
        </SubMenu>
      );
    }
    return (
      <HashLinkedMenuItem
        key={key}
        data-id={`${key}`}
        linkAddress={`${config[key]}`}
        itemText={`${key}`}
        pageYOffset={navRouterYOffset}
      />
    );
  });

/**
 * React component that renders a menu sidebar for a participant in a study.
 *
 * @param selectedKeys The currently selected menu items.
 * @param studyId The ID of the study the participant belongs to.
 * @param participantId The ID of the participant.
 * @param defaultOpenKeys The default opened sub menu items.
 * @param therapyElements An array of therapy elements to include in the menu.
 * @param visualizations An array of visualizations to include in the menu.
 * @param labelingInformation The labeling information to include in the menu.
 * @returns The antd Sider component with the menu.
 */
export function ParticipantSider({
  selectedKeys = [],
  studyId,
  participantId,
  defaultOpenKeys = [""],
  therapyElements = [],
  visualizations,
  labelingInformation,
}: IParticipantMenuProps) {
  const isInTabletMode = useTabletMode();
  const [collapsed, setCollapsed] = useState(isInTabletMode);
  const { t } = useTranslation();
  const [scrollPosition, setScrollPosition] = useState(window.pageYOffset);
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (collapsed) {
      setOpenKeys([]);
    } else {
      setOpenKeys(defaultOpenKeys);
    }
  }, [defaultOpenKeys[0], collapsed]);

  useEffect(() => {
    if (isInTabletMode) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  }, [isInTabletMode]);

  useLayoutEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.pageYOffset);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  const participantRoutes = Paths.getStudyPaths(studyId).getParticipantsPaths(participantId);
  const visualizationsMenu = createMenuContentFromStructure(createMenuStructure(visualizations));
  const therapyConfigMenu = createMenuContentFromStructure(createMenuStructure(therapyElements));
  const collapsedWidth = isInTabletMode ? collapsedSiderWidthTabletMode : collapsedSiderWidth;

  return (
    <Sider
      width={participantSiderWidth}
      style={{
        ...participantSiderStyle,
        position: isInTabletMode ? "fixed" : undefined,
        height: isInTabletMode ? "100vh" : undefined,
        top: isInTabletMode
          ? headerHeight + Math.max(...[participantPageHeaderHeight - scrollPosition, 0])
          : undefined,
      }}
      collapsedWidth={collapsedWidth}
      collapsible
      collapsed={collapsed}
      onCollapse={(value: boolean) => setCollapsed(value)}
      trigger={
        <div style={{ backgroundColor: "white" }}>
          <div
            style={{
              fontSize: 16,
              backgroundColor: highlighterColor,
              color: primaryColor,
            }}
          >
            {collapsed ? <RightOutlined /> : <LeftOutlined />}
          </div>
        </div>
      }
    >
      <Affix offsetTop={headerHeight}>
        <Menu
          selectedKeys={selectedKeys}
          defaultOpenKeys={collapsed ? [] : defaultOpenKeys}
          mode="inline"
          theme="light"
          inlineIndent={16}
          style={{
            ...participantMenuStyle,
            maxHeight: `calc(100vH - ${
              participantPageHeaderHeight -
              28 +
              Math.max(...[participantPageHeaderHeight - scrollPosition, 0])
            }px)`,
          }}
          openKeys={openKeys}
          onOpenChange={(values: any) => {
            const topLevelSubMenuItemToOpen = [
              ParticipantMenuItemEnum.THERAPY_CONFIG,
              ParticipantMenuItemEnum.DASHBOARD,
            ].find((menuItem) => values.includes(menuItem) && !openKeys?.includes(menuItem));

            if (topLevelSubMenuItemToOpen === ParticipantMenuItemEnum.THERAPY_CONFIG) {
              setOpenKeys([ParticipantMenuItemEnum.THERAPY_CONFIG]);
            } else if (topLevelSubMenuItemToOpen === ParticipantMenuItemEnum.DASHBOARD) {
              setOpenKeys([ParticipantMenuItemEnum.DASHBOARD]);
            } else {
              setOpenKeys(values);
            }
          }}
          onSelect={() => {
            if (isInTabletMode && !collapsed) setCollapsed(true);
          }}
        >
          {visualizations ? (
            <SubMenuWithPermission
              action={ActionEnum.PARTICIPANT_VIEW_DETAILS}
              data-id="participant.menu.dashboard"
              icon={<LineChartOutlined />}
              key={ParticipantMenuItemEnum.DASHBOARD}
              participantId={participantId}
              title={t("participant.menu.dashboard")}
              onTitleClick={() => {
                navigate(participantRoutes.DASHBOARD);
              }}
            >
              {visualizationsMenu}
            </SubMenuWithPermission>
          ) : (
            <LinkedMenuItemWithPermission
              action={ActionEnum.PARTICIPANT_VIEW_DETAILS}
              data-id="participant.menu.dashboard"
              icon={<LineChartOutlined />}
              itemText={t("participant.menu.dashboard")}
              key={ParticipantMenuItemEnum.DASHBOARD}
              linkAddress={participantRoutes.DASHBOARD}
              participantId={participantId}
            />
          )}

          {therapyElements.length > 0 ? (
            <SubMenuWithPermission
              action={ActionEnum.PARTICIPANT_VIEW_DETAILS}
              data-id="participant.menu.therapyConfig"
              icon={<ToolOutlined />}
              key={ParticipantMenuItemEnum.THERAPY_CONFIG}
              participantId={participantId}
              title={t("participant.menu.therapyConfig")}
              onTitleClick={() => {
                navigate(participantRoutes.THERAPY_CONFIG);
              }}
            >
              {therapyConfigMenu}
            </SubMenuWithPermission>
          ) : null}

          <LinkedMenuItemWithPermission
            action={ActionEnum.PARTICIPANT_VIEW_DETAILS}
            data-id="participant.menu.supervisors"
            icon={<EyeOutlined />}
            itemText={t("participant.menu.supervisors")}
            key={ParticipantMenuItemEnum.SUPERVISORS}
            linkAddress={participantRoutes.SUPERVISORS}
            participantId={participantId}
          />

          {labelingInformation && !isInInteractionDesignerMode ? (
            <LinkedMenuItemWithPermission
              action={ActionEnum.PARTICIPANT_VIEW_DETAILS}
              data-id="participant.menu.labeling"
              icon={<TagOutlined />}
              itemText={t("participant.menu.labeling")}
              key={ParticipantMenuItemEnum.LABELING_INFORMATION}
              linkAddress={participantRoutes.LABELING_INFORMATION}
              participantId={participantId}
              studyId={studyId}
            />
          ) : null}
        </Menu>
      </Affix>
    </Sider>
  );
}
