import { Button, Form, Input } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useUpdateProfile } from "../../services";
import { useUserId } from "../../shared";
import LoginContainer, { layout, tailLayout } from "./LoginContainer";

export interface IFirstLoginProps {
  logout: () => void;
}

export function FirstLogin({ logout }: IFirstLoginProps) {
  const { t } = useTranslation();
  const { userId } = useUserId();
  const { loading, updateProfile } = useUpdateProfile({});

  const onFinish = (values: any) => {
    updateProfile({
      userId,
      firstName: values.firstName,
      lastName: values.lastName,
      title: values.title,
    });
  };

  return (
    <LoginContainer title={t("user.firstLogin.title")}>
      <Form {...layout} name="basic" onFinish={onFinish}>
        <Form.Item name="title" label={t("user.title")} rules={[{ required: false }]}>
          <Input />
        </Form.Item>

        <Form.Item name="firstName" label={t("user.firstName")} rules={[{ required: true }]}>
          <Input data-id="BASIC_FIRST_NAME" />
        </Form.Item>

        <Form.Item name="lastName" label={t("user.lastName")} rules={[{ required: true }]}>
          <Input data-id="BASIC_LAST_NAME" />
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button loading={loading} style={{ float: "right" }} type="primary" htmlType="submit">
            {t("common.save")}
          </Button>

          <Button style={{ float: "left" }} onClick={() => logout()}>
            {t("user.menu.signOut")}
          </Button>
        </Form.Item>
      </Form>
    </LoginContainer>
  );
}

export default FirstLogin;
