import { ApolloError, useMutation } from "@apollo/client";
import { notification } from "antd";
import { useTranslation } from "react-i18next";
import { UpdateUserDetailsMutation, useUpdateUserDetailsMutation } from "../codegen";

interface IUseUpdateProfile {
  // eslint-disable-next-line no-unused-vars
  onCompleted?: (data: UpdateUserDetailsMutation) => void;
  // eslint-disable-next-line no-unused-vars
  onError?: (error: ApolloError) => void;
}

interface IUpdateProfil {
  userId: string;
  firstName: string;
  lastName: string;
  title?: string;
}

export function useUpdateProfile({
  onCompleted = () => {},
  onError = () => {},
}: IUseUpdateProfile) {
  const { t } = useTranslation();
  const [mutate, { loading }] = useUpdateUserDetailsMutation({
    onCompleted: (data) => {
      notification.success({
        message: t("notifications.updateProfileInformation.success"),
      });
      onCompleted(data);
    },
    onError: (error) => {
      notification.error({
        message: t("notifications.updateProfileInformation.error"),
      });
      onError(error);
    },
  });

  const updateProfile = (props: IUpdateProfil) => {
    mutate({
      variables: {
        userId: props.userId,
        firstName: props.firstName,
        lastName: props.lastName,
        title: props.title,
      },
    });
  };

  return { updateProfile, loading };
}

export default useUpdateProfile;
