import React, { ReactNode } from "react";
import { mainPagesStyle } from "../../../shared";

interface IMainPageContainerProps {
  children: ReactNode;
}

export function MainPageContainer({ children }: IMainPageContainerProps) {
  return <div style={mainPagesStyle}>{children}</div>;
}

export default MainPageContainer;
