import { Select } from "antd";
import React, { CSSProperties, useState } from "react";
import { useTranslation } from "react-i18next";
import { getStudyRoleLabelColor } from "..";
import { StudyRoleEnum } from "../../enumerations/study-role-enum";
import TagMultiFilter from "./TagMultiFilter";

const { Option } = Select;

export interface IStudyRoleFilterProps {
  initialValue?: string[];
  style?: CSSProperties;
  // eslint-disable-next-line no-unused-vars
  onChange: (value: string[]) => void;
}

export function StudyRoleMultiFilter({
  onChange,
  style = {},
  initialValue = [StudyRoleEnum.PARTICIPANT_MANAGER, StudyRoleEnum.ADMIN, StudyRoleEnum.OWNER],
}: IStudyRoleFilterProps) {
  const { t } = useTranslation();
  return (
    <TagMultiFilter
      getTagColor={getStudyRoleLabelColor}
      initialValue={initialValue}
      placeholderText={t("study.personnel.members.roleFilterMulti.placeholder")}
      options={["PATIENTMANAGER", "ADMIN", "OWNER"]}
      onChange={onChange}
      style={style}
    />
  );
}

export interface IStudyRoleSingleFilterProps {
  initialValue?: StudyRoleEnum;
  style?: CSSProperties;
  // eslint-disable-next-line no-unused-vars
  onChange: (value: StudyRoleEnum) => void;
}

export function StudyRoleFilterSingle({
  initialValue = StudyRoleEnum.ALL,
  onChange,
  style = {},
}: IStudyRoleSingleFilterProps) {
  const [value, setValue] = useState<StudyRoleEnum>(initialValue);
  const { t } = useTranslation();

  const handleChange = (newValue: any) => {
    setValue(newValue);
    onChange(newValue);
  };

  const options = [StudyRoleEnum.PARTICIPANT_MANAGER, StudyRoleEnum.ADMIN, StudyRoleEnum.OWNER];

  return (
    <Select value={value} style={style} onChange={handleChange}>
      <Option value={StudyRoleEnum.ALL}>{t("studyRoleFilter.all")}</Option>
      {options.map((option) => (
        <Option key={option} value={option}>
          {option}
        </Option>
      ))}
    </Select>
  );
}
