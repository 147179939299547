/* eslint-disable no-unused-vars */
import React from "react";
import { RiUserHeartLine } from "react-icons/ri";
import { useTranslation } from "react-i18next";
import { SettingOutlined, HomeOutlined, TeamOutlined, ShareAltOutlined } from "@ant-design/icons";
import { usePermission, ActionEnum } from "../../../services";
import {
  StudyLogo,
  ISiderMenuProps,
  SideMenuContainer,
  LinkedMenuItemWithPermission,
  SubMenuWithPermission,
  Paths,
} from "../../../shared";

export interface IStudySiderProps extends ISiderMenuProps {
  studyId: string;
  studyLogo?: string | null;
  therapyElements?: { name: string; hashLink: string }[];
}

// eslint-disable-next-line no-shadow
export enum StudySiderMenuItemEnum {
  OVERVIEW = "OVERVIEW",
  DASHBOARD = "DASHBOARD",
  PARTICIPANTS = "PARTICIPANTS",
  SETTINGS = "SETTINGS",
  GROUP_SUBMENU = "GROUP_SUBMENU",
  GROUPS = "GROUPS",
  GROUPS_THERAPY_CONFIG = "GROUPS_THERAPY_CONFIG",
  PERSONNEL_SUBMENU = "PERSONNEL_SUBMENU",
  PARTICIPANT_SUPERVISORS = "PARTICIPANT_SUPERVISORS",
  STUDY_ADMINISTRATORS = "STUDY_ADMINISTRATORS",
  EDIT_STUDY = "EDIT_STUDY",
  THERAPY_DESIGN = "THERAPY_DESIGN",
}

export function StudySider(props: IStudySiderProps) {
  const { studyId, title, studyLogo, therapyElements = [] } = props;
  const { permitted } = usePermission({ action: ActionEnum.STUDY_VIEW_DETAILS, studyId });
  const { t } = useTranslation();
  const studyRoutes = Paths.getStudyPaths(studyId);

  return permitted ? (
    <SideMenuContainer
      {...props}
      homeRoute={studyRoutes.BASE_PATH}
      titleLogo={<StudyLogo src={studyLogo} studyTitle={title} />}
    >
      <LinkedMenuItemWithPermission
        data-id="study.menu.overview"
        key={StudySiderMenuItemEnum.OVERVIEW}
        icon={<HomeOutlined />}
        action={ActionEnum.STUDY_VIEW_DETAILS}
        studyId={studyId}
        linkAddress={studyRoutes.OVERVIEW}
        itemText={t("study.menu.overview")}
      />

      <LinkedMenuItemWithPermission
        data-id="study.menu.participants"
        key={StudySiderMenuItemEnum.PARTICIPANTS}
        icon={<RiUserHeartLine />}
        action={ActionEnum.STUDY_VIEW_PARTICIPANTS}
        studyId={studyId}
        linkAddress={studyRoutes.PARTICIPANTS}
        itemText={t("study.menu.participants")}
      />

      <SubMenuWithPermission
        data-id="study.menu.participantsGroups"
        key={StudySiderMenuItemEnum.GROUP_SUBMENU}
        icon={<TeamOutlined />}
        title={t("study.menu.participantsGroups")}
        action={ActionEnum.STUDY_VIEW_GROUPS}
        studyId={studyId}
      >
        <LinkedMenuItemWithPermission
          data-id="study.menu.manageGroups"
          key={StudySiderMenuItemEnum.GROUPS}
          action={ActionEnum.STUDY_VIEW_GROUPS}
          studyId={studyId}
          linkAddress={studyRoutes.PARTICIPANTS_GROUPS}
          itemText={t("study.menu.manageGroups")}
        />

        {therapyElements.length > 0 && (
          <LinkedMenuItemWithPermission
            data-id="study.menu.therapyConfig"
            key={StudySiderMenuItemEnum.GROUPS_THERAPY_CONFIG}
            action={ActionEnum.STUDY_EDIT_GROUP_THERAPY_CONFIG}
            studyId={studyId}
            linkAddress={studyRoutes.PARTICIPANTS_GROUPS_THERAPY_CONFIG}
            itemText={t("study.menu.therapyConfig")}
          />
        )}
      </SubMenuWithPermission>

      <SubMenuWithPermission
        data-id="study.menu.studyPersonnel"
        key={StudySiderMenuItemEnum.PERSONNEL_SUBMENU}
        icon={<ShareAltOutlined />}
        action={ActionEnum.STUDY_VIEW_MEMBERS}
        studyId={studyId}
        title={t("study.menu.studyPersonnel")}
      >
        <LinkedMenuItemWithPermission
          data-id="study.menu.participantSupervisors"
          key={StudySiderMenuItemEnum.PARTICIPANT_SUPERVISORS}
          action={ActionEnum.STUDY_VIEW_MEMBERS}
          studyId={studyId}
          linkAddress={studyRoutes.SUPERVISORS}
          itemText={t("study.menu.participantSupervisors")}
        />

        <LinkedMenuItemWithPermission
          data-id="study.menu.studyEditors"
          key={StudySiderMenuItemEnum.STUDY_ADMINISTRATORS}
          action={ActionEnum.STUDY_VIEW_MEMBERS}
          studyId={studyId}
          linkAddress={studyRoutes.EDITORS}
          itemText={t("study.menu.studyEditors")}
        />
      </SubMenuWithPermission>

      <SubMenuWithPermission
        data-id="study.menu.settings"
        key={StudySiderMenuItemEnum.SETTINGS}
        icon={<SettingOutlined />}
        title={t("study.menu.settings")}
        action={ActionEnum.STUDY_EDIT}
        studyId={studyId}
      >
        <LinkedMenuItemWithPermission
          data-id="study.menu.generalSettings"
          key={StudySiderMenuItemEnum.EDIT_STUDY}
          action={ActionEnum.STUDY_EDIT}
          studyId={studyId}
          linkAddress={studyRoutes.SETTINGS}
          itemText={t("study.menu.generalSettings")}
        />
      </SubMenuWithPermission>
    </SideMenuContainer>
  ) : null;
}
