import { ApolloError } from "@apollo/client";
import { notification } from "antd";
import { useTranslation } from "react-i18next";

import {
  CreateOrganizationMemberMutation,
  GetOrganizationMembersDocument,
  useCreateOrganizationMemberMutation,
} from "../codegen";

interface IUseCreateOrganizationMember {
  // eslint-disable-next-line no-unused-vars
  onCompleted?: (data: CreateOrganizationMemberMutation) => void;
  // eslint-disable-next-line no-unused-vars
  onError?: (error: ApolloError) => void;
}

interface ICreateOrganizationMember {
  email: string;
  organizationId: string;
  roleId: string;
}

export function useCreateOrganizationMember({
  onCompleted = () => {},
  onError = () => {},
}: IUseCreateOrganizationMember) {
  const { t } = useTranslation();
  const [mutate, { loading }] = useCreateOrganizationMemberMutation({
    onCompleted: (data) => {
      notification.success({
        message: t("notifications.createOrganizationMember.success", {
          userName: data.createOrganizationMemberAction?.email,
          roleName: data.createOrganizationMemberAction?.organizationMember?.organizationRole.name,
        }),
      });
      onCompleted(data);
    },
    onError: (error) => {
      notification.error({
        message: t("notifications.createOrganizationMember.error"),
      });
      onError(error);
    },
  });

  const createOrganizationMember = (props: ICreateOrganizationMember) => {
    mutate({
      variables: {
        email: props.email,
        organizationId: props.organizationId,
        roleId: props.roleId,
      },
      refetchQueries: [
        {
          query: GetOrganizationMembersDocument,
          variables: { organizationId: props.organizationId },
        },
      ],
    });
  };

  return { createOrganizationMember, loading };
}

export default useCreateOrganizationMember;
