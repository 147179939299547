/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Typography, Divider, Select, Form, Input, Button, Result, Alert } from "antd";
import { BulbOutlined } from "@ant-design/icons";
import "../../shared/layout/css/FullPage.css";
import { UserFromQuery, useCreateStudy, useExecutorsOfOrganizationLazy } from "../../services";
import { boxShadow, PageHeader, primaryColor, Paths } from "../../shared";
import { MainPageContainer } from "./components";

const { Title, Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

const buttonLayout = {
  wrapperCol: {
    offset: 21,
    span: 3,
  },
};

interface INewStudiesProps {
  user: UserFromQuery;
}

interface IValidator {
  validateStatus: "" | "success" | "error" | "warning" | "validating" | undefined;
  errorMsg: string | null;
}

export function NewStudy({ user }: INewStudiesProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { createStudy, loading } = useCreateStudy({
    onCompleted: (data) => {
      const studyId = data.createStudyAction?.id;
      if (studyId) {
        navigate(Paths.getStudyPaths(studyId).OVERVIEW);
      }
    },
  });
  const [form] = Form.useForm();
  const {
    getExecutorsOfOrganization,
    executors,
    loading: executorsLoading,
  } = useExecutorsOfOrganizationLazy();

  const organizationRoles = user.organizationMembers.filter(
    (item) => item.organizationRole.organizationCreateStudy,
  );

  const handleCreateStudy = (values: any) => {
    createStudy({
      name: values.name,
      organizationId: values.organizationId,
      executorId: values.executorId,
      description: values.description,
    });
  };

  return organizationRoles.length > 0 ? (
    <MainPageContainer>
      <PageHeader
        hideDivider
        title={<Title level={2}>{t("study.createNew.title")}</Title>}
        description={t("study.createNew.description")}
      />

      <Divider />

      <Form {...layout} name="basic" onFinish={handleCreateStudy} form={form}>
        <Form.Item
          name="organizationId"
          label={t("study.createNew.organization")}
          rules={[{ required: true, message: t("study.errorMessages.missingOrganization") }]}
        >
          <Select
            placeholder={t("study.createNew.selectOrganizationPlaceholder")}
            onChange={(organizationId) => {
              form.setFieldsValue({ therapyDesignId: undefined });
              getExecutorsOfOrganization(organizationId);
            }}
          >
            {organizationRoles?.map((item) => (
              <Option value={item.organization.id} key={item.organization.id}>
                {item.organization.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Alert
          style={{ marginBottom: 16 }}
          message={t("study.createNew.executorAlert.title")}
          description={t("study.createNew.executorAlert.description")}
          type="info"
          showIcon
        />

        <Form.Item
          name="executorId"
          label={t("study.createNew.executorLabel")}
          rules={[{ required: true, message: t("study.errorMessages.missingExecutor") }]}
        >
          <Select
            placeholder={t("study.createNew.selectExecutorPlaceholder")}
            disabled={executors === undefined || executors.length === 0}
            loading={executorsLoading}
          >
            {executors?.map((item) => (
              <Option value={item.id} key={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="name"
          label={t("study.createNew.studyNameLabel")}
          rules={[{ required: true, message: t("study.errorMessages.studyNameMissing") }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="description"
          label={t("study.createNew.studyDescriptionLabel")}
          rules={[{ required: false }]}
        >
          <TextArea rows={4} />
        </Form.Item>

        <Form.Item {...buttonLayout}>
          <Button loading={loading} style={{ float: "right" }} type="primary" htmlType="submit">
            {t("study.createNew.createButton")}
          </Button>
        </Form.Item>
      </Form>
    </MainPageContainer>
  ) : (
    <Result
      style={{
        margin: "auto",
        width: "70%",
        background: "white",
        padding: 40,
        boxShadow,
      }}
      icon={<BulbOutlined style={{ color: primaryColor }} />}
      title={t("study.createNew.createOrganization.title")}
      subTitle={
        <>
          <Text>{t("study.createNew.createOrganization.description.1")}</Text>
          <br />
          <Text>{t("study.createNew.createOrganization.description.2")} </Text>
          <ol style={{ textAlign: "left" }}>
            <li>{t("study.createNew.createOrganization.description.3")}</li>
            <li>{t("study.createNew.createOrganization.description.4")}</li>
            <li>{t("study.createNew.createOrganization.description.5")}</li>
          </ol>
        </>
      }
      extra={
        <Link to={Paths.ORGANIZATION_NEW}>
          <Button type="primary">{t("study.createNew.createOrganization.createButton")}</Button>
        </Link>
      }
    />
  );
}

export default NewStudy;
