import { ApolloError } from "@apollo/client";
import { notification } from "antd";
import { useTranslation } from "react-i18next";
import { UpdateExecutorMutation, useUpdateExecutorMutation } from "../codegen";

interface IUseUpdateOrganization {
  // eslint-disable-next-line no-unused-vars
  onCompleted?: (data: UpdateExecutorMutation) => void;
  // eslint-disable-next-line no-unused-vars
  onError?: (error: ApolloError) => void;
}

interface IUpdateExecutor {
  id: string;
  executorUrl: string;
  executorUrlExternal: string;
  participantLimit: number;
  name: string;
  hidden: boolean;
  expiresAt: string;
}

export function useUpdateExecutor({
  onCompleted = () => {},
  onError = () => {},
}: IUseUpdateOrganization) {
  const { t } = useTranslation();
  const [mutate, { ...rest }] = useUpdateExecutorMutation({
    onCompleted: (data) => {
      notification.success({
        message: t("notifications.updateExecutor.success", {
          executorName: data.updateExecutor?.name,
        }),
      });
      onCompleted(data);
    },
    onError: (error) => {
      notification.error({
        message: t("notifications.updateExecutor.error"),
      });
      onError(error);
    },
    context: {
      headers: {
        "x-hasura-role": "master",
      },
    },
  });

  const updateExecutor = async ({
    id,
    executorUrl,
    executorUrlExternal,
    hidden,
    name,
    participantLimit,
    expiresAt,
  }: IUpdateExecutor) => {
    await mutate({
      variables: {
        id,
        executorUrl,
        executorUrlExternal,
        name,
        hidden,
        participantLimit,
        expiresAt,
      },
    });
  };

  return { updateExecutor, ...rest };
}

export default useUpdateExecutor;
