import {
  GetOrganizationQuery,
  useGetOrganizationLazyQuery,
  useGetOrganizationQuery,
} from "../codegen";

export function useOrganization(organizationId: string) {
  const { data, ...rest } = useGetOrganizationQuery({
    variables: { organizationId },
  });

  return { organization: data?.organization, ...rest };
}

export function useOrganizationLazy() {
  const [fetch, { data, ...rest }] = useGetOrganizationLazyQuery();

  const getOrganization = (organizationId: string) => {
    fetch({ variables: { organizationId } });
  };

  return { organization: data?.organization, getOrganization, ...rest };
}

export default useOrganization;

export type OrganizationFromQuery = NonNullable<GetOrganizationQuery["organization"]>;
