/* eslint-disable react/require-default-props */
import { Avatar, Upload, Button, Space } from "antd";
import { UploadOutlined, UserOutlined, RocketOutlined } from "@ant-design/icons";
import ImgCrop from "antd-img-crop";

import React from "react";
import Resizer from "react-image-file-resizer";
import { useTranslation } from "react-i18next";

interface IUpdateProfilePictureProps {
  imgBase64?: string;
  // eslint-disable-next-line no-unused-vars
  onImageChange: (imgBase64: string) => void;
  type: "avatar" | "logo";
}

export function UpdateProfilePicture({
  type,
  imgBase64,
  onImageChange,
}: IUpdateProfilePictureProps) {
  const { t } = useTranslation();
  const shapeAvatar = type === "avatar" ? "circle" : "square";
  return (
    <Space size="large">
      {imgBase64 ? (
        <Avatar size={100} shape={shapeAvatar} src={imgBase64} />
      ) : (
        <Avatar
          size={100}
          shape={shapeAvatar}
          icon={type === "avatar" ? <UserOutlined /> : <RocketOutlined />}
        />
      )}
      <div>
        <ImgCrop
          rotationSlider
          showGrid
          cropShape={type === "avatar" ? "round" : "rect"}
          modalOk={t("uploadProfilePicture.uploadButton", {
            pictureType:
              type === "avatar" ? t("uploadProfilePicture.avatar") : t("uploadProfilePicture.logo"),
          })}
          modalCancel={t("common.cancel")}
          modalTitle={t("uploadProfilePicture.editButton", {
            pictureType:
              type === "avatar" ? t("uploadProfilePicture.avatar") : t("uploadProfilePicture.logo"),
          })}
        >
          <Upload
            accept="image/png,image/jpeg"
            showUploadList={false}
            beforeUpload={(file) => {
              if (file) {
                try {
                  Resizer.imageFileResizer(
                    file,
                    150,
                    150,
                    "JPEG",
                    100,
                    0,
                    (uri) => {
                      if (typeof uri === "string") {
                        onImageChange(uri);
                      }
                    },
                    "base64",
                    150,
                    150,
                  );
                } catch (err) {
                  console.log(err);
                }
              }
              return false;
            }}
          >
            <Button style={{ marginTop: 8 }} icon={<UploadOutlined />}>
              {t("uploadProfilePicture.newButton", {
                pictureType:
                  type === "avatar"
                    ? t("uploadProfilePicture.avatar")
                    : t("uploadProfilePicture.logo"),
              })}
            </Button>
          </Upload>
        </ImgCrop>
        <br />
        <Button
          danger
          style={{ width: "100%", marginTop: 8 }}
          disabled={!imgBase64}
          onClick={() => onImageChange("")}
        >
          {t("uploadProfilePicture.deleteButton", {
            pictureType:
              type === "avatar" ? t("uploadProfilePicture.avatar") : t("uploadProfilePicture.logo"),
          })}
        </Button>
      </div>
    </Space>
  );
}

export default UpdateProfilePicture;
