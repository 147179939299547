import { ApolloError } from "@apollo/client";
import { notification } from "antd";
import { useTranslation } from "react-i18next";
import { useTherapyId } from "..";
import {
  CreateParticipantActionMutation,
  GetExecutorParticipantsDocument,
  GetParticipantsOfStudyDocument,
  useCreateParticipantActionMutation,
} from "../codegen";

interface IUseCreateParticipantProps {
  studyId: string;
  // eslint-disable-next-line no-unused-vars
  onCompleted?: (data: CreateParticipantActionMutation) => void;
  // eslint-disable-next-line no-unused-vars
  onError?: (error: ApolloError) => void;
}

interface ICreateParticipant {
  groupId: string;
  pseudonym: string;
  therapyStart?: any;
  therapyEnd?: any;
}

// eslint-disable-next-line no-unused-vars
export function useCreateParticipant({
  studyId,
  onCompleted = () => {},
  onError = () => {},
}: IUseCreateParticipantProps) {
  const { t } = useTranslation();
  const { therapyId } = useTherapyId(studyId);
  const [mutate, { loading }] = useCreateParticipantActionMutation({
    onCompleted: (data) => {
      notification.success({
        message: t("notifications.createParticipant.success", {
          pseudonym: data.createParticipantAction?.participant?.pseudonym,
        }),
      });

      onCompleted(data);
    },
    onError: (error) => {
      notification.error({
        message: t("notifications.createParticipant.error"),
      });
      onError(error);
    },
  });

  const createParticipant = (props: ICreateParticipant) => {
    return mutate({
      variables: {
        groupId: props.groupId,
        pseudonym: props.pseudonym,
        therapyStart: props.therapyStart ? props.therapyStart : null,
        therapyEnd: props.therapyEnd ? props.therapyEnd : null,
      },
      refetchQueries: [
        { query: GetExecutorParticipantsDocument, variables: { therapyId } },
        { query: GetParticipantsOfStudyDocument, variables: { studyId } },
      ],
    });
  };

  return { createParticipant, loading };
}

export default useCreateParticipant;
