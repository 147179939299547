import { Menu } from "antd";
import { MenuItemProps } from "antd/lib/menu/MenuItem";
import React, { ReactNode } from "react";
import { Link } from "react-router-dom";

export interface ILinkedMenuItemProps extends MenuItemProps {
  linkAddress: string;
  itemText: string | ReactNode;
}

export function LinkedMenuItem(props: ILinkedMenuItemProps) {
  const { linkAddress, itemText, ...rest } = props;
  return (
    <Menu.Item {...rest}>
      <Link to={linkAddress}>{itemText}</Link>
    </Menu.Item>
  );
}
