import { useTranslation } from "react-i18next";
import { useValidateParticipantTherapyConfigLazyQuery } from "../codegen";

export interface IUseValidateParticipantConfigProps {
  participantId: string;
  values: { variableId: string; value: any }[];
}

export function useValidateParticipantConfig() {
  const { i18n } = useTranslation();
  const [fetch, { data, loading }] = useValidateParticipantTherapyConfigLazyQuery({
    fetchPolicy: "cache-first",
  });

  const validate = ({ participantId, values }: IUseValidateParticipantConfigProps) =>
    fetch({ variables: { participantId, values, language: i18n.language } });

  return {
    validationResult: data?.validateParticipantTherapyConfigAction,
    validate,
    loading,
  };
}
