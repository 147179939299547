import { useSendTestNotificationMutation } from "../codegen";

export function useSendTestNotification(participantId: string) {
  const [mutate, { loading }] = useSendTestNotificationMutation({
    variables: { participantId },
  });

  const sendTestNotification = async () => {
    const result = await mutate();
    return result.data?.sendTestNotificationAction;
  };

  return { sendTestNotification, loading };
}

export default useSendTestNotification;
