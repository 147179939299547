import { Modal } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { GetSupervisorAssignmentsOfUserQuery, useDeleteSupervisor } from "../../../../services";
import useCreateSupervisorsById from "../../../../services/graphql/hooks/useCreateSupervisorById";

import { SupervisorAssignmentList } from "../../components";

export interface IFinishEditAssignments {
  numOfAssignmentsAdded: number;
  numOfAssignmentsRemoved: number;
}

type Participant = NonNullable<
  GetSupervisorAssignmentsOfUserQuery["supervisors"]
>[number]["participant"];

interface IEditSupervisorAssignmentModalProps {
  open: boolean;
  userId: string;
  userName: string;
  studyId: string;
  supervisorRoleId: string;
  assignedParticipants: Participant[];
  onCancel: () => void;
  // eslint-disable-next-line no-unused-vars
  onFinish: (result: IFinishEditAssignments) => void;
}

export function EditSupervisorAssignmentModal({
  open,
  userId,
  userName,
  assignedParticipants,
  supervisorRoleId,
  studyId,
  onCancel,
  onFinish,
}: IEditSupervisorAssignmentModalProps) {
  const [assignedParticipantsEdited, setAssignedParticipantsEdited] = useState<Participant[]>([]);
  const { t } = useTranslation();

  const { createSupervisors, loading: createSupervisorLoading } = useCreateSupervisorsById({});
  const { deleteSupervisor, loading: deleteSupervisorLoading } = useDeleteSupervisor({});

  const loading = createSupervisorLoading || deleteSupervisorLoading;

  const handleCancel = () => {
    onCancel();
  };

  const handleOnFinish = () => {
    const addedParticipants = insertNewAssignments();
    const removedParticipants = removeDeletedAssignments();

    onFinish({
      numOfAssignmentsAdded: addedParticipants.length,
      numOfAssignmentsRemoved: removedParticipants.length,
    });
  };

  const getParticipantsToAdd = (
    newParticipantsList: Participant[],
    oldParticipantsList: Participant[],
  ) => {
    const participantsToAdd = newParticipantsList.filter(
      (newParticipant) =>
        !oldParticipantsList.find((oldParticipant) => oldParticipant.id === newParticipant.id) &&
        true,
    );
    return participantsToAdd;
  };

  const getParticipantsToRemove = (
    newParticipantsList: Participant[],
    oldParticipantsList: Participant[],
  ) => {
    const participantsToRemove = oldParticipantsList.filter(
      (oldParticipant) =>
        !newParticipantsList.find((newParticipant) => oldParticipant.id === newParticipant.id) &&
        true,
    );
    return participantsToRemove;
  };

  const insertNewAssignments = () => {
    const participantsToAdd = getParticipantsToAdd(
      assignedParticipantsEdited,
      assignedParticipants,
    );
    const participantIds = participantsToAdd.map((participant) => participant.id);
    if (participantIds.length > 0) {
      createSupervisors({ participantIds, userId, supervisorRoleId, studyId });
    }

    return participantsToAdd;
  };

  const removeDeletedAssignments = () => {
    const participantsToRemove = getParticipantsToRemove(
      assignedParticipantsEdited,
      assignedParticipants,
    );

    participantsToRemove.forEach((participant) => {
      deleteSupervisor({ participantId: participant.id, userId, studyId });
    });

    return participantsToRemove;
  };

  return (
    <Modal
      title={t("study.supervisors.editModal.title", { userName })}
      closable
      destroyOnClose
      open={open}
      confirmLoading={loading}
      onCancel={handleCancel}
      onOk={handleOnFinish}
      okText={t("common.ok")}
      // @ts-ignore
      okButtonProps={{ "data-id": "common.ok" }}
      cancelText={t("common.cancel")}
      // @ts-ignore
      cancelButtonProps={{ "data-id": "common.cancel" }}
    >
      <SupervisorAssignmentList
        value={assignedParticipants}
        userId={userId}
        studyId={studyId}
        onChange={setAssignedParticipantsEdited}
      />
    </Modal>
  );
}
