import { ApolloError } from "@apollo/client";
import { notification } from "antd";
import { useTranslation } from "react-i18next";
import {
  CreateExecutorMutation,
  GetExecutorParticipantsDocument,
  GetExecutorsDocument,
  useCreateExecutorMutation,
} from "../codegen";

interface IUseCreateOrganizationMember {
  // eslint-disable-next-line no-unused-vars
  onCompleted?: (data: CreateExecutorMutation) => void;
  // eslint-disable-next-line no-unused-vars
  onError?: (error: ApolloError) => void;
}

interface ICreateExecutor {
  executorUrl: string;
  executorUrlExternal: string;
  organizationId?: string;
  name: string;
  therapyDesignId: string;
  participantLimit: number;
  expiresAt: string;
}

export function useCreateExecutor({
  onCompleted = () => {},
  onError = () => {},
}: IUseCreateOrganizationMember) {
  const { t } = useTranslation();
  const [mutate, { ...rest }] = useCreateExecutorMutation({
    onCompleted: (data) => {
      notification.success({
        message: t("notifications.createExecutor.success", {
          executorName: data.createExecutor?.name,
        }),
      });
      onCompleted(data);
    },
    onError: (error) => {
      notification.error({
        message: t("notifications.createExecutor.error"),
      });
      onError(error);
    },
    context: {
      headers: {
        "x-hasura-role": "master",
      },
    },
  });

  const createExecutor = ({
    executorUrl,
    executorUrlExternal,
    name,
    therapyDesignId,
    organizationId,
    participantLimit,
    expiresAt,
  }: ICreateExecutor) => {
    console.log(expiresAt);
    mutate({
      variables: {
        executorUrl,
        executorUrlExternal,
        organizationId,
        name,
        therapyDesignId,
        participantLimit,
        expiresAt,
      },
      refetchQueries: [
        {
          query: GetExecutorsDocument,
        },
      ],
    });
  };

  return { createExecutor, ...rest };
}

export default useCreateExecutor;
