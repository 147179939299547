import { CalendarOutlined, CloseOutlined } from "@ant-design/icons";
import { Typography, Space, Button, DatePicker } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { primaryColor } from "../../../../../shared";

const { Text } = Typography;
const { RangePicker } = DatePicker;

export interface IAnalysisRangePickerProps {
  start: string;
  end: string;
  // eslint-disable-next-line no-unused-vars
  onChange: (props: { start: dayjs.Dayjs; end: dayjs.Dayjs }) => Promise<any>;
}

/**
 * Component to define the date range for the visualizations
 */
export function AnalysisRangePicker({ start, end, onChange }: IAnalysisRangePickerProps) {
  const { t } = useTranslation();
  const [collapseByUser, setCollapseByUser] = useState(false);
  const [collapseByScroll, setCollapseByScroll] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [showRefreshButtons, setShowRefreshButtons] = useState(false);
  const [analysisRange, setAnalysisRange] = useState({ start: dayjs(start), end: dayjs(end) });
  const [loading, setLoading] = useState(false);

  const scrollControl = () => {
    if (typeof window !== "undefined") {
      if (window.scrollY > lastScrollY) {
        setCollapseByScroll(true);
      } else {
        setCollapseByScroll(false);
      }
      setLastScrollY(window.scrollY);
    }
  };

  useEffect(() => {
    setAnalysisRange({ start: dayjs(start), end: dayjs(end) });
  }, [start, end]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", scrollControl);

      return () => {
        window.removeEventListener("scroll", scrollControl);
      };
    }
    return () => {};
  }, [lastScrollY]);

  return (
    <div style={{ width: 320, textAlign: "right" }}>
      {collapseByUser || collapseByScroll ? (
        <Button
          type="primary"
          icon={<CalendarOutlined />}
          onClick={() => {
            setCollapseByUser(false);
            setCollapseByScroll(false);
          }}
        >
          {t("analysisRangePicker.title")}
        </Button>
      ) : (
        <Space align="start" size={0}>
          <Button
            type="primary"
            icon={<CloseOutlined />}
            style={{ borderRadius: "6px 0px 0px 6px" }}
            onClick={() => setCollapseByUser(true)}
          />

          <div
            style={{
              padding: 8,
              paddingLeft: 16,
              paddingRight: 16,
              borderRadius: "0px 6px 6px 6px",
              borderColor: primaryColor,
              backgroundColor: "white",
              borderStyle: "solid",
              borderWidth: 1,
              textAlign: "left",
            }}
          >
            <div>
              <Text style={{ fontWeight: 500, color: primaryColor }}>
                {t("analysisRangePicker.title")}
              </Text>
            </div>

            <RangePicker
              style={{
                marginTop: 8,
                marginBottom: 8,
                width: 240,
              }}
              bordered={showRefreshButtons}
              presets={[
                {
                  label: t("analysisRangePicker.oneWeek"),
                  value: [dayjs().subtract(7, "d").startOf("d"), dayjs().endOf("d")],
                },
                {
                  label: t("analysisRangePicker.twoWeeks"),
                  value: [dayjs().subtract(14, "d").startOf("d"), dayjs().endOf("d")],
                },
                {
                  label: t("analysisRangePicker.threeWeeks"),
                  value: [dayjs().subtract(21, "d").startOf("d"), dayjs().endOf("d")],
                },
                {
                  label: t("analysisRangePicker.fourWeeks"),
                  value: [dayjs().subtract(28, "d").startOf("d"), dayjs().endOf("d")],
                },
              ]}
              disabledDate={(currentDate) =>
                dayjs().endOf("d").isBefore(currentDate.startOf("d"), "d")
              }
              allowEmpty={[false, false]}
              allowClear={false}
              value={[analysisRange.start as any, analysisRange.end as any]}
              onChange={(values) => {
                if (values != null)
                  setAnalysisRange((prev) => ({
                    start: values[0] ? values[0] : prev.start,
                    end: values[1] ? values[1] : prev.end,
                  }));
              }}
              onOpenChange={(open) => {
                if (!open) {
                  setShowRefreshButtons(true);
                }
              }}
            />
            {showRefreshButtons ? (
              <Space
                align="end"
                style={{ width: "100%", display: "flex", justifyContent: "space-between" }}
              >
                <Button
                  onClick={() => {
                    setShowRefreshButtons(false);
                    setAnalysisRange({ start: dayjs(start), end: dayjs(end) });
                  }}
                >
                  {t("common.cancel")}
                </Button>
                <Button
                  loading={loading}
                  type="primary"
                  onClick={async () => {
                    setLoading(true);
                    await onChange(analysisRange);
                    setLoading(false);
                    setShowRefreshButtons(false);
                  }}
                >
                  {t("common.apply")}
                </Button>
              </Space>
            ) : null}
          </div>
        </Space>
      )}
    </div>
  );
}

export default AnalysisRangePicker;
