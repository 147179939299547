import { Form, Radio, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

import { RadioButtonRoleItem } from "../../../../shared";
import { useStudyRoles } from "../../../../services";

const { Text } = Typography;

interface IStudyRolesFormItemProps {
  studyId: string;
}

export function StudyRolesFormItem({ studyId }: IStudyRolesFormItemProps) {
  const { studyRoles } = useStudyRoles(studyId);
  const { t } = useTranslation();
  const participantManagerRole = studyRoles?.find((role) => role.name === "PARTICIPANTMANAGER");
  const adminRole = studyRoles?.find((role) => role.name === "ADMIN");
  const ownerRole = studyRoles?.find((role) => role.name === "OWNER");

  if (participantManagerRole && adminRole && ownerRole) {
    return (
      <Form.Item
        name="roleId"
        rules={[
          {
            required: true,
            message: t("studyRolesFormItem.missingRole"),
          },
        ]}
      >
        <Radio.Group style={{ width: "100%" }}>
          <RadioButtonRoleItem
            roleId={participantManagerRole?.id}
            roleName="PARTICIPANTMANAGER"
            description={
              <>
                <Text>{t("studyRolesFormItem.participantManager.description.1")}</Text>
                <ul>
                  <li>
                    <b>{t("studyRolesFormItem.participantManager.description.2")}</b>
                  </li>
                  <li>
                    <b>{t("studyRolesFormItem.participantManager.description.3")}</b>
                  </li>
                  <li>
                    <b>{t("studyRolesFormItem.participantManager.description.4")}</b>
                  </li>
                </ul>
              </>
            }
            type="info"
          />
          <RadioButtonRoleItem
            roleId={adminRole.id}
            roleName="ADMIN"
            description={
              <>
                <Text>{t("studyRolesFormItem.admin.description.1")}</Text>
                <ul>
                  <li>
                    <b>{t("studyRolesFormItem.admin.description.2")}</b>
                  </li>
                  <li>
                    <b>{t("studyRolesFormItem.admin.description.3")}</b>
                  </li>
                </ul>
              </>
            }
            type="warning"
          />

          <RadioButtonRoleItem
            roleId={ownerRole.id}
            roleName="OWNER"
            description={
              <>
                <Text>{t("studyRolesFormItem.owner.description.1")}</Text>
                <ul>
                  <li>
                    <b>{t("studyRolesFormItem.owner.description.2")}</b>
                  </li>
                  <li>
                    <b>{t("studyRolesFormItem.owner.description.3")}</b>
                  </li>
                </ul>
              </>
            }
            type="error"
          />
        </Radio.Group>
      </Form.Item>
    );
  }

  return null;
}

export default StudyRolesFormItem;
