import { ApolloError } from "@apollo/client";
import { notification } from "antd";
import { useTranslation } from "react-i18next";

import {
  CreateStudyMemberMutation,
  GetStudyMembersDocument,
  useCreateStudyMemberMutation,
} from "../codegen";

interface IUseCreateStudyMemberProps {
  // eslint-disable-next-line no-unused-vars
  onCompleted?: (data: CreateStudyMemberMutation) => void;
  // eslint-disable-next-line no-unused-vars
  onError?: (error: ApolloError) => void;
}

interface ICreateStudyMember {
  studyId: string;
  email: string;
  roleId: string;
}

export function useCreateStudyMember({
  onCompleted = () => {},
  onError = () => {},
}: IUseCreateStudyMemberProps) {
  const { t } = useTranslation();
  const [mutate, { loading }] = useCreateStudyMemberMutation({
    onCompleted: (data) => {
      notification.success({
        message: t("notifications.createStudyMember.success", {
          userName: data.createStudyMemberAction?.email,
          userRole: data.createStudyMemberAction?.studyMember?.studyRole.name,
        }),
      });
      onCompleted(data);
    },
    onError: (error) => {
      notification.error({
        message: t("notifications.createStudyMember.error"),
      });
      onError(error);
    },
  });

  const createStudyMember = (props: ICreateStudyMember) => {
    mutate({
      variables: {
        studyId: props.studyId,
        email: props.email,
        roleId: props.roleId,
      },
      refetchQueries: [{ query: GetStudyMembersDocument, variables: { studyId: props.studyId } }],
    });
  };

  return { createStudyMember, loading };
}

export default useCreateStudyMember;
