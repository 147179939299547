import React from "react";
import { Form, Button, Input, Collapse, Typography, Alert, App } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TextArea from "antd/lib/input/TextArea";
import {
  ActionEnum,
  useDeleteOrganization,
  usePermission,
  useUpdateOrganization,
} from "../../services";
import { Loader, Paths, useCachedOrganization, useOrganizationId } from "../../shared";

const { Panel } = Collapse;
const { Title, Text, Paragraph } = Typography;

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

const buttonLayout = {
  wrapperCol: {
    offset: 21,
    span: 3,
  },
};

export function OrganizationSettings() {
  const { organizationId } = useOrganizationId();
  const { organization } = useCachedOrganization();
  const { updateOrganization, loading } = useUpdateOrganization({});
  const { t } = useTranslation();
  const { modal } = App.useApp();
  const navigate = useNavigate();
  const { permitted: allowedToDeleteOrganization, loading: permissionLoading } = usePermission({
    action: ActionEnum.ORGANIZATION_DELETE_ORGANIZATION,
    organizationId,
  });
  const { deleteOrganization } = useDeleteOrganization({
    onCompleted: (data) => {
      if (data.deleteOrganizationAction.success) {
        navigate(Paths.HOME);
      }
    },
  });

  const handleFinish = (values: any) => {
    updateOrganization({
      organizationId,
      address: values.address,
      city: values.city,
      country: values.country,
      name: values.name,
      zip: values.zip,
      department: values.department,
      description: values.description,
    });
  };

  const handleDeleteOrganization = () => {
    modal.confirm({
      title: t("organization.setting.confirmDelete.title", { organization: organization.name }),
      icon: <ExclamationCircleOutlined />,
      content: t("organization.setting.confirmDelete.description"),
      okText: t("common.yes"),
      okType: "danger",
      cancelText: t("common.no"),
      onOk() {
        deleteOrganization(organizationId);
      },
    });
  };

  if (permissionLoading) {
    return <Loader />;
  }

  return (
    <Collapse
      expandIconPosition="right"
      bordered={false}
      style={{ backgroundColor: "white" }}
      defaultActiveKey={["1"]}
    >
      <Panel
        header={
          <>
            <Title level={4} style={{ marginTop: 0 }}>
              {t("organization.setting.information.title")}
            </Title>
            <Text>{t("organization.setting.information.description")}</Text>
          </>
        }
        key="1"
      >
        <Form
          {...layout}
          style={{ marginTop: "20px" }}
          name="basic"
          initialValues={{
            name: organization.name,
            department: organization.department,
            description: organization.description,
            address: organization.address,
            zip: organization.zip,
            city: organization.city,
            country: organization.country,
          }}
          onFinish={handleFinish}
        >
          <Form.Item
            name="name"
            label={t("organization.name")}
            style={{ display: "inline-block", width: "calc(50% - 8px)" }}
            rules={[{ required: true, message: "Please input an organization name!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="department"
            label={t("organization.department")}
            style={{ display: "inline-block", width: "calc(50% - 8px)", float: "right" }}
            rules={[{ required: false }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="description"
            label={t("organization.description")}
            rules={[{ required: false }]}
          >
            <TextArea rows={3} />
          </Form.Item>

          <Form.Item
            name="address"
            label={t("organization.address")}
            rules={[{ required: true, message: t("organization.errorMessages.missingAddress") }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="zip"
            label={t("organization.zip")}
            rules={[{ required: true, message: t("organization.errorMessages.missingPostalCode") }]}
            style={{ display: "inline-block", width: "calc(15% - 8px)" }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="city"
            label={t("organization.city")}
            rules={[{ required: true, message: t("organization.errorMessages.missingCity") }]}
            style={{ display: "inline-block", width: "calc(85% - 8px)", float: "right" }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="country"
            label={t("organization.country")}
            rules={[{ required: true, message: t("organization.errorMessages.missingCountry") }]}
          >
            <Input />
          </Form.Item>
          <Form.Item {...buttonLayout}>
            <Button style={{ float: "right" }} type="primary" htmlType="submit" loading={loading}>
              {t("organization.setting.information.saveButton")}
            </Button>
          </Form.Item>
        </Form>
      </Panel>
      <Panel
        header={
          <>
            <Title level={4} style={{ marginTop: 0 }}>
              {t("organization.setting.advanced.title")}
            </Title>
            <Text>{t("organization.setting.advanced.shortDescription")}</Text>
          </>
        }
        key="3"
      >
        <Alert
          description={
            <>
              <Paragraph>{t("organization.setting.advanced.deleteDescription.1")}</Paragraph>
              <Paragraph>
                <ul>
                  {!allowedToDeleteOrganization && (
                    <li key="1">
                      <Text strong>{t("organization.setting.advanced.deleteDescription.2")}</Text>
                    </li>
                  )}
                  {organization.studies.length !== 0 && (
                    <li key="2">
                      <Text strong>
                        {t("organization.setting.advanced.deleteDescription.3", {
                          studyCount: organization.studies.length,
                        })}
                      </Text>
                      <ul>
                        {organization.studies.map((study) => (
                          <li key={study.id}>{study.name}</li>
                        ))}
                      </ul>
                    </li>
                  )}
                </ul>
              </Paragraph>
            </>
          }
          type="error"
          action={
            <Button
              disabled={!allowedToDeleteOrganization || organization.studies.length !== 0}
              danger
              onClick={handleDeleteOrganization}
            >
              {t("common.delete")}
            </Button>
          }
        />
      </Panel>
    </Collapse>
  );
}

export default OrganizationSettings;
