import { Affix, Button, Form, App, notification, Space, Typography, Modal } from "antd";
import { Store } from "antd/lib/form/interface";
import ReactRouterPrompt from "react-router-prompt";
import React, { useState, useEffect, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import useTherapyDesign from "../../../../services/graphql/hooks/useTherapyDesign";
import { PageHeader, ErrorBoundary, useCachedStudy } from "../../../../shared";
import { filterForEditedTherapyValues } from "../../../../utils";
import { ChangedValuesTable, IChangedValue, VariableType } from "./formItems";
import { GroupVariableAssignment } from "../../../../services";

const { Paragraph, Text } = Typography;

interface IGroupVariableInformation extends GroupVariableAssignment {
  groupName: string;
}

interface ITherapyConfigFormProps {
  title: string;
  description: string;
  editable: boolean;
  additionalHeaderContent?: ReactNode;
  // eslint-disable-next-line no-unused-vars
  onFinish?: (values: any) => void;
  initialValues?: Store;
  affixOffsetTop?: number;
  children?: React.ReactNode;
  forceValueRefresh?: boolean;
  groupVariableInformation?: IGroupVariableInformation[];
}

export function TherapyConfigForm({
  title,
  description,
  editable,
  affixOffsetTop,
  additionalHeaderContent = null,
  children,
  initialValues,
  onFinish = () => {},
  forceValueRefresh = false,
  groupVariableInformation,
}: ITherapyConfigFormProps) {
  const [form] = Form.useForm();
  const { modal } = App.useApp();
  const [isInEditMode, setIsInEditMode] = useState(false);
  const { t } = useTranslation();
  const { study } = useCachedStudy();
  const { getTherapyVariable } = useTherapyDesign(study.id);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isInEditMode) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = null;
    }
  });

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [forceValueRefresh]);

  /**
   * Handler called when the user clicks on the edit button. If the form is in
   * edit mode, the form will be validated and the onFinish handler will be
   * called. If the form is not in edit mode, the form will be set to edit mode.
   */
  const handleEditClick = () => {
    if (isInEditMode) {
      form
        .validateFields()
        .then(() => {
          setLoading(true);
          handleOnFormFinish(form.getFieldsValue());
        })
        .catch(() => {
          notification.error({
            message: t("study.therapyConfig.invalidConfiguration.title"),
            description: t("study.therapyConfig.invalidConfiguration.description"),
          });
        });
    } else {
      setIsInEditMode(true);
    }
  };

  /**
   * Handler called when the user clicks on the cancel button. The form will be
   * reset and the form will be set to not in edit mode.
   */
  const handleCancelClick = () => {
    setIsInEditMode(false);
    form.resetFields();
  };

  /**
   * Shows a modal with the changed values. The user can confirm the changes or
   * cancel them.
   */
  const showModalWithChangedValues = ({
    changedValues,
    useGroupConfigMode,
    handleOk,
  }: {
    changedValues: IChangedValue[];
    useGroupConfigMode: boolean;
    handleOk: () => void;
  }) => {
    modal.confirm({
      title: t("study.therapyConfig.confirmChanges.title"),
      style: { minWidth: useGroupConfigMode ? 800 : 600 },
      content:
        changedValues.length > 0 ? (
          <div style={{ maxHeight: "60vH", overflowY: "auto" }}>
            <Paragraph>{t("study.therapyConfig.confirmChanges.description")}</Paragraph>
            <ChangedValuesTable
              useGroupConfigMode={useGroupConfigMode}
              changedValues={changedValues}
            />
          </div>
        ) : (
          <Text>{t("study.therapyConfig.confirmChanges.descriptionNoChanges")}</Text>
        ),
      okText: t("common.ok"),
      // @ts-ignore
      okButtonProps: { "data-id": "common.ok" },
      cancelText: t("common.cancel"),
      onOk: () => handleOk(),
    });
  };

  /**
   * Handler called when the user has edited the participant configuration and
   * wants to save the changes.
   */
  const handleParticipantConfigChange = (
    editedValues: {
      key: string;
      currentValue: any;
      oldValue: any;
    }[],
    onOk: () => void,
  ) => {
    const changedValues = editedValues.map(({ key, currentValue, oldValue }) => {
      const variable = getTherapyVariable(key);
      return {
        key: variable?.displayName || key,
        newValue: currentValue.value,
        oldValue: oldValue.value,
        validatorProps: variable?.validatorProps,
        type: variable?.type || VariableType.UNKNOWN,
      };
    });

    showModalWithChangedValues({
      changedValues,
      useGroupConfigMode: false,
      handleOk: () => onOk(),
    });
  };

  /**
   * Handler called when the user has edited the group configuration and wants
   * to save the changes.
   */
  const handleParticipantsGroupConfigChange = (
    editedValues: {
      key: string;
      currentValue: any;
      oldValue: any;
    }[],
    groupVariableAssignments: IGroupVariableInformation[],
    onOk: () => void,
  ) => {
    const editedValuesWithGroupName = editedValues
      .map(({ key, currentValue, oldValue }) => {
        const assignment = groupVariableAssignments.find(({ id }) => id === key);
        const variable = getTherapyVariable(assignment?.variableId || "ERROR");

        return {
          key: variable?.displayName || key,
          newValue: currentValue.value,
          oldValue: oldValue.value,
          validatorProps: variable?.validatorProps,
          type: variable?.type || VariableType.UNKNOWN,
          groupName: assignment?.groupName || "ERROR",
        };
      })
      .sort((a, b) => a.groupName.localeCompare(b.groupName));

    showModalWithChangedValues({
      changedValues: editedValuesWithGroupName,
      useGroupConfigMode: true,
      handleOk: () => onOk(),
    });
  };

  /**
   * Handler called when the user confirms the changes
   */
  const handleSave = ({ values, shouldSave }: { values: any; shouldSave: boolean }) => {
    if (shouldSave) {
      onFinish(values);
    }
    setIsInEditMode(false);
  };

  /**
   * Handler called when the user has finished editing the form.
   */
  const handleOnFormFinish = (values: any) => {
    setLoading(false);
    const editedValues = filterForEditedTherapyValues({ editedValues: values, initialValues });
    if (groupVariableInformation) {
      handleParticipantsGroupConfigChange(editedValues, groupVariableInformation, () =>
        handleSave({ values, shouldSave: editedValues.length > 0 }),
      );
    } else {
      handleParticipantConfigChange(editedValues, () =>
        handleSave({ values, shouldSave: editedValues.length > 0 }),
      );
    }
  };

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      const newProps = {
        editing: isInEditMode,
      };
      return React.cloneElement(child, newProps);
    }
    return child;
  });

  return (
    <ErrorBoundary>
      <ReactRouterPrompt
        when={(args) =>
          isInEditMode && args.currentLocation.pathname !== args.nextLocation.pathname
        }
      >
        {({ isActive, onConfirm, onCancel }) => (
          <Modal
            cancelText={t("common.cancel")}
            closable={false}
            okText={t("common.ok")}
            okType="danger"
            onCancel={onCancel}
            onOk={onConfirm}
            title={t("study.therapyConfig.leavePageWhenEditingTitle")}
            open={isActive}
          >
            <p>{t("study.therapyConfig.leavePageWhenEditing")}</p>
          </Modal>
        )}
      </ReactRouterPrompt>
      <PageHeader
        style={{ width: "100%", padding: 0 }}
        title={title}
        description={description}
        extra={[
          <Affix key="editSave" offsetTop={affixOffsetTop} style={{ minWidth: 200 }}>
            <Space style={{ float: "right" }}>
              <Button
                key="cancelButton"
                style={{ display: isInEditMode ? "inline" : "none" }}
                onClick={handleCancelClick}
              >
                {t("common.cancel")}
              </Button>
              <Button
                data-id="editButton"
                disabled={!editable}
                key="editButton"
                loading={loading}
                onClick={handleEditClick}
                type="primary"
              >
                {isInEditMode ? t("common.save") : t("common.edit")}
              </Button>
            </Space>
          </Affix>,
        ]}
      >
        {additionalHeaderContent}
      </PageHeader>

      <Form form={form} initialValues={initialValues} scrollToFirstError>
        {childrenWithProps}
      </Form>
    </ErrorBoundary>
  );
}

export default TherapyConfigForm;
