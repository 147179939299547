import { Form, Radio, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useOrganizationRoles } from "../../../services";

import { RadioButtonRoleItem } from "../../../shared";

const { Text } = Typography;

interface IOrganizationRolesFormItemProps {
  organizationId: string;
}

export function OrganizationRolesFormItem({ organizationId }: IOrganizationRolesFormItemProps) {
  const { organizationRoles } = useOrganizationRoles(organizationId);
  const memberRole = organizationRoles?.find((role) => role.name === "MEMBER");
  const ownerRole = organizationRoles?.find((role) => role.name === "OWNER");
  const { t } = useTranslation();

  if (ownerRole && memberRole) {
    return (
      <Form.Item
        name="roleId"
        rules={[
          {
            required: true,
            message: t("organizationRolesFormItem.missingRole"),
          },
        ]}
      >
        <Radio.Group style={{ width: "100%" }}>
          <RadioButtonRoleItem
            roleId={memberRole.id}
            roleName="MEMBER"
            description={
              <>
                <Text>{t("organizationRolesFormItem.memberRole.description.1")}</Text>
                <ul>
                  <li>
                    <b>{t("organizationRolesFormItem.memberRole.description.2")}</b>
                  </li>
                </ul>
              </>
            }
            type="info"
          />

          <RadioButtonRoleItem
            roleId={ownerRole.id}
            roleName="OWNER"
            description={
              <>
                <Text>{t("organizationRolesFormItem.ownerRole.description.1")}</Text>
                <ul>
                  <li>
                    <b>{t("organizationRolesFormItem.ownerRole.description.2")}</b>
                  </li>
                  <li>
                    <b>{t("organizationRolesFormItem.ownerRole.description.3")}</b>
                  </li>
                  <li>
                    <b>{t("organizationRolesFormItem.ownerRole.description.4")}</b>
                  </li>
                </ul>
              </>
            }
            type="warning"
          />
        </Radio.Group>
      </Form.Item>
    );
  }

  return null;
}

export default OrganizationRolesFormItem;
