import { ApolloError } from "@apollo/client";
import { notification } from "antd";
import { useTranslation } from "react-i18next";
import { useUserId } from "../../../shared";
import {
  DeleteAccountMutation,
  GetUserDocument,
  useCanDeleteAccountQuery,
  useDeleteAccountMutation,
} from "../codegen";

interface IUseDeleteParticipantProps {
  // eslint-disable-next-line no-unused-vars
  onCompleted?: (data: DeleteAccountMutation) => void;
  // eslint-disable-next-line no-unused-vars
  onError?: (error: ApolloError) => void;
}

export function useDeleteAccount({
  onCompleted = () => {},
  onError = () => {},
}: IUseDeleteParticipantProps) {
  const { t } = useTranslation();
  const { userId } = useUserId();

  const { data: canDeleteCheck, loading: canDeleteLoading } = useCanDeleteAccountQuery({
    variables: { userId },
    fetchPolicy: "network-only",
  });
  const [mutate, { loading: deleteLoading }] = useDeleteAccountMutation({
    onCompleted: (data) => {
      if (data.deleteAccountAction?.success) {
        notification.success({
          message: t("notifications.deleteAccount.success"),
        });
      } else {
        notification.warning({
          message: t("notifications.deleteAccount.error"),
        });
      }
      onCompleted(data);
    },
    onError: (error) => {
      notification.error({
        message: t("notifications.deleteAccount.error"),
      });
      onError(error);
    },
  });

  const deleteAccount = () => {
    mutate({
      variables: {
        userId,
      },
      refetchQueries: [{ query: GetUserDocument, variables: { userId } }],
    });
  };

  return {
    deleteAccount,
    deleteLoading,
    canDelete: canDeleteCheck?.canDeleteAccountAction,
    canDeleteLoading,
  };
}

export default useDeleteAccount;
