import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Space, App } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { IUpdateApiAccount, useDeleteApiAccount } from "../../../../services";
import UpdateApiAccountModal from "./UpdateApiAccountModal";

interface IApiAccountsActionButtonsProps extends IUpdateApiAccount {
  style?: React.CSSProperties;
  studyId: string;
  editable?: boolean;
}

/**
 * Component for the API Account table. Renders an edit and delete icon button
 * enabling editing / deleting an API Account
 */
export function ApiAccountsActionButtons({
  style,
  editable = false,
  id,
  expiresAt,
  studyId,
  serviceName,
}: IApiAccountsActionButtonsProps) {
  const { t } = useTranslation();
  const { modal } = App.useApp();
  const { deleteApiAccount } = useDeleteApiAccount({});
  const [showUpdateApiAccountModal, setShowUpdateApiAccountModal] = useState(false);

  return (
    <>
      <Space style={style}>
        <Button
          disabled={!editable}
          icon={<EditOutlined />}
          onClick={() => setShowUpdateApiAccountModal(true)}
        />
        <Button
          disabled={!editable}
          type="primary"
          icon={<DeleteOutlined />}
          onClick={() =>
            modal.confirm({
              title: t("study.apiAccountTable.delete.confirm.title", {
                serviceName,
              }),
              icon: <ExclamationCircleOutlined />,
              content: t("study.apiAccountTable.delete.confirm.description", {
                serviceName,
              }),
              okText: t("common.yes"),
              okType: "danger",
              cancelText: t("common.no"),
              onOk() {
                deleteApiAccount({ accountId: id, studyId });
              },
            })
          }
        />
      </Space>
      <UpdateApiAccountModal
        id={id}
        expiresAt={expiresAt}
        onCancel={() => setShowUpdateApiAccountModal(false)}
        serviceName={serviceName}
        open={showUpdateApiAccountModal}
      />
    </>
  );
}

export default ApiAccountsActionButtons;
