import { ApolloError } from "@apollo/client";
import { notification } from "antd";
import { useTranslation } from "react-i18next";
import { DeleteOrganizationMemberMutation, useDeleteOrganizationMemberMutation } from "../codegen";

interface IDeleteOrganizationMemberProps {
  // eslint-disable-next-line no-unused-vars
  onCompleted?: (data: DeleteOrganizationMemberMutation) => void;
  // eslint-disable-next-line no-unused-vars
  onError?: (error: ApolloError) => void;
}

interface IDeleteOrganizationMember {
  organizationId: string;
  userId: string;
}

export function useDeleteOrganizationMember({
  onCompleted = () => {},
  onError = () => {},
}: IDeleteOrganizationMemberProps) {
  const { t } = useTranslation();
  const [mutate, { loading }] = useDeleteOrganizationMemberMutation({
    onCompleted: (data) => {
      notification.success({
        message: t("notifications.deleteOrganizationMember.success"),
      });
      onCompleted(data);
    },
    onError: (error) => {
      notification.error({
        message: t("notifications.deleteOrganizationMember.error"),
      });
      onError(error);
    },
  });

  const deleteOrganizationMember = (props: IDeleteOrganizationMember) => {
    mutate({
      variables: {
        organizationId: props.organizationId,
        userId: props.userId,
      },
    });
  };

  return { deleteOrganizationMember, loading };
}

export default useDeleteOrganizationMember;
