import { useGetOrganizationsQuery } from "../codegen";

export function useOrganizations(
  { queryAsMaster }: { queryAsMaster: boolean } = { queryAsMaster: false },
) {
  const { data, ...rest } = useGetOrganizationsQuery({
    context: {
      headers: {
        "x-hasura-role": queryAsMaster ? "master" : "user",
      },
    },
  });

  return { organizations: data?.organizations, ...rest };
}

export default useOrganizations;
