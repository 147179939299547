import { ExclamationCircleOutlined } from "@ant-design/icons";
import { App, Button, Skeleton, Tooltip } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useDeleteSupervisor, useExecutorParticipant, useUserLazy } from "../../../services";
import {
  BaseParticipantListItem,
  IParticipantListItemProps,
  primaryColor,
  Paths,
  StudyNameTag,
} from "../../../shared";
import { ParticipantStateTag } from "../../study/participant/components/state";

interface ISupervisedParticipantProps extends IParticipantListItemProps {
  userId: string;
}

export function SupervisedParticipantsListItem(props: ISupervisedParticipantProps) {
  const { studyId, id, studyName, pseudonym, userId } = props;
  const { getUser } = useUserLazy();
  const { t } = useTranslation();
  const { modal, notification } = App.useApp();
  const { deleteSupervisor, loading: deleteLoading } = useDeleteSupervisor({
    onCompleted: () => {
      getUser(userId);
      notification.success({
        message: t("supervisedParticipants.notification.successRemove", {
          pseudonym,
        }),
      });
    },
    onError: () => {
      notification.error({ message: t("supervisedParticipants.notification.errorRemove") });
    },
  });
  const { participant } = useExecutorParticipant(id);

  const showDeleteConfirm = () => {
    modal.confirm({
      title: t("supervisedParticipants.listItem.confirmDelete.title", { pseudonym }),
      icon: <ExclamationCircleOutlined />,
      content: t("supervisedParticipants.listItem.confirmDelete.description", { pseudonym }),
      okText: t("common.yes"),
      okType: "danger",
      cancelText: t("common.no"),
      onOk() {
        deleteSupervisor({ participantId: id, studyId, userId });
      },
    });
  };

  return (
    <BaseParticipantListItem
      {...props}
      extra={[
        <StudyNameTag key="nameTag" name={studyName} />,
        participant ? (
          <ParticipantStateTag
            key="stateTag"
            coupleUrl={participant.coupleUrl}
            therapyEnd={participant.therapyEnd}
            therapyStart={participant.therapyStart}
          />
        ) : (
          <Skeleton.Button key="stateTagSkeleton" size="small" />
        ),
        <Tooltip
          key="removeButton"
          title={t("supervisedParticipants.listItem.removeTooltip", { pseudonym })}
          color={primaryColor}
        >
          <Button
            loading={deleteLoading}
            style={{ marginLeft: 10 }}
            onClick={() => showDeleteConfirm()}
          >
            {t("supervisedParticipants.listItem.removeButton")}
          </Button>
        </Tooltip>,
        <Link
          key="detailsButton"
          to={Paths.getStudyPaths(studyId).getParticipantsPaths(id).DASHBOARD}
        >
          <Button style={{ marginLeft: 10 }} type="primary">
            {t("supervisedParticipants.listItem.detailsButton")}
          </Button>
        </Link>,
      ]}
    />
  );
}

export default SupervisedParticipantsListItem;
