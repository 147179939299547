import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Divider, List, Button, Alert, Space } from "antd";
import {
  usePermission,
  ActionEnum,
  useStudyMembers,
  GetStudyMembersQuery,
} from "../../../services";
import {
  LoadingHandler,
  PageHeader,
  SearchField,
  StudyRoleEnum,
  StudyRoleFilterSingle,
} from "../../../shared";

import { CreateStudyMemberModal, StudyMemberListItem } from "./components";
import useSupervisorRoles from "../../../services/graphql/hooks/useSupervisorRoles";
import useStudyId from "../../../shared/hooks/useStudyId";

type StudyMember = GetStudyMembersQuery["getStudyMembersAction"][number];

export function StudyMembers() {
  const { t } = useTranslation();
  const { studyId } = useStudyId();
  const [searchResult, setSearchResult] = useState({
    searchText: "",
    items: [] as StudyMember[],
  });
  const [isAddMemberModalVisible, setIsAddMemberModalVisible] = useState(false);
  const [studyRoleFilter, setStudyRoleFilter] = useState<StudyRoleEnum>(StudyRoleEnum.ALL);
  const { studyMembers, loading: getStudyMembersDataLoading, refetch } = useStudyMembers(studyId);
  useSupervisorRoles(studyId);

  const { permitted: canAddMember, loading: canAddMemberLoading } = usePermission({
    action: ActionEnum.STUDY_CREATE_MEMBER,
    studyId,
  });

  const filter = (membersToFilter: StudyMember[]) => {
    const filterForRole =
      studyRoleFilter === StudyRoleEnum.ALL
        ? membersToFilter
        : membersToFilter.filter(
            (member) => member.studyMember?.studyRole.name === studyRoleFilter,
          );
    const filterForSearchResult = filterForRole.filter((member) =>
      searchResult.items.find((item) => item.id === member.id),
    );

    return filterForSearchResult;
  };

  return (
    <LoadingHandler loading={canAddMemberLoading || (getStudyMembersDataLoading && !studyMembers)}>
      <PageHeader
        title={t("study.personnel.members.title")}
        description={t("study.personnel.members.description")}
        hideDivider
        style={{ marginBottom: 24, padding: 0 }}
      >
        {canAddMember && (
          <Alert
            style={{ marginTop: 12 }}
            message={t("study.personnel.members.warning.title")}
            description={t("study.personnel.members.warning.description.1")}
            type="warning"
            showIcon
            closable
          />
        )}
      </PageHeader>

      <Space>
        <SearchField
          allItems={studyMembers?.map((member) => ({
            item: member,
            searchFields: [
              `${member.userFirstName} ${member.userLastName}`,
              `${member.userLastName} ${member.userFirstName}`,
              member.userEmail,
            ],
          }))}
          placeholderText={t("study.personnel.members.searchPlaceholder")}
          onSearch={setSearchResult}
          style={{ width: 240 }}
        />
        <StudyRoleFilterSingle
          initialValue={studyRoleFilter}
          onChange={setStudyRoleFilter}
          style={{ width: 176 }}
        />
      </Space>

      <Button
        style={{ float: "right" }}
        type="primary"
        disabled={!canAddMember}
        onClick={() => setIsAddMemberModalVisible(true)}
      >
        {t("study.personnel.members.createButton")}
      </Button>

      <Divider style={{ marginBottom: 0 }} />
      <List<StudyMember>
        itemLayout="horizontal"
        size="small"
        pagination={{
          hideOnSinglePage: true,
          pageSize: 10,
        }}
        dataSource={filter(studyMembers || [])}
        renderItem={(item) => (
          <StudyMemberListItem
            searchStringToHighlight={searchResult.searchText}
            avatar={item.userAvatar}
            email={item.userEmail}
            firstName={item.userFirstName}
            lastName={item.userLastName}
            memberId={item.id}
            roleId={item.studyMember?.studyRole.id}
            roleName={item.studyMember?.studyRole.name || ""}
            memberUserId={item.studyMember?.userId}
            studyId={studyId}
            onRemoveRole={() => refetch()}
          />
        )}
      />

      <CreateStudyMemberModal
        studyId={studyId}
        open={isAddMemberModalVisible}
        onCancel={() => setIsAddMemberModalVisible(false)}
      />
    </LoadingHandler>
  );
}

export default StudyMembers;
