/* eslint-disable react/require-default-props */
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  ITherapyElement,
  ParticipantVariableConfigsToUpdate,
  useUpdateParticipantVariableConfig,
} from "../../../../services";

import { useUserId, DummyAnchor, headerHeight } from "../../../../shared";
import { sortTherapyElementsByTitle, filterForEditedTherapyValues } from "../../../../utils";

import {
  ITherapyVariableValue,
  TherapyElementContainer,
  TherapyElementsParticipantConfig,
} from "../../components";
import { TherapyConfigForm } from "../../components/therapyConfigTable/TherapyConfigForm";

interface IParticipantTherapyConfigContentProps {
  participantId: string;
  hasPermissionToEdit: boolean;
  elementsWithEditableVariables?: ITherapyElement[];
  therapyConfig?: ITherapyVariableValue[];
}

export function ParticipantTherapyConfigContent({
  participantId,
  hasPermissionToEdit,
  elementsWithEditableVariables = [],
  therapyConfig = [],
}: IParticipantTherapyConfigContentProps) {
  const { userId } = useUserId();
  const { t } = useTranslation();
  const [forceRefresh, setForceRefresh] = useState(false);
  const { updateParticipantVariableConfig } = useUpdateParticipantVariableConfig({
    participantId,
    onError: () => setForceRefresh((prev: boolean) => !prev),
  });

  const createChildElements = useMemo(
    () =>
      sortTherapyElementsByTitle(elementsWithEditableVariables).map((item) => {
        if (typeof item === "string") {
          return <DummyAnchor key={item} id={item} />;
        }
        if (item && item.therapyVariables.length > 0) {
          return (
            <TherapyElementContainer
              id={item.id}
              key={item.id}
              title={item.title}
              description={item.description}
            >
              <TherapyElementsParticipantConfig
                therapyVariables={item.therapyVariables}
                therapyVariablesValues={therapyConfig}
                participantId={participantId}
              />
            </TherapyElementContainer>
          );
        }

        return null;
      }),
    [elementsWithEditableVariables, therapyConfig],
  );

  const handleOnFormFinish = (editedValues: any, initialValues: any) => {
    const filteredValues = filterForEditedTherapyValues({ editedValues, initialValues });

    const preparedConfigArray: ParticipantVariableConfigsToUpdate[] = filteredValues.map(
      ({ key, currentValue }) => ({
        therapyVariableId: key,
        participantId,
        variableValue: currentValue,
        editorId: userId,
      }),
    );
    updateParticipantVariableConfig(preparedConfigArray);
  };

  const initialValuesArray = therapyConfig.map((item) => ({
    key: item.therapyVariableId,
    value: item.therapyVariableValue,
  }));

  const initialValueObject = Object.fromEntries(initialValuesArray.map((e) => [e.key, e.value]));

  return (
    <div style={{ maxWidth: 1000, margin: "auto" }}>
      <TherapyConfigForm
        title={t("participant.therapyConfig.title")}
        description={t("participant.therapyConfig.description")}
        editable={hasPermissionToEdit}
        affixOffsetTop={headerHeight + 16}
        initialValues={initialValueObject}
        onFinish={(values) => handleOnFormFinish(values, initialValueObject)}
        forceValueRefresh={forceRefresh}
      >
        {createChildElements}
      </TherapyConfigForm>
    </div>
  );
}

export default ParticipantTherapyConfigContent;
