import { HomeOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Paths } from "../routes";

export function BackHomeButton() {
  const { t } = useTranslation();
  return (
    <Link to={Paths.HOME}>
      <Button type="primary" icon={<HomeOutlined />}>
        {t("common.results.backHomeButton")}
      </Button>
    </Link>
  );
}

export default BackHomeButton;
