/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */

import React from "react";
import { Vega } from "react-vega";
import { VegaProps } from "react-vega/lib/Vega";
import styled from "styled-components";
import NoDataOverlayContainer from "./NoDataOverlayContainer";

// eslint-disable-next-line react/jsx-props-no-spreading
export const StyledVegaContainer = styled((props: any) => (
  <div style={{ width: "80%" }} {...props} />
))`
  && .vega-embed {
    width: 100%;
  }
`;

interface IVegaContainerProps extends VegaProps {
  showNoDataOverlay?: Boolean;
}

function VegaContainer({ showNoDataOverlay = false, ...rest }: IVegaContainerProps) {
  return (
    <NoDataOverlayContainer showNoDataOverlay={showNoDataOverlay}>
      <StyledVegaContainer>
        <Vega style={{ padding: 0 }} actions={false} {...rest} />
      </StyledVegaContainer>
    </NoDataOverlayContainer>
  );
}

export default VegaContainer;
