import { useParams } from "react-router-dom";

export function useStudyId() {
  const { studyId } = useParams();
  if (!studyId) {
    throw new Error("StudyId not defined");
  }
  return { studyId };
}

export default useStudyId;
