import { useGetStudyMembersQuery } from "../codegen";

export function useStudyMembers(studyId: string) {
  const { data, loading, refetch } = useGetStudyMembersQuery({
    variables: { studyId },
    fetchPolicy: "cache-and-network",
  });

  return { studyMembers: data?.getStudyMembersAction, loading, refetch };
}

export default useStudyMembers;
