import equal from "deep-equal";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

/* eslint-disable */
interface ISortingHelperProps {
  valueA: string;
  valueB: string;
  order: "descending" | "ascending";
}

export function sortingHepler(props: ISortingHelperProps) {
  // equal items sort equally
  if (props.valueA === props.valueB) {
    return 0;
  }
  // nulls sort after anything else
  else if (props.valueA === null) {
    return 1;
  } else if (props.valueB === null) {
    return -1;
  }
  // if descending, highest sorts first
  else if (props.order === "descending") {
    return props.valueA < props.valueB ? 1 : -1;
  } else {
    return props.valueA < props.valueB ? -1 : 1;
  }
}

export function isArrayOfType(array: any[], type: string) {
  return Array.isArray(array) && array.every((value) => typeof value === type);
}

interface IFilterforEditedValuesProps {
  editedValues: any;
  initialValues: any;
}

/**
 * Helper that filters the therapy config for edited values
 */
export function filterForEditedTherapyValues({
  editedValues,
  initialValues,
}: IFilterforEditedValuesProps) {
  const filteredKeys = Object.keys(editedValues).filter((key) => {
    return !equal(editedValues[key], initialValues[key]);
  });

  const filteredValues = filteredKeys.map((key) => ({
    key,
    currentValue: editedValues[key],
    oldValue: initialValues[key],
  }));

  return filteredValues;
}

export function momentToStringWithoutTimezone(dayjs: dayjs.Dayjs) {
  return dayjs.format("YYYY-MM-DDTHH:mm:ss");
}

export function endOfDayString(dayjs: dayjs.Dayjs) {
  return momentToStringWithoutTimezone(dayjs.endOf("day"));
}

export function startOfDayString(dayjs: dayjs.Dayjs) {
  return momentToStringWithoutTimezone(dayjs.startOf("day"));
}

export interface IAddObjectFieldProps {
  /**
   * object to which the field should be added
   */
  object: any;
  /**
   * Path to field. Nested path, seperated by delimiter
   */
  path: string;
  /**
   * Delimiter used in path to indicates nested path
   */
  delimiter?: string;
  /**
   * value of the field
   */
  value: any;
}
// ->utils, testing

export function addObjectField({ object, path, value, delimiter = ":" }: IAddObjectFieldProps) {
  const keys = path.split(delimiter);
  const lastKey = keys.pop();
  const lastObj = keys.reduce((obj2: any, key: string) => {
    // eslint-disable-next-line no-param-reassign
    obj2[key] = obj2[key] || {};
    return obj2[key];
  }, object);
  if (lastKey && lastObj[lastKey] === undefined) {
    lastObj[lastKey] = value;
  } else {
    console.error("element exists");
  }
}

export const isInInteractionDesignerMode = import.meta.env.VITE_PLATFORM === "InteractionDesigner";
