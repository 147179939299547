import React from "react";
import { IFormItemValidator, TherapyConfigFormItem } from "./formItems/TherapyConfigFormItem";

export interface IEditableCellProps extends React.HTMLAttributes<HTMLElement>, IFormItemValidator {
  // eslint-disable-next-line react/require-default-props
  editableCell?: boolean;
  editing: boolean;
  children: React.ReactNode;
  dataIndex: string;
  variableType: string;
  mandatory: boolean;
  // eslint-disable-next-line react/require-default-props
  validatorProps?: any;
}

export function EditableCell({
  editableCell = false,
  editing = false,
  children,
  dataIndex,
  variableType,
  validatorProps,
  mandatory,
  validate,
  ...restProps
}: IEditableCellProps) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <td {...restProps}>
      {editableCell ? (
        <TherapyConfigFormItem
          name={dataIndex}
          editing={editing}
          variableType={variableType}
          mandatory={mandatory}
          validatorProps={validatorProps}
          validate={validate}
        />
      ) : (
        children
      )}
    </td>
  );
}

export default EditableCell;
