import { useGetParticipantQuery } from "../codegen";

export function useParticipant(participantId: string) {
  const { data, ...rest } = useGetParticipantQuery({
    variables: { id: participantId },
  });

  return { participant: data?.participant, ...rest };
}

export default useParticipant;
