import React, { ReactNode } from "react";
import { PageContent, IPageContentProps } from "./PageContent";

export interface IAlertInformation {
  message: string;
  description: string;
}

export interface IPageContainerProps extends IPageContentProps {
  sider: ReactNode;
}

export function PageContainer(props: IPageContainerProps) {
  const { sider, children, hideSider } = props;
  return (
    <>
      {!hideSider ? sider : null}

      <PageContent {...props}>{children}</PageContent>
    </>
  );
}
