import { Form, Modal } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useUpdateStudyMember } from "../../../../services";
import { StudyRolesFormItem } from "./StudyRolesFormItem";

interface IEditStudyRoleModalProps {
  studyId: string;
  userId: string;
  userName: string;
  open: boolean;
  currentRoleId: string;
  onCancel: () => void;
}

export function EditStudyRoleModal({
  studyId,
  userId,
  userName,
  open,
  currentRoleId,
  onCancel,
}: IEditStudyRoleModalProps) {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { updateStudyMember, loading } = useUpdateStudyMember({ onCompleted: () => onCancel() });

  useEffect(() => {
    if (open) {
      form.setFieldsValue({ roleId: currentRoleId });
    }
  }, [open]);

  const handleOnFormFinish = (values: any) => {
    updateStudyMember({
      studyId,
      userId,
      studyRoleId: values.roleId,
    });
  };

  return (
    <Modal
      title={t("editStudyRoleModal.title", { userName })}
      closable
      open={open}
      onCancel={onCancel}
      confirmLoading={loading}
      onOk={() => form.submit()}
      okText={t("common.ok")}
      // @ts-ignore
      okButtonProps={{ "data-id": "common.ok" }}
      cancelText={t("common.cancel")}
      // @ts-ignore
      cancelButtonProps={{ "data-id": "common.cancel" }}
      destroyOnClose
      afterClose={() => form.resetFields()}
    >
      <Form form={form} onFinish={handleOnFormFinish}>
        <StudyRolesFormItem studyId={studyId} />
      </Form>
    </Modal>
  );
}

export default EditStudyRoleModal;
