import React from "react";
import { StudySider, IStudySiderProps } from "./StudySider";

import { IPageContentProps, PageContainer } from "../../../shared";

export interface IStudyContainerProps extends IStudySiderProps, IPageContentProps {}

export function StudyContainer(props: IStudyContainerProps) {
  const { children, hideSider, mainLayoutStyle } = props;
  const layoutWithoutSider = mainLayoutStyle
    ? { ...mainLayoutStyle, marginLeft: 0, backgroundColor: "white" }
    : { marginLeft: 0, backgroundColor: "white" };

  return (
    <PageContainer
      sider={<StudySider {...props} />}
      {...props}
      mainLayoutStyle={hideSider ? layoutWithoutSider : mainLayoutStyle}
      hideSider={hideSider}
    >
      {children}
    </PageContainer>
  );
}
