import React from "react";
import { Form, Select, Typography } from "antd";
import { IBaseFormItemProps, ITherapyConfigInputProps } from "./TherapyConfigFormItem";

const { Text } = Typography;

export interface ISelectFormItemProps extends IBaseFormItemProps {
  selectType: "single" | "multiple";
  validatorProps: ISelectItemValidatorProps;
}

export interface ISelectInputProps extends ITherapyConfigInputProps {
  selectType: "single" | "multiple";
  validatorProps: ISelectItemValidatorProps;
}

export interface ISelectItemValidatorProps {
  options: { value: string; label: string }[];
}

/**
 * Form item wrapper to use the SelectInput component with antd forms
 */
export function SelectItem({
  editing,
  name,
  executorValidationRule,
  validatorProps,
  selectType,
}: ISelectFormItemProps) {
  return (
    <Form.Item name={name} rules={[executorValidationRule]}>
      <SelectInput editing={editing} validatorProps={validatorProps} selectType={selectType} />
    </Form.Item>
  );
}

/**
 * Checks if the validatorProps are valid. Returns true if they are valid and false
 */
const areValidatorPropsValid = (validatorProps: any) => {
  const isInvalid =
    // check if validatorProps is defined
    !validatorProps ||
    // check if options is defined
    !validatorProps.options ||
    // check if options is an array
    !Array.isArray(validatorProps.options) ||
    // check if options is an empty array
    validatorProps.options.length <= 1 ||
    // check type of each option
    !validatorProps.options.every(
      (option: any) => typeof option.value === "number" && typeof option.label === "string",
    );

  return !isInvalid;
};

/**
 * Checks if the value is valid. Returns true if it is valid and false if it is invalid
 */
const isValueValid = ({
  therapyVariableConfigValue,
  selectType,
  validatorProps,
}: {
  therapyVariableConfigValue: any;
  validatorProps: ISelectItemValidatorProps;
  selectType: "single" | "multiple";
}) => {
  let isInvalid = true;

  if (selectType === "single") {
    // check if value is in options
    isInvalid =
      validatorProps.options.find(
        (option: any) => option.value === therapyVariableConfigValue?.value,
      ) === undefined;
  }

  if (selectType === "multiple") {
    isInvalid =
      // check if value is an array
      !Array.isArray(therapyVariableConfigValue?.value) ||
      // check if every value is represented in options
      !therapyVariableConfigValue?.value.every((value: any) =>
        validatorProps.options.find((option: any) => option.value === value),
      );
  }
  return !isInvalid;
};

/**
 *  Component for rendering a select input for displaying and editing
 *  SingleSelect and MultiSelect variable values.
 *  @param editing - boolean value to determine if the input is editable
 *  @param selectType - determines if the select is a single or multi select
 *  @param therapyVariableConfigValue - the value of the variable. Has to be an
 *  object with a value field. value is a string or an array of strings
 *  depending on the selectType
 *  @param onChange - called when the value changes
 *  @param validatorProps - Has to be an object with an options field. options
 *  is an array of objects with a value and a label field. value and label are
 *  strings.
 */
export function SelectInput({
  editing,
  value: therapyVariableConfigValue,
  onChange,
  selectType,
  validatorProps,
}: ISelectInputProps) {
  if (!areValidatorPropsValid(validatorProps)) {
    return <Text type="danger">Options missing or wrong</Text>;
  }

  if (!isValueValid({ therapyVariableConfigValue, selectType, validatorProps })) {
    return <Text type="danger">Wrong value</Text>;
  }

  return (
    <Select
      mode={selectType === "multiple" ? "multiple" : undefined}
      disabled={!editing}
      value={therapyVariableConfigValue?.value}
      onChange={(value) => {
        if (onChange)
          onChange({
            ...therapyVariableConfigValue,
            value,
          });
      }}
      options={validatorProps.options}
    />
  );
}

export default SelectItem;
