import { EllipsisOutlined, SettingOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu, MenuProps, Tooltip } from "antd";
import React from "react";

import useParticipantState, {
  IParticipantStateActionsProps,
  useParticipantStateActions,
  ParticipantActionEnum,
  IParticipantsStateAction,
} from "./useParticipantState";
import { primaryColor } from "../layout";

export function useParticipantActionButtons({
  participantId,
  coupleUrl,
  pseudonym,
  studyId,
  coupledAt,
  therapyEnd,
  therapyPeriodId,
  therapyStart,
}: IParticipantStateActionsProps) {
  const state = useParticipantState({ coupleUrl, therapyEnd, therapyStart });

  const { actions } = useParticipantStateActions({
    coupleUrl,
    participantId,
    pseudonym,
    state: state.name,
    studyId,
    therapyEnd,
    therapyPeriodId,
    therapyStart,
    coupledAt,
  });

  const primaryActions = actions.filter((action) =>
    [
      ParticipantActionEnum.COUPLE_SMARTPHONE,
      ParticipantActionEnum.START,
      ParticipantActionEnum.PAUSE,
    ].includes(action.key),
  );

  const secondaryActions = actions.filter((action) =>
    [ParticipantActionEnum.EDIT_PERIOD].includes(action.key),
  );
  const moreActions = actions.filter(
    (action) =>
      ![
        ParticipantActionEnum.COUPLE_SMARTPHONE,
        ParticipantActionEnum.START,
        ParticipantActionEnum.PAUSE,
        ParticipantActionEnum.EDIT_PERIOD,
      ].includes(action.key),
  );

  const primaryButtons = primaryActions.map((action) => (
    <Tooltip color={primaryColor} title={action.tooltip}>
      <Button
        type="primary"
        icon={action.icon}
        key={action.key}
        onClick={action.action}
        disabled={action.disabled}
      >
        {action.title}
      </Button>
    </Tooltip>
  ));

  const secondaryButtons = secondaryActions.map((action) => (
    <Button icon={action.icon} key={action.key} onClick={action.action} disabled={action.disabled}>
      <Tooltip color={primaryColor} title={action.tooltip}>
        {action.title}
      </Tooltip>
    </Button>
  ));

  const moreButton = (
    <Dropdown
      key="dropdown"
      trigger={["click"]}
      overlay={<ParticipantActionsMenu actions={moreActions} />}
    >
      <Button icon={<SettingOutlined />}>
        <EllipsisOutlined />
      </Button>
    </Dropdown>
  );

  return [...secondaryButtons, ...primaryButtons, moreButton];
}

interface IMoreButtonMenuProps extends MenuProps {
  actions: IParticipantsStateAction[];
}

export function ParticipantActionsMenu({ actions, ...props }: IMoreButtonMenuProps) {
  return (
    <Menu {...props}>
      {actions.map((action, index) => (
        <React.Fragment key={action.key}>
          {index > 0 && actions[index].actionGroup !== actions[index - 1].actionGroup && (
            <Menu.Divider />
          )}
          <Menu.Item onClick={action.action} icon={action.icon} disabled={action.disabled}>
            <Tooltip color={primaryColor} title={action.tooltip}>
              {action.title}
            </Tooltip>
          </Menu.Item>
        </React.Fragment>
      ))}
    </Menu>
  );
}

export function ParticipantActionsMenuButton({
  participantId,
  coupleUrl,
  coupledAt,
  pseudonym,
  studyId,
  therapyEnd,
  therapyPeriodId,
  therapyStart,
}: IParticipantStateActionsProps) {
  const state = useParticipantState({ coupleUrl, therapyEnd, therapyStart });

  const { actions } = useParticipantStateActions({
    coupleUrl,
    participantId,
    pseudonym,
    state: state.name,
    studyId,
    therapyEnd,
    therapyPeriodId,
    therapyStart,
    coupledAt,
  });

  return (
    <Dropdown
      key="dropdown"
      trigger={["click"]}
      overlay={<ParticipantActionsMenu actions={actions} />}
    >
      <Button
        style={{
          border: "none",
          padding: 0,
        }}
      >
        <EllipsisOutlined
          style={{
            fontSize: 20,
          }}
        />
      </Button>
    </Dropdown>
  );
}

export default useParticipantActionButtons;
