import { ApolloError } from "@apollo/client";
import { notification } from "antd";
import { useTranslation } from "react-i18next";
import {
  GetStudyDocument,
  UpdateAllowedEmailDomainsMutation,
  useUpdateAllowedEmailDomainsMutation,
} from "../codegen";

interface IUseUpdateStudySettings {
  studyId: string;
  // eslint-disable-next-line no-unused-vars
  onCompleted?: (data: UpdateAllowedEmailDomainsMutation) => void;
  // eslint-disable-next-line no-unused-vars
  onError?: (error: ApolloError) => void;
}

/**
 * Hook that offers functions to update study settings
 *
 */
export function useUpdateStudySettings({
  studyId,
  onCompleted = () => {},
  onError = () => {},
}: IUseUpdateStudySettings) {
  const { t } = useTranslation();
  const [mutate, { loading }] = useUpdateAllowedEmailDomainsMutation({
    onCompleted: (data) => {
      notification.success({
        message: t("notifications.updateAllowedDomains.success"),
      });
      onCompleted(data);
    },
    onError: (error) => {
      notification.error({
        message: t("notifications.updateAllowedDomains.error"),
      });
      onError(error);
    },
    refetchQueries: [{ query: GetStudyDocument, variables: { id: studyId } }],
  });

  /**
   * Updates the allowed email domains property of a study
   * @param list of allowed email domains
   */
  const updateAllowedEmailDomains = ({
    allowedEmailDomains,
  }: {
    allowedEmailDomains?: string[];
  }) => {
    mutate({
      variables: {
        studyId,
        allowedEmailDomains:
          allowedEmailDomains && allowedEmailDomains.length > 0
            ? allowedEmailDomains?.map((domain) => domain.trim()).join(",")
            : null,
      },
    });
  };

  return { updateAllowedEmailDomains, loading };
}

export default useUpdateStudySettings;
