/* eslint-disable no-nested-ternary */
import React from "react";
import { CheckCircleTwoTone } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { Button, DatePicker, Form, Input, InputNumber, Modal, Select, Space } from "antd";
import dayjs from "dayjs";
import { useCreateExecutor, useFindExecutor, useOrganizations } from "../../../services";

const { Option } = Select;

interface ICreateExecutorModalProps {
  open: boolean;
  onCancel: () => void;
}

function CreateExecutorModal({ open, onCancel }: ICreateExecutorModalProps) {
  const { t } = useTranslation();
  const { getTherapyDesigns, therapyDesigns, validateStatus, clearResults } = useFindExecutor();
  const { organizations } = useOrganizations({ queryAsMaster: true });
  const { createExecutor, loading } = useCreateExecutor({ onCompleted: () => closeModal() });

  const [form] = Form.useForm();
  const areTherapyDesignsAvailable = therapyDesigns && therapyDesigns?.length > 0;

  const handleCheckUrl = () => {
    if (!areTherapyDesignsAvailable) {
      getTherapyDesigns(form.getFieldValue("executorUrl"));
    } else {
      form.resetFields();
      clearResults();
    }
  };
  const closeModal = () => {
    form.resetFields();
    onCancel();
    clearResults();
  };

  const handleFinish = (values: any) => {
    createExecutor({
      executorUrlExternal: values.executorUrlExternal,
      executorUrl: values.executorUrl,
      name: values.name,
      therapyDesignId: values.therapyDesignId,
      organizationId: values.organizationId === "all" ? undefined : values.organizationId,
      participantLimit: values.participantLimit,
      expiresAt: values.expiresAt?.endOf("day").toISOString(),
    });
  };

  return (
    <Modal
      open={open}
      title={t("management.executor.createExecutorModal.title")}
      okText={t("common.create")}
      cancelText={t("common.cancel")}
      // @ts-ignore
      cancelButtonProps={{ "data-id": "common.cancel" }}
      onCancel={closeModal}
      // @ts-ignore
      okButtonProps={{ "data-id": "common.create", loading }}
      onOk={() => form.submit()}
      destroyOnClose
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleFinish}
        initialValues={{ organizationId: "all", participantLimit: -1, expiresAt: null }}
      >
        <Form.Item style={{ marginBottom: 0, marginTop: 24 }}>
          <Space.Compact block size="middle">
            <Form.Item
              name="executorUrl"
              style={{ width: "180%" }}
              label={t("management.executor.createExecutorModal.executorUrlLabel")}
              rules={[
                {
                  required: true,
                  // type: "url",
                  message: t("management.executor.createExecutorModal.missingUrl"),
                },
              ]}
            >
              <Input
                data-id="executorUrl.input"
                disabled={areTherapyDesignsAvailable}
                suffix={
                  validateStatus === "success" && <CheckCircleTwoTone twoToneColor="#52c41a" />
                }
              />
            </Form.Item>
            <Form.Item style={{ alignSelf: "end" }}>
              <Button
                data-id="handleCheckUrl"
                onClick={handleCheckUrl}
                disabled={validateStatus === "validating"}
                style={{ alignSelf: "" }}
              >
                {areTherapyDesignsAvailable
                  ? t("management.executor.createExecutorModal.clear")
                  : t("management.executor.createExecutorModal.check")}
              </Button>
            </Form.Item>
          </Space.Compact>
        </Form.Item>
        <Form.Item
          name="executorUrlExternal"
          label={t("management.executor.createExecutorModal.executorUrlLabelExternal")}
          rules={[
            {
              required: true,
              message: t("management.executor.createExecutorModal.missingUrl"),
            },
          ]}
        >
          <Input disabled={!areTherapyDesignsAvailable} />
        </Form.Item>
        <Form.Item
          name="therapyDesignId"
          label={t("management.executor.createExecutorModal.therapyDesignLabel")}
          rules={[
            { required: true, message: t("management.executor.createExecutorModal.missingDesign") },
          ]}
        >
          <Select disabled={!areTherapyDesignsAvailable}>
            {therapyDesigns?.map((therapyDesign) => (
              <Option
                key={therapyDesign.id}
                value={therapyDesign.id}
              >{`${therapyDesign.title} ${therapyDesign.version}`}</Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="organizationId"
          label={t("management.executor.createExecutorModal.organizationLabel")}
          rules={[{ required: true }]}
        >
          <Select disabled={!areTherapyDesignsAvailable}>
            <Option key="all" value="all">
              ALL
            </Option>
            {organizations?.map((organization) => (
              <Option
                key={organization.id}
                value={organization.id}
              >{`${organization.name}`}</Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="name"
          label={t("management.executor.createExecutorModal.executorNameLabel")}
          rules={[
            { required: true, message: t("management.executor.createExecutorModal.missingName") },
          ]}
        >
          <Input data-id="name.input" disabled={!areTherapyDesignsAvailable} />
        </Form.Item>
        <Form.Item
          name="participantLimit"
          label={t("management.executor.executorListItem.executorParticipantLimit")}
          rules={[
            { required: true, message: t("management.executor.createExecutorModal.missingLimit") },
          ]}
        >
          <InputNumber
            type="number"
            disabled={!areTherapyDesignsAvailable}
            min={-1}
            step={1}
            precision={0}
            style={{ width: 200 }}
          />
        </Form.Item>
        <Form.Item
          name="expiresAt"
          label={t("management.executor.executorListItem.executorExpirationDate")}
        >
          <DatePicker
            style={{ width: 200 }}
            disabled={!areTherapyDesignsAvailable}
            disabledDate={(date) => date.isBefore(dayjs())}
            showToday={false}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default CreateExecutorModal;
