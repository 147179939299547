import React from "react";
import { Menu } from "antd";
import {
  SettingOutlined,
  UserOutlined,
  DeploymentUnitOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { ActionEnum } from "../../../services";
import {
  ISiderMenuProps,
  LinkedMenuItemWithPermission,
  Paths,
  SideMenuContainer,
} from "../../../shared";

const { SubMenu } = Menu;

export interface IOrganizationSiderProps extends ISiderMenuProps {
  organizationId: string;
}

export const OrganizationSiderMenuItemEnum = {
  OVERVIEW: "organization:overview",
  STUDIES: "organization:studies",
  MEMBERS: "organization:members",
  SETTINGS: "organization:settings",
  CONTRACTS: "organization:contracts",
  EDIT: "organization:edit",
};

export function OrganizationSider(props: IOrganizationSiderProps) {
  const { organizationId } = props;
  const { t } = useTranslation();
  const organizationRoutes = Paths.getOrganizationPaths(organizationId);

  return (
    <SideMenuContainer {...props} homeRoute={organizationRoutes.BASE_PATH}>
      <LinkedMenuItemWithPermission
        key={OrganizationSiderMenuItemEnum.OVERVIEW}
        icon={<HomeOutlined />}
        action={ActionEnum.ORGANIZATION_VIEW_DETAILS}
        organizationId={organizationId}
        linkAddress={organizationRoutes.OVERVIEW}
        itemText={t("organization.menu.overview")}
      />

      <LinkedMenuItemWithPermission
        key={OrganizationSiderMenuItemEnum.STUDIES}
        icon={<DeploymentUnitOutlined />}
        action={ActionEnum.ORGANIZATION_VIEW_STUDIES}
        organizationId={organizationId}
        linkAddress={organizationRoutes.STUDIES}
        itemText={t("organization.menu.studies")}
      />

      <LinkedMenuItemWithPermission
        key={OrganizationSiderMenuItemEnum.MEMBERS}
        icon={<UserOutlined />}
        action={ActionEnum.ORGANIZATION_VIEW_MEMBERS}
        organizationId={organizationId}
        linkAddress={organizationRoutes.MEMBERS}
        itemText={t("organization.menu.members")}
      />

      <SubMenu
        key={OrganizationSiderMenuItemEnum.SETTINGS}
        icon={<SettingOutlined />}
        title={t("organization.menu.settings")}
      >
        <LinkedMenuItemWithPermission
          key={OrganizationSiderMenuItemEnum.EDIT}
          action={ActionEnum.ORGANIZATION_EDIT_ORGANIZATION}
          organizationId={organizationId}
          linkAddress={organizationRoutes.SETTINGS}
          itemText={t("organization.menu.generalSettings")}
        />
      </SubMenu>
    </SideMenuContainer>
  );
}

export default OrganizationSider;
