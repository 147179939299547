import React from "react";
import { useLocation } from "react-router";
import { useUser } from "../../services";
import { Loader, Paths, ServerError, useUserId } from "../../shared";
import { UserContainer, UserSiderMenuItemEnum } from "./components";

export function UserController({ children }: { children?: React.ReactNode }) {
  const { userId } = useUserId();
  const location = useLocation();
  const { user, loading: userLoading } = useUser(userId);

  const getContainerPropsFromPath = () => {
    let breadCrumbs = [""];
    let selectedKeys = [""];
    let defaultOpenKeys;

    switch (location.pathname) {
      case Paths.ACCOUNT:
        selectedKeys = [UserSiderMenuItemEnum.ACCOUNT];
        breadCrumbs = ["User Settings", "Account"];
        break;

      default:
        selectedKeys = [UserSiderMenuItemEnum.PROFILE];
        breadCrumbs = ["User Settings", "Profile"];
    }

    return {
      breadCrumbs,
      selectedKeys,
      defaultOpenKeys,
    };
  };

  if (userLoading) {
    return <Loader />;
  }

  if (!user) {
    return <ServerError />;
  }

  return (
    <UserContainer
      {...getContainerPropsFromPath()}
      title={`${user.firstName} ${user.lastName}`}
      userAvatar={user.avatar ? user.avatar : undefined}
    >
      {children}
    </UserContainer>
  );
}

export default UserController;
