import { DatePicker, Form, Input, Modal } from "antd";
import dayjs from "dayjs";
import React from "react";
import { useTranslation } from "react-i18next";
import { IUpdateApiAccount, useUpdateApiAccount } from "../../../../services";

interface IUpdateApiAccountModalProps extends IUpdateApiAccount {
  /* indicates if the modal should be open or not */
  open: boolean;
  /* handler that is called, when the cancel button of the modal is clicked */
  onCancel: () => void;
}

/**
 * Modal dialogue containing a form for updating the name and/or expiration date
 * for an existing API account
 */
export function UpdateApiAccountModal({
  id,
  serviceName,
  expiresAt,
  open,
  onCancel,
}: IUpdateApiAccountModalProps) {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { updateApiAccount, loading } = useUpdateApiAccount({ onCompleted: () => onCancel() });

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  const handleOnFormFinish = (values: any) => {
    updateApiAccount({
      id,
      expiresAt: dayjs(values.expiresAt).endOf("day").toISOString(),
      serviceName: values.serviceName,
    });
  };

  return (
    <Modal
      title={t("study.updateApiAccount.modal.title")}
      closable
      destroyOnClose
      open={open}
      onCancel={handleCancel}
      onOk={() => form.submit()}
      confirmLoading={loading}
      okText={t("common.ok")}
      cancelText={t("common.cancel")}
    >
      <Form
        form={form}
        onFinish={handleOnFormFinish}
        layout="vertical"
        initialValues={{ serviceName, expiresAt: dayjs(expiresAt) }}
      >
        <Form.Item
          name="serviceName"
          label={t("study.createApiAccount.form.serviceName.label")}
          rules={[
            {
              required: true,
              message: t("study.createApiAccount.form.serviceName.error"),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="expiresAt"
          label={t("study.createApiAccount.form.expirationDate.label")}
          rules={[
            {
              required: true,
              message: t("study.createApiAccount.form.expirationDate.error"),
            },
          ]}
        >
          <DatePicker
            style={{ width: "100%" }}
            placeholder={t("study.createApiAccount.form.expirationDate.placeholder")}
            disabledDate={(current) => current && current < dayjs().endOf("day")}
            showToday={false}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default UpdateApiAccountModal;
