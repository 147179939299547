import { useGetParticipantsByStudyIdAndPseudonymLazyQuery } from "../codegen";

export interface ICheckPseudonymProps {
  studyId: string;
  pseudonym: string;
}

export function useCheckPseudonym() {
  const [fetch] = useGetParticipantsByStudyIdAndPseudonymLazyQuery({
    fetchPolicy: "network-only",
  });

  const isPseudonymAvailable = async ({ studyId, pseudonym }: ICheckPseudonymProps) => {
    const req = await fetch({ variables: { studyId, pseudonym } });
    const participants = req.data?.participants;
    return participants?.length === 0;
  };

  return { isPseudonymAvailable };
}
