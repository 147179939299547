import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { App } from "antd";
import { useTranslation } from "react-i18next";
import { ActionEnum, useExecutorStatistics, usePermission, useStudy } from "../../services";
import {
  Loader,
  Paths,
  ServerError,
  StudyProvider,
  useEditableTherapyElements,
  useStudyId,
} from "../../shared";
import { StudyContainer, StudySiderMenuItemEnum } from "./components";

export function StudyController({ children }: { children: React.ReactNode }) {
  const { modal } = App.useApp();
  const { t } = useTranslation();
  const location = useLocation();
  const [showWarningModal, setShowWarningModal] = useState(true);
  const { studyId } = useStudyId();
  const { study, loading: studyLoading } = useStudy(studyId);
  const { usageStatistics } = useExecutorStatistics();
  const { pathname } = useLocation();
  const { permitted: hasStudyPermission, loading: permissionLoading } = usePermission({
    action: ActionEnum.STUDY_VIEW_DETAILS,
    studyId,
  });

  useEffect(() => {
    if (usageStatistics?.isExpired && showWarningModal) {
      modal.warning({
        title: t("study.participants.studyExpired.title"),
        content: t("study.participants.studyExpired.descriptionShort", {
          date: new Date(usageStatistics?.expiresAt as string).toLocaleDateString(),
        }),
      });
      setShowWarningModal(false);
    }
  }, [usageStatistics?.isExpired, showWarningModal]);

  const {
    editableTherapyElements,
    therapyDesign,
    loading: therapyDesignLoading,
  } = useEditableTherapyElements({
    studyId,
    configurationLevel: "GROUP",
  });

  const therapyElementsHashLinks = editableTherapyElements?.map((element) => ({
    name: element.title,
    hashLink: `${pathname}/therapyconfig#${element.id}`,
  }));

  const getContainerPropsFromPath = () => {
    let selectedKeys = [""];
    let defaultOpenKeys;
    let maxContentWidth;
    let contentStyle;

    switch (location.pathname) {
      case Paths.getStudyPaths(studyId).OVERVIEW:
        selectedKeys = [StudySiderMenuItemEnum.OVERVIEW];
        maxContentWidth = "90%";
        break;

      case Paths.getStudyPaths(studyId).SETTINGS:
        selectedKeys = [StudySiderMenuItemEnum.EDIT_STUDY];
        defaultOpenKeys = [StudySiderMenuItemEnum.SETTINGS];
        break;
      case Paths.getStudyPaths(studyId).PARTICIPANTS:
        selectedKeys = [StudySiderMenuItemEnum.PARTICIPANTS];
        maxContentWidth = 1000;
        break;

      case Paths.getStudyPaths(studyId).PARTICIPANTS_GROUPS:
        selectedKeys = [StudySiderMenuItemEnum.GROUPS];
        defaultOpenKeys = [StudySiderMenuItemEnum.GROUP_SUBMENU];
        break;
      case Paths.getStudyPaths(studyId).PARTICIPANTS_GROUPS_THERAPY_CONFIG:
        selectedKeys = [StudySiderMenuItemEnum.GROUPS_THERAPY_CONFIG];
        defaultOpenKeys = [StudySiderMenuItemEnum.GROUP_SUBMENU];
        maxContentWidth = "90%";
        break;
      case Paths.getStudyPaths(studyId).EDITORS:
        selectedKeys = [StudySiderMenuItemEnum.STUDY_ADMINISTRATORS];
        defaultOpenKeys = [StudySiderMenuItemEnum.PERSONNEL_SUBMENU];
        break;
      case Paths.getStudyPaths(studyId).SUPERVISORS:
        selectedKeys = [StudySiderMenuItemEnum.PARTICIPANT_SUPERVISORS];
        defaultOpenKeys = [StudySiderMenuItemEnum.PERSONNEL_SUBMENU];
        break;
      default:
        selectedKeys = [StudySiderMenuItemEnum.PARTICIPANTS];
        maxContentWidth = "100%";
        contentStyle = {
          paddingTop: 0,
        };
    }

    return {
      selectedKeys,
      defaultOpenKeys,
      maxContentWidth,
      contentStyle,
    };
  };

  if (studyLoading || therapyDesignLoading || permissionLoading) {
    return <Loader componentName="StudyController" />;
  }

  if (!study || !therapyDesign) {
    return <ServerError />;
  }

  return (
    <StudyContainer
      {...getContainerPropsFromPath()}
      title={study.name}
      studyId={study.id}
      studyLogo={study.logo}
      hideSider={!hasStudyPermission}
      therapyElements={therapyElementsHashLinks}
    >
      <StudyProvider study={study}>{children}</StudyProvider>
    </StudyContainer>
  );
}

export default StudyController;
