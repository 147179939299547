import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { List, Divider, Button, Result, Steps, Typography } from "antd";
import { BulbOutlined } from "@ant-design/icons";
import "../../shared/layout/css/FullPage.css";
import { PageHeader, primaryColor, Paths, SearchField, StudyListItem } from "../../shared";
import { UserFromQuery } from "../../services";
import { MainPageContainer } from "./components";

type UserAsStudyMember = UserFromQuery["studyMembers"][number];

const { Step } = Steps;
const { Text, Title } = Typography;

interface IStudiesOverviewProps {
  user: UserFromQuery;
}

export function StudiesOverview({ user }: IStudiesOverviewProps) {
  const { studyMembers, organizationMembers } = user;
  const [searchResult, setSearchResult] = useState({ searchText: "", items: studyMembers });
  const { t } = useTranslation();

  const filter = (rolesToFilter: UserAsStudyMember[]) => {
    const filterForSearchText = rolesToFilter.filter((role) =>
      searchResult.items.find((item) => item.id === role.id),
    );
    return filterForSearchText;
  };

  return studyMembers.length > 0 ? (
    <MainPageContainer>
      <PageHeader
        hideDivider
        title={
          <Title level={2} data-id="studiesOverview.title">
            {t("studiesOverview.title")}
          </Title>
        }
        description={t("studiesOverview.description")}
        extra={[
          <SearchField
            key="searchField"
            allItems={studyMembers?.map((studyMember) => ({
              item: studyMember,
              searchFields: studyMember.study.description
                ? [studyMember.study.name, studyMember.study.description]
                : [studyMember.study.name],
            }))}
            dataId="studiesOverview.search"
            placeholderText={t("studiesOverview.searchPlaceholder")}
            onSearch={setSearchResult}
            style={{ float: "left", width: 240 }}
          />,
          <Link key="newStudyButton" to={Paths.STUDY_NEW}>
            <Button type="primary" data-id="studiesOverview.newStudyButton">
              {t("studiesOverview.newStudyButton")}
            </Button>
          </Link>,
        ]}
      />

      <Divider style={{ marginTop: 12 }} />

      <List<UserAsStudyMember>
        itemLayout="horizontal"
        size="small"
        pagination={{
          hideOnSinglePage: true,
          pageSize: 5,
        }}
        dataSource={filter(studyMembers)}
        renderItem={(item) => (
          <StudyListItem
            searchStringToHighlight={searchResult.searchText}
            studyId={item.study.id}
            studyName={item.study.name}
            studyDescription={item.study.description}
            studyRole={item.studyRole.name}
            organizationName={
              item.study.organization.name ? item.study.organization.name : undefined
            }
            studyLogo={item.study.logo ? item.study.logo : undefined}
          />
        )}
      />
    </MainPageContainer>
  ) : (
    <Result
      style={{
        margin: "auto",
        marginTop: 64,
        width: "70%",
        minWidth: 1000,
        padding: 40,
      }}
      data-id="studiesOverview.welcomeTitle"
      icon={<BulbOutlined style={{ color: primaryColor }} />}
      title={t("studiesOverview.welcomeTitle")}
      subTitle={
        <>
          <Text>{t("studiesOverview.steps.title")}</Text>
          <Steps
            current={organizationMembers.length === 0 ? 1 : 2}
            percent={60}
            style={{ marginTop: 40, marginBottom: 40 }}
          >
            <Step
              title={t("studiesOverview.steps.first.title")}
              description={t("studiesOverview.steps.first.description")}
            />
            <Step
              title={t("studiesOverview.steps.second.title")}
              description={t("studiesOverview.steps.second.description")}
            />
            <Step
              title={t("studiesOverview.steps.third.title")}
              description={t("studiesOverview.steps.third.description")}
            />
          </Steps>
        </>
      }
      extra={
        organizationMembers.length === 0 ? (
          <Link to={Paths.ORGANIZATION_NEW}>
            <Button data-id="studiesOverview.button.createOrganization" type="primary">
              {t("studiesOverview.button.createOrganization")}
            </Button>
          </Link>
        ) : (
          <Link to={Paths.STUDY_NEW}>
            <Button data-id="studiesOverview.button.createStudy" type="primary">
              {t("studiesOverview.button.createStudy")}
            </Button>
          </Link>
        )
      }
    />
  );
}

export default StudiesOverview;
