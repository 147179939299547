import i18n from "i18next";
import i18nextBrowserLanguagedetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import translationDeID from "./shared/assets/locales/de_ID/translation.json";
import translationDeTD from "./shared/assets/locales/de_TD/translation.json";
import translationsEnTd from "./shared/assets/locales/en_TD/translation.json";
import translationsEnId from "./shared/assets/locales/en_ID/translation.json";
import translationsSkTD from "./shared/assets/locales/sk/translation.json";
import translationsNlTD from "./shared/assets/locales/nl/translation.json";

// i18next-http-backend loads translations from your server https://github.com/i18next/i18next-http-backend
// detect user language learn more: https://github.com/i18next/i18next-browser-languageDetector
// pass the i18next instance to react-i18next
// init i18next for all options read: https://www.i18next.com/overview/configuration-options

declare module "i18next" {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

const isInInteractionDesignerMode = import.meta.env.VITE_PLATFORM === "InteractionDesigner";

const resourcesTD = {
  de: {
    translation: translationDeTD,
  },
  en: {
    translation: translationsEnTd,
  },
  sk: {
    translation: translationsSkTD,
  },
  nl: {
    translation: translationsNlTD,
  },
};

const resourcesID = {
  de: {
    translation: translationDeID,
  },
  en: {
    translation: translationsEnId,
  },
};

i18n
  .use(i18nextBrowserLanguagedetector)
  .use(initReactI18next)
  .init({
    backend: {
      requestOptions: {
        cache: "reload",
      },
    },
    resources: isInInteractionDesignerMode ? resourcesID : resourcesTD,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed as react escapes by default
    },
  });

export default i18n;
