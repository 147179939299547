import React from "react";
import { useLocation } from "react-router";
import { ActionEnum, useOrganization, usePermission } from "../../services";
import { Loader, OrganizationProvider, Paths, ServerError, useOrganizationId } from "../../shared";
import { OrganizationContainer, OrganizationSiderMenuItemEnum } from "./components";

export function OrganizationController({ children }: { children: React.ReactNode }) {
  const location = useLocation();
  const { organizationId } = useOrganizationId();
  const organizationRoutes = Paths.getOrganizationPaths(organizationId);
  const { organization, loading: organizationLoading } = useOrganization(organizationId);
  const { loading: permissionLoading } = usePermission({
    organizationId,
    action: ActionEnum.ORGANIZATION_VIEW_DETAILS,
  });

  const getContainerPropsFromPath = () => {
    let selectedKeys = [""];
    let defaultOpenKeys;

    switch (location.pathname) {
      case organizationRoutes.STUDIES:
        selectedKeys = [OrganizationSiderMenuItemEnum.STUDIES];
        break;
      case organizationRoutes.MEMBERS:
        selectedKeys = [OrganizationSiderMenuItemEnum.MEMBERS];
        break;
      case organizationRoutes.SETTINGS:
        selectedKeys = [OrganizationSiderMenuItemEnum.EDIT];
        defaultOpenKeys = [OrganizationSiderMenuItemEnum.SETTINGS];
        break;
      case organizationRoutes.CONTRACTS:
        selectedKeys = [OrganizationSiderMenuItemEnum.CONTRACTS];
        defaultOpenKeys = [OrganizationSiderMenuItemEnum.SETTINGS];
        break;

      default:
        selectedKeys = [OrganizationSiderMenuItemEnum.OVERVIEW];
    }

    return {
      selectedKeys,
      defaultOpenKeys,
    };
  };

  if (organizationLoading || permissionLoading) {
    return <Loader />;
  }

  if (!organization) {
    return <ServerError />;
  }

  return (
    <OrganizationContainer
      {...getContainerPropsFromPath()}
      organizationId={organization.id}
      title={organization.name}
    >
      <OrganizationProvider organization={organization}>{children}</OrganizationProvider>
    </OrganizationContainer>
  );
}

export default OrganizationController;
