import { useEffect, useState } from "react";
import { GetTherapyDesignsQuery, useGetTherapyDesignsLazyQuery } from "../codegen";

export function useFindExecutor() {
  const [validateStatus, setValidateStatus] = useState<
    "" | "warning" | "error" | "success" | "validating" | undefined
  >(undefined);
  const [therapyDesigns, setTherapyDesigns] = useState<GetTherapyDesignsQuery>();

  const [fetch, { data, loading, refetch, ...rest }] = useGetTherapyDesignsLazyQuery({
    fetchPolicy: "network-only",
    onCompleted: (data) => setTherapyDesigns(data),
    onError: (error) => {
      setValidateStatus("error");
    },
    context: {
      headers: {
        "x-hasura-role": "master",
      },
    },
  });

  const clearResults = () => {
    setTherapyDesigns(undefined);
    setValidateStatus(undefined);
  };

  useEffect(() => {
    if (loading) {
      setValidateStatus("validating");
      return;
    }
    if (data && data.getTherapyDesignsAction.length === 0) {
      setValidateStatus("warning");
      setTherapyDesigns(data);
    }
    if (data && data.getTherapyDesignsAction.length > 0) {
      setValidateStatus("success");
      setTherapyDesigns(data);
    }
  }, [loading, data]);

  const getTherapyDesigns = (executorUrl: string) => {
    if (executorUrl) {
      fetch({ variables: { executorUrl } }).then((result) => {
        setTherapyDesigns(result.data);
        if (result.data && result.data.getTherapyDesignsAction.length > 0) {
          setValidateStatus("success");
        } else {
          setValidateStatus("error");
        }
      });
    }
  };

  return {
    therapyDesigns: therapyDesigns?.getTherapyDesignsAction,
    getTherapyDesigns,
    clearResults,
    validateStatus,
    loading,
    ...rest,
  };
}

export default useFindExecutor;
