import { CSSProperties } from "react";
import { isInInteractionDesignerMode } from "../../../utils";

export const primaryColor = isInInteractionDesignerMode ? "#4096ff" : "#08979c";

const calculateColorGradient = ({
  color,
  direction,
}: {
  color: string;
  direction: string;
}): string => {
  const colors = generateColors(color);
  return `linear-gradient(${direction}, ${colors[0]}, ${colors[1]}, ${colors[2]}, ${colors[3]}, ${colors[4]})`;
};

const generateColors = (color: string): string[] => {
  const color2 = shadeColor(color, -10);
  const color3 = shadeColor(color, -20);
  const color4 = shadeColor(color, -30);
  const color5 = shadeColor(color, -40);
  return [color, color2, color3, color4, color5];
};

const shadeColor = (color: string, percent: number): string => {
  let R = parseInt(color.substring(1, 3), 16);
  let G = parseInt(color.substring(3, 5), 16);
  let B = parseInt(color.substring(5, 7), 16);

  R = Math.min(255, Math.max(0, Math.round((R * (100 + percent)) / 100)));
  G = Math.min(255, Math.max(0, Math.round((G * (100 + percent)) / 100)));
  B = Math.min(255, Math.max(0, Math.round((B * (100 + percent)) / 100)));

  const RR = R.toString(16).padStart(2, "0").toUpperCase();
  const GG = G.toString(16).padStart(2, "0").toUpperCase();
  const BB = B.toString(16).padStart(2, "0").toUpperCase();

  return `#${RR}${GG}${BB}`;
};

export const boxShadow = "0px 0px 20px 2px rgba(0,0,0,0.15)";
export const collapseSiderAtScreenWidth = 1180;
export const collapsedSiderWidth = 80;
export const collapsedSiderWidthTabletMode = 48;
export const errorRedBackground = "#FFEBEF";
export const errorRedLabel = "#CB002E";
export const errorRedPrimary = "#E10033";
export const headerHeight = 64;
export const highlighterColor = `${primaryColor}40`;
export const informationBlueBackground = "#ECF6FF";
export const informationBlueLabel = "#096DD9";
export const informationBluePrimary = "#0084FF";
export const informationOrangeBackground = "#FADB14";
export const informationOrangeLabel = "#D4380D";
export const informationOrangePrimary = "#FA541C";
export const navRouterYOffset = headerHeight + 16;
export const participantPageHeaderHeight = 140;
export const participantSiderWidth = 216;
export const siderColor = "#f5f5f5";
export const siderWidth = 204;
export const successGreenBackground = "#F6FFED";
export const successGreenLabel = "#2D7F09";
export const successGreenPrimary = "#36B401";
export const warningYellowBackground = "#FFFBE6";
export const warningYellowLabel = "#8F5F00";
export const warningYellowPrimary = "#FAAD14";
export const windowHeightMinusHeader = "calc(100vh - 67px)";
export const listItemTitleFontWeight = 500;

export const mainPagesStyle = {
  maxWidth: 1000,
  width: "100%",
  padding: 24,
  minHeight: windowHeightMinusHeader,
  margin: "auto",
};

export const defaultListItemStyle = {
  backgroundColor: "white",
  marginBottom: "5px",
  borderStyle: "solid",
  borderColor: "#E5E5E5",
  borderWidth: "1px",
  borderRadius: 6,
};

export const siderStyle: CSSProperties = {
  backgroundColor: siderColor,

  borderRightStyle: "solid",
  borderRightColor: "#d9d9d9",
  borderRightWidth: 1,
  zIndex: 2,
};

export const participantSiderStyle: CSSProperties = {
  backgroundColor: "#FEFEFE",
  minHeight: "100vh",
  borderRightStyle: "solid",
  borderRightColor: "#d9d9d9",
  borderRightWidth: 1,
  zIndex: 3,
};

export const participantMenuStyle: CSSProperties = {
  overflowY: "auto",
  overflowX: "hidden",
};

export const siderMenuStyle: CSSProperties = {
  backgroundColor: siderColor,
};

export const siderHeaderStyle: CSSProperties = {
  marginLeft: 12,
  marginBottom: 24,
  marginTop: 24,
};

export const mainLayoutMargin = 0;

export const mainLayoutStyle: CSSProperties = {
  marginTop: 0,
  maxWidth: "100%",
  overflowX: "hidden",
  backgroundColor: "white",
  marginRight: mainLayoutMargin,
  marginBottom: mainLayoutMargin,
  marginLeft: mainLayoutMargin,
  zIndex: 1,
};

export const mainContentStyle: CSSProperties = {
  margin: 24,
  backgroundColor: "white",
};

export const participantLayoutStyle: CSSProperties = {
  width: "100%",
};

export const participantContentStyle: CSSProperties = {
  padding: 8,
  maxWidth: "100%",
  overflowX: "auto",
  paddingTop: 24,
  backgroundColor: "white",
};

export const participantPageHeaderStyle: CSSProperties = {
  backgroundColor: "#FCFCFC",
  height: participantPageHeaderHeight,
  width: `100%`,
  borderBottomStyle: "solid",
  borderBottomColor: "#d9d9d9",
  borderBottomWidth: 1,
  zIndex: 9,
};

export const breadcrumbStyle: CSSProperties = {
  margin: "16px 0",
};

export const loginDivStyle: CSSProperties = {
  background: "white",
  minWidth: "400px",
  width: "20vw",
  padding: "20px",
  paddingLeft: "30px",
  paddingRight: "30px",
  margin: "auto",
  textAlign: "center",
  borderRadius: 6,
  boxShadow,
};

export const backGroundColorGradient = calculateColorGradient({
  color: primaryColor,
  direction: "to right top",
});

export const backgroundLoginStyle: CSSProperties = {
  minHeight: "100vH",
  backgroundImage: backGroundColorGradient,
};

export const loginTitleStyle: CSSProperties = {
  margin: "auto",
  paddingTop: 80,
  color: "#ededed",
  fontSize: "29px",
  letterSpacing: "3px",
  lineHeight: "1.2em",
  width: "100vW",
  textAlign: "center",
  fontWeight: 400,
};

export const loginWrapperStyle: CSSProperties = {
  width: "100vW",
  marginTop: 64,
  marginBottom: 64,
  textAlign: "center",
};

export const headerStyle: CSSProperties = {
  position: "fixed",
  paddingRight: 12,
  paddingLeft: 0,
  zIndex: 10,
  width: "100%",
  backgroundImage: calculateColorGradient({ color: primaryColor, direction: "to right" }),
};
