import { useGetUserLazyQuery } from "../codegen";

export function useUserLazy() {
  const [fetch, { data, loading }] = useGetUserLazyQuery({
    fetchPolicy: "cache-and-network",
  });

  const getUser = (userId: string) => {
    fetch({ variables: { userId } });
  };

  return { user: data?.user, getUser, loading };
}

export default useUserLazy;
