import { ApolloError } from "@apollo/client";
import { App, Form, Typography, notification } from "antd";
import { useForm } from "antd/lib/form/Form";
import dayjs from "dayjs";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParticipant } from "..";
import { CenterInModal, TherapyPeriodFormItem } from "../../../shared";
import { endOfDayString, startOfDayString } from "../../../utils";
import {
  GetParticipantActionDocument,
  UpdateTherapyPeriodMutation,
  useUpdateTherapyPeriodMutation,
} from "../codegen";

const { Text } = Typography;

interface IUseUpdateParticipantProps {
  participantId: string;
  // eslint-disable-next-line no-unused-vars
  onCompleted?: (data: UpdateTherapyPeriodMutation) => void;
  // eslint-disable-next-line no-unused-vars
  onError?: (error: ApolloError) => void;
}

interface IUseUpdateTherapyPeriod {
  therapyPeriodId: string;
  start?: string | null;
  end?: string | null;
  maxEndDate?: string | null;
}

export function useUpdateTherapyPeriod({
  participantId,
  onCompleted = () => {},
  onError = () => {},
}: IUseUpdateParticipantProps) {
  const { t } = useTranslation();
  const [form] = useForm();
  const { participant } = useParticipant(participantId);
  const { modal } = App.useApp();
  const [mutate, { loading }] = useUpdateTherapyPeriodMutation({
    onCompleted: (data) => {
      notification.success({
        message: t("notifications.updateTherapyPeriod.success"),
      });
      onCompleted(data);
    },
    onError: (error) => {
      notification.error({
        message: t("notifications.updateTherapyPeriod.error"),
      });
      onError(error);
    },
  });

  const updateTherapyPeriod = ({ therapyPeriodId, start, end }: IUseUpdateTherapyPeriod) => {
    mutate({
      variables: {
        therapyPeriod: {
          participantId,
          therapyPeriodId,
          start,
          end,
        },
      },
      refetchQueries: [{ query: GetParticipantActionDocument, variables: { id: participantId } }],
    });
  };

  const showUpdateTherapyPeriodModal = ({
    therapyPeriodId,
    start,
    end,
    maxEndDate,
  }: IUseUpdateTherapyPeriod) => {
    let endDateToSet = null;
    if (end && !maxEndDate) {
      endDateToSet = dayjs(end);
    }
    if (end && maxEndDate) {
      endDateToSet = dayjs(end).isAfter(dayjs(maxEndDate)) ? dayjs(maxEndDate) : dayjs(end);
    }
    if (!end && maxEndDate) {
      endDateToSet = dayjs(maxEndDate);
    }

    modal.confirm({
      title: t("participant.updateTherapyPeriodModal.title", { pseudonym: participant?.pseudonym }),
      icon: null,
      content: (
        <CenterInModal>
          <Text>{t("participant.updateTherapyPeriodModal.description")}</Text>
          <Form
            initialValues={{
              dateRange: [start && dayjs(start), endDateToSet],
            }}
            layout="vertical"
            style={{ marginTop: 24 }}
            form={form}
            onFinish={(values: any) =>
              updateTherapyPeriod({
                therapyPeriodId,
                start:
                  values.dateRange && values.dateRange[0]
                    ? startOfDayString(values.dateRange[0])
                    : null,
                end:
                  values.dateRange && values.dateRange[1]
                    ? endOfDayString(values.dateRange[1])
                    : null,
              })
            }
          >
            <TherapyPeriodFormItem name="dateRange" form={form} endDate={maxEndDate} />
          </Form>
        </CenterInModal>
      ),
      okText: t("common.save"),
      cancelText: t("common.cancel"),
      okButtonProps: { loading },
      onOk: async () => {
        await form.validateFields();
        form.submit();
      },
    });
  };

  return { updateTherapyPeriod, showUpdateTherapyPeriodModal, loading };
}

export default useUpdateTherapyPeriod;
