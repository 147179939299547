/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
import React from "react";

interface IDummyAnchorProps {
  id: string;
  editing?: boolean;
}

/**
 * Anchor used for hashrouting in TherapyConfig Content.
 * editing prop ist needed to avoid warnings.
 * TherapyConfigForm is expecting this prop
 *
 * @param param0
 * @returns
 */
export function DummyAnchor({ id }: IDummyAnchorProps) {
  return <p id={id} />;
}

export default DummyAnchor;
