import { useGetOrganizationRolesOfOrganizationQuery } from "../codegen";

export function useOrganizationRoles(organizationId: string) {
  const { data, loading, refetch } = useGetOrganizationRolesOfOrganizationQuery({
    variables: { organizationId },
  });

  return { organizationRoles: data?.organizationRoles, loading, refetch };
}

export default useOrganizationRoles;
