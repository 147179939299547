import { useGetSupervisorsOfStudyQuery } from "../codegen";

export function useSupervisors(studyId: string) {
  const { data, loading, refetch } = useGetSupervisorsOfStudyQuery({
    variables: { studyId },
    fetchPolicy: "cache-and-network",
  });

  return { supervisors: data?.getSupervisorsOfStudyAction, loading, refetch };
}

export default useSupervisors;
