/* eslint-disable react/require-default-props */
import {
  CloudServerOutlined,
  ExclamationCircleOutlined,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import { Button, List, App, Skeleton, Space, Tag, Typography } from "antd";
import React, { useState } from "react";
import Highlighter from "react-highlight-words";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import {
  ExecutorFromQuery,
  useDeleteExecutor,
  useTherapyDesignsFromExecutor,
} from "../../../services";
import { defaultListItemStyle, highlighterColor, primaryColor } from "../../../shared";
import EditExecutorModal from "./EditExecutorModal";

const { Text } = Typography;

interface IExecutorListItemProps {
  executor: ExecutorFromQuery;
  searchStringToHighlight?: string;
}

export function ExecutorListItem({
  executor,
  searchStringToHighlight = "",
}: IExecutorListItemProps) {
  const { t } = useTranslation();
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const { deleteExecutor } = useDeleteExecutor({});
  const { therapyDesigns } = useTherapyDesignsFromExecutor(executor.executorUrl);
  const { modal } = App.useApp();

  const therapyDesign = therapyDesigns?.find((design) => design.id === executor.therapyDesignId);

  const handleDeleteExecutor = () => {
    modal.confirm({
      title: t("management.executor.confirmDelete.title", { executorName: executor.name }),
      icon: <ExclamationCircleOutlined />,
      content: t("management.executor.confirmDelete.description"),
      okText: t("common.yes"),
      okType: "danger",
      cancelText: t("common.no"),
      onOk() {
        deleteExecutor(executor.id);
      },
    });
  };

  const numOfStudies = executor.studies.filter((study) => study.deletedAt === null).length;

  return (
    <List.Item
      style={{ ...defaultListItemStyle }}
      key={executor.id}
      extra={[
        <Tag key="tag" color={numOfStudies > 0 ? "green" : "blue"}>
          {numOfStudies} {t("common.study", { count: numOfStudies })}
        </Tag>,
        <Space key="buttons">
          <Button onClick={() => setIsEditModalVisible(true)} type="primary">
            {t("common.edit")}
          </Button>
          <Button onClick={handleDeleteExecutor} disabled={numOfStudies > 0}>
            {t("common.remove")}
          </Button>
        </Space>,
      ]}
    >
      <List.Item.Meta
        avatar={<CloudServerOutlined />}
        title={
          <Space>
            <Text style={{ color: primaryColor }}>
              <Highlighter
                highlightStyle={{ background: highlighterColor }}
                searchWords={[searchStringToHighlight]}
                autoEscape
                textToHighlight={executor.name}
              />
            </Text>
            {executor.hidden && (
              <Tag key="visibility" icon={<EyeInvisibleOutlined />} color="default">
                {t("management.executor.executorListItem.hiddenTag")}{" "}
              </Tag>
            )}
          </Space>
        }
        description={
          <>
            <Text>
              <Text strong>
                {t("management.executor.executorListItem.description.organization")}{" "}
              </Text>

              <Highlighter
                highlightStyle={{ background: highlighterColor }}
                searchWords={[searchStringToHighlight]}
                autoEscape
                textToHighlight={
                  executor.organization
                    ? executor.organization.name
                    : t("management.executor.executorListItem.allOrganizations")
                }
              />
            </Text>
            <br />
            <Text>
              <Text strong>{t("management.executor.executorListItem.description.url")} </Text>
              <Highlighter
                highlightStyle={{ background: highlighterColor }}
                searchWords={[searchStringToHighlight]}
                autoEscape
                textToHighlight={executor.executorUrl}
              />
            </Text>
            <br />

            <Text>
              <Text strong>
                {t("management.executor.executorListItem.description.executorUrlExternal")}{" "}
              </Text>
              <Highlighter
                highlightStyle={{ background: highlighterColor }}
                searchWords={[searchStringToHighlight]}
                autoEscape
                textToHighlight={executor.executorUrlExternal}
              />
            </Text>
            <br />

            <Text>
              <Text strong>
                {t("management.executor.executorListItem.executorParticipantLimit")}
                {": "}
              </Text>
              {executor.participantLimit}
            </Text>
            <br />

            <Text>
              <Text strong>
                {t("management.executor.executorListItem.executorExpirationDate")}
                {": "}
              </Text>
              {executor.expiresAt
                ? dayjs(executor.expiresAt).toDate().toLocaleDateString()
                : t("management.executor.executorListItem.executorExpirationDateNotSet")}
            </Text>
            <br />

            <Text>
              <Text strong>
                {t("management.executor.executorListItem.description.therapyDesign")}{" "}
              </Text>
              {therapyDesign ? (
                <Highlighter
                  highlightStyle={{ background: highlighterColor }}
                  searchWords={[searchStringToHighlight]}
                  autoEscape
                  textToHighlight={`${therapyDesign.title} ${therapyDesign.version}`}
                />
              ) : (
                <Skeleton.Input size="small" style={{ width: 100 }} />
              )}
            </Text>
          </>
        }
      />
      <EditExecutorModal
        open={isEditModalVisible}
        onCancel={() => setIsEditModalVisible(false)}
        executor={executor}
      />
    </List.Item>
  );
}

export default ExecutorListItem;
