import { CheckCircleFilled, ExclamationCircleFilled, PlusOutlined } from "@ant-design/icons";
import { Button, Space, Table, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ActionEnum, useApiAccounts, usePermission } from "../../../../services";
import { errorRedPrimary, successGreenPrimary } from "../../../../shared";
import ApiAccountsActionButtons from "./ApiAccountActionButtons";
import CreateApiAccountModel from "./CreateApiAccountModal";

interface IAccountRow {
  /* hasura id of the account  */
  key: string;
  /* name of the service that want's to access the API */
  serviceName: string;
  /* inidicates if expiration date has passed */
  status: "EXPIRED" | "ACTIVE";
  /* creation date */
  createdAt: string;
  /* expiration date */
  expiresAt: string;
}

const { Text } = Typography;

/**
 * Defines the columns for the API account table
 */
const apiAccountColumns = ({
  t,
  editable = false,
  studyId,
}: {
  /* translation function */
  t: any;
  /* indicates if the delete and edit buttons should be enabled or not (for
  details see antd documentation)  */
  editable?: boolean;
  /* id of the current study */
  studyId: string;
}) => {
  const columns: ColumnsType<IAccountRow> = [
    {
      title: t("study.apiAccountTable.serviceName"),
      dataIndex: "serviceName",
      key: "serviceName",
      render: (serviceName: string) => <Text strong>{serviceName}</Text>,
    },
    {
      title: t("study.apiAccountTable.createdAt"),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt: string) =>
        dayjs(createdAt)
          .toDate()
          .toLocaleDateString(undefined, { dateStyle: "medium" } as any),
    },
    {
      title: t("study.apiAccountTable.expiresAt"),
      dataIndex: "expiresAt",
      key: "expiresAt",
      render: (expiresAt: string) =>
        dayjs(expiresAt)
          .toDate()
          .toLocaleDateString(undefined, { dateStyle: "medium" } as any),
      sorter: (a: IAccountRow, b: IAccountRow) => dayjs(a.expiresAt).diff(dayjs(b.expiresAt)),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: t("study.apiAccountTable.status"),
      dataIndex: "status",
      key: "status",
      render: (status: string) => {
        if (status === "ACTIVE") {
          return (
            <Space>
              <CheckCircleFilled
                style={{
                  color: successGreenPrimary,
                  fontSize: 20,
                }}
              />
              <Text style={{ color: successGreenPrimary }}>
                {t("study.apiAccountTable.activeStatus")}
              </Text>
            </Space>
          );
        }

        return (
          <Space>
            <ExclamationCircleFilled
              style={{
                color: errorRedPrimary,
                fontSize: 20,
              }}
            />
            <Text style={{ color: errorRedPrimary }}>
              {t("study.apiAccountTable.expiredStatus")}
            </Text>
          </Space>
        );
      },
    },
    {
      title: "",
      key: "actions",
      render: (_: any, record: IAccountRow) => (
        <ApiAccountsActionButtons
          style={{ float: "right" }}
          editable={editable}
          id={record.key}
          expiresAt={record.expiresAt}
          serviceName={record.serviceName}
          studyId={studyId}
        />
      ),
    },
  ];
  return columns;
};

/**
 * Component that renders a table containing the API Accounts of a study and a
 * button to create new API Accounts
 */
export function ApiAccountTable({ studyId }: { studyId: string }) {
  const { t } = useTranslation();
  const { permitted } = usePermission({ action: ActionEnum.STUDY_EDIT, studyId });
  const [showCreateApiAccountModal, setShowCreateApiAccountModal] = useState(false);
  const { apiAccounts } = useApiAccounts(studyId);

  const data: IAccountRow[] = apiAccounts
    ? apiAccounts
        .map((apiAccount) => ({
          key: apiAccount.id,
          serviceName: apiAccount.serviceName,
          createdAt: apiAccount.createdAt,
          expiresAt: apiAccount.expiresAt,
          status: (dayjs(apiAccount.expiresAt).isAfter(dayjs()) ? "ACTIVE" : "EXPIRED") as
            | "ACTIVE"
            | "EXPIRED",
        }))
        .sort((a, b) => dayjs(b.expiresAt).diff(dayjs(a.expiresAt)))
    : [];

  return (
    <>
      <Button
        disabled={!permitted}
        style={{ float: "right", marginBottom: 24 }}
        type="primary"
        icon={<PlusOutlined />}
        onClick={() => setShowCreateApiAccountModal(true)}
      >
        {t("study.study.apiAccountTable.addAccountButton")}
      </Button>
      <Table
        pagination={{ defaultPageSize: 10, hideOnSinglePage: true }}
        dataSource={data}
        columns={apiAccountColumns({
          t,
          editable: permitted,
          studyId,
        })}
      />
      <CreateApiAccountModel
        open={showCreateApiAccountModal}
        onCancel={() => setShowCreateApiAccountModal(false)}
        studyId={studyId}
      />
    </>
  );
}

export default ApiAccountTable;
