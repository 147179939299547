import { ApolloError } from "@apollo/client";
import { notification } from "antd";
import { useTranslation } from "react-i18next";

import { useUserId } from "../../../shared";
import {
  GetUserDocument,
  LeaveOrganizationMutation,
  useCanLeaveOrganizationQuery,
  useLeaveOrganizationMutation,
} from "../codegen";

interface IUseDeleteParticipantProps {
  organizationId: string;
  // eslint-disable-next-line no-unused-vars
  onCompleted?: (data: LeaveOrganizationMutation) => void;
  // eslint-disable-next-line no-unused-vars
  onError?: (error: ApolloError) => void;
}

export function useLeaveOrganization({
  organizationId,
  onCompleted = () => {},
  onError = () => {},
}: IUseDeleteParticipantProps) {
  const { t } = useTranslation();
  const { userId } = useUserId();
  const { data: canLeaveCheck, loading: canLeaveLoading } = useCanLeaveOrganizationQuery({
    variables: { organizationId, userId },
    fetchPolicy: "network-only",
  });
  const [mutate, { loading: leaveLoading }] = useLeaveOrganizationMutation({
    onCompleted: (data) => {
      if (data.leaveOrganizationAction?.success) {
        notification.success({
          message: t("notifications.leaveOrganization.success"),
        });
      } else {
        notification.warning({
          message: t("notifications.leaveOrganization.error"),
        });
      }
      onCompleted(data);
    },
    onError: (error) => {
      notification.error({
        message: t("notifications.leaveOrganization.error"),
      });
      onError(error);
    },
  });

  const leaveOrganization = () => {
    mutate({
      variables: {
        organizationId,
        userId,
      },
      refetchQueries: [{ query: GetUserDocument, variables: { userId } }],
    });
  };

  return {
    leaveOrganization,
    leaveLoading,
    canLeave: canLeaveCheck?.canLeaveOrganizationAction.canLeave,
    canLeaveLoading,
  };
}

export default useLeaveOrganization;
