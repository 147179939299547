import React from "react";
import Highlighter from "react-highlight-words";
import { useTranslation } from "react-i18next";
import { List, Avatar, Button, Typography, Tag as AntdTag } from "antd";
import { UserOutlined, ClockCircleOutlined } from "@ant-design/icons";
import Tag from "../others/Tag";
import { highlighterColor } from "../layout";

const { Text } = Typography;

export interface IMemberProps {
  memberId: string;
  avatar?: string | null;
  email: string;
  firstName?: string | null;
  lastName?: string | null;
  searchStringToHighlight?: string;
}

export interface IMemberListItemsProps extends IMemberProps {
  hideRemove?: boolean;
  disableEdit?: boolean;
  hideRoleTag?: boolean;
  removeButtonTitle?: string;
  editButtonTitle?: string;
  roleName?: string;
  roleId: string;
  roleColor?: string;
  onRemoveRole?: () => void;
  onEditRole?: () => void;
}

export function MemberListItem({
  firstName,
  hideRoleTag = false,
  disableEdit = false,
  hideRemove = false,
  roleColor,
  roleName,
  onEditRole,
  editButtonTitle,
  onRemoveRole,
  removeButtonTitle,
  avatar: roleUserAvatar,
  lastName: roleLastName,
  email: roleUserEmail,
  searchStringToHighlight,
}: IMemberListItemsProps) {
  const { t } = useTranslation();
  return (
    <List.Item
      style={{ minHeight: 64 }}
      extra={[
        <div
          key="roleTag"
          style={{
            display: hideRoleTag ? "none" : "unset",
            marginRight: disableEdit && hideRemove ? 0 : 16,
          }}
        >
          <Tag color={roleColor} text={roleName || "     "} />
        </div>,

        <Button
          style={{ display: disableEdit ? "none" : "unset" }}
          key="editButton"
          type="default"
          onClick={onEditRole}
        >
          {editButtonTitle || t("memberListItem.editRoleButton")}
        </Button>,

        <Button
          key="removeButton"
          style={{ display: hideRemove ? "none" : "unset", marginLeft: 8 }}
          type="default"
          onClick={onRemoveRole}
        >
          {removeButtonTitle || t("memberListItem.removeMemberButton")}
        </Button>,
      ]}
    >
      <List.Item.Meta
        avatar={
          roleUserAvatar ? <Avatar src={roleUserAvatar} /> : <Avatar icon={<UserOutlined />} />
        }
        title={
          firstName ? (
            <Text style={{ fontWeight: 500 }}>
              <Highlighter
                highlightStyle={{ background: highlighterColor }}
                searchWords={[searchStringToHighlight || ""]}
                autoEscape
                textToHighlight={`${firstName} ${roleLastName}`}
              />
            </Text>
          ) : (
            <AntdTag key="regTag" icon={<ClockCircleOutlined />} color="default">
              {t("memberListItem.registrationPending")}
            </AntdTag>
          )
        }
        description={
          <Text type="secondary" style={{ maxWidth: "95%" }} ellipsis>
            <Highlighter
              highlightStyle={{ background: highlighterColor }}
              searchWords={[searchStringToHighlight || ""]}
              autoEscape
              textToHighlight={roleUserEmail}
            />
          </Text>
        }
      />
    </List.Item>
  );
}
