import { Alert, Button, DatePicker, Form, Input, Modal, Space, Spin, Typography } from "antd";
import dayjs from "dayjs";
import { CheckCircleOutlined, CheckOutlined, CopyOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CreateApiAccountActionMutation, useCreateApiAccount } from "../../../../services";
import { siderColor } from "../../../../shared";

interface ICreateApiAccountModalProps {
  /* id of study in which the account should be created */
  studyId: string;
  /* indicates if the modal should be open or not */
  open: boolean;
  /* handler that is called, when the cancel button of the modal is clicked */
  onCancel: () => void;
}

type AccountDetails = CreateApiAccountActionMutation["createApiAccountAction"];

const { Paragraph, Text } = Typography;

/**
 * Modal dialogue containing a form for creating new API accounts.
 * After the account has been created, the user credentials for the account are displayed instead of the form.
 */
export function CreateApiAccountModel({ studyId, open, onCancel }: ICreateApiAccountModalProps) {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { createApiAccount, loading } = useCreateApiAccount({});
  const [accountDetails, setAccountDetails] = useState<AccountDetails>();

  const handleCancel = () => {
    setAccountDetails(undefined);
    form.resetFields();
    onCancel();
  };

  const handleOnFormFinish = async (values: any) => {
    const createApiAccountResult = await createApiAccount({
      studyId,
      expiresAt: dayjs(values.expiresAt).endOf("day").toISOString(),
      serviceName: values.serviceName,
    });

    if (createApiAccountResult.data?.createApiAccountAction) {
      setAccountDetails(createApiAccountResult.data.createApiAccountAction);
    }
  };

  return (
    <Modal
      title={
        accountDetails ? (
          <Space direction="horizontal">
            <CheckCircleOutlined />
            <Text>{t("study.createApiAccount.modal.titleAccountCreated")}</Text>
          </Space>
        ) : (
          t("study.createApiAccount.modal.title")
        )
      }
      closable={false}
      destroyOnClose
      open={open}
      onCancel={handleCancel}
      cancelButtonProps={{ style: { display: accountDetails ? "none" : undefined } }}
      // @ts-ignore
      okButtonProps={{ "data-id": "common.ok" }}
      onOk={
        accountDetails
          ? () => handleCancel()
          : () => {
              if (!loading) {
                form.submit();
              }
            }
      }
      okText={accountDetails ? t("common.close") : t("common.ok")}
      cancelText={t("common.cancel")}
    >
      {accountDetails ? (
        <>
          <Paragraph>{t("study.createApiAccount.modal.successInformation")}</Paragraph>
          <Alert
            style={{ marginTop: 12 }}
            message={t("study.createApiAccount.modal.fugacity")}
            description={
              <Text strong>{t("study.createApiAccount.modal.fugacityExplanation")}</Text>
            }
            type="warning"
            showIcon
          />
          <div
            style={{
              backgroundColor: siderColor,
              borderColor: "black",
              borderRadius: 4,
              borderStyle: "solid",
              borderWidth: 1,
              marginTop: 12,
              padding: 0,
            }}
          >
            <Text
              style={{ float: "right" }}
              copyable={{
                text: JSON.stringify({
                  client_id: accountDetails.clientId,
                  client_secret: accountDetails.clientSecret,
                  username: accountDetails.accountId,
                  password: accountDetails.password,
                }),
                format: "text/plain",
                tooltips: [],
                icon: [
                  <Button key="copy" type="link" icon={<CopyOutlined />}>
                    {t("common.copy")}
                  </Button>,
                  <Button key="copied" type="text" icon={<CheckOutlined />}>
                    {t("common.copied")}
                  </Button>,
                ],
              }}
            />
            <div style={{ padding: 8 }}>
              <div>
                <Text strong style={{ marginRight: 4 }}>
                  {`${t("study.createApiAccount.modal.clientId")}:`}
                </Text>
                <Text data-id="study.createApiAccount.modal.clientId">
                  {accountDetails.clientId}
                </Text>
              </div>
              <div style={{ marginTop: 6 }}>
                <Text strong style={{ marginRight: 4 }}>
                  {`${t("study.createApiAccount.modal.clientSecret")}:`}
                </Text>
                <Text data-id="study.createApiAccount.modal.clientSecret">
                  {accountDetails.clientSecret}
                </Text>
              </div>
              <div style={{ marginTop: 6 }}>
                <Text strong style={{ marginRight: 4 }}>
                  {`${t("study.createApiAccount.modal.accountId")}:`}
                </Text>
                <Text data-id="study.createApiAccount.modal.accountId">
                  {accountDetails.accountId}
                </Text>
              </div>
              <div style={{ marginTop: 6 }}>
                <Text strong style={{ marginRight: 4 }}>
                  {`${t("study.createApiAccount.modal.password")}:`}
                </Text>
                <Text data-id="study.createApiAccount.modal.password">
                  {accountDetails.password}
                </Text>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Spin spinning={loading} tip="Loading...">
          <Form form={form} onFinish={handleOnFormFinish} layout="vertical">
            <Form.Item
              name="serviceName"
              label={t("study.createApiAccount.form.serviceName.label")}
              rules={[
                {
                  required: true,
                  message: t("study.createApiAccount.form.serviceName.error"),
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="expiresAt"
              label={t("study.createApiAccount.form.expirationDate.label")}
              rules={[
                {
                  required: true,
                  message: t("study.createApiAccount.form.expirationDate.error"),
                },
              ]}
            >
              <DatePicker
                disabledDate={(current) => current && current < dayjs().endOf("day")}
                placeholder={t("study.createApiAccount.form.expirationDate.placeholder")}
                showToday={false}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Form>
        </Spin>
      )}
    </Modal>
  );
}

export default CreateApiAccountModel;
